import React from 'react';
import styles from "./CBPlayerInformation.module.css";

import { checkPropsAndRenderComponent } from 'CBReact/Utils/DebugUtils';

export function CBPlayerInformation (props)
{

    function renderCBPlayerInformation ()
    {
        return (
            <div className={`${styles.playerInfo} ${props.alignRight ? styles.right : styles.left}`}>
                {props.playerImage && !props.alignRight && props.playerName && 
                <div>
                    <a href={props.playerLink || ""} target="_blank" rel="noopener noreferrer">
                        <img alt={props.playerName} className={`${styles.image}`} src={props.playerImage}/>
                    </a>
                </div>}

                <div className={styles.textContainer}>
                    <div className={styles.name}>
                        <a className={props.playerName ? styles.link : styles.inactive} href={props.playerLink || ""} target="_blank" rel="noopener noreferrer">
                            {props.playerName || props.playerColor}
                        </a>
                    </div>
                    {
                        props.playerName &&
                        <div className={styles.playerData}>
                            {props.playerTitle ? <span>{props.playerTitle}</span> : <span className={styles.inactive}>TITLE</span>} <br/> 
                            {props.playerElo ? <span>{props.playerElo}</span> : <span className={styles.inactive}>ELO</span>} <br/> 
                            {props.playerNation ? <span>{props.playerNation}</span> : <span className={styles.inactive}>NATION</span>}
                        </div> 
                    }
                    {
                        !props.playerName && 
                        <div className={styles.playerData}>
                            {"TITLE\nELO\nNAT"}
                        </div>
                    }
                </div>

                {props.playerImage && props.alignRight && props.playerName &&
                <div className={styles.imageContainer}>
                    <a href={props.playerLink || ""} target="_blank" rel="noopener noreferrer">
                        <img alt={props.playerName} className={`${styles.image}`} src={props.playerImage}/>
                    </a>
                </div>}
            </div>
        );
    }
    return checkPropsAndRenderComponent([props.playerColor], renderCBPlayerInformation);
}