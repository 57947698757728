// jb 12.11.2013

// "use strict";

import { HashPosition } from "common/Chess/TeraBrain/HashPosition"
import { TBrainMoveList } from "common/Chess/TeraBrain/TBrainMoveList"
import { Log } from "common/Tools/Log"

export class TeraBrainInfo 
{

    constructor () {
        this.pos = new HashPosition();
        this.moveList = new TBrainMoveList();
	};

	read () {

	};

	fromMessage ( rMsg ) {
		var pBuf = rMsg.GetPDataBuf();
		pBuf.Rewind();

		// NH2020 changed to lowercase 
		this.readFromDataBuffer( pBuf );
		//ReadFromDataBuffer( pBuf );
	};

	writeToDataBuffer ( rBuf ) {

		rBuf.beginSizedWrite();

		this.pos.writeToDataBuffer( rBuf );
		rBuf.writeInt64( this.m_nVisits );
		this.m_nVisits.writeToDataBuffer( rBuf );

		rBuf.wndSizedWrite();
	};

	readFromDataBuffer ( rBuf ) {

		rBuf.beginSizedRead();

		this.pos.readFromDataBuffer( rBuf );
		this.m_nVisits = rBuf.readInt64();
		this.moveList.readFromDataBuffer( rBuf );

		rBuf.endSizedRead();
		return true;
	};

	toString () {
		var strRet = "";
		return strRet;
	};

	getSocketsMessage () {
		// NH2020 TeraBrainMessage undefined
		Log.Missing();
		// var aMsg = new TeraBrainMessage( SocketsMessageType.TERABRAIN_BOOKINFO );
		// WriteToDataBuffer( aMsg.GetPDataBuf() );

		// return aMsg;
	};

	getNVisits () {
		return this.m_nVisits;
	};


	getMoveList () {
		return this.moveList;
	};

	setMoveList ( r ) {
		this.m_nVisits = r;
	};


	getHashPosition () {
		return this.pos;
	};

}

