// "use strict";

import { EngineHostMode } from "common/Chess/PlayingModes/EngineHostMode"
// import { CloudPlayingModeMixin } from "common/App/CloudMixins"
// import { SimpleEngineModeMixin } from 'common/Chess/PlayingModes/SimpleEngineModeMixin'
// import { Mixin, classMixin } from 'common/Patterns/ObjectUtil';
import { RepeatGate } from "common/Tools/Timer"
// import { Log } from "common/Tools/Log"
import { MoveEnterType } from "common/Chess/Logic/Game" 
import { System } from "common/Tools/System";
// import { glApp } from "common/App/App"
// import { SearchMask } from "common/Chess/OnlineDB/SearchMask"

export class OnlineDBMode extends EngineHostMode
{
	constructor ( kernel )
	{
		// NH2020 Added super(kernel)
		super(kernel);

		//CB.inherit( OnlineDBMode, CB.EngineHostMode );
		// classMixin( OnlineDBMode, CloudPlayingModeMixin );

		// NH2020 funktioniert diese if Abfrage.
	   	// if ( SimpleEngineModeMixin )
			// Mixin( OnlineDBMode, SimpleEngineModeMixin );

		//CB.OnlineDBMode.superClass.call( this );

		this.kernel = kernel;
		this.curBoard = [];
		this.nMaxOnlineDBGames = 40;
		this.saved = false;
		this.cntUpdates = 0;
		this.nMultiVars = 1;
		  
		this.gateUpdate = new RepeatGate();
	};

	blockLiveBookUpdate ( block )
	{
		if ( this.blockUpdate )
			return;
	};

	onEnteredMove ( mv )
	{
		this.saved = false;

		// NH2020 changed to super.
		return super.onEnteredMove( mv );
		//return this.superClass.onEnteredMove.call( this, mv );
	};

	// NH2021D This overwrites PlayingMode.getMoveEnterType()
	getMoveEnterType ()
	{
		return MoveEnterType.NEWLINE;
	};

	updateGamePos ( config )
	{
		if ( this.updateCloudOnGamePos )
		{
			this.updateCloudOnGamePos();
			this.cntUpdates++;
		}

		this.gateUpdate.exec( function ()
		{
			if ( this.kernel.game.getCurPos().hasLegals() )
			{
		      this.goAll();
			}
		}.bind( this ), 300 );
	};

	searchBook ()
	{

	};

	setDefaultAnimSteps ( boardWin )
	{
		if ( this.kernel )
			var b = boardWin || this.kernel.boardWin;
		if ( b )
			if ( System.isMobile() )
				b.animSteps = { forMove: 0.3, forPosition: 0.3 };
			else
				b.animSteps = { forMove: 0.8, forPosition: 0.8 };
	};

	shouldAnimatePosChange ()
	{
		return true;
	};

	accountTypeListener ( accountType )
	{
		if ( System.isMobile() )
		{
			switch ( accountType.type )
			{
				default:
				case 1:
					this.nMaxOnlineDBGames = 20;
					break;
				case 2:
					this.nMaxOnlineDBGames = 30;
					break;
				case 3:
					this.nMaxOnlineDBGames = 50;
					break;
			}
		}
		else switch ( accountType.type )
		{
			default:
			case 1:
				this.nMaxOnlineDBGames = 25;
				break;
			case 2:
				this.nMaxOnlineDBGames = 60;
				break;
			case 3:
				this.nMaxOnlineDBGames = 100;
				break;
		}
	};
	
	search ()
	{
		// NH2021D
		// if ( glApp.panelMgr.dbGames )
		// {
		// 	var searchMask = new SearchMask();
		// 	searchMask.setSearchBoard( this.kernel.game.getCurPos().board, this.kernel.game.getCurPos().getSideToMove() );
		// 	searchMask.setUseBoard( true );

		// 	if ( glApp.onlineLobby != null )
		// 		glApp.onlineLobby.search( searchMask, this.nMaxOnlineDBGames, this.kernel.game.getMoveNo() );
		// }
	};

	shouldSave ()
	{
		return this.cntUpdates > 10;
	};

}
