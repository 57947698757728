import React, { useContext, useState } from 'react';
import styles from "./CBPremiumSearch.module.css";

import { checkPropsAndRenderComponent } from 'CBReact/Utils/DebugUtils';
import { CBSearchInput } from 'CBReact/Components/DesignElements/InputFields/CBSearchInput';
import { CBCheckBox } from 'CBReact/Components/DesignElements/InputFields/CBCheckBox';
import { CBMButton } from "CBReact/Components/DesignElements/Buttons/Button/CBButton";
import { CBMSearchIcon } from 'CBReact/Components/DesignElements/Icons/CBIcons';
import { LoginContext } from 'CBReact/Contexts/CBOnlineDBContext';
import { LOGIN_MODES } from 'CBReact/Components/Account/AccountUtils';
import { CBButtonBadge } from 'CBReact/Components/DesignElements/Buttons/ButtonBadge/CBButtonBadge';

export function CBPremiumSearch (props)
{

    const [ignoreColor, setIgnoreColor] = useState(props.searchOptions.current.ignoreColor);
    const loginStatus = useContext(LoginContext).loginStatus;

    // function handleWhite (newWhite)
    // {
    //     props.searchOptions.current.white = newWhite;
    // }

    // function handleBlack (newBlack)
    // {
    //     props.searchOptions.current.black = newBlack;
    // }

    // function handleWhiteEloMin (newWhiteEloMin)
    // {
    //     props.searchOptions.current.whiteEloMin = parseInt(newWhiteEloMin);
    // }

    // function handleBlackEloMin (newBlackEloMin)
    // {
    //     props.searchOptions.current.blackEloMin = parseInt(newBlackEloMin);
    // }

    // function handleYearMin (newYearMin)
    // {
    //     props.searchOptions.current.yearMin = parseInt(newYearMin);
    // }

    // function handleYearMax (newYearMax)
    // {
    //     props.searchOptions.current.yearMax = parseInt(newYearMax);
    // }

    // function handlePlace (newPlace)
    // {
    //     props.searchOptions.current.place = newPlace;
    // }

    function handlePremiumClick (e)
    {
        e.stopPropagation(); 
        window.open("https://account.chessbase.com/en/matrix", "_blank", "noopener");
    }

    function renderCBPremiumSearch ()
    {
        return (
            <div className={`${styles.container} ${(loginStatus === LOGIN_MODES.guest || loginStatus === LOGIN_MODES.free) ? styles.guest : ""}`}>

                    <div className={`${styles.row} ${styles.first}`}>
                        <div className={`${styles.headline}`}>Player Data</div>
                    </div>

                    <div className={`${styles.row} ${styles.first}`}>
                        <CBSearchInput
                            handleChange={(newWhite) => {props.searchOptions.current.white = newWhite;}}
                            startText={props.searchOptions.current.white}
                            type={"text"}
                            id={"white"}
                            label={ignoreColor ? "Player 1:" : "White:"}
                            placeholder={"Carlsen"}
                            hasAutoComplete={true}
                            style={{margin: "0px 5px 0px 0px"}}/>
                        <CBSearchInput
                            handleChange={(newWhiteEloMin) => {props.searchOptions.current.whiteEloMin = parseInt(newWhiteEloMin);}}
                            startText={props.searchOptions.current.whiteEloMin}
                            type={"number"}
                            id={"elowhite"}
                            label={"Elo Min:"}
                            placeholder={"2860"}
                            style={{margin: "0px 0px 0px 5px"}}/>
                    </div>

                    <div className={styles.row}>
                        <CBSearchInput
                            handleChange={(newBlack) => {props.searchOptions.current.black = newBlack;}}
                            startText={props.searchOptions.current.black}
                            type={"text"}
                            id={"black"}
                            label={ignoreColor ? "Player 2:" : "Black:"}
                            placeholder={"Anand"}
                            hasAutoComplete={true}
                            style={{margin: "0px 5px 0px 0px"}}/>
                        <CBSearchInput
                            handleChange={(newBlackEloMin) => {props.searchOptions.current.blackEloMin = parseInt(newBlackEloMin);}}
                            startText={props.searchOptions.current.blackEloMin}
                            type={"number"}
                            id={"eloblack"}
                            label={"Elo Min:"}
                            placeholder={"2750"}
                            style={{margin: "0px 0px 0px 5px"}}/>
                    </div>

                    <div className={styles.row}>
                        <CBCheckBox
                            label={"Ignore Color"}
                            initValue={props.searchOptions.current.ignoreColor}
                            handleChange={(checked) => { props.searchOptions.current.ignoreColor = checked; setIgnoreColor(checked); }} />
                    </div>

                    <div className={styles.row}>
                        <div className={styles.headline}>Game Data</div>
                    </div>

                    <div className={`${styles.row} ${styles.first}`}>
                        <CBSearchInput
                            handleChange={(newYearMin) => {props.searchOptions.current.yearMin = parseInt(newYearMin);}}
                            startText={props.searchOptions.current.yearMin}
                            type={"number"}
                            id={"yearmin"}
                            label={"Year Min:"}
                            placeholder={"1990"}
                            style={{margin: "0px 5px 0px 0px"}}/>
                        <CBSearchInput
                            handleChange={(newYearMax) => {props.searchOptions.current.yearMax = parseInt(newYearMax);}}
                            startText={props.searchOptions.current.yearMax}
                            type={"number"}
                            id={"yearmax"}
                            label={"Year Max:"}
                            placeholder={"2021"}
                            style={{margin: "0px 0px 0px 5px"}}/>
                    </div>

                    <div className={styles.row}>
                        <CBSearchInput
                            handleChange={(newPlace) => {props.searchOptions.current.place = newPlace;}}
                            startText={props.searchOptions.current.place}
                            type={"text"}
                            id={"place"}
                            label={"Place:"}
                            placeholder={"Candidates Tournament"}
                            style={{margin: "0px"}}/>
                    </div>

                    <div className={styles.row}>
                        <CBCheckBox
                            label={"Wins"}
                            initValue={props.searchOptions.current.wins}
                            handleChange={(checked) => { props.searchOptions.current.wins = checked; }} />
                        <CBCheckBox
                            label={"Draws"}
                            initValue={props.searchOptions.current.draws}
                            handleChange={(checked) => { props.searchOptions.current.draws = checked; }} />
                        <CBCheckBox
                            label={"Losses"}
                            initValue={props.searchOptions.current.losses}
                            handleChange={(checked) => { props.searchOptions.current.losses = checked }} />
                    </div>
                    
                    <div className={`${styles.row} ${styles.submit}`}>
                        <CBMButton style={{borderRadius: "0px 0px 6px 6px"}} onClick={props.handleSubmit}>
                            <CBMSearchIcon height={"70%"} />
                        </CBMButton>
                    </div>

                    {
                        (loginStatus === LOGIN_MODES.guest || loginStatus === LOGIN_MODES.free) &&
                        <div className={styles.premiumHint}>
                            <span className={styles.span}>Get</span>
                            <div className={styles.premiumBadge}>
                                <CBButtonBadge size="small" onClick={handlePremiumClick}>Premium</CBButtonBadge>
                            </div>
                            <span className={styles.span}>to use expert search.</span><br/>
                        </div>
                    }

            </div>
        )
    }

    return checkPropsAndRenderComponent([props.handleSubmit, props.searchOptions], renderCBPremiumSearch);
}