import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import styles from "./CBChessBoard.module.css";

import { checkPropsAndRenderComponent } from 'CBReact/Utils/DebugUtils';
import { CBMSquare } from 'CBReact/Components/Chess/ChessBoard/CBSquare';
import { CBMChessPiece } from 'CBReact/Components/Chess/ChessBoard/CBChessPiece';
import { CBMBoardMargin } from 'CBReact/Components/Chess/ChessBoard/CBBoardMargin';
import { CBMBoardArrow } from 'CBReact/Components/Chess/ChessBoard/CBBoardArrow';
import { CBSelectPromotion } from './CBSelectPromotion';

import { Move } from 'common/Chess/Logic/Move';
import { Piece } from "common/Chess/Logic/Chess.js";
import { Square } from 'common/Chess/Logic/Chess'

import { BoardThemeContext, LayoutContext } from 'CBReact/Contexts/CBOnlineDBContext';
import { BOARD_LAYOUTS } from 'CBReact/Hooks/ReactContext/useBoardThemeContext';
import { ONLINEDB_LAYOUTS } from 'CBReact/Hooks/ReactContext/useOnlineDBLayoutContext'

export function CBChessBoard (props)
{
    const windowWidth = useContext(LayoutContext).windowWidth;
    const appLayout = useContext(LayoutContext).appLayout;

    const boardLayout = useContext(BoardThemeContext).boardLayout;
    const boardTheme = useContext(BoardThemeContext).boardTheme;

    const [squareSize, setSquareSize] = useState(0);
    const [selectedSquare, setSelectedSquare] = useState(-1);
    const [pieceList, setPieceList] = useState(Array.from(Array(33).keys()).slice(1)); // Create an array from 1-32
    const [userArrows, setUserArrows] = useState([]);

    const [promoMove, setPromoMove] = useState({from: -1, to: -1});
    const [showPromotionChoices, setShowPromotionChoices] = useState(false);

    const boardRef = useRef();
    const inDragRef = useRef(false);
    
    // Make sure that scrolling is disabled while a piece is dragged. Works with old iPad too!
    // TODO: This might be problematic when using multiple boards
    useEffect(() => {
        window.addEventListener('touchmove', preventScrollingOnDrag, { passive: false });
        return () => {
            window.removeEventListener('touchmove', preventScrollingOnDrag, {passive: false});
        }
    }, []);

    function preventScrollingOnDrag (e)
    {
        if (inDragRef.current) 
        {
            e.preventDefault(); 
            e.stopPropagation(); 
        }
    }

    useEffect(() => {
        setUserArrows([]);
        setPromoMove({from: -1, to: -1});
        setShowPromotionChoices(false);
    }, [props.boardPosition]);

    //#region Board Resizing/ Subpixel Consideration 

    const boardRefCallback = useCallback((node) => {
        if (node != null && !boardRef.current)
        {
            boardRef.current = node;
            recalculateBoardSize();
        }
    })

    useEffect(() => {
        recalculateBoardSize();
    }, [appLayout, windowWidth])

    function recalculateBoardSize ()
    {
        if (boardRef.current)
        {
            if (appLayout === ONLINEDB_LAYOUTS.oneColumn)
            {
                let clientRect = boardRef.current.getBoundingClientRect();
                let newSquareSize = clientRect.width / 8;
    
                boardRef.current.style.gridTemplateRows = `repeat(8, ${newSquareSize}px)`;
                boardRef.current.style.gridTemplateColumns = `repeat(8, ${newSquareSize}px)`;
    
                setSquareSize(newSquareSize);
            }
            else
            {
                let clientRect = boardRef.current.getBoundingClientRect();
                let borderSize = clientRect.width * 0.03;
                let modulo = (clientRect.width - (borderSize * 2)) % 4;
                borderSize += (modulo * 0.5);
                let newSquareSize = (clientRect.width - (borderSize * 2)) / 8
    
                boardRef.current.style.gridTemplateRows = `${borderSize}px repeat(8, ${newSquareSize}px) ${borderSize}px`;
                boardRef.current.style.gridTemplateColumns = `${borderSize}px repeat(8, ${newSquareSize}px) ${borderSize}px`;
                
                setSquareSize(newSquareSize);
            }
        }
    }

    //#endregion

    const handlePieceDropped = useCallback((from, to) => {

        var isLegalMove = props.boardPosition.isLegalFromTo(from, to);
        var isCaptureMove = props.boardPosition.getPiece(to) !== 0; // Originally: board.get() != 0 (HandlerBoardControl makeMove)
        var isPromotionMove = props.boardPosition.isProm(from, to);

        if (isLegalMove)
        {
            // Originally: MoveEntry.boardWinMoveEntered. Habe den Color+Square Check weggelassen, da dieser schon über isLegal
            // abgedeckt sein sollte.

            if (isPromotionMove)
            {
                setPromoMove({from, to});
                setShowPromotionChoices(true);
            }
            else
                props.gameKernel.reactToEnteredMove(new Move(from, to, isPromotionMove && (Piece.QUEEN & 7)));
        }
        else if (from !== to)
        {
            // Add user Arrow
            let newUserArrows = [...userArrows, {from: from, to: to}];
            setUserArrows(newUserArrows);
        }

        return {isLegalMove: isLegalMove, isCaptureMove: isCaptureMove, isPromotionMove: isPromotionMove};

    }, [props.gameKernel, props.boardPosition, userArrows]);

    function enterPromotion (chosenPiece)
    {
        setPromoMove({from: -1, to: -1});
        setShowPromotionChoices(false);

        if (chosenPiece)
            props.gameKernel.reactToEnteredMove(new Move(promoMove.from, promoMove.to, (chosenPiece & 7)));
    }

    function renderCBChessBoard ()
    {

        return (
            <div 
                ref={boardRefCallback}
                className={styles.grid} 
                boardtheme={boardTheme} 
                boardalignment={boardLayout === BOARD_LAYOUTS.desktop ? props.whiteIsBottom ? "default" : "upsideDown" : props.whiteIsBottom ? "defaultMobile" : "upsideDownMobile"}>
                
                {/* Render Squares */}
                {
                    Array.from(Array(64).keys()).map((value, squareIndex) => {

                        return <CBMSquare
                            key={"f" + squareIndex}
                            isWhiteField={Square.isLight(squareIndex)}
                            isHighlighted={selectedSquare === squareIndex}
                            squareIndex={squareIndex}
                            squareName={Square.Names[squareIndex]}
                            showSquareInfo={boardLayout === BOARD_LAYOUTS.mobile ? true : false}
                            whiteIsBottom={props.whiteIsBottom}
                        />
                    })
                }

                {/* Render Select Promotion */}
                {
                    showPromotionChoices && promoMove.to !== -1 &&
                    <CBSelectPromotion 
                        squareName={Square.Names[promoMove.to]}
                        enterPromotion={enterPromotion}
                        isWhitePromo={Square.Names[promoMove.to].charAt(1) === "8"}
                        fromTop={props.whiteIsBottom ? Square.Names[promoMove.to].charAt(1) === "8" : Square.Names[promoMove.to].charAt(1) === "1"}/>
                }

                {/* Render Margin */}
                {
                    boardLayout === BOARD_LAYOUTS.desktop &&
                    <CBMBoardMargin whiteIsBottom={props.whiteIsBottom}/>
                }
                
                {/* Render Last Move Arrow */}
                {
                    props.gameKernel.game.getLastMove() &&
                    <CBMBoardArrow
                        squareName={Square.Names[props.gameKernel.game.getLastMove().from]}
                        from={Square.getArrowTranslate(props.gameKernel.game.getLastMove().from, props.whiteIsBottom)}
                        to={Square.getArrowTranslate(props.gameKernel.game.getLastMove().to, props.whiteIsBottom)}
                        hasArrowHead={true}
                        isLastMoveArrow={true}
                        doAnimate={true}
                    />
                }

                {/* Render User Drawn Arrows */}
                {
                    userArrows.map((arrow, index) => {
                        return <CBMBoardArrow
                        key={index}
                        squareName={Square.Names[arrow.from]}
                        from={Square.getArrowTranslate(arrow.from, props.whiteIsBottom)}
                        to={Square.getArrowTranslate(arrow.to, props.whiteIsBottom)}
                        hasArrowHead={true}
                        isLastMoveArrow={false}
                        doAnimate={false}
                    />
                    })
                }

                {/* Render Pieces */}
                {
                    pieceList.map((piece, index) => {
                        var square = props.boardPosition.uniquePiecePositions.findIndex((uniquePiece) => {return uniquePiece === piece});
                        var pieceIndex = props.boardPosition.board[square] || -1;

                        if (promoMove.from !== -1 && promoMove.to !== -1 && square === promoMove.from)
                            square = promoMove.to;

                        //var newTranslate = Square.getTranslate(square, squareSize, props.whiteIsBottom);
                        var showPiece = square === -1 || squareSize === 0 ? false : true;

                        return <CBMChessPiece
                            key={`${piece} ${props.whiteIsBottom ? "white" : "black"}`}
                            boardIsShowingPromotion={showPromotionChoices}
                            showPiece={showPiece}
                            pieceIndex={pieceIndex}
                            squareIndex={square}
                            squareSize={squareSize}
                            whiteIsBottom={props.whiteIsBottom}
                            setSelectedSquare={setSelectedSquare}
                            handlePieceDropped={handlePieceDropped}
                            // translate={newTranslate}
                            boardInDragRef={inDragRef}
                        />
                    })
                }

            </div>
        )
    }

    return checkPropsAndRenderComponent([props.gameKernel, props.boardPosition, props.whiteIsBottom], renderCBChessBoard);
}

// TODO Board. Was das Board alles können muss:
// Eigene Zugeingabe. Mit check, ob der Zug gültig ist oder nicht. Wenn nicht: Zurück aufs Ausgangsfeld
// Promotion Zug.
// Erstmal auf ReplayHandlerBoardControl konzentrieren und die dortige Funktionalität einbauen:
// - MoveSpriteHandler: Sprite anklicken, bewegen und absetzen. Promotion Möglichkeiten anzeigen (Siehe BoardHandler.js isProm). Hide Pieces.
// - MakeMoveHandler: Zug ausführen. Durch From->To Square. Außerdem: Promotion Click handle.
// - MakeMoveHandler: Flow Zugausführung: OnSquareMove -> HandlerBoardControl.makeMove -> Board.change
// - MousePointerHandler: Den Mauscursor verändern, wenn er sich über eine anklickbare Figur bewegt. (Je nach Spielart oder Spielsituation unterschiedlich)
// - TwoClickMoveHandler: Einen Zug durch zwei Klicks ausführen. Die Farbe des ersten angeklickten Feldes ändern. (Set From Square?! Siehe: TwoClickMoveHandler secondClick)
// - ShowLegalMovesHandler: Mögliche Züge anzeigen lassen. Um die üge zu bekomme, siehe: ShowLegalMovesHandler/add
// - AttributeBoardHandler: Pfeile zeichnen. Square Marker zeichnen. Hier nochmal genauer in den Code reinschauen, was passiert.
// - SelectBoardHandler: Selektiert das Board. Sollte hier kein Problem sein.


// Allgemein: Die Dinge, die ich hier gemacht habe in Board Handlern abhaken.

// Ideen:
// - Highlight des Feldes, auf das die Figur atktuell abgestellt werden würde
// - Mobile Version: Figuren über dem Fingen anzeigen? Dazu Feld, auf die die Figur aktuell bewegt werden würde

// Ursprünglicher Event Flow:
// Board Area bekommt das Event. Es wird geschaut, ob die Maus innerhalb des Boards ist.
// Wenn es in einem Board ist: Aufrufen der Funktion in der Board Control, die für das Board zuständig ist.
// 