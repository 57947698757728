

import React, { useState, useRef, Fragment, useEffect, useContext } from 'react';
import styles from "./CBTable.module.css";

import { CBMTableResizer } from "./CBTableResizer"
import { checkPropsAndRenderComponent } from 'CBReact/Utils/DebugUtils';
import { LayoutContext } from 'CBReact/Contexts/CBOnlineDBContext';
import { DimensionUtils } from 'CBReact/Utils/DimensionUtils';
import { CBMTableSortIcon, CBMTableSortReversedIcon } from 'CBReact/Components/DesignElements/Icons/CBIcons';
import { CBLoadingCircle } from 'CBReact/Components/DesignElements/Loaders/CBLoadingCircle/CBLoadingCircle';
import { CBScrollSearchHint } from 'CBReact/Components/Table/CBScrollSearchHint';

export function CBTable (props)
{
    const tableRef = useRef(null);
    const [cellsToRender, setCellsToRender] = useState([]);
    const [heightStyles, setHeightStyles] = useState();

    const headerCellRef = useRef(null);
    const windowWidth = useContext(LayoutContext).windowWidth;
    const appLayout = useContext(LayoutContext).appLayout;

    // Handle Window Resize/ Layout Change: Check if table needs to be redrawn with different number of cells.
    useEffect(() => {
        if (tableRef.current)
        {
            var notToRender = [];
            for (let i = 0; i < props.tableData.removeOnResize.length; i++)
            {
                if (props.tableData.removeAtSize[i] > tableRef.current.offsetWidth)
                    notToRender.push(props.tableData.removeOnResize[i]);
            }

            // If Cell count changes: ReRender with new cells.
            if (cellsToRender.length + notToRender.length !== props.tableData.header.length)
            {
                var toRender = [];

                for (let i = 0; i < props.tableData.header.length; i++)
                {
                    if (!notToRender.includes(i))
                        toRender.push(i);
                }

                setCellsToRender(toRender);

                // Reset FlexGrow Values
                var newFlexGrow = props.tableData.flexGrowFallback;
                props.tableDispatch({ type: 'RESIZE', newFlexGrow });
            }

            // Update Height
            setHeightStyles(getHeightStyles());
        }

    }, [windowWidth, appLayout]);

    useEffect(() => {
        setHeightStyles(getHeightStyles());
    }, [tableRef.current]);

    useEffect(() => {
        // Scroll to top if new Data is from an initial search, not from scroll search
        if (props.tableData && props.tableData.scrollToTop && tableRef.current)
            tableRef.current.scrollTop = 0;
    }, [props.tableData])

    function handleSort (columnIndex)
    {
        props.tableDispatch({ type: 'SORT', columnIndex });
    }

    function renderHeaderCell (content, index)
    {
        var renderIndex = cellsToRender.indexOf(index);
        if (renderIndex === -1)
            return;

        let classes;
        if (index === props.tableData.selectedHeader)
            classes = `${styles.cellOuter} ${styles.headerCellSortable} ${styles.selected}`;
        else if (props.tableData.sortingMethods[index])
            classes = `${styles.cellOuter} ${styles.headerCellSortable}`;
        else 
            classes = `${styles.cellOuter}`;

        return  <div key={"cell"+index} 
                    className={classes} 
                    onMouseDown={() => handleSort(index)}
                    style={{flexGrow: props.tableData.flexGrow[index]}}>
                        <CBMTableResizer headerParent={headerCellRef} 
                                        tableDispatch={props.tableDispatch} 
                                        cellsToRender={cellsToRender}
                                        index={renderIndex} 
                                        flexGrow={props.tableData.flexGrow}/>
                        {index === props.tableData.selectedHeader && !props.tableData.isSortedReversed && <CBMTableSortIcon height={"30%"}/> }
                        {index === props.tableData.selectedHeader && props.tableData.isSortedReversed && <CBMTableSortReversedIcon height={"30%"}/> }  
                        <div className={`${styles.headerCellInner} ${index === props.tableData.selectedHeader && styles.selected}`}>
                            {content}
                        </div>
                </div>
    }

    function renderCell (content, index)
    {
        var renderIndex = cellsToRender.indexOf(index);
        if (renderIndex === -1)
            return;

        return  <div key={"cell"+index} className={`${styles.cellOuter}`} style={{flexGrow: props.tableData.flexGrow[index]}}>
                    <div className={index in props.tableData.renderFancy ? styles.cellInnerFancy : styles.cellInner}>
                        {index in props.tableData.renderFancy ? props.tableData.renderFancy[index]() : content}
                    </div>
                </div>
    }

    function renderRow (row, index)
    {
        let classes;
        if (row.info.selection !== undefined && row.info.selection === props.tableData.selectedRow)
            classes = `${styles.bodyRow} ${styles.selected}`;
        else if (index % 2 === 0)
            classes = `${styles.bodyRow}`
        else 
            classes = `${styles.bodyRow} ${styles.striped}`;

        return (
            <div key={"row"+index} className={classes} onClick={(e) => handleRowClick(e, row.info) }>
                    {row.render.map(renderCell)}
            </div>
        );
    }

    function handleRowClick (e, rowInfo)
    {
        props.onEntryClick(e, rowInfo);

        if (rowInfo.selection !== undefined)
        {
            let newSelection = rowInfo.selection;
            props.tableDispatch({ type: 'SELECTROW', newSelection });
        }
    }

    function getHeightStyles ()
    {
        if (tableRef.current && props.fillRemainingHeight)
        {
            let bottomSpace = props.fillRemainingHeight || 0;
            let newHeight = DimensionUtils.getRemainingHeight(tableRef.current) - bottomSpace;
            newHeight = newHeight > 200 ? newHeight : 200;
            return {minHeight: newHeight, maxHeight: newHeight};
        }
        else if (props.maxHeight && props.minHeight)
            return {maxHeight: props.maxHeight, minHeight: props.minHeight};
        else if (props.maxHeight)
            return {maxHeight: props.maxHeight}
        else if (props.fixedHeight)
            return {minHeight: props.fixedHeight, maxHeight: props.fixedHeight}
        else
            return;
    }

    function renderCBTable ()
    {
        return (
            <Fragment>
                <div className={`${styles.header}`} ref={headerCellRef}>
                    {props.tableData.header.map(renderHeaderCell)}
                </div>
                <div ref={tableRef} className={`${styles.body}`} style={heightStyles} >
                    {props.tableData.content.length > 0 && props.tableData.content.map(renderRow)}
                    {props.showScrollEnd && <CBScrollSearchHint tableRef={tableRef} tableData={props.tableData}/>}
                    {props.tableData.content.length <= 0 && !props.isLoading && <div className={styles.isEmptyHint}>No search Results...</div>}
                    {props.isLoading && props.tableData.content.length === 0 && <CBLoadingCircle style={props.loadingCircleStyle}/> }
                </div>
            </Fragment>
        );
    }

    return checkPropsAndRenderComponent([props.tableData, props.tableDispatch, props.onEntryClick, windowWidth], renderCBTable)
}
