// ST 2011
// ES 6 MW: Feb 27, 2020

import { Piece, Square, Side, CastleRights } from 'common/Chess/Logic/Chess'
import { Move } from 'common/Chess/Logic/Move'
import { Undo } from 'common/Chess/Logic/Undo'
import { Board } from 'common/Chess/Logic/Board'
import { idiv } from 'common/Tools/Tools'
import { Char, StringIterator } from 'common/Tools/Strings/CharUtil'

// NH2020I wichtige Klasse, die eine Position beinhaltet und Auskünfite über diese Position geben kann.
// zb, ob ein bestimmter zug Legal ist. Ob der Zug ein promotion Zug ist. Oder auch, auf welche Felder
// eine gegebene figur ziehen kann.
// Die eigentliche Position des Bretts ist im Feld this.board als Array gespeichert.
// this.board verweist NICHT auf die Klasse, die in Board.js definiert wird.

export class Position
{
	constructor( _fen )
	{
		this.initFields();
		if ( !_fen )
			this.initStartPos();
		else
		{
			if ( _fen.length )
			{
				if ( _fen.charAt( 0 ) === 'w' )
					this.initString( _fen );
				else
					this.initFEN( _fen );
			}
		}
	}

	static NOEP = -1;

	copy( pos )
	{
		for ( var fld = 0; fld < this.board.length; ++fld )
			this.board[ fld ] = pos.board[ fld ];

		// NH2020
		for ( var fld = 0; fld < this.uniquePiecePositions.length; fld++ )
			this.uniquePiecePositions[ fld ] = pos.uniquePiecePositions[ fld ];

		this.sd = pos.sd;
		this.ep = pos.ep;
		this.cr = pos.cr;
		this.lastKillOrPawn = pos;
		this.numPly = pos.numPly;
	};

	// NH2020 added this to change "lastKillOrPawn"
	copy02 (pos)
	{
		for ( var fld = 0; fld < this.board.length; ++fld )
			this.board[ fld ] = pos.board[ fld ];

		for ( var fld = 0; fld < this.uniquePiecePositions.length; fld++ )
			this.uniquePiecePositions[ fld ] = pos.uniquePiecePositions[ fld ];

		this.sd = pos.sd;
		this.ep = pos.ep;
		this.cr = pos.cr;
		this.lastKillOrPawn = pos.lastKillOrPawn;
		this.numPly = pos.numPly;
	}

	equals( _pos )
	{
		if ( !_pos )
			return false;

		if ( this.sd !== _pos.sd )
			return false;
		if ( this.ep !== _pos.ep )
			return false;
		if ( this.cr !== _pos.cr )
			return false;

		for ( var fld = 0; fld < this.board.length; ++fld )
		{
			if ( this.board[ fld ] !== _pos.board[ fld ] )
				return false;
		}

		return true;
	};

	// NH2020 made static
	static plyToMvNum( _numPly, _sd )
	{
		return idiv( _numPly, 2 ) + 1;
	};

	static mvToPlyNum( _numMv, _sd )
	{
		return 2 * ( _numMv - 1 ) + _sd;
	};

	getMoveNum()
	{
		return Position.plyToMvNum( this.numPly, this.sd );
	};

	setMoveNum( _numMv )
	{
		this.numPly = Position.mvToPlyNum( _numMv, this.sd );
	};

	getPlyNum()
	{
		return this.numPly;
	};

	initStartPos()
	{
		this.sd = Side.WHITE;

		for ( var col = Square.C_A; col <= Square.C_H; ++col )
		{
			this.board[ Square.I( col, Square.R_2 ) ] = Piece.W_PAWN;
			this.board[ Square.I( col, Square.R_7 ) ] = Piece.B_PAWN;
		}

		this.board[ Square.A1 ] = this.board[ Square.H1 ] = Piece.W_ROOK;
		this.board[ Square.B1 ] = this.board[ Square.G1 ] = Piece.W_KNIGHT;
		this.board[ Square.C1 ] = this.board[ Square.F1 ] = Piece.W_BISHOP;

		this.board[ Square.D1 ] = Piece.W_QUEEN;
		this.board[ Square.E1 ] = Piece.W_KING;

		this.board[ Square.A8 ] = this.board[ Square.H8 ] = Piece.B_ROOK;
		this.board[ Square.B8 ] = this.board[ Square.G8 ] = Piece.B_KNIGHT;
		this.board[ Square.C8 ] = this.board[ Square.F8 ] = Piece.B_BISHOP;

		this.board[ Square.D8 ] = Piece.B_QUEEN;
		this.board[ Square.E8 ] = Piece.B_KING;

		// NH2020 
		this.uniquePiecePositions = 
		[   1, 2, 0, 0, 0, 0, 3, 4,
			5, 6, 0, 0, 0, 0, 7, 8,
			9, 10, 0, 0, 0, 0, 11, 12,
			13, 14, 0, 0, 0, 0, 15, 16,
			17, 18, 0, 0, 0, 0, 19, 20,
			21, 22, 0, 0, 0, 0, 23, 24,
			25, 26, 0, 0, 0, 0, 27, 28,
			29, 30, 0, 0, 0, 0, 31, 32 ];


		this.cr = CastleRights.ALL;

		this.lastKillOrPawn = 0;
		this.numPly = 0;
	};

	boardEquals( board )
	{
		for ( var i = 0; i < this.board.length && i < 64; i++ )
		{
			if ( this.board[ i ] !== board[ i ] )
				return false;
		}
		return true;
	};

	initFEN( _fen )
	{
		var it = new StringIterator( _fen );

		var rCur = Square.R_8;
		var cCur = Square.C_A;

		var ch;

		for ( ch = it.Next();
			ch !== 0 && !Char.IsWhiteSpace( ch );
			ch = it.Next() )
		{
			if ( ch === '/' )
			{
				--rCur;
				cCur = Square.C_A;
			}
			else if ( Char.IsDigit( ch ) )
			{
				cCur += Number( ch );
			}
			else
			{
				var fld = Square.I( cCur, rCur );
				var pc = Piece.fromString( ch );

				// CBDebug.assert( pc !== Piece.NONE );

				this.board[ fld ] = pc;
				
				// NH202 TODO: Init FEN mit unique Piece IDs

				cCur++;
			}
		}

		if ( it.isEOF() ) return;

		it.skipWhile( Char.IsWhiteSpace );

		if ( it.isEOF() ) return;

		//Seite am Zug.
		this.sd = Side.fromString( it.Current() );

		it.skipWhile( Char.IsWhiteSpace );

		if ( it.isEOF() ) return;

		var strCr = it.AcceptUntil( Char.IsWhiteSpace, true );

		this.cr = CastleRights.fromString( strCr );

		if ( it.isEOF() ) return;

		it.skipWhile( Char.IsWhiteSpace );

		//EP
		var strFld = it.AcceptUntil( Char.IsWhiteSpace, true );

		if ( strFld === '-' )
			this.ep = Position.NOEP;
		else
		{
			var fldEp = Square.fromString( strFld );
			this.ep = Square.C( fldEp );
		}

		let mvNum = 1;
		this.numPly = Position.mvToPlyNum( mvNum, this.sd );

		if ( it.isEOF() )
			return;

		//Kill Clock
		it.skipWhile( Char.IsWhiteSpace );

		if ( it.isEOF() )
			return;

		var strLastKill = it.AcceptUntil( Char.IsWhiteSpace, true );
		this.lastKillOrPawn = Number( strLastKill );
		if ( it.isEOF() )
			return;
		//Move Num
		it.skipWhile( Char.IsWhiteSpace );
		if ( it.isEOF() )
			return;
		var strMvNum = it.AcceptUntil( Char.IsWhiteSpace, true );
		mvNum = Number( strMvNum );

		if ( !mvNum )
			mvNum = 1;

		this.numPly = Position.mvToPlyNum( mvNum, this.sd );
	};

	// NH2021 TODO: InitString mit unique Piece IDs
	initString( str )
	{
		this.initEmpty();
		var l = str.length;
		var ger = false;
		for ( var i = 0; i < l; i++ )
		{
			if ( ( ( str[ i ] === 'b' ) || ( str[ i ] === 's' ) ) && ( str[ i + 1 ] === 'K' ) )
			{
				ger = ( str[ i ] === 's' );
				break;
			}
		}
		var curr_piece = 0, curr_colour = 0;
		for ( let i = 0; i < l; i++ )
		{
			switch ( str[ i ] )
			{
				case 'K':
					curr_piece = Piece.KING;
					break;
				case 'D':
				case 'Q':
					curr_piece = Piece.QUEEN;
					break;
				case 'T':
				case 'R':
					curr_piece = Piece.ROOK;
					break;
				case 'L':
					curr_piece = Piece.BISHOP;
					break;
				case 'B':
					curr_piece = ger ? Piece.PAWN : Piece.BISHOP;
					break;
				case 'S':
				case 'N':
					curr_piece = Piece.KNIGHT;
					break;
				case 'P':
					curr_piece = Piece.PAWN;
					break;
				case 'a':
				case 'b':
				case 'c':
				case 'd':
				case 'e':
				case 'f':
				case 'g':
				case 'h':
					if ( ( str.charCodeAt( i + 1 ) > 48 /*0*/ ) && ( str.charCodeAt( i + 1 ) < 57 /*9*/ ) )
						this.board[ ( ( str.charCodeAt( i ) - 97 /*a*/ ) << 3 ) + str.charCodeAt( i + 1 ) - 49 /*1*/ ] = ( curr_piece | curr_colour );
					else
						if ( ( str[ i ] === 'b' ) && !ger )
							curr_colour = Piece.BLACK;
					break;
				case 's':
					if ( ger )
						curr_colour = Piece.BLACK;
					break;
				case 'w':
					curr_colour = Piece.WHITE;
					break;
				case ':':
					this.parseAttributes( str.slice( i + 1 ) );
					i = l;	// done.
					break;
				default:
					break;
			}
		}

		if ( this.cr === 0 )	// castle rights: if the positions of the pieces fit, assume castling legal, if nothing further specified.
		{
			if ( this.board[ Square.E1 ] === Piece.W_KING )
			{
				if ( this.board[ Square.H1 ] === Piece.W_ROOK )
					this.cr |= CastleRights.W_00;
				else if ( this.board[ Square.A1 ] === Piece.W_ROOK )
					this.cr |= CastleRights.W_000;
			}
			if ( this.board[ Square.E8 ] === Piece.B_KING )
			{
				if ( this.board[ Square.H8 ] === Piece.B_ROOK )
					this.cr |= CastleRights.B_00;
				else if ( this.board[ Square.A8 ] === Piece.B_ROOK )
					this.cr |= CastleRights.B_000;
			}
		}
	};

	parseAttributes( str )
	{
		//Log.Log( str );
		if ( str[ 0 ] === 'b' )
			this.sd = Side.BLACK;
	};

	canMakeNullMove()
	{
		return !this.isCheck();
	};

	makeNullMove()
	{
		return this.makeMove( new Move( 0, 0 ) );
	};

	unmakeNullMove()
	{
		return this.unmakeMove( new Move( 0, 0 ) );
	};

	// NH2020 Weshalb wird diese Funktion bei jedem Zug ca. 20 Mal aufgerufen?
	makeMove( _mv )
	{
		// console.trace("Make Move!");
		if ( !_mv )
			return;

		var undo = new Undo( this, _mv );

		++this.numPly;

		this.sd = Side.other( this.sd );
		var oldEP = this.ep;
		this.ep = Position.NOEP;

		if ( _mv.isNullMove() )
			return undo;

		//Always do...
		var pcMvd = this.board[ _mv.from ];
		var pcVct = this.board[ _mv.to ];

		this.board[ _mv.to ] = this.board[ _mv.from ];
		this.board[ _mv.from ] = Piece.NONE;

		// NH2020
		this.uniquePiecePositions[_mv.to] = this.uniquePiecePositions[_mv.from];
		this.uniquePiecePositions[_mv.from] = Piece.NONE;

		// rowFrom/rowTo
		var rFrom = Square.R( _mv.from );
		var rTo = Square.R( _mv.to );

		// columnFrom/columnTo
		var cFrom = Square.C( _mv.from );
		var cTo = Square.C( _mv.to );

		//Pawn special cases...
		if ( Piece.nominal( pcMvd ) === Piece.PAWN )
		{
			// Handle en passent
			if ( cTo !== cFrom && cTo === oldEP && pcVct === Piece.NONE )
			{
				pcVct = Piece.changeSide( pcMvd );

				var fldVct = Square.I( cTo, rFrom );
				this.board[ fldVct ] = Piece.NONE;

				// NH2021
				var pcVctUnique = this.uniquePiecePositions[fldVct];
				this.uniquePiecePositions[fldVct] = Piece.NONE;

				// NH2021
				// undo.setEP( pcVct, fldVct );
				undo.setEP( pcVct, fldVct, pcVctUnique );
			}

			// Promotion Zug
			if ( _mv.prom !== Piece.NONE )
			{
				var pcProm = Piece.make( _mv.prom, Piece.side( pcMvd ) );
				this.board[ _mv.to ] = pcProm;
			}

			// First pawn move with two steps. Set en passent possibility.
			if ( Math.abs( rFrom - rTo ) === 2 )
			{
				this.ep = cFrom;
			}
		}

		// King special cases
		if ( Piece.nominal( pcMvd ) === Piece.KING )
		{
			// Castle
			if ( Math.abs( cTo - cFrom ) === 2 )
			{
				var from2 = null;
				var to2 = null;

				if ( cTo === Square.C_G )
				{
					from2 = Square.I( Square.C_H, rFrom );
					to2 = Square.I( Square.C_F, rFrom );
				}
				else
				{
					from2 = Square.I( Square.C_A, rFrom );
					to2 = Square.I( Square.C_D, rFrom );
				}

				this.board[ to2 ] = this.board[ from2 ];
				this.board[ from2 ] = Piece.NONE;

				// NH2021
				let from2Unique = this.uniquePiecePositions[from2];
				this.uniquePiecePositions[to2] = this.uniquePiecePositions[from2];
				this.uniquePiecePositions[from2] = Piece.NONE;

				// NH2021
				// undo.setCastle( from2, to2 );
				undo.setCastle( from2, to2, from2Unique);
			}
		}

		// NH2021I Set Castling Rights
		if ( this.cr )
		{
			if ( this.cr & CastleRights.W_ALL )
			{
				if ( _mv.from === Square.E1 )
					this.cr &= ~CastleRights.W_ALL;
				else if ( _mv.from === Square.A1 || _mv.to === Square.A1 )
				{
					this.cr &= ~CastleRights.W_000;
				}
				else if ( _mv.from === Square.H1 || _mv.to === Square.H1 )
				{
					this.cr &= ~CastleRights.W_00;
				}
			}

			if ( this.cr & CastleRights.B_ALL )
			{
				if ( _mv.from === Square.E8 )
					this.cr &= ~CastleRights.B_ALL;
				else if ( _mv.from === Square.A8 || _mv.to === Square.A8 )
				{
					this.cr &= ~CastleRights.B_000;
				}
				else if ( _mv.from === Square.H8 || _mv.to === Square.H8 )
				{
					this.cr &= ~CastleRights.B_00;
				}
			}
		}

		return undo;
	};

	// NH2021 Muss diese Funktion so oft aufgerufen werden?
	// Aufrufe kommen wohl daher, dass die legalen Züge einer Position generiert werden
	// Dafür wird gecheckt, ob ein möglicher Zug ein Suicide Move wäre, indem makeMove aufgerufen und dann
	// rückgängig gemacht wird.
	unmakeMove( _mv, _undo )
	{

		if ( !_mv.isNullMove() )
		{
			this.board[ _mv.from ] = _undo.mvd;
			this.board[ _mv.to ] = Piece.NONE;

			this.board[ _undo.fldVct ] = _undo.vct;

			// NH2021
			this.uniquePiecePositions[_mv.from] = _undo.mvdUnique;
			this.uniquePiecePositions[_mv.to] = Piece.NONE;
			this.uniquePiecePositions[_undo.fldVct] = _undo.vctUnique;

			if ( _undo.to2 || _undo.from2 )
			{
				this.board[ _undo.from2 ] = this.board[ _undo.to2 ];
				this.board[ _undo.to2 ] = Piece.NONE;

				// NH2021
				this.uniquePiecePositions[_undo.from2] = this.uniquePiecePositions[_undo.to2];
				this.uniquePiecePositions[_undo.to2] = Piece.NONE;
			}
		}

		this.cr = _undo.cr;
		this.ep = _undo.ep;
		--this.numPly;
		this.sd = Side.other( this.sd );

	};

	generateLegals()
	{
		var arrMvs = this.generateSemiLegals();
		return this.filterLegals( arrMvs );
	};

	/**
  @param {number=} _pc opt
  */
	generateSemiLegals( _pc )
	{
		var arrRes = [];

		for ( var fld = 0; fld < this.board.length; ++fld )
		{
			var pc = this.board[ fld ];
			if ( pc === Piece.NONE )
				continue;

			if ( _pc && pc !== _pc )
				continue;

			var sd = Piece.side( pc );
			if ( sd !== this.sd )
				continue;

			this._generateSemiLegalsFrom( fld, pc, arrRes );
		}

		return arrRes;
	};

	generateSemiLegalsFrom( _from, _pc )
	{
		if ( !_pc )
			_pc = this.board[ _from ];

		var arrRes = [];

		this._generateSemiLegalsFrom( _from, _pc, arrRes );

		return arrRes;
	};

	generateLegalsFrom( _from, _pc )
	{
		var arrSemiLegals = this.generateSemiLegalsFrom( _from, _pc );
		return this.filterLegals( arrSemiLegals );
	};

	_generateSemiLegalsFrom( _fld, _pc, _arrRes )
	{
		switch ( _pc )
		{
			default:
				break;
			case Piece.W_PAWN:
			case Piece.B_PAWN:
				this.generatePawn( _pc, _fld, _arrRes );
				break;
			case Piece.W_KNIGHT:
			case Piece.B_KNIGHT:
				this.generateKnight( _pc, _fld, _arrRes );
				break;
			case Piece.W_BISHOP:
			case Piece.B_BISHOP:
				this.generateBishop( _pc, _fld, _arrRes );
				break;
			case Piece.W_ROOK:
			case Piece.B_ROOK:
				this.generateRook( _pc, _fld, _arrRes );
				break;
			case Piece.W_QUEEN:
			case Piece.B_QUEEN:
				this.generateQueen( _pc, _fld, _arrRes );
				break;
			case Piece.W_KING:
			case Piece.B_KING:
				this.generateKing( _pc, _fld, _arrRes );
				break;
		}
	};

	generateSemiLegalsTo( _to, _pc )
	{
		//Temporär...
		var arrSemiLegals = this.generateSemiLegals( _pc );
		return arrSemiLegals.filter(
			function( _mv )
			{
				return _mv.to === _to;
			}
		);
	};




	static g_arrKnightMvs =
		[
			//dc,dr
			[ 2, 1 ],
			[ 1, 2 ],
			[ -1, 2 ],
			[ -2, 1 ],

			[ -2, -1 ],
			[ -1, -2 ],
			[ 1, -2 ],
			[ 2, -1 ]
		];

	static g_arrKingMvs =
		[
			//dc,dr
			[ 1, 0 ],
			[ 1, 1 ],
			[ 0, 1 ],
			[ -1, 1 ],

			[ -1, 0 ],
			[ -1, -1 ],
			[ 0, -1 ],
			[ 1, -1 ]
		];

	static g_arrBishopMvs =
		[
			//dc,dr
			[ 1, 1 ],
			[ -1, 1 ],

			[ -1, -1 ],
			[ 1, -1 ]
		];

	static g_arrRookMvs =
		[
			//dc,dr
			[ 1, 0 ],
			[ 0, 1 ],

			[ -1, 0 ],
			[ 0, -1 ]
		];

	generateKnight( _pc, _fld, _arrRes )
	{
		this.generate( _pc, _fld, Position.g_arrKnightMvs, false, _arrRes );
	};

	generateKing( _pc, _fld, _arrRes )
	{
		this.generate( _pc, _fld, Position.g_arrKingMvs, false, _arrRes );

		//Rochaden...
		if ( _pc === Piece.W_KING )
		{
			if ( !( this.cr & CastleRights.W_ALL ) )
				return;

			if ( this.isAttacked( Square.E1, Side.BLACK ) )
				return;

			if ( this.cr & CastleRights.W_00 )
			{
				if ( this.board[ Square.F1 ] === Piece.NONE &&
					this.board[ Square.G1 ] === Piece.NONE )
				{
					if ( !this.isAttacked( Square.F1, Side.BLACK )
						&& !this.isAttacked( Square.G1, Side.BLACK )
					)
					{
						_arrRes.push( Move.g_mvW00 );
					}
				}
			}

			if ( this.cr & CastleRights.W_000 )
			{
				if ( this.board[ Square.D1 ] === Piece.NONE &&
					this.board[ Square.C1 ] === Piece.NONE &&
					this.board[ Square.B1 ] === Piece.NONE )
				{
					if ( !this.isAttacked( Square.D1, Side.BLACK )
						&& !this.isAttacked( Square.C1, Side.BLACK )
					)
					{
						_arrRes.push( Move.g_mvW000 );
					}
				}
			}
		}

		if ( _pc === Piece.B_KING )
		{
			if ( !( this.cr & CastleRights.B_ALL ) )
				return;

			if ( this.isAttacked( Square.E8, Side.WHITE ) )
				return;

			if ( this.cr & CastleRights.B_00 )
			{
				if ( this.board[ Square.F8 ] === Piece.NONE &&
					this.board[ Square.G8 ] === Piece.NONE )
				{
					if ( !this.isAttacked( Square.F8, Side.WHITE )
						&& !this.isAttacked( Square.G8, Side.WHITE )
					)
					{
						_arrRes.push( Move.g_mvB00 );
					}
				}
			}

			if ( this.cr & CastleRights.B_000 )
			{
				if ( this.board[ Square.D8 ] === Piece.NONE &&
					this.board[ Square.C8 ] === Piece.NONE &&
					this.board[ Square.B8 ] === Piece.NONE )
				{
					if ( !this.isAttacked( Square.D8, Side.WHITE )
						&& !this.isAttacked( Square.C8, Side.WHITE )
					)
					{
						_arrRes.push( Move.g_mvB000 );
					}
				}
			}
		}


	};

	generateRook( _pc, _fld, _arrRes )
	{
		this.generate( _pc, _fld, Position.g_arrRookMvs, true, _arrRes );
	};

	generateBishop( _pc, _fld, _arrRes )
	{
		this.generate( _pc, _fld, Position.g_arrBishopMvs, true, _arrRes );
	};

	generateQueen( _pc, _fld, _arrRes )
	{
		this.generateRook( _pc, _fld, _arrRes );
		this.generateBishop( _pc, _fld, _arrRes );
	};

	generatePawn( _pc, _fld, _arrRes )
	{
		var rDir = _pc === Piece.W_PAWN ? +1 : -1;
		var rProm = _pc === Piece.W_PAWN ? Square.R_8 : Square.R_1;

		var cFrom = Square.C( _fld );
		var rFrom = Square.R( _fld );

		var sdMe = Piece.side( _pc );

		var rTo = rFrom + rDir;
		var fldOne = Square.I( cFrom, rTo );

		var promMv = rTo === rProm;

		if ( this.board[ fldOne ] === Piece.NONE )
		{
			if ( promMv )
			{
				_arrRes.push( new Move( _fld, fldOne, Piece.QUEEN ) );
				_arrRes.push( new Move( _fld, fldOne, Piece.ROOK ) );
				_arrRes.push( new Move( _fld, fldOne, Piece.BISHOP ) );
				_arrRes.push( new Move( _fld, fldOne, Piece.KNIGHT ) );
			}
			else
			{
				var mvSingle = new Move( _fld, fldOne );
				_arrRes.push( mvSingle );
			}

			if ( ( _pc === Piece.W_PAWN && rFrom === Square.R_2 ) ||
				( _pc === Piece.B_PAWN && rFrom === Square.R_7  ))
			{
				var fldDouble = Square.I( cFrom, rFrom + 2 * rDir );

				if ( this.board[ fldDouble ] === Piece.NONE )
				{
					var mvDouble = new Move( _fld, fldDouble );

					_arrRes.push( mvDouble );
				}
			}
		}

		for ( var cDir = -1; cDir <= 1; cDir += 2 )
		{
			var cTo = cFrom + cDir;
			var rTakeTo = rFrom + rDir;

			if ( !Square.isLegal( cTo, rTakeTo ) )
				continue;

			var fldTo = Square.I( cTo, rTakeTo );
			var pcVct = this.board[ fldTo ];

			if ( pcVct !== Piece.NONE && Piece.side( pcVct ) !== sdMe )
			{
				if ( promMv )
				{
					_arrRes.push( new Move( _fld, fldTo, Piece.QUEEN ) );
					_arrRes.push( new Move( _fld, fldTo, Piece.ROOK ) );
					_arrRes.push( new Move( _fld, fldTo, Piece.BISHOP ) );
					_arrRes.push( new Move( _fld, fldTo, Piece.KNIGHT ) );
				}
				else
				{
					var mvKill = new Move( _fld, fldTo );
					_arrRes.push( mvKill );
				}
			}

			//ep?
			if ( pcVct === Piece.NONE && cTo === this.ep )
			{
				var rEPFrom = sdMe === Side.WHITE ? Square.R_5 : Square.R_4;
				var fldEPVct = Square.I( cTo, rEPFrom );
				var pcEPVct = this.board[ fldEPVct ];

				if ( rFrom === rEPFrom && pcEPVct === Piece.changeSide( _pc ) )
				{
					var mvEPKill = new Move( _fld, fldTo );

					_arrRes.push( mvEPKill );
				}
			}
		}
	};

	generate( _pc, _fld, _arrMvs, _long, _arrRes )
	{
		var sdMe = Piece.side( _pc );
		var rFrom = Square.R( _fld );
		var cFrom = Square.C( _fld );

		for ( var inx = 0, len = _arrMvs.length; inx < len; ++inx )
		{
			var dshift = _arrMvs[ inx ];

			var cTo = cFrom;
			var rTo = rFrom;

			var contin = _long;

			do
			{
				cTo += dshift[ 0 ];
				rTo += dshift[ 1 ];

				if ( !Square.isLegal( cTo, rTo ) )
					break;

				var to = Square.I( cTo, rTo );

				var pcVct = this.board[ to ];
				if ( pcVct !== Piece.NONE )
				{
					if ( Piece.side( pcVct ) === sdMe )
						break;
					else
						contin = false;
				}


				var mvNew = new Move( _fld, to );

				_arrRes.push( mvNew );
			} while ( contin );
		}

	};

	filterLegals( _arrMvs )
	{
		var pos = this;
		return _arrMvs.filter
			(
				function( _mv )
				{
					return !pos.isSuicideMove( _mv );
				}
			);
	};

	isProm( _from, _to )
	{
		var sd = this.sd;
		var pcMvd = this.board[ _from ];
		return pcMvd === Piece.make( Piece.PAWN, sd ) &&
			Square.R( _to ) === Square.getPromRow( sd );
	};

	isLegalFromTo( _from, _to )
	{
		var mvDummy = new Move( _from, _to );

		if ( this.isProm( _from, _to ) )
		{
			//var sd = this.sd;
			mvDummy.prom = Piece.QUEEN; //Piece.make( Piece.QUEEN, sd );
		}

		return this.isLegalMove( mvDummy );
	};

	isLegalMove( _mv )
	{
		return ( _mv != null ) &&
			( _mv.isNullMove() || this.isSemiLegalMove( _mv ) ) &&
			!this.isSuicideMove( _mv );
	};

	isSemiLegalMove( _mv )
	{
		//Fast checks...
		if ( _mv === null )
			return false;

		var from = _mv.from;
		var to = _mv.to;

		if ( from === to )
			return false;

		var pcMvd = this.board[ from ];

		if ( pcMvd === Piece.NONE )
			return false;
		if ( Piece.side( pcMvd ) !== this.sd )
			return false;
		var pcVct = this.board[ to ];

		if ( pcVct !== Piece.NONE &&
			Piece.side( pcMvd ) === Piece.side( pcVct ) )
			return false;

		//Nun die Muhesame...
		var arrSemiLegals = this.generateSemiLegalsFrom( from, pcMvd );

		return arrSemiLegals.some
			(
				_mv.equals.bind( _mv )
			);

	};

	// NH2021I Checks if the move would move the king to an attacked square
	// NH2021I Do we really need to call makeMove every time here? This happens a lot!
	// NH2021I TODO Maybe check if the king is to be moved. Only then makeMove. Or does this happen already?
	isSuicideMove( _mv )
	{
		var undo = this.makeMove( _mv );
		var res = this.isSuicidePos();
		this.unmakeMove( _mv, undo );
		return res;
	};


	initFields()
	{
		this.board = new Array( Board.SIZE );

		// NH2020 Added this field to maintain pieces in animations
		this.uniquePiecePositions = new Array(Board.SIZE);

		// console.log("Init Position");

		this.initEmpty();
	};

	initEmpty()
	{
		for ( var i = 0; i < 64; i++ )
		{
			this.board[ i ] = Piece.NONE;
		}
		this.sd = Side.WHITE;
		this.cr = 0;
		this.ep = Position.NOEP;
		this.lastKillOrPawn = 0;
		this.numPly = 0;
	};

	toNormalizedFEN()
	{
		var fen = "";
		for ( var r = Square.R_8; r >= Square.R_1; --r )
		{
			var cntEmpty = 0;
			for ( var c = Square.C_A; c <= Square.C_H; ++c )
			{
				var inx = Square.I( c, r );
				var pc = this.board[ inx ];

				if ( pc === Piece.NONE )
				{
					++cntEmpty;
					continue;
				}

				if ( cntEmpty > 0 )
					fen += cntEmpty;

				cntEmpty = 0;

				fen += Piece.toString( pc );
			}
			if ( cntEmpty > 0 )
				fen += cntEmpty;

			if ( r > Square.R_1 )
				fen += "/";
		}


		//Side To Move
		fen += " ";
		fen += Side.toString( this.sd );
		//Castle Rights
		fen += " ";
		fen += CastleRights.toString( this.cr );
		//EP
		fen += " ";
		if ( this.ep === Position.NOEP )
			fen += "-";
		else
		{
			var fldEP = this.getEPField();
			fen += Square.toString( fldEP );
		}
		return fen;

	};

	toFEN()
	{
		var fen = this.toNormalizedFEN();
		fen += " 0 ";

		var mvNum = this.getMoveNum();
		fen += mvNum;

		return fen;
	};

	toString = this.toFEN;

	toDescriptiveString()
	{
		var cPieceNames = [ " ", "K", "Q", "N", "B", "R", "P" ];
		var diaString = "";
		var thePieces = [], ptrPieces = [];

		for ( var i = 0; i < 16; i++ )
		{
			thePieces.push( [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0 ] );
			ptrPieces[ i ] = 0;
		}

		for ( let i = 0; i < 64; i++ )
		{
			var p = this.board[ i ] & 15;
			if ( p && ptrPieces[ p ] < 16 )
				thePieces[ p ][ ptrPieces[ p ]++ ] = i;
		}
		diaString += 'w';
		for ( let i = 1; i < 7; i++ )
		{
			if ( ptrPieces[ i ] > 0 )
			{
				if ( i > 1 )
					diaString += ',';
				var cPiece = cPieceNames[ i ];
				diaString += String( cPiece );
				for ( var ix = 0; ix < ptrPieces[ i ]; ix++ )
				{
					let p = thePieces[ i ][ ix ];
					//diaString += char( 'a' + ( p >> 3 ) );
					//diaString += char( '1' + ( p & 7 ) );
					diaString += Square.toString( p );
					if ( ix < ptrPieces[ i ] - 1 )
						diaString += ',';
				}
			}
		}
		diaString += "/b";
		for ( let i = 9; i < 15; i++ )
		{
			if ( ptrPieces[ i ] > 0 )
			{
				if ( i > 9 )
					diaString += ',';
				let cPiece = cPieceNames[ i & 7 ];
				diaString += String( cPiece );
				for ( let ix = 0; ix < ptrPieces[ i ]; ix++ )
				{
					let p = thePieces[ i ][ ix ];
					diaString += Square.toString( p );
					//diaString += char( 'a' + ( p >> 3 ) );
					//diaString += char( '1' + ( p & 7 ) );
					if ( ix < ptrPieces[ i ] - 1 )
						diaString += ',';
				}
			}
		}

		return diaString;
	};

	getPiece( _inx )
	{
		return this.board[ _inx ];
	};

	getPieces()
	{
		var arrRes = [];
		for ( var inx = 0; inx < this.board.length; ++inx )
		{
			var pc = this.board[ inx ];
			if ( pc === Piece.NONE )
				continue;

			var newPiece = new PieceData( pc, inx );

			arrRes.push( newPiece );
		}

		return arrRes;
	};

	countPieces( p )
	{
		var cnt = 0;
		for ( var inx = 0; inx < this.board.length; ++inx )
		{
			if ( this.board[ inx ] === p )
				cnt++;
		}
		return cnt;
	}

	countAllPieces( black )
	{
		var cnt = 0;
		for ( var inx = 0; inx < this.board.length; ++inx )
		{
			if ( this.board[ inx ] )
			{
				if ( ( ( this.board[ inx ] & Piece.SIDE_MASK ) !== 0 ) === black )
					cnt++;
			}

		}
		return cnt;
	}

	getSideToMove()
	{
		return this.sd;
	};

	getBoard()
	{
		return this.board;
	}

	isWTM()
	{
		return this.sd === Side.WHITE;
	};

	isBTM()
	{
		return this.sd === Side.BLACK;
	};

	setBTM( b )
	{
		this.sd = b ? Side.BLACK : Side.WHITE;
	};

	getBTM = this.isBTM;

	setCastleRights( cBits )
	{
		this.cr = cBits;
	};

	getEPFile()
	{
		if ( this.ep === Position.NOEP )
			return -1;
		return this.ep % 8;
	}

	getCPPEPFile()
	{
		if ( this.ep === Position.NOEP )
			return 0;
		return ( this.ep % 8 ) + 1;
	}

	setCPPEPFile( cppep )	/* 1..8 */
	{
		if ( cppep <= 0 )
			this.ep = Position.NOEP;
		else
			this.ep = cppep - 1;
	}

	getEPField()
	{
		if ( this.ep === Position.NOEP )
			return -1;
		var rEP = this.sd === Side.WHITE ? Square.R_6 : Square.R_3;
		return Square.I( this.ep, rEP );
	};

	getEPCol()
	{
		return this.ep;
	};

	getEPVictimField()
	{
		if ( this.ep === Position.NOEP )
			return -1;
		var rEP = this.sd === Side.WHITE ? Square.R_5 : Square.R_4;
		return Square.I( this.ep, rEP );
	};

	getCastleRights()
	{
		return this.cr;
	};

	getPossibleCastleRights()
	{
		var cr = 0;
		if ( this.board[ Square.E1 ] === Piece.W_KING )
		{
			if ( this.board[ Square.A1 ] === Piece.W_ROOK )
				cr |= CastleRights.W_000;

			if ( this.board[ Square.H1 ] === Piece.W_ROOK )
				cr |= CastleRights.W_00;
		}
		if ( this.board[ Square.E8 ] === Piece.B_KING )
		{
			if ( this.board[ Square.A8 ] === Piece.B_ROOK )
				cr |= CastleRights.B_000;

			if ( this.board[ Square.H8 ] === Piece.B_ROOK )
				cr |= CastleRights.B_00;
		}
		return cr;
	};

	getPossibleEP()
	{
		var arrRes = [];
		var r = this.sd === Side.WHITE ? Square.R_5 : Square.R_4;

		var pcKillPawn = Piece.make( Piece.PAWN, this.sd );
		var pcEPPawn = Piece.changeSide( pcKillPawn );

		for ( var c = Square.C_A; c <= Square.C_H; ++c )
		{
			var fld = Square.I( c, r );
			if ( this.board[ fld ] !== pcEPPawn )
				continue;
			if ( ( c > Square.C_A && this.board[ Square.I( c - 1, r ) ] === pcKillPawn )
				||( c < Square.C_H && this.board[ Square.I( c + 1, r ) ] === pcKillPawn ))
				arrRes.push( c );


		}

		return arrRes;
	};

	isAttacked( _fld, _sd )
	{
		var arrAttacks = this.getAttacksTo( _fld, _sd );
		return arrAttacks.length > 0;
	};

	getAttacksTo( _fld, _sd )
	{
		var arrRes = [];
		this._getAttacksTo( _fld, _sd, arrRes );
		return arrRes;
	};

	_getAttacksTo( _fld, _sd, _arrRes )
	{
		this.getPawnAttacksTo( _fld, _sd, _arrRes );
		this.getKnightAttacksTo( _fld, _sd, _arrRes );
		this.getKingAttacksTo( _fld, _sd, _arrRes );
		this.getRangedAttacksTo( _fld, _sd, _arrRes );
	};


	static getIsPiece( _pc1, _pc2 )
	{
		return function( _pc ) { return ( _pc === _pc1 ) || (_pc2 && _pc === _pc2 ); }
	}

	getKnightAttacksTo( _fld, _sd, _arrRes )
	{
		this.getAttacks( _fld, _sd, Position.g_arrKnightMvs,
			Position.getIsPiece( Piece.make( Piece.KNIGHT, _sd ) ),
			false,
			_arrRes );
	};

	getKingAttacksTo( _fld, _sd, _arrRes )
	{
		this.getAttacks( _fld, _sd, Position.g_arrKingMvs,
			Position.getIsPiece( Piece.make( Piece.KING, _sd ) ),
			false,
			_arrRes );
	};

	getQueenAttacksTo( _fld, _sd, _arrRes )
	{
		this.getAttacks( _fld, _sd, Position.g_arrBishopMvs,
			Position.getIsPiece( Piece.make( Piece.QUEEN, _sd ) ),
			true,
			_arrRes );
		this.getAttacks( _fld, _sd, Position.g_arrRookMvs,
			Position.getIsPiece( Piece.make( Piece.QUEEN, _sd ) ),
			true,
			_arrRes );
	};

	//Ruhige Bauernzüge...
	getPawnMovesTo( _fld, _sd, _arrRes )
	{
		if ( this.board[ _fld ] !== Piece.NONE )
			return;

		var rTo = Square.R( _fld );
		var cTo = Square.C( _fld );

		if ( _sd === Side.WHITE &&
			( rTo === Square.R_1 ||
				rTo === Square.R_2 ) )
			return;
		if ( _sd === Side.BLACK &&
			( rTo === Square.R_8 ||
				rTo === Square.R_7 ) )
			return;

		var rDir = _sd === Side.WHITE ? -1 : +1;

		var pc = Piece.make( Piece.PAWN, _sd );
		var rDbl = _sd === Side.WHITE ? Square.R_4 : Square.R_5;

		var fldOne = Square.I( cTo, rTo + rDir );

		if ( this.board[ fldOne ] === pc )
		{
			_arrRes.push( fldOne );
			return;
		}
		else if ( rTo === rDbl && this.board[ fldOne ] === Piece.NONE )
		{
			var fldDbl = Square.I( cTo, rTo + 2 * rDir );
			if ( this.board[ fldDbl ] === pc )
			{
				_arrRes.push( fldDbl );
			}
		}

	};

	getBishopAttacksTo( _fld, _sd, _arrRes )
	{
		this.getAttacks( _fld, _sd, Position.g_arrBishopMvs,
			Position.getIsPiece( Piece.make( Piece.BISHOP, _sd ) ),
			true,
			_arrRes );
	};

	getRookAttacksTo( _fld, _sd, _arrRes )
	{
		this.getAttacks( _fld, _sd, Position.g_arrRookMvs,
			Position.getIsPiece( Piece.make( Piece.ROOK, _sd ) ),
			true,
			_arrRes );
	};

	getRangedAttacksTo( _fld, _sd, _arrRes )
	{
		this.getAttacks( _fld, _sd, Position.g_arrRookMvs,
			Position.getIsPiece( Piece.make( Piece.ROOK, _sd ), Piece.make( Piece.QUEEN, _sd ) ),
			true,
			_arrRes );

		this.getAttacks( _fld, _sd, Position.g_arrBishopMvs,
			Position.getIsPiece( Piece.make( Piece.BISHOP, _sd ), Piece.make( Piece.QUEEN, _sd ) ),
			true,
			_arrRes );
	};



	static g_arrWhPawnAttks =
		[
			//dc,dr
			[ 1, 1 ],
			[ -1, 1 ]
		];

	static g_arrBlPawnAttks =
		[
			//dc,dr
			[ 1, -1 ],
			[ -1, -1 ]
		];



	getPawnAttacksTo( _fld, _sd, _arrRes )
	{
		if ( _sd === Side.WHITE )
			this.getAttacks( _fld, _sd, Position.g_arrWhPawnAttks,
				Position.getIsPiece( Piece.W_PAWN ),
				false,
				_arrRes );
		else
			this.getAttacks( _fld, _sd, Position.g_arrBlPawnAttks,
				Position.getIsPiece( Piece.B_PAWN ),
				false,
				_arrRes );
	};

	getAttacks( _fld, _sd, _arrMvs, _func, _long, _arrRes )
	{
		var rTo = Square.R( _fld );
		var cTo = Square.C( _fld );

		for ( var inx = 0, len = _arrMvs.length; inx < len; ++inx )
		{
			var dshift = _arrMvs[ inx ];

			var cFrom = cTo;
			var rFrom = rTo;

			var contin = _long;

			do
			{
				cFrom -= dshift[ 0 ];
				rFrom -= dshift[ 1 ];

				if ( !Square.isLegal( cFrom, rFrom ) )
					break;

				var from = Square.I( cFrom, rFrom );

				var pcAttkr = this.board[ from ];

				if ( pcAttkr !== Piece.NONE )
				{
					if ( Piece.side( pcAttkr ) !== _sd )
						break;
					else
						contin = false;

					if ( _func( pcAttkr ) )
						_arrRes.push( from );
				}
			} while ( contin );
		}
	};

	getKingsPos( _sd )
	{
		var pcKing = Piece.make( Piece.KING, _sd );
		return this.board.indexOf( pcKing );
	};

	getPiecePos( piece, _sd )
	{
		var pcKing = Piece.make( piece, _sd );
		return this.board.indexOf( pcKing );
	};

	isCheck()
	{
		var fldTo = this.getKingsPos( this.sd );
		var sdChecker = Side.other( this.sd );

		return this.isAttacked( fldTo, sdChecker );
	};

	// NH2021I Check if my king would be attacked after I made a move
	isSuicidePos()
	{
		var sdVct = Side.other( this.sd );
		var fldTo = this.getKingsPos( sdVct );

		return this.isAttacked( fldTo, this.sd );
	};

	isMate()
	{
		if ( !this.isCheck() )
			return false;
		return !this.hasLegals();
	};

	isStaleMate()
	{
		if ( this.isCheck() )
			return false;
		return !this.hasLegals();
	};

	swapSide() 
	{
		if ( !this.isCheck() && !this.isMate() && !this.isStaleMate() )
		{
			this.makeMove( new Move() );
			return true;
		}
		return false;
	}

	hasLegals()
	{
		var arrLegals = this.generateLegals();
		return arrLegals.length > 0;
	};

	getVictim( _mv )
	{
		var pcVct = this.board[ _mv.to ];
		if ( pcVct !== Piece.NONE )
			return pcVct;

		var pcMvd = this.board[ _mv.from ];

		if ( Piece.nominal( pcMvd ) === Piece.PAWN
			&& Square.C( _mv.from ) !== Square.C( _mv.to ) )
		{
			var fldEP = Square.I( Square.C( _mv.to ), Square.R( _mv.from ) );
			return this.board[ fldEP ];
		}
		return Piece.NONE;
	}

	isTake( _mv )
	{
		return this.getVictim( _mv ) !== Piece.NONE;
	};

	calcNeed( _mv )
	{
		var from = _mv.from;
		var pcMvd = this.board[ from ];

		var res = { needCol: false, needRow: false };

		var pcNom = Piece.nominal( pcMvd );

		if ( pcNom === Piece.PAWN ||
			pcNom === Piece.KING )
			return res;

		//Vielleicht ist es der einzige seiner Gattung

		var arrCoCandidates = [];

		for ( var inx = 0; inx < Board.SIZE; ++inx )
		{
			var pc = this.board[ inx ];
			if ( pc === pcMvd && inx !== from )
				arrCoCandidates.push( inx );
		}

		if ( !arrCoCandidates.length )
			return res;

		if ( pcNom === Piece.BISHOP )
		{
			var unique = arrCoCandidates.every
				(
					function( _fld )
					{
						return Square.isDark( _fld ) !== Square.isDark( from );
					}
				);

			if ( unique )
				return res;
		}

		var uniqueCol = true;
		var uniqueRow = true;
		var cocand = false;
		//Nun ja...
		for ( var inxCand = 0, len = arrCoCandidates.length; inxCand < len; ++inxCand )
		{
			var cofrom = arrCoCandidates[ inxCand ];
			var arrMvs = this.generateSemiLegalsFrom( cofrom, pcMvd );

			if ( arrMvs.some( function( _mvElem ) { return _mvElem.to === _mv.to; } ) )
			{
				cocand = true;

				uniqueCol &= Square.C( cofrom ) !== Square.C( from );
				uniqueRow &= Square.R( cofrom ) !== Square.R( from );
			}
		}

		if ( cocand )
		{
			res.needCol = uniqueCol || !uniqueRow;
			res.needRow = !uniqueCol;
		}

		return res;
	};

	preCalcCtx( _mv )
	{
		this.preSetMinCtx( _mv );

		if ( _mv.isNullMove() )
			return;

		var need = this.calcNeed( _mv );

		_mv.setNeedCol( need.needCol );
		_mv.setNeedRow( need.needRow );

	};

	//HACK, wenn needCol & needRow garantiert nicht benötigt werden...
	preSetMinCtx( _mv )
	{
		if ( _mv.isNullMove() )
		{
			_mv.setMoved( Piece.make( Piece.PAWN, this.sd ) );
			_mv.setVictim( Piece.NONE );
		}
		else
		{
			_mv.setMoved( this.board[ _mv.from ] );
			var pcVct = this.getVictim( _mv );
			_mv.setVictim( pcVct );
			_mv.setIsCastling();
		}
		// "#IFDEBUG"
		// _mv.debug = Square.toString( _mv.from ) + "-" + Square.toString( _mv.to );
		// "#ENDIF"
	};

	postCalcCtx( _mv, _canBeLast )
	{
		if ( _mv.isNullMove() )
		{
			_mv.setCheck( false );
			return;
		}

		var check = this.isCheck();
		_mv.setCheck( check );
		if ( check && _canBeLast )
			_mv.setMate( !this.hasLegals() );
	};

	read( _buf )
	{
		// NH2021 TODO: Create unique piece IDs on position Read.
		console.log("Read Position");

		this.board = _buf.readByteArray( Board.SIZE );

		this.sd = _buf.readInt();
		this.ep = _buf.readByte() - 1;
		this.cr = _buf.readByte();

		this.numPly = 0;
	};

	write( _buf )
	{
		_buf.writeByteArray( this.board, Board.SIZE );

		_buf.writeInt( this.sd );
		_buf.writeByte( this.ep + 1 );
		_buf.writeByte( this.cr );
		//_buf.writeShort(this.numPly);
	};

	copyTo( newPosition )
	{
		newPosition.sd = this.sd;
		newPosition.ep = this.ep;
		newPosition.cr = this.cr;
		for ( var n = 0; n < 64; n++ )
			newPosition.board[ n ] = this.board[ n ];
	};

	static packBoard( _board )
	{
		var arrPacked = new Array( 32 );
		for ( var inx = 0, len = arrPacked.length; inx < len; ++inx )
			arrPacked[ inx ] = _board[ 2 * inx ] | ( _board[ 2 * inx + 1 ] << 4 );
		return arrPacked;
	};

	static unpackBoard( _arrPacked )
	{
		var board = new Array( _arrPacked.length * 2 );
		for ( var inx = 0, len = _arrPacked.length; inx < len; ++inx )
		{
			board[ 2 * inx ] = _arrPacked[ inx ] & 0xF;
			board[ 2 * inx + 1 ] = _arrPacked[ inx ] >> 4;
		}
		return board;
	};

	fillSquaresOfSide( squareSet, side )
	{
		for ( var s = 0; s < this.board.length; s++ )
		{
			if ( Piece.side( this.board[ s ] ) === side )
			{
				squareSet[ s ] = this.board[ s ];
			}
		}
	};

	setEquals( other )
	{
		for ( var s = 0; s < this.board.length; s++ )
		{
			if ( this.board[ s ] !== other.board[ s ] )
				return false;
		}

		if ( this.sd !== other.sd )
			return false;
		if ( this.ep !== other.ep )
			return false;
		if ( this.cr !== other.cr )
			return false;

		return true;
	};

	setGreater( other )
	{
		for ( var s = 0; s < this.board.length; s++ )
		{
			if ( this.board[ s ] !== other.board[ s ] )
				return this.board[ s ] > other.board[ s ];
		}

		if ( this.sd !== other.sd )
			return this.sd > other.sd;
		if ( this.ep !== other.ep )
			return this.ep > other.ep
		if ( this.cr !== other.cr )
			return this.cr > other.cr;

		return false;
	};

	dump( fnLog )
	{
		var b = new Board();
		b.copyFrom( this.board );
		b.toDebugStrs().forEach( fnLog !== undefined ? fnLog : function( sLine ) 
		{ console.log( sLine ); 
		} );
		var flags = "s=" + this.sd + ", ep=" + this.ep + ", cr=" + this.cr;
		if ( fnLog !== undefined )
			fnLog( flags );
		else
			console.log( flags );
	};

	isLegal()
	{
		//SOME TEST FOR LEGAL POS...
		var arrPieces = this.getPieces();
		if ( arrPieces.length > 32 )
			return false;
		if ( arrPieces.length < 2 )
			return false;

		var arrCnts = new Array( Piece.B_PAWN + 1 );
		for ( let i = 0; i < arrCnts.length; ++i )
			arrCnts[ i ] = 0;

		for ( let i = 0; i < arrPieces.length; ++i )
		{
			var pcd = arrPieces[ i ];
			++arrCnts[ pcd.piece ];

			if ( Piece.nominal( pcd.piece ) === Piece.PAWN )
			{
				var r = Square.R( pcd.field );
				if ( r === Square.R_1 || r === Square.R_8 )
					return false;
			}
		}

		if ( arrCnts[ Piece.W_KING ] !== 1 )
			return false;
		if ( arrCnts[ Piece.B_KING ] !== 1 )
			return false;

		if ( arrCnts[ Piece.W_PAWN ] > 8 )
			return false;
		if ( arrCnts[ Piece.B_PAWN ] > 8 )
			return false;


		return true;
	};

	isBoardEqual( board )
	{
		if ( board.length !== this.board.length )
			return false;

		for ( var i = 0; i < this.board.length; i++ )
		{
			if ( this.board[ i ] !== board[ i ] )
				return false;
		}

		return true;
	};

	findSquaresOfPiece( piece )
	{
		var sqs = [];
		for ( var i = 0; i < this.board.length; i++ )
		{
			if ( this.board[ i ] === piece )
				sqs.push( i );
		}
		return sqs;
	};

	static fromValues( board, side, castleRights, epFile )
	{
		var position = new Position();
		board.copyTo( position.board );
		position.sd = side;
		position.setCastleRights( castleRights );
		position.setCPPEPFile( epFile );
		return position;
	}
}

export class PieceData
{
	constructor( _pc, _fld )
	{
		this.piece = _pc;
		this.field = _fld;
	}

	toString()
	{
		var pcNom = Piece.nominal( this.piece );
		return Piece.toString( pcNom ) + Square.toString( this.field );
	}

	equals( _other )
	{
		return this.piece === _other.piece &&
			this.field === _other.field;
	};
}