
import React, { useContext, useEffect, useReducer } from 'react';

import { CBTable } from "CBReact/Components/Table/CBTable"
import { TableSort, TableReducer, TableData } from "CBReact/Components/Table/TableUtils";
import { CBButtonBadge } from "CBReact/Components/DesignElements/Buttons/ButtonBadge/CBButtonBadge";
import { SIDENAV_RENDERMODES } from "CBReact/Components/NavBars/CBNavBarSide";
import { SideNavContext, LocalizationContext, LoginContext, TeraBrainLobbyContext } from 'CBReact/Contexts/CBOnlineDBContext';
import { LOGIN_MODES } from "CBReact/Components/Account/AccountUtils";
import { checkPropsAndRenderComponent } from 'CBReact/Utils/DebugUtils';

export function CBEngineTable(props) 
{
    const changeSideNav = useContext(SideNavContext).changeSideNav;
    const loginStatus = useContext(LoginContext).loginStatus;
    const loc = useContext(LocalizationContext).localization;

    const searchPositionInfo = useContext(TeraBrainLobbyContext).searchPositionInfo;
    const engineTableContent = useContext(TeraBrainLobbyContext).engineTableContent;
    const engineTableIsLoading = useContext(TeraBrainLobbyContext).engineTableIsLoading;
    const teraBrainLobbyIsConnected = useContext(TeraBrainLobbyContext).isConnected;

    const engineTableData = new TableData({
        selectedHeader: -1,
        selectedRow: -1,
        header: ["Eval", "Variation", "Depth", "Engine"],
        flexGrow: [1, 2, 1, 2],
        flexGrowFallback: [1, 2, 1, 2],
        sortingMethods: [TableSort.numbers, TableSort.move, TableSort.numbers, TableSort.alphabet],
        removeOnResize: [],
        removeAtSize: [], 
        renderFancy: { 1: function () { return <CBButtonBadge size="small" onClick={handleLoginClick}>Anmelden</CBButtonBadge> }},
    });

    const [engineTable, engineTableDispatch] = useReducer(TableReducer, engineTableData);

    useEffect(() => {
        if (engineTableContent.length === 0)
            searchPositionInfo(props.boardPosition, props.startPosition);
            // searchPositionInfo(props.gameKernel.getCurPos(), props.gameKernel.game.getStartPos());
    }, [teraBrainLobbyIsConnected]);

    useEffect(() => {
        engineTableDispatch({ type: 'CONTENT', newContent: engineTableContent });
        engineTableDispatch({ type: 'SORT', columnIndex: 0 });
        engineTableDispatch({ type: 'SORT', columnIndex: 0 });
    }, [engineTableContent]);

    useEffect(() => {
            searchPositionInfo(props.boardPosition, props.startPosition);
    }, [props.boardPosition])

    useEffect(() => {
        if (loginStatus === LOGIN_MODES.guest)
        {
            let newRenderFancy = {
                1: function () { return <CBButtonBadge size="small" onClick={handleLoginClick}>{loc.strings.LOGIN}</CBButtonBadge> }
            };
            engineTableDispatch({type: 'RENDERFANCY', newRenderFancy});
        }
        else if (loginStatus === LOGIN_MODES.free || loginStatus === LOGIN_MODES.standard || loginStatus === LOGIN_MODES.premium)
        {
            let newRenderFancy = {};
            engineTableDispatch({type: 'RENDERFANCY', newRenderFancy});
        }
    }, [loginStatus, loc]);

    function handleInfoLineClick (e, rowInfo)
    {
        e.preventDefault();
    }

    function handleLoginClick (e)
    {
        e.stopPropagation();
        changeSideNav(true, SIDENAV_RENDERMODES.login);
    }

    function renderCBEngineTable ()
    {
        return (
                <CBTable tableData={engineTable} 
                tableDispatch={engineTableDispatch} 
                onEntryClick={handleInfoLineClick} 
                fixedHeight={82}
                isLoading={engineTableIsLoading}/>
        );
    }

    return checkPropsAndRenderComponent([props.boardPosition, props.startPosition, changeSideNav, loginStatus, loc, searchPositionInfo, engineTableContent], renderCBEngineTable);
}