// ST 2011
// ES 6 MW: Feb 27, 2020

export function InitStringFormat()
{
	var formatCache = {};
	var regexFmt = /{(\w+(\.\w+)*(\[\d+\])?)}/g;

	//var numFmt = /{\d+}/;

	function _prepareFormat( _fmt )
   {
      try
      {
         var res = "";
         //        var frst = "";

         regexFmt.lastIndex = 0;
         var inxLastEnd = 0;

         while ( true )
         {
            var match = regexFmt.exec( _fmt );
            if ( !match )
               break;
            var inxStart = match.index;
            if ( inxStart > inxLastEnd )
            {
               var strLit = _fmt.substring( inxLastEnd, inxStart ).quote();

               if ( res )
                  res += "+";
               res += strLit;

            }

            var prop = match[1];
            var num = parseInt( prop );
            var strArg;

            strArg = "nz(";

            if ( !isNaN( num ) )
            {
               strArg += "(_a[" + ( num + 1 ) + "])";
            }
            else
            {
               strArg += "(_a." + prop + ")";
            }

            strArg += ")";


            if ( res )
               res += "+";
            res += strArg;

            inxLastEnd = regexFmt.lastIndex;
         };
         var endStr = _fmt.length;
         if ( endStr > inxLastEnd )
         {
            var strEndLit = _fmt.substring( inxLastEnd ).quote();
            if ( res )
               res += "+";
            res += strEndLit;
         }
         var func = "var nz = function(_v){if(_v === undefined || _v === null)return ''; return _v.toString()};" +
            "return " + res + ";";

      }
      catch ( x )
      {
     //    LogException( "_prepFormat", x );
      }

		return new Function( "_a", func );
	}


	if ( !String.formatEx )
		String.formatEx = function ( _fmt )
      {
         try
         {
            var exec = formatCache[_fmt];
            if ( !exec )
            {
               exec = _prepareFormat( _fmt );
               formatCache[_fmt] = exec;
            }

            var arg = arguments;
            if ( arguments.length === 2 && typeof ( arguments[1] ) === "object" && _fmt.indexOf( "{0}" ) < 0 )
               arg = arguments[1];
         }
         catch ( x )
         {
           // Log.LogException( "formatEx", x );
         }

			return exec( arg );
		};

	String.f = String.formatEx;	// used so many times, abbreviated like in String::F in C++

	if ( !String.vformatEx )
		String.vformatEx = function ( _fmt, _arg )
		{
			var exec = _prepareFormat( _fmt );
			return exec( _arg );
		};
}
