// jb 12.11.2013

// "use strict";

import { HashPosition } from "common/Chess/TeraBrain/HashPosition"
import { WebSockMessage } from "common/WebClient/Protocol/WebSockMessage"
import { TaggedHashPos } from "common/Chess/TeraBrain/TaggedHashPosition"
import { TeraBrainRequestType } from "common/Chess/TeraBrain/TeraBrainLobby"
import { SockMsgId } from "common/WebClient/Protocol/WebSockMessage"

export class TeraBrainRequest
{
	static TBREQ_NORM_EVAL = 0x01;
	static TBREQ_MULTI_REQ = 0x02;

	constructor ( rPos, eRequestType, rMachineInfo ) 
	{
		this.m_eRequestType = eRequestType;
		this.m_aHashPos = new HashPosition( rPos );
		this.m_aMachineInfo = rMachineInfo;
		this.m_nMoveNo = rPos.getMoveNum();
		this.m_bCountVisit = true;
		this.m_bConfirmRequested = false;
		this.m_bHumanServerGame = false;
		this.m_nFlags = 0;
	};

	// NH2020. Changed: static prototyperRequestsPosInfo () {
	requestsPosInfo () {
		return this.m_eRequestType == TeraBrainRequestType.TBREQUEST_LB_POSINFO;
	};

	requestsAllMoves () {
		return this.m_eRequestType == TeraBrainRequestType.TBREQUEST_LB_ALLMOVES;
	};

	requestsSearchInfo () {
		return this.m_eRequestType == TeraBrainRequestType.TBREQUEST_SEARCH_INFO;
	};

	isUploadOnly () {
		return this.m_eRequestType == TeraBrainRequestType.TBREQUEST_LB_UPLOADONLY;
	};

	getHashPos () {
		return this.m_aHashPos;
	};

	getMoveNo () {
		return this.m_nMoveNo;
	};

	setMoveNo ( n ) {
		this.m_nMoveNo = n;
	};

	getTaggedPos () {
		// NH2020 made lowercase, added this
		return new TaggedHashPos( this.getHashPos(), this.getMoveNo() );
	};

	countVisit () {
		return this.m_bCountVisit;
	};

	setCountVisit ( n ) {
		this.m_bCountVisit = n || false;
	};

	confirmRequested () {
		return this.m_bConfirmRequested;
	};

	setConfirmRequested ( b ) {
		this.m_bConfirmRequested = b || false;
	};

	isHumanServerGame () {
		return this.m_bHumanServerGame;
	};

	setIsHumanServerGame ( b ) {
		this.m_bHumanServerGame = b || false;
	};

	isNormedEval () {
		return this.ISFLAG( TeraBrainRequest.TBREQ_NORM_EVAL );
	};

	setIsNormedEval ( b ) {
		this.SETFLAG( TeraBrainRequest.TBREQ_NORM_EVAL, b );
	};

	setIsMultiRequest ( b )
	{
		this.SETFLAG( TeraBrainRequest.TBREQ_MULTI_REQ, b );
	};

	SETFLAG ( n, b ) {
		if ( b ) this.m_nFlags |= ( n );
		else this.m_nFlags &= ~( n );
	};

	ISFLAG ( n ) {
		return ( this.m_nFlags & ( n ) ) != 0;
	};

	fromMessage ( rMsg ) {
		var aBuf = rMsg.getPDataBuf();
		aBuf.rewind();
		this.readFromDataBuffer( aBuf );
	};

	getSocketsMessage () {
		var aMsg = new WebSockMessage( SockMsgId.TERABRAIN_REQUEST );

		this.writeToDataBuffer( aMsg.buf );

		return aMsg;
	};

	writeToDataBuffer ( rBuf ) {
		rBuf.beginSizedWrite();
		rBuf.writeUint32( this.m_eRequestType );
		this.m_aHashPos.writeToDataBuffer( rBuf );
		this.m_aMachineInfo.writeToDataBuffer( rBuf );

		rBuf.writeUint32( this.m_nMoveNo );
		rBuf.writeBool( this.m_bCountVisit );
		rBuf.writeBool( this.m_bConfirmRequested );
		rBuf.writeBool( this.m_bHumanServerGame );
		rBuf.writeUint32( this.m_nFlags );
		rBuf.endSizedWrite();
	};

	readFromDataBuffer ( rBuf ) {

		rBuf.BeginSizedRead();
		this.m_eRequestType = ( TeraBrainRequestType )( rBuf.ReadInt() );
		this.m_aHashPos.ReadFromDataBuffer( rBuf );
		this.m_aMachineInfo.ReadFromDataBuffer( rBuf );

		this.m_nMoveNo = rBuf.ReadUint32();
		this.m_bCountVisit = rBuf.ReadBool();
		this.m_bConfirmRequested = rBuf.ReadBool();
		this.m_bHumanServerGame = rBuf.ReadBool();
		this.m_nFlags = rBuf.ReadUint32();

		rBuf.EndSizedRead();
		return true;
	};
}

