import React, { Fragment, useContext, useEffect, useRef, useState, useCallback } from 'react';
import styles from "./CBScrollSearchHint.module.css";

import { LOGIN_MODES } from 'CBReact/Components/Account/AccountUtils';
import { LoginContext, OnlineLobbyContext } from "CBReact/Contexts/CBOnlineDBContext";
import { Calculate } from 'CBReact/Utils/Calculate';

import { checkPropsAndRenderComponent } from 'CBReact/Utils/DebugUtils';
import { CBLoadingCircle } from 'CBReact/Components/DesignElements/Loaders/CBLoadingCircle/CBLoadingCircle';
import { CBMButtonBadge } from "CBReact/Components/DesignElements/Buttons/ButtonBadge/CBButtonBadge";
import { CBCirclePlusIcon } from 'CBReact/Components/DesignElements/Icons/CBIcons';

export function CBScrollSearchHint (props)
{
    const loginStatus = useContext(LoginContext).loginStatus;
    const getCurrentLoginStatus = useContext(LoginContext).getCurrentLoginStatus;
    const scrollSearchOnlineDB = useContext(OnlineLobbyContext).scrollSearchOnlineDB;
    const isScrollLoading = useContext(OnlineLobbyContext).isScrollLoading;
    const currentSearchCount = useContext(OnlineLobbyContext).currentSearchCount;

    const [loaderVisibility, setLoaderVisibility] = useState(0);
    const [reachedScrollEnd, setReachedScrollEnd] = useState(false);
    
    const containerRef = useRef();
    const timeOutRef = useRef(null);
    const lastDistanceToScrollEnd = useRef(0);

    useEffect(() => {
        if (!isScrollLoading)
        {
            setReachedScrollEnd(false);
            setLoaderVisibility(0);
            timeOutRef.current = null;
        }
    }, [isScrollLoading]);

    useEffect(() => {
        if (currentSearchCount === 1)
        {
            setReachedScrollEnd(false);
            setLoaderVisibility(0);
            timeOutRef.current = null;
        }
    }, [currentSearchCount]);

    useEffect(() => {
        if (props.tableRef.current)
            props.tableRef.current.addEventListener("scroll", handleScroll);

        return () => {
            if (props.tableRef.current)
                props.tableRef.current.removeEventListener("scroll", handleScroll);
        }
    }, [props.tableRef]);

    const handleScroll = useCallback((e) =>
    {
        if (props.tableRef.current)
        {
            let distanceToScrollEnd = Calculate.clamp(props.tableRef.current.scrollHeight - props.tableRef.current.scrollTop - props.tableRef.current.clientHeight, 0, Number.MAX_SAFE_INTEGER);

            if (containerRef.current && distanceToScrollEnd < containerRef.current.offsetHeight)
                setLoaderVisibility(Calculate.map(distanceToScrollEnd, 0, containerRef.current.offsetHeight, 1, 0));

            if (distanceToScrollEnd <= 3 && lastDistanceToScrollEnd.current > 3 && timeOutRef.current === null && (getCurrentLoginStatus() === LOGIN_MODES.standard || getCurrentLoginStatus() === LOGIN_MODES.premium))
            {
                timeOutRef.current = setTimeout(() => {
                    setLoaderVisibility(1);
                    setReachedScrollEnd(true);
                    scrollSearchOnlineDB();
                }, 400);
            }

            lastDistanceToScrollEnd.current = distanceToScrollEnd;
        }
    }, []);

    function handlePremiumClick (e)
    {
        e.stopPropagation(); 
        window.open( "https://account.chessbase.com/en/matrix", "_blank", "noopener");
    }

    function renderCBScrollSearchHint ()
    {
        return (
            <div className={styles.container} ref={containerRef}>
                {
                    (loginStatus === LOGIN_MODES.free || loginStatus === LOGIN_MODES.guest) && 
                    <Fragment>
                        <div className={styles.premiumContainer} style={{transform: `scale(${loaderVisibility})`, opacity: loaderVisibility}}>
                            <span>Get</span>
                            <div className={styles.premiumBadge}>
                                <CBMButtonBadge size="small" onClick={handlePremiumClick}>Premium</CBMButtonBadge>
                            </div>
                            <span>to load more games.</span>
                        </div>

                    </Fragment>
                }
                {
                   (loginStatus === LOGIN_MODES.standard || loginStatus === LOGIN_MODES.premium) &&
                    <div className={styles.loadingContainer} style={{transform: `scale(${loaderVisibility})`, opacity: loaderVisibility}}>
                        {!reachedScrollEnd && <CBCirclePlusIcon />} 
                        {reachedScrollEnd && <CBLoadingCircle style={{width: "60px", height: "60px", left: "calc(50% - 30px)", top: "calc(50% - 30px)"}} /> }
                    </div>
                }
            </div>
        )
    }

    return checkPropsAndRenderComponent([props.tableRef, props.tableData], renderCBScrollSearchHint)
}