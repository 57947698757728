

import React from 'react';
import styles from "./CBSideNavButton.module.css";

export function CBSideNavButton (props)
{
    return (
        <a href={props.link} target="_blank" rel="noopener noreferrer" onClick={props.onClick} className={`${styles.button} ${props.selected === true && styles.selected}`}>
            {props.children}
            {props.color && <div className={`${styles.color} ${props.selected === true ? styles.selected : styles[props.color]}`} /> }
            {props.image && <img className={styles.image} src={props.image} alt="Switch App Icon"/>}
        </a>
    );
}