// jb 12.11.2013

// "use strict";

import { MachineInfo } from "common/Chess/TeraBrain/MachineInfo"
import { Log } from "common/Tools/Log"

export class ClientMachineInfo extends MachineInfo
{

	// NH2021D
	// constructor ()
	// {
	// 	super()
	// 	// NH2020 Will Inheritance Work?
	// 	// Seems to work
	// 	// Log.WillItWork();
	// };

	//CB.inherit( ClientMachineInfo, CB.MachineInfo );

	read ( buf ) {
		this.strLastName = buf.readString( 100 );
		this.strFirstName = buf.readString( 100 );
		this.aNation = buf.readUint32();
		this.nElo = buf.readUint32();

	};

	Init () {
		/*
      pGlData->GetCPUCount( m_aCPUInfo );
      if( pGlData->IsIntel() && ( m_aCPUInfo.GetPhysicalCores() < m_aCPUInfo.GetLogicalCores() ) )
      {	SETFLAG( PROP_HT, true );
      }

      if( !s_nSpeed )
      {	AutoHourglass aHG;
         s_nSpeed = CBHeumasEngine().GetMachineSpeed();
         TRACE( "HeumasSpeed=%d\n",  s_nSpeed );
      #ifdef _WIN64
         s_nSpeed *= 0.9;		//	Die Heumas-Engine ist unter 64-Bit ca 10% schneller
      #endif
      #ifdef _DEBUG
         //for ( int i = 0; i < 3; i++ )
         //{	TRACE( "HeumasSpeed=%d\n", CBHeumasEngine().GetMachineSpeed() );
         //}
      #endif
      }
*/
	  	// NH2020 s_nSpeed not defined due to previous comment
		//m_nSpeed = s_nSpeed;

		// NH2020 Added this
		this.s_bInited = true;
	};

	GetNSpeed () {
		// NH2020 s_nSpeed cannot be properly defined
		Log.Missing();
		// if ( !s_bInited || s_nSpeed == 0 ) {
		// 	Init();
		// 	if ( s_nSpeed == 0 )
		// 		s_nSpeed = 100000;	// i7 has 850000
		// }

		// return s_nSpeed;
	};

}



