// mw 3.6.2013, ports DrawChecker in Win FrameWork for classical chess

// ES6 4.3.2020

import { Log } from 'common/Tools/Log'


import { ObjUtil } from 'common/Patterns/ObjectUtil';

import { Piece, Side } from 'common/Chess/Logic/Chess';


export var DrawType = 
{
	NOT_DECIDED: 0,
	MATE: 1,
	STALEMATE: 2,
	MATERIAL: 3,
	THREE_TIMES: 4,
	FIFTY_MOVES: 5,
	MOVES_290: 6,
	DRAW: 7,
	WHITE_WINS: 8,
	BLACK_WINS: 9,
	TWO_TIMES: 10
};

export class DrawChecker
{
	checkGame( game )
	{
		var drawType = this.checkPos( game.getCurPos() );
		if ( drawType !== DrawType.NOT_DECIDED )
			return drawType;

		var nMinMoves = 5;

		if ( game.getPlyNum() >= nMinMoves )
		{
         var castleRights = game.getCurPos().getCastleRights();
         var gameLen = game.getMoves().length;
			var nMinQuick = Math.max( 0, gameLen - nMinMoves );
			for ( var nMove = gameLen - 1; nMove >= nMinQuick; nMove-- )
			{
				var move = game.getMoves()[nMove];
				if ( move )
				{
					if ( !move.undo && move.isValid() && nMove < game.getPlyNum() - 1 )
					{
						// var cnt = 0;
						// for ( var n = 0; n < nMove; n++ )
						// 	if ( game.getMoves()[n].undo )
						// 		cnt++;

						// "nMove=" + nMove + "/" + game.getPlyNum() + ", cnt=" + cnt
						//try
						//{
						//	throw ( new Error( "DrawChkUndo" ) );
						//}
						//catch( x )
						//{
						//	var stk = x.stack ? x.stack : "nostack";
						//	LogException( x, "undo-", stk );
						//}
						break;
					}
					if ( move.isTake() || move.isProm() || move.isPawn() )
						return DrawType.NOT_DECIDED;
				}
				else
					return DrawType.NOT_DECIDED;
			}

			var nEPFile = game.getCurPos().getEPCol();
			var moveFiftyLim = game.getPlyNum() - 100;
			var threeCount = 1;
			var runPos = ObjUtil.clone( game.getCurPos() );

			for ( let nMove = gameLen; nMove > 0; --nMove )
			{
				let move = game.getMoves()[--nMove];

				if ( !move || !move.undo )	// cannot retract without undo information, see game.makeMove()
				{
					return DrawType.NOT_DECIDED;
				}

				if ( move.isTake() || move.isProm() || move.isPawn() )
					break;
				if ( game.getCurPos().getEPCol() !== nEPFile )
					break;
				if ( nMove <= moveFiftyLim )
					return DrawType.FIFTY_MOVES;

				runPos.unmakeMove( move, move.undo );

				if ( threeCount )
				{
					if ( castleRights !== runPos.getCastleRights() )
					{
						threeCount = 0;
						if ( moveFiftyLim <= 0 )
							break;
					}
				}

				// other side:
				if ( nMove > 0 )
				{
					let move = game.getMoves()[--nMove];
					if ( move.isTake() || move.isProm() || move.isPawn() )
						break;
					if ( game.getCurPos().getEPCol() !== nEPFile )
						break;
					if ( nMove <= moveFiftyLim )
						return DrawType.FIFTY_MOVES;
					if ( !move.undo )	// cannot retract without undo information, see game.makeMove()
						return DrawType.NOT_DECIDED;

					runPos.unmakeMove( move, move.undo );

					if ( threeCount )
					{
						if ( castleRights !== runPos.getCastleRights() )
						{
							threeCount = 0;
							if ( moveFiftyLim <= 0 )
								break;
						}

						if ( nEPFile !== runPos.getEPCol() )
							continue;
						if ( !runPos.boardEquals( game.getCurPos().board ) )	// check only when same side to move
							continue;

						threeCount++;
						if ( threeCount >= 3 )
							return DrawType.THREE_TIMES;
					}
				}
			}
		}
		return DrawType.NOT_DECIDED;
	};

	checkPos( pos )
	{
		if ( !pos.hasLegals() )
		{
			if ( pos.isCheck() )
			{
				return DrawType.MATE;
			} else
			{
				return DrawType.STALEMATE;
			}
		}

		// Material:
		var pieces = [0, 0, 0, 0, 0, 0, 0, 0],
			piecesWithClr = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
			wBish = 0,
			bBish = 0;

		for ( var s = 0; s < 64; s++ )
		{
			pieces[pos.board[s] & 7]++;	// Piece.PIECE_MASK
			piecesWithClr[pos.board[s] & 15]++;	// Piece.PIECE_MASK | Piece.SIDE_MASK
			if ( ( pos.board[s] & 7 ) === Piece.BISHOP )
			{
				if ( pos.board[s] & 8 )
				{
					if ( ( s & 1 ) === ( ( s >> 3 ) & 1 ) )
						bBish |= 1;
					else
						bBish |= 2;
				} else
				{
					if ( ( s & 1 ) === ( ( s >> 3 ) & 1 ) )
						wBish |= 1;
					else
						wBish |= 2;
				}
			}
		}

		if ( !pieces[Piece.QUEEN] && !pieces[Piece.ROOK] && !pieces[Piece.PAWN] )
		{
			if ( !pieces[Piece.BISHOP] )
			{
				if ( pieces[Piece.KNIGHT] <= 1 )
					return DrawType.MATERIAL;
			}
			if ( !pieces[Piece.KNIGHT] )
			{
				if ( pieces[Piece.BISHOP] <= 1 )
					return DrawType.MATERIAL;
				if ( ( wBish < 3 ) && ( bBish < 3 ) )
				{
					if ( !wBish || !bBish || ( wBish === bBish ) )
						return DrawType.MATERIAL;
				}
			}
		}

		return DrawType.NOT_DECIDED;
	};

	canWin( pos, side )
	{
		var canWin = false;

		try
		{
			var board = pos.board;

			var colMask = ( side === Side.WHITE ) ? 0 : Side.SIDE_MASK;
			var nPieceCnt = [];
			for ( var p = 0; p < 16; p++ )
				nPieceCnt[p] = 0;

			for ( var sq = 0; sq < 64; sq++ )	// more than naked king?
			{
				nPieceCnt[board[sq] & 15]++;
				if ( board[sq] && ( ( board[sq] & Side.SIDE_MASK ) === colMask )
					  && ( ( board[sq] & Piece.PIECE_MASK ) !== Piece.KING ) )
					canWin = true;
			}

			if ( canWin & !nPieceCnt[Piece.W_PAWN] && !nPieceCnt[Piece.B_PAWN] )
			{
				var nColor = side === Side.WHITE ? Piece.WHITE : Piece.BLACK;

				if ( !nPieceCnt[nColor | Piece.QUEEN] && !nPieceCnt[nColor | Piece.ROOK]
					&& ( nPieceCnt[nColor | Piece.BISHOP] + nPieceCnt[nColor | Piece.KNIGHT] === 1 )
					&& !nPieceCnt[( Piece.COLOUR - nColor ) | Piece.BISHOP] && !nPieceCnt[( Piece.COLOUR - nColor ) | Piece.KNIGHT] )
				{
					if ( nPieceCnt[nColor | Piece.BISHOP] )
					{
						if ( nPieceCnt[( Piece.COLOUR - nColor ) | Piece.QUEEN] || nPieceCnt[( Piece.COLOUR - nColor ) | Piece.ROOK] )
							canWin = false;
					}
					else if ( nPieceCnt[nColor | Piece.KNIGHT] )
					{
						if ( nPieceCnt[( Piece.COLOUR - nColor ) | Piece.QUEEN] && !nPieceCnt[( Piece.COLOUR - nColor ) | Piece.ROOK] )
							canWin = false;
					}
				}
			}
		}
		catch ( x ) 	// paranoid
		{
			canWin = true;
			Log.Exception( "CanWin", x );
      }


		return canWin;
	};

   static getStrDrawType = function ( t )
   {
      switch ( t )
      {
         default:
         case DrawType.MATE:
            return "Mate";
         case DrawType.STALEMATE:
            return "Stalemate";
         case DrawType.MATERIAL:
            return "No Material";
         case DrawType.THREE_TIMES:
            return "Threefold Rep";
         case DrawType.FIFTY_MOVES:
            return "50 Moves Rule";
         case DrawType.MOVES_290:
            return "290 Moves";
         case DrawType.DRAW:
            return "Draw";
         case DrawType.WHITE_WINS:
            return "WWin";
         case DrawType.BLACK_WINS:
            return "BWin";
         case DrawType.TWO_TIMES:
            return "Twofold Rep";
      }
   };
}