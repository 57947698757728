// mw 3.2.2013

// ES6 28.2.2020


//////////////////////////////////////////////////////////////////////

export class Point
{
	constructor ( x, y )
	{
		if ( typeof x == "object" && typeof y == "undefined" )
		{
			if ( x.clientX !== undefined )	// mouse click?
			{
				this.set( x.clientX, x.clientY );
			}
			else if ( x.touches && x.touches.length )
			{
				this.set( x.touches[0].pageX, x.touches[0].pageY );
			}
			else if ( x.pageX !== undefined )
			{
				this.set( x.pageX, x.pageY );
			}
		}
		else
			this.set( x, y );	// use "new Pt( x, y )"
	};

	set( x, y )
	{
		this.x = x;
		this.y = y;
	};

	add( p )
	{
		return new Point( this.x + p.x, this.y + p.y );
	};

	subtract( p )
	{
		return new Point( this.x - p.x, this.y - p.y );
	};

	getDist( p )
	{
		var dVec = this.subtract( p );

		return Math.sqrt( dVec.x * dVec.x + dVec.y * dVec.y );
	};

	getVecLen()
	{
		return Math.sqrt( this.x * this.x + this.y * this.y );
	};

	toInt()
	{
		this.x = Math.floor( this.x + 0.5 );
		this.y = Math.floor( this.y + 0.5 );
	}

	cloneIntPoint()
	{
		var p = new Point( this.x, this.y );
		p.toInt();
		return p;
	}

	toString()
	{
		return "(" + this.x + "," + this.y + ")";
	};

	getX()
	{
		return this.x;
	};

	getY()
	{
		return this.y;
	};

}


//////////////////////////////////////////////////////////////////////

export class Size
{
	constructor( w, h )
	{
		this.w = w;
		this.h = h;
	};

	toString()
	{
		return "w=" + this.w + ", h=" + this.h;
	};

	centerInSize( sizOuter )
	{
		return new Point(( sizOuter.w - this.w ) / 2, ( sizOuter.h - this.h ) / 2 );
	};

}

//////////////////////////////////////////////////////////////////////

export class Rect
{
	constructor ( l, t, r, b )
	{
		this.left = l || 0;
		this.top = t || 0;
		this.right = r || 0;
		this.bottom = b || 0;
	};

	get w()
	{
		return this.getSize().w;
	}
	set w( _w )
	{
		this.right = this.left + _w - 1;
	}
	get h()
	{
		return this.getSize().h;
	}
	set h( _h )
	{
		this.right = this.left + _h - 1;
	}

	get topLeft()
	{
		return new Point( this.left, this.top );
	}
	set topLeft( tl )
	{
		var w = this.w;
		var h = this.h;
		this.left = tl.x; this.top = tl.y;
		this.w = w;
		this.h = h;
	}

	fromRect( rect )
	{
		this.left = rect.left;
		this.top = rect.top;
		this.right = rect.right;
		this.bottom = rect.bottom;
	};

	fromPosSize( pos, size )
	{
		this.left = pos.x;
		this.top = pos.y;
		this.right = pos.x + size.w - 1;
		this.bottom = pos.y + size.h - 1;
	};

	fromClientRect( clientRect )
	{
		this.left = clientRect.left;
		this.top = clientRect.top;
		this.right = clientRect.right;
		this.bottom = clientRect.bottom;
	};

	fromTwoPoints( x1, y1, x2, y2 )
	{
		this.left = Math.min( x1, x2 );
		this.right = Math.max( x1, x2 );
		this.top = Math.min( y1, y2 );
		this.bottom = Math.max( y1, y2 );
	};

	toString()
	{
		return "l=" + this.left + ", t=" + this.top + " " + this.getSize().toString();
	};

	getPos()
	{
		return new Point( this.left, this.top );
	};

	getTopLeft()
	{
		return new Point( this.left, this.top );
	};
	getTopRight()
	{
		return new Point( this.right, this.top );
	};
	getBottomRight()
	{
		return new Point( this.right, this.bottom );
	};
	getBottomLeft()
	{
		return new Point( this.left, this.bottom );
	};

	getSize()
	{
		return new Size( this.right - this.left + 1, this.bottom - this.top + 1 );
	};

	getUnion( rect )
	{
		var left = Math.min( Math.min( this.left, rect.left ), Math.min( this.right, rect.right ) );
		var right = Math.max( Math.max( this.left, rect.left ), Math.max( this.right, rect.right ) );
		var top = Math.min( Math.min( this.top, rect.top ), Math.min( this.bottom, rect.bottom ) );
		var bottom = Math.max( Math.max( this.top, rect.top ), Math.max( this.bottom, rect.bottom ) );

		return new Rect( left, top, right, bottom );
	};

	getCutAtBoundary( boundary )
	{
		var ret = new Rect( this.left, this.top, this.right, this.bottom );
		if ( ret.left < boundary.left || ret.left >= boundary.right )
			ret.left = boundary.left;
		if ( ret.right > boundary.right || ret.right <= boundary.left )
			ret.right = boundary.right;
		if ( ret.top < boundary.top || ret.top >= boundary.bottom )
			ret.top = boundary.top;
		if ( ret.bottom > boundary.bottom || ret.bottom <= boundary.top )
			ret.bottom = boundary.bottom;

		return ret;
	};

	isInside( x, y )
	{
		return x >= this.left && x <= this.right
			  && y >= this.top && y <= this.bottom;
	};

	getPath()
	{
		var path = new Path();
		path.push( this.getTopLeft() );
		path.push( this.getTopRight() );
		path.push( this.getBottomRight() );
		path.push( this.getBottomLeft() );

		return path;
	};

	move( dx, dy )
	{
		this.left += dx;
		this.top += dy;
		this.right += dx;
		this.bottom += dy;
	};

	shrink( dx, dy )
	{
		this.left += dx;
		this.right -= dx;
		this.top += dy;
		this.bottom -= dy;
	};

	expand( dx, dy )
	{
		this.shrink( -dx, -dy );
	};
}

//////////////////////////////////////////////////////////////////////

export class Vector
{
	constructor ( x, y, z )
	{
		this.set( x, y, z );
	};

	set( x, y, z )
	{
		this.x = x;
		this.y = y;
		this.z = z;
	};

	add( vec )
	{
		return new Vector( this.x + vec.x, this.y + vec.y, this.z + vec.z );
	};

	subtract( vec )
	{
		return new Point( this.x - vec.x, this.y - vec.y, this.z - vec.z );
	};

	getLength()
	{
		if ( this.z !== undefined )
			return Math.sqrt( this.x * this.x + this.y * this.y + this.z * this.z );
		else
			return Math.sqrt( this.x * this.x + this.y * this.y );
	};

	negate()
	{
		this.x = -this.x;
		this.y = -this.y;
		if ( this.z !== undefined )
			this.z = -this.z;
	};

	toString()
	{
		if ( this.z !== undefined )
			return "(" + this.x + "," + this.y + "," + this.z + ")";
		else
		{
			return "(" + this.x + "," + this.y + ")";
		}
	};

}

//////////////////////////////////////////////////////////////////

export class Path
{
	constructor()
	{
		this.subPaths = [];
	};

	toString()
	{
		let strRet = "Pth:";
		for ( let s = 0; s < this.subPaths.length; s++ )
		{
			strRet += "s=";
			this.subPaths[s].forEach(
				function ( v, a, e )
				{
					strRet += v.toString();
					if ( e < a.length - 1 )
					{
						strRet += ",";
					}
				} );
		}
		return strRet;
	};

	push( point )
	{
		if ( this.subPaths.length === 0 )
		{
			this.subPaths.push( [] );
		}
		this.subPaths[this.subPaths.length - 1].push( point );
	};

	exec( ctx )
	{
		ctx.beginPath();
		for ( var s = 0; s < this.subPaths.length; s++ )
		{
			if ( this.subPaths[s].length > 1 )
			{
				this.execPoly( ctx, this.subPaths[s] );
			}
		}
		ctx.closePath();
	};

	execPoly( ctx, points )
	{
		ctx.moveTo( points[0].x, points[0].y );
		for ( var p = 1; p < points.length; p++ )
		{
			ctx.lineTo( points[p].x, points[p].y );
		};
	}
	
	curve( ctx )
	{
		for ( var s = 0; s < this.subPaths.length; s++ )
		{
			if ( this.subPaths[s].length > 3 )
			{
				ctx.beginPath();
				this.execCurve( ctx, this.subPaths[s] );
				ctx.closePath();
			}
		};
	};

	execCurve( ctx, points )
	{
		ctx.moveTo( points[0].x, points[0].y );
		var i;
		for ( i = 1; i < points.length - 2; i++ )
		{
			var xc = ( points[i].x + points[i + 1].x ) / 2;
			var yc = ( points[i].y + points[i + 1].y ) / 2;

			ctx.quadraticCurveTo( points[i].x, points[i].y, xc, yc );
		}
		ctx.quadraticCurveTo( points[i].x, points[i].y, points[i + 1].x, points[i + 1].y );
	};

	reverse()
	{
		if ( this.subPaths.length )
			this.subPaths[this.subPaths.length - 1].reverse();
	};

	append( p )
	{
		this.subPaths = this.subPaths.concat( p.subPaths );
	}

}
