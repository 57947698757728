
import React, { useContext, Fragment } from 'react';
import ReactGA from 'react-ga';
import styles from "./CBNavBarSide.module.css";
import iconStyles from "CBReact/Components/DesignElements/Icons/CBIcons.module.css"

import { CBHorizontalSpacer } from "CBReact/Components/DesignElements/HorizontalSpacer/CBHorizontalSpacer"
import { CBLogIn } from "CBReact/Components/Account/CBLogIn"
import { LocalizationContext, LoginContext, SideNavContext } from "CBReact/Contexts/CBOnlineDBContext"
import { CBSettings } from 'CBReact/Components/Settings/CBSettings';
import { CBSideNavButton } from "CBReact/Components/DesignElements/Buttons/SideNavButton/CBSideNavButton"
import { LOGIN_MODES } from "CBReact/Components/Account/AccountUtils"
import { CBAccountInfo } from 'CBReact/Components/Account/CBAccountInfo';
import { CBMCloseIcon } from 'CBReact/Components/DesignElements/Icons/CBIcons';

// import Switch App Icons
import playChess from "CBReact/Resources/Raster/SwitchApp/Playchess32.png";
import liveChess from "CBReact/Resources/Raster/SwitchApp/Live32.png";
import tactics from "CBReact/Resources/Raster/SwitchApp/tactics32.png";
import videos from "CBReact/Resources/Raster/SwitchApp/Live32.png";
import fritz from "CBReact/Resources/Raster/SwitchApp/videos32.png";
import openings from "CBReact/Resources/Raster/SwitchApp/Openings32.png";
import myGames from "CBReact/Resources/Raster/SwitchApp/MyGames32.png";
import quiz from "CBReact/Resources/Raster/SwitchApp/quiz32.png";
import players from "CBReact/Resources/Raster/SwitchApp/Players32.png";
import news from "CBReact/Resources/Raster/SwitchApp/News32.png";
import studies from "CBReact/Resources/Raster/SwitchApp/Studies32.png";

export const SIDENAV_RENDERMODES = {
    main: 0,
    login: 1,
    settings: 2,
    account: 3,
}

export function CBNavBarSide (props)
{
    const sideNavIsOpen = useContext(SideNavContext).sideNavIsOpen;
    const sideNavRenderMode = useContext(SideNavContext).sideNavRenderMode;
    const changeSideNav = useContext(SideNavContext).changeSideNav;
    const loginStatus = useContext(LoginContext).loginStatus;
    const userName = useContext(LoginContext).userName;
    const loc = useContext(LocalizationContext).localization;

    switch (sideNavRenderMode)
    {
        case SIDENAV_RENDERMODES.main:
            return renderSideBar(renderMain);
        case SIDENAV_RENDERMODES.login:
            return renderSideBar(renderLogIn);
        case SIDENAV_RENDERMODES.settings:
            return renderSideBar(renderSettings);
        case SIDENAV_RENDERMODES.account:
            return renderSideBar(renderAccount);
        default:
            return renderSideBar(renderMain);
    }

    function renderSideBar (renderModeFunction)
    {
        return (
            <Fragment>
            <div className={`${styles.base} ${sideNavIsOpen ? styles.open : styles.closed}`}>
                <div onClick={() => changeSideNav(true)} className={styles.closeButton}>
                    <CBMCloseIcon className={iconStyles.defaultIcon} height={"15px"}/>
                </div>
                <CBHorizontalSpacer/>
                {renderModeFunction()}
            </div>
        </Fragment>
        );
    }

    function renderLogIn ()
    {
        return <CBLogIn />;
    }

    function renderAccount ()
    {
        return <CBAccountInfo />;
    }

    function renderSettings ()
    {
        return <CBSettings />;
    }

    function sendSwitchAppAnalytics (appName)
    {
        ReactGA.event({
            category: 'Navigation',
            action: 'SwitchedApp',
            label: appName
        });
    }

    function renderMain ()
    {
        return (
            <Fragment>
                <div className={styles.topContainer}>
                    {loginStatus === LOGIN_MODES.guest ?
                    <CBSideNavButton onClick={() => changeSideNav(false, SIDENAV_RENDERMODES.login)}>Log In</CBSideNavButton> :
                    <CBSideNavButton onClick={() => changeSideNav(false, SIDENAV_RENDERMODES.account)}>{userName !== "" ? (userName + " (" + loc.strings.ACCOUNT + ")") : "My Account"}</CBSideNavButton>
                    }
                    <CBSideNavButton onClick={() => changeSideNav(false, SIDENAV_RENDERMODES.settings)}>Settings</CBSideNavButton>
                </div>
                <CBHorizontalSpacer/>
                <div className={styles.appLinkContainer}>
                    <CBSideNavButton onClick={() => {sendSwitchAppAnalytics("PlayChess")}} link={"https://play.chessbase.com/de/"} color={"playChess"} image={playChess}>Play Chess</CBSideNavButton>
                    <CBSideNavButton onClick={() => {sendSwitchAppAnalytics("LiveChess")}} link={"https://live.chessbase.com/"} color={"liveChess"} image={liveChess}>Live Chess</CBSideNavButton>
                    <CBSideNavButton onClick={() => {sendSwitchAppAnalytics("Tactics")}} link={"https://tactics.chessbase.com/de/"} color={"tactics"} image={tactics}>Tactics</CBSideNavButton>
                    <CBSideNavButton onClick={() => {sendSwitchAppAnalytics("Videos")}} link={"https://videos.chessbase.com/"} color={"videos"} image={videos}>Videos</CBSideNavButton>
                    <CBSideNavButton onClick={() => {sendSwitchAppAnalytics("Fritz")}} link={"https://fritz.chessbase.com/"} color={"fritz"} image={fritz}>Fritz</CBSideNavButton>
                    <CBSideNavButton onClick={() => {sendSwitchAppAnalytics("Openings")}} link={"https://mymoves.chessbase.com/de/"} color={"openings"} image={openings}>Openings</CBSideNavButton>
                    <CBSideNavButton onClick={() => {sendSwitchAppAnalytics("MyGames")}} link={"https://mygames.chessbase.com/"} color={"myGames"} image={myGames}>My Games</CBSideNavButton>
                    <CBSideNavButton onClick={() => {sendSwitchAppAnalytics("Quiz")}} link={"https://quiz.chessbase.com/en/"} color={"quiz"} image={quiz}>Quiz</CBSideNavButton>
                    <CBSideNavButton onClick={() => {sendSwitchAppAnalytics("Players")}} link={"https://players.chessbase.com/de/list/world-top-100"} color={"players"} image={players}>Players</CBSideNavButton>
                    <CBSideNavButton onClick={() => {sendSwitchAppAnalytics("News")}} link={"https://en.chessbase.com/"} color={"news"} image={news}>News</CBSideNavButton>
                    <CBSideNavButton onClick={() => {sendSwitchAppAnalytics("Studies")}} link={"https://studies.chessbase.com/"} color={"studies"} image={studies}>Studies</CBSideNavButton>
                </div>
            </Fragment>
        );
    }
}