

import React from 'react';
import styles from "./CBRibbon.module.css";

export function CBRibbon (props)
{
    return(
        <div className={`${styles.base}`}>
            {props.children}
        </div>
    );
}