// "use strict";

import { EloNumber } from "common/Chess/Format/EloNumber"

export class EloArray
{

	constructor ()
	{
		// NH2020 put these in constructor
		this.vecElo = [];
	};

	readFromDataBuffer ( rBuf )
	{
		rBuf.beginSizedRead();

		this.clear();

		var nCount = rBuf.readUint32();
		if ( nCount > 0x1000 )
			return false;
		if ( nCount > 0 )
		{
			var bOldFormat = rBuf.readBool();
			for ( var n = 0; n < nCount; n++ )
			{
				var aEloNumber = new EloNumber( bOldFormat );
				aEloNumber.readFromDataBuffer( rBuf );
				this.vecElo.push( aEloNumber );

			}
		}
		rBuf.endSizedRead();
		return true;
	}

	readLatestFromDataBuffer ( rBuf )
	{
		return this.ReadFromDataBuffer( rBuf );
	}

	clear ()
	{
		if ( this.vecElo )
		{
			this.vecElo.clear();
		}
		else
			this.vecElo = [];
	}

	getTrendStr ( nTrendLen )
	{
		var n = ( this.count() - 1 );
		var aTrend = "";
		for ( var i = 0; i < nTrendLen; i++ )
		{
			var n2 = ( n - 1 );
			if ( n2 >= 0 )
			{
				var nMonthN = this.vecElo[n].getDate().month;
				while ( n2 >= 0 && Math.abs( nMonthN - this.vecElo[n2].getDate().month ) < 6 )
					n2--;
				if ( n2 >= 0 )
				{
					var nDiff = this.vecElo[n].getNElo() - this.vecElo[n2].getNElo();
					if ( Math.abs( nDiff ) >= 10 )
					{
						if ( nDiff > 0 )
							aTrend = '+' + aTrend;
						else
							aTrend = '-' + aTrend;
					}
					else
						aTrend = '0' + aTrend;
				}
				n = n2;
			}
		}
		if ( aTrend.length == nTrendLen )
			return aTrend;
		else
			return "";

	}

	getNBestElo ( rDate )
	{

		var nEloMax = this.vecElo[0].getNElo();
		rDate = this.vecElo[0].getDate();
		for ( var i = 0; i < this.vecElo.length; i++ )
		{
			var rElo = this.vecElo[i];
			if ( rElo.getNElo() > nEloMax )
			{
				nEloMax = rElo.getNElo();
				rDate = rElo.getDate();
			}
		}
		return nEloMax;
	}

	getNWorstElo ( rDate )
	{

		var nEloMin = this.vecElo[0].getNElo();
		rDate = this.vecElo[0].getDate();
		for ( var i = 0; i < this.vecElo.length; i++ )
		{
			var rElo = this.vecElo[i];
			if ( rElo.getNElo() < nEloMin )
			{
				nEloMin = rElo.getNElo();
				rDate = rElo.getDate();
			}
		}
		return nEloMin;
	}

	getApproxElo ( nMinYear, nMaxYear )
	{
		var nTotalSum = 0;
		var nFound = 0;
		for ( var i = 0; i < this.vecElo.length; i++ )
		{
			var rElo = this.vecElo[i];
			if ( rElo.getDate().getYear() >= nMinYear && rElo.getDate().getYear() <= nMaxYear )
			{
				nTotalSum += rElo.getNElo();
				nFound++;
			}
		}
		return ( nFound > 0 ? nTotalSum / nFound : 0 );
	}


	getBestMatchEloByDate ( rDate )
	{
		var nRetElo = 0;
		if ( this.vecElo.length > 0 )
			nRetElo = this.vecElo[this.vecElo.length - 1].getNElo();
		return nRetElo;
	}

	count ()
	{
		return this.vecElo.length;
	}

	get ( n )
	{
		if ( n < this.vecElo.length )
			return this.vecElo[n];
		return new EloNumber();
	}

}

