// jb 12.11.2013

"use strict";

import { TBrainMove, TBRAIN } from "common/Chess/TeraBrain/TBrainMove"
import { TBrainTimeStamp } from "common/Chess/TeraBrain/TeraBrainTimeStamp"
// import { TeraBrainRequest } from "common/Chess/TeraBrain/TeraBrainRequest"
// import { Log } from "common/Tools/Log"

export class HashMoveData 
{
	constructor () 
	{
		// NH2020 Added SETFLAGS and ISFLAG Methods from TeraBrainRequest. Previously undefined.
		// Seems to work
		// Log.WillItWork();
		this.m_aLine = [0];
	};

	read ( buf ) {
	};

	WriteToDataBuffer ( rDB ) {
		//rDB.BeginSizedWrite();

		//rDB.WriteUnsigned( m_nConfidence );
		//rDB.WriteShort( m_nEval );
		//rDB.WriteBYTE( m_nDepth );
		//rDB.WriteBYTE( m_nFlags );

		//rDB.WriteUShort( TBRAIN.HASH_LINE_LEN );
		//// rDB.WriteData( m_aLine, sizeof(m_aLine) );

		//rDB.WriteUnsigned( m_nEngineId );
		//rDB.WriteUnsigned( m_nUserId );
		//rDB.WriteUShort( m_nTimeStamp );
		//rDB.WriteUShort( m_nCalcDepth );
		//rDB.WriteBool( m_bReasonableDepth );
		//rDB.WriteUnsigned( m_nRankingListWeight );

		//rDB.EndSizedWrite();
		return true;
	};

	readFromDataBuffer ( rDB ) {
		var bRet = true;

		rDB.beginSizedRead();

		this.m_nConfidence = rDB.readUint32();
		this.m_nEval = rDB.readInt16();
		this.m_nDepth = rDB.readByte();
		this.m_nFlags = rDB.readByte();

		var nLineLen = rDB.readUint16();

		// NH2020 TBrainMove.prototype.HASH_LINE_LEN removed prototype
		for ( var nMove = 0; nMove < TBrainMove.HASH_LINE_LEN && nMove < nLineLen; ++nMove )
			this.m_aLine[nMove] = rDB.readUint16();

		this.m_nEngineId = rDB.readUint32();
		this.m_nUserId = rDB.readUint32();
		this.m_nTimeStamp = rDB.readUint16();
		this.m_nCalcDepth = rDB.readUint16();	// maybe remove later, useful in testing
		this.m_bReasonableDepth = rDB.readBool();
		this.m_nRankingListWeight = rDB.readUint32();

		rDB.endSizedRead();
		return bRet;
	};

	//HashMoveData.prototype.isValid = function ()
	//{
	//	return m_aLine.length < 3 || m_aLine[0] == 0 && m_aLine[1] == 0;
	//};

	Empty () {
		/*if ( m_aLine[0] != null)
      {	ShortMove<CBMove> sMove( m_aLine[0] );
         return !CBMove( sMove ).IsValid();
      }*/
		return true;
	};

	MovesEqualTo ( rhs ) {
		/*	      for( int n = 0; n < HASH_LINE_LEN; ++n )
               if ( m_aLine[n] != rhs.m_aLine[n] )
                  return true;
     */
		return false;
	}

	ToString () {
		var strRet = "??";

		for ( var n = 0; n < TBRAIN.HASH_LINE_LEN; ++n ) {

			/*ShortMove<CBMove> sMove( m_aLine[n] );
			CBMove aMove( sMove );
			aMove.SetType( MOVE_UNDEF );
			strRet.AddSeparated( aMove.GetTestStr() );*/
		}

		/*           strRet.AddSeparated( "Eval=" + String( m_nEval ), ", " );
					  strRet.AddSeparated( "Depth=" + String( m_nDepth ), ", " );
					  strRet.AddSeparated( "EngineId=" + String( m_nEngineId ), ", " );
					  strRet.AddSeparated( "Wght=" + String( m_nRankingListWeight ), ", " );
					  strRet.AddSeparated( "Flags=" + String( m_nFlags ), ", " );
				  }
				  */
		return strRet;
	};

	//  public void FillVariation( Variation rVar ) 
	//{
	/*rVar.Clear();
   if ( !Empty() )
   {	for( int n = 0; n < HASH_LINE_LEN; ++n )
      {	ShortMove<Move64> sMove( m_aLine[n] );
         if ( sMove )
            rVar += Move64( sMove );
      }
   }*/
	//}

	SetTimeStamp ( rDate ) {
		this.m_nTimeStamp = TBrainTimeStamp.DateToUShort( rDate );
	};

	GetTimeStamp () {
		return TBrainTimeStamp.UShortToDate( this.m_nTimeStamp );
	};

	Test ( rVar, bBTM ) {
		/*if( rVar.Count() )
		{	for( int i=0; i<HASH_LINE_LEN && i<rVar.Count(); ++i )
			{	ShortMove<Move64> aMove( rVar[i] );
				m_aLine[i] = (unsigned short)aMove;
			}
			Evaluation &rEval = rVar.GetEval();
		#ifdef WITH_TERABRAIN_NORM_EVAL
			m_nEval = rEval.GetIntVal();
		#else
			m_nEval = rEval.GetSideIntVal( bBTM );
		#endif
		//	TRACE( "HashMoveData, btm=%d, eval=%d\n", bBTM, m_nEval );
			m_nDepth = rEval.GetNDepth();
			m_nRankingListWeight = 0u;
			SETFLAG( EVAL_MOVE_NO, false );
	
			return true;
		}
		*/

		return false;
	};

	fillVariation ( rVar, bBTM ) {/*
	      rVar.Clear();

	      if( !Empty() )
	      {	for( int i=0; i<HASH_LINE_LEN && m_aLine[i]; ++i )
		      {	ShortMove<Move64> aMove( m_aLine[i] );
			      rVar += (Move64)aMove;
		      }

		      if( IsEval() )
			      FillEvaluation( rVar.GetEval(), bBTM );
		      else
		      {	ASSERT( 0 );
		      }
		      return true;
	      }*/

		return false;
	};

	FillEvaluation ( rEval, bBTM ) {/*
      #ifdef __USAGE_SERVER
	      ASSERT( 0 );	// check if only called on client (norm eval flag)
      #endif
      
      #ifdef WITH_TERABRAIN_NORM_EVAL
	      rEval.SetIntVal( m_nEval );
      #else
	      rEval.SetIntVal( bBTM ? -m_nEval : m_nEval );
      #endif
	      rEval.SetNDepth( m_nDepth );
            */
		return true;
	};

	SetMove ( rMove, nMoveNo ) {/*
	      if( !rMove.IsNullMove() )
	      {	ShortMove<Move64> aMove( rMove );
		      m_aLine[0] = (unsigned short)aMove;
		      m_nEval = nMoveNo;
		      SETFLAG( EVAL_MOVE_NO, true );
      
      		return true;
	      }
            */
		return false;

	};

	FillMove ( rMove, rnMoveNo ) {/*
	      if( !Empty() )
	      {	ShortMove<Move64> aMove( m_aLine[0] );
		      rMove = (Move64)aMove;
		      if( IsMoveNo() )
		      {	rnMoveNo = m_nEval;
		      } else
		      {	ASSERT( 0 );
		      }
      
      		return true;
	      }
         */
		return false;
	};

	IsMate () {
		return Math.Abs( this.m_nEval ) > 29000;
	};

	GetNCalcDepthPercent ( nCurrCalcDepth ) {
		var nCalc = this.GetNCalcDepth();
		if ( nCalc != 0 )
			return ( ( 100.0 * nCurrCalcDepth ) / this.GetNCalcDepth() + 0.5 );
		else
			return 0;
	};


	Count () {
		return TBRAIN.HASH_LINE_LEN;
	};

	Clear () {
		//memset( this, 0, sizeof(HashMoveData) );
	};

	GetNEval () {
		// return (short)( (short)ISFLAG( (int)TBRAIN.EVAL_MOVE_NO ) ? 0 : (short)m_nEval);
		return 0;
	};

	setNEval ( n ) {
		this.m_nEval = n;
		this.SETFLAG( TBRAIN.EVAL_MOVE_NO, false );
	};

	GetNMoveNo () {
		// return (short)( ISFLAG((int)TBRAIN.EVAL_MOVE_NO ) ? m_nEval : 0);
		return 0;
	};

	SetNMoveNo ( n ) {
		this.m_nEval = n;
		this.SETFLAG( TBRAIN.EVAL_MOVE_NO, true );
	};

	GetNDepth () {
		return this.m_nDepth;
	};

	SetNDepth ( c ) {
		this.m_nDepth = c;
	};

	GetNConfidence () {
		return this.m_nConfidence;
	};

	SetNConfidence ( n ) {
		this.m_nConfidence = n;
	};

	GetMove ( n ) {
		//   ASSERT( n < HASH_LINE_LEN );
		//if( n < HASH_LINE_LEN )
		//return m_aLine[n];
		return 0;
	};

	SetMove ( nMove, n ) {
		//ASSERT( n < HASH_LINE_LEN );
		//				if( n < HASH_LINE_LEN )
		//				m_aLine[n] = nMove;
	};

	IsEval () {
		return !this.ISFLAG( TBRAIN.EVAL_MOVE_NO );
	};

	IsMoveNo () {
		return this.ISFLAG( TBRAIN.EVAL_MOVE_NO );
	};

	etNEngineId () {
		return this.m_nEngineId;
	};

	SetNEngineId ( r ) {
		this.m_nEngineId = r;
	};

	GetNUserId () {
		return this.m_nUserId;
	};

	SetNUserId ( r ) {
		this.m_nUserId = r;
	};

	UserIsLogin () {
		return this.ISFLAG( TBRAIN.USER_IS_LOGIN );
	};

	SetUserIsLogin ( b ) {
		this.SETFLAG( TBRAIN.USER_IS_LOGIN, b );
	};

	SetNTimeStamp ( n ) {
		this.m_nTimeStamp = n;
	};

	GetNCalcDepth () {
		return this.m_nCalcDepth;
	};

	IsReasonableDepth () {
		return this.m_bReasonableDepth;
	};

	SetIsReasonableDepth ( b ) {
		this.m_bReasonableDepth = b;
	};

	IsDisco () {
		// NH2020 TBrainConstants undefined... Used values from TBrainMove
		return this.m_nCalcDepth >= TBrainMove.CALCTIME_DISCOVERY;
		//return this.m_nCalcDepth >= TBrainConstants.CALCTIME_DISCOVERY;
	};

	IsDeep () {
		// NH2020 TBrainConstants undefined... Used values from TBrainMove
		return this.m_nCalcDepth >= TBrainMove.CALCTIME_DEEP;
		//return this.m_nCalcDepth >= TBrainConstants.CALCTIME_DEEP;
	};

	GetRankingListWeight () {
		return this.m_nRankingListWeight;
	};

	SetRankingListWeight ( n ) {
		this.m_nRankingListWeight = n;
	};

	SETFLAG ( n, b ) {
		if ( b ) this.m_nFlags |= ( n );
		else this.m_nFlags &= ~( n );
	};

	ISFLAG ( n ) {
		return ( this.m_nFlags & ( n ) ) != 0;
	};

}

