// ST 2011
// ES6 3.3.2020

import { StringBuilder } from "common/Tools/Strings/StringBuilder";
import { ObjUtil } from 'common/Patterns/ObjectUtil';
import { Piece, Square } from 'common/Chess/Logic/Chess';
import { Game } from 'common/Chess/Logic/Game';

import { Move } from 'common/Chess/Logic/Move';
import { MoveLine } from 'common/Chess/Logic/MoveLine';
import { Position } from 'common/Chess/Logic/Position';
import { AnnoType } from 'common/Chess/Format/AnnoTypes';
import { GameResultEnum, GameHeader } from 'common/Chess/Logic/GameHeader';
import { ColSq, ColArrow, ColSqAnno, ColArrowAnno, TextAnno, EvalAnno, TrainingAnno, TimeAnno, 
		 MoveMedalAnno, EvalProfileAnno, SymbolsAnno } from 'common/Chess/Format/AnnoTypes';
import { ClockParams } from 'common/Chess/PlayingModes/ClockParams';
import { SpecialGlyphs } from "./glyph";
import { PGNLexer, TokenType } from "./PGNLexer";


export class PGNSubLine extends Array
{
}

export class PGNMove
{
	constructor( _str )
	{
		this.m_strMove = null;
		this.m_isCheck = false;
		this.m_isMate = false;
		this.m_isShortCastle = false;
		this.m_isLongCastle = false;

		this.m_isNullMove = false;
		this.m_isTake = false;

		this.m_mvd = Piece.NONE;
		this.m_prom = Piece.NONE;

		this.m_colFrom = -1;
		this.m_rowFrom = -1;

		this.m_colTo = -1;
		this.m_rowTo = -1;

		this.m_to = -1;

		if ( _str )
		{
			this.m_strMove = _str;
			this.init( _str );
		}
	};


	//Initers for special cases...
	static g_regex = /^(K|Q|R|N|B)?([a-h]?)([1-8]?)(x?)([a-h])([1-8])(?:=?(K|Q|R|N|B))?$/;
	init( _strMv )
	{
		if ( PGNMove.g_regex.exec( _strMv ) )
		{
			this.m_mvd = Piece.fromString( RegExp.$1 );
			this.m_mvd = Piece.nominal( this.m_mvd );

			if ( RegExp.$2 )
				this.m_colFrom = Square.colFromString( RegExp.$2 );
			if ( RegExp.$3 )
				this.m_rowFrom = Square.rowFromString( RegExp.$3 );

			if ( RegExp.$4 )
				this.m_isTake = true;

			if ( RegExp.$5 )
				this.m_colTo = Square.colFromString( RegExp.$5 );
			if ( RegExp.$6 )
				this.m_rowTo = Square.rowFromString( RegExp.$6 );

			if ( RegExp.$7 )
			{
				this.m_prom = Piece.fromString( RegExp.$7 );
				this.m_prom = Piece.nominal( this.m_prom );
			}

			if ( this.m_rowFrom < 0 && this.m_mvd === Piece.NONE )
				this.m_mvd = Piece.PAWN;

			if ( this.m_colTo >= 0 && this.m_rowTo >= 0 )
				this.m_to = Square.I( this.m_colTo, this.m_rowTo );
		}
	};

	setNullMove()
	{
		this.m_isNullMove = true;
	};

	isNullMove()
	{
		return this.m_isNullMove;
	};

	setLongCastling()
	{
		this.m_isLongCastle = true;
	};

	isLongCastling()
	{
		return this.m_isLongCastle;
	};

	setShortCastling()
	{
		this.m_isShortCastle = true;
	};

	isShortCastling()
	{
		return this.m_isShortCastle;
	};

	setCheck( _chk )
	{
		this.m_isCheck = _chk;
	};

	setMate( _mate )
	{
		this.m_isMate = _mate;
	};

	isTake()
	{
		return this.m_isTake;
	};

	matches( _mv )
	{
		var from = _mv.from;
		var to = _mv.to;

		if ( this.m_to !== to )
			return false;

		if ( this.m_mvd !== Piece.NONE )
		{
			var mvd = _mv.getMoved();
			if ( Piece.nominal( mvd ) !== this.m_mvd )
				return false;
		}

		return this.matchesFrom( from );

	};

	matchesFrom( _fld )
	{
		if ( this.m_colFrom >= 0 )
			if ( this.m_colFrom !== Square.C( _fld ) )
				return false;

		if ( this.m_rowFrom >= 0 )
			if ( this.m_rowFrom !== Square.R( _fld ) )
				return false;

		return true;
	};

	getMoved()
	{
		return this.m_mvd;
	};

	getTo()
	{
		return this.m_to;
	};

	getProm()
	{
		return this.m_prom;
	};
}

//////////////////////////////////////////////////////////////////////////////////////////////////////

export class PGNComment
{
	constructor( _strComment )
	{
		//	this.m_strComment = this.preprocessComment( _strComment ); // TODO, still minifying problem with AnnoType below

		this.m_strComment = _strComment || "";
	};

	getComment()
	{
		return this.m_strComment;
	};

	//preprocessComment( _str )
	//{
	//	return _str.replace( /\/\\/g, "FOO" );
	//};

}

//////////////////////////////////////////////////////////////////////////////////////////////////////
export class PGNGlyph
{
	constructor( _glyph )
	{
		this.m_glyph = _glyph || 9;
	}

	getGlyph = function()
	{
		return this.m_glyph;
	}
}

//////////////////////////////////////////////////////////////////////////////////////////////////////

export class PGNMoveNum
{
	constructor( _num, _isBlack )
	{
		this.m_num = _num;
		this.m_isBlack = _isBlack;
	}
}

export class PGNGame
{
	constructor()
	{
		this.m_hdrs = {};
		this.m_mainLine = new PGNSubLine();
	}

	getHeaders = function()
	{
		return this.m_hdrs;
	};

	getMainLine = function()
	{
		return this.m_mainLine;
	};
}

//////////////////////////////////////////////////////////////////////////////////////////////////////

export class PGNParser
{
	constructor( _lexer )
	{
		if ( _lexer )
			this.m_lexer = _lexer;
		else
			this.m_lexer = new PGNLexer( "" );
	};

	acceptGame()
	{
		var gm = new PGNGame();
		if ( !this.acceptHeaders( gm.m_hdrs ) )
			return null;
		if ( !this.acceptGameNotation( gm ) )
			return null;
		return gm;
	};

	acceptGameNoMoves()
	{
		var gm = new PGNGame();
		if ( !this.acceptHeaders( gm.m_hdrs ) )
			return null;
		return gm;
	};

	acceptGameNotation( _gm )
	{
		this.acceptNotation( _gm.m_mainLine );
		var res = this.acceptResult();
		if ( res != null )
			_gm.m_hdrs.result = res;
		//Kam garantiert nach Result-Header.
		return true;
	};

	static isResultTokenType = function( _type )
	{
		return _type === TokenType.RES_0_1 ||
			_type === TokenType.RES_1_0 ||
			_type === TokenType.RES_1_2 ||
			_type === TokenType.RES_STAR;
	};

	acceptResult()
	{
		var tokRes = this.m_lexer.pop( PGNParser.isResultTokenType );
		if ( tokRes )
		{
			switch ( tokRes.type )
			{
				default:
				case TokenType.RES_0_1:
					return GameResultEnum.BLACK_WINS;
				case TokenType.RES_1_0:
					return GameResultEnum.WHITE_WINS;
				case TokenType.RES_1_2:
					return GameResultEnum.DRAW;
				case TokenType.RES_STAR:
					return GameResultEnum.LINE;
			}
		}
		return null;
	};

	acceptNotation( _line )
	{
		for ( var elem = this.acceptNotaElem();
			elem != null;
			elem = this.acceptNotaElem() )
		{
			_line.push( elem );
		}
		return true;
	};

	acceptNotaElem()
	{
		var elem = this.acceptMoveNum();
		if ( elem )
			return elem;

		elem = this.acceptMove();
		if ( elem )
			return elem;

		elem = this.acceptGlyph();
		if ( elem )
			return elem;

		elem = this.acceptSubLine();
		if ( elem )
			return elem;

		elem = this.acceptComment();
		if ( elem )
			return elem;

		return null;
	};

	// NH2020 made static
	static tokenTypeToGlyph( _type )
	{
		switch ( _type )
		{
			case TokenType.BAD:
				return SpecialGlyphs.BAD_MOVE;
			case TokenType.BAD_GOOD:
				return SpecialGlyphs.DUBIOUS_MOVE;
			case TokenType.GOOD:
				return SpecialGlyphs.GOOD_MOVE;
			case TokenType.GOOD_BAD:
				return SpecialGlyphs.SPECULATIVE_MOVE;
			case TokenType.VERY_GOOD:
				return SpecialGlyphs.VERY_GOOD_MOVE;
			case TokenType.VERY_BAD:
				return SpecialGlyphs.VERY_BAD_MOVE;
			default:
				return SpecialGlyphs.NONE;
		}
	}

	isGlyphType( _type )
	{
		return PGNParser.tokenTypeToGlyph( _type ) !== SpecialGlyphs.NONE;
	}

	acceptGlyph()
	{
		var tokBuck = this.m_lexer.popT( TokenType.BUCK );
		if ( tokBuck )
		{
			var tokNum = this.m_lexer.popT( TokenType.NUM );
			return new PGNGlyph( Number( tokNum.str ) );
		}

		var tokSpec = this.m_lexer.pop( this.isGlyphType );
		if ( tokSpec )
		{
			var glyph = this.tokenTypeToGlyph( tokSpec.type );
			return new PGNGlyph( glyph );
		}

		return null;
	};

	acceptSubLine()
	{
		var tokOp = this.m_lexer.popT( TokenType.VAR_OP );
		if ( tokOp )
		{
			var line = new PGNSubLine();

			this.acceptNotation( line );

			this.m_lexer.popT( TokenType.VAR_CL );

			return line;
		}
		return null;
	};

	acceptComment()
	{
		var tokComment = this.m_lexer.popT( TokenType.COMMENT );
		if ( tokComment )
		{
			return new PGNComment( tokComment.str );
		}
		return null;
	};

	acceptMoveNum()
	{
		var tokNum = this.m_lexer.popT( TokenType.NUM );
		if ( tokNum )
		{
			var cntDots = 0;
			
			while ( this.m_lexer.popT( TokenType.DOT ) != null )
				++cntDots;
			
			return new PGNMoveNum( Number( tokNum.str ), cntDots > 1 );
		}
		return null;
	};

	static isMoveTokenType = function( _type )
	{
		return _type === TokenType.MOVE ||
			_type === TokenType.LONG_ROCHADE ||
			_type === TokenType.SHORT_ROCHADE ||
			_type === TokenType.NULLMOVE;
	};

	acceptMove()
	{
		var tokMove = this.m_lexer.pop( PGNParser.isMoveTokenType );
		if ( tokMove )
		{
			var mv = new PGNMove();
			switch ( tokMove.type )
			{
				default:
				case TokenType.MOVE:
					mv.init( tokMove.str );
					break;
				case TokenType.LONG_ROCHADE:
					mv.setLongCastling();
					break;
				case TokenType.SHORT_ROCHADE:
					mv.setShortCastling();
					break;
				case TokenType.NULLMOVE:
					mv.setNullMove();
					break;
			}
			if ( tokMove.type !== TokenType.NULLMOVE )
			{
				var chk = this.m_lexer.popT( TokenType.CHECK ) != null;
				var mate = false;
				if ( !chk )
					mate = this.m_lexer.popT( TokenType.MATE ) != null;

				mv.setCheck( chk );
				mv.setMate( mate );
			}

			return mv;
		}
		return null;
	};

	acceptHeaders( _hdrs )
	{
		while ( this.acceptHeader( _hdrs ) ) { }
		return true;
	};

	acceptHeader( _hdrs )
	{
		if ( this.m_lexer.popT( TokenType.HEAD_OP ) )
		{
			var tokVar = this.m_lexer.popT( TokenType.STR_VAR );
			var tokVal = this.m_lexer.popT( TokenType.STR_VAL );

			this.m_lexer.popT( TokenType.HEAD_CL );

			var prop = tokVar.str.toLowerCase();
			_hdrs[ prop ] = tokVal.str;
			return true;
		}
		return false;
	};

	//////////////////////////////////////////////////////////////////////////////////////////////////////


	//////////////////////////////////////////////////////////////////////////////////////////////////////

	static toColSq = function( _arrArgs )
	{
		var res = new ColSqAnno();
		for ( var inx = 0, len = _arrArgs.length; inx < len; ++inx )
		{
			var strArg = _arrArgs[ inx ];

			var csq = new ColSq();
			csq.init( strArg );

			res.push( csq );
		}

		return res;
	};

	static toColArrow = function( _arrArgs )
	{
		var res = new ColArrowAnno();
		for ( var inx = 0, len = _arrArgs.length; inx < len; ++inx )
		{
			var strArg = _arrArgs[ inx ];

			var carr = new ColArrow();
			carr.init( strArg );

			res.push( carr );
		}

		return res;
	};

	static toDiag = function( _arrArgs )
	{
		var strLegend = "";
		if ( _arrArgs.length > 0 )
			strLegend = _arrArgs[ 0 ];
		return new TextAnno( strLegend );
	};

	static toTime = function( _arrArgs )
	{
		var time = _arrArgs[ 0 ];

		var arrParts = time.split( ':' );
		var secs = Number( arrParts[ 0 ] ) * 3600 + Number( arrParts[ 1 ] ) * 60 + Number( arrParts[ 2 ] );

		var res = new TimeAnno();
		res.fromCentiSecs( 100 * secs );

		return res;
	};

	static toEval = function( _arrArgs )
	{
		var myeval = Number( _arrArgs[ 0 ] ) * 0.01;
		var depth = 0;
		if ( _arrArgs.length > 1 )
			depth = Number( _arrArgs[ 1 ] );
		return new EvalAnno( myeval, depth );
	};

	static toTraining = function( _arrArgs )
	{
		var res = new TrainingAnno();
		if ( _arrArgs.length >= 7 && _arrArgs[ 0 ].length === 2 ) // new format: language id
		{
			res.move = _arrArgs[ 4 ];

			for ( var l = 0; l < _arrArgs.length / 7; l++ )
			{
				if ( _arrArgs[ l * 7 ].length === 2 )
				{
					res.texts[ _arrArgs[ l * 7 ].toLowerCase() ] =
					{
						question: _arrArgs[ l * 7 + 1 ],
						help: _arrArgs[ l * 7 + 2 ],
					}
				}
			}
		}
		else // old format
		{
			res.move = _arrArgs[ 3 ];
			res.texts[ "en" ] =
			{
				question: _arrArgs[ 0 ],
				help: _arrArgs[ 1 ]
			}
		}
		res.fromPGN = true;

		return res;
	};

	static toEvalProfile = function( _arrArgs )
	{
		var evals = [];
		if ( _arrArgs.length > 3 )
		{
			var first = parseInt( _arrArgs[ 0 ] );
			var last = parseInt( _arrArgs[ 1 ] );
			for ( var i = 0; i < last - first + 1; i++ )
			{
				evals.push( parseInt( _arrArgs[ i + 2 ] ) );
			}
			var res = new EvalProfileAnno( first, last, evals );
			res.fromPGN = true;
			return res;
		}
	};

	static toMedal = function( _arrArgs )
	{
		var res = new MoveMedalAnno( parseInt( _arrArgs[ 0 ] ) );
		return res;
	};

	static PGNAnnoResolver =
		{
			"cal": { func: PGNParser.toColArrow, type: AnnoType.ARROW_LIST },
			"csl": { func: PGNParser.toColSq, type: AnnoType.SQUARE_LIST },
			"dia": { func: PGNParser.toDiag, type: AnnoType.DIAGRAM },
			"eval": { func: PGNParser.toEval, type: AnnoType.FRITZ_EVAL },
			"emt": { func: PGNParser.toTime, type: AnnoType.TIME },
			"tqu": { func: PGNParser.toTraining, type: AnnoType.TRAINING },
			"mdl": { func: PGNParser.toMedal, type: AnnoType.MOVE_MEDAL },
			"evp": { func: PGNParser.toEvalProfile, type: AnnoType.EVAL_PROFILE },
		};


	static g_regexEval = /(-?(?:\d?\d\.\s?\d\d?)|(?:#\d+))\/\s?(\d+)/;
	static toAnno = function( _mv, _strComment )
	{
		//Sonderfälle
		if ( _strComment.indexOf( "/" ) >= 0 )
		{
			var tmpComment = _strComment.replace( /\r/g, ' ' );
			tmpComment = tmpComment.replace( /\n/g, ' ' );
			tmpComment = tmpComment.replace( /\s\s/g, ' ' );
			//Das kann die Bewertung sein!

			var match = PGNParser.g_regexEval.exec( tmpComment );

			if ( match )
			{
				var myEval = match[ 1 ];
				myEval = myEval.replace( /\s/g, "" );
				if ( myEval.indexOf( "#" ) === 0 )
					myEval = 32000;
				else if ( myEval.indexOf( "-#" ) === 0 )
					myEval = -32000;
				else
					myEval = Number( myEval );

				var depth = Number( match[ 2 ] );
				var evalAnno = new EvalAnno( myEval, depth );

				_mv.setAnnoItem( AnnoType.FRITZ_EVAL, evalAnno );

				var inxStart = match.index;
				let len = match[ 0 ].length;

				_strComment = tmpComment.substring( 0, inxStart ) +
					tmpComment.substring( inxStart + len );
			}
		}

		var esc = _strComment.indexOf( "[%" ) >= 0;
		if ( esc )
		{
			var arrCmds = [];
			_strComment = PGNCommand.process( _strComment, arrCmds );

			for ( let inx = 0, len = arrCmds.length; inx < len; ++inx )
			{
				var cmd = arrCmds[ inx ];

				if ( cmd.m_cmd in PGNParser.PGNAnnoResolver )
				{
					var cmdToAnno = PGNParser.PGNAnnoResolver[ cmd.m_cmd ];
					var type = cmdToAnno.type;
					var anno = cmdToAnno.func( cmd.m_args );

					if ( anno )
					{
						//if ( type == AnnoType.TRAINING )		// wrong move ... need the next one.
						//{	anno.move = new Move( _mv.from, _mv.to, _mv.prom );
						//}
						_mv.setAnnoItem( type, anno );
					}
				}
			}
		}

		if ( _strComment )
			_mv.setAnnoItem( AnnoType.POSTTEXT, new TextAnno( _strComment ) );

	};

}

//////////////////////////////////////////////////////////////////////////////////////////////////////
//Shtoby gladenko...
export class PGN
{
	static checkUndefined( _str )
	{
		if ( _str === undefined )
			return "";
		return _str === "?" ? "" : _str;
	}

	static isDefined( _str )
	{
		return _str && _str !== "?";
	}

	static toHeader( _pgnHdr )
	{
		var hdr = new GameHeader();

		hdr.setWhite( PGN.checkUndefined( _pgnHdr.white ) );
		hdr.setBlack( PGN.checkUndefined( _pgnHdr.black ) );

		hdr.setEvent( PGN.checkUndefined( _pgnHdr.event ) );
		hdr.setSite( PGN.checkUndefined( _pgnHdr.site ) );

		hdr.setAnnotator( PGN.checkUndefined( _pgnHdr.annotator ) );

		hdr.setECO( PGN.checkUndefined( _pgnHdr.eco ) );

		var res = _pgnHdr.result;
		if ( typeof ( res ) === "string" )
		{
			hdr.setResult( GameResultEnum.fromString( res ) );
		}
		else
		{
			hdr.setResult( res );
		}

		hdr.setRoundStr( PGN.checkUndefined( _pgnHdr.round ) );

		if ( PGN.isDefined( _pgnHdr.whiteelo ) )
		{
			hdr.setEloWhite( parseInt( _pgnHdr.whiteelo ) );
		}

		if ( PGN.isDefined( _pgnHdr.blackelo ) )
		{
			hdr.setEloBlack( parseInt( _pgnHdr.blackelo ) );
		}

		if ( PGN.isDefined( _pgnHdr.date ) )
		{
			hdr.setDateStr( _pgnHdr.date );
		}

		if ( PGN.isDefined( _pgnHdr.timecontrol ) )
		{
			hdr.setClockParams( PGN.toClockParams( _pgnHdr.timecontrol ) );
		}

		if ( PGN.isDefined( _pgnHdr.plycount ) )
		{
			hdr.plyCount = ( parseInt( _pgnHdr.plycount ) + 1 ) >> 1;
		}

		return hdr;
	}

	static rexCP = /^(\d+)(?:\+(\d+))?$/;
	static toClockParams( _str )
	{
		var res = PGN.rexCP.exec( _str );
		if ( !res )
			return new ClockParams();
		return new ClockParams( Number( res[ 1 ] ) / 60, Number( res[ 2 ] ) );
	}

	static toGame( _pgnGM )
	{
		//Headers vorerst ignorieren...
		//Und nun...
		var fen = null;
		var hdrs = _pgnGM.getHeaders();
		if ( /*hdrs.setup && */ hdrs.fen )
			fen = hdrs.fen;

		var hdr = PGN.toHeader( hdrs );

		var start = new Position( fen );
		var pgnLine = _pgnGM.getMainLine();
		var mainLine = PGN.toLine( start, pgnLine );

		var gmRes = new Game( start, mainLine );
		gmRes.setHeader( hdr );

		//	CBDebug.call( gmRes, gmRes.selfTest );

		return gmRes;
	}

	static toMove( _pgnMv, _pos )
	{
		if ( _pgnMv.isLongCastling() )
		{
			let mv = _pos.isWTM() ? Move.g_mvW000 : Move.g_mvB000;
			if ( PGN.trustLegal || _pos.isLegalMove( mv ) )
				return ObjUtil.clone( mv );
		}

		if ( _pgnMv.isShortCastling() )
		{
			let mv = _pos.isWTM() ? Move.g_mvW00 : Move.g_mvB00;
			if ( PGN.trustLegal || _pos.isLegalMove( mv ) )
				return ObjUtil.clone( mv );
		}

		if ( _pgnMv.isNullMove() )
		{
			return new Move();
		}

		var to = _pgnMv.getTo();
		var mvd = _pgnMv.getMoved();

		var sd = _pos.getSideToMove();
		var prom = _pgnMv.getProm();


		if ( mvd !== Piece.PAWN )
			prom = Piece.NONE;

		var arrAttks = [];
		switch ( mvd )
		{
			case Piece.KNIGHT:
				_pos.getKnightAttacksTo( to, sd, arrAttks );
				break;
			case Piece.BISHOP:
				_pos.getBishopAttacksTo( to, sd, arrAttks );
				break;
			case Piece.ROOK:
				_pos.getRookAttacksTo( to, sd, arrAttks );
				break;
			case Piece.QUEEN:
				_pos.getQueenAttacksTo( to, sd, arrAttks );
				break;
			case Piece.KING:
				_pos.getKingAttacksTo( to, sd, arrAttks );
				break;
			case Piece.PAWN:
				if ( _pgnMv.isTake() )
				{
					var pcVct = _pos.getPiece( to );
					if ( pcVct === Piece.NONE && to === _pos.getEPField() )
					{
						var fldEP = _pos.getEPVictimField();
						pcVct = _pos.getPiece( fldEP );
					}
					if ( pcVct !== Piece.NONE && Piece.side( pcVct ) !== sd )
					{
						_pos.getPawnAttacksTo( to, sd, arrAttks );
					}
				}
				else
				{
					let pcVct = _pos.getPiece( to );
					if ( pcVct === Piece.NONE )
						_pos.getPawnMovesTo( to, sd, arrAttks );
				}
				break;
			default:
				return null;
		}

		if ( !arrAttks.length )
			return null;
		if ( arrAttks.length === 1 && PGN.trustLegal )
			return new Move( arrAttks[ 0 ], to, prom );

		//Es gib't mehr als einen Kandidat!!!
		var funFltr = _pgnMv.matchesFrom.bind( _pgnMv );
		arrAttks = arrAttks.filter( funFltr );
		if ( !arrAttks.length )
			return null;
		if ( arrAttks.length === 1 && PGN.trustLegal )
			return new Move( arrAttks[ 0 ], to );

		var fnFldToMv = function( _from )
		{
			return new Move( _from, to );
		};

		var arrMvs = arrAttks.map( fnFldToMv );

		arrMvs = _pos.filterLegals( arrMvs );
		if ( !arrMvs.length )
			return null;
		if ( arrMvs.length === 1 )
			return arrMvs[ 0 ];

		return null;
	}

	static detectLineEnd( _strPGN )
	{
		var arrEnds = [ '\r\n', '\n', '\r' ];
		for ( var inx = 0, len = arrEnds.length; inx < len; ++inx )
		{
			var end = arrEnds[ inx ];
			if ( _strPGN.indexOf( end ) >= 0 )
				return end;
		}
		return null;
	}

	static fixMixed( _strPGN )
	{
		_strPGN = _strPGN.replace( /\r\n/g, '\n' );
		_strPGN = _strPGN.replace( /\r/g, '\n' );

		return _strPGN;
	}

	static canBeMixed( _strPGN )
	{
		return _strPGN.indexOf( '\r\r' ) >= 0 ||
			_strPGN.indexOf( '\n\n' ) >= 0;
	}

	static splitGames = function( _strPGN )
	{
		var firstToken = _strPGN.indexOf( "[" );
		if ( firstToken === -1 )
			firstToken = _strPGN.indexOf( "1." );
		if ( firstToken > 0 )
			_strPGN = _strPGN.slice( firstToken );

		var arrRes = [];
		//Split in PGNs...

		var lineEnd = PGN.detectLineEnd( _strPGN );

		if ( lineEnd == null )
		{
			//Nix zu holen...
			return [ _strPGN ];
		}

		if ( lineEnd === '\r\n' )
		{
			if ( PGN.canBeMixed( _strPGN ) )
			{
				_strPGN = PGN.fixMixed( _strPGN );
				lineEnd = '\n';
			}
		}

		var delim = lineEnd + lineEnd + '[';
		var lenShift = 2 * lineEnd.length;

		var lastInx = 0;
		for (
			var inxDelim = _strPGN.indexOf( delim, lastInx );
			inxDelim !== -1 && lastInx < _strPGN.length;
			inxDelim = _strPGN.indexOf( delim, lastInx ) )
		{
			var pgn = _strPGN.substring( lastInx, inxDelim );
			arrRes.push( pgn );
			lastInx = inxDelim + lenShift;
		}

		if ( lastInx < _strPGN.length )
		{
			var lastPGN = _strPGN.substring( lastInx );
			arrRes.push( lastPGN );
		}

		return arrRes;
	};

	static g_regexHdr = /\[(\w+)\s+"([\s\S]*?)"\]/g;
	static g_regexWS = /^\s*$/;

	static repair = function( _strPGN )
	{
		//Was tun? Alle [xx] in einzelnen Zeilen reintun. Nachder letzen, vor der ersten - neue Zeile.
		var lineEnd = PGN.detectLineEnd( _strPGN );

		if ( !lineEnd )
			lineEnd = '\r\n';

		var regexHdr = PGN.g_regexHdr;
		var regexWS = PGN.g_regexWS;

		var sb = new StringBuilder();

		regexHdr.lastIndex = 0;
		var inxLast = 0;
		var res = null;
		while ( ( res = regexHdr.exec( _strPGN ) ) != null )
		{
			var inxStart = res.index;

			var strBetween = _strPGN.substring( inxLast, inxStart );

			if ( !regexWS.test( strBetween ) )
			{
				sb.append( lineEnd );
				sb.append( strBetween.trim() );
				sb.append( lineEnd );
				sb.append( lineEnd );
			}

			var strVar = res[ 1 ];
			var strVal = res[ 2 ];

			strVal = strVal.replace( /\r/g, " " );
			strVal = strVal.replace( /\n/g, " " );

			sb.appendFormat( '[{0} "{1}"]', strVar, strVal );
			sb.append( lineEnd );

			inxLast = inxStart + res[ 0 ].length;
		}

		var strRest = _strPGN.substring( inxLast );

		if ( !regexWS.test( strRest ) )
		{
			sb.append( lineEnd );
			sb.append( strRest.trim() );
			sb.append( lineEnd );
			sb.append( lineEnd );
		}

		var strRes = sb.toString();


		return strRes;
	};

	static trustLegal = true;

	static parseGame ( _strPGN )
	{
		if ( _strPGN )
		{
			var lexer = new PGNLexer( _strPGN );
			var parser = new PGNParser( lexer );

			var pgnGM = parser.acceptGame();
			if ( !pgnGM )
				return null;
			var gm = PGN.toGame( pgnGM );

			//	CBDebug.profileEnd();

			return gm;
		}
		else
			return new Game();
	};

	static parseGameNoMoves = function( _strPGN )
	{
		if ( _strPGN )
		{
			var lexer = new PGNLexer( _strPGN );
			var parser = new PGNParser( lexer );

			var pgnGM = parser.acceptGameNoMoves();
			if ( !pgnGM )
				return null;
			var gm = PGN.toGame( pgnGM );

			return gm;
		}
		else
			return new Game();
	};

	static parseHeader = function( _strPGN )
	{
		//		CBDebug.profile( "parseHeader" );

		var lexer = new PGNLexer( _strPGN );
		var parser = new PGNParser( lexer );

		var pgnHdr = {};

		parser.acceptHeaders( pgnHdr );
		if ( !pgnHdr )
			return null;
		var hdr = PGN.toHeader( pgnHdr );

		//	CBDebug.profileEnd();

		return hdr;
	};

	static toLine( _start, _pgnLine )
	{
		var cur = ObjUtil.clone( _start );
		var lineRes = new MoveLine();
		var undoLast = null;
		var mvLast = null;

		var preComment = null;

		for ( var inx = 0, len = _pgnLine.length; inx < len; ++inx )
		{
			var elem = _pgnLine[ inx ];

			if ( elem instanceof PGNMoveNum )
				continue;

			if ( elem instanceof PGNMove )
			{
				var mv = PGN.toMove( elem, cur );
				if ( !mv )
					break;

				//	CBDebug.call( cur, cur.isLegalMove, mv );

				undoLast = cur.makeMove( mv );
				mvLast = mv;
				lineRes.push( mv );

				if ( preComment )
				{
					if ( preComment.m_strComment && preComment.m_strComment.indexOf( "[%" ) === 0 )
						PGNParser.toAnno( mvLast, preComment.getComment() );
					else
						mvLast.setAnnoItem( AnnoType.PRETEXT, new TextAnno( preComment.getComment() ) );
					preComment = null;
				}

				continue;
			}

			if ( elem instanceof PGNSubLine )
			{
				if ( !mvLast )
					continue;

				//Wird dadrunter geklont...
				cur.unmakeMove( mvLast, undoLast );
				var lineSub = PGN.toLine( cur, elem );
				mvLast.addLine( lineSub );

				var inxMv = lineRes.length - 1;

				lineSub.setParentLine( lineRes );
				lineSub.setParentMoveIndex( inxMv );

				cur.makeMove( mvLast );

				continue;
			};

			if ( elem instanceof PGNComment )
			{
				if ( !mvLast )
				{
					preComment = elem;
				}
				else
				{
					var strComment = elem.getComment();

					PGNParser.toAnno( mvLast, strComment );


				}
				continue;
			}

			if ( elem instanceof PGNGlyph )
			{
				if ( mvLast )
				{
					var symbols = mvLast.getAnnoItem( AnnoType.SYMBOL );
					if ( !symbols )
						symbols = new SymbolsAnno();
					symbols.push( elem.getGlyph() );	// symbols used as an array here
					mvLast.setAnnoItem( AnnoType.SYMBOL, symbols );

					continue;
				}

			}

			continue;
		};

		for ( var i = 0; i < lineRes.length - 1; i++ )
		{
			if ( lineRes[ i ].hasAnno() && lineRes[ i ].getAnno().hasTraining() )
			{
				var t = lineRes[ i ].getAnno().getTraining();
				var _mv = lineRes[ i + 1 ];	// next move, CB++ convention
				t.move = new Move( _mv.from, _mv.to, _mv.prom );
			}
		}

		return lineRes;
	}

}

export class PGNCommand
{
	constructor( _cmd, _args )
	{
		if ( !_cmd )
			_cmd = "";
		if ( !_args )
			_args = [];
		this.m_cmd = _cmd;
		this.m_args = _args;
	}

	/*
	Ein Command \w+
	Ein Parameter [^",\]]* | "[^"]*"\s*
	*/

	init( _strCmd, _strParams )
	{
		this.m_cmd = _strCmd;
		PGNCommand.initArgs( _strParams, this.m_args );
	};

	static initArgs = function( _strArgs, _args )
	{
		_strArgs = _strArgs.replace( /[\n]/g, " " );
		_strArgs = _strArgs.replace( /[\r]/g, "" );

		// var regexArg = /\s*(\"[^\",]*\"|[^\",]*)\s*($|,)/g; // geht nicht bei quoted strings.
		var regexArg = /(".*?")|([-\w\n]+),|([-:\w\n]+)$/g;	// neu, mw 7/16. Inkl Umlauts (Olivers Kolumne)
		regexArg.lastIndex = 0;
		var more = false;
		do
		{
			var match = regexArg.exec( _strArgs );
			if ( match )
			{
				var strArg = match[ 1 ] || match[ 2 ] || match[ 3 ];
				if ( strArg )
				{
					if ( strArg[ 0 ] === "\"" )
					{
						strArg = strArg.substring( 1 );
						if ( strArg[ strArg.length - 1 ] === "\"" )
							strArg = strArg.substring( 0, strArg.length - 1 );
					}
					_args.push( strArg );

					if ( regexArg.lastIndex === match.index )
						++regexArg.lastIndex;
					more = match.index >= 0;
				}
				else
					break;
			}
			else
				break;
		} while ( more );
	};

	static g_regexWhole = /\[%(\w+)((?:"[^"]*"|[^"\]]*)*)\]/;
	static process = function( _strComment, _arrCmds )
	{
		while ( _strComment )
		{
			var match = PGNCommand.g_regexWhole.exec( _strComment );
			if ( match )
			{
				var cmd = new PGNCommand();
				var strCmd = match[ 0 ];
				cmd.init( match[ 1 ], match[ 2 ] );

				_arrCmds.push( cmd );

				_strComment = _strComment.substring( 0, match.index ) +
					_strComment.substring( match.index + strCmd.length );
			}
			else
				break;
		}
		return _strComment;
	};
}

