// mw 26.3.2013

// ES6 3.3.2020

import { EloRating } from "common/Chess/PlayChess/EloRating";

export class ClockParams
{
	constructor( minutes, incSecs )
	{
		if ( minutes !== undefined )
			this.startMins = minutes;
		else
			this.startMins = 0;
		this.incSecs = incSecs || 0;
		if ( this.startMins < 1 && this.incSecs ===0 )
			this.startMins = 1;
		this.addMins = 0;
		this.addSecs = 0;
		this.flags = 0;
	};

	static POOL_BLITZ = 1;	// flags

	fromDataBuf( buf )
	{
		/// <param name="buf" type="DataBuffer"></param>
		this.startMins = buf.readInt32() & 0x0000ffff;
		this.incSecs = buf.readInt32() & 0x0000ffff;
		this.addMins = buf.readInt16();
		this.addSecs = buf.readInt16();
		this.flags = buf.readInt32();
	};

	toDataBuf( buf )
	{
		/// <param name="buf" type="DataBuffer"></param>
		buf.writeInt32( this.startMins & 0x0000ffff );
		buf.writeInt32( this.incSecs & 0x0000ffff );
		buf.writeInt16( this.addMins );
		buf.writeInt16( this.addSecs );
		buf.writeInt32( this.flags );
	};

	toString()
	{
		return this.startMins + "m + " + this.incSecs + "s";
	};

	//setPoolBlitz( b )
	//{
	//	setFlag( this, ClockParams.POOL_BLITZ, b );
	//};

	//isPoolBlitz( b )
	//{
	//	isFlag( this.flags, ClockParams.POOL_BLITZ );
	//};

	canBePoolBlitz()
	{
		return this.addMins === 0 && this.addSecs === 0
			&& this.incSecs === 0
			&& ( this.startMins === 1 || this.startMins === 3 || this.startMins === 5 );
	};

	static createFromSeekParams = function ( seekParams )
	{
		var cp = new ClockParams( 3, 0 );
		if ( seekParams )
		{
			var params = seekParams.split( ',' );
			if ( params.length )
			{
				cp.startMins = params[0];
				if ( cp.startMins === 0 )
					cp.startMins = 5;
				if ( params.length > 1 )
					cp.incSecs = params[1];
			}
		}
		return cp;
	};

	getSortVal()
	{
		return this.startMins * 40 + this.incSecs;
	};

	calcRatingType()
	{
		if ( this.getAvGameTimeSeconds() > 900 )
			return EloRating.SLOW;
		else if ( this.getAvGameTimeSeconds() >= 180 )
			return EloRating.BLITZ;

		return EloRating.BULLET;
	};

	getGameTypeName()
	{
		switch( this.calcRatingType() )
		{
			default:
			case EloRating.BULLET:
				return "Bullet";
			case EloRating.BLITZ:
				return "Blitz";
			case EloRating.SLOW:
				return "Slow";
		}
	};

	getAvGameTimeSeconds()
	{
		return this.startMins * 60 + 40 * this.incSecs;
	};

	isBlitz()
	{
		return this.calcRatingType() === EloRating.BLITZ;
	};

	isBullet()
	{
		return this.calcRatingType() === EloRating.BULLET;
	};

	isSlow()
	{
		return this.calcRatingType() === EloRating.SLOW;
	};

	getStartSecs()
	{
		return this.startMins * 60;
	};

}