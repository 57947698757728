// "use strict";

import { Title } from "common/Chess/Tools/CBTitle";

export class LowPlayerData {

   // constructor () {
   // };
   
   static PLMAX = {
      MAX_NATIONS : 10,
      MAX_TITLES : 5
   };

   read (pBuf) {
      this.m_Titles = [];
      this.m_TitleDate = [];
      this.m_OtherNations = [];
      this.m_ChangeDate = [];

      for (var i = 0; i < LowPlayerData.PLMAX.MAX_TITLES; i++) {
         var aTitle = new Title(pBuf.readUint16());
         this.m_Titles.push(aTitle);
         var aDate = pBuf.readCBDate();
         this.m_TitleDate.push(aDate);
      }
      this.m_ChangedBornNation = pBuf.readCBDate();
      for (var i = 0; i < LowPlayerData.PLMAX.MAX_NATIONS; i++) {
         var nNation = pBuf.readUint16();
         this.m_OtherNations.push(nNation);
         var aDate = pBuf.readCBDate();
         this.m_ChangeDate.push(aDate);
      }
   };

   getPrevTitle (n) {
      return this.m_Titles[n];
   };

   setPrevTitle (n, rT) {
      this.m_Titles[n] = rT;
   };

   getPrevTitleDate (n) {
      return this.m_TitleDate[n];
   };

   setPrevTitleDate (n, rD) {
      this.m_TitleDate[n] = rD;
   };

   getChangedBornNation () {
      return this.m_ChangedBornNation;
   };

   setChangedBornNation (rD) {
      this.m_ChangedBornNation = rD;
   };

   getNextNation (n) {
      return this.m_OtherNations[n];
   };

   setNextNation (n, rN) {
      this.m_OtherNations[n] = rN;
   };

   getNextChangedDate (n) {
      return this.m_ChangeDate[n];
   };

   setNextChangedDate (n, rD) {
      this.m_ChangeDate[n] = rD;
   };

   getMaxTitles () {
      return LowPlayerData.PLMAX.MAX_TITLES;
   };

   getMaxNations () {
      return LowPlayerData.PLMAX.MAX_NATIONS;
   };

   // welchen Titel
   // wann erhalten
   // wann der erste L‰nderwechsel
   // bis wann

}

