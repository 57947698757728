import { useEffect, useRef } from 'react';

export function useAfterMountEffect (callback, dependencyArray)
{
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current)
            callback();
        else
            didMount.current = true;
    }, dependencyArray);
}

