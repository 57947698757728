
import React from 'react';
import styles from "./CBTableResizer.module.css";

export function CBTableResizer (props)
{
    var lastPos = 0;

    function dragStart(e)
    {
        if (e.cancelable) 
            e.preventDefault();

        e.stopPropagation();
        
        window.addEventListener('mousemove', drag);
        window.addEventListener('mouseup', dragStop);
        window.addEventListener('touchmove', drag);
        window.addEventListener('touchend', dragStop);

        lastPos = e.touches ? e.touches[0].clientX : e.clientX;
    }

    function dragStop(e)
    {
        if (e.cancelable) 
            e.preventDefault();

        e.stopPropagation();

        window.removeEventListener('mousemove', drag);
        window.removeEventListener('mouseup', dragStop);
        window.removeEventListener('touchmove', drag);
        window.removeEventListener('touchend', dragStop);
    }

    function drag(e)
    {
        var mouseX = e.touches ? e.touches[0].clientX : e.clientX;
        var dragOffset = mouseX - lastPos;
        lastPos = mouseX;
        // console.log(dragOffset);

        if (props.headerParent.current.firstChild)
        {
            var headerSize = props.headerParent.current.offsetWidth;
            var cell = props.headerParent.current.firstChild;
            var currentPixelSizes = [cell.offsetWidth];
            var newFlexGrow = [];
            for (var i = 0; i < props.flexGrow.length; i++)
                newFlexGrow.push(0);

            // Get Current Pixel Sizes of all cells
            var accumulatedFlex = 0;
            var cellIndex = 0
            while (cell.nextSibling)
            {
                currentPixelSizes.push(cell.nextSibling.offsetWidth);

                cellIndex += 1;
                if (cellIndex > props.index) // props.index sollte index der gerenderten Zelle wiederspiegeln
                    accumulatedFlex += props.flexGrow[props.cellsToRender[cellIndex]];

                cell = cell.nextSibling;
            }

            // Set new Pixel Size of dragged cell
            currentPixelSizes[props.index] = currentPixelSizes[props.index] + dragOffset;

            // Add final pixel sizes for all cells before the selected cell + selected cell
            var pixelsTaken = 0;
            for (let i = 0; i < currentPixelSizes.length; i++)
            {
                if (i > props.index)
                    break;
                
                pixelsTaken += currentPixelSizes[i];
                newFlexGrow[props.cellsToRender[i]] = currentPixelSizes[i];
            }

            // Calculate pixel sizes of remaining cells, based on previous flex sizes
            var pixelsToBeTaken = headerSize - pixelsTaken;
            for (let i = props.index + 1; i < currentPixelSizes.length; i++)
            {
                var takePixels = (props.flexGrow[props.cellsToRender[i]] * pixelsToBeTaken) / accumulatedFlex;

                newFlexGrow[props.cellsToRender[i]] = takePixels;
            }

            // Set new Cell sizes
            if (props.tableDispatch)
                props.tableDispatch({ type: 'RESIZE', newFlexGrow });
            else 
                console.warn("No Table Dispatch Function defined.");
                
        }
    }

    return (
        <div 
            className={styles.base}
            onTouchStart={dragStart}
            onMouseDown={dragStart} >
            <div className={styles.visible}>
            </div>
        </div>
    );  
}

function resizerPropsAreEqual(prev, next)
{
    return prev.cellsToRender === next.cellsToRender && prev.flexGrow === next.flexGrow;
}

export const CBMTableResizer = React.memo(CBTableResizer, resizerPropsAreEqual);