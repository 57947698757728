// ST 2011
// ES 6 MW: Feb 27, 2020

import { Piece, CastleRights } from 'common/Chess/Logic/Chess'

export class Undo
{
	// NH2021 Added uniqePiecePositions
	constructor( _pos, _mv )
	{
		if ( _pos !== undefined )
		{
			this.cr = _pos.cr;
			this.ep = _pos.ep;
			this.mvd = _pos.board[ _mv.from ];
			this.vct = _pos.board[ _mv.to ];
			
			this.mvdUnique = _pos.uniquePiecePositions[_mv.from];
			this.vctUnique = _pos.uniquePiecePositions[_mv.to];
		}
		else
		{
			this.cr = CastleRights.NONE;
			this.ep = 0;
			this.vct = Piece.NONE;
			this.mvd = Piece.NONE;

			// NH2021 TODO Maybe add default values for mvdUnique and vctUnique
			console.trace("UNDO CONSTRUCTOR")
		}

		if ( _mv !== undefined )
			this.fldVct = _mv.to;

		this.to2 = 0;
		this.from2 = 0;
	}

	// NH2021 Added Unique Values
	setEP( _vct, _fldVct, _vctUnique )
	{
		this.fldVct = _fldVct;
		this.vct = _vct;

		if (_vctUnique)
			this.vctUnique = _vctUnique;
	};

	// NH2021 Added Unique Values
	setCastle( _from2, _to2 )
	{
		this.from2 = _from2;
		this.to2 = _to2;
	};

}


