// ST 2011
// ES6 3.3.2020

import { EnumToString } from 'common/Patterns/ObjectUtil';
import { StringIterator, Char } from "common/Tools/Strings/CharUtil";
import { Piece } from 'common/Chess/Logic/Chess';

export var TokenType = {
	NONE: 0,
	UNKNOWN: 1,
	EOF: 2,
	HEAD_OP: 3,
	HEAD_CL: 4,
	STR_VAR: 5,
	STR_VAL: 6,
	COMMENT: 7,
	VAR_OP: 8,
	VAR_CL: 9,
	RES_STAR: 10,
	RES_1_0: 11,
	RES_0_1: 12,
	RES_1_2: 13,
	BUCK: 14,
	CHECK: 15,
	MATE: 16,
	MOVE: 17,
	ROCHADE: 18,
	NULLMOVE: 19,
	NUM: 20,
	DOT: 21,
	LONG_ROCHADE: 22,
	SHORT_ROCHADE: 23,
	BAD: 24,
	VERY_BAD: 25,
	GOOD: 26,
	VERY_GOOD: 27,
	BAD_GOOD: 28,
	GOOD_BAD: 29
};

function Token( _type, _str )
{
	this.type = _type;
	if ( _str )
		this.str = _str;
};

Token.prototype.type = TokenType.UNKNOWN;
Token.prototype.str = "";

Token.prototype.toString = function()
{
	var res = EnumToString( TokenType, this.type );
	if ( this.str )
		res += "(" + this.str + ")";
	return res;
};

export class PGNTokenStream
{
	constructor( _strPGN )
	{
		this.m_it = new StringIterator( _strPGN || "" );
		this.m_it.Next();

		this.m_inHeader = false;
	
	}

	nextToken()
	{
		//WhiteSpace v pizdu.
		this.m_it.skipWhile( Char.IsWhiteSpace, true );

		//Sind wird am Ende.
		if ( this.m_it.isEOF() )
			return new Token( TokenType.EOF );

		var ch = this.m_it.Current();
		this.m_it.Next();

		switch ( ch )
		{
			default:
				break;
			case 'X':
			case '#':
				return new Token( TokenType.MATE );
			case '+':
				return new Token( TokenType.CHECK );
			case '$':
				return new Token( TokenType.BUCK );
			case '*':
				return new Token( TokenType.RES_STAR, ch );
			case '[':
				this.m_inHeader = true;
				return new Token( TokenType.HEAD_OP );
			case ']':
				this.m_inHeader = false;
				return new Token( TokenType.HEAD_CL );
			case '(':
				return new Token( TokenType.VAR_OP );
			case ')':
				return new Token( TokenType.VAR_CL );
			case '.':
				return new Token( TokenType.DOT );
		}

		if ( this.m_inHeader )
		{
			if ( Char.IsLetterOrDigit( ch ) )
			{
				var hdrVar = ch + this.m_it.AcceptWhile( Char.IsLetterOrDigit, true );
				return new Token( TokenType.STR_VAR, hdrVar );
			}

			if ( ch === '"' )
			{
				//HeaderWert!
				var hdrVal = this.m_it.AcceptUntil( Char.getIsChar( ']' ), true );
				hdrVal = hdrVal.trim();
				if ( hdrVal.charAt( hdrVal.length - 1 ) === '"' )
					hdrVal = hdrVal.slice( 0, hdrVal.length - 1 );
				return new Token( TokenType.STR_VAL, hdrVal );
			}
		}

		if ( ch === '{' )
		{
			var strComment = this.m_it.AcceptUntil( Char.getIsChar( '}' ), true );
			this.m_it.skipWhile( Char.getIsChar( '}' ), true );

			return new Token( TokenType.COMMENT, strComment );
		}

		if ( ch === '0' )
		{
			//Rochaden???
			if ( this.m_it.AcceptStr( "-0-0", true ) )
				return new Token( TokenType.LONG_ROCHADE );
			if ( this.m_it.AcceptStr( "-0", true ) )
				return new Token( TokenType.SHORT_ROCHADE );
			if ( this.m_it.AcceptStr( "-1", true ) )
				return new Token( TokenType.RES_0_1 );
		}

		if ( ch === 'O' )
		{
			//Rochaden???
			if ( this.m_it.AcceptStr( "-O-O", true ) )
				return new Token( TokenType.LONG_ROCHADE );
			if ( this.m_it.AcceptStr( "-O", true ) )
				return new Token( TokenType.SHORT_ROCHADE );
		}

		if ( ch === '1' )
		{
			//Rochaden???
			if ( this.m_it.AcceptStr( "-0", true ) )
				return new Token( TokenType.RES_1_0 );
			if ( this.m_it.AcceptStr( "/2-1/2", true ) )
				return new Token( TokenType.RES_1_2 );


		}

		//Alle Sonderfälle sind nun abgehackt!
		if ( Char.IsDigit( ch ) )
		{
			var strNum = ch + this.m_it.AcceptWhile( Char.IsDigit, true );
			return new Token( TokenType.NUM, strNum );
		}

		if ( ch === '-' )
		{
			if ( this.m_it.AcceptChar( "-", true ) )
				return new Token( TokenType.NULLMOVE );
		}

		if ( ch === '?' )
		{
			if ( this.m_it.AcceptChar( "?", true ) )
				return new Token( TokenType.VERY_BAD );
			if ( this.m_it.AcceptChar( "!", true ) )
				return new Token( TokenType.BAD_GOOD );
			return new Token( TokenType.BAD );
		}

		if ( ch === '!' )
		{
			if ( this.m_it.AcceptChar( "?", true ) )
				return new Token( TokenType.GOOD_BAD );
			if ( this.m_it.AcceptChar( "!", true ) )
				return new Token( TokenType.VERY_GOOD );
			return new Token( TokenType.GOOD );
		}

		if ( PGNTokenStream.isMoveChar( ch ) )
		{
			//Nun schmerzvoll den Zug akzeptieren...
			var strMove = ch + this.m_it.AcceptWhile( PGNTokenStream.isMoveChar, true );
			return new Token( TokenType.MOVE, strMove );
		}

		return new Token( TokenType.UNKNOWN, ch );
	};

	static isMoveChar = function( _ch )
	{
		return ( _ch >= 'a' && _ch <= 'h' ) ||
			( _ch >= '1' && _ch <= '8' ) ||
			( _ch === 'x' ) ||
			( _ch === '=' ) ||
			( Char.IsUpperCaseLetter( _ch ) && Piece.fromString( _ch ) !== Piece.NONE );
	};
}

//LEXER

export class PGNLexer
{
	constructor( _strPGN ) 
	{
		this.m_stm = new PGNTokenStream( _strPGN );
		this.m_last = null;
	}

	peek( _func )
	{
		if ( !this.m_last )
			this.m_last = this.m_stm.nextToken();

		if ( _func && _func( this.m_last.type ) )
			return this.m_last;
		else
			return null;
	};

	static getIsType = function( _typeCmp )
	{
		return function( _type )
		{
			return _type === _typeCmp;
		};
	};

	pop( _func )
	{
		var res = this.peek( _func );
		if ( res )
			this.m_last = null;
		return res;
	};

	popT( _type )
	{
		return this.pop( PGNLexer.getIsType( _type ) );
	};

	peekT( _type )
	{
		return this.peek( PGNLexer.getIsType( _type ) );
	};
}