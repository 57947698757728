import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';

import * as serviceWorker from './serviceWorker';

import { CBHtmlRoot } from "CBReact/Components/HtmlRoot/CBHtmlRoot";
import { CBOnlineDBFrame } from "CBReact/Components/Frames/CBOnlineDBFrame";
import { CBNavBarSide } from "CBReact/Components/NavBars/CBNavBarSide";
import { CookieUtils } from 'CBReact/Utils/CookieUtils';
// import { GameKernel } from 'common/Chess/Logic/GameKernel';
import { GameKernelLight } from 'common/Chess/Logic/GameKernelLight';
import { OnlineDBMode } from 'common/Chess/PlayingModes/OnlineDBModeNew';
import { DOM } from "common/HTMLDocument/Text";
import { VersionCode, VersionCodeEnum } from "common/WebClient/VersionCode";
import { InitStringFormat } from "common/Tools/Strings/StringFormat";
import { AddStringFillIns } from "common/Tools/Strings/CharUtil";

// Import global css variables for Design Schemes
import "CBReact/DesignSchemes/OnlineDBSchemes.css"

import { CBLayoutContext } from 'CBReact/Contexts/CBOnlineDBContext';
import { CBLocalizationContext } from 'CBReact/Contexts/CBOnlineDBContext';
import { CBLoginContext } from 'CBReact/Contexts/CBOnlineDBContext';
import { CBOnlineLobbyContext } from 'CBReact/Contexts/CBOnlineDBContext';
import { CBPlayerLobbyContext } from 'CBReact/Contexts/CBOnlineDBContext';
import { CBTeraBrainLobbyContext } from 'CBReact/Contexts/CBOnlineDBContext';
import { CBThemeContext } from 'CBReact/Contexts/CBOnlineDBContext';
import { CBBoardThemeContext } from 'CBReact/Contexts/CBOnlineDBContext';
import { CBSideNavContext } from 'CBReact/Contexts/CBOnlineDBContext';
import { ClientParams } from 'common/WebClient/ClientParams';

// IMPORTANT: UNCOMMENT BEFORE BUILD
ReactGA.initialize('UA-2255141-21');
ReactGA.set({ anonymizeIp: true });
ReactGA.pageview("/index");

//#region Init Variables. This could be done differently in the future... 

InitStringFormat();
AddStringFillIns();

export var clientParams = new ClientParams("OnlineDB");

// TODO Replace DOM.getCookie Methoden with CookieUtils.GetCookie/ CookieUtils.GetStorageItem
clientParams.userName = DOM.getAccountNameCookie( true );
clientParams.loginToken = DOM.getCookie( "AccountToken" );

export var versionCode = new VersionCode( VersionCodeEnum.JSCBONLINE, 5, 5, 0 );

//#endregion

var gk = new GameKernelLight();
gk.setPlayingMode( new OnlineDBMode( gk ) );

// Load Game via Localstorage -> Not Cross App currently
var loadedGame = CookieUtils.LoadGameFromStorage();

// Load Game via classic cookie -> Uses cross app cookies
// var loadedGame = CookieUtils.LoadGameFromCookie();

if (loadedGame)
    gk.game.assign( loadedGame );

ReactGA.event({
    category: 'Version',
    action: 'OnlineDB React 1.0'
})

window.addEventListener('pagehide', (event) => {
    // Save Game as classic cookie -> Uses cross app cookies
    //CookieUtils.SaveGameAsCookie(gk.game);
    
    // Save Game to Localstorage: No Cross app cookies currently
    CookieUtils.SaveGameToStorage(gk.game);
});

// TODO: Find a nicer solution for all these contexts...
function OnlineDB ()
{
    return (
        <CBLayoutContext>
            <CBLocalizationContext>
                <CBLoginContext>
                    <CBOnlineLobbyContext>
                        <CBPlayerLobbyContext>
                            <CBTeraBrainLobbyContext>
                                <CBThemeContext>
                                    <CBBoardThemeContext>
                                        <CBSideNavContext>
                                            <CBHtmlRoot/>
                                            <CBOnlineDBFrame gameKernel={gk}/>
                                            <CBNavBarSide />
                                        </CBSideNavContext>
                                    </CBBoardThemeContext>
                                </CBThemeContext>
                            </CBTeraBrainLobbyContext>
                        </CBPlayerLobbyContext>
                    </CBOnlineLobbyContext>
                </CBLoginContext>
            </CBLocalizationContext>
        </CBLayoutContext>
    )
}

ReactDOM.render(
    <React.StrictMode>
        <OnlineDB/>
    </React.StrictMode>,
    document.getElementById( 'root' ) 
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
