import React, { useContext } from 'react';
import styles from "./CBSelectPromotion.module.css";

import { checkPropsAndRenderComponent } from 'CBReact/Utils/DebugUtils';
import { Piece } from "common/Chess/Logic/Chess.js";
import { BoardThemeContext } from 'CBReact/Contexts/CBOnlineDBContext';
import { CBMCloseIcon } from 'CBReact/Components/DesignElements/Icons/CBIcons';

export function CBSelectPromotion (props)
{

    const getPieceImageCss = useContext(BoardThemeContext).getPieceImageCss;

    function renderCBSelectPromotion ()
    {
        return (
            <div 
                className={`${styles.promotionContainer} ${props.fromTop ? styles.top : styles.bottom}`}
                style={{gridArea: props.squareName}}>
                <div 
                    className={`${styles.promotionElement} ${getPieceImageCss(props.isWhitePromo ? Piece.W_QUEEN : Piece.B_QUEEN)} ${styles.promotionQueen}`} 
                    onClick={() => {props.enterPromotion(Piece.W_QUEEN)}}
                    // style={{backgroundImage: `url(${getPieceImage(props.isWhitePromo ? Piece.W_QUEEN : Piece.B_QUEEN)})`}}
                    />
                <div 
                    className={`${styles.promotionElement} ${getPieceImageCss(props.isWhitePromo ? Piece.W_ROOK : Piece.B_ROOK)} ${styles.promotionRook}`}
                    onClick={() => {props.enterPromotion(Piece.ROOK)}} 
                    // style={{backgroundImage: `url(${getPieceImage(props.isWhitePromo ? Piece.W_ROOK : Piece.B_ROOK)})`}}
                    />
                <div 
                    className={`${styles.promotionElement} ${getPieceImageCss(props.isWhitePromo ? Piece.W_BISHOP : Piece.B_BISHOP)} ${styles.promotionBishop}`} 
                    onClick={() => {props.enterPromotion(Piece.BISHOP)}}
                    // style={{backgroundImage: `url(${getPieceImage(props.isWhitePromo ? Piece.W_BISHOP : Piece.B_BISHOP)})`}}
                    />
                <div 
                    className={`${styles.promotionElement} ${getPieceImageCss(props.isWhitePromo ? Piece.W_KNIGHT : Piece.B_KNIGHT)} ${styles.promotionKnight}`} 
                    onClick={() => {props.enterPromotion(Piece.KNIGHT)}}
                    // style={{backgroundImage: `url(${getPieceImage(props.isWhitePromo ? Piece.W_KNIGHT : Piece.B_KNIGHT)})`}}
                    />
                <div 
                    className={`${styles.promotionElement} ${props.fromTop ? styles.closePromotion : styles.closePromotionBottom}`} 
                    onClick={() => {props.enterPromotion()}}>
                        <div className={styles.closePromotionInner}>
                            <CBMCloseIcon className={styles.closePromotion} height={"34%"}/>
                        </div>
                </div>
            </div>
        );
    }

    return checkPropsAndRenderComponent([props.squareName, props.isWhitePromo, props.enterPromotion, props.fromTop], renderCBSelectPromotion)
}
