// EloRating, mw 28.1.2013

// ES6 3.3.2020

export class EloRating
{
	constructor ()
	{
		this.nElo = [];
		for ( var i = 0; i < this.RATING_COUNT_MAX; i++ )
			this.nElo.push( 0 );
		this.RATING_COUNT = 5;
		this.RATING_COUNT_MAX = 10;			
	}

	// use as EloRating.BLITZ
	static NONE = 0;
	static BULLET = 1;
	static BLITZ = 2;
	static SLOW = 4;
	static ENGINE = 8;	// not used in js
	static CENTAUR = 16;

	static POOL = 0x80;

	static POOL_BULLET =  EloRating.BULLET | EloRating.POOL;
	static POOL_BLITZ = EloRating.BLITZ | EloRating.POOL;

	

	fromDataBuf( buf )
	{
		/// <param name="buf" type="CB.DataBuffer"></param>
		for ( var i = 0; i < this.RATING_COUNT; i++ )
		{
			if ( i + 3 * this.RATING_COUNT < this.RATING_COUNT_MAX )
			{
				this.nElo[i] = buf.readInt16() & 0x0fff;
				this.nElo[i + 3 * this.RATING_COUNT] = buf.readInt16() & 0x0fff;
			}
			else
			{
				this.nElo[i] = buf.readInt32() & 0x0fff;
			}

			//	Rating-Blocks 2+3
			if ( i + 2 * this.RATING_COUNT < this.RATING_COUNT_MAX )
			{
				this.nElo[i + this.RATING_COUNT] = buf.readInt16() & 0x0fff;
				this.nElo[i + 2 * this.RATING_COUNT] = buf.readInt16() & 0x0fff;
			}
			else
			{
				this.nElo[i + this.RATING_COUNT] = buf.readInt32() & 0x0fff;
			}
		}
		buf.beginSizedRead();
		buf.endSizedRead();
	};


	toDataBuf( buf )
	{
		/// <param name="buf" type="CB.DataBuffer"></param>
		for ( var i = 0; i < this.RATING_COUNT; i++ )
		{
			if ( i + 3 * this.RATING_COUNT < this.RATING_COUNT_MAX )
			{
				buf.writeInt16( this.nElo[i] & 0x0fff );
				buf.writeInt16( this.nElo[i + 3 * this.RATING_COUNT] & 0x0fff );
			} else
			{
				buf.writeInt32( this.nElo[i] & 0x0fff );
			}
			if ( i + 2 * this.RATING_COUNT < this.RATING_COUNT_MAX )
			{
				buf.writeInt16( this.nElo[i + this.RATING_COUNT] & 0x0fff );
				buf.writeInt16( this.nElo[i + 2 * this.RATING_COUNT] & 0x0fff );
			} else
			{
				buf.writeInt( this.nElo[i + this.RATING_COUNT] & 0x0fff );
			}
		}
		buf.writeInt32( 0 );	// rest size
	}

	getStrElo( ratingType )
	{
		if ( this.getElo( ratingType ) > 0 )
			return this.getElo( ratingType );
		else
		{
			return "";
		}
	};

	getArrayIndex( ratingType )
	{
		if ( ratingType & EloRating.BULLET )
			return ( ratingType & EloRating.POOL ) ? 9 : 0;
		if ( ratingType & EloRating.BLITZ )
			return ( ratingType & EloRating.POOL ) ? 8 : 1;
		if ( ratingType & EloRating.SLOW )
			return 2;

		return 8;	//	Default: Human Pool Blitz
	}

	getElo( ratingType )
	{
		switch ( ratingType )
		{
			default:
			case EloRating.BULLET:
				return this.nElo[0];
			case EloRating.BLITZ:
				return this.nElo[1];
			case EloRating.SLOW:
				return this.nElo[2];
			case EloRating.POOL_BLITZ:
				return this.nElo[8];
			case EloRating.POOL_BULLET:
				return this.nElo[9];
		}
	};
	
	getHighestBlitzRating()
	{
		if ( this.nElo[8] > this.nElo[1] )
			return this.nElo[8];
		if ( this.nElo[1] )
			return this.nElo[1];

		return "";
	};
}

