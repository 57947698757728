import React, { useContext } from 'react';
import styles from "./CBNotationTools.module.css";
import FileSaver from 'file-saver';
import ReactGA from 'react-ga';

import { checkPropsAndRenderComponent } from "CBReact/Utils/DebugUtils"
import { CBButtonBadge } from 'CBReact/Components/DesignElements/Buttons/ButtonBadge/CBButtonBadge';
import { PGNWriter } from 'common/Chess/Format/PGN/PGNWriter';

import { LOGIN_MODES } from 'CBReact/Components/Account/AccountUtils';
import { LoginContext } from "CBReact/Contexts/CBOnlineDBContext"
import { CBDownloadIcon } from 'CBReact/Components/DesignElements/Icons/CBIcons';
import { System } from 'common/Tools/System';

export function CBNotationTools (props) {

    const loginStatus = useContext(LoginContext).loginStatus;
    
    function downloadPGN ()
	{
        var game = props.gameKernel.game;
        var strPGN = PGNWriter.toPGN( game );
        var strTitle = game.hdr.getStrPlayers( false, 0 ) + ".pgn";

        var hasBlob = window.Blob;

        if ( hasBlob )	// new way
        {
            // console.log( "Blob DL: " + strTitle );
            var blb = new Blob( [strPGN], { type: "text/plain;charset=utf-8" } );
            FileSaver.saveAs(blb, strTitle);

            ReactGA.event({
                category: 'Interaction',
                action: 'Downloaded PGN',
            });
        }
        else if ( System.getIEVersion() > 0 )
        {
        	// NH2020 Added window
        	if ( window.clipboardData && window.clipboardData.setData )
        	{
        		alert( "On Internet Explorer the game has been pasted to the clipboard ('Ctrl-V')" );
        		// NH2020 Added window
        		window.clipboardData.setData( "Text", strPGN );
        	}
        }
	};

    function handlePGNClick (e)
    {
        e.preventDefault();

        if (loginStatus === LOGIN_MODES.guest || loginStatus === LOGIN_MODES.free)
        {
            window.open( "https://account.chessbase.com/en/matrix", "_blank", "noopener");
        }
        else if (loginStatus === LOGIN_MODES.standard || loginStatus === LOGIN_MODES.premium)
        {
            downloadPGN();
        }
    }

    function renderCBNotationBar ()
    {
        return (
            <div className={styles.container} >
                <div className={styles.toolObject} >
                    <CBButtonBadge size="small" onClick={handlePGNClick} style={{paddingRight: "20px"}}>
                     {(loginStatus === LOGIN_MODES.guest || loginStatus === LOGIN_MODES.free) && "Premium | "}
                        PGN
                        <CBDownloadIcon height={"65%"}/>
                    </CBButtonBadge> 
                </div>
            </div>
        );
    }

    return checkPropsAndRenderComponent([props.gameKernel], renderCBNotationBar);

}