// ST 2011
// ES 6 MW: Feb 28, 2020

import { Side, Piece, Square } from "common/Chess/Logic/Chess";
import { Position } from "common/Chess/Logic/Position";
// import { Log } from "common/Tools/Log";

export var InvariantLocalization = {
	arrStrPieces: [],

	strTakes: "x",
	strCheck: "+",
	strMate: "#",
	strProm: "",
	str00: "0-0",
	str000: "0-0-0",
	strNullMove: "--",
	bOmitPawnTakes: false
};

InvariantLocalization.arrStrPieces[Piece.W_KING] =
InvariantLocalization.arrStrPieces[Piece.B_KING] = "K";

InvariantLocalization.arrStrPieces[Piece.W_QUEEN] =
InvariantLocalization.arrStrPieces[Piece.B_QUEEN] = "Q";

InvariantLocalization.arrStrPieces[Piece.W_ROOK] =
InvariantLocalization.arrStrPieces[Piece.B_ROOK] = "R";

InvariantLocalization.arrStrPieces[Piece.W_BISHOP] =
InvariantLocalization.arrStrPieces[Piece.B_BISHOP] = "B";

InvariantLocalization.arrStrPieces[Piece.W_KNIGHT] =
InvariantLocalization.arrStrPieces[Piece.B_KNIGHT] = "N";

InvariantLocalization.arrStrPieces[Piece.W_PAWN] =
InvariantLocalization.arrStrPieces[Piece.B_PAWN] = "";


export class NotationGenerator
{
	constructor ( _loc )
	{	
		// NH2020 
		this.m_loc = _loc || InvariantLocalization;
	}

	getLocalization()
	{
		return this.m_loc;
	};

	getLineNota( _pos, _line, _firstMove )
	{
		var first = 0;
		if ( _firstMove )
			first = _firstMove;

		var numMv = _pos.getMoveNum();
		
		var strRes = "";

		for ( var i = first; i < _line.length; ++i )
		{
			var mv = _line[i];

			var pcMvd = mv.getMoved();
			var wtm = Piece.side( pcMvd ) === Side.WHITE;

			strRes += this.getMoveNota( mv, wtm || !i ? numMv : 0 ) + " ";


			if ( !wtm )
				++numMv;
		}

		return strRes;
	};

	genLineNotaFromEnd( game, nMoves )
	{
		var moveNum = game.getMainLine().length;

		moveNum -= nMoves;
		if ( moveNum < 0 )
			moveNum = 0;

		var numMv = Math.floor( moveNum / 2 ) + 1;

		var res = [];

		var first = Math.max( 0, game.getMoveNo() - nMoves );

		for ( var i = first; i < game.getMainLine().length; ++i )
		{
			var mv = game.getMainLine()[i];

			var pcMvd = mv.getMoved();
			var wtm = Piece.side( pcMvd ) === Side.WHITE;

			res.push( this.getMoveNota( mv, wtm || ( i === first ) ? numMv : 0 ) );

			if ( !wtm )
				++numMv;
		}

		return res;
	};

	getNota( _mv, _kernel )
	{


		var mvNum = Math.floor( _kernel.getCurPos().getMoveNum() / 2 + 1 );
		_kernel.getCurPos().preCalcCtx( _mv );
		return this.getMoveNota( _mv, mvNum );
	};

	getMoveNota( _mv, _numMv )
	{
		var res = "";
		var pcMvd = _mv.getMoved();

		if ( _numMv )
		{
			res = "" + _numMv + ".";
			if ( Piece.side( pcMvd ) === Side.BLACK )
			{
				res += "..";
			}
		}

		if ( _mv.isNullMove() )
		{
			res += this.m_loc.strNullMove;
		}
		else if ( _mv.isShortCastling() )
		{
			res += this.m_loc.str00;
		}
		else if ( _mv.isLongCastling() )
		{
			res += this.m_loc.str000;
		}
		else
		{
			if ( pcMvd < 1 || pcMvd > 15 )
			{
				res += "[pcMvd=" + pcMvd + "]";
			}
			else
			{
				res += this.m_loc.arrStrPieces[pcMvd];
			}

			if ( _mv.isNeedCol() )
				res += Square.colToString( Square.C( _mv.from ) );
			if ( _mv.isNeedRow() )
				res += Square.rowToString( Square.R( _mv.from ) );

			if ( _mv.isTake() )
			{
				if ( pcMvd === Piece.W_PAWN || pcMvd === Piece.B_PAWN )
				{
					res += Square.colToString( Square.C( _mv.from ) );
					if ( !this.m_loc.bOmitPawnTakes )
						res += this.m_loc.strTakes;
				}
				else
				{
					res += this.m_loc.strTakes;
				}
			}

			res += Square.toString( _mv.to );

			if ( _mv.prom )
			{
				res += this.m_loc.strProm;
				res += this.m_loc.arrStrPieces[_mv.prom];
			}
		}

		if ( _mv.isMate() )
			res += this.m_loc.strMate;
		else if ( _mv.isCheck() )
			res += this.m_loc.strCheck;

		return res;
	};

	getStrMoveNumInLine( i, iStart, blackStarts )
	{
		var moveNum = Position.plyToMvNum( i + iStart );

		var str = "";
		if ( ( ( i + iStart ) & 1 ) === 0 || i === 0 )
		{
			str = moveNum.toString() + ".";
			if ( blackStarts )
			{
				if ( ( ( i + iStart ) & 1 ) === 0 )
					str += "..";
			}
			else
			{
				if ( ( ( i + iStart ) & 1 ) !== 0 )
					str += "..";
			}
		}
		return str;
	}


	static Localizations = 
	{
		invariant: InvariantLocalization
	};
	

}
export var NotationLocalizations = NotationGenerator.Localizations;