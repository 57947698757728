import { Side } from "common/Chess/Logic/Chess";
import { NotationGenerator } from "common/Chess/Logic/Notation";
import { HashTableData } from "common/Chess/TeraBrain/HashTableData";
import { TBrainMove } from "common/Chess/TeraBrain/TBrainMove";
import { TBrainMoveList } from "common/Chess/TeraBrain/TBrainMoveList";
import { TeraBrainLobby } from "common/Chess/TeraBrain/TeraBrainLobby";
import { ObjUtil } from "common/Patterns/ObjectUtil";
import { System } from "common/Tools/System";
import { LoginMode } from "common/WebClient/Protocol/LogonData";
import { useEffect, useRef, useState } from "react";

const SERVER = System.isHTTPS() ? [ "wss://tbridge.chessbase.com:6008", "wss://tbridge2.chessbase.com:6008" ] : [ "ws://tbridge2.chessbase.com:7000", "ws://tbridge.chessbase.com:7000" ];

export function useTeraBrainLobbyContext (localizationRef)
{
    const teraBrainLobbyRef = useRef();
    const callbackIndices = useRef();

    // TODO: Not really ideal. Best would be: position and start position for every search no matter from where it comes.
    const bookTablePositionRef = useRef();
    const bookTableStartPositionRef = useRef();
    const engineTablePositionRef = useRef();
    const engineTableStartPositionRef = useRef();

    // Search Timers:
    const bookTableTimer = useRef();
    const engineTableTimer = useRef();

    const [bookTableContent, setBookTableContent] = useState([]);
    const [bookTableIsLoading, setBookTableIsLoading] = useState(true);
    const [engineTableContent, setEngineTableContent] = useState([]);
    const [engineTableIsLoading, setEngineTableIsLoading] = useState(true);
    const [isConnected, setIsConnected] = useState(false);

    useEffect(() => {
        if (!teraBrainLobbyRef.current)
        {
            var newTeraBrainLobby= new TeraBrainLobby();
            newTeraBrainLobby.create( SERVER, "Guest", "", LoginMode.GUEST );
            newTeraBrainLobby.connect();
            newTeraBrainLobby.addOnIDReceivedListener(handleTeraBrainLobbyConnected);

            var index01 = newTeraBrainLobby.addOnLiveBookListener(handleLiveBookUpdate);
            var index02 = newTeraBrainLobby.addOnTBDataListener(handlePosInfoUpdate);
            callbackIndices.current = [index01, index02];

            teraBrainLobbyRef.current = newTeraBrainLobby;
            window.addEventListener('beforeunload', logOffTeraBrainLobby);
        }

        return () => {
            if (teraBrainLobbyRef && teraBrainLobbyRef.current && callbackIndices && callbackIndices.current)
            {
                teraBrainLobbyRef.current.removeOnLiveBookListener(callbackIndices.current[0]);
                teraBrainLobbyRef.current.removeOnTBDataListener(callbackIndices.current[1]);
            }
        }
    }, []);

    function searchTeraBrainBook (position, startPosition)
    {
        if (isConnected)
        {
            setBookTableIsLoading(true);
            
            if (bookTableTimer.current)
                clearTimeout(bookTableTimer.current);

            bookTableTimer.current =  setTimeout(() => {
                bookTablePositionRef.current = position;
                bookTableStartPositionRef.current = startPosition;
                teraBrainLobbyRef.current.requestAllMoves(position);
            }, 500);
        }
    }

    function searchPositionInfo (position, startPosition) 
    {
        if (isConnected)
        {
            if (engineTableTimer.current)
                clearTimeout(engineTableTimer.current);

            engineTableTimer.current =  setTimeout(() => {
                engineTablePositionRef.current = position;
                engineTableStartPositionRef.current = startPosition;
                teraBrainLobbyRef.current.requestPosInfo(position);
                setEngineTableIsLoading(true);
            }, 500);
        }
    }

    function handleTeraBrainLobbyConnected ()
    {
        setIsConnected(true);
    }

    function handleLiveBookUpdate (data)
    {
        var bookEntries = TeraBrainLobbyUtils.getBookData(data, bookTablePositionRef.current, bookTableStartPositionRef.current, localizationRef.current.strings.NOTA_LOCALIZATION);

        // Create new Table Content
        var newContent = [];
        for (var i = 0; i < bookEntries.length; i++)
        {
            let entry = bookEntries[i];
            newContent.push({
                render: [entry.line,
                        entry.Games,
                        Math.round(entry.Result).toString() + "%",
                        Math.round(entry.EloAvg),
                        Math.round(entry.fPercent).toString(),
                        entry.m_nVisits,
                        entry.players],
                info: { selection: entry.id, from: entry.fr, to: entry.to }
            });
        }

        setBookTableContent(newContent);
        setBookTableIsLoading(false);
    }

    function handlePosInfoUpdate (data) 
    {
        // Original Function. See TBPanel grid.update
        if (engineTableStartPositionRef.current && data.table.move.length > 0)
        {
            // unused var
            // var position = data.position;

            var lines = data.table.move;

            // Create new Table Content
            var newContent = [];
            for (var i = 0; i < lines.length; i++)
            {
                let entry = lines[i];
                var variation = HashTableData.generateLineWithStartPosition(engineTablePositionRef.current, entry, engineTableStartPositionRef.current, 3, localizationRef.current.strings.NOTA_LOCALIZATION).lineNota;

                if (entry.m_aLine[0] !== 0)
                {
                    newContent.push({
                        render: [parseFloat(entry.m_nEval * 0.01).toFixed(2),
                                variation,
                                entry.m_nDepth,
                                entry.EngineName || ''],
                        info: { selection: entry.EngineName }
                    });
                }
            }

            setEngineTableContent(newContent);
            setEngineTableIsLoading(false);
        }
    }

    function logOffTeraBrainLobby ()
    {
        if (teraBrainLobbyRef.current)
            teraBrainLobbyRef.current.logOff(); 
    }

    return {searchTeraBrainBook, searchPositionInfo, isConnected, bookTableContent, bookTableIsLoading, engineTableContent, engineTableIsLoading};
    // return [searchTeraBrainBook, searchPositionInfo, isConnected, bookTableContent, bookTableIsLoading, engineTableContent, engineTableIsLoading];
}




class TeraBrainLobbyUtils
{
    static getBookData (data, pos, startPosition, notaLocalization)
    {
        var moves = data.moves;
        var curSide = pos.getSideToMove();

        if (pos.getPieces().length <= 6)
        {
           if (!data.sorted)
              TBrainMoveList.sortEval(moves, curSide === Side.BLACK);
        }

        var nTotalVisits = 0;
        for (var n = 0; n < data.moves.length; n++) {
           nTotalVisits += data.moves[n].getNVisits();
        }

        var stampToday = TBrainMove.getTimeStampToday();

        var isEndGame = pos.getPieces().length <= 6;

        var records = [];
        for (var nMove = 0; nMove < data.moves.length; nMove++) {
           var rTBMove = data.moves[nMove];

           if (isEndGame || TeraBrainLobbyUtils.passesMoveFilter(rTBMove, stampToday)) {
                var aMove = rTBMove.getMoveRecord();

                rTBMove.line = TeraBrainLobbyUtils.generateLine(data.position, pos, startPosition, aMove, notaLocalization);

                rTBMove.fPercent = 100.0;
                if (nTotalVisits)
                    rTBMove.fPercent = (100.0 * rTBMove.getNVisits()) / nTotalVisits;
                rTBMove.m_nEval = rTBMove.getNEval();
                rTBMove.Games = rTBMove.m_performanceStats.getNGames();
                rTBMove.Result = rTBMove.m_performanceStats.getFPercent(data.position.isBTM());
                rTBMove.EloAvg = rTBMove.m_performanceStats.getSortEloAv();
                rTBMove.fr = aMove.from;
                rTBMove.to = aMove.to;
                rTBMove.players = [];
                
                delete rTBMove.id;	// might already be there in re-firing with LiveBookDisplay
                rTBMove.id = TeraBrainLobbyUtils.calcId( aMove );

                records.push(rTBMove);
            }
        }

        return records;
    }

    static passesMoveFilter (tbMove, stampToday)
    {
        // var minVisits = config.minVisits || 2;
        // NH2020 hardcoded minVisits.
        var minVisits = 10;

        return (tbMove.getNGames() > 0 || stampToday - tbMove.m_nTimeStamp < 300 || tbMove.isMyMove)
           && (tbMove.getNVisits() >= minVisits || tbMove.hasEval());
    }

    static generateLine (dataPosition, gamePosition, gameStartPosition, aMove, notaLocalization)
    {
       var line = TeraBrainLobbyUtils.convertJSONLine(ObjUtil.clone(dataPosition), aMove);
       var notaGen = new NotationGenerator(notaLocalization);
       var strLine = "";
       var blackStarts = gameStartPosition.getBTM();
       for (var i = 0; i < line.length; i++)
       {
          var strMove = notaGen.getMoveNota(line[i]);
          var strMoveNum = notaGen.getStrMoveNumInLine(i, gamePosition.getPlyNum(), blackStarts);
          strLine += strMoveNum + strMove;
          if (i < line.length - 1)
             strLine += " ";
       }
       return strLine;
    };

    static convertJSONLine (position, moveline)
    {
       var line = [];
       if (position.isLegalMove(moveline))
       {
          position.preCalcCtx(moveline);
          position.makeMove(moveline);
          position.postCalcCtx(moveline, true);
          line.push(moveline);
       }
       return line;
    };

    static calcId (move) 
    {
        var id = (move.prom << 12) + (move.from << 8) + (move.to);
        return "TBBook" + id;
    };
}