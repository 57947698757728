import { useEffect, useRef } from 'react';

export function usePrevious (prop) {

    const previousRef = useRef();

    useEffect(() => {
      previousRef.current = prop;
    }, [prop]);

    return previousRef.current;
  }