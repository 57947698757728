// jb 12.11.2013

// "use strict";

import { CBDate } from "common/Tools/CBDate"
import { Log } from "common/Tools/Log"

export class TBrainTimeStamp
{

	// constructor () 
	// {
	// };

	static TS_STARTYEAR = 1945;

	UShortToDate ( nTimeStamp ) {
		var nDay = nTimeStamp & 0x1f;								// 5 Bit
		var nMonth = ( nTimeStamp >> 5 ) & 0x0f;				// 4 Bit
		var nYear = TBrainTimeStamp.TS_STARTYEAR + ( ( nTimeStamp >> 9 ) & 0x7f );	// 7 Bit	

		return new CBDate( Math.Min( CBDate.Today().GetDate(), new CBDate( nYear, nMonth, nDay ).GetDate() ) );
	};

	DateToUShort ( rDate ) {
		// NH2020 ushort undefined
		Log.Missing();

		// var nYear = Math.Max( TBrainTimeStamp.TS_STARTYEAR, rDate.GetYear() );
		// return ( ushort )( rDate.GetDay() | ( rDate.GetMonth() << 5 ) | ( ( nYear - TBrainTimeStamp.TS_STARTYEAR ) << 9 ) );
	};

	GetNTodaysTimeStamp () {
		return this.DateToUShort( CBDate.Today() );
	};

}


