// "use strict";

export class ExtRatingType12 {

   // constructor () {
   // };

   readFromDataBuffer ( rBuf ) {
      this.nFlags = rBuf.readUint16();
      this.nRating = rBuf.readUint16();
      this.aNation = rBuf.readUint8();
      this.sProvider = rBuf.readUint16();
      return true;
   }

   getRating ()  {
      return this.nRating;
   }

}
