// jb 12.11.2013

// "use strict";

export class CPUInfo
{

	constructor () {
		this.m_nNumaNode = 0;
		this.m_nPhysicalProcs = 0;
		this.m_nPhysicalCores = 0;
		this.m_nLogicalCores = 0;
	};

	writeToDataBuffer = function ( rDB ) {
		rDB.beginSizedWrite();

		rDB.writeUint32( this.m_nNumaNodes );
		rDB.writeUint32( this.m_nPhysicalProcs );
		rDB.writeUint32( this.m_nPhysicalCores );
		rDB.writeUint32( this.m_nLogicalCores );
		rDB.endSizedWrite();
		return true;
	};

}




