// ES 6 MW: Feb 27, 2020

/* eslint-disable eqeqeq */

// NH2020I Eine Datei, in der einige Schachlogiken definiert sind.
// Es gibt zB ein Objekt Pieces, in dem definiert wird, welcher Figur, welcher int Wert zugeordnet wird
// Oder auch: definition der Squares des Brettes, Castle Rights etc.

export var Side =
{
	NONE: -1,
	WHITE: 0,
	BLACK: 1,

	SIDE_OFFSET: 3
};

Side.SIDE_MASK = 8;

Side.other = function (_sd)
{
	return _sd ^ Side.BLACK;
};

Side.fromString = function (_str)
{
	return _str === "w" ? Side.WHITE : Side.BLACK;
};

Side.toString = function (_sd)
{
	return _sd === Side.WHITE ? "w" : "b";
};

//////////////////////////////////////////////////////////////////////////////////////////

export var Piece = {
	NONE: 0,
	KING: 1,
	QUEEN: 2,
	KNIGHT: 3,
	BISHOP: 4,
	ROOK: 5,
	PAWN: 6,

	W_KING: 1,
	W_QUEEN: 2,
	W_KNIGHT: 3,
	W_BISHOP: 4,
	W_ROOK: 5,
	W_PAWN: 6,

	B_KING: 9,
	B_QUEEN: 10,
	B_KNIGHT: 11,
	B_BISHOP: 12,
	B_ROOK: 13,
	B_PAWN: 14,

	COLOUR: 8,
	WHITE: 0,
	BLACK: 8,

	JOKER: 7
};

Piece.PIECE_MASK = 7;
Piece.SIDE_MASK = 8;

Piece.nominal = function (_pc)
{
	return _pc & Piece.PIECE_MASK;
};

Piece.side = function (_pc)
{
	return (_pc & Side.SIDE_MASK) / 8;
};

Piece.changeSide = function (_pc)
{
	return _pc ^ Side.SIDE_MASK;
};

Piece.make = function (_pc, _sd)
{
	return _pc | _sd * 8;
};

Piece.g_arrPieces =
	[ " ",
		"K", "Q", "N", "B", "R", "P",
		" ", " ",
		"k", "q", "n", "b", "r", "p" ];

Piece.toString = function (_pc)
{
	return Piece.g_arrPieces[ _pc ];
};

Piece.fromString = function (_ch)
{
	var inx = Piece.g_arrPieces.indexOf(_ch);
	if (inx < 0)
		return Piece.NONE;
	return inx;
};

// Piece.getNameAccusative = function (_pc) {
// 	switch (_pc) {
// 		case Piece.KING:
// 			return lm.KING_ACC;
// 		case Piece.QUEEN:
// 			return lm.QUEEN_ACC;
// 		case Piece.KNIGHT:
// 			return lm.KNIGHT_ACC;
// 		case Piece.BISHOP:
// 			return lm.BISHOP_ACC;
// 		case Piece.ROOK:
// 			return lm.ROOK_ACC;
// 		case Piece.PAWN:
// 			return lm.PAWN_ACC;
// 	}
// };


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export var CastleRights = {
	NONE: 0,
	W_000: 1,
	W_00: 2,
	B_000: 4,
	B_00: 8
};

CastleRights.W_ALL = CastleRights.W_00 | CastleRights.W_000;
CastleRights.B_ALL = CastleRights.B_00 | CastleRights.B_000;
CastleRights.ALL = CastleRights.W_ALL | CastleRights.B_ALL;

CastleRights.toString = function (_cr)
{
	if (_cr === CastleRights.NONE)
		return "-";
	var res = "";
	if (_cr & CastleRights.W_00)
		res += "K";
	if (_cr & CastleRights.W_000)
		res += "Q";
	if (_cr & CastleRights.B_00)
		res += "k";
	if (_cr & CastleRights.B_000)
		res += "q";
	return res;
}

CastleRights.fromString = function (_str)
{
	var res = CastleRights.NONE;
	// var it = new StringIterator(_str);

	// for (var ch = it.Next();
	// 	ch !== 0;
	// 	ch = it.Next()) {
	// 	switch (ch) {
	// 		case 'K':
	// 			res |= CastleRights.W_00;
	// 			break;
	// 		case 'Q':
	// 			res |= CastleRights.W_000;
	// 			break;
	// 		case 'k':
	// 			res |= CastleRights.B_00;
	// 			break;
	// 		case 'q':
	// 			res |= CastleRights.B_000;
	// 			break;
	// 	}
	// }

	return res;
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export var Square = {
	ROW_OFFSET: 0,
	ROW_MASK: 7,
	COL_OFFSET: 3,
	COL_MASK: 7 << 3,

	A1: 0, A2: 1, A3: 2, A4: 3, A5: 4, A6: 5, A7: 6, A8: 7,
	B1: 8 + 0, B2: 8 + 1, B3: 8 + 2, B4: 8 + 3, B5: 8 + 4, B6: 8 + 5, B7: 8 + 6, B8: 8 + 7,
	C1: 16 + 0, C2: 16 + 1, C3: 16 + 2, C4: 16 + 3, C5: 16 + 4, C6: 16 + 5, C7: 16 + 6, C8: 16 + 7,
	D1: 24 + 0, D2: 24 + 1, D3: 24 + 2, D4: 24 + 3, D5: 24 + 4, D6: 24 + 5, D7: 24 + 6, D8: 24 + 7,

	E1: 32 + 0, E2: 32 + 1, E3: 32 + 2, E4: 32 + 3, E5: 32 + 4, E6: 32 + 5, E7: 32 + 6, E8: 32 + 7,
	F1: 40 + 0, F2: 40 + 1, F3: 40 + 2, F4: 40 + 3, F5: 40 + 4, F6: 40 + 5, F7: 40 + 6, F8: 40 + 7,
	G1: 48 + 0, G2: 48 + 1, G3: 48 + 2, G4: 48 + 3, G5: 48 + 4, G6: 48 + 5, G7: 48 + 6, G8: 48 + 7,
	H1: 56 + 0, H2: 56 + 1, H3: 56 + 2, H4: 56 + 3, H5: 56 + 4, H6: 56 + 5, H7: 56 + 6, H8: 56 + 7,

	C_A: 0, C_B: 1, C_C: 2, C_D: 3, C_E: 4, C_F: 5, C_G: 6, C_H: 7,
	R_1: 0, R_2: 1, R_3: 2, R_4: 3, R_5: 4, R_6: 5, R_7: 6, R_8: 7
};

Square.g_arrSquareStrs = [
	"a1", "a2", "a3", "a4", "a5", "a6", "a7", "a8",
	"b1", "b2", "b3", "b4", "b5", "b6", "b7", "b8",
	"c1", "c2", "c3", "c4", "c5", "c6", "c7", "c8",
	"d1", "d2", "d3", "d4", "d5", "d6", "d7", "d8",
	"e1", "e2", "e3", "e4", "e5", "e6", "e7", "e8",
	"f1", "f2", "f3", "f4", "f5", "f6", "f7", "f8",
	"g1", "g2", "g3", "g4", "g5", "g6", "g7", "g8",
	"h1", "h2", "h3", "h4", "h5", "h6", "h7", "h8"
];

// NH2021
Square.Names = Square.g_arrSquareStrs;

Square.toString = function (n)
{
	return this.g_arrSquareStrs[ n ];
};

Square.getStr = Square.toString;

Square.getStrXY = function (x, y)
{
	return Square.getStr(Square.get64(x, y));
};

Square.colToString = function (_col)
{
	return String.fromCharCode("a".charCodeAt() + _col);
}

Square.rowToString = function (_row)
{
	return _row + 1;
}

Square.colFromString = function (_str)
{
	return _str.charCodeAt(0) - "a".charCodeAt();
}

Square.rowFromString = function (_str)
{
	return _str.charCodeAt(0) - "1".charCodeAt();
}

Square.fromString = function (_str)
{
	if (!_str || _str.length !== 2)
		return undefined;
	var c = Square.colFromString(_str.charAt(0));
	var r = Square.rowFromString(_str.charAt(1));

	return Square.I(c, r);
};

Square.R = function (_inx)
{
	return (_inx & Square.ROW_MASK) >> Square.ROW_OFFSET;
};

Square.C = function (_inx)
{
	return (_inx & Square.COL_MASK) >> Square.COL_OFFSET;
};

Square.F = function (_inx)
{
	return (_inx & Square.COL_MASK) >> Square.COL_OFFSET;
};

Square.getRank = Square.R;

Square.getFile = Square.C;

Square.DIST = function (_fld1, _fld2)
{
	var dr = Square.R(_fld1) - Square.R(_fld2);
	var dc = Square.C(_fld1) - Square.C(_fld2);
	return Math.sqrt(dr * dr + dc * dc);
};

Square.I = function (_col, _row)
{
	return _col * 8 + _row;
};

// NH2021
Square.getTranslate = function (squareIndex, squareSize, whiteIsBottom)
{
	if (squareIndex === -1)
		return undefined;
	else if (whiteIsBottom)
		return {x: Square.C(squareIndex) * squareSize, y: -Square.R(squareIndex) * squareSize};
	else
		return {x: (7 - Square.C(squareIndex)) * squareSize, y: (-7 + Square.R(squareIndex)) * squareSize};
}

Square.getArrowTranslate = function (squareIndex, whiteIsBottom)
{
	if (squareIndex === -1)
		return undefined;
	else if (whiteIsBottom)
		return {x: Square.C(squareIndex) * 100, y: -Square.R(squareIndex) * 100}
	else
		return {x: (7 - Square.C(squareIndex)) * 100, y: (-7 + Square.R(squareIndex)) * 100};
}

// a clearer naming of Square.I( col, row ) below
Square.get64 = Square.I;

Square.isLegal = function (_col, _row)
{
	return _col <= Square.C_H &&
		_col >= Square.C_A &&
		_row <= Square.R_8 &&
		_row >= Square.R_1;
};

Square.isDark = function (_inx)
{
	var col = Square.C(_inx);
	var row = Square.R(_inx);
	return !((col + row) % 2);
};

Square.isLight = function (_inx)
{
	return !Square.isDark(_inx);
};

Square.isCenterFile = function (s)
{
	return Square.F(s) == Square.C_D || Square.F(s) == Square.C_E;
};

Square.isCenterRank = function (s)
{
	return Square.R(s) == Square.R_4 || Square.R(s) == Square.R_5;
};

Square.isCenterSquare = function (_s)
{
	return Square.isCenterFile(_s) && Square.isCenterRank(_s);
};

Square.getDist = function (a, b)
{
	var dFile = Square.getFile(a) - Square.getFile(b);
	var dRank = Square.getRank(a) - Square.getRank(b);
	return Math.sqrt(dFile * dFile + dRank * dRank);
};

Square.getFileDist = function (a, b)
{
	return Square.getFile(a) - Square.getFile(b);
};

Square.getPromRow = function (_sd)
{
	return _sd === Side.WHITE ? Square.R_8 : Square.R_1;
};

