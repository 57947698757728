// mw 15.4.2013
// ES6 1.3.2020

export class Storage
{
	constructor() 
	{
		if ( window.localStorage )
			this.storage = window.localStorage;
		else if ( window.sessionStorage )
			this.storage = window.sessionStorage;
	};

	save( label, obj )
	{
		if ( obj && this.storage !== undefined )
			this.storage[ label ] = JSON.stringify( obj );
	};

	load( label )
	{
		if ( this.storage !== undefined && this.storage[ label ] && this.storage[ label ] !== "undefined" )
			return JSON.parse( this.storage[ label ] );
	};

}