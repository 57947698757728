// ES 6 MW: Feb 27, 2020

import { ObjUtil } from 'common/Patterns/ObjectUtil'

export class HashTable
{
    constructor( _funcHash, _funcEq )
    {
        if ( _funcHash )
            this.funcHash = _funcHash;

        if ( _funcEq )
            this.funcEq = _funcEq;

        this.impl = {};
    };

    impl = {};

    funcHash ( _obj )
    {
        return ObjUtil.hashCode( _obj );
    };

    funcEq ( _obj1, _obj2 )
    {
        return _obj1 === _obj2;
    };

    getItem ( _key )
    {
        var hashKey = this.funcHash( _key );
        var arrPairs = this.impl[ hashKey ];
        if ( !arrPairs )
            return undefined;

        var funcFilter =
            function( _pair )
            {
                return this.funcEq( _pair.key, _key );
            }.bind( this );


        var matches = arrPairs.filter( funcFilter );
        if ( matches != null && matches.length > 0 )
            return matches[ 0 ].value;

        return undefined;
    };

    setItem ( _key, _value )
    {
        var hashKey = this.funcHash( _key );
        var arrPairs = this.impl[ hashKey ];
        if ( !arrPairs )
        {
            arrPairs = [];
            this.impl[ hashKey ] = arrPairs;
        };

        for ( var inx = 0, len = arrPairs.length; inx < len; ++inx )
        {
            var pair = arrPairs[ inx ];
            if ( this.funcEq( pair.key, _key ) )
            {
                pair.value = _value;
                return;
            }
        };

        var newPair = { key: _key, value: _value };
        arrPairs.push( newPair );
    };

    removeItem ( _key )
    {
        var hashKey = this.funcHash( _key );
        var arrPairs = this.impl[ hashKey ];
        if ( !arrPairs )
            return;

        var funcFilter =
            function( _pair )
            {
                return !this.funcEq( _pair.key, _key );
            }.bind( this );


        var arrNewPairs = arrPairs.filter( funcFilter );
        this.impl[ hashKey ] = arrNewPairs;

        return undefined;
    };

    clear ()
    {
        this.impl = {};
    };
}