// jb 12.11.2013

// "use strict"

import { Log } from "common/Tools/Log"
import { TBrainMove } from "common/Chess/TeraBrain/TBrainMove"

export class TBrainMoveList
 {

	constructor () 
	{
		this.moves = [];
	};

	writeToDataBuffer = function ( rBuf ) {
		rBuf.BeginSizedWrite();

		// NH2020 GetNFormat nowhere to be found...
		// NH2020 Count undefined...
		Log.Missing();
		//rBuf.WriteInt( GetNFormat() );

		// rBuf.WriteUnsigned( Count );
		// for ( var n = 0 ; n < Count ; n++ ) {
		// 	this[n].WriteToDataBuffer( rBuf );
		// }

		rBuf.EndSizedWrite();
	};

	readFromDataBuffer = function ( rBuf ) {
		rBuf.beginSizedRead();

		/*int nFormat = */rBuf.readInt();

		this.clear();
		var nCount = rBuf.readInt();
		for ( var n = 0; n < nCount; n++ ) {
			var aMove = new TBrainMove();
			aMove.readFromDataBuffer( rBuf );
			this.add( aMove );
		}

		rBuf.endSizedRead();
		return true;
	};

	clear = function () {
		this.moves = [];
	}

	count = function () {
		return this.moves.length;
	}

	add = function ( aTBMove ) {
		this.moves.push( aTBMove );
	}

	get = function ( index ) {
		return this.moves[ index ];
	}

	getNFormat = function () {
		return 2;
	};

	getMaxGames = function ()
	{
		var nMaxGames = 0;
		for ( var i = 0; i < this.moves.length; i++ )
		{
			if ( this.moves[i].getNGames() > nMaxGames )
				nMaxGames = this.moves[i].getNGames();
		}
		return nMaxGames;
	};

	static sortEval = function ( moves, btm )
	{
		moves.sort( function ( a, b )
		{
			if ( btm )
			{
				return a.m_nEval - b.m_nEval;
			}
			else
			{
				return b.m_nEval - a.m_nEval;
			}
		} );
	};

	/* if hintMove is among the best moves (e.g. all moves are drawn)
		then take it. If not, take the best move.
		Assumes that moveList is sorted.
	*/
	getHintedBestMove = function ( hintMove )
	{
		if ( this.moves.length == 0 )
			return hintMove;

		var strongestEval = this.moves[0].m_nEval;
		for ( var i = 0; i < this.moves.length; i++ )
		{
			if ( Math.abs( this.moves[i].m_nEval - strongestEval ) < 50 )
			{
				if ( this.moves[i].move.equals( hintMove ) )
					return hintMove;
			}
			else
				break; // for
		}
		Log.Log( "EngineHintMove not Best: " + hintMove, "LogError" );
		return this.moves[0].move;	
	};
}



