// import { glApp  } from "common/App/App";
import { PlayingMode } from "./PlayingMode";
import { EngineInstance } from "common/Chess/Engine/EngineInstance";
import { Log } from "common/Tools/Log";

export class EngineHostMode extends PlayingMode
{
	constructor ( _kernel, _szPool )
	{
		super( _kernel )

		this.szPool = _szPool || 0;

		this.engines = [];
		this.nEngines = 0;
		this.panEng = null;
	};

	loadEngineInstance( _strUrl, _inx, _name )
	{
		return new EngineInstance( _strUrl, this.szPool, _inx, _name );
	};

	changeEngineLines( _inc )
	{
		var eng = this.engines[0];

		var lines = eng.getMultiPV();
		lines += _inc;
		lines = Math.max( lines, 1 );

		this.stopAll();
		eng.setMultiPV( lines );
		this.goAll();
	};

	loadEngine( _inx )
	{
		if ( this.nEngines < _inx + 1 )
			this.nEngines = _inx + 1;

		Log.Missing();
		// NH2021D
		// if ( glApp.panelMgr.openEnginePanel )
		// {
		// 	var panEng = this.panEng = glApp.panelMgr.openEnginePanel( _inx );

		// 	if ( panEng && !this.engines[_inx] )
		// 	{
		// 		var eng = this.engines[_inx] = this.loadEngineInstance( glApp.config.getEngineUrl()[_inx], _inx );

		// 		panEng.setEngine( eng );
		// 		if ( panEng.setAnalysis )
		// 			panEng.setAnalysis( this.isAnalysis() );

		// 		this.addOnBestMove( eng );

		// 		eng.setTag( _inx );
		// 	}
		// }
	};

	isEngineLoaded( _inx )
	{
		return !!this.engines[_inx];
	};

	unloadEngine( _inx )
	{
		if ( this.engines[_inx] )
		{
			this.engines[_inx].exit();	// stop, dispose, clear listeners

			delete this.engines[_inx];
		}
	};

	// NH2020 Added this function
	onEnteredMove ( mv )
	{
		return super.onEnteredMove( mv )
	}

	onBestMove( _eng, _best )
	{
		var pos = this.kernel.game.getCurPos();
		if ( !pos.isLegalMove( _best.move ) )
			return;

		this.kernel.execEngineMove( _best.move );
	};

	addOnBestMove( _eng )
	{
		if ( !this.idOnBestMove )
			this.idOnBestMove = _eng.addOnBestMoveListener( this.onBestMove.bind( this ) );
	};

	removeOnBestMove( _eng )
	{
		_eng.removeOnBestMoveListener( this.idOnBestMove );
		this.idOnBestMove = null;
	};

	destroy()
	{
		this.stopAll();
		this.engines.forEach( function ( _eng ) { if ( _eng ) _eng.dispose(); } );
	};

	goAll()
	{
		this.engines.forEach( function ( _eng )
		{
			if ( _eng ) _eng.go( this.kernel.game );
		}.bind( this ) );
	};

	stopAll()
	{
		this.engines.forEach( function ( _eng ) { if ( _eng ) _eng.stop(); } );
	};

	newGame()
	{
		this.stopAll();
		this.engines.forEach( function ( _eng )
		{
			if ( _eng ) _eng.newGame();
		} );
	};

	setEngineOff()
	{
		this.stopAll();
	} 

	toggleHandicap()
	{
		this.setHandicap( !this.isHandicap() );
	};

	setHandicap( _handicap )
	{
		
	};

	isHandicap()
	{
		return false;
	};

	getHandicap()
	{
		return 0;
	};

	saveState( _obj )
	{
	};

	loadState( _obj )
	{
	};
}