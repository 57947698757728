

import React from 'react';
import baseStyles from "../CBModules.module.css";
import styles from "./CBSearchModule.module.css";

import { CBSearchContainer } from "CBReact/Components/SearchField/CBSearchContainer"
import { CBDatabaseTable } from "CBReact/Components/Modules/SearchModule/CBDatabaseTable"

import { checkPropsAndRenderComponent } from 'CBReact/Utils/DebugUtils';

export function CBSearchModule (props)
{
    function renderCBSearchModule ()
    {
        return (
            <div id="search" className={`${baseStyles.base} ${styles.searchModule}`}>
                <CBSearchContainer gameKernel={props.gameKernel}/>
                <CBDatabaseTable gameKernel={props.gameKernel} selectedGame={props.selectedGame} fillRemainingHeight={props.fillRemainingHeight} tableMaxHeight={props.tableMaxHeight} />
            </div>
        );
    }

    return checkPropsAndRenderComponent([props.gameKernel, props.selectedGame], renderCBSearchModule);
}

// This function assumes that gamekernel will always stay the same....
function searchModulePropsAreEqual (prev, next)
{
    return  prev.selectedGame === next.selectedGame && 
            prev.fillRemainingHeight === next.fillRemainingHeight && 
            prev.tableMaxHeight === next.tableMaxHeight;
}

export const CBMSearchModule = React.memo(CBSearchModule, searchModulePropsAreEqual);