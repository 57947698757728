
export class Calculate 
{
    static map (value, inMin, inMax, outMin, outMax) {
        return (value - inMin) * (outMax - outMin) / (inMax - inMin) + outMin;
    };

    static clamp (value, min, max) {
        return Math.min(Math.max(value, min), max);
    }
    
    static distance (position1, position2, minDistance, maxDistance)
    {
        var a = Math.abs(position1.x - position2.x);
        var b = Math.abs(position1.y - position2.y);
        return Calculate.clamp(Math.sqrt(a*a+b*b), minDistance, maxDistance);
    }

    static angle (position1, position2)
    {
        return Math.atan2(position2.y - position1.y, position2.x - position1.x) * 180 / Math.PI;
    }
}