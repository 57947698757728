
import React from 'react';
import baseStyles from "../CBModules.module.css";
import styles from "./CBEngineModule.module.css";

import { checkPropsAndRenderComponent } from 'CBReact/Utils/DebugUtils';
import { CBEngineTable } from './CBEngineTable';
import { CBLocalEngine } from './CBLocalEngine';
import { ComparisonUtils } from 'CBReact/Utils/ComparisonUtils';

export function CBEngineModule(props) 
{
    function renderCBEngineModule ()
    {
        return (
            <div id="engine" className={`${baseStyles.base} ${styles.engineModule}`}>
                <CBLocalEngine gameKernel={props.gameKernel} boardPosition={props.boardPosition} />
                <CBEngineTable boardPosition={props.boardPosition} startPosition={props.startPosition} />
            </div>
        );
    }

    return checkPropsAndRenderComponent([props.gameKernel], renderCBEngineModule);
}

// Assumes gameKernel is always the same. Best would be if gameKernel would not be needed...
function engineModulePropsAreEqual (prev, next)
{
    return ComparisonUtils.arraysAreEqual(prev.boardPosition.board, next.boardPosition.board);
}

export const CBMEngineModule = React.memo(CBEngineModule, engineModulePropsAreEqual);