
// NH2020
// <reference path="Protocol/WebSockMessage.js" />

// "use strict";

// NH2020
//CB.namespace( "CB.Server.PlayerLobby" );

import { LocalPlayerBase } from "common/Chess/PlayerBase/LocalPlayerBase"
import { Connector } from "common/WebClient/Connector"
import { Log } from "common/Tools/Log";
import { RepeatGate } from "common/Tools/Timer"
// import { glApp } from "common/App/App";
import { LogonData } from "common/WebClient/Protocol/LogonData";
import { WebSockMessage } from "common/WebClient/Protocol/WebSockMessage";
import { LoginMode } from "common/WebClient/Protocol/LogonData";
import { SockMsgId } from "common/WebClient/Protocol/WebSockMessage";
import { ConnectId } from "common/WebClient/Connection";
import { PlayerUserMsgId } from "common/Chess/PlayerBase/PlayerMessage"
import { Language } from "common/Tools/Language";
import { Player } from "common/Chess/PlayerBase/Player"
import { NewCBPlayer } from "common/Chess/PlayerBase/NewCBPlayer"
import { PlayerKey } from "common/Chess/PlayerBase/PlayerKey"
import { HighPlayerData } from "common/Chess/PlayerBase/HighPlayerData"
import { DataBuffer } from "common/Container/DataBuffer"
// import { DOM } from "common/HTMLDocument/Text";
// import { ChatData } from "common/WebClient/Protocol/ChatData"
import { ListenersUtil } from "common/Patterns/Listeners";
import { clientParams, versionCode } from 'index'

// NH2020I Klasse um Informationen über Spielende abzufragen.
// Kann zum einen auf einen lokalen Speicher zugreifen, aber wohl auch Anfragen an den Server stellen
// Über getPlayer wird wohl geschaut, ob es einen lokal gespeicherten Player gibt,
// wenn nicht wird der Server angefragt

export class PlayerLobby extends Connector
{

	constructor ()
	{
		super();
		
		//this.user = user;
		//this.pass = pass;
		//this.conn = new CB.Connection( this, uri );
		//this.loginMode = mode;

		// this.idGroup = 0;

		//this.pingStart = CB.getTick();
		//this.pingTimer = new CB.Timer( this.onServerPingTimeout.bind( this ) );

		//this.reconnectTries = 0;
		//this.timerReconnect = new CB.Timer();

		// NH2020 Will inheritance work? Will Listeners Work?
		PlayerLobby.InitListeners();

		this.name = "Players";

		this.idPlayerTicket = 0;
		this.idCandidateTicket = 0;
		this.idPictureTicket = 0;
		this.playerContext = {};

		this.reconnectBaseTime = 60000;
		this.localPB = new LocalPlayerBase();
		this.requestQueue = [];
		this.connectTries = 0;
		//if ( CB.System.runsOnChessBaseCom() )
		//{
		//	document.domain = "chessbase.com";		//kann man leider doch nicht über mehrere subdomains machen
		//}

		// NH2020 put these variables in constructor
		// this.requestQueue = null
		this.gatePing = new RepeatGate();

		// Added this.me and initialised it with null
		this.me = null;
	};

	// NH2020
	//CB.inherit( PL, CB.Server.Connector ); // calls constructor of CB.Server.Connector
	//PL.prototype.requestQueue = null;

	connect ( fn, me )
   	{
      //console.log("PL::CONNECT");

		this.callbackfnConnected = fn;

		// NH2020 
		super.connect()
		// this.superClass.connect.apply( this, arguments );
	};

	logon ()
	{
		if ( !this.hasIdReceived )
		{
			var guid = /* glApp.clientParams */ clientParams.guid;
			var aLogonData = new LogonData( this.user, this.pass, LoginMode.NORMAL, guid );   // glApp.clientParams.guid
			this.send( aLogonData.getSocketsMsg( true, true ) );
		}
	};

	logOff ()
	{
		if ( this.hasIdReceived && !this.loggedOff )
		{
			var aMsg = new WebSockMessage( SockMsgId.LOGOFF );
			aMsg.address( this.conn.connectId, ConnectId.SERVER );
			aMsg.buf.writeInt32( 0 );	// default log off
			this.send( aMsg );
			this.pingTimer.stop();
			this.loggedOff = true;
		}
	};

	notifyUserActivity ()
	{
	};

	getNId ()
	{
		return this.conn.connectId;
	};

	getNIdBound ()
	{
		return this.idBound;
	};

	isGuest ()
	{
		if ( this.client )
			return this.client.isGuest();

		return true;
	};

	onConnect ()
	{
		this.pingTimer.stop();
		if ( this.loginMode == LoginMode.GUEST )
		{
			this.logonGuest();
		} else
		{
			this.logon();
		}
	};

	sendFeedback ( strAddress, strComment, callbackFeedback )
	{
		this.callbackFeedback = callbackFeedback;

		var aMsg = new WebSockMessage( SockMsgId.PLAYER_BASE_USER );
		aMsg.setUserType( PlayerUserMsgId.SEND_FEEDBACK );

		aMsg.buf.writeString( strAddress );
		aMsg.buf.writeString( strComment );
		aMsg.buf.writeInt32( /* glApp.versionCode */ versionCode.nFamily );
		aMsg.buf.writeString( Language.getStrUserLanguage() );
		this.send( aMsg );
	};

	/*
	//Waere cool. Gibt nur kein callback wenn kein Spieler gefunden wurde.
	PL.prototype.isRequesting = false;
	PL.prototype.requestQueue = null;
	PL.prototype.queueRequestWithTicket = function ( firstName, lastName, elo, year, callback )
	{
		 var request = function ()
		 {
			  this.isRequesting = true;
			  this.requestWithTicket( firstName, lastName, elo, year, requestCallback.bind( this ) );
		 }
		 var requestCallback = function ()
		 {
			  //debugger;
			  this.isRequesting = false;
			  callback( arguments[0] );
			  if ( this.requestQueue.length > 0 )
			  {
					this.requestQueue.shift()();
			  }
		 }
		 if ( this.isRequesting )
		 {
			  this.requestQueue.push( request.bind( this ) );
		 }
		 else
		 {
			  request.bind( this )();
		 }
	}
	*/

	localPB = null;

	byteArrayToBase64 ( buffer )
	{
		{
			var binary = '';
			var bytes = new Uint8Array( buffer );
			var len = bytes.byteLength;
			for ( var i = 0; i < len; i++ )
			{
				binary += String.fromCharCode( bytes[i] );
			}
			return window.btoa( binary );
		}
	}

	Base64ToByteArray ( base64 )
	{
		var binary_string = window.atob( base64 );
		var len = binary_string.length;
		var bytes = new Uint8Array( len );
		for ( var i = 0; i < len; i++ )
		{
			bytes[i] = binary_string.charCodeAt( i );
		}
		return bytes.buffer;

	}
	addToStorage ( player, firstname, lastname )
	{

		//var key = player.player.m_NewCBPlayer.m_aKey.m_sLastName + "," + player.player.m_NewCBPlayer.m_aKey.m_sFirstName;
		var storageObject = { player: player, created: new Date() };

		this.localPB.setPlayer( storageObject, firstname, lastname );

	}

	// NH2020 removed var: var playerFound = function ( storageObject, callback )
	playerFound ( storageObject, callback )
	{

		var player = storageObject.player;
		//if ( player.pic64 )
		//{

		//	player.pic = this.Base64ToByteArray( player.pic64 );
		//	player.pic64 = null;
		//}

		var aPlayer = new Player();
		aPlayer.m_NewCBPlayer = new NewCBPlayer();
		aPlayer.m_NewCBPlayer.m_aKey = new PlayerKey();
		aPlayer.m_NewCBPlayer.m_HighData = new HighPlayerData();

		aPlayer.setFirstName( player.player.m_NewCBPlayer.m_aKey.m_sFirstName );
		aPlayer.setLastName( player.player.m_NewCBPlayer.m_aKey.m_sLastName );
		aPlayer.setNation( player.player.m_NewCBPlayer.m_HighData.m_aNation );
		aPlayer.setTitle( player.player.m_NewCBPlayer.m_HighData.m_aTitle );
		callback( { player: aPlayer, pic: player.pic } );

	}

	getLocalPlayer ( firstName, lastName, callbackPlayer, callbackNotFound )
	{
		this.localPB.beginGetPlayer( firstName, lastName, 0, 0, function ( storageObject )
		{
			var data = storageObject.created;
			data.setDate( data.getDate() + 1 );
			if ( data > new Date() )
				this.playerFound( storageObject, callbackPlayer )
			else
				callbackNotFound();
		},
		callbackNotFound );
	}

	// NH2020I: callbacNotFound seems to be the function that gets called if the player is not
	// in the local playerBase. The player can then still be found after the server request.
	getPlayer ( firstName, lastName, elo, year, callbackPlayer, callbackNotFound )
	{
		var key = lastName + "," + firstName || "";
		var playerSuccessFunc = function ( storageObject )
		{
			var date = storageObject.created;
			if ( date.setMonth( date.getMonth() + 1 ) > new Date() )
			{
				// Log.Log("Player found in local storage");
				this.playerFound( storageObject, callbackPlayer );
				//var player = storageObject.player;
				////if ( player.pic64 )
				////{

				////	player.pic = this.Base64ToByteArray( player.pic64 );
				////	player.pic64 = null;
				////}

				//var aPlayer = new CB.Player();
				//aPlayer.m_NewCBPlayer = new CB.NewCBPlayer();
				//aPlayer.m_NewCBPlayer.m_aKey = new CB.PlayerKey();
				//aPlayer.m_NewCBPlayer.m_HighData = new CB.HighPlayerData();

				//aPlayer.setFirstName( player.player.m_NewCBPlayer.m_aKey.m_sFirstName );
				//aPlayer.setLastName( player.player.m_NewCBPlayer.m_aKey.m_sLastName );
				//aPlayer.setNation( player.player.m_NewCBPlayer.m_HighData.m_aNation );
				//aPlayer.setTitle( player.player.m_NewCBPlayer.m_HighData.m_aTitle );
				//callbackPlayer( { player: aPlayer, pic: player.pic } );
			}
			else
			{
				playerErrorFunc();
			}
		};

		// NH2020 Changed formatting
	// 	var playerErrorFunc = function ( datas )
	// 	{
	// 	  if ( ++this.connectTries < 12 )
	// 	  {
			  
	// 		  if ( !this.isLoggedIn() && !( this.conn.isConnected() || this.conn.isConnecting() ) )
	// 			  this.connect();
	// 	  //  if ( this.isLoggedIn() )
	// 		  {
	// 		  this.requestWithTicket( firstName, lastName, elo, year, function ( data )
	// 		  {
	// 			  this.addToStorage( data, firstName, lastName );
	// 			  callbackPlayer( data );
	// 		  }.bind( this ) );
	// 		  if ( callbackNotFound )
	// 			  callbackNotFound();
	// 		  }
	// 	  }
	//   }.bind( this );

		// NH2020 It seems this function gets called everytime the player cannot be found locally. Server Request is started.
		// I changed the increment of connect tries to only occur when the user is not connected.
		// Else, there can be only 12 server requests for player data
		var playerErrorFunc = function ( datas )
      	{

			if ( !this.isLoggedIn() && !( this.conn.isConnected() || this.conn.isConnecting() ) && this.connectTries < 12 )
			{
				this.connect();
				this.connectTries++;
				console.log(this.connectTries);
			}

			this.requestWithTicket( firstName, lastName, elo, year, function ( data )
			{
				this.addToStorage( data, firstName, lastName );
				callbackPlayer( data );
			}.bind( this ) );

			if ( callbackNotFound )
				callbackNotFound();

		}.bind( this );

		this.localPB.beginGetPlayer( firstName, lastName, elo, year, playerSuccessFunc.bind( this ), playerErrorFunc.bind( this ) );
	}

	workRequestQueue ()
	{
		while ( this.requestQueue.length > 0 )
		{
			this.requestQueue.pop()();
		}
	}

	// mit und ohne Bild gleiche Anfrage, nur der Type ist anders
	requestMultiPlayerInfo ( playerArray, callbackPlayerArray, withPicture )
	{
		if ( !this.boundLoginOk )
      {
         if ( !this.isLoggedIn() && ++this.connectTries < 4 && !( this.conn.isConnected() || this.conn.isConnecting() ) )
            this.connect();
			this.requestQueue.push( function ()
			{
				this.requestMultiPlayerInfo( playerArray, callbackPlayerArray, withPicture );
			}.bind( this ) );
			return;
		}

		this.idPlayerTicket++;
		var me = this;
		this.playerContext[this.idPlayerTicket] =
		{
			fnCallback: function ( arr )
			{
				for ( var i = 0; i < arr.length; i++ )
				{
					if ( arr[i].found )
					{
						me.addToStorage( arr[i], playerArray[i].firstName, playerArray[i].lastName );
					}
				}
				callbackPlayerArray( arr );
			}
		}

		var aMsg = new WebSockMessage( SockMsgId.PLAYER_BASE_USER );
		if ( withPicture )
			aMsg.setUserType( PlayerUserMsgId.QUERY_JS_MULTI_PLAYER_WITH_PIC );
		else
			aMsg.setUserType( PlayerUserMsgId.QUERY_JS_MULTI_PLAYER_INFO );
		aMsg.buf.writeUint32( this.idPlayerTicket );
		var buf = new DataBuffer();
		buf.writeUint32( playerArray.length );
		for ( var i = 0; i < playerArray.length; i++ )
		{
			buf.writeString( String.formatEx( "{0}#{1}", playerArray[i].lastName, playerArray[i].firstName ) );
			buf.writeUint32( playerArray[i].elo );
		}
		//aMsg.buf.writeInt32( buf.getSize() );
		//aMsg.buf.writeUint32( buf.getSize() );
		aMsg.buf.writeDataBuffer( buf );
		this.send( aMsg );
	}

	requestWithTicket ( firstName, lastName, elo, year, callbackPlayer )
	{
		if ( !this.boundLoginOk )
		{
			this.requestQueue.push( function ()
			{
				this.requestWithTicket( firstName, lastName, elo, year, callbackPlayer );
			}.bind( this ) );
			// Log.Log( "PlayerLobby no boundLogin" );
			return;	// bad luck
		}

		// Log.Log("Request Player Lobby Data");
		// Log.Log( "PBReq=" + lastName, "", "pb" );

		this.idPlayerTicket++;
		this.playerContext[this.idPlayerTicket] =
		 {
		 	fnCallback: callbackPlayer,
		 	elo: elo,
		 	year: year,
		 	player: {},
		 	pic: null
		 }


		var aMsg = new WebSockMessage( SockMsgId.PLAYER_BASE_USER );
		aMsg.setUserType( PlayerUserMsgId.QUERY_JS_PLAYER_BY_NAME_AND_ELO );

		aMsg.buf.writeUint32( this.idPlayerTicket );
		aMsg.buf.writeString( firstName );
		aMsg.buf.writeString( lastName );
		aMsg.buf.writeUint32( elo );
		aMsg.buf.writeUint32( year );
		//this.send( aMsg, true /*buffer*/ );
		this.send( aMsg );
	};

	handleReceived ( sockMsg )
	{
		if ( this.handleReceivedByConnector( sockMsg ) )
			return;

		// Log.Log( "PBReply=" + sockMsg.getType() + "/" + sockMsg.getUserType(), "", "pb" );

		try
		{
			switch ( sockMsg.getType() )
			{
				default:
					// NH2020 Changed log function
					Log.Log( "Unhandled: " + sockMsg.toString() );
					break;

				case SockMsgId.DEFAULTGROUPS:
					this.boundLoginOk = true;
					if ( this.callbackfnConnected )
						this.callbackfnConnected();
					this.workRequestQueue();
					this.fireOnConnected();
					//else
					//	this.logOff();
					break;
				case SockMsgId.BINDERTIMEOUT:
					// this.logIntoChat( "Sorry, cannot reach playerbase.com. It is in maintenance.", "LogBold" );
					break;
				case SockMsgId.INVALIDPASSWORD:
					//                    this.logIntoChat( "Sorry, your account or password is not valid.", "LogError" );
					break;
				case SockMsgId.ALREADYLOGGEDON:
					//                  this.logIntoChat( "Already logged on: " + this.user + ". Please log off or wait a few minutes.", "LogBold" );
					break;
					//case CB.SockMsgId.SERVER_IS_ALIVE:
					//	this.handleServerIsAlive( sockMsg );
					//	break;
				case SockMsgId.CHAT:
					this.handleChat( sockMsg );
					break;
				case SockMsgId.RECONNECT_OK:
					this.handleReconnectOk( sockMsg );
					break;
				case SockMsgId.PLAYER_BASE_USER:
					this.handleUserMsg( sockMsg );
					break;
			}
		}
		catch ( x )
		{
			var idStr = SockMsgId.toNumString( sockMsg.getType() );
			Log.Exception( idStr, x );

			// NH2020 comment
			// "#IFDEBUG"
			// alert( "PlBaseException: " + x.toString() );
			// "#ENDIF"
		}
	};

	handleUserMsg ( sockMsg )
	{
		try
		{
			switch ( sockMsg.getUserType() )
			{
				default:
					Log.Log( "UserMsg=" + PlayerUserMsgId.toString( sockMsg.getUserType() ) )
					break;
				case PlayerUserMsgId.ANS_JS_PLAYER_BY_NAME_AND_ELO:
					this.handleFillJSPlayer( sockMsg );
					break;
				case PlayerUserMsgId.SEND_FEEDBACK_ANS:
					this.handleFeedback( sockMsg );
					break;
				case PlayerUserMsgId.ANS_JS_PLAYER_MULTI_PLAYER_INFO:
					this.handleFillJSPlayerArray( sockMsg );
					break;
				case PlayerUserMsgId.ANS_JS_MULTI_PLAYER_WITH_PIC:
					this.handleFillJSPlayerArrayWithPics( sockMsg );
					break;
			}
		}
		catch ( x )
		{
			var idStr = PlayerUserMsgId.toNumString( sockMsg.getUserType() );
			Log.Exception( idStr, x );

			// NH2020 comment
			// "#IFDEBUG"
			// alert( "PlBaseException: " + x.toString() );
			// "#ENDIF"
		}
	};

	handleMultiNationInfo ( sockMsg )
	{
		this.handleTicket( sockMsg );
	};

	handleFillPlayerItemByNameAndThumb ( sockMsg )
	{
		this.handleTicket( sockMsg );
	};

	handleMultiPlayerItemBNat ( sockMsg )
	{
		this.handleTicket( sockMsg );
	};

	handleGetBatchId ( sockMsg )
	{
		this.handleTicket( sockMsg );
	};

	handleCurrentPBVersion ( sockMsg )
	{
		var aDB = sockMsg.getBuf();
		var nServerVersion = aDB.readUint32();

	};

	handleFillPlayerByCSID ( sockMsg )
	{
		this.handleTicket( sockMsg );
	};

	handleFillJSPlayer ( sockMsg )
	{
		var aDB = sockMsg.getBuf();
		var nTicketId = aDB.readUint32();

		var aPlayer = new Player();
		aPlayer.m_NewCBPlayer = new NewCBPlayer();
		aPlayer.m_NewCBPlayer.m_aKey = new PlayerKey();
		aPlayer.m_NewCBPlayer.m_HighData = new HighPlayerData();

		var bFound = aDB.readBool();

		// Log.Log( "JSPlayer: found=" + bFound, "LogBlue", "pb" );
		if ( bFound )
		{
			aPlayer.setFirstName( aDB.readString( 100 ) );
			aPlayer.setLastName( aDB.readString( 100 ) );
			var nELO = aDB.readInt();

			aPlayer.setNation( aDB.readInt() );
			aPlayer.setTitle( aDB.readShort() );
			var nPicture = aDB.readInt();
			if ( nPicture != 0 )
			{
				var nSize = aDB.readUint32();
				// Log.Log( "JSPlayer: found Picture, size=" + nSize, "LogBlue", "pb" );

				// image data
				var data = [];
				for ( var n = 0; n < nSize; n++ )
					data.push( aDB.readUint8() );
				//var sData = aDB.readString(20000);

				this.playerContext[nTicketId].fnCallback( {
					player: aPlayer,
					pic: data
				} )
			}
			else
				this.playerContext[nTicketId].fnCallback(
					 {
					 	player: aPlayer
					 } );
		}
	};

	handleFillJSPlayerArray ( sockMsg )
	{
		var aDB = sockMsg.getBuf();
		var nTicketId = aDB.readUint32();

		var nCount = aDB.readInt32();
		var arrPlayer = [];
		//for ( var i = 0; i < nCount; i++ )
		while ( aDB.nPos < aDB.nSize )
		{
			var aPlayer = new Player();
			aPlayer.m_NewCBPlayer = new NewCBPlayer();
			aPlayer.m_NewCBPlayer.m_aKey = new PlayerKey();
			aPlayer.m_NewCBPlayer.m_HighData = new HighPlayerData();

			var found = aDB.readBool();
			aPlayer.setLastName( aDB.readString( 100 ) );
			aPlayer.setFirstName( aDB.readString( 100 ) );
			var elo = aDB.readUint32();
			aPlayer.setNation( aDB.readUint32() );
			aPlayer.setTitle( aDB.readShort() );
			arrPlayer.push(
				{
					found: found,
					player: aPlayer
				} );
		}
		this.playerContext[nTicketId].fnCallback( arrPlayer );

	}

	handleFillJSPlayerArrayWithPics ( sockMsg )
	{
		var db = sockMsg.getBuf();
		var nTicketId = db.readUint32();
		var nCount = db.readUint32();
		var arrPlayer = [];
		while ( db.nPos < db.nSize )
		{
			var aPlayer = new Player();
			aPlayer.m_NewCBPlayer = new NewCBPlayer();
			aPlayer.m_NewCBPlayer.m_aKey = new PlayerKey();
			aPlayer.m_NewCBPlayer.m_HighData = new HighPlayerData();

			var found = db.readBool();
			aPlayer.setLastName( db.readString( 100 ) );
			aPlayer.setFirstName( db.readString( 100 ) );
			var elo = db.readUint32();
			aPlayer.setNation( db.readUint32() );
			aPlayer.setTitle( db.readShort() );
			var nPic = db.readInt32();
			var data = null;
			if ( nPic != 0 )
			{
				var nPicSize = db.readUint32();
				data = [];
				for ( var i = 0; i < nPicSize; i++ )
					data.push( db.readUint8() );
			}
			arrPlayer.push(
				{
					found: found,
					player: aPlayer,
					pic: data,
				} );
		}
		this.playerContext[nTicketId].fnCallback( arrPlayer );

	}

	handleTicket ( sockMsg )
	{
		var aDB = sockMsg.getBuf();
		var nTicketId = aDB.readUint32();

		if ( nTicketId == 1 )
		{
			var nCode = aDB.readUint32();
			var nResCode = aDB.readUint32();
			var aPlayer = new Player();
			aPlayer.readFromDataBuffer( aDB );

			this.callbackPlayer( aPlayer, this.me );
			// callback here

		}
		else
		{
			var nCode = aDB.readUint32();
			var nResult = aDB.readUint32();
			if ( nResult == 2 )
			{
				var nSize = aDB.readUint32();

				// image data
				var data = [];
				for ( var n = 0; n < nSize; n++ )
					data.push( aDB.ReadByte() );

				this.callbackPortrait( data, this.me );
				//if (onCBPlayerImage != null) {
				//onCBPlayerImage (data);
				//}


			} else
			{
				this.callbackPortrait( data, this.me );
			}
			//Console.WriteLine ("receiving pic");
		}
	};

	handleFeedback ( sockMsg )
	{
		alert( "thanks" );
	};

	handleChat ( sockMsg )
	{
		Log.Missing();
		// NH2021D 
		// var chat = new ChatData;
		// chat.fromSocketsMsg( sockMsg );
		// if ( chat.isToSession() )
		// {
		// 	var strGameTitle = this.watchSessions.getTitleOfSession( chat.getIdSession() );
		// 	if ( strGameTitle.length )
		// 		chat.strMsg = "(" + strGameTitle + "): " + chat.strMsg;
		// 	glApp.panelMgr.chatOut( chat.toString(), "LogKibitzChat" );
		// }
		// else
		// 	glApp.panelMgr.chatOut( chat.toString() );
	};


	handleReconnectOk ( sockMsg )
	{
		var idGroup = sockMsg.getBuf().readInt();
		Log.Log( "Reconnect OK, groupId=" + idGroup + ", N=" + sockMsg.getVal() + " idRecv=" + sockMsg.getIdReceiver(), "Bold" );
		this.fireOnConnected();
	};

	logIntoChat ()
	{
		// place holder
	};

	send ( msg )
	{
		//		CB.LOG( "PBSend: " + msg.toString() );
		try
		{
			if ( msg.getIdSender() == 0 )
				msg.setIdSender( this.getNId() );
			if ( msg.getIdReceiver() == 0 )
				msg.setIdReceiver( ConnectId.SERVER );
			this.conn.sendMessage( msg, true /*noId*/, true /*noCheck*/ );
		}
		catch ( e )
		{
			Log.Log( e.toString() );
			if ( !this.timerReconnect.isActive() && !this.inTorture )
			{
				this.timerReconnect.runOnce( Math.min( 60 * 1000, 5000 + this.reconnectTries * 1000 ),
		            function ()
		            {
		 	            this.pingServer( true /*silent*/ );
		            }.bind( this ) );
				this.reconnectTries++;
			}
		}
	};

	




	// NH2020
	// "#IFDEBUG"
	// test = function ()
	// {
	// 	this.logIntoChat( "Start Torture" );
	// 	//	this.sendSimple( CB.SockMsgId.TEST );
	// 	this.torturer = new CB.Torturer( "ws://localhost" );
	// 	this.torturer.run();
	// };
	// "#ENDIF"

	// "#IFDEBUG"
	// test2 = function ()
	// {
	// 	this.logIntoChat( "Stop Torture" );
	// 	if ( this.torturer )
	// 		this.torturer.stop();
	// };
	// "#ENDIF"

	sendRandomMessage ()
	{
		for ( var i = 0; i < 10; i++ )
		{
			var val = Math.floor( Math.random() * 15 );
			//	CB.LOG( "Rnd=" + val );
			switch ( val )
			{
				case 0:
					this.pingServer();
					break;
				case 1:
					this.changeGroup( 13 );
					break;
				case 2:
					this.changeGroup( 14 );
					break;
				case 3:
					this.requestChannels();
					break;
				case 4:
					this.updatePlayerStatus();
					break;
				case 5:
					this.lists.requestGameList( true );
					break;
				case 6:
					//this.lists.sendSeek( new CB.ClockParams( 3, 2 ) );
					break;
				case 7:
					this.lists.onShowPlayers();
					this.lists.onHideGames();
					break;
				case 8:
					this.lists.onShowGames();
					this.lists.onHidePlayers();
					break;
				case 9:
				case 10:
				case 11:	// irgendeinen Dreck senden
				case 12:
					var msg = new WebSockMessage( 7000 + Math.floor( Math.random() * 100 ) );
					msg.address( this.getNId(), ConnectId.SERVER );
					if ( msg.getType() != SockMsgId.LOGOFF && msg.getType() != SockMsgId.LOGON )
						this.send( msg );
					break;
				case 13:
				case 14:	// dito für User Msg
					var msg = new WebSockMessage( SockMsgId.USER );
					msg.setUserType( 1000 + Math.floor( Math.random() * 100 ) );
					msg.address( this.getNId(), ConnectId.SERVER );
					this.send( msg );
					break;
			}
		}
	};

	static InitListeners()
    {
        if ( !PlayerLobby.prototype.fireEvent )
        {
			ListenersUtil.initForListeners( PlayerLobby );
			ListenersUtil.addEvent( PlayerLobby, "Connected" );
        }
    }

}
