import { LOGIN_MODES } from "CBReact/Components/Account/AccountUtils";
import { GameResultEnum } from "common/Chess/Logic/GameHeader";
import { NotationGenerator } from "common/Chess/Logic/Notation";
import { OnlineEntryEnum } from "common/Chess/OnlineDB/OnlineEntry";
import { OnlineLobby } from "common/Chess/OnlineDB/OnlineLobby";
import { ObjUtil } from "common/Patterns/ObjectUtil";
import { System } from "common/Tools/System";
import { LoginMode } from "common/WebClient/Protocol/LogonData";
import { useEffect, useRef, useState, useCallback } from "react";

import ReactGA, { ga } from 'react-ga';

const SERVER = System.isHTTPS() ? "wss://dbserver.chessbase.com:443" : "ws://dbserver.chessbase.com:7000";

export function useOnlineLobbyContext (getCurrentLoginStatus, localizationRef)
{
    const onlineLobbyRef = useRef();
    const callbackIndices = useRef();
    const searchMask = useRef();
    const databaseTableContentRef = useRef([]);

    const [databaseTableContent, setDatabaseTableContent] = useState([]);
    const [playerData, setPlayerData] = useState({});
    const [isConnected, setIsConnected] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isScrollLoading, setIsScrollLoading] = useState(false);
    const [reachedScrollSearchEnd, setReachedScrollSearchEnd] = useState(false);
    const [currentSearchCount, setCurrentSearchCount] = useState(0);

    useEffect(() => {
        if (!onlineLobbyRef.current)
        {
            var newOnlineLobby = new OnlineLobby();
            newOnlineLobby.create( SERVER, "Guest", "", LoginMode.GUEST ); // createLobby
            newOnlineLobby.connect();
            newOnlineLobby.addOnIDReceivedListener(handleOnlineLobbyConnected);

            var index01 = newOnlineLobby.addOnGamesListener(handleGames);
            var index02 = newOnlineLobby.addOnNoGamesFoundListener(handleNoGamesFound);
            var index03 = newOnlineLobby.addOnScrollSearchListener(handleGamesOnScrollSearch)
            callbackIndices.current = [index01, index02, index03];

            onlineLobbyRef.current = newOnlineLobby;
            window.addEventListener('beforeunload', logOffOnlineLobby);
        }

        return () => {
            if (onlineLobbyRef && onlineLobbyRef.current && callbackIndices && callbackIndices.current)
            {
                onlineLobbyRef.current.removeOnGamesListener(callbackIndices.current[0]);
                onlineLobbyRef.current.removeOnNoGamesFoundListener(callbackIndices.current[1]);
                onlineLobbyRef.current.removeOnScrollSearchListener(callbackIndices.current[2]);
            }
        }
    }, []);

    // TODO: Could maybe be done with onlineLobby/Connector value of is connected
    function handleOnlineLobbyConnected ()
    {
        setIsConnected(true);
    }

    function searchOnlineDB (newSearchMask, maxGames, firstMove)
    {
        searchMask.current = newSearchMask;

        if (isConnected)
        {
            setIsLoading(true);
            setReachedScrollSearchEnd(false);
            setCurrentSearchCount(1);
            onlineLobbyRef.current.search(searchMask.current, maxGames, firstMove);
        }

        ReactGA.event({
            category: 'Interaction',
            action: 'Searched Database',
        });
    }

    function scrollSearchOnlineDB ()
    {
        var isPremium = (getCurrentLoginStatus() === LOGIN_MODES.standard || getCurrentLoginStatus() === LOGIN_MODES.premium);

        if (isConnected && isPremium && !isScrollLoading)
        {
            setCurrentSearchCount(currentSearchCount+1);
            setIsScrollLoading(true);
            onlineLobbyRef.current.scrollSearch();
        }
    }

    function refreshOnlineDBList (newBoard, newSideToMove, maxGames, firstMove)
    {
        if (isConnected && searchMask.current)
        {
            setIsLoading(true);
            setReachedScrollSearchEnd(false);
            setCurrentSearchCount(1);

            searchMask.current.setSearchBoard(newBoard, newSideToMove);
            searchMask.current.setUseBoard(true);

            onlineLobbyRef.current.search(searchMask.current, maxGames, firstMove);
        }
    }

    function requestAutoComplete (text, callback)
    {   
        if (isConnected)
            onlineLobbyRef.current.requestAutoComplete(text, callback);
    }

    function handleGames (games, reachedScrollEnd)
    {
        setIsLoading(false);

        if (reachedScrollEnd)
            setReachedScrollSearchEnd(true);

        var newDatabaseTableContent = createDatabaseTableContent(games);
        if (newDatabaseTableContent)
        {
            databaseTableContentRef.current = newDatabaseTableContent;
            setDatabaseTableContent(newDatabaseTableContent);
        }

        var newPlayerData = createPlayerData(games);
        if (newPlayerData)
            setPlayerData(newPlayerData);
    }

    function handleGamesOnScrollSearch (games, reachedScrollEnd)
    {
        setIsLoading(false);
        setIsScrollLoading(false);

        if (reachedScrollEnd)
            setReachedScrollSearchEnd(true);

        var newDatabaseTableContent = createDatabaseTableContent(games);
        if (newDatabaseTableContent)
        {
            databaseTableContentRef.current = databaseTableContentRef.current.concat(newDatabaseTableContent);
            setDatabaseTableContent(databaseTableContentRef.current);
        }
    }

    function handleNoGamesFound ()
    {
        setIsLoading(false);
        setDatabaseTableContent([]);
        setReachedScrollSearchEnd(true);
        databaseTableContentRef.current = [];
    }

    function createDatabaseTableContent (games)
    {
        var showPremium = (getCurrentLoginStatus() === LOGIN_MODES.standard || getCurrentLoginStatus() === LOGIN_MODES.premium);
        var newContent = [];
        for (var i = 0; i < games.length; i++)
        {
            games[i].aGame.onlineDBID = games[i].aGameNr;

            let header = games[i].aGame.hdr;
            newContent.push({
                render: [header.date.year,
                        header.white.getListStr(),
                        header.eloWh || '',
                        header.black.getListStr(),
                        header.eloBl || '',
                        GameResultEnum.toString(header.result),
                        header.eco,
                        showPremium ? OnlineLobbyUtils.gameToString(games[i].aGame, onlineLobbyRef.current.firstMove, localizationRef.current.strings.NOTA_LOCALIZATION) : ""],
                info: { selection: games[i].aGameNr, game: games[i].aGame }
            });
        }

        return newContent;
    }

    function createPlayerData (games)
    {
        if (getCurrentLoginStatus() === LOGIN_MODES.guest || getCurrentLoginStatus() === LOGIN_MODES.free)
            return;

        var playerData = {};

        for ( var i = 0; i < games.length; i++ )
        {
            if ( games[i].nFlags & OnlineEntryEnum.FLAGS_ONLINE_GAME )
            {
                if ( onlineLobbyRef.current.firstMove < games[i].aGame.getCurLine().length )
                {
                    var move = games[i].aGame.getCurLine()[onlineLobbyRef.current.firstMove];
                    var selection = OnlineLobbyUtils.calcTeraBrainID(move);
                    if (!playerData[selection])
                        playerData[selection] = [];

                    var player = onlineLobbyRef.current.firstMove & 1 ? games[i].aGame.hdr.black.getListStr( 0 ) : games[i].aGame.hdr.white.getListStr( 0 );
                    
                    if (playerData[selection].length < 3 && !playerData[selection].includes(player))
                        playerData[selection].push((playerData[selection].length === 0 ? "" : "/") + player);
                }
            }
        }

        return playerData;
    }

    function logOffOnlineLobby ()
    {
        if (onlineLobbyRef.current)
            onlineLobbyRef.current.logOff(); 
    }

    return {searchOnlineDB, scrollSearchOnlineDB, refreshOnlineDBList, requestAutoComplete, isConnected, isLoading, isScrollLoading, reachedScrollSearchEnd, currentSearchCount, databaseTableContent, playerData};
}

class OnlineLobbyUtils 
{

    static gameToString (game, startMove, notaLocalization)
    {
        var str = "";
        var notaGen = new NotationGenerator(notaLocalization);
        game.gotoIndex( startMove || 0 );
        var pos = ObjUtil.clone( game.getCurPos() );
        var nMove = game.getMoveNo();
        var nCnt = 0;
        while ( nMove < game.getCurLine().length && nCnt < 16 )
        {
            var move = game.getCurLine()[nMove];
            pos.preCalcCtx( move );
            pos.makeMove( move );
            pos.postCalcCtx( move, true );
            var strMove = notaGen.getMoveNota( move );
            var strMoveNum = notaGen.getStrMoveNumInLine( nCnt, game.getMoveNo() );
            if ( str.length )
                str += " ";
            str += strMoveNum + strMove;
            nMove++;
            nCnt++;
        }
        return str;
    }

    static calcTeraBrainID (move) 
    {
        var id = (move.prom << 12) + (move.from << 8) + (move.to);
        return "TBBook" + id;
    };
}