/* eslint-disable eqeqeq */

import { Log } from 'common/Tools/Log'
//import { glApp } from "common/App/App";
import { ListenersUtil } from 'common/Patterns/Listeners';
import { ObjUtil } from 'common/Patterns/ObjectUtil';
import { CBDebug } from "common/Tools/Debug/debug_util";	
import { SpecialGlyphs } from "common/Chess/Format/PGN/glyph";
import { StringBuilder } from 'common/Tools/Strings/StringBuilder';
import { Move } from "common/Chess/Logic/Move";
//import { $ } from "jquery";

import { fritzWorkerScript } from "common/Chess/Engine/webasm/FritzWorkerBlob"


export var LineEvalType =
{
	LOWERBOUND: 1,
	UPPERBOUND: 2,

	EXACT: 3
};

var PredefinedEngineOptions =
{
	MultiPV: "MultiPV",
	Ponder: "Ponder",
};


export class EngineValue
{
	static MATE_VALUE = 30000;
	static MATE_THRESHOLD = 28000;
	static MOVE_VALUE = 28000;
	static MOVE_THRESHOLD = 26000;

	static MIN_VALUE = -this.MATE_VALUE;
	static MAX_VALUE = this.MATE_VALUE;

	static getMateEval( _mateIn )
	{
		if ( _mateIn < 0 )
			return -EngineValue.MATE_VALUE - _mateIn;
		return EngineValue.MATE_VALUE - _mateIn;
	};

	static getMovesToMate( _eval )
	{
		if ( _eval > -EngineValue.MATE_THRESHOLD || _eval < EngineValue.MATE_THRESHOLD )
			return -1;
		if ( _eval < 0 )
			return _eval - EngineValue.MATE_THRESHOLD;
		return EngineValue.MATE_THRESHOLD - _eval;
	};
};

export class EvaluationGrade 
{
	static EV_TOTALLY_WINNING = 200;
	static EV_WINNING = 140;
	static EV_ADVANTAGE = 70;
	static EV_SLIGHT_ADVANTAGE = 25;
	static EV_TOTALLY_LOSING = -this.EV_TOTALLY_WINNING;
	static EV_LOSING = -this.EV_WINNING;
	static EV_DISADVANTAGE = -this.EV_ADVANTAGE;
	static EV_SLIGHT_DISADVANTAGE = -this.EV_SLIGHT_ADVANTAGE;

	static getGlyph( _eval )
	{
		var gly = SpecialGlyphs.NONE;

		if ( _eval >= this.EV_WINNING )
			gly = SpecialGlyphs.W_DECIS_ADV;
		else if ( _eval >= this.EV_ADVANTAGE )
			gly = SpecialGlyphs.W_MODER_ADV;
		else if ( _eval > this.EV_SLIGHT_ADVANTAGE )
			gly = SpecialGlyphs.W_SLIGHT_ADV;
		else if ( _eval >= this.EV_SLIGHT_DISADVANTAGE )
			gly = SpecialGlyphs.EQUAL_ACTIVE_POS;
		else if ( _eval > this.EV_DISADVANTAGE )
			gly = SpecialGlyphs.B_SLIGHT_ADV;
		else if ( _eval > this.EV_LOSING )
			gly = SpecialGlyphs.B_MODER_ADV;
		else
			gly = SpecialGlyphs.B_DECIS_ADV;

		return gly;
	};
}


////////////////////////////////////////////////////////////////////

export class EngineInstance 
{
	constructor( _engUrl, _szPool, _tag, _name )
	{
		//console.log( _engUrl );
		Log.Log("New Engine. URL: " + _engUrl );

		this.url = _engUrl;
		this.worker = null;
		this.calculating = false;

		this.tag = _tag || null;

		this.name = _name;

		this.szPool = _szPool;
		//if ( !( this.szPool >= 0 ) )
		//	this.szPool = 1;

		this.pool = [];
		this.cur = null;
		this.lines = [];

		this.meta = { name: "", author: "" };

		this.metaDone = false;

		if ( this.szPool )
			this.idPoolTimeout = setInterval( this._poolTimeout.bind( this ), this.POOL_TIMEOUT );

		this.engOptionVals = EngineInstance._createPreDefaultOptions();

		//Auf jeden Fall einen erzeugen...
		if ( this.szPool > 0 )
			this._pushWorker();

		this.id = ++EngineInstance.engineCount;

		this.minDepthForFire = 0;

		EngineInstance.InitListeners();
	};

	static engineCount = 0;

	static _createPreDefaultOptions = function()
	{
		var res = {};
		res[ PredefinedEngineOptions.MultiPV ] = 1;
		res[ PredefinedEngineOptions.Ponder ] = "false";
		return res;
	};

	setMinDepthForFire( n )
	{
		this.minDepthForFire = n;
	};

	getTag()
	{
		return this.tag;
	};

	setTag( _tag )
	{
		this.tag = _tag;
	};

	/*_gm is a Game
	_options = 
	{
		infinite: bool,
		wtime: int,
		btime: int,
		time: int,
		depth: int
	}
	*/

	_killWorker()
	{
		//	Log.Log( "KillWorker", "LogRed" );

		CBDebug.assert( this.worker != null );
		CBDebug.assert( this.calculating );

		this.calculating = false;
		this.worker.terminate();
		this.worker = null;
		this.loadingWorker = false;
	};

	_handleBestMove( _str )
	{
		var arr = _str.split( " " );
		var strMv = arr[ 1 ];

		var mv = Move.fromString( strMv );

		var ponderMove;
		if ( arr[ 2 ] === "ponder" )
			ponderMove = Move.fromString( arr[ 3 ] );

		this.calculating = false;

		this.fireOnBestMove( this, { move: mv, ponder: ponderMove } );
	};

	_handleInfo( _str )
	{
		//Log.Log( "UCI INFO for eng=" + this.id, "LogBlue" );
		// console.log( "UCI Info " + _str );

		var arrTokens = _str.split( " " );

		var depth = null;
		var seldepth = null;

		var val = null;
		var pvnum = 1;
		var pv = null;
		var nps = null;
		var nodes = null;
		var time = null;

		var curmv = null;
		var curmvnum = null;

		var evalType = LineEvalType.EXACT;
		var mate = false;

		for ( var i = 1; i < arrTokens.length; ++i )
		{
			var strToken = arrTokens[ i ];

			switch ( strToken )
			{
				case "depth":
					depth = parseInt( arrTokens[ ++i ] );
					break;
				case "seldepth":
					seldepth = parseInt( arrTokens[ ++i ] );
					break;
				case "nps":
					nps = parseInt( arrTokens[ ++i ] );
					break;
				case "nodes":
					nodes = parseInt( arrTokens[ ++i ] );
					break;
				case "score":
					var type = arrTokens[ ++i ];
					val = parseInt( arrTokens[ ++i ] );
					switch ( type )
					{
						default:
							break;
						case "cp":
							break;
						case "mate":
							mate = true;
							break;
					}
					break;
				case "time":
					time = parseInt( arrTokens[ ++i ] );
					break;
				case "currmovenumber":
					curmvnum = parseInt( arrTokens[ ++i ] );
					break;
				case "currmove":
					{
						let strMv = arrTokens[ ++i ];
						curmv = Move.fromString( strMv );
					}
					break;
				case "lowerbound":
					evalType = LineEvalType.LOWERBOUND;
					break;
				case "upperbound":
					evalType = LineEvalType.UPPERBOUND;
					break;
				case "multipv":
					pvnum = parseInt( arrTokens[ ++i ] );
					break;
				case "pv":
						pv = [];
						for ( ++i; i < arrTokens.length; ++i )
						{
							var strMv = arrTokens[ i ];
							if ( Move.isValidString( strMv ) )
								pv.push( Move.fromString( strMv ) );
							else
							{
								--i;
								break;
							}
						}
					break;
				case "hashfull":
					break;
				default:
					continue;

			}
		}

		seldepth = seldepth || depth;

		if ( depth && depth > this.minDepthForFire )
		{
			this.fireOnInfo( this, depth, seldepth, nodes, nps, time );
		}
		if ( curmv )
		{
			if ( !this.linesOnly )	// todo flags
				this.fireOnCurMove( this, curmvnum, curmv );
		}
		if ( pv )
		{
			this.lines[ pvnum - 1 ] = pv;

			if ( depth > this.minDepthForFire || depth <= 2 )
			{
				var lnPV = ObjUtil.clone( pv );
				this.fireOnLine( this, depth, seldepth, evalType, mate, val, pvnum, lnPV,
					{
						handicapStyle: 0,
						analysis: this.currGoOptions && this.currGoOptions.infinite
					} );
			}
		}
	};

	static g_regexMeta = /^id (\w+) (.*)$/i;

	_handleIdInfo( _str )
	{
		if ( this.metaDone )
		{
			return;
		}

		var arr = EngineInstance.g_regexMeta.exec( _str );
		if ( !arr || !arr.length )
			return;

		var strVar = arr[ 1 ];
		var strVal = arr[ 2 ];

		this.meta[ strVar ] = strVal;
	};

	_handleUCIOK( _str )
	{
		//	Log.Log( "UCI OK for eng=" + this.name + this.id.toString() , "LogBold" );

		if ( this.metaDone )
			return; 
		this.metaDone = true;
		//	Log.Log( _str );
		if ( !this.linesOnly )	// todo flags
			this.fireOnUCIInfo( this );
	};

	_handle( _str )
	{
		//Log.Log( _str );

		if ( _str === "readyok" )
		{
			//nix
		}
		else if ( _str === "uciok" )
		{
			this._handleUCIOK();
		}
		else if ( _str.startsWith( "id" ) )
		{
			this._handleIdInfo( _str );
		}
		else if ( _str.startsWith( "option" ) )
		{
			//nix
		}
		else if ( _str.startsWith( "bestmove" ) )
		{
			this._handleBestMove( _str );
			//	Log.Log( "UCI BEST=" + _str, "LogGreen" );
		}
		else if ( _str.startsWith( "info" ) )
		{
			this._handleInfo( _str );
		}
	};

	_onMessage( _evt )
	{
		this.loadingWorker = false;

		var str = _evt.data;

		// Log.Log( str );
		//console.log( "_onMessage: " + str );

		this._handle( str );
	};

	_post( _strCmd )
	{
		try
		{
			if ( this.worker )
			{
				this.worker.postMessage( _strCmd );
			}
		}
		catch ( x )
		{
			Log.Exception( "Post", x );
		}
	};

	static POOL_TIMEOUT = 4000;

	_getWorker()
	{
		if ( this.pool.length == 0 )
		{
			//	Log.Log( "Creating new Worker", "LogGreen" );
			try
			{
				return new Worker( fritzWorkerScript );
			}
			catch ( x )
			{
				alert( "Engine: " + x );
			}
		}
		//else
		//	Log.Log( "Using existing Worker", "LogGreen" );
		return this.pool.pop();
	};

	_poolTimeout()
	{
		if ( this.pool.length >= this.szPool )
			return;
		this._pushWorker();
	};

	_pushWorker()
	{
		//	Log.Log( "Worker: " + this.url, "LogBlue" );
		//"#IFDEBUG"
		//console.log( "Engine: " + this.url );
		// "#ENDIF"
		try
		{
			// if ( EngineInstance.isAjax )
			// {
			// 	this._startWorkerAjax( function( worker )
			// 	{
			// 		this.pool.push( worker );
			// 	}.bind( this ) );
			// }
			// else
			// 	this.pool.push( new Worker( this.url ) );

			this.pool.push( new Worker( fritzWorkerScript ) );
		}
		catch ( x )
		{
			Log.Exception( "Engine " + this.url, x );
		}
	};

	_startWorker()
	{
		try
		{
			this.worker = this._getWorker();
			if ( this.worker )
			{
				this.worker.onmessage = this._onMessage.bind( this );
				this._post( "uci" );
				this._post( "isready" );

				this._sendPendingOptions();
			}
		}
		catch ( x )
		{
			Log.Exception( "Start this.url", x );
		}
	};

	go( _gm, _options )
	{
		this.currGoOptions = _options || { infinite: true };
		this.stop();	// 
		this.calculating = true;	// set early, so that a quick stop kills the worker
		// this._post( "stop" );	// reminder of a better implementation
		if ( !this.linesOnly )	// todo flags
			this.fireOnSearchStart();
		if ( !this.worker && !this.loadingWorker )
		{
			this.loadingWorker = true;
			// if ( EngineInstance.isAjax )
			// {
			// 	this._startWorkerAjax( ( function( gm )
			// 	{
			// 		return function( worker )
			// 		{
			// 			this.loadingWorker = false;
			// 			this.worker = worker;

			// 			this.worker.onmessage = this._onMessage.bind( this );

			// 			this._post( "uci" );
			// 			this._post( "isready" );

			// 			this._sendPendingOptions();

			// 			var strPos = EngineInstance.UCIUtils.getPosString( gm );
			// 			var strGo = EngineInstance.UCIUtils.getGoString( this.currGoOptions );
			// 			//console.log( strPos );
			// 			//console.log( strGo );
			// 			if ( strGo != "" )
			// 			{
			// 				this.cur = ObjUtil.clone( gm.getCurPos() );
			// 				this.lines = [];
			// 				this._post( strPos );
			// 				this._post( strGo );
			// 			}
			// 		}.bind( this )
			// 	}.bind( this )( _gm ) ) );
			// 	return;
			// }
			// else
				this._startWorker();
		}
		else
		{
			Log.Log( "Go, Worker already there" );
		}

		var strPos = EngineInstance.UCIUtils.getPosString( _gm );
		var strGo = EngineInstance.UCIUtils.getGoString( this.currGoOptions );

		if ( strGo != "" )
		{
			this.cur = ObjUtil.clone( _gm.getCurPos() );
			this.lines = [];
			this._post( strPos );
			this._post( strGo );
		}
	};

	getCurPos()
	{
		return this.cur;
	};

	getLines()
	{
		return this.lines;
	};

	getBestMove()
	{
		if ( this.lines.length == 0 )
			return null;
		if ( this.lines[ 0 ].length == 0 )
			return null;
		return this.lines[ 0 ][ 0 ];
	};

	stop()
	{
		if ( this.worker )
		{
			if ( this.calculating )
			{
				//	Log.Log( "Engine Stop, Killing Worker", "LogGreen" );
				this._post( "quit" );
				this._killWorker();
			}
			else
			{
				//NIX MACHEN
				//	Log.Log( "Engine Stop, Worker not killed!", "LogRed" );
			}
		}
	};

	getName()
	{
		return this.meta.name;
	};

	isCalculating()
	{
		return this.calculating;
	};

	setHighestMoveInBook()	// only handicap
	{

	};

	newGame()
	{
		if ( this.worker && !this.calculating )
		{
			this._post( "ucinewgame" );
		}
		if ( !this.linesOnly )	// todo flags
			this.fireOnNewGame();
	};

	dispose()
	{
		if ( this.szPool )
		{
			if ( this.pool.length )
			{
				for ( var i = 0; i < this.pool.length; ++i )
				{
					var wrk = this.pool[ i ];
					wrk.terminate();
				}
			}
			clearInterval( this.idPoolTimeout );
		}
		this.removeAllListeners();
	};

	exit()
	{
		this.stop();
		this.dispose();
		this.fireOnExit();
	};

	



	setOption( _name, _val )
	{
		this.engOptionVals[ _name ] = _val;

		if ( this.worker )
		{
			this._post( EngineInstance.UCIUtils.getSetOptionString( _name, _val ) );
		}
	};

	getOption( _name )
	{
		return this.engOptionVals[ _name ];
	};

	//SHORT CUT for MultiPV
	/**************/
	setMultiPV( _multiPV )
	{
		this.setOption( PredefinedEngineOptions.MultiPV, _multiPV );
	};
	getMultiPV()
	{
		return this.getOption( PredefinedEngineOptions.MultiPV );
	};
	clearHash()
	{
		//this.setOption( PredefinedEngineOptions.ClearHash );
	};
	/**************/

	setPonder( on )
	{
		this.setOption( PredefinedEngineOptions.Ponder, on ? "true" : "false" );
	};

	offerDrawToEngine()
	{
		return false;	// TODO
	};

	_sendPendingOptions()
	{
		for ( var name in this.engOptionVals )
		{
			if ( !this.engOptionVals.hasOwnProperty( name ) )
				continue;

			this._post( EngineInstance.UCIUtils.getSetOptionString( name, this.engOptionVals[ name ] ) );
		}
	};

	// _startWorkerAjax( fnLoaded )
	// {
	// 	// klappt leider nicht im IE11. crasht im ersten postmessage des Workers.
	// 	// OK in Edge, Chrome

	// 	var url = 'https://pgn.chessbase.com/common/chess/engine/df14/df14AjaxData.js';
	// 	if ( glApp.canRunWebAssembly() )
	// //		url = 'https://pgn.chessbase.com/common/chess/engine/webasm/fritzajax3.js';
	// 		url = 'common/chess/engine/webasm/fritzajax3.js';

	// 	$.ajax( {
	// 		url: url,
	// 		type: "GET",
	// 		data: {},
	// 		async: true,
	// 		cache: true,
	// 		processData: false,
	// 		// contentType: false,   (This doesn't seem like it should be "false")                 
	// 		success: function( data )
	// 		{
	// 			// var len = data.length;
	// 			var blob = new Blob( [ data ], { type: 'application/javascript' } );
	// 			var worker = new Worker( URL.createObjectURL( blob ) );
	// 			fnLoaded( worker );
	// 			//console.log( "Loaded Remote Worker: " + url );
	// 		}
	// 	} );
	// };

	static InitListeners()
	{
		if ( !EngineInstance.prototype.fireEvent )
		{
			ListenersUtil.initForListeners( EngineInstance );
			ListenersUtil.addEvent( EngineInstance, "Line" );
			ListenersUtil.addEvent( EngineInstance, "LosingLine" );	// not used in standard engine, only Beefy.
			ListenersUtil.addEvent( EngineInstance, "CurMove" );
			ListenersUtil.addEvent( EngineInstance, "CurLine" );
			ListenersUtil.addEvent( EngineInstance, "Info" );
			ListenersUtil.addEvent( EngineInstance, "BestMove" );
			ListenersUtil.addEvent( EngineInstance, "UCIInfo" );
			ListenersUtil.addEvent( EngineInstance, "NewGame" );
			ListenersUtil.addEvent( EngineInstance, "SearchStart" );
			ListenersUtil.addEvent( EngineInstance, "Exit" );
			ListenersUtil.addEvent( EngineInstance, "Threat" );

			// NH2020 Added this, as it was missing in EngineLinesElement: setEngine
			ListenersUtil.addEvent( EngineInstance, "BeautyScore" );
		}

	};

		/////////////////////////////////////////////////////////////////////////////
	static UCIUtils = {
		getPosString: function( _gm )
		{
			var str = "position startpos";
	
			if ( !_gm.isNormalInit() )
			{
				str = "position fen " + _gm.getStartPos().toFEN();
			}
	
			var line = _gm.getCurLineCut();
	
			if ( line.length > 0 )
			{
				str += " moves";
	
				for ( var i = 0; i < line.length; ++i )
				{
					var mv = line[ i ];
					str += " " + mv.toString();
				}
			}
	
			//console.log(str);
			return str;
		},
		getGoString: function( _options )
		{
			var sb = new StringBuilder();
	
			sb.append( "go " );
	
			_options = _options || {};
	
			if ( _options.depth )
			{
				sb.appendFormat( "depth {0} ", _options.depth );
			}
			else if ( _options.moveTime )
			{
				sb.appendFormat( "movetime {0} ", Math.round( _options.moveTime ) );
			}
			if ( _options.wTime )
			{
				sb.appendFormat( "wtime {0} ", Math.round( _options.wTime ) );
			}
			if ( _options.bTime )
			{
				sb.appendFormat( "btime {0} ", Math.round( _options.bTime ) );
			}
			if ( _options.infinite || ObjUtil.isEmpty( _options ) )
			{
				sb.appendFormat( "infinite " );
			}
	
			//console.log(sb.toString());
			return sb.toString();
		},

		getSetOptionString( _name, _val )
		{
			var str = String.formatEx( "setoption name {0}", _name );
			if ( typeof _val !== "undefined" )
			{
				str += String.formatEx( " value {0}", _val );
			}
	
			//console.log(str);
			return str;
		}
	};
};