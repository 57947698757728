import React from 'react';
import styles from "./CBNotationBar.module.css";

import { CBMaterialBalance } from 'CBReact/Components/Chess/MaterialBalance/CBMaterialBalance';

import { checkPropsAndRenderComponent } from "CBReact/Utils/DebugUtils"
import { CBNotationTools } from '../NotationTools/CBNotationTools';


export function CBNotationBar (props) {


    function renderCBNotationBar ()
    {
        return (
            <div className={styles.container}>
                <CBMaterialBalance boardPosition={props.boardPosition} />
                <CBNotationTools gameKernel={props.gameKernel} />
            </div>
        );
    }

    return checkPropsAndRenderComponent([props.gameKernel, props.boardPosition], renderCBNotationBar);

}