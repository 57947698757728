// "use strict";

import { Title } from "common/Chess/Tools/CBTitle";

export class HighPlayerData {

   // constructor () {
   //  };

   readFromDataBuffer (pBuf) {
      this.setDeathDate(pBuf.readCBDate());
      this.setCurrentTitle(new Title(pBuf.readUint16()));
      this.setNation( pBuf.readUint16() );
      this.setBornNation( pBuf.readUint16() );
      this.setIsMale(pBuf.readBool());
   };

   setDeathDate (date) {
      this.m_aDeathDate = date;
   };

   getDeathDate () {
      return this.m_aDeathDate;
   };


   getCurrentTitle () {
      return this.m_aTitle;
   };

   setCurrentTitle (rT) {
      this.m_aTitle = rT;
   };

   getNation () {
      return this.m_aNation;
   };

   setNation (rN) {
      this.m_aNation = rN;
   };

   getBornNation () {
      return this.m_aBornNation;
   };

   setBornNation (rN) {
      this.m_aBornNation = rN;
   };

   getIsMale () {
      return this.m_isMale;
   };

   setIsMale (bMale) {
      this.m_isMale = bMale;
   };

}



