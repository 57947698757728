// mw 7.1.2014
// ES6 3.3.2020

import { ObjUtil } from 'common/Patterns/ObjectUtil';
import { Piece } from 'common/Chess/Logic/Chess';
import { InvariantLocalization } from "common/Chess/Logic/Notation";
// import { Log } from 'common/Tools/Log';


export var glLoc;		// global localization object
export var lm;			// get strings with lm.IDENTIFIER;

export class Localization
{
	constructor( locObjs )
	{
		glLoc = this;

		this.locObjects = [].concat( locObjs );
		lm = this.locObjects[ 0 ];

		for ( var i = 1; i < this.locObjects.length; i++ )
		{
			var o = this.locObjects[ i ];

			for ( var m in o )
			{
				if ( o.hasOwnProperty( m ) )
					lm[ m ] = o[ m ];
			}
		}

		var NotaLoc = ObjUtil.clone( InvariantLocalization );

		NotaLoc.arrStrPieces[ Piece.W_KING ] =
			NotaLoc.arrStrPieces[ Piece.B_KING ] = lm.NOTATION_KING;

		NotaLoc.arrStrPieces[ Piece.W_QUEEN ] =
			NotaLoc.arrStrPieces[ Piece.B_QUEEN ] = lm.NOTATION_QUEEN;

		NotaLoc.arrStrPieces[ Piece.W_ROOK ] =
			NotaLoc.arrStrPieces[ Piece.B_ROOK ] = lm.NOTATION_ROOK;

		NotaLoc.arrStrPieces[ Piece.W_BISHOP ] =
			NotaLoc.arrStrPieces[ Piece.B_BISHOP ] = lm.NOTATION_BISHOP;

		NotaLoc.arrStrPieces[ Piece.W_KNIGHT ] =
			NotaLoc.arrStrPieces[ Piece.B_KNIGHT ] = lm.NOTATION_KNIGHT;

		NotaLoc.arrStrPieces[ Piece.W_PAWN ] =
			NotaLoc.arrStrPieces[ Piece.B_PAWN ] = "";

		lm.NOTA_LOCALIZATION = NotaLoc;
	};
}

export var Loc =
{
	Chess:
	{
		
	},

	MyMoves:
	{

	}
}
