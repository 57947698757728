
import { Game } from "common/Chess/Logic/Game";
import { NotationGenerator } from "common/Chess/Logic/Notation";


export class EngineModuleUtils
{
    static getEngineLineString (_sender, _pv, _eval, _mate, _notaLocalization)
    {
            var position = _sender.getCurPos();
            var notationGenerator = new NotationGenerator(_notaLocalization);
    
            Game.initLine( position, _pv );
            var lineString = notationGenerator.getLineNota( position, _pv );
    
            var evaluation = _eval;
    
            if ( _mate )
            {
                if ( evaluation < 0 )
                    evaluation = -evaluation;
                evaluation = notationGenerator.getLocalization().strMate + evaluation;
            } 
            else
            {
                if ( !position.isWTM() ) evaluation = -evaluation;
                evaluation /= 100;
                evaluation = evaluation.toFixed( 2 );
            }
            
            lineString = "(" + evaluation + ") " + lineString;
            return lineString;
    }
}