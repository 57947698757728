// mw 6.2.2013
// ES 6 MW: Feb 27, 2020

import { Side, Piece, Square } from 'common/Chess/Logic/Chess'

export class Board
{
	constructor( bSetup ) 
	{
		this.sqs = [];
		for ( var s = 0; s < 64; s++ )
			this.sqs.push( 0 );
		if ( bSetup )
			this.setup();
	};

	static SIZE = 64;

	setup()
	{
		this.sqs = [ Piece.W_ROOK, Piece.W_PAWN, 0, 0, 0, 0, Piece.B_PAWN, Piece.B_ROOK,
		Piece.W_KNIGHT, Piece.W_PAWN, 0, 0, 0, 0, Piece.B_PAWN, Piece.B_KNIGHT,
		Piece.W_BISHOP, Piece.W_PAWN, 0, 0, 0, 0, Piece.B_PAWN, Piece.B_BISHOP,
		Piece.W_QUEEN, Piece.W_PAWN, 0, 0, 0, 0, Piece.B_PAWN, Piece.B_QUEEN,
		Piece.W_KING, Piece.W_PAWN, 0, 0, 0, 0, Piece.B_PAWN, Piece.B_KING,
		Piece.W_BISHOP, Piece.W_PAWN, 0, 0, 0, 0, Piece.B_PAWN, Piece.B_BISHOP,
		Piece.W_KNIGHT, Piece.W_PAWN, 0, 0, 0, 0, Piece.B_PAWN, Piece.B_KNIGHT,
		Piece.W_ROOK, Piece.W_PAWN, 0, 0, 0, 0, Piece.B_PAWN, Piece.B_ROOK ];
	};

	clear()
	{
		this.sqs.forEach(
			function( _, i, arr )
			{
				arr[ i ] = 0;
			}
		);
	};

	fromPosStr( str )
	{
		var currColour = Side.WHITE, currPiece = Piece.KING;

		for ( var i = 0; i < str.length; i++ )
		{
			switch ( str[ i ] )
			{
				case 'K':
					currPiece = Piece.KING;
					break;
				case 'Q':
					currPiece = Piece.QUEEN;
					break;
				case 'R':
					currPiece = Piece.ROOK;
					break;
				case 'B':
					currPiece = Piece.BISHOP;
					break;
				case 'N':
					currPiece = Piece.KNIGHT;
					break;
				case 'P':
					currPiece = Piece.PAWN;
					break;
				case 'a':
				case 'b':
				case 'c':
				case 'd':
				case 'e':
				case 'f':
				case 'g':
				case 'h':
					var nThis = str.charCodeAt( i );
					var nNext = str.charCodeAt( i + 1 );
					if ( ( nNext >= 49 ) && ( nNext <= 56 ) )
						this.sqs[ ( ( nThis - 97 ) << 3 ) + nNext - 49 ] = ( currPiece | currColour );
					else
						if ( nThis === 98 )
							currColour = Side.SIDE_MASK;

					break;
				case 'w':
					currColour = Side.WHITE;
					break;
				default:
					break;
			}
		}
	};

	copyFrom( arr )
	{
		for ( var i = 0; i < arr.length; i++ )
			this.sqs[ i ] = arr[ i ];
	};

	copyTo( arr )
	{
		for ( var i = 0; i < 64; i++ )
			arr[ i ] = this.sqs[ i ];
	};

	set( n64, piece )
	{
		this.sqs[ n64 ] = piece;
	};

	get( n64 )
	{
		return this.sqs[ n64 ];
	};

	change( from, to )
	{
		this.sqs[ to ] = this.sqs[ from ];
		this.sqs[ from ] = 0;
	};

	promo( from, to, prom )
	{
		this.sqs[ to ] = prom;
		this.sqs[ from ] = 0;
	};

	enPassant( from, to, ep )
	{
		this.sqs[ to ] = this.sqs[ from ];
		this.sqs[ from ] = 0;
		this.sqs[ ep ] = 0;
	};

	writeToDataBuffer = function( rBuf )
	{
		for ( var i = 0; i < 64; i++ )
			rBuf.writeByte( this.sqs[ i ] );
	}

	toDebugStrs()
	{
		var strs = [];
		for ( var rank = 7; rank >= 0; rank-- )
		{
			var strRank = "";
			for ( var file = 0; file < 8; file++ )
			{
				var p = this.get( file * 8 + rank );
				if ( p )
					strRank += Piece.g_arrPieces[ p ];
				else
				{
					if ( Square.isDark( file * 8 + rank ) )
						strRank += ".";
					else
						strRank += " ";
				}
			}
			strs.push( strRank );
		}
		return strs;
	};

	/*static*/
	findSamePieceClosestSquare( piece, sq, refBoard, setExclude )
	{
		// primitive default implementation, ignore 'closest':
		for ( var i = 0; i < 64; i++ )
		{
			if ( setExclude === undefined || !setExclude[ i ] )
			{
				if ( refBoard[ i ] === piece )
				{
					return i;
				}
			}
		}
		return -1;
	};

	//////////////////////////////////////////
	test()
	{
		var board = new Board();
		board.setup();
		board.toDebugStrs().forEach( function( sLine ) 
		{
			console.log( sLine );
		} );
	};
}

