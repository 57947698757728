// ST 2011

// ES 6 MW: Feb 27, 2020

export class StringBuilder
{
	//[Zeile, [Zeile, [Zeile, [Zeile]]]]

	constructor ()
	{
		this.sp = null;
		this.ep = null;
		this.l = 0;
		this.accum = '';
		this.MAX_LEN = 2800;
	}

// "#IFDEBUG"
// 	this.debugStr = '';
// "#ENDIF"

	

	appendFormat (  )
	{
		var str = String.formatEx.apply( "", arguments );
		this.append( str );
	};

	append ( what )
	{
		// "#IFDEBUG"
		// this.debugStr += what;
		// "#ENDIF"

		this.accum += what;
		if ( this.accum.length > this.MAX_LEN )
		{
			if ( this.sp === null )
			{
				this.ep = [];
				this.sp = this.ep;
			}
			else
			{
				var oep = this.ep;
				this.ep = [];
				oep[1] = this.ep;
			}
			this.ep[0] = this.accum;
			this.accum = '';
			++this.l;
		}
	}

	toString ()
	{
		if ( this.l === 0 ) 
		return this.accum;

		while ( this.l > 1 )
		{
			var ptr = this.sp;
			var nsp = [];
			var nep = nsp;
			var nl = 0;

			while ( typeof ( ptr ) != 'undefined' )
			{
				if ( typeof ( nep[0] ) == 'undefined' )
				{
					nep[0] = ptr[0];
					++nl;
				}
				else
				{
					if ( typeof ( ptr[0] ) != 'undefined' ) nep[0] += ptr[0];
					nep[1] = [];
					nep = nep[1];
				}
				ptr = ptr[1];
			}
			this.sp = nsp;
			this.ep = nep;
			this.l = nl;
		}
		return this.sp[0] + this.accum;
	};
}