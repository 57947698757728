

import { ObjUtil } from 'common/Patterns/ObjectUtil';
import { Piece } from 'common/Chess/Logic/Chess';
import { Language } from 'common/Tools/Language';
import { CookieUtils } from "CBReact/Utils/CookieUtils";

import getChessLocEng from "CBReact/Resources/Localization/en/Chess.en";
import getMyMovesLocEng from "CBReact/Resources/Localization/en/Chess.en";

export var InvariantLocalization = {
	arrStrPieces: [],

	strTakes: "x",
	strCheck: "+",
	strMate: "#",
	strProm: "",
	str00: "0-0",
	str000: "0-0-0",
	strNullMove: "--",
	bOmitPawnTakes: false
};

InvariantLocalization.arrStrPieces[Piece.W_KING] =
	InvariantLocalization.arrStrPieces[Piece.B_KING] = "K";

InvariantLocalization.arrStrPieces[Piece.W_QUEEN] =
	InvariantLocalization.arrStrPieces[Piece.B_QUEEN] = "Q";

InvariantLocalization.arrStrPieces[Piece.W_ROOK] =
	InvariantLocalization.arrStrPieces[Piece.B_ROOK] = "R";

InvariantLocalization.arrStrPieces[Piece.W_BISHOP] =
	InvariantLocalization.arrStrPieces[Piece.B_BISHOP] = "B";

InvariantLocalization.arrStrPieces[Piece.W_KNIGHT] =
	InvariantLocalization.arrStrPieces[Piece.B_KNIGHT] = "N";

InvariantLocalization.arrStrPieces[Piece.W_PAWN] =
	InvariantLocalization.arrStrPieces[Piece.B_PAWN] = "";

// const filePaths = ["Chess", "MyMoves", "openings", "Playchess"];
const filePaths = ["Chess", "MyMoves"];

export class LocalizationUtils 
{
	static InitLocalization() 
	{
		var newLocalization = {};
		newLocalization.NOTA_LOCALIZATION = ObjUtil.clone(InvariantLocalization);

		let chessLocEng = getChessLocEng();
		let myMovesLocEng = getMyMovesLocEng();

		ObjUtil.mixObject(newLocalization, chessLocEng);
		ObjUtil.mixObject(newLocalization, myMovesLocEng);

		return {language: Language.ENG, strings: newLocalization};
	}

	static SetLanguage(newLanguage, currentLocalization, setLocalization) 
	{
		var newLocalization = {};
		Object.assign(newLocalization, currentLocalization);

		var folderName = LocalizationUtils.GetLanguageFolderName(newLanguage);

		var nImportCallbacks = 0;
		for (var i = 0; i < filePaths.length; i++)
		{
			import("CBReact/Resources/Localization/" + folderName + "/" + filePaths[i] + "." + folderName + ".js") // "CBReact/Utils/Localization/en/Chess.en.js"
			.then(localizationObj => {
				let localizationPart = localizationObj.default();
				ObjUtil.mixObject(newLocalization, localizationPart);

				if (localizationPart.NOTATION_KING && localizationPart.NOTATION_QUEEN && localizationPart.NOTATION_ROOK && localizationPart.NOTATION_BISHOP && localizationPart.NOTATION_KNIGHT)
					newLocalization.NOTA_LOCALIZATION = LocalizationUtils.GetNotationLocalization(localizationPart);
				
				nImportCallbacks++;
				if (nImportCallbacks === filePaths.length)
					setLocalization({language: newLanguage, strings: newLocalization});
			})
			.catch(error => { 
				nImportCallbacks++;
				if (nImportCallbacks === filePaths.length)
					setLocalization({language: newLanguage, strings: newLocalization});

				// console.log("Loading Error: " + error + ", " + nImportCallbacks);
			});
		}

		// CookieUtils.SetCookie("CBLoc", newLanguage);
		CookieUtils.SetStorageItem("CBLoc", newLanguage);
	};

	static GetNotationLocalization (localizationPart)
	{
		let notaLoc = ObjUtil.clone(InvariantLocalization);

		notaLoc.arrStrPieces[Piece.W_KING] =
		notaLoc.arrStrPieces[Piece.B_KING] = localizationPart.NOTATION_KING;

		notaLoc.arrStrPieces[Piece.W_QUEEN] =
		notaLoc.arrStrPieces[Piece.B_QUEEN] = localizationPart.NOTATION_QUEEN;

		notaLoc.arrStrPieces[Piece.W_ROOK] =
		notaLoc.arrStrPieces[Piece.B_ROOK] = localizationPart.NOTATION_ROOK;

		notaLoc.arrStrPieces[Piece.W_BISHOP] =
		notaLoc.arrStrPieces[Piece.B_BISHOP] = localizationPart.NOTATION_BISHOP;

		notaLoc.arrStrPieces[Piece.W_KNIGHT] =
		notaLoc.arrStrPieces[Piece.B_KNIGHT] = localizationPart.NOTATION_KNIGHT;

		notaLoc.arrStrPieces[Piece.W_PAWN] =
		notaLoc.arrStrPieces[Piece.B_PAWN] = "";

		return notaLoc;
	}

	static GetAvailableLanguages() 
	{
		return [
			Language.ENG,
			Language.DEU,
			Language.ESP,
			Language.NED,
			Language.ROM,
			Language.CHN,
			Language.FRA,
			Language.RUS,
			Language.NOR,
			Language.POL,
			Language.ITA,
			Language.HEB,
			Language.CAT,
			Language.TRK,
			Language.SLO,
			Language.GRK,
			Language.UKR,
			Language.VIE,
			Language.ARA,
			Language.SER,
			Language.LIT,
			Language.BRA,
			Language.FAR,
			Language.GAL,
			Language.CRO,
			Language.CZE,
			Language.HIN,
		];
	};

	static GetLanguageFolderName (language) 
	{
		switch (language) {
			case Language.ENG:
				return "en";
			case Language.DEU:
				return "de";
			case Language.ESP:
				return "es";
			case Language.NED:
				return "nl";
			case Language.ROM:
				return "ro";
			case Language.CHN:
				return "zh";
			case Language.FRA:
				return "fr";
			case Language.RUS:
				return "ru";
			case Language.NOR:
				return "no";
			case Language.POL:
				return "pl";
			case Language.ITA:
				return "it";
			case Language.HEB:
				return "he";
			case Language.CAT:
				return "cat";
			case Language.TRK:
				return "tr";
			case Language.SLO:
				return "slo";
			case Language.GRK:
				return "el";
			case Language.UKR:
				return "uk";
			case Language.VIE:
				return "vi";
			case Language.ARA:
				return "ar";
			case Language.SER:
				return "sr";
			case Language.LIT:
				return "lt";
			case Language.BRA:
				return "br";
			case Language.FAR:
				return "fa";
			case Language.GAL:
				return "ga";
			case Language.CRO:
				return "hr";
			case Language.CZE:
				return "cs";
			case Language.HIN:
				return "hi";
			default:
				return "en";
		}
	}
}