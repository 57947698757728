import React, { useEffect, useRef } from 'react';

import { CBRibbon } from "CBReact/Components/DesignElements/Ribbon/CBRibbon"
import { CBButton } from "CBReact/Components/DesignElements/Buttons/Button/CBButton"

import { checkPropsAndRenderComponent } from 'CBReact/Utils/DebugUtils';
import { CBMResetIcon, CBMTurnBoardIcon, CBMNextIcon, CBMPreviousIcon, CBMToEndIcon, CBMToStartIcon } from 'CBReact/Components/DesignElements/Icons/CBIcons';

export function CBBoardNavigationBar (props)
{

    const keyDownInterval = useRef();

    useEffect(() => {
        window.onkeydown = checkKeyDown;
        window.onkeyup = checkKeyUp;
    }, []);

    function checkKeyDown (e)
    {
        // e.preventDefault();
        // e.stopPropagation();
        if (!keyDownInterval.current)
            moveOnKeyDown(e, true);
    }

    function moveOnKeyDown (e, isFirst)
    {
        if (e.key === 'ArrowLeft')
            moveBack(e);
        else if (e.key === 'ArrowRight')
            moveForward(e);

        keyDownInterval.current = setTimeout(() => {
            moveOnKeyDown(e, false);
        }, isFirst ? 350 : 60);
    }

    function checkKeyUp (e)
    {
        // e.preventDefault();
        // e.stopPropagation();
        if ((e.key === 'ArrowLeft' || e.key === 'ArrowRight') && keyDownInterval.current)
        {
            clearTimeout(keyDownInterval.current);
            keyDownInterval.current = null;
        }
    }

    function newGame (e)
    {
        e.preventDefault();
        props.gameKernel.newGame();
    }

    function restartGame (e)
    {
        e.preventDefault();

        if (props.gameKernel.playingMode.mayNavigate())
            props.gameKernel.game.gotoStart();
    }

    function moveBack (e)
    {
        e.preventDefault();

        if (props.gameKernel.playingMode.mayNavigate())
            props.gameKernel.game.gotoPrev();
    }

    function moveForward (e)
    {
        e.preventDefault();

        if (props.gameKernel.playingMode.mayNavigate())
            props.gameKernel.game.gotoNext();
    }

    function goToGameEnd (e)
    {
        e.preventDefault();

        if (props.gameKernel.playingMode.mayNavigate())
            props.gameKernel.game.gotoEnd();
    }

    function turnBoard (e)
    {
        e.preventDefault();

        props.setWhiteIsBottom(!props.whiteIsBottom);

        // if (props.gameKernel.boardWin)
        //     props.gameKernel.boardWin.flipBoard();
    }

    function renderCBBoardNavigationBar ()
    {
        return(
            <CBRibbon>
                <CBButton borderRight={true} onClick={newGame}> <CBMResetIcon height={"60%"}/> </CBButton>
                <CBButton borderRight={true} onClick={restartGame}> <CBMToStartIcon height={"38%"} /> </CBButton>
                <CBButton style={{flexGrow: "1.5"}} borderRight={true} onClick={moveBack}> <CBMPreviousIcon height={"40%"}/> </CBButton>
                <CBButton style={{flexGrow: "1.5"}} borderRight={true} onClick={moveForward}> <CBMNextIcon height={"40%"}/> </CBButton>
                <CBButton borderRight={true} onClick={goToGameEnd}> <CBMToEndIcon height={"38%"} /> </CBButton>
                <CBButton onClick={turnBoard}> <CBMTurnBoardIcon height={"60%"}/> </CBButton>
            </CBRibbon>
        )
    }

    return checkPropsAndRenderComponent([props.gameKernel, props.whiteIsBottom, props.setWhiteIsBottom], renderCBBoardNavigationBar);
}