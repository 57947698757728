// mw 17.1.2014

// "use strict";

import { Storage } from "common/Tools/Storage";
import { Position } from "common/Chess/Logic/Position"
import { ObjUtil } from "common/Patterns/ObjectUtil";
import { Set } from "common/Container/Set"

export class PositionTracker
{
	constructor ()
	{
		this.set = new Set();

		this.trackCnt = 0;
		this.enabled = true;
	};

	save ()
	{
		var store = new Storage();
		store.save( "posns_trackCnt", this.trackCnt );
		this.set.save( "posns",
			function ( pos )
			{
				// do not store more than 200 positions:
				return this.trackCnt - pos.lastSeenAt < 200;
			}.bind( this )
		);
	};

	load ()
	{
		var store = new Storage();
		this.trackCnt = store.load( "posns_trackCnt" ) || 0;

		this.set.load( "posns",
			function ( json )
			{
				if ( json )
				{
					var pos = new Position();
					pos.copy( json.pos );
					var p = new TrackedPos( pos );
					p.cnt = json.cnt;
					p.lastSeenAt = json.lastSeenAt;
					return p;
				}
			} );
	};

	track ( pos )
	{
		if ( this.enabled )
		{
			this.trackCnt++;
			if ( this.set.contains( new TrackedPos( pos ) ) )
			{
			//	CB.LOG( "Position Exists", "LogGreen" );
				var tracked = this.set.get( new TrackedPos( pos ) );
				tracked.inc();
				tracked.lastSeenAt = this.trackCnt;
			//	CB.LOG( "Count = " + tracked.cnt );
				return true;
			}
			else
			{
			//	CB.LOG( "NEW POSITION", "LogRed" );
				var trackedPos = new TrackedPos( pos );
				trackedPos.lastSeenAt = this.trackCnt;
				this.set.insert( trackedPos );
				//	trackedPos.pos.dump();
			}
		}
		return false;
	};

	
	getAge ( pos )
	{
		var trackedPos = this.set.get( new TrackedPos( pos ) );
		if ( trackedPos !== undefined )
		{
			if ( trackedPos.cnt == 0 )
				return -1;
			else
				return this.trackCnt - trackedPos.lastSeenAt;
		}
		return -1;
	};

}

//////////////////////////////////////////////////////////////////////////////////

export class TrackedPos
{

	constructor ( pos )
	{
		this.pos = ObjUtil.clone( pos );
		this.cnt = 0;
		this.lastSeenAt = 0;
	};

	setEquals ( other )
	{
		return this.pos.setEquals( other.pos );
	};

	setGreater ( other )
	{
		return this.pos.setGreater( other.pos );
	};

	inc ()
	{
		this.cnt++;
	};

}
