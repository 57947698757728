

import React, { useContext } from 'react';
import styles from "./CBSearchFilterContainer.module.css";

import { CBMButtonBadge } from "CBReact/Components/DesignElements/Buttons/ButtonBadge/CBButtonBadge"
import { checkPropsAndRenderComponent } from 'CBReact/Utils/DebugUtils';


export function CBSearchFilterContainer (props)
{

    function renderFilter (filterEntry, index)
    {
        if (filterEntry.text)
            return <CBMButtonBadge key={"filter" + index} size="big" renderClose={true} onClick={props.removeFilter} index={index}>{filterEntry.text}</CBMButtonBadge>
        else
            return null;
    }

    function renderQuickSearchFilter (filterEntry, index)
    {
        return <CBMButtonBadge key={"qsfilter" + index} size="big" renderClose={true} onClick={props.removeQuickSearchFilter} index={index}>{filterEntry}</CBMButtonBadge>
    }

    function renderCBSearchFilterContainer ()
    {
        return (
            <div className={`${styles.filter}`}>
                {props.filterList.map(renderFilter)}
                {props.quickSearchFilterList.map(renderQuickSearchFilter)}
            </div>
        );
    }

    return checkPropsAndRenderComponent([props.removeFilter, props.filterList, props.removeQuickSearchFilter, props.quickSearchFilterList], renderCBSearchFilterContainer);
}