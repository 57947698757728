

import React from 'react';
import styles from "./CBLoadingCircle.module.css";

// Loading Circle with absolute position. Parent must be relative.
// Size and position can be set by parent by passing the "style" prop. For example:
// style={{width: "60px", height: "60px", left: "calc(50% - 30px)", top: "15%"}}
export function CBLoadingCircle (props)
{
    return (
        <div className={styles.background}>
            <div className={styles.loader} style={props.style}></div>
        </div>
    );
}