// ES 6 MW: Feb 27, 2020

import 
{
	AnnoType, AnnoRec, AnnoFactory,
	TextAnno, SymbolsAnno

} from 'common/Chess/Format/AnnoTypes'

export class Annotation 
{
	static DIAG_CHAR = '[#]';

	toString()
	{
		var strRet = "";
		for ( var i in this )
		{
			if ( this.hasOwnProperty( i ) )
				strRet += this[ i ].toString();
		}
		return strRet;
	};

	_read( _buf, _cntAnnos )
	{
		for ( var inx = 0; inx < _cntAnnos; ++inx )
		{
			/*var annoLen = */_buf.readShort();

			var hdr = AnnoRec.readHeader( _buf );

			// CBDebug.assert( hdr.len === annoLen, "LEN OK" );

			hdr.len -= AnnoRec.ANNO_HEAD_SIZE;

			var anno = AnnoFactory.factory( hdr.type );

			if ( !anno )
			{
				_buf.skip( hdr.len );
				continue;
			}

			anno.read( hdr.len, _buf );

			this.setItem( hdr.type, anno );
		}
		return this;
	};

	_read2( _buf, _cntAnnos )
	{
		this._read( _buf, _cntAnnos );
		return this;
	};

	write( buf )
	{
		/// <param name="_buf" type="DataBuffer">BUFFER.</param>
		var cnt = 0;
		for ( var i in this )
		{
			if ( this.hasOwnProperty( i ) )
			{
				cnt++;
			}
		}

		buf.writeShort( cnt );

		for ( let i in this )
		{
			if ( this.hasOwnProperty( i ) )
			{
				this[ i ].write( buf, i );
			}
		}
	};

	write2( buf )
	{
		var annoTypes = [];
		for ( var i in this )
		{
			if ( this.hasOwnProperty( i ) )
			{
				annoTypes.push( i );
			}
		}
		var lengthPos = buf.getPos();
		buf.writeByte( 0 /*annoTypes.length*/ );	// maybe we cannot write every type. e.g. FritzEval
		var cnt = 0;
		for ( let i = 0; i < annoTypes.length; i++ )
		{
			if ( this[ annoTypes[ i ] ].write )
			{
				this[ annoTypes[ i ] ].write( buf, annoTypes[ i ] );
				cnt++;
			}
			else
			{
				// "#IFDEBUG"
				// 	Log.Log( "Cannot write annotation, sorry: type=" + annoTypes[i], "LogError", "game" );
				// "#ENDIF"
				//	throw ( "Not savEvad" );
			}
		}
		var pos = buf.getPos();
		buf.setPos( lengthPos );
		buf.writeByte( cnt );
		buf.setPos( pos );
	};

	static write( _buf, _annos )
	{
		/// <param name="_buf" type="DataBuffer">BUFFER.</param>
		/// <param name="_annos" type="Annotation">Annotations.</param>
		if ( !_annos )
			_buf.writeShort( 0 );
		else
			_annos.write( _buf );
	};

	static write2( _buf, _annos )
	{
		if ( !_annos )
			_buf.writeByte( 0 );
		else
			_annos.write2( _buf );
	};

	read( _buf )
	{
		/// <param name="_buf" type="DataBuffer">BUFFER.</param>
		var cntAnnos = _buf.readShort();
		this._read( _buf, cntAnnos );
	};

	read2( _buf )
	{
		/// <param name="_buf" type="DataBuffer">BUFFER.</param>
		var cntAnnos = _buf.readByte();
		if ( cntAnnos )
			this._read2( _buf, cntAnnos );
	};

	fromDataBuf = this.read;
	toDataBuf = this.write;

	static readFactory( _buf )
	{
		/// <param name="_buf" type="DataBuffer">BUFFER.</param>
		var cntAnnos = _buf.readShort();
		if ( !cntAnnos )
			return null;
		var anno = new Annotation();
		return anno._read( _buf, cntAnnos );
	};

	static readFactory2( _buf )
	{
		/// <param name="_buf" type="DataBuffer">BUFFER.</param>
		var cntAnnos = _buf.readUint8();
		if ( !cntAnnos )
			return null;
		var anno = new Annotation();
		return anno._read2( _buf, cntAnnos );
	};


	empty()
	{
		for ( var t in this )
		{
			if ( this.hasOwnProperty( t ) )
				return false;
		}
		return true;
	};

	count()
	{
		var cnt = 0;
		for ( var t in this )
		{
			if ( this.hasOwnProperty( t ) )
				cnt++;
		}
		return cnt;
	};

	setItem( _type, _anno )
	{
		this[ _type ] = _anno;
	};

	hasItem( _type )
	{
		return !!this[ _type ];
	};

	getItem( _type )
	{
		return this[ _type ];
	};

	deleteItem( type )
	{
		delete this[ type ];
	};

	addPreText( text )
	{
		// add not implemented yet!
		this[ AnnoType.PRETEXT ] = new TextAnno( text );
	};

	addPostText( text )
	{
		// add not implemented yet!
		if ( this[ AnnoType.POSTTEXT ] && this[ AnnoType.POSTTEXT ].m_str.length )
			this[ AnnoType.POSTTEXT ].addText( " " + text );
		else
			this[ AnnoType.POSTTEXT ] = new TextAnno( text );
	};

	hasDiagram()
	{
		if ( this[ AnnoType.POSTTEXT ] && this[ AnnoType.POSTTEXT ].m_str.length )
		{
			return this[ AnnoType.POSTTEXT ].m_str.search( "[#]" ) !== -1;
		}
	};

	toggleDiagram = function()
	{
		if ( this[ AnnoType.POSTTEXT ] && this[ AnnoType.POSTTEXT ].m_str.length )
		{
			if ( this[ AnnoType.POSTTEXT ].m_str.search( "[#]" ) !== -1 )
			{
				this[ AnnoType.POSTTEXT ].m_str = this[ AnnoType.POSTTEXT ].m_str.replace( "[#]", "" );
				if ( !this[ AnnoType.POSTTEXT ].m_str.length )
				{
					delete this[ AnnoType.POSTTEXT ];
				}
				return;
			}
			else
				this[ AnnoType.POSTTEXT ].m_str += "[#]";
		}
		else
		{
			this[ AnnoType.POSTTEXT ] = new TextAnno( "[#]" );
		}
	}

	removeDiagram = function()
	{
		if ( this[ AnnoType.POSTTEXT ] && this[ AnnoType.POSTTEXT ].m_str.length )
		{
		}
	}

	hasPreText()
	{
		return this.hasItem( AnnoType.PRETEXT );
	};

	getPreText()
	{
		if ( this.hasPreText() )
		{
			return this[ AnnoType.PRETEXT ].getString();
		}
	};

	getPostText()
	{
		if ( this.hasPostText() )
		{
			return this[ AnnoType.POSTTEXT ].getString();
		}
	};

	hasPostText()
	{
		return this.hasItem( AnnoType.POSTTEXT );
	};

	addSymbol( symbol )
	{
		if ( !this[ AnnoType.SYMBOL ] )
			this[ AnnoType.SYMBOL ] = new SymbolsAnno();
		this[ AnnoType.SYMBOL ].addSymbol( symbol );
	};

	hasSymbol()
	{
		return this[ AnnoType.SYMBOL ] !== undefined;
	};

	getSymbol()
	{
		return this[ AnnoType.SYMBOL ];
	};

	hasColSquares()
	{
		return this.hasItem( AnnoType.SQUARE_LIST );
	};

	hasArrows()
	{
		return this.hasItem( AnnoType.ARROW_LIST );
	};

	hasTraining()
	{
		return this.hasItem( AnnoType.TRAINING );
	};

	getTraining()
	{
		return this[ AnnoType.TRAINING ];
	};

	setTraining( t )
	{
		if ( t )
			this[ AnnoType.TRAINING ] = t;
		else
			delete this[ AnnoType.TRAINING ];
	};
}

