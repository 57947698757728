
import React, { useContext, Fragment, useState } from 'react';
import styles from "./CBSearchBar.module.css";

import { CBRibbon } from "CBReact/Components/DesignElements/Ribbon/CBRibbon";
import { CBMButton } from "CBReact/Components/DesignElements/Buttons/Button/CBButton";
import { checkPropsAndRenderComponent } from 'CBReact/Utils/DebugUtils';
import { CBMSearchIcon } from 'CBReact/Components/DesignElements/Icons/CBIcons';
import { CBMBoardIcon } from 'CBReact/Components/DesignElements/Icons/CBIcons';
import { CBAutoComplete } from "CBReact/Components/DesignElements/Selects/CBAutoComplete"

export function CBSearchBar (props)
{

    const [inputData, setInputData] = useState({requestAutoComplete: false, keepAutoComplete: false, text: ""});

    function setSearchBoard (e)
    {
        e.preventDefault();
        
        setInputData({requestAutoComplete: false, keepAutoComplete: false, text: ""});
        props.searchOptions.current.quickSearch = "";
        props.searchOptions.current.useBoard = true;
        props.handleSubmit();
    }

    function handleInputChange (e)
    {
        setInputData({requestAutoComplete: true, keepAutoComplete: false, text: e.target.value});
        props.searchOptions.current.quickSearch = e.target.value;
    }

    function handleFocus (e)
    {
        e.preventDefault();
        props.setShowPremiumSearch(true);
    }

    function handleKeyDown (e)
    {
        if (e.keyCode === 13)
        {
            e.preventDefault();
            props.searchOptions.current.quickSearch = inputData.text;
            setInputData({requestAutoComplete: false, keepAutoComplete: false, text: ""});
            props.handleSubmit();
        }
    }

    function handleAutoCompleteSelection (autoCompleteText)
    {
        setInputData({requestAutoComplete: false, keepAutoComplete: true, text: autoCompleteText});
    }

    function handleAutocompleteSubmit (autoCompleteText)
    {
        setInputData({requestAutoComplete: false, keepAutoComplete: false, text: ""});
        props.searchOptions.current.quickSearch = autoCompleteText;
        props.handleSubmit();
    }

    function renderCBSearchBar ()
    {
        return (
            <Fragment>
                <CBRibbon>

                    <div className={`${styles.searchFieldContainer}`} style={{flexGrow: "5"}}>
                        <input 
                            type="text" 
                            value={inputData.text} 
                            className={`${styles.searchField}`} 
                            placeholder={"Quick Search..."}
                            onChange={handleInputChange} 
                            onClick={handleFocus}
                            onKeyDown={handleKeyDown}
                            maxLength="40" 
                        />
                    </div>

                    <CBAutoComplete 
                        inputData={inputData}
                        handleSelection={handleAutoCompleteSelection}
                        handleSubmit={handleAutocompleteSubmit}
                        quickSearchIndent={true}/>

                    <CBMButton borderLeft={true} onClick={props.handleSubmit}>
                        <CBMSearchIcon height={"70%"} />
                    </CBMButton>

                    <CBMButton borderLeft={true} onClick={setSearchBoard}>
                        <CBMBoardIcon height={"70%"}/>
                    </CBMButton>

                </CBRibbon>
            </Fragment>

        );
    }

    return checkPropsAndRenderComponent([props.searchOptions, props.handleSubmit, props.setShowPremiumSearch], renderCBSearchBar);
}