
import React, {  useState, Fragment, useEffect, useContext, useCallback } from 'react';
import styles from "./CBOnlineFrame.module.css";

import { CBBoardModule } from "CBReact/Components/Modules/BoardModule/CBBoardModule"
import { CBMEngineModule } from "CBReact/Components/Modules/EngineModule/CBEngineModule"
import { CBMSearchModule } from "CBReact/Components/Modules/SearchModule/CBSearchModule"
import { CBMBookModule } from "CBReact/Components/Modules/BookModule/CBBookModule"
import { CBMGameInfoModule } from 'CBReact/Components/Modules/GameInfoModule/CBGameInfoModule';
import { CBMNavBarTop } from "CBReact/Components/NavBars/CBNavBarTop"
import { CBNavBarBottom } from "CBReact/Components/NavBars/CBNavBarBottom"

import { checkPropsAndRenderComponent } from "CBReact/Utils/DebugUtils"
import { ONLINEDB_LAYOUTS } from "CBReact/Hooks/ReactContext/useOnlineDBLayoutContext";
import { LayoutContext } from 'CBReact/Contexts/CBOnlineDBContext';
import { Position } from 'common/Chess/Logic/Position';


export var ONECOLUMNRENDERMODES = {
    board: 0,
    search: 1,
    book: 2,
}

export function CBOnlineDBFrame (props)
{
    // GameKernel
    const [gameKernel, setGameKernel] = useState(props.gameKernel);

    // Game and GameKernel specific data
    const [notationPath, setNotationPath] = useState(gameKernel.game.getSimplePath());
    const [mainLine, setMainLine] = useState({ mainLine: gameKernel.game.mainLine });
    const [boardPosition, setBoardPosition] = useState(() => {let pos = new Position(); pos.copy02(gameKernel.getCurPos()); return pos;});
    const [startPosition, setStartPosition] = useState(gameKernel.game.getStartPos());
    const [gameHeader, setGameHeader] = useState(gameKernel.game.hdr);
    const [currentGameID, setCurrentGameID] = useState(gameKernel.game.onlineDBID);
    const [oneColumnRenderMode, setOneColumnRenderMode] = useState(ONECOLUMNRENDERMODES.board);

    const appLayout = useContext(LayoutContext).appLayout;

    useEffect(() => {
        if (gameKernel != null) {
            // Für Erweiterung: Siehe NotationElement.setGame()
            gameKernel.game.addOnMoveListener(onGameMove);
            gameKernel.game.addOnNavigateListener(onGameNavigate);
            gameKernel.game.addOnResetGameListener(onGameReset);

            // Initialise Game Notation with current Game
            var newMainLine = { mainLine: gameKernel.game.mainLine };
            setMainLine(newMainLine);
            setNotationPath(gameKernel.game.getSimplePath());
        }

        // TODO: Remove Listeners when component gets unmounted.
        // Es wäre schön, wenn man dafür keinen Index bräuchte...
    }, [gameKernel]);

    function onGameMove() {
        // Creating an Object to make the state immutable... is there a better way?
        setMainLine({ mainLine: gameKernel.game.mainLine });
        setNotationPath(gameKernel.game.getSimplePath());
        setBoardPositions();
    }

    function onGameNavigate () {
        setNotationPath(gameKernel.game.getSimplePath());
        setBoardPositions();
    }

    function onGameReset () {
        // Creating an Object to make the state immutable... is there a better way?
        setMainLine({ mainLine: gameKernel.game.mainLine });
        setNotationPath(gameKernel.game.getSimplePath());
        setGameHeader(gameKernel.game.hdr);
        setCurrentGameID(gameKernel.game.onlineDBID);
        setBoardPositions();
    }

    const changeGamePosition = useCallback((lineStackPath) => {
        gameKernel.game.goToSimplePathGenerateAnimationData(lineStackPath);
        // gameKernel.game.gotoSimplePath(lineStackPath);
        setNotationPath(lineStackPath);
    }, []);

    const changeOneColumnRenderMode = useCallback((newRenderMode) => {
        setOneColumnRenderMode(newRenderMode);
    }, []);

    function setBoardPositions ()
    {
        var position = new Position();
        position.copy02(gameKernel.getCurPos());
        setBoardPosition(position);

        position = new Position();
        position.copy02(gameKernel.game.getStartPos());
        setStartPosition(position);
    }

    function getOneColumnRender ()
    {
        switch(oneColumnRenderMode)
        {
            case ONECOLUMNRENDERMODES.board:
                return (
                    <div className={`${styles.center} ${styles.margins}`}>
                        <CBBoardModule gameKernel={gameKernel} 
                                        gameHeader={gameHeader} 
                                        mainLine={mainLine.mainLine} 
                                        notationPath={notationPath} 
                                        boardPosition={boardPosition} 
                                        changeGamePosition={changeGamePosition} 
                                        fillRemainingHeight={109}
                                        style={{marginBottom: "38px"}}/> 
                                        {/* TODO: Create adjusting laylout for small mobile heights (instead of margin:bottom 38) */}
                    </div>
                )
            case ONECOLUMNRENDERMODES.search:
                return (
                    <div className={`${styles.center} ${styles.margins}`}>
                        <CBMGameInfoModule gameHeader={gameHeader}/>
                        <CBMSearchModule gameKernel={gameKernel} fillRemainingHeight={46} selectedGame={currentGameID}/>
                    </div>
                )
            case ONECOLUMNRENDERMODES.book:
                return (
                    <div className={`${styles.center} ${styles.margins}`}>
                        <CBMEngineModule gameKernel={gameKernel} boardPosition={boardPosition} startPosition={startPosition}/>
                        <CBMBookModule gameKernel={gameKernel} boardPosition={boardPosition} startPosition={startPosition} fillRemainingHeight={46}/>
                    </div>
                )
            default:
                return (
                    <div className={`${styles.center} ${styles.margins}`}>
                        <CBBoardModule gameKernel={gameKernel} 
                                        gameHeader={gameHeader} 
                                        mainLine={mainLine.mainLine} 
                                        notationPath={notationPath} 
                                        boardPosition={boardPosition} 
                                        changeGamePosition={changeGamePosition} 
                                        fillRemainingHeight={109}
                                        style={{marginBottom: "38px"}}/>
                    </div>
                )
        }
    }

    function renderOneColumn ()
    {
        return (     
            <Fragment>
                <div className={`${styles.moduleContainer} ${styles.baseMarginsOneColumn}`}>
                    {getOneColumnRender()}
                </div>
                <CBNavBarBottom changeOneColumnRenderMode={changeOneColumnRenderMode}/>
            </Fragment>
        );
    }

    function renderTwoColumns ()
    {
        return (
            <Fragment>
                <CBMNavBarTop />
                <div className={`${styles.moduleContainer}  ${styles.baseMarginsTwoColumns}`} >
                    <div className={`${styles.left} ${styles.margins}`}>
                        <CBBoardModule gameKernel={gameKernel} gameHeader={gameHeader} mainLine={mainLine.mainLine} notationPath={notationPath} boardPosition={boardPosition} changeGamePosition={changeGamePosition} fillRemainingHeight={78}/>
                    </div>
                    <div className={`${styles.center} ${styles.margins}`}>
                        <CBMGameInfoModule gameHeader={gameHeader}/>
                        <CBMSearchModule gameKernel={gameKernel} fillRemainingHeight={false} tableMaxHeight={"180px"} selectedGame={currentGameID}/>
                        <CBMBookModule gameKernel={gameKernel} boardPosition={boardPosition} startPosition={startPosition} tableMaxHeight={"80px"}/>
                        <CBMEngineModule gameKernel={gameKernel} boardPosition={boardPosition} startPosition={startPosition}/>
                    </div>
                </div>
            </Fragment>
        )
    }

    function renderThreeColumns ()
    {
        return (
            <Fragment>
                <CBMNavBarTop />
                <div className={`${styles.moduleContainer}  ${styles.baseMarginsThreeColumns}`} >
                    <div className={`${styles.left} ${styles.margins}`}>
                        <CBBoardModule gameKernel={gameKernel} gameHeader={gameHeader} mainLine={mainLine.mainLine} notationPath={notationPath} boardPosition={boardPosition} changeGamePosition={changeGamePosition} fillRemainingHeight={78}/>
                    </div>
                    <div className={`${styles.center} ${styles.margins}`}>
                        <CBMGameInfoModule gameHeader={gameHeader}/>
                        <CBMSearchModule gameKernel={gameKernel} fillRemainingHeight={15} selectedGame={currentGameID}/>
                    </div>
                    <div className={`${styles.right} ${styles.margins}`}>
                        <CBMBookModule gameKernel={gameKernel} boardPosition={boardPosition} startPosition={startPosition} tableFixedHeight={"250px"}/>
                        <CBMEngineModule gameKernel={gameKernel} boardPosition={boardPosition} startPosition={startPosition}/>
                    </div>
                </div>
            </Fragment>
        )
    }

    function renderCBOnlineFrame ()
    {
        switch (appLayout)
        {
            case ONLINEDB_LAYOUTS.oneColumn:
                return renderOneColumn();
            case ONLINEDB_LAYOUTS.twoColumns:
                return renderTwoColumns();
            case ONLINEDB_LAYOUTS.threeColumns:
                return renderThreeColumns();
            default: 
                return renderOneColumn();
        }
    }

    return checkPropsAndRenderComponent([props.gameKernel], renderCBOnlineFrame);

}
