// mw 18.4.2013

// ES6 Feb 2020


export class Timer
{
	constructor( handler )
	{
		this.handler = handler;
		this.active = false;
	};

	launch( handler )
	{
		this.runOnce( 0, handler );
	};

	runOnce( timeout, handler )
	{
		if ( this.inHandler )
		{
			this.fnAfterHandler = function()
			{
				this.runOnce( timeout, handler );
			}.bind( this );
		}
		else
		{
			if ( typeof timeout == "undefined" || timeout == null )
				timeout = 5000;

			if ( handler )
				this.handler = handler;
			this.stop();
			this.isPeriodic = false;
			this.active = true;
			this.timeout = timeout;
			this.timer = window.setTimeout( this.timeoutHandler.bind( this ), this.timeout );
			this.tick = new Tick();
		}
	};

	runPeriodic( timeout, handler )
	{
		if ( handler )
			this.handler = handler;
		this.stop();
		this.isPeriodic = true;
		this.active = true;
		this.timeout = timeout || 5000;
		this.timer = window.setInterval( this.timeoutHandler.bind( this ), this.timeout );
		this.tick = new Tick();
	};

	restart()
	{
		if ( this.active )
		{
			this.stop();
			this.active = true;
			if ( this.isPeriodic )
				this.timer = window.setInterval( this.timeoutHandler.bind( this ), this.timeout );
			else
				this.timer = window.setTimeout( this.timeoutHandler.bind( this ), this.timeout );
			this.tick = new Tick();
		}
	};

	getExpiredMS()
	{
		if ( this.tick )
			return this.tick.getExpiredMS();

		return 0;
	};

	stop()
	{
		if ( this.isPeriodic )
			window.clearInterval( this.timer );
		else
			window.clearTimeout( this.timer );
		this.active = false;
	};

	isActive()
	{
		return this.active;
	};

	timeoutHandler()
	{
		this.inHandler = true;
		if ( this.handler )
			this.handler();
		this.inHandler = false;
		this.active = this.isPeriodic;
		if ( this.fnAfterHandler )
		{
			this.fnAfterHandler();
			delete this.fnAfterHandler;
		}
	};

	getTimeoutMs()
	{
		if ( this.timeout )
			return this.timeout;
		return 0;
	};

	// static und böse:
	static busyWait = function( milliSecs )
	{
		milliSecs += new Date().getTime();
		while ( new Date() < milliSecs )
		{
		}
	};

	static launch = function( fn, milliSecs )
	{
		milliSecs = milliSecs || 100;
		new Timer().runOnce( milliSecs, fn );
	};
}

////////////////////////////////////////////////////////////////////

// high resolution timer available in browser?


export class Tick
{
	constructor()
	{
		this.start();
	};

	static hasHiResTimer = window.performance && window.performance.now;

	start()
	{
		if ( Tick.hasHiResTimer )
			this.ticker = window.performance.now();
		else
			this.ticker = new Date().valueOf();
	};

	getExpired()
	{
		if ( Tick.hasHiResTimer )
			return Math.round( ( window.performance.now() - this.ticker ) * 100 ) / 100;
		else
			return new Date().valueOf() - this.ticker;
	};

	logTime( txt )
	{
		var strLog = "";
		if ( txt )
			strLog = txt + ": ";
		strLog += this.getExpired() + "ms";
		this.start();

		return strLog;
	};

	getExpiredMS ()
	{
		return Math.round( this.getExpired() );
	};

	static getTick ()
	{
		if ( Tick.hasHiResTimer )
			return Math.round( window.performance.now() );
		else
			return new Date().valueOf();
	};

	static getHiResTick = function()
	{
		if ( Tick.hasHiResTimer )
			return window.performance.now();
		else
		{
			return new Date().valueOf();
		}
	}

}


////////////////////////////////////////////////////////////////////

export class TimeSpan
{
	constructor( autoRestart )
	{
		if ( autoRestart !== undefined )
			this.autoRestart = autoRestart;
		else
		{
			this.autoRestart = true;
		}
		this.start();
	};

	start()
	{
		this.tick = new Date().valueOf();
	};

	isElapsed( nSecs )
	{
		if ( this.getElapsedMilliSecs() > nSecs * 1000 )
		{
			if ( this.autoRestart )
				this.start();
			return true;
		}
		return false;
	};

	getElapsedMilliSecs()
	{
		return new Date().valueOf() - this.tick;
	};
}

////////////////////////////////////////////////////////////////
// avoid e.g. fast repeat of a command click

export class RepeatGate
{
	exec( fn, milliSecs )
	{
		if ( this.timer )
			this.timer.stop();
		this.timer = new Timer( fn );
		this.milliSecs = milliSecs;
		this.timer.runOnce( milliSecs );
	};

	isActive()
	{
		return this.timer && this.timer.isActive();
	};

	stop()
	{
		if ( this.timer )
			this.timer.stop();
	}

	restart()
	{
		if ( this.isActive() )
		{
			this.timer.stop();
			this.timer.runOnce( this.milliSecs || 1000 );
		}
	};
}
