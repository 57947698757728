

export class DimensionUtils
{
    static getDocumentCoordinates (element)
    {
        let box = element.getBoundingClientRect();
      
        return {
          top: box.top + window.pageYOffset,
          right: box.right + window.pageXOffset,
          bottom: box.bottom + window.pageYOffset,
          left: box.left + window.pageXOffset
        };
    }

    static getRemainingHeight (element)
    {
        let coords = DimensionUtils.getDocumentCoordinates(element);
        var windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        return windowHeight - coords.top;
    }
}