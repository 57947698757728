

export class MoveCoder {
    
    constructor () {

        // NH2020 Added the following to constructor
        this.moveEncode19 =  
        [	 115, 113, 245, 122, 119,  36,  91, 210,
            93, 137,  56, 239, 159, 205, 160, 142,
            246,  17, 125,  46,  62, 241,  57, 130,
            39,  88,  11,  70, 204, 118, 173, 135,
            54,  30, 199,  68, 154, 186,  55,  27,
            168,  53, 175, 123,  52,  49, 129, 221,
            206,   1,  73, 169,  18, 209,  58, 165,
            183,  72,  31, 198, 251,   6,  63,   2,
            101, 229, 136,  32,  60,  71,  41,  37,
            110, 250, 197,  51,  43, 150, 105, 163,
            238,  26, 191, 116, 249, 207, 208,  75,
            215,  40, 158, 111, 120, 134, 234,  67,
            176,  86, 121, 185, 124,  83, 240,  24,
            89, 244,  13, 196, 223,  78,  38, 231,
            103,  79, 171, 145, 184, 151, 149,  69,
            188, 181, 161,   0, 195,  44,   5, 178,
            172, 174,  66, 133,  80, 217, 254, 236,
            146, 132, 157, 235,  96, 242, 140,  87,
            74, 247, 100, 219,  95,  99, 187, 224,
            85, 255, 155,  22, 237, 192, 148, 126,
            141, 167, 189, 139, 180,  20,  47,  23,
            182, 222, 179, 131, 220,   3, 253, 200,
            127,   7,  16,  33, 214, 152, 211,  42,
            14,  97,  64,  45,  77, 227,  19,  29,
            248, 153, 156, 112, 147, 109,  61, 233,
            225, 218,  81, 144, 213,  59,  84,   9,
            166, 104,  76, 194, 228, 232, 212,  48,
            177, 252, 203, 143,  34,  10, 162,  35,
            98, 106,  65,  90,  25, 190, 193, 138,
            128,  92, 230,   4,  15, 107,  21, 243,
            201, 108, 102,  12, 202, 114,  94,  28,
            216, 164, 117,  82, 226, 170,   8,  50
        ];

        this.moveDecode19 = 
        [	 123,  49,  63, 173, 235, 126,  61, 177,
            254, 207, 221,  26, 243, 106, 184, 236,
            178,  17,  52, 190, 165, 238, 155, 167,
            103, 228,  81,  39, 247, 191,  33,  58,
            67, 179, 220, 223,   5,  71, 110,  24,
            89,  70, 183,  76, 125, 187,  19, 166,
            215,  45, 255,  75,  44,  41,  32,  38,
            10,  22,  54, 205,  68, 198,  20,  62,
            186, 226, 130,  95,  35, 119,  27,  69,
            57,  50, 144,  87, 210, 188, 109, 113,
            132, 202, 251, 101, 206, 152,  97, 143,
            25, 104, 227,   6, 233,   8, 246, 148,
            140, 185, 224, 149, 146,  64, 242, 112,
            209,  78, 225, 237, 241, 197,  72,  91,
            195,   1, 245,   0,  83, 250,  29,   4,
            92,  98,   3,  43, 100,  18, 159, 176,
            232,  46,  23, 171, 137, 131,  93,  31,
            66,   9, 231, 163, 142, 160,  15, 219,
            203, 115, 136, 196, 158, 118,  77, 117,
            181, 193,  36, 154, 194, 138,  90,  12,
            14, 122, 222,  79, 249,  55, 208, 161,
            40,  51, 253, 114, 128,  30, 129,  42,
            96, 216, 127, 170, 164, 121, 168,  56,
            116,  99,  37, 150, 120, 162, 229,  82,
            157, 230, 211, 124, 107,  74,  59,  34,
            175, 240, 244, 218,  28,  13,  48,  85,
            86,  53,   7, 182, 214, 204, 180,  88,
            248, 133, 201, 147, 172,  47, 169, 108,
            151, 200, 252, 189, 212,  65, 234, 111,
            213, 199,  94, 139, 135, 156,  80,  11,
            102,  21, 141, 239, 105,   2,  16, 145,
            192,  84,  73,  60, 217, 174, 134, 153
        ];
    };    

    // NH2020 changed to encode: previously function was doubled: MoveCoder.prototype.decode
    encode (cMove, coffs) {
        return this.moveEncode19[cMove-coffs];
    };
    
    decode (cMove, coffs) {
        return (this.moveDecode19[cMove]+coffs);
    }

}
