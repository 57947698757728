// ST 2011
// ES6 3.3.2020

import { ObjUtil } from 'common/Patterns/ObjectUtil';
import { StringBuilder } from "common/Tools/Strings/StringBuilder";
import { Piece, Side } from 'common/Chess/Logic/Chess';
import { InvariantLocalization, NotationGenerator } from 'common/Chess/Logic/Notation'
import { AnnoType } from 'common/Chess/Format/AnnoTypes';


export var PGNLocalization = ObjUtil.copyTo( InvariantLocalization,
	{ strProm: "=" },
	true );


export class PGNWriter
{
	constructor()
	{
		this.notaGen = new NotationGenerator( PGNLocalization );
	}

	// NH2020 made static
	static writeHeader( _sb, _game )
	{
		var hdr = _game.getHeader();
		// console.log("HEADER: " + hdr);

		PGNWriter.writeHeaderItem( _sb, "Event", hdr.getEvent() );
		PGNWriter.writeHeaderItem( _sb, "White", hdr.getWhite() );
		PGNWriter.writeHeaderItem( _sb, "Black", hdr.getBlack() );
		PGNWriter.writeHeaderItem( _sb, "Site", hdr.getSite() );
		PGNWriter.writeHeaderItem( _sb, "Round", hdr.getRoundStr() );
		PGNWriter.writeHeaderItem( _sb, "Annotator", hdr.getAnnotator() );

		// NH2020 changed the following line
		// previously: PGNWriter.writeHeaderItem( _sb, "Result", hdr.getesult().toPGNString() );
		PGNWriter.writeHeaderItem( _sb, "Result", hdr.getCBResult().toPGNString() );
		
		PGNWriter.writeHeaderItem( _sb, "Date", hdr.getDateStr() );
		if ( hdr.getEloWhite() )
		PGNWriter.writeHeaderItem( _sb, "WhiteElo", hdr.getEloWhite() );
		if ( hdr.getEloBlack() )
		PGNWriter.writeHeaderItem( _sb, "BlackElo", hdr.getEloBlack() );
		PGNWriter.writeHeaderItem( _sb, "PlyCount", _game.getMainLine().length );

		var normInit = _game.isNormalInit();
		if ( !normInit )
		{
			PGNWriter.writeHeaderItem( _sb, "Setup", 1 );

			var fen = _game.getStartPos().toFEN();

			PGNWriter.writeHeaderItem( _sb, "FEN", fen );
		}

		var clockParams = hdr.getClockParams();
		if ( clockParams )
		{
			var strCtrl = clockParams.getStartSecs().toString();
			if ( clockParams.incSecs )
				strCtrl += "+" + clockParams.incSecs;
				PGNWriter.writeHeaderItem( _sb, "TimeControl", strCtrl );
		}
	}

	writeCmd( _sb, _name )
	{
		_sb.appendFormat( "[%{0}", _name );
		var cnt = arguments.length - 2;
		if ( cnt )
		{
			_sb.append( " " );
			for ( var inx = 2; inx < arguments.length; ++inx )
			{
				var val = arguments[inx];
				if ( inx > 2 )
					_sb.append( "," );

				_sb.append( val );
			}
		}
		_sb.appendFormat( "]" );
	}

	writeTimeAnno( _sb, _timeAnno )
	{
		var centSecs = _timeAnno.getTimeIn100th();
		var h = Math.floor( centSecs / ( 3600 * 100 ) );
		centSecs -= h * 3600 * 100;
		var m = Math.floor( centSecs / ( 60 * 100 ) );
		centSecs -= m * 60 * 100;
		var secs = Math.round( centSecs / 100, 1 );

		PGNWriter.writeCmd( _sb, "emt", String.formatEx( "{0}:{1}:{2}", h, m, secs ) );
	};

	writePostAnno( _sb, _anno )
	{
		var symbols = _anno.getItem( AnnoType.SYMBOL );
		if ( symbols )
		{
			for ( var inx = 0, len = symbols.length; inx < len; ++inx )
			{
				var gly = symbols[inx];

				// eslint-disable-next-line no-template-curly-in-string
				_sb.appendFormat( "${0} ", gly );
			}
		}

		var cnt = _anno.count();
		if ( _anno.hasItem( AnnoType.SYMBOL ) )
			--cnt;
		if ( _anno.hasItem( AnnoType.PRETEXT ) )
			--cnt;

		if ( !cnt )
			return;

		_sb.append( "{" );

		var time = _anno[AnnoType.TIME];

		if ( time )
		{
			PGNWriter.writeTimeAnno( _sb, time );
		}

		var txt = _anno[AnnoType.POSTTEXT];
		if ( txt )
		{
			_sb.append( txt.getString() );
		}

		_sb.append( "} " );
	}

	writePreAnno( _sb, _anno )
	{
		var txt = _anno[AnnoType.PRETEXT];

		if ( !txt )
			return;

		_sb.append( "{" );

		_sb.append( txt.getString() );
		_sb.append( "} " );
	}

	// NH2020 made static
	static writeMove( _sb, _mv, _numMv, _needNum )
	{
		var anno = _mv.getAnno();
		if ( _mv.hasAnno() )
		PGNWriter.writePreAnno( _sb, anno );

		var pcMvd = _mv.getMoved();

		if (_needNum || Piece.side(pcMvd) === Side.WHITE)
		{
			_sb.append( _numMv );
			_sb.append(".");

			if ( Piece.side( pcMvd ) === Side.BLACK )
			{
				_sb.append( ".." );
			}
			_sb.append(" ");
		}
			
		var notaGen = new NotationGenerator();
		var str = notaGen.getMoveNota( _mv );
		_sb.append( str );
		_sb.append( " " );

		if ( _mv.hasAnno() )
			PGNWriter.writePostAnno( _sb, anno );

		if (_mv.hasLines())
		{
			for (var inx = 0; inx < _mv.getSubLinesCount(); ++inx)
			{
				_sb.append("(");
				var ln = _mv.getLine( inx );

				PGNWriter.writeLine( _sb, ln, _numMv );

				_sb.append( ")" );
			}
		}

	}

	// NH2020 made static
	static writeLine( _sb, _line, _numMv )
	{
		for ( var i = 0; i < _line.length; ++i )
		{
			var mv = _line[i];

			var pcMvd = mv.getMoved();

			PGNWriter.writeMove( _sb, mv, _numMv, i === 0 );

			if (Piece.side(pcMvd) === Side.BLACK)
				++_numMv;
		}
	}

	// NH2020 made static
	static writeGame( _sb, _game )
	{
		var numMv = 1;
		if(!_game.isNormalInit())
			numMv = _game.getStartPos().getMoveNum();
			PGNWriter.writeLine( _sb, _game.getMainLine(), numMv );
	}

	// NH2020 madeStatic
	static writeHeaderItem( _sb, _var, _val )
	{
		_sb.appendFormat( '[{0} "{1}"]', _var, _val );
		_sb.append( "\r\n" );
	}


	static toPGN( _game )
	{
		var sb = new StringBuilder();

		PGNWriter.writeHeader( sb, _game );

		sb.append( "\r\n" );

		PGNWriter.writeGame( sb, _game );

		// NH2020 changed the following line
		// sb.append( _game.hdr.getesult().toPGNString() );
		sb.append( _game.hdr.getCBResult().toPGNString() );

		sb.append( "\r\n" );
		sb.append( "\r\n" );

		return sb.toString();
	};

	static toUrlPGN( _game )
	{
		var pgn = this.toPGN( _game );

		var strTags = ["Site \"?\"", "Round \"?\"", "Date \"????.??.??\"", "Result \"*\""];

		for ( var i = 0; i < strTags.length; i++ )
		{
			var reg = "/[" + strTags[i] + "/]/ig";
			pgn = pgn.replace( reg, "" );
		}

		pgn = encodeURI( pgn );

		pgn = pgn.replace( /\.%20/ig, "." );
		pgn = pgn.replace( /%0D%0A/ig, "%20" );
		pgn = pgn.replace( /%20%20/ig, "%20" );
		pgn = pgn.replace( /%20%20/ig, "%20" );

		return pgn;
	};

	toFEN( _game )
	{
		var sb = new StringBuilder();

		var fen = _game.getCurPos().toFEN();
		sb.append( fen );

		//writeHeader( sb, _game );

		//sb.append( "\r\n" );

		//writeGame( sb, _game );

		//sb.append( _game.hdr.getesult().toPGNString() );

		//sb.append( "\r\n" );
		//sb.append( "\r\n" );

		return sb.toString();
	};
}