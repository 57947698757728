
import { System } from "common/Tools/System";
import { strUtil } from "common/Tools/Tools";

const SERVER = System.isHTTPS() ? "https://users.chessbase.com" : "http://users.chessbase.com";
const BACKUP_SERVER = System.isHTTPS() ? "https://usersback.chessbase.com" : "http://usersback.chessbase.com";

export var LOGIN_MODES = {
    guest: 0,
    free: 1,
    standard: 2,
    premium: 3,
}

export class AccountUtils
{
    static PasswordLogin (username, password, callback) 
    {
        var nameCoded = AccountUtils.EncodeAndBase64( username );
		var key = AccountUtils.GetCmdKey( nameCoded, "chk" );
		var queryParameters = {
			cmd: "chk",
			user: nameCoded,
			app: "database", // TODO: Diesen Value als Parameter übergeben
			pass: AccountUtils.EncodeAndBase64( password ),
			key: key 
		};

		AccountUtils.QueryServer (queryParameters, callback);
    };

    static TokenLogin (userName, token, callback)
	{
		var nameCoded = AccountUtils.EncodeAndBase64( userName );
		var key = AccountUtils.GetCmdKey( nameCoded, "get" );
		var queryParameters = {
			cmd: "get",
			user: nameCoded,
			app: "database",
			token: token,
			key: key
		};

		AccountUtils.QueryServer(queryParameters, callback);
    }
    
    static QueryServer( parameters, callback )
	{
		var query = [];
		for ( var key in parameters )
		{
			query.push( encodeURIComponent( key ) + '=' + encodeURIComponent( parameters[key] ) );
		}
		var data = query.join( '&' );

		var xmlRequest = new XMLHttpRequest();
		xmlRequest.open( 'POST', SERVER);
		xmlRequest.setRequestHeader( "Content-type", "application/x-www-form-urlencoded" );
		xmlRequest.setRequestHeader( "X-Requested-With", "XMLHttpRequest" );
        xmlRequest.timeout = 3000;

        xmlRequest.onerror = xmlRequest.ontimeout = function ()
        {
			// console.log("XML Request Timeout/Error");
			xmlRequest.abort();
			xmlRequest.open( 'POST', BACKUP_SERVER);
			xmlRequest.ontimeout = AccountUtils.HandleLoginFailed;
			xmlRequest.onerror = AccountUtils.HandleLoginFailed;
			xmlRequest.send( data );
		}

		xmlRequest.onload = function ()
		{
			if ( xmlRequest.readyState === 4 && xmlRequest.status === 200 ) 
			{
				var responsedata = xmlRequest.responseText;
				callback( responsedata ? JSON.parse( responsedata ) : {});
			}
			else
				callback({});
		}

		xmlRequest.send( data );
    };

    static HandleLoginFailed ()
	{
		// console.log("Handle Login Failed");
    };
    
    static GetLoginStatusFromSubscription (subscription)
    {
		// return LOGIN_MODES.standard;
        switch (subscription)
        {
            case 0:
            case 1:
                return LOGIN_MODES.free;
            case 2: 
                return LOGIN_MODES.standard;
            case 3:
                return LOGIN_MODES.premium;
			default:
				return LOGIN_MODES.free;
        }
    }

    // TODO: Localisation
    static GetLoginStringFromLoginStatus (loginStatus)
    {
        switch (loginStatus)
        {
            case LOGIN_MODES.guest:
                return "Guest"
            case LOGIN_MODES.free:
                return "Free";
            case LOGIN_MODES.standard: 
                return "Standard";
            case LOGIN_MODES.premium:
                return "Premium";
			default:
				return "Guest";
        }
    }
    
    static GetCmdKey( name, cmd )
	{
		if ( name.Length === 0 )
			name = "nameCoded";

		var cat = cmd + name;
		var nameChars = name.split( "" ), codeChars = cmd.split( "" ), chars2 = cat.split( "" );

		var nCode = 0, nName = 0;
		for ( var i = 0; i < chars2.length ; i++ )
		{
			if ( ( i & 1 ) !== 0 && nCode < codeChars.length )
			{
				chars2[i] = codeChars[nCode];
				nCode++;
			}
			else if ( nName < nameChars.length )
			{
				chars2[i] = nameChars[nameChars.length - nName - 1];
				nName++;
			}
		}
		var encoded = AccountUtils.EncodeAndBase64( chars2.join( "" ) );
		return encoded;
	};
    
    static EncodeAndBase64( txt )
	{
		txt = strUtil.escapeHtmlEntities( txt );
		var encoded = strUtil.base64.encode( txt );
		return encoded;
	};
}
