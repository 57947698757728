// jb 31.1.2014

// "use strict";

export var GameAutoCompleteEnum = {
    ACR_NONE      :  0x0000,
    ACR_LASTNAME	:	0x0001,
    ACR_FIRSTNAME	:	0x0002,
    ACR_EVENT		:	0x0004,
    ACR_SITE		:	0x0008,

    toString:
        function ( n ) {
            for ( var attr in this ) {
                if ( this[attr] == n )
                   return "GameAutoCompleteId: " + attr;
            }
            return "Unknown GameAutoCompleteId: " + n;
        }
};

export class GameAutoCompleteRequest {

    constructor ( strRequest, nFlags ) {
       this.m_strRequest = strRequest;
       this.m_nFlags = nFlags;
    };

    write ( rDB ) {
       rDB.beginSizedWrite();
       rDB.writeInt32( this.m_nFlags );
       rDB.writeString(this.m_strRequest);
       rDB.endSizedWrite();
    }

}


///////////////////////////////////////////////////////////////////////////
export class GameAutoCompleteAnswer {

   // constructor () {
   // };

   read (rDB) {
      this.data = [];

      rDB.beginSizedRead();
      var nCount = rDB.readInt32();
      for (var n = 0; n < nCount; n++) {
         var strItem = rDB.readString(80);
         this.data.push(strItem);
      }
      rDB.endSizedRead();
   }

}



