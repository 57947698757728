// jb 3.7.2013

// "use strict";

import { Position } from "../Logic/Position";
import { OnlineStatEntry } from "common/Chess/OnlineDB/OnlineStatEntry"

export class OnlineStatistics {

	constructor() {
		this.vecStat = [];
	};

//	OnlineStatistics.prototype.vecStat = [];

	clear = function () {
		this.vecStat = [];
	};

	readFromDataBuffer = function ( rDB, rPos ) {

		var bRet = true;
		this.clear();

		var nStat = rDB.readUint32();

		var strFen = rPos.toFEN();

		var OrgPosition = new Position( strFen );
		if ( nStat > 0 ) {
			var aStat = new OnlineStatEntry();
			aStat.readFromDataBuffer( rDB, OrgPosition );
			--nStat;
			//this.vecStat.push(aStat);

			if ( ( nStat > 0 ) ) {
				for ( var n = 0; n < aStat.getNMoves() && nStat > 0; ++n, --nStat ) {

					var strFenOrg = rPos.toFEN();

					var OrgPositionMove = new Position( strFenOrg );

					var rMove = aStat.getMove( n );

					OrgPositionMove.preCalcCtx( rMove );
					OrgPositionMove.makeMove( rMove );
					OrgPositionMove.postCalcCtx( rMove, true );

					var aNext = new OnlineStatEntry( rMove );
					aNext.readFromDataBuffer( rDB, OrgPositionMove );

					this.vecStat.push( aNext );
				}
			}
		}

		return bRet;
	};

	getEntry = function ( n ) {
		return this.vecStat[n];
	};

	Count = function () {
		return this.vecStat.length;
	};

}
