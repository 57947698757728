// ST 2011
// ES 6 MW: Feb 27, 2020

// "#NOMINIFY=reduce";

import { createReadFactory, createReadFactory2 } from 'common/Container/DataBuffer'
import { ObjUtil } from 'common/Patterns/ObjectUtil'
import { AnnoType} from 'common/Chess/Format/AnnoTypes'
import { Annotation } from 'common/Chess/Logic/Annotation'
import { Move } from 'common/Chess/Logic/Move'
// import { Log } from 'common/Tools/Log'


export class MoveLine extends Array
{
	constructor( _parentLine, _inxMv )
	{
		super();
		this.parent = null;
		this.inxMvParent = 0;

		if ( _parentLine )
		{
			this.parent = _parentLine;
			this.inxMvParent = _inxMv;
		}
	}


	//6.10.2015 Dank den smarten Cloner nicht mehr nötig.
	// mw, 4.5.2015 (stack overflow in ObjUtil.clone because of parent).
	//clone = function (_hash)
	//{
	//	var res = new CB.MoveLine();

	//	for ( var inx = 0, len = this.length; inx < len; ++inx )
	//	{
	//		if ( this[inx] )
	//			res[inx] = ObjUtil.clone( this[inx], _hash );
	//	}

	//	_hash.setItem(this, res);

	//	return res;
	//};

	isMainLine()
	{
		return !this.parent;
	};

	setMainLine()
	{
		this.parent = null;
		this.inxMvParent = 0;
	};

	getParentLine()
	{
		return this.parent;
	};

	setParentLine( _line )
	{
		this.parent = _line;
	};

	getParentMoveIndex()
	{
		return this.inxMvParent;
	};

	setParentMoveIndex( _inxMvParent )
	{
		this.inxMvParent = _inxMvParent;
	};

	getTreeMoveCount()
	{
		return this.reduce( function( _cnt, _mv )
		{
			return _cnt + _mv.getTreeMoveCount();
		}, 0 );
	};

	setSubParentData( _rec )
	{
		this.forEach( function( _mv, _inx )
		{
			_mv.setSubParentData( this, _inx, _rec );
		}.bind( this ) );
	};

	swapMoves( _inxMv, _lineReplacement )
	{
		var cnt = this.length - _inxMv;
		var args = [ _inxMv, cnt ];

		if ( _lineReplacement )
		{
			for ( var inx = 0, len = _lineReplacement.length; inx < len; ++inx )
				args.push( _lineReplacement[ inx ] );
		}

		var arrOld = this.splice.apply( this, args );

		this.setSubParentData();

		args = [ 0, _lineReplacement.length ].concat( arrOld );

		_lineReplacement.splice.apply( _lineReplacement, args );

		_lineReplacement.setSubParentData();
		_lineReplacement.setParentLine( this );

		return _lineReplacement;
	};

	selfTest( _pos, _lineParent, _inxParentMv )
	{
		// CBDebug.assert( this.inxMvParent === _inxParentMv );
		//CBDebug.assert( this.parent === _lineParent );  // tritt haufig auf beim kibitzen streams

		// CBDebug.assert( !_lineParent || ( this.getParentMove() != null && this.getParentMove().indexOfLine( this ) >= 0 ) );

		var cur = ObjUtil.clone( _pos );

		for ( var inx = 0, len = this.length; inx < len; ++inx )
		{
			var mv = this[ inx ];

			//	CBDebug.assert( cur.isLegalMove( mv ), mv.toString() + " illegal (assert)" );
			//if ( !cur.isLegalMove( mv ) ) {
			//	Log.Log( "selfTest illegal: " + mv, "LogError" );
			//}

			if ( mv.hasLines() )
			{
				// CBDebug.assert( !_lineParent || inx );

				var subLines = mv.getSubLines();
				for ( var inxLn = 0; inxLn < subLines.length; ++inxLn )
				{
					var line = subLines[ inxLn ];

					line.selfTest( ObjUtil.clone( cur ), this, inx );
				}
			}

			cur.makeMove( mv );
		};
	};

	getParentMove()
	{
		if ( !this.parent )
			return null;
		return this.parent[ this.inxMvParent ];
	};

	toString()
	{
		return this.join( " " );
	};

	static readFactory = createReadFactory( MoveLine );
	static readFactory2 = createReadFactory2( MoveLine );

	read( _buf )
	{
		_buf.readArray( this, Move.readFactory );
		this.setSubParentData();
		this.shiftTrainingAnnosBack();	//	internally, we have training annos on the move before the queried move
	};

	read2( _buf )
	{
		var lineLen = _buf.readUint8();
		if ( lineLen & 0x80 )
			lineLen = ( ( ( lineLen << 8 ) | _buf.readUint8() ) & 0x7fff );
		if ( lineLen > 0 )
		{
			if ( lineLen > 0x800 )
			{
				lineLen = 0;
				return false;
			}
			if ( lineLen > 0 )
			{
				//delete[] pLine;
				// var lineMax = ( lineLen + 3 ) & ~3;
				this.length = lineLen;
				for ( var n = 0; n < lineLen; n++ )
				{
					var aMove = new Move();
					aMove.read2( _buf );
					this[ n ] = aMove;
				}
			}
			/*	      for (var i = 0; i < lineLen; i++) {
							_buf.readArrayShort(this, CB.Move.readFactory2);
							if (!pLine[i].ReadFromDataBuffer2(rBuf, nVersion)) {
								pLine[i].Delete();
								lineLen = i;
								return false;
							}
						}*/
		} else
			if ( lineLen < 0 )
			{
				lineLen = 0;
				return false;
			}
		this.setSubParentData();

		this.shiftTrainingAnnosBack();	//	internally, we have training annos on the move before the queried move
	};

	write( _buf )
	{
		this.shiftTrainingAnnosFwd();	//	internally, we have training annos on the move before the queried move
		_buf.writeArray( this );
	};

	write2( _buf )
	{
		this.shiftTrainingAnnosFwd();	//	internally, we have training annos on the move before the queried move

		if ( this.length <= 0x800 )
		{
			if ( this.length & ~0x7f )
			{
				_buf.writeUint8( ( this.length << 8 ) | 0x80 );
			}
			_buf.writeUint8( this.length & 0xff );
		}
		for ( var i = 0; i < this.length; i++ )
			this[ i ].write2( _buf );
	};

	hasLines()
	{
		return this.some( function( _mv ) { return _mv.hasLines(); } );
	};


	// in JS, training annos are linked to the move before the solution move.
	// so convert, when reading/writing
	// if game comes from parsed PGN, they are already one move earlier than in C++

	// back for reading
	shiftTrainingAnnosBack()
	{
		for ( var i = 1; i < this.length; i++ )
		{
			if ( this[ i ].hasAnno() && this[ i ].getAnno().hasTraining() )
			{
				var t = this[ i ].getAnno().getTraining();
				this[ i ].getAnno().setTraining( null );
				this[ i - 1 ].setAnnoItem( AnnoType.TRAINING, t );
			}
		}
	};

	// forward for writing
	shiftTrainingAnnosFwd()
	{
		for ( var i = this.length - 2; i >= 0; i-- )
		{
			if ( this[ i ].hasAnno() && this[ i ].getAnno().hasTraining() )
			{
				var t = this[ i ].getAnno().getTraining();
				this[ i ].getAnno().setTraining( null );
				this[ i + 1 ].setAnnoItem( AnnoType.TRAINING, t );
			}
		}
	};

	isAnnotated()
	{
		return this.some( function( _mv ) { return _mv.hasLines() || _mv.hasAnno(); } );
	};

	// not supported yet fully
	setAnno( anno )
	{
		this.anno = anno;
	};

	getAnno( anno )
	{
		return this.anno;
	};

	setAnnoItem( type, val )
	{
		if ( !this.anno )
			this.anno = new Annotation();

		this.anno.setItem( type, val );
	};

	hasAnno()
	{
		return this.anno !== undefined;
	};

	unAnnotate()
	{
		this.forEach( function( _mv ) { _mv.unAnnotate(); } );
	};

	forAllMoves( fn )
	{
		// Log.Log("Line, for all moves.");
		this.forEach( function( _mv ) { _mv.forAllMoves( fn ); } );
	};

	removeLines()
	{
		this.forEach( function( _mv ) { _mv.removeLines(); } );
	};
}
