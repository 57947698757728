

export class TableSort 
{
    static numbers (columnIndex)
    {
        return (a, b) => {
            return b.render[columnIndex] - a.render[columnIndex];
        }
    }

    static alphabet (columnIndex)
    {
        return (a, b) => {
            if (a.render[columnIndex] === b.render[columnIndex])
                return 0;
            else if (a.render[columnIndex] === "")
                return 1;
            else
                return (a.render[columnIndex] < b.render[columnIndex]) ? -1 : 1;
        }
    }

    static alphabetReversed (columnIndex)
    {
        return (a, b) => {
            if (a.render[columnIndex] === b.render[columnIndex])
                return 0;
            else
                return (a.render[columnIndex] < b.render[columnIndex]) ? 1 : -1;
        }
    }

    static move (columnIndex)
    {
        return (a, b) => {
            a = a.render[columnIndex].replace(/[^a-zA-Z]/g, "").toLowerCase();
            b = b.render[columnIndex].replace(/[^a-zA-Z]/g, "").toLowerCase();
            if (a === b)
                return 0;
            else
                return (a < b) ? -1 : 1;
        }
    }

    static resultPercent (columnIndex)
    {
        return (a, b) => {
            a = parseFloat(a.render[columnIndex].replace(/\D/g, "").toLowerCase());
            b = parseFloat(b.render[columnIndex].replace(/\D/g, "").toLowerCase());
            return b - a;
        }
    }
}

export class TableData 
{
    constructor (tableData)
    {
        // Selected Column initial value
        this.selectedHeader = tableData.selectedHeader || -1;

        // Selected Row initial value
        this.selectedRow = tableData.selectedRow || -1;

        // Define Column Names in array
        this.header = tableData.header || [];

        // Defines relative Column sizes
        this.flexGrow = tableData.flexGrow || [];
        this.flexGrowFallback = Object.assign({}, this.flexGrow);

        // Defines sorting functions of columns
        this.sortingMethods = tableData.sortingMethods || [];
        this.isSortedReversed = false;

        // Define Columns to be removed at resize
        this.removeOnResize = tableData.removeOnResize || [];

        // Remove columns at size smaller than...
        this.removeAtSize = tableData.removeAtSize || [];

        // Defines fancy rendering for columns based on index
        this.renderFancy = tableData.renderFancy || {};

        // Defines Table Content. Each Array element is an object with a "render" and an info field.
        this.content = tableData.content || [];

        if (this.header.length !== this.flexGrow.length || this.header.length !== this.sortingMethods.length || this.header.length === 0)
            console.warn("Table has been initialised incorrectly. Please check associative array sizes.");

        if (this.removeOnResize.length !== this.removeAtSize.length)
            console.warn("Table has been initialised incorrectly. Please check associative array sizes.");
    }
}

export const TableReducer = (table, action) => 
{
    var newTable = [];
    var newContent;

    switch (action.type)
    {
        case "CONTENT":
            newContent = action.newContent;
            newTable = [];
            Object.assign(newTable, table);
            newTable.content = newContent;
            
            if (!action.keepHeaderSelection)
                newTable.selectedHeader = -1;

            newTable.scrollToTop = false;
            return newTable;
        case "SCROLLTOTOP":
            newTable = [];
            Object.assign(newTable, table);
            newTable.scrollToTop = true;
            return newTable;
        case "SETHEADER":
            newTable = [];
            Object.assign(newTable, table);
            newTable.header = action.header;
            newTable.scrollToTop = false;
            return newTable;
        case "SETPLAYERDATA":
            newTable = [];
            Object.assign(newTable, table);
            newTable.content = [];
            newTable.scrollToTop = false;
            for (var i = 0; i < table.content.length; i++)
            {
                newTable.content.push(table.content[i]);

                var selection = table.content[i].info.selection;
                if (action.playerData[selection])
                    newTable.content[i].render[action.columnIndex] = action.playerData[selection];
            }
            return newTable;
        case "SORT":
            // If new selected cell === previously selected cell: Reverse!
            if (table.selectedHeader === action.columnIndex && table.sortingMethods[action.columnIndex])
            {
                newContent = [];
                Object.assign(newContent, table.content);
                newTable = [];
                Object.assign(newTable, table);
                newTable.content = newContent.reverse();
                newTable.isSortedReversed = !table.isSortedReversed;
                newTable.scrollToTop = false;
                return newTable;
            }
            // If new selected cell != previously selected cell: Sort!
            else if (table.selectedHeader !== action.columnIndex && table.sortingMethods[action.columnIndex])
            {
                newTable = [];
                Object.assign(newTable, table);
                newTable.selectedHeader = action.columnIndex;
                newTable.content = table.content.sort(table.sortingMethods[action.columnIndex](action.columnIndex));
                newTable.isSortedReversed = false;
                newTable.scrollToTop = false;
                return newTable;
            }
            else
                return table;
        case "REFRESHSORT":
            if (table.sortingMethods[table.selectedHeader])
            {
                console.log("Refresh Sorting!");
                newTable = [];
                Object.assign(newTable, table);
                newTable.content = table.content.sort(table.sortingMethods[table.selectedHeader](table.selectedHeader));
                newTable.scrollToTop = false;
                return newTable;
            }
            else
                return table;
        case "RESIZE":
            newTable = [];
            Object.assign(newTable, table);
            var newFlexGrow = [];
            Object.assign(newFlexGrow, action.newFlexGrow);
            newTable.flexGrow = newFlexGrow;
            newTable.scrollToTop = false;
            return newTable;
        case "SELECTROW":
            newTable = [];
            Object.assign(newTable, table);
            newTable.selectedRow = action.newSelection;
            newTable.scrollToTop = false;
            return newTable;
        case "RESET":
            newTable = [];
            Object.assign(newTable, table);
            newTable.selectedRow = -1;
            newTable.selectedHeader = -1;
            newTable.scrollToTop = false;
            return newTable;
        case "RENDERFANCY":
            newTable = [];
            Object.assign(newTable, table);
            newTable.renderFancy = action.newRenderFancy;
            newTable.scrollToTop = false;
            return newTable;
        default:
            return table;
    }
}