

import React, { useReducer, useContext, useEffect } from 'react';
import ReactGA from 'react-ga';
import baseStyles from "../CBModules.module.css";
import styles from "./CBBookModule.module.css";

import { TableReducer, TableSort } from "CBReact/Components/Table/TableUtils"
import { CBTable } from "CBReact/Components/Table/CBTable"
import { CBButtonBadge } from "CBReact/Components/DesignElements/Buttons/ButtonBadge/CBButtonBadge"
import { SideNavContext, LocalizationContext, LoginContext, OnlineLobbyContext, TeraBrainLobbyContext } from "CBReact/Contexts/CBOnlineDBContext"
import { SIDENAV_RENDERMODES } from "CBReact/Components/NavBars/CBNavBarSide"
import { Move } from 'common/Chess/Logic/Move';
import { LOGIN_MODES } from "CBReact/Components/Account/AccountUtils";
import { TableData } from 'CBReact/Components/Table/TableUtils';
import { checkPropsAndRenderComponent } from 'CBReact/Utils/DebugUtils';
import { ComparisonUtils } from 'CBReact/Utils/ComparisonUtils';

export function CBBookModule (props)
{
    const changeSideNav = useContext(SideNavContext).changeSideNav;
    const loginStatus = useContext(LoginContext).loginStatus;
    const getCurrentLoginStatus = useContext(LoginContext).getCurrentLoginStatus;
    const loc = useContext(LocalizationContext).localization;

    const bookTableContent = useContext(TeraBrainLobbyContext).bookTableContent;
    const bookTableIsLoading = useContext(TeraBrainLobbyContext).bookTableIsLoading;
    const searchTeraBrainBook = useContext(TeraBrainLobbyContext).searchTeraBrainBook;
    const teraBrainLobbyIsConnected = useContext(TeraBrainLobbyContext).isConnected;

    const playerData = useContext(OnlineLobbyContext).playerData;
    const refreshOnlineDBList = useContext(OnlineLobbyContext).refreshOnlineDBList;

    const bookTableData = new TableData({
        selected: -1,
        header: [loc.strings.MOVE, loc.strings.GAMES, loc.strings.RESULT, loc.strings.ELO_AVG, "%", loc.strings.VISITS, "Players"],
        flexGrow: [1.5, 2, 1.5, 1.5, 0.8, 2, 1.8],
        flexGrowFallback: [1.5, 2, 1.5, 1.5, 0.8, 2, 1.8],
        sortingMethods: [TableSort.move, TableSort.numbers, TableSort.resultPercent, TableSort.numbers, TableSort.numbers, TableSort.numbers, TableSort.alphabet],
        removeOnResize: [5, 6],
        removeAtSize: [500, 418], 
        renderFancy: {
            1: function () { return <CBButtonBadge size="small" onClick={handleLoginClick}>Anmelden</CBButtonBadge> },
            5: function () { return <CBButtonBadge size="small" onClick={handleLoginClick}>Anmelden</CBButtonBadge> },
            6: function () { return <CBButtonBadge size="small" onClick={handlePremiumClick}>Premium</CBButtonBadge> }, },
    });

    const [bookTable, bookTableDispatch] = useReducer(TableReducer, bookTableData);

    useEffect(() => {
        if (bookTableContent.length === 0)
            searchTeraBrainBook(props.boardPosition, props.startPosition);
    }, [teraBrainLobbyIsConnected]);

    useEffect(() => {
        searchTeraBrainBook(props.boardPosition, props.startPosition);
    }, [props.boardPosition]);

    useEffect(() => {  
        bookTableDispatch({type: 'SETPLAYERDATA', columnIndex: 6, playerData});
    }, [playerData]);

    useEffect(() => {
        bookTableDispatch({ type: 'CONTENT', newContent: bookTableContent });
        bookTableDispatch({ type: 'SORT', columnIndex: 5 });
        bookTableDispatch({ type: 'SELECTROW', newSelection: -1});
    }, [bookTableContent]);

    useEffect(() => {
        if (loginStatus === LOGIN_MODES.guest)
        {
            let newRenderFancy = {
                1: function () { return <CBButtonBadge size="small" onClick={handleLoginClick}>{loc.strings.LOGIN}</CBButtonBadge> },
                5: function () { return <CBButtonBadge size="small" onClick={handleLoginClick}>{loc.strings.LOGIN}</CBButtonBadge> },
                6: function () { return <CBButtonBadge size="small" onClick={handlePremiumClick}>Premium</CBButtonBadge> },
            };
            bookTableDispatch({type: 'RENDERFANCY', newRenderFancy});
        }
        else if (loginStatus === LOGIN_MODES.free)
        {
            let newRenderFancy = {
                6: function () { return <CBButtonBadge size="small" onClick={handlePremiumClick}>Premium</CBButtonBadge> }
            };
            bookTableDispatch({type: 'RENDERFANCY', newRenderFancy});
        }
        else if (loginStatus === LOGIN_MODES.standard || loginStatus === LOGIN_MODES.premium)
        {
            let newRenderFancy = {};
            bookTableDispatch({type: 'RENDERFANCY', newRenderFancy});
        }

    }, [loginStatus, loc]);

    useEffect (() => {
        if (loc.strings)
            bookTableDispatch({type: "SETHEADER", header: [loc.strings.MOVE, loc.strings.GAMES, loc.strings.RESULT, loc.strings.ELO_AVG, "%", loc.strings.VISITS, "Players"]})
    }, [loc])

    // TODO: Es wäre schön, gameKernel als Prop loszuwerden. Letze Funktion, in der er gebraucht wird.
    function handleBookClick (e, rowInfo)
    {
        e.preventDefault();

        if (rowInfo && rowInfo.from && rowInfo.to)
        {
            var newMove = new Move( rowInfo.from, rowInfo.to );
            if (props.gameKernel.getCurPos().isLegalMove( newMove ))
                props.gameKernel.game.makeMove(newMove, props.gameKernel.playingMode.getMoveEnterType());

            if (props.gameKernel.game)
                refreshOnlineDBList(props.gameKernel.game.getCurPos().board, props.gameKernel.game.getCurPos().getSideToMove(), 50, props.gameKernel.game.getMoveNo());
        }

        ReactGA.event({
            category: 'Interaction',
            action: 'Clicked Book Entry',
        });
    }

    function handleLoginClick (e)
    {
        e.stopPropagation();
        changeSideNav(true, SIDENAV_RENDERMODES.login);
    }

    function handlePremiumClick (e)
    {
        e.stopPropagation(); 
        window.open( "https://account.chessbase.com/en/matrix", "_blank", "noopener" );
    }

    function renderCBBookModule ()
    {
        return (
            <div id="book" className={`${baseStyles.base} ${styles.bookModule}`}>
                <CBTable tableData={bookTable} 
                tableDispatch={bookTableDispatch} 
                onEntryClick={handleBookClick} 
                fillRemainingHeight={props.fillRemainingHeight} 
                maxHeight={props.tableMaxHeight}  
                fixedHeight={props.tableFixedHeight}
                isLoading={bookTableIsLoading}/>
            </div>
        )
    }

    return checkPropsAndRenderComponent([props.gameKernel, props.boardPosition, props.startPosition, changeSideNav, loginStatus, getCurrentLoginStatus, loc, bookTableContent, searchTeraBrainBook, playerData], renderCBBookModule);
}

// Assumes GameKernel is always the same and will never change
function bookModulePropsAreEqual (prev, next)
{
    return  ComparisonUtils.arraysAreEqual(prev.boardPosition.board, next.boardPosition.board) && 
            prev.fillRemainingHeight === next.fillRemainingHeight && 
            prev.maxHeight === next.maxHeight && 
            prev.fixedHeight === next.fixedHeight;
}

export const CBMBookModule = React.memo(CBBookModule, bookModulePropsAreEqual);