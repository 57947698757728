// jb 3.7.2013

// "use strict";

import { Board } from "common/Chess/Logic/Board"

export var FilterFlagsEnum = {
	OLSM_NULL: 0x0000,
	OLSM_USE_BOARD: 0x0001,
	OLSM_WINS: 0x0010,
	OLSM_DRAWS: 0x0020,
	OLSM_LOSSES: 0x0040,
	OLSM_WINS_ONLY: 0x0080,
	OLSM_WHITE: 0x0100,
	OLSM_BLACK: 0x0200,
	OLSM_IGNORE_COLORS: 0x0400,
	OLSM_BEST_GAMES: 0x1000,
	OLSM_USE_MATERIAL: 0x2000
};


export class SearchMask
{
	constructor ()
	{
		this.init();

		this.m_strWhMask = "";
		this.m_strBlMask = "";
	
		this.m_strTournMask = "";
		this.m_strTitle = "";
	
		this.m_strPlaceMask = "";
		this.freeText = "";
	
		this.m_nMinECO = 0;
		this.m_nMaxECO = 0xffff;
	
		this.m_iMinYear = 0;
		this.m_iMaxYear = 3000;

		this.m_iWhiteMinElo = 0;
		this.m_iBlackMinElo = 0;
	
		this.m_board = new Board();
		this.m_eSideToMove = 0;

		// this.m_nFlags = FilterFlagsEnum.OLSM_WINS | FilterFlagsEnum.OLSM_DRAWS | FilterFlagsEnum.OLSM_LOSSES | FilterFlagsEnum.OLSM_WHITE;
		// this.m_nFlags = FilterFlagsEnum.OLSM_WINS | FilterFlagsEnum.OLSM_DRAWS | FilterFlagsEnum.OLSM_LOSSES | FilterFlagsEnum.OLSM_WHITE;
		this.m_nFlags = FilterFlagsEnum.OLSM_WINS | FilterFlagsEnum.OLSM_DRAWS | FilterFlagsEnum.OLSM_LOSSES | FilterFlagsEnum.OLSM_WHITE | FilterFlagsEnum.OLSM_BLACK;
	};

	// NH2020 made these static
	static ELO_NONE = 0;
	static YEAR_NONE = 0;


	init ( buf )
	{
	};

	// Schreibt Informationen zur Suche in den Buffer?!
	writeToDataBuffer ( rDB )
	{
		rDB.beginSizedWrite();
		rDB.writeByte( 1 /* sizeof(byte)*/ );
		rDB.writeASCIIString( this.m_strWhMask );
		rDB.writeASCIIString( this.m_strBlMask );
		rDB.writeASCIIString( this.m_strTitle );
		rDB.writeASCIIString( this.m_strPlaceMask );
		rDB.writeInt( this.m_iMinYear );
		rDB.writeInt( this.m_iMaxYear );
		rDB.writeInt( this.m_iWhiteMinElo );
		rDB.writeInt( this.m_iBlackMinElo );
		rDB.writeUint16( this.m_nMinECO );
		rDB.writeUint16( this.m_nMaxECO );
		rDB.writeInt( this.m_nFlags );

		// Wenn das Board benutzt wird: Schreibe die BoardPositionen
		if ( this.getUseBoard() )
		{
			rDB.writeByte( this.m_eSideToMove );
			for ( var i = 0; i < 64; i++ ) {
				rDB.writeByte( this.m_board[i] );
			}

		}
		rDB.writeASCIIString( this.freeText );
		rDB.endSizedWrite();
	};

	setSearchBoard ( rBoard, eSideToMove, nMoveNo )
	{
		this.m_board = rBoard;
		this.m_eSideToMove = eSideToMove;
	};

	SETFLAG ( n, b )
	{
		if ( b ) this.m_nFlags |= ( n );
		else this.m_nFlags &= ~( n );
	};

	ISFLAG ( n )
	{
		return ( this.m_nFlags & ( n ) ) != 0;
	};

	setIgnoreColors ( b )
	{
		this.SETFLAG( FilterFlagsEnum.OLSM_IGNORE_COLORS, b );
	};

	setUseBoard ( b )
	{
		this.SETFLAG( FilterFlagsEnum.OLSM_USE_BOARD, b );
	};

	getUseBoard ()
	{
		return this.ISFLAG(FilterFlagsEnum.OLSM_USE_BOARD);
	};

	setUseMaterial (b)
	{
		this.SETFLAG(FilterFlagsEnum.OLSM_USE_MATERIAL, b);
	};

	getUseMaterial ()
	{
		return this.ISFLAG(FilterFlagsEnum.OLSM_USE_MATERIAL);
	};

	setWins ( b )
	{
		this.SETFLAG( FilterFlagsEnum.OLSM_WINS, b );
	};

	setDraws ( b )
	{
		this.SETFLAG( FilterFlagsEnum.OLSM_DRAWS, b );
	};

	setLosses ( b )
	{
		this.SETFLAG( FilterFlagsEnum.OLSM_LOSSES, b );
	};

	setWhite ( b )
	{
		this.SETFLAG( FilterFlagsEnum.OLSM_WHITE, b );
	};

	setBlack ( b )
	{
		this.SETFLAG( FilterFlagsEnum.OLSM_BLACK, b );
	};

	setGoodGames ( b )
	{
		this.SETFLAG( FilterFlagsEnum.OLSM_BEST_GAMES, b );
	};

	setFreeText ( str )
	{
		this.freeText = str;
	};

	// Den geschriebenen Text der Sucheingabe parsen
	// Variablen außerhalb der Funktion werden nur gesetzt, wenn das Jahr > 0??
	parseFreeText ()
	{		
		var text = "";

		var parts = this.freeText.split( ' ' );
		var year = 0;

		if ( parts.length > 1 )
		{
			for ( var i = 0; i < parts.length; i++ )
			{
				parts[i].replace( " ", "" );
				// Wenn der Part keine Zahl ist
				if ( isNaN( parts[i] ) )
				{
					if ( text )
						text += " ";
					text += parts[i];
				}
				// Wenn der Part eine Zahl ist: Gehe von Jahr aus?
				else
				{
					year = parts[i];
				}
			}
		}
		if ( year > 0 )
		{
			this.freeText = text;
			this.m_iMinYear = this.m_iMaxYear = year;
		}
	};

	toString ()
	{
		return "searchmask=" + this.freeText + " board= " + this.m_board.toString();
	};

}



