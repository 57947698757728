// jb 12.11.2013

// import { Log } from "common/Tools/Log";

// ES6 4.3.2020

export class Title
{

	constructor( s )
	{
		this.eTitleCode = s;

		// NH2020 Added these directly to function
		// this.Code.CBTC_WGM = this.Code.CBTC_WIM | this.Code.CBTC_WFM;
		// this.Code.CBTC_GM = this.Code.CBTC_IM | this.Code.CBTC_FM;
	}

	// NH2020 made static
	static Code = 
	{
		CBTC_NONE: 0,
		CBTC_MARKED: 1,
		CBTC_HGM: 2,
		CBTC_WFM: 4,
		CBTC_WIM: 8,
		CBTC_FM: 16,
		CBTC_IM: 32,
		CBTC_WWCH: 64,
		CBTC_WCH: 128,
	}


	// NH2020 made static
	static getStr( eCode )
	{
		// NH2020 Does this work. Seems to work
		// Log.WillItWork();
		var CBTC_WGM = Title.Code.CBTC_WIM | Title.Code.CBTC_WFM;
		var CBTC_GM = Title.Code.CBTC_IM | Title.Code.CBTC_FM;

		if ( ( eCode & CBTC_GM ) === CBTC_GM )
			return "GM";
		if ( ( eCode & CBTC_WGM ) === CBTC_WGM )
			return "WGM";
		if ( eCode & Title.Code.CBTC_WCH )
			return "WCH";
		if ( eCode & Title.Code.CBTC_WWCH )
			return "WWCH";
		if ( eCode & Title.Code.CBTC_WIM )
			return "WIM";
		if ( eCode & Title.Code.CBTC_WFM )
			return "WFM";
		if ( eCode & Title.Code.CBTC_IM )
			return "IM";
		if ( eCode & Title.Code.CBTC_FM )
			return "FM";
	}

	getAllTitles()
	{
		var Str1 = "";
		if ( ( this.eTitleCode & Title.Code.CBTC_WCH ) > 0 )
			Str1 += "WCH, ";
		if ( ( this.eTitleCode & Title.Code.CBTC_WWCH ) > 0 )
			Str1 += "WWCH, ";
		if ( ( this.eTitleCode & Title.Code.CBTC_GM ) === Title.Code.CBTC_GM )
			Str1 += "GM, ";
		else if ( ( this.eTitleCode & Title.Code.CBTC_IM ) > 0 )
			Str1 += "IM, ";
		else if ( ( this.eTitleCode & Title.Code.CBTC_FM ) > 0 )
			Str1 += "FM, ";
		if ( ( this.eTitleCode & Title.Code.CBTC_WGM ) === Title.Code.CBTC_WGM )
			Str1 += "WGM, ";
		else if ( ( this.eTitleCode & Title.Code.CBTC_WIM ) > 0 )
			Str1 += "WIM, ";
		else if ( ( this.eTitleCode & Title.Code.CBTC_WFM ) > 0 )
			Str1 += "WFM, ";
		if ( ( this.eTitleCode & Title.Code.CBTC_HGM ) > 0 )
			Str1 += "HGM, ";
		if ( ( this.eTitleCode & Title.Code.CBTC_MARKED ) > 0 )
			Str1 += "VIP, ";
		//   Str1.EraseTrailingChars( ' ' );
		// Str1.EraseTrailingChars( ',' );
		return Str1;
	}
}
