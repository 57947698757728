
import React, { useContext } from 'react';
import styles from "./CBAccount.module.css";

import { SideNavContext, LocalizationContext, LoginContext } from "CBReact/Contexts/CBOnlineDBContext"
import { AccountUtils } from "CBReact/Components/Account/AccountUtils";

export function CBAccountInfo (props)
{
    const changeSideNav = useContext(SideNavContext).changeSideNav;
    const logoutContext = useContext(LoginContext).logout;
    const userName = useContext(LoginContext).userName;
    const loginStatus = useContext(LoginContext).loginStatus;
    const loc = useContext(LocalizationContext).localization;

    // Original Version here: CloudMixins.setLoggedIn(false)
    function logOut (e)
    {
        e.preventDefault();
        logoutContext();
        changeSideNav(true);
    }

    // TODO: Get Account Type from "Account Utils"
    return (
        <div className={styles.container}>
            <div className={`${styles.label}`}>Username</div>
            <div className={`${styles.infoText}`}>{userName !== "" ? userName : "---"}</div>
            <div className={`${styles.label}`}>Account Type</div>
            <div className={`${styles.infoText}`}>{AccountUtils.GetLoginStringFromLoginStatus(loginStatus)}</div>
            <div className={`${styles.label}`}>More</div>
            <a href="https://account.chessbase.com/en/me" target="_blank" rel="noopener noreferrer" className={`${styles.button} ${styles.register}`}>{loc.strings.ACCOUNT}</a>
            <div onClick={logOut} className={styles.button}>{loc.strings.LOGOUT}</div>
        </div>
    );
}