

import React, { useState, useContext } from 'react';
import styles from "./CBSettings.module.css";

import { CBSideNavButton } from "CBReact/Components/DesignElements/Buttons/SideNavButton/CBSideNavButton"
import { SIDENAV_RENDERMODES } from '../NavBars/CBNavBarSide';
import { ThemeContext, LocalizationContext, BoardThemeContext } from 'CBReact/Contexts/CBOnlineDBContext';
import { Language } from 'common/Tools/Language';
import { LocalizationUtils } from 'CBReact/Hooks/ReactContext/useLocalizationContext/LocalizationUtils';
import { DESIGN_THEMES } from 'CBReact/Hooks/ReactContext/useThemeContext';
import { PIECE_THEMES, BOARD_THEMES_SETTINGS } from 'CBReact/Hooks/ReactContext/useBoardThemeContext';

import { CookieUtils } from 'CBReact/Utils/CookieUtils';

const SETTINGSMODE = {
    basic: 0,
    language: 1,
    board: 2,
    theme: 3,
    pieces: 4,
} 

export function CBSettings (props)
{

    const [renderMode, setRenderMode] = useState(SETTINGSMODE.basic);

    const selectedTheme = useContext(ThemeContext).theme;
    const setTheme = useContext(ThemeContext).setTheme;

    const selectedBoardThemeSetting = useContext(BoardThemeContext).boardThemeSetting;
    const changeBoardTheme = useContext(BoardThemeContext).changeBoardTheme;
    const selectedPieceTheme = useContext(BoardThemeContext).pieceTheme;
    const changePieceTheme = useContext(BoardThemeContext).changePieceTheme;

    const loc = useContext(LocalizationContext).localization;
    const changeLocalization = useContext(LocalizationContext).changeLocalization;

    function setNewRenderMode (e, newRenderMode)
    {
        e.preventDefault();

        if (newRenderMode === renderMode)
            setRenderMode(SIDENAV_RENDERMODES.basic);
        else
            setRenderMode(newRenderMode);
    }

    return (
        <div className={styles.settingsContainer}>
            <div className={styles.label}>Settings</div>
            <CBSideNavButton 
                onClick={(e) => setNewRenderMode(e, SETTINGSMODE.language)} 
                color={"settingsColorHeader"}
                selected={renderMode === SETTINGSMODE.language ? true : false}>
                    {loc.strings.LANGUAGE}
            </CBSideNavButton>
            { renderMode === SETTINGSMODE.language && renderLanguage()}

            <CBSideNavButton 
                onClick={(e) => setNewRenderMode(e, SETTINGSMODE.board)} 
                color={"settingsColorHeader"}
                selected={renderMode === SETTINGSMODE.board ? true : false}>
                    {loc.strings.BOARD}
            </CBSideNavButton>
            { renderMode === SETTINGSMODE.board && renderBoard()}

            <CBSideNavButton 
                onClick={(e) => setNewRenderMode(e, SETTINGSMODE.pieces)} 
                color={"settingsColorHeader"}
                selected={renderMode === SETTINGSMODE.pieces ? true : false}>
                    {loc.strings.PIECES || "Pieces"}
            </CBSideNavButton>
            { renderMode === SETTINGSMODE.pieces && renderPieces()}

            <CBSideNavButton 
                onClick={(e) => setNewRenderMode(e, SETTINGSMODE.theme)} 
                color={"settingsColorHeader"}
                selected={renderMode === SETTINGSMODE.theme ? true : false}>
                    Theme
            </CBSideNavButton>
            { renderMode === SETTINGSMODE.theme && renderTheme()}
        </div>
    );

    function chooseLanguage (e, language)
    {
        e.preventDefault();
        changeLocalization(language);
    }

    function  renderLanguageButton (language, nativeString)
    {
        return (
            <CBSideNavButton key={language} selected={language === loc.language} onClick={(e) => chooseLanguage(e, language)} color={"settingsColorEntry"}>{nativeString}</CBSideNavButton>
        )
    }

    function renderLanguage ()
    {
        return (
            <div className={styles.inset}>
                {LocalizationUtils.GetAvailableLanguages().map(language => {    
                    var nativeString = Language.getNativeLangName(language);
                    return renderLanguageButton(language, nativeString);
                })}
            </div>
        );
    }

    function chooseBoard (e, boardThemeSetting)
    {
        e.preventDefault();

        // CookieUtils.SetCookie("CBBoardThemeSetting", boardThemeSetting);
        CookieUtils.SetStorageItem("CBBoardThemeSetting", boardThemeSetting);
        changeBoardTheme(boardThemeSetting);
    }

    function renderBoard ()
    {
        return(
            <div className={styles.inset}>
                <CBSideNavButton onClick={(e) => chooseBoard(e, BOARD_THEMES_SETTINGS.clean)} selected={selectedBoardThemeSetting === BOARD_THEMES_SETTINGS.clean} color={"settingsColorEntry"}>Clean</CBSideNavButton>
                <CBSideNavButton onClick={(e) => chooseBoard(e, BOARD_THEMES_SETTINGS.blue)} selected={selectedBoardThemeSetting === BOARD_THEMES_SETTINGS.blue} color={"settingsColorEntry"}>Blue</CBSideNavButton>
                <CBSideNavButton onClick={(e) => chooseBoard(e, BOARD_THEMES_SETTINGS.wood)} selected={selectedBoardThemeSetting === BOARD_THEMES_SETTINGS.wood} color={"settingsColorEntry"}>Wood</CBSideNavButton>
            </div>
        )
    }

    function choosePieces (e, pieceTheme)
    {
        e.preventDefault();

        //CookieUtils.SetCookie("CBPieces", pieceTheme);
        CookieUtils.SetStorageItem("CBPieces", pieceTheme);
        changePieceTheme(pieceTheme);
    }

    function renderPieces ()
    {
        return(
            <div className={styles.inset}>
                <CBSideNavButton onClick={(e) => choosePieces(e, PIECE_THEMES.fritz)} selected={selectedPieceTheme === PIECE_THEMES.fritz} color={"settingsColorEntry"}>{loc.strings.BOARD_FRITZ}</CBSideNavButton>
                <CBSideNavButton onClick={(e) => choosePieces(e, PIECE_THEMES.crystals)} selected={selectedPieceTheme === PIECE_THEMES.crystals} color={"settingsColorEntry"}>{loc.strings.BOARD_CRYSTALS}</CBSideNavButton>
                <CBSideNavButton onClick={(e) => choosePieces(e, PIECE_THEMES.uscf)} selected={selectedPieceTheme === PIECE_THEMES.uscf} color={"settingsColorEntry"}>{loc.strings.BOARD_USCF}</CBSideNavButton>
                <CBSideNavButton onClick={(e) => choosePieces(e, PIECE_THEMES.oldstyle)} selected={selectedPieceTheme === PIECE_THEMES.oldstyle} color={"settingsColorEntry"}>{loc.strings.BOARD_OLDSTYLE}</CBSideNavButton>
            </div>
        )
    }

    function chooseTheme (e, theme)
    {
        e.preventDefault();

        if (theme !== selectedTheme)
        {
            setTheme(theme);
            // CookieUtils.SetCookie("CBTheme", theme);
            CookieUtils.SetStorageItem("CBTheme", theme);
        }
    }

    function renderTheme ()
    {
        return(
            <div className={styles.inset}>
                <CBSideNavButton    onClick={(e) => chooseTheme(e, DESIGN_THEMES.light)} 
                                    color={"settingsColorEntry"}
                                    selected={selectedTheme === DESIGN_THEMES.light ? true : false}>
                                        Light
                </CBSideNavButton>
                <CBSideNavButton    onClick={(e) => chooseTheme(e, DESIGN_THEMES.dark)} 
                                    color={"settingsColorEntry"} 
                                    selected={selectedTheme === DESIGN_THEMES.dark ? true : false}>
                                        Dark Mode
                </CBSideNavButton>
            </div>
        )
    }
}