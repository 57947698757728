import { SIDENAV_RENDERMODES } from "CBReact/Components/NavBars/CBNavBarSide";
import { useRef, useState } from "react";

import ReactGA from 'react-ga';

export function useSideNavContext ()
{
    const [sideNavRenderMode, setSideNavRenderMode] = useState(SIDENAV_RENDERMODES.main);
    const [sideNavIsOpen, setsideNavIsOpen] = useState(false);
    const sideNavIsOpenRef = useRef();
    sideNavIsOpenRef.current = sideNavIsOpen;

    function changeSideNav (toggle, renderMode)
    {
        if (renderMode !== null && renderMode !== sideNavRenderMode && !(toggle && sideNavIsOpenRef.current))
            setSideNavRenderMode(renderMode);
        
        if (toggle)
            setsideNavIsOpen(!sideNavIsOpenRef.current);

        if (sideNavIsOpenRef.current === false)
        {
            ReactGA.event({
                category: 'Navigation',
                action: 'Opened SideBar',
            });
        }

    }

    return {sideNavIsOpen, sideNavRenderMode, changeSideNav};
    // return [sideNavIsOpen, sideNavRenderMode, changeSideNav];
}