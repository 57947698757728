import React, { useContext, useEffect, useRef, useState } from 'react';
import styles from "./CBChessPiece.module.css";

import { checkPropsAndRenderComponent } from 'CBReact/Utils/DebugUtils';
import { usePrevious } from 'CBReact/Hooks/usePrevious';
import { BoardThemeContext } from 'CBReact/Contexts/CBOnlineDBContext';
import { PIECE_THEMES } from "CBReact/Hooks/ReactContext/useBoardThemeContext";
import { Square } from 'common/Chess/Logic/Chess'

// TODO: evtl. unterschiedliche Callbacks je nach Spielmodus in einer anderen Datei implementieren
// Dann basierend auf Spielmodus die Callbacks auswählen

export function CBChessPiece (props)
{
    
    const pieceTheme = useContext(BoardThemeContext).pieceTheme;
    const getPieceImageCss = useContext(BoardThemeContext).getPieceImageCss;

    const [inDrag, setInDrag] = useState(false);

    const pieceRef = useRef();
    const pieceIndexRef = useRef(props.pieceIndex);
    const startPositionRef = useRef();
    const selectedSquareRef = useRef(-1);

    const prevSquareIndex = usePrevious(props.squareIndex);
    const prevShowPiece = usePrevious(props.showPiece);
    const prevPieceTheme = usePrevious(pieceTheme);

    function changeInlineTransform (speed, translate, scale)
    {
        if (pieceRef.current && translate)
        {
            pieceRef.current.style.transition = `transform ${speed}s`;
            pieceRef.current.style.transform = `translate(${translate.x}px, ${translate.y}px) scale(${scale})`;
        }
    }

    useEffect(() => {
        if (props.showPiece && pieceTheme != PIECE_THEMES.preload)
            changeInlineTransform (0.0, Square.getTranslate(props.squareIndex, props.squareSize, props.whiteIsBottom), 1.0);
    }, [props.squareSize])

    useEffect(() => {
        if (props.pieceIndex !== -1)
            pieceIndexRef.current = props.pieceIndex;
    }, [props.pieceIndex])

    useEffect(() => {
        if (pieceTheme === PIECE_THEMES.preload || !pieceRef.current)
            return;

        // Move then Scale up
        if ((props.showPiece && pieceTheme !== prevPieceTheme) || (props.showPiece && !prevShowPiece))
        {
            changeInlineTransform(0.0, Square.getTranslate(props.squareIndex, props.squareSize, props.whiteIsBottom), 0.0);
            // Double Request Animation frame, because one does not seem to always wait for the next frame
            window.requestAnimationFrame(() => {window.requestAnimationFrame(() => {changeInlineTransform(0.25, Square.getTranslate(props.squareIndex, props.squareSize, props.whiteIsBottom), 1.0);})});
        }
        // Scale Down
        else if (!props.showPiece && prevSquareIndex != undefined)
            changeInlineTransform(0.25, Square.getTranslate(prevSquareIndex, props.squareSize, props.whiteIsBottom), 0.0);
        // Translate
        else if (props.showPiece && prevSquareIndex != undefined)
            changeInlineTransform(0.25, Square.getTranslate(props.squareIndex, props.squareSize, props.whiteIsBottom), 1.0);

    }, [pieceTheme, props.showPiece, props.squareIndex]);

    //#region Handle Piece Drag And Drop 

    function getDragPosition (e)
    {
        return e.touches ? {x: e.touches[0].clientX, y: e.touches[0].clientY} : {x: e.clientX, y: e.clientY};
    }

    function startDrag (e)
    {
        e.preventDefault();
        e.stopPropagation();

        let currentPosition = getDragPosition(e);

        let rect = e.target.getBoundingClientRect();
        let pieceCenter = {x: rect.left + rect.width * 0.5, y: rect.top + rect.height * 0.5};
        let offset = {x: currentPosition.x - pieceCenter.x, y: currentPosition.y - pieceCenter.y};
        let translate = Square.getTranslate(props.squareIndex, props.squareSize, props.whiteIsBottom);

        startPositionRef.current = {x: currentPosition.x - offset.x - translate.x, y: currentPosition.y - offset.y - translate.y};
        let newTranslate = {x: currentPosition.x - startPositionRef.current.x, y: currentPosition.y - startPositionRef.current.y};

        changeInlineTransform(0.0, {x: newTranslate.x, y: newTranslate.y}, 1.0);

        window.addEventListener('mousemove', onDrag, { passive: false });
        window.addEventListener('mouseup', endDrag, { passive: false });
        window.addEventListener('touchmove', onDrag, { passive: false });
        window.addEventListener('touchend', endDrag, { passive: false });

        setInDrag(true);
        props.boardInDragRef.current = true;
    }

    // TODO: Check Element from Point Performance. Change to custom solution if bad.
    // Oder: Element from point einfach nur bei MouseUp aufrufen
    function onDrag (e)
    {
        e.preventDefault();
        e.stopPropagation();
        
        if (startPositionRef.current)
        {
            let currentPosition = getDragPosition(e);
            let newTranslate = {x: currentPosition.x - startPositionRef.current.x, y: currentPosition.y - startPositionRef.current.y};

            changeInlineTransform(0.0, {x: newTranslate.x, y: newTranslate.y}, 1.0);

            // Get Current Square that the mouse is hovering over
            let hoverElement = document.elementFromPoint(currentPosition.x, currentPosition.y);
            let newSquare = hoverElement ? parseInt(hoverElement.getAttribute("squareindex")) : -1;
            if (isNaN(newSquare))
                newSquare = -1;

            if (newSquare !== selectedSquareRef.current && newSquare >= 0 && newSquare <= 63)
            {
                selectedSquareRef.current = newSquare;
                props.setSelectedSquare(newSquare);
            }
            else if (newSquare === -1 && selectedSquareRef.current !== -1)
            {
                selectedSquareRef.current = -1;
                props.setSelectedSquare(-1);
            }
        }
    }

    function endDrag (e)
    {
        e.preventDefault();
        e.stopPropagation();

        window.removeEventListener('mousemove', onDrag, { passive: false });
        window.removeEventListener('mouseup', endDrag, { passive: false });
        window.removeEventListener('touchmove', onDrag, { passive: false });
        window.removeEventListener('touchend', endDrag, { passive: false });

        let moveInfo = {};

        if (selectedSquareRef.current !== -1 && selectedSquareRef.current >= 0 && selectedSquareRef.current <= 63)
            moveInfo = props.handlePieceDropped(props.squareIndex, selectedSquareRef.current);
        
        if (!moveInfo.isLegalMove)
            changeInlineTransform(0.25, Square.getTranslate(props.squareIndex, props.squareSize, props.whiteIsBottom), 1.0);

        setInDrag(false);
        props.boardInDragRef.current = false;
        selectedSquareRef.current = -1;
        props.setSelectedSquare(-1);
    }

    //#endregion

    function renderCBChessPiece ()
    {
        return (
            <div 
                ref={pieceRef}
                squareindex={props.squareIndex}
                className={`${styles.piece} ${getPieceImageCss(pieceIndexRef.current)} ${inDrag ? styles.inDrag : ""} ${props.boardIsShowingPromotion ? styles.inactive : ""}`}
                onMouseDown={startDrag}
                onTouchStart={startDrag}
            />
        )
    }

    return checkPropsAndRenderComponent([props.boardIsShowingPromotion, props.handlePieceDropped, props.pieceIndex, props.squareIndex, props.squareSize, props.whiteIsBottom, props.setSelectedSquare, props.showPiece, props.boardInDragRef], renderCBChessPiece);
}

function chessPiecePropsAreEqual (prev, next)
{
    return prev.handlePieceDropped === next.handlePieceDropped && 
            prev.pieceIndex === next.pieceIndex &&
            prev.showPiece === next.showPiece &&
            prev.squareIndex === next.squareIndex &&
            prev.squareSize === next.squareSize &&
            prev.boardIsShowingPromotion === next.boardIsShowingPromotion;
}

export const CBMChessPiece = React.memo(CBChessPiece, chessPiecePropsAreEqual);