import React, { useContext, useState, useEffect, useRef, useCallback } from 'react';
import styles from "./CBAutoComplete.module.css";

import { checkPropsAndRenderComponent } from 'CBReact/Utils/DebugUtils';
import { OnlineLobbyContext } from 'CBReact/Contexts/CBOnlineDBContext';
import { useAfterMountEffect } from 'CBReact/Hooks/useAfterMountEffect';

export function CBAutoComplete (props)
{

    const requestAutoComplete = useContext(OnlineLobbyContext).requestAutoComplete;

    const [autoCompleteList, setAutoCompleteList] = useState([]);
    const [selectionIndex, setSelectionIndex] = useState(-1);

    const selectionRef = useCallback(node => {scrollToAutoCompleteElement(node)},[]);
    const containerRef = useRef();

    const handleKeyDown = useCallback ((e) => 
    {
        if (autoCompleteList.length > 0)
        {
            // Arrow Up
            if (e.keyCode === 38) {
                e.preventDefault();
                setSelectionIndex(selectionIndex < 1 ? autoCompleteList.length - 1 : selectionIndex - 1);
            }
            // Arrow Down
            else if (e.keyCode === 40) 
            {
                e.preventDefault();
                setSelectionIndex((selectionIndex + 1) % autoCompleteList.length);
            }
            // Enter
            else if (e.keyCode === 13 && selectionIndex >= 0 && selectionIndex <= autoCompleteList.length)
            {
                e.preventDefault();
                props.handleSubmit(autoCompleteList[selectionIndex]);
            }
        }
    }, [selectionIndex, autoCompleteList]);

    const handleClick = useCallback ((e) =>
    {
        if (autoCompleteList.length > 0 && containerRef.current && !containerRef.current.contains(e.target))
            setAutoCompleteList([]);   
    }, [autoCompleteList]);

    useEffect(() => {
        document.addEventListener("keydown", handleKeyDown);
        document.addEventListener("mousedown", handleClick);
        return (() => {
            document.removeEventListener("keydown", handleKeyDown);
            document.removeEventListener("mousedown", handleClick);
        });
    }, [handleKeyDown, handleClick]);

    useAfterMountEffect(() => {
        if (props.inputData.text.length >= 3 && props.inputData.requestAutoComplete)
            requestAutoComplete(props.inputData.text, handleReceiveAutoComplete);
        else if (autoCompleteList.length > 0 && !props.inputData.keepAutoComplete)
        {
            setAutoCompleteList([]);
            setSelectionIndex(-1);
        }
    }, [props.inputData]);

    useEffect(() => {
        if (selectionIndex >= 0 && selectionIndex < autoCompleteList.length)
            props.handleSelection(autoCompleteList[selectionIndex]);
    }, [selectionIndex]);

    function scrollToAutoCompleteElement (node)
    {
        if (containerRef.current && node != null)
            containerRef.current.scrollTop = node.offsetTop;
    }

    function handleReceiveAutoComplete (autoComplete)
    {
        if (autoComplete.data.length > 0)
        {
            setAutoCompleteList(autoComplete.data);
            setSelectionIndex(-1);
        }
    }

    function renderElement (element, index)
    {
        return (
            <div 
                className={`${styles.option} ${index === selectionIndex ? styles.selected : ""} ${props.quickSearchIndent ? styles.quickSearch : ""}`}
                key={index}
                ref={index === selectionIndex ? selectionRef : null}
                onMouseDown={(e) => {e.preventDefault(); props.handleSubmit(element); }}>
                    {element}
            </div>
        )
    }

    function renderCBAutoComplete ()
    {
        return (
            <div className={styles.autoComplete} ref={containerRef}>
                {autoCompleteList.map(renderElement)}
            </div>
        )
    }

    return checkPropsAndRenderComponent([props.inputData, props.handleSubmit, props.handleSelection], renderCBAutoComplete);
}