// ES6 3.3.2020

import { Piece } from 'common/Chess/Logic/Chess';
import { ObjUtil } from 'common/Patterns/ObjectUtil';
import { InvariantLocalization, NotationLocalizations } from 'common/Chess/Logic/Notation'

export var SpecialGlyphs = {
	//PGN-Symbole

	NONE: 0,//null annotation
	GOOD_MOVE: 1, 	//good move (traditional "!")
	BAD_MOVE: 2, 	//poor move (traditional "?")
	VERY_GOOD_MOVE: 3, 	//very good move (traditional "!!")
	VERY_BAD_MOVE: 4, 	//very poor move (traditional "??")
	SPECULATIVE_MOVE: 5, 	//speculative move (traditional "!?")
	DUBIOUS_MOVE: 6, 	//questionable move (traditional "?!")
	FORCED_MOVE: 7, 	//forced move (all others lose quickly)
	SINGULAR_MOVE: 8, 	//singular move (no reasonable alternatives)
	WORST_MOVE: 9, 	//worst move
	DRAWN_POS: 10, 	//drawish position
	EQUAL_QUIET_POS: 11, 	//equal chances, quiet position
	EQUAL_ACTIVE_POS: 12, 	//equal chances, active position
	UNCLEAR_POS: 13, 	//unclear position
	W_SLIGHT_ADV: 14, 	//White has a slight advantage
	B_SLIGHT_ADV: 15, 	//Black has a slight advantage
	W_MODER_ADV: 16, 	//White has a moderate advantage
	B_MODER_ADV: 17, 	//Black has a moderate advantage
	W_DECIS_ADV: 18, 	//White has a decisive advantage
	B_DECIS_ADV: 19, 	//Black has a decisive advantage
	W_CRASH_ADV: 20, 	//White has a crushing advantage (Black should resign)
	B_CRASH_ADV: 21, 	//Black has a crushing advantage (White should resign)
	W_ZUGZW: 22, 	//White is in zugzwang
	B_ZUGZW: 23, 	//Black is in zugzwang
	W_SLIGHT_SPC: 24, 	//White has a slight space advantage
	B_SLIGHT_SPC: 25, 	//Black has a slight space advantage
	W_MODER_SPC: 26, 	//White has a moderate space advantage
	B_MODER_SPC: 27, 	//Black has a moderate space advantage
	W_DECIS_SPC: 28, 	//White has a decisive space advantage
	B_DECIS_SPC: 29, 	//Black has a decisive space advantage
	W_SLIGHT_TEMP: 30, 	//White has a slight time (development) advantage
	B_SLIGHT_TEMP: 31, 	//Black has a slight time (development) advantage
	W_MODER_TEMP: 32, 	//White has a moderate time (development) advantage
	B_MODER_TEMP: 33, 	//Black has a moderate time (development) advantage
	W_DECIS_TEMP: 34, 	//White has a decisive time (development) advantage
	B_DECIS_TEMP: 35, 	//Black has a decisive time (development) advantage
	W_INITIATIVE: 36, 	//White has the initiative
	B_INITIATIVE: 37, 	//Black has the initiative
	W_LASTING_INITIATIVE: 38, 	//White has a lasting initiative
	B_LASTING_INITIATIVE: 39, 	//Black has a lasting initiative
	W_ATTACK: 40, 	//White has the attack
	B_ATTACK: 41, 	//Black has the attack
	W_INSUFF_COMP: 42, 	//White has insufficient compensation for material deficit
	B_INSUFF_COMP: 43, 	//Black has insufficient compensation for material deficit
	W_SUFF_COMP: 44, 	//White has sufficient compensation for material deficit
	B_SUFF_COMP: 45, 	//Black has sufficient compensation for material deficit
	W_GOOD_COMP: 46, 	//White has more than adequate compensation for material deficit
	B_GOOD_COMP: 47, 	//Black has more than adequate compensation for material deficit
	W_SLIGHT_CENTER: 48, 	//White has a slight center control advantage
	B_SLIGHT_CENTER: 49, 	//Black has a slight center control advantage
	W_MODER_CENTER: 50, 	//White has a moderate center control advantage
	B_MODER_CENTER: 51, 	//Black has a moderate center control advantage
	W_DECIS_CENTER: 52, 	//White has a decisive center control advantage
	B_DECIS_CENTER: 53, 	//Black has a decisive center control advantage
	W_SLIGHT_KS: 54, 	//White has a slight kingside control advantage
	B_SLIGHT_KS: 55, 	//Black has a slight kingside control advantage
	W_MODER_KS: 56, 	//White has a moderate kingside control advantage
	B_MODER_KS: 57, 	//Black has a moderate kingside control advantage
	W_DECIS_KS: 58, 	//White has a decisive kingside control advantage
	B_DECIS_KS: 59, 	//Black has a decisive kingside control advantage
	W_SLIGHT_QS: 60, 	//White has a slight queenside control advantage
	B_SLIGHT_QS: 61, 	//Black has a slight queenside control advantage
	W_MODER_QS: 62, 	//White has a moderate queenside control advantage
	B_MODER_QS: 63, 	//Black has a moderate queenside control advantage
	W_DECIS_QS: 64, 	//White has a decisive queenside control advantage
	B_DECIS_QS: 65, 	//Black has a decisive queenside control advantage
	W_BAD_FRST_RANK: 66, 	//White has a vulnerable first rank
	B_BAD_FRST_RANK: 67, 	//Black has a vulnerable first rank
	W_GOOD_FRST_RANK: 68, 	//White has a well protected first rank
	B_GOOD_FRST_RANK: 69, 	//Black has a well protected first rank
	W_BAD_PROTECTED_KING: 70, //White has a poorly protected king
	B_BAD_PROTECTED_KING: 71, //Black has a poorly protected king
	W_GOOD_PROTECTED_KING: 72, //White has a well protected king
	B_GOOD_PROTECTED_KING: 73, //Black has a well protected king
	W_BAD_PLACED_KING: 74, 	//White has a poorly placed king
	B_BAD_PLACED_KING: 75, 	//Black has a poorly placed king
	W_GOOD_PLACED_KING: 76, 	//White has a well placed king
	B_GOOD_PLACED_KING: 77, 	//Black has a well placed king
	W_VERY_WEAK_PAWNS: 78, 	//White has a very weak pawn structure
	B_VERY_WEAK_PAWNS: 79, 	//Black has a very weak pawn structure
	W_MODER_WEAK_PAWNS: 80, 	//White has a moderately weak pawn structure
	B_MODER_WEAK_PAWNS: 81, 	//Black has a moderately weak pawn structure
	W_MODER_STRONG_PAWNS: 82, 	//White has a moderately strong pawn structure
	B_MODER_STRONG_PAWNS: 83, 	//Black has a moderately strong pawn structure
	W_VERY_STRONG_PAWNS: 84, 	//White has a very strong pawn structure
	B_VERY_STRONG_PAWNS: 85, 	//Black has a very strong pawn structure
	W_BAD_KNIGHT: 86, 	//White has poor knight placement
	B_BAD_KNIGHT: 87, 	//Black has poor knight placement
	W_GOOD_KNIGHT: 88, 	//White has good knight placement
	B_GOOD_KNIGHT: 89, 	//Black has good knight placement
	W_BAD_BISHOP: 90, 	//White has poor bishop placement
	B_BAD_BISHOP: 91, 	//Black has poor bishop placement
	W_GOOD_BISHOP: 92, 	//White has good bishop placement
	B_GOOD_BISHOP: 93, 	//Black has good bishop placement
	W_BAD_ROOK: 94, 	//White has poor rook placement
	B_BAD_ROOK: 95, 	//Black has poor rook placement
	W_GOOD_ROOK: 96, 	//White has good rook placement
	B_GOOD_ROOK: 97, 	//Black has good rook placement
	W_BAD_QUEEN: 98, 	//White has poor queen placement
	B_BAD_QUEEN: 99, 	//Black has poor queen placement
	W_GOOD_QUEEN: 100, 	//White has good queen placement
	B_GOOD_QUEEN: 101, 	//Black has good queen placement
	W_BAD_PIECES: 102, 	//White has poor piece coordination
	B_BAD_PIECES: 103, 	//Black has poor piece coordination
	W_GOOD_PIECES: 104, 	//White has good piece coordination
	B_GOOD_PIECES: 105, 	//Black has good piece coordination
	W_OP_VERY_BAD: 106, 	//White has played the opening very poorly
	B_OP_VERY_BAD: 107, 	//Black has played the opening very poorly
	W_OP_BAD: 108, 	//White has played the opening poorly
	B_OP_BAD: 109, 	//Black has played the opening poorly
	W_OP_GOOD: 110, 	//White has played the opening well
	B_OP_GOOD: 111, 	//Black has played the opening well
	W_OP_VERY_GOOD: 112, 	//White has played the opening very well
	B_OP_VERY_GOOD: 113, 	//Black has played the opening very well
	W_MG_VERY_BAD: 114, 	//White has played the middlegame very poorly
	B_MG_VERY_BAD: 115, 	//Black has played the middlegame very poorly
	W_MG_BAD: 116, 	//White has played the middlegame poorly
	B_MG_BAD: 117, 	//Black has played the middlegame poorly
	W_MG_GOOD: 118, 	//White has played the middlegame well
	B_MG_GOOD: 119, 	//Black has played the middlegame well
	W_MG_VERY_GOOD: 120, 	//White has played the middlegame very well
	B_MG_VERY_GOOD: 121, 	//Black has played the middlegame very well
	W_EG_VERY_BAD: 122, 	//White has played the ending very poorly
	B_EG_VERY_BAD: 123, 	//Black has played the ending very poorly
	W_EG_BAD: 124, 	//White has played the ending poorly
	B_EG_BAD: 125, 	//Black has played the ending poorly
	W_EG_GOOD: 126, 	//White has played the ending well
	B_EG_GOOD: 127, 	//Black has played the ending well
	W_EG_VERY_GOOD: 128, 	//White has played the ending very well
	B_EG_VERY_GOOD: 129, 	//Black has played the ending very well
	W_SLIGHT_COUNTER: 130, 	//White has slight counterplay
	B_SLIGHT_COUNTER: 131, 	//Black has slight counterplay
	W_MODER_COUNTER: 132, 	//White has moderate counterplay
	B_MODER_COUNTER: 133, 	//Black has moderate counterplay
	W_DECIS_COUNTER: 134, 	//White has decisive counterplay
	B_DECIS_COUNTER: 135, 	//Black has decisive counterplay
	W_MODER_ZEITNOT: 136, 	//White has moderate time control pressure
	B_MODER_ZEITNOT: 137, 	//Black has moderate time control pressure
	W_SEVERE_ZEITNOT: 138, 	//White has severe time control pressure
	B_SEVERE_ZEITNOT: 139, 	//Black has severe time control pressure

	//Fritz-Symbole
	WITH_IDEA: 140,			//with the idea",												//	140
	AIMED_AGAINST: 141,			//aimed against",
	BETTER_IS: 142,				//better is",                                     	//	>:
	WORSE_IS: 143,				//worse is",     												//	<:
	EQUAL_IS: 144,				//equivalent is"												//	:
	EDITORS_MARK: 145,			//editors remark",	//	RR

	NOVELTY: 146,				//novelty",														//	N
	WEAK_POINT: 147,				//weak point",													//	147
	ENDGAME: 148,				//endgame",
	LINE: 149,					//line",
	DIAGONAL: 150,				//diagonal",
	//	150
	W_BISHOP_PAIR: 151,		//White has the pair of bishops",                   //	151
	B_BISHOP_PAIR: 152,		//Black has the pair of bishops",
	OPP_COLOR_BISHOPS: 153,		//bishops of opposite colour",
	SAME_COLOR_BISHOPS: 154,		//bishops of the same colour",
	W_UNITED_PAWNS: 155,		//White has united pawns",
	B_UNITED_PAWNS: 156,		//Black has united pawns",
	W_SEPARATE_PAWNS: 157,		//White has separated pawns",
	B_SEPARATE_PAWNS: 158,		//Black has separated pawns",
	W_DOUBLE_PAWNS: 159,		//White has double pawns",
	B_DOUBLE_PAWNS: 160,		//Black has double pawns",                         	//	160
	W_PASSED_PAWNS: 161,		//White has a passed pawn",                         //	161
	B_PASSED_PAWNS: 162,		//Black has a passed pawn",
	W_MORE_PAWNS: 163,		//White has an advantage in number of pawns",
	B_MORE_PAWNS: 164,		//Black has an advantage in number of pawns"			//	164

	// not an eval. They belong to marks, because they need to be combined with evaluations
	MY_MOVE: 165,
	IMPORTANT: 166,
	WHITE: 167,
	BLACK: 168,
	BOTH: 169,
};

export var GlyphTexts = {};

GlyphTexts[SpecialGlyphs.NONE] = "";
GlyphTexts[SpecialGlyphs.GOOD_MOVE] = "!";
GlyphTexts[SpecialGlyphs.BAD_MOVE] = "?";
GlyphTexts[SpecialGlyphs.VERY_GOOD_MOVE] = "\u203C";
GlyphTexts[SpecialGlyphs.VERY_BAD_MOVE] = "??";
GlyphTexts[SpecialGlyphs.SPECULATIVE_MOVE] = "!?";
GlyphTexts[SpecialGlyphs.DUBIOUS_MOVE] = "?!";
GlyphTexts[SpecialGlyphs.FORCED_MOVE] = "!";
GlyphTexts[SpecialGlyphs.SINGULAR_MOVE] = "\uE021";
GlyphTexts[SpecialGlyphs.WORST_MOVE] = "";
GlyphTexts[SpecialGlyphs.DRAWN_POS] = "=";
GlyphTexts[SpecialGlyphs.EQUAL_QUIET_POS] = "=";
GlyphTexts[SpecialGlyphs.EQUAL_ACTIVE_POS] = "=";
GlyphTexts[SpecialGlyphs.UNCLEAR_POS] = "\u221E";
GlyphTexts[SpecialGlyphs.W_SLIGHT_ADV] = "\uE02F";
GlyphTexts[SpecialGlyphs.B_SLIGHT_ADV] = "\uE02E";
GlyphTexts[SpecialGlyphs.W_MODER_ADV] = "\u00B1";
GlyphTexts[SpecialGlyphs.B_MODER_ADV] = "\uE01A";
GlyphTexts[SpecialGlyphs.W_DECIS_ADV] = "+-";
GlyphTexts[SpecialGlyphs.B_DECIS_ADV] = "-+";
GlyphTexts[SpecialGlyphs.W_CRASH_ADV] = "+-";
GlyphTexts[SpecialGlyphs.B_CRASH_ADV] = "-+";
GlyphTexts[SpecialGlyphs.W_ZUGZW] = "\uE01D";
GlyphTexts[SpecialGlyphs.B_ZUGZW] = "\uE01D";
GlyphTexts[SpecialGlyphs.W_SLIGHT_SPC] = "\uE023";
GlyphTexts[SpecialGlyphs.B_SLIGHT_SPC] = "\uE023";
GlyphTexts[SpecialGlyphs.W_MODER_SPC] = "\uE023";
GlyphTexts[SpecialGlyphs.B_MODER_SPC] = "\uE023";
GlyphTexts[SpecialGlyphs.W_DECIS_SPC] = "\uE023";
GlyphTexts[SpecialGlyphs.B_DECIS_SPC] = "\uE023";
GlyphTexts[SpecialGlyphs.W_SLIGHT_TEMP] = "\uE02D";
GlyphTexts[SpecialGlyphs.B_SLIGHT_TEMP] = "\uE02D";
GlyphTexts[SpecialGlyphs.W_MODER_TEMP] = "\uE02D";
GlyphTexts[SpecialGlyphs.B_MODER_TEMP] = "\uE02D";
GlyphTexts[SpecialGlyphs.W_DECIS_TEMP] = "\uE02D";
GlyphTexts[SpecialGlyphs.B_DECIS_TEMP] = "\uE02D";
GlyphTexts[SpecialGlyphs.W_INITIATIVE] = "\uE012";
GlyphTexts[SpecialGlyphs.B_INITIATIVE] = "\uE012";
GlyphTexts[SpecialGlyphs.W_LASTING_INITIATIVE] = "\uE012";
GlyphTexts[SpecialGlyphs.B_LASTING_INITIATIVE] = "\uE012";
GlyphTexts[SpecialGlyphs.W_ATTACK] = "\uE013";
GlyphTexts[SpecialGlyphs.B_ATTACK] = "\uE013";
GlyphTexts[SpecialGlyphs.W_INSUFF_COMP] = "\uE004\uE000";
GlyphTexts[SpecialGlyphs.B_INSUFF_COMP] = "\uE004\uE000";
GlyphTexts[SpecialGlyphs.W_SUFF_COMP] = "\uE000";
GlyphTexts[SpecialGlyphs.B_SUFF_COMP] = "\uE000";
GlyphTexts[SpecialGlyphs.W_GOOD_COMP] = "\uE000";
GlyphTexts[SpecialGlyphs.B_GOOD_COMP] = "\uE000";
GlyphTexts[SpecialGlyphs.W_SLIGHT_CENTER] = "\uE01E";
GlyphTexts[SpecialGlyphs.B_SLIGHT_CENTER] = "\uE01E";
GlyphTexts[SpecialGlyphs.W_MODER_CENTER] = "\uE01E";
GlyphTexts[SpecialGlyphs.B_MODER_CENTER] = "\uE01E";
GlyphTexts[SpecialGlyphs.W_DECIS_CENTER] = "\uE01E";
GlyphTexts[SpecialGlyphs.B_DECIS_CENTER] = "\uE01E";
GlyphTexts[SpecialGlyphs.W_SLIGHT_KS] = "\uE00F";
GlyphTexts[SpecialGlyphs.B_SLIGHT_KS] = "\uE00F";
GlyphTexts[SpecialGlyphs.W_MODER_KS] = "\uE00F";
GlyphTexts[SpecialGlyphs.B_MODER_KS] = "\uE00F";
GlyphTexts[SpecialGlyphs.W_DECIS_KS] = "\uE00F";
GlyphTexts[SpecialGlyphs.B_DECIS_KS] = "\uE00F";
GlyphTexts[SpecialGlyphs.W_SLIGHT_QS] = "\uE00E";
GlyphTexts[SpecialGlyphs.B_SLIGHT_QS] = "\uE00E";
GlyphTexts[SpecialGlyphs.W_MODER_QS] = "\uE00E";
GlyphTexts[SpecialGlyphs.B_MODER_QS] = "\uE00E";
GlyphTexts[SpecialGlyphs.W_DECIS_QS] = "\uE00E";
GlyphTexts[SpecialGlyphs.B_DECIS_QS] = "\uE00E";
GlyphTexts[SpecialGlyphs.W_BAD_FRST_RANK] = "\uE010\uE018\u0031";
GlyphTexts[SpecialGlyphs.B_BAD_FRST_RANK] = "\uE010\uE018\u0038";
GlyphTexts[SpecialGlyphs.W_GOOD_FRST_RANK] = "";
GlyphTexts[SpecialGlyphs.B_GOOD_FRST_RANK] = "";
GlyphTexts[SpecialGlyphs.W_BAD_PROTECTED_KING] = "\uE010\uE024";
GlyphTexts[SpecialGlyphs.B_BAD_PROTECTED_KING] = "\uE010\uE024";
GlyphTexts[SpecialGlyphs.W_GOOD_PROTECTED_KING] = "";
GlyphTexts[SpecialGlyphs.B_GOOD_PROTECTED_KING] = "";
GlyphTexts[SpecialGlyphs.W_BAD_PLACED_KING] = "\uE010\uE024";
GlyphTexts[SpecialGlyphs.B_BAD_PLACED_KING] = "\uE010\uE024";
GlyphTexts[SpecialGlyphs.W_GOOD_PLACED_KING] = "";
GlyphTexts[SpecialGlyphs.B_GOOD_PLACED_KING] = "";
GlyphTexts[SpecialGlyphs.W_VERY_WEAK_PAWNS] = "\uE010\uE029";
GlyphTexts[SpecialGlyphs.B_VERY_WEAK_PAWNS] = "\uE010\uE029";
GlyphTexts[SpecialGlyphs.W_MODER_WEAK_PAWNS] = "\uE010\uE029";
GlyphTexts[SpecialGlyphs.B_MODER_WEAK_PAWNS] = "\uE010\uE029";
GlyphTexts[SpecialGlyphs.W_MODER_STRONG_PAWNS] = "";
GlyphTexts[SpecialGlyphs.B_MODER_STRONG_PAWNS] = "";
GlyphTexts[SpecialGlyphs.W_VERY_STRONG_PAWNS] = "";
GlyphTexts[SpecialGlyphs.B_VERY_STRONG_PAWNS] = "";
GlyphTexts[SpecialGlyphs.W_BAD_KNIGHT] = "\uE010\uE028";
GlyphTexts[SpecialGlyphs.B_BAD_KNIGHT] = "\uE010\uE028";
GlyphTexts[SpecialGlyphs.W_GOOD_KNIGHT] = "";
GlyphTexts[SpecialGlyphs.B_GOOD_KNIGHT] = "";
GlyphTexts[SpecialGlyphs.W_BAD_BISHOP] = "\uE010\uE027";
GlyphTexts[SpecialGlyphs.B_BAD_BISHOP] = "\uE010\uE027";
GlyphTexts[SpecialGlyphs.W_GOOD_BISHOP] = "";
GlyphTexts[SpecialGlyphs.B_GOOD_BISHOP] = "";
GlyphTexts[SpecialGlyphs.W_BAD_ROOK] = "\uE010\uE026";
GlyphTexts[SpecialGlyphs.B_BAD_ROOK] = "\uE010\uE026";
GlyphTexts[SpecialGlyphs.W_GOOD_ROOK] = "";
GlyphTexts[SpecialGlyphs.B_GOOD_ROOK] = "";
GlyphTexts[SpecialGlyphs.W_BAD_QUEEN] = "\uE010\uE025";
GlyphTexts[SpecialGlyphs.B_BAD_QUEEN] = "\uE010\uE025";
GlyphTexts[SpecialGlyphs.W_GOOD_QUEEN] = "";
GlyphTexts[SpecialGlyphs.B_GOOD_QUEEN] = "";
GlyphTexts[SpecialGlyphs.W_BAD_PIECES] = "";
GlyphTexts[SpecialGlyphs.B_BAD_PIECES] = "";
GlyphTexts[SpecialGlyphs.W_GOOD_PIECES] = "";
GlyphTexts[SpecialGlyphs.B_GOOD_PIECES] = "";
GlyphTexts[SpecialGlyphs.W_OP_VERY_BAD] = "";
GlyphTexts[SpecialGlyphs.B_OP_VERY_BAD] = "";
GlyphTexts[SpecialGlyphs.W_OP_BAD] = "";
GlyphTexts[SpecialGlyphs.B_OP_BAD] = "";
GlyphTexts[SpecialGlyphs.W_OP_GOOD] = "";
GlyphTexts[SpecialGlyphs.B_OP_GOOD] = "";
GlyphTexts[SpecialGlyphs.W_OP_VERY_GOOD] = "";
GlyphTexts[SpecialGlyphs.B_OP_VERY_GOOD] = "";
GlyphTexts[SpecialGlyphs.W_MG_VERY_BAD] = "";
GlyphTexts[SpecialGlyphs.B_MG_VERY_BAD] = "";
GlyphTexts[SpecialGlyphs.W_MG_BAD] = "";
GlyphTexts[SpecialGlyphs.B_MG_BAD] = "";
GlyphTexts[SpecialGlyphs.W_MG_GOOD] = "";
GlyphTexts[SpecialGlyphs.B_MG_GOOD] = "";
GlyphTexts[SpecialGlyphs.W_MG_VERY_GOOD] = "";
GlyphTexts[SpecialGlyphs.B_MG_VERY_GOOD] = "";
GlyphTexts[SpecialGlyphs.W_EG_VERY_BAD] = "\uE010\uE01F";
GlyphTexts[SpecialGlyphs.B_EG_VERY_BAD] = "\uE010\uE01F";
GlyphTexts[SpecialGlyphs.W_EG_BAD] = "\uE010\uE01F";
GlyphTexts[SpecialGlyphs.B_EG_BAD] = "\uE010\uE01F";
GlyphTexts[SpecialGlyphs.W_EG_GOOD] = "";
GlyphTexts[SpecialGlyphs.B_EG_GOOD] = "";
GlyphTexts[SpecialGlyphs.W_EG_VERY_GOOD] = "";
GlyphTexts[SpecialGlyphs.B_EG_VERY_GOOD] = "";
GlyphTexts[SpecialGlyphs.W_SLIGHT_COUNTER] = "\uE017";
GlyphTexts[SpecialGlyphs.B_SLIGHT_COUNTER] = "\uE017";
GlyphTexts[SpecialGlyphs.W_MODER_COUNTER] = "\uE017";
GlyphTexts[SpecialGlyphs.B_MODER_COUNTER] = "\uE017";
GlyphTexts[SpecialGlyphs.W_DECIS_COUNTER] = "\uE017";
GlyphTexts[SpecialGlyphs.B_DECIS_COUNTER] = "\uE017";
GlyphTexts[SpecialGlyphs.W_MODER_ZEITNOT] = "\uE01C";
GlyphTexts[SpecialGlyphs.B_MODER_ZEITNOT] = "\uE01C";
GlyphTexts[SpecialGlyphs.W_SEVERE_ZEITNOT] = "\uE01C";
GlyphTexts[SpecialGlyphs.B_SEVERE_ZEITNOT] = "\uE01C";
GlyphTexts[SpecialGlyphs.WITH_IDEA] = "\u2206";
GlyphTexts[SpecialGlyphs.AIMED_AGAINST] = "\uE022";
GlyphTexts[SpecialGlyphs.BETTER_IS] = "\uE020";
GlyphTexts[SpecialGlyphs.WORSE_IS] = "\u2264";
GlyphTexts[SpecialGlyphs.EQUAL_IS] = "=";
GlyphTexts[SpecialGlyphs.EDITORS_MARK] = "RR";
GlyphTexts[SpecialGlyphs.NOVELTY] = "N";
GlyphTexts[SpecialGlyphs.WEAK_POINT] = "\uE010";
GlyphTexts[SpecialGlyphs.ENDGAME] = "\uE01F";
GlyphTexts[SpecialGlyphs.LINE] = "\uE018";
GlyphTexts[SpecialGlyphs.DIAGONAL] = "\uE019";
GlyphTexts[SpecialGlyphs.W_BISHOP_PAIR] = "\uE001";
GlyphTexts[SpecialGlyphs.B_BISHOP_PAIR] = "\uE001";
GlyphTexts[SpecialGlyphs.OPP_COLOR_BISHOPS] = "\uE002";
GlyphTexts[SpecialGlyphs.SAME_COLOR_BISHOPS] = "\uE003";
GlyphTexts[SpecialGlyphs.W_UNITED_PAWNS] = "\uE02B";
GlyphTexts[SpecialGlyphs.B_UNITED_PAWNS] = "\uE02B";
GlyphTexts[SpecialGlyphs.W_SEPARATE_PAWNS] = "\uE02C";
GlyphTexts[SpecialGlyphs.B_SEPARATE_PAWNS] = "\uE02C";
GlyphTexts[SpecialGlyphs.W_DOUBLE_PAWNS] = "\uE007";
GlyphTexts[SpecialGlyphs.B_DOUBLE_PAWNS] = "\uE007";
GlyphTexts[SpecialGlyphs.W_PASSED_PAWNS] = "\uE008";
GlyphTexts[SpecialGlyphs.B_PASSED_PAWNS] = "\uE008";
GlyphTexts[SpecialGlyphs.W_MORE_PAWNS] = ">";
GlyphTexts[SpecialGlyphs.B_MORE_PAWNS] = ">";
GlyphTexts[SpecialGlyphs.MY_MOVE] = "*";
GlyphTexts[SpecialGlyphs.IMPORTANT] = "**";
GlyphTexts[SpecialGlyphs.WHITE] = "";		// invisible
GlyphTexts[SpecialGlyphs.BLACK] = "";
GlyphTexts[SpecialGlyphs.BOTH] = "";

export var PieceGlyphs = {};

//Nach der Nachricht von K.Thompson habe ich Unicode ausprobiert. 
PieceGlyphs[Piece.PAWN] = "\uE029";
PieceGlyphs[Piece.KNIGHT] = "\uE028";
PieceGlyphs[Piece.BISHOP] = "\uE027";
PieceGlyphs[Piece.ROOK] = "\uE026";
PieceGlyphs[Piece.QUEEN] = "\uE025";
PieceGlyphs[Piece.KING] = "\uE024";

export var UnicodePieceGlyphs = {};

//Nach der Nachricht von K.Thompson habe ich Unicode ausprobiert. 
UnicodePieceGlyphs[Piece.PAWN] = "\u2659";
UnicodePieceGlyphs[Piece.KNIGHT] = "\u2658";
UnicodePieceGlyphs[Piece.BISHOP] = "\u2657";
UnicodePieceGlyphs[Piece.ROOK] = "\u2656";
UnicodePieceGlyphs[Piece.QUEEN] = "\u2655";
UnicodePieceGlyphs[Piece.KING] = "\u2654";


export function getText ( _glyph )
{
	return GlyphTexts[_glyph];
};

GlyphTexts.addTexts = function ( _strMv, _symbols )
{
	var txts = {};
	txts[GlyphType.MOVE_GLYPH] =
	txts[GlyphType.PRE_GLYPH] =
	txts[GlyphType.POST_GLYPH] = "";

	for ( var inx = 0, len = _symbols.length; inx < len; ++inx )
	{
		var gly = _symbols[inx];
		var str = GlyphTexts.getText( gly );

		txts[GlyphType.getType( gly )] += str;
	}

	return txts[GlyphType.PRE_GLYPH] + _strMv + txts[GlyphType.MOVE_GLYPH] + txts[GlyphType.POST_GLYPH];
}

export var GlyphLocalization = ObjUtil.clone( InvariantLocalization );

GlyphLocalization.arrStrPieces[Piece.W_KING] =
GlyphLocalization.arrStrPieces[Piece.B_KING] = PieceGlyphs[Piece.KING];

GlyphLocalization.arrStrPieces[Piece.W_QUEEN] =
GlyphLocalization.arrStrPieces[Piece.B_QUEEN] = PieceGlyphs[Piece.QUEEN];

GlyphLocalization.arrStrPieces[Piece.W_ROOK] =
GlyphLocalization.arrStrPieces[Piece.B_ROOK] = PieceGlyphs[Piece.ROOK];

GlyphLocalization.arrStrPieces[Piece.W_BISHOP] =
GlyphLocalization.arrStrPieces[Piece.B_BISHOP] = PieceGlyphs[Piece.BISHOP];

GlyphLocalization.arrStrPieces[Piece.W_KNIGHT] =
GlyphLocalization.arrStrPieces[Piece.B_KNIGHT] = PieceGlyphs[Piece.KNIGHT];

GlyphLocalization.arrStrPieces[Piece.W_PAWN] =
GlyphLocalization.arrStrPieces[Piece.B_PAWN] = "";

export var UnicodeGlyphLocalization = ObjUtil.clone( InvariantLocalization );

UnicodeGlyphLocalization.arrStrPieces[Piece.W_KING] =
UnicodeGlyphLocalization.arrStrPieces[Piece.B_KING] = UnicodePieceGlyphs[Piece.KING];

UnicodeGlyphLocalization.arrStrPieces[Piece.W_QUEEN] =
UnicodeGlyphLocalization.arrStrPieces[Piece.B_QUEEN] = UnicodePieceGlyphs[Piece.QUEEN];

UnicodeGlyphLocalization.arrStrPieces[Piece.W_ROOK] =
UnicodeGlyphLocalization.arrStrPieces[Piece.B_ROOK] = UnicodePieceGlyphs[Piece.ROOK];

UnicodeGlyphLocalization.arrStrPieces[Piece.W_BISHOP] =
UnicodeGlyphLocalization.arrStrPieces[Piece.B_BISHOP] = UnicodePieceGlyphs[Piece.BISHOP];

UnicodeGlyphLocalization.arrStrPieces[Piece.W_KNIGHT] =
UnicodeGlyphLocalization.arrStrPieces[Piece.B_KNIGHT] = UnicodePieceGlyphs[Piece.KNIGHT];

UnicodeGlyphLocalization.arrStrPieces[Piece.W_PAWN] =
UnicodeGlyphLocalization.arrStrPieces[Piece.B_PAWN] = "";

var GlyphType =
{
	PRE_GLYPH: 0,
	MOVE_GLYPH: 1,
	POST_GLYPH: 2
};

GlyphType.getType = function ( _gly )
{
	//Langfristig so!
	//Habe bei MF abgeguckt.

	if ( _gly <= SpecialGlyphs.WORST_MOVE
		|| ( _gly >= SpecialGlyphs.MY_MOVE && _gly <= SpecialGlyphs.BOTH ) )
		return GlyphType.MOVE_GLYPH;

	if ( _gly === SpecialGlyphs.W_ZUGZW ||
	  _gly === SpecialGlyphs.B_ZUGZW )
		return GlyphType.MOVE_GLYPH;

	if ( _gly <= SpecialGlyphs.B_SEVERE_ZEITNOT ||
	  _gly >= SpecialGlyphs.NOVELTY )
		return GlyphType.POST_GLYPH;

	return GlyphType.PRE_GLYPH;
};

NotationLocalizations.glyphs = GlyphLocalization;
NotationLocalizations.uglyphs = UnicodeGlyphLocalization;
