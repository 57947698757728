
import React, { useContext } from 'react';
import styles from "./CBNavBarTop.module.css";
import iconStyles from "CBReact/Components/DesignElements/Icons/CBIcons.module.css"

import { SideNavContext, LoginContext } from "CBReact/Contexts/CBOnlineDBContext"
import { SIDENAV_RENDERMODES } from './CBNavBarSide';
import { LOGIN_MODES } from "CBReact/Components/Account/AccountUtils";
import { checkPropsAndRenderComponent } from 'CBReact/Utils/DebugUtils';
import { CBMAccountIcon, CBMSideNavIcon, CBMSettingsIcon } from 'CBReact/Components/DesignElements/Icons/CBIcons';

export function CBNavBarTop (props)
{
    const changeSideNav = useContext(SideNavContext).changeSideNav;
    const loginStatus = useContext(LoginContext).loginStatus;
    const userName = useContext(LoginContext).userName;

    function renderCBNavBarTop ()
    {
        return (
            <div className={styles.container}>
                <div className={`${styles.navBarButton} ${styles.more}`} onClick={() => changeSideNav(true, SIDENAV_RENDERMODES.main)}>
                    <CBMSideNavIcon className={iconStyles.headerIcon}/>
                </div>
                <div className={`${styles.navBarButton} ${styles.settings}`} onClick={() => changeSideNav(true, SIDENAV_RENDERMODES.settings)}>
                    <CBMSettingsIcon />
                </div>
                {
                    loginStatus === LOGIN_MODES.guest ?
                    <div className={`${styles.navBarButton} ${styles.account}`} onClick={() => changeSideNav(true, SIDENAV_RENDERMODES.login)}> 
                        <CBMAccountIcon />
                    </div> :
                    <div className={`${styles.navBarButton} ${styles.account}`} onClick={() => changeSideNav(true, SIDENAV_RENDERMODES.account)}> 
                        <CBMAccountIcon />
                        <span className={styles.welcomeText}>{userName !== "" ? userName : "User"}</span>
                    </div> 
                }
                <div className={styles.headlineContainer}>
                    <span className={styles.headline}>DATABASE</span>
                    <span className={styles.subHeadline}>by ChessBase</span>
                </div>
            </div>
        );
    }

    return checkPropsAndRenderComponent([], renderCBNavBarTop);
}

// Has no props, so this can always return true. NavBar Only Rerenders on context value change.
function navBarTopPropsAreEqual (prev, next)
{
    return true;
}

export const CBMNavBarTop = React.memo(CBNavBarTop, navBarTopPropsAreEqual);