
import React, { useContext, useEffect, useState, useRef, Fragment } from 'react';
import ReactGA from 'react-ga';
import styles from "./CBLocalEngine.module.css";

import { CBRibbon } from "CBReact/Components/DesignElements/Ribbon/CBRibbon"
import { CBButton } from "CBReact/Components/DesignElements/Buttons/Button/CBButton"
import { CBModuleHeadline } from "CBReact/Components/DesignElements/Text/ModuleHeadline/CBModuleHeadline"
import { SideNavContext, LocalizationContext, LoginContext, TeraBrainLobbyContext } from 'CBReact/Contexts/CBOnlineDBContext';
import { EngineInstance } from 'common/Chess/Engine/EngineInstance';
import { checkPropsAndRenderComponent } from 'CBReact/Utils/DebugUtils';
import { System } from 'common/Tools/System';
import { EngineModuleUtils } from './EngineModuleUtils';
import { CBMPlusIcon, CBMMinusIcon } from 'CBReact/Components/DesignElements/Icons/CBIcons';

// Release
// const ENGINE_URL = System.canRunWebAssembly() ? "https://pgn.chessbase.com/common/chess/engine/webasm/fritzajax3.js" : "/Common/Chess/Engine/df14/df14.js";
// Debug
const ENGINE_URL = System.canRunWebAssembly() ? "/Common/Chess/Engine/WebAsm/FritzAjax3.js" : "/Common/Chess/Engine/df14/df14.js";

export function CBLocalEngine(props) 
{
    const changeSideNav = useContext(SideNavContext).changeSideNav;
    const loginStatus = useContext(LoginContext).loginStatus;
    const loc = useContext(LocalizationContext).localization;
    const locRef = useContext(LocalizationContext).localizationRef;

    const searchPositionInfo = useContext(TeraBrainLobbyContext).searchPositionInfo;
    const engineTableContent = useContext(TeraBrainLobbyContext).engineTableContent;

    const [engineLines, setEngineLines] = useState(0);
    const [lineStrings, setLineStrings] = useState([]);
    const [linesRefDidChange, setLinesRefDidChange] = useState(false);
    const [engineInfo, setEngineInfo] = useState("...");
    const engineRef = useRef();
    const lineStringsRef = useRef();

    useEffect(() => {
        restartEngineOnPosChange();
    }, [props.boardPosition])

    useEffect(() => {
        
        setLinesRefDidChange(false);

        if (lineStringsRef.current)
        {
            var newLineStrings = [];
            for (const [key, value] of Object.entries(lineStringsRef.current)) 
                newLineStrings.push(value);
            
            setLineStrings(newLineStrings);
        }

    }, [linesRefDidChange]);

    function restartEngineOnPosChange ()
    {
        if (engineRef.current && engineRef.current.worker != null)
        {
            engineRef.current.fireOnThreat( false );
            engineRef.current.go(props.gameKernel.game, { infinite: true });
        }
    }

    function addAnalysisLine(e) 
    {
        e.preventDefault();

        if (engineLines === 0 && !engineRef.current)
        {
            engineRef.current = new EngineInstance(ENGINE_URL, 0, 0, "engine01");
            engineRef.current.addOnLineListener(handleEngineLine);
            engineRef.current.addOnInfoListener(handleEngineInfo);
            engineRef.current.setMultiPV(1);
            engineRef.current.fireOnThreat( false );
            engineRef.current.go(props.gameKernel.game, { infinite: true });
            setEngineLines(1);

            lineStringsRef.current = {};

            ReactGA.event({
                category: 'Interaction',
                action: 'Added first engine line',
            });
        }
        else if (engineLines < 5)
        {
            engineRef.current.setMultiPV(engineLines + 1);
            engineRef.current.go(props.gameKernel.game, { infinite: true });
            setEngineLines(engineLines + 1);
        }

    }

    function removeAnalysisLine(e) 
    {
        e.preventDefault();

        if (engineLines > 1)
        {
            engineRef.current.setMultiPV(engineLines - 1);
            engineRef.current.go(props.gameKernel.game, { infinite: true });
            delete lineStringsRef.current[engineLines];
            setEngineLines(engineLines - 1);
            setLinesRefDidChange(true);
        }
        else if (engineLines === 1)
        {
            engineRef.current.setMultiPV(0);
            engineRef.current.stop();
            delete lineStringsRef.current[1];
            setEngineLines(0);
            setLinesRefDidChange(true);
        }
    }

    function handleEngineLine (_sender, _depth, _seldepth, _evalType, _mate, _eval, _pvnum, _pv, _options) 
    {
        lineStringsRef.current[_pvnum] = EngineModuleUtils.getEngineLineString(_sender, _pv, _eval, _mate, locRef.current.strings.NOTA_LOCALIZATION);
        setLinesRefDidChange(true);
    }

    function handleEngineInfo (_sender, _depth, _seldepth, _nodes, _nps, _time) 
    {
        var infoString = String.f(locRef.current.strings.ENG_SEARCH_INFO_FMT, _depth, _seldepth, Math.round(_nps*0.001), Math.round(_time*0.001));
        setEngineInfo(infoString);
    }

    function renderEngineLine (content, index, isLastItem)
    {
        return (
            <div key={"l" + index} className={`${styles.engineLine} ${styles.engineLinePadding}`}>
                {content}
            </div>
        )
    }

    function renderCBEngineModule ()
    {
        return (
            <Fragment>
                <CBRibbon>
                    <CBModuleHeadline flexGrow={3.5}> <b>Engine Analysis</b> </CBModuleHeadline>
                    <CBButton borderLeft={true} onClick={addAnalysisLine}> <CBMPlusIcon height={"40%"}/> </CBButton>
                    <CBButton borderLeft={true} onClick={removeAnalysisLine}> <CBMMinusIcon height={"40%"}/> </CBButton>
                </CBRibbon>
                <div className={styles.engineLineContainer}>
                    {lineStrings.length > 0 ? 
                    lineStrings.map((content, index) => {return renderEngineLine(content, index, index === lineStrings.length-1)}) : 
                    <div className={`${styles.engineLine} ${styles.engineLineInactive}`}>Click '+' to add engine line</div>}
    
                    {lineStrings.length > 0 && <div className={styles.engineInfo}>{engineInfo}</div>}
                </div>
            </Fragment>
        );
    }

    return checkPropsAndRenderComponent([changeSideNav, loginStatus, loc, locRef, searchPositionInfo, engineTableContent], renderCBEngineModule);
}