// jb 12.11.2013

// "use strict";

import { HashPosition } from "common/Chess/TeraBrain/HashPosition"
import { TBRAIN, TBrainMove } from "common/Chess/TeraBrain/TBrainMove"
import { Log } from "common/Tools/Log"
import { HashMoveData } from "common/Chess/TeraBrain/HashMoveData"
import { ObjUtil } from "common/Patterns/ObjectUtil"
import { NotationGenerator } from "common/Chess/Logic/Notation"
import { InvariantLocalization } from "common/Chess/Logic/Notation";
import { Move } from "common/Chess/Logic/Move"
import { CBDebug } from "common/Tools/Debug/debug_util";
// import { isFlag } from "common/Tools/Tools"

export class HashTableData
{
	static HTD_DISCOVERED = 0x01;

	constructor () 
	{
		this.m_nIdDiscoverer = 0;
		this.pos = new HashPosition();
		this.move = [];

		// NH2020 m_aMove was undefined.... Is it move???
		this.m_aMove = [];
	};

	static emptyFactory ()
	{
		var htd = new HashTableData();
		for ( var i = 0; i < TBRAIN.HASH_LINE_CNT; i++ )
		{
			htd.move.push( new HashMoveData() );
		}
		return htd;
	};

	writeToDataBuffer ( rBuf ) {
		rBuf.beginSizedWrite();

		this.pos.writeToDataBuffer( rBuf );
		rBuf.writeUShort( TBRAIN.HASH_LINE_CNT );
		for ( var nLine = 0; nLine < TBRAIN.HASH_LINE_CNT; ++nLine )
			this.m_aMove[nLine].writeToDataBuffer( rBuf );
		rBuf.writeInt64( this.m_nPopularity );
		rBuf.writeUnsigned( this.m_nIdDiscoverer );
		rBuf.writeInt( this.m_nFlags );

		// NH2020 TBrainConstants undefined... Used values from TBrainMove
		// rBuf.writeUnsigned( TBrainConstants.CALCTIME_REASONABLE_THRESH );
		// rBuf.writeUnsigned( TBrainConstants.CALCTIME_DISCOVERY );
		// rBuf.writeUnsigned( TBrainConstants.CALCTIME_DONATION );
		// rBuf.writeUnsigned( TBrainConstants.CALCTIME_DEEP );
		rBuf.writeUnsigned( TBrainMove.CALCTIME_REASONABLE_THRESH );
		rBuf.writeUnsigned( TBrainMove.CALCTIME_DISCOVERY );
		rBuf.writeUnsigned( TBrainMove.CALCTIME_DONATION );
		rBuf.writeUnsigned( TBrainMove.CALCTIME_DEEP );

		rBuf.writeUnsigned( this.m_nGames );
		rBuf.wndSizedWrite();

		return true;
	}

	readFromDataBuffer ( rBuf ) {
		var bRet = true;

		rBuf.beginSizedRead();

		this.pos.readFromDataBuffer( rBuf );
		var nLineCnt = rBuf.readUint16();

		// NH2020 removed .prototype
		// seems to work
		// Log.WillItWork();
		for ( var nLine = 0; nLine < TBrainMove.HASH_LINE_CNT && nLine < nLineCnt; ++nLine ) {
			if ( this.move[nLine] == null )
				this.move[nLine] = new HashMoveData();
			this.move[nLine].readFromDataBuffer( rBuf );
		}
		this.m_nPopularity = rBuf.readUint64();
		this.m_nIdDiscoverer = rBuf.readUint32();
		this.m_nFlags = rBuf.readUint32();
		this.m_nReasonableThresh = rBuf.readUint32();
		this.m_nDiscoveryThresh = rBuf.readUint32();
		this.m_nDonationThresh = rBuf.readUint32();
		this.m_nDeepAnalysisThresh = rBuf.readUint32();
		if ( this.m_nDeepAnalysisThresh == 0 )
			this.m_nDeepAnalysisThresh = TBrainMove.prototype.CALCTIME_DEEP;
		this.m_nGames = rBuf.readUint16();

		rBuf.endSizedRead();
		return bRet;
	};

	clear () {
		for ( var nLine = 0; nLine < TBRAIN.HASH_LINE_CNT; ++nLine )
			this.m_aMove[nLine].clear();
		this.pos.Clear();
		this.m_nIdDiscoverer = 0;
		this.m_nFlags = 0;
		this.m_nPopularity = 0;
		this.m_nGames = 0;
	};

	static generateLine ( position, aMove, gameKernel, maxMoves )
	{
		var maxLen = maxMoves || 3;
		var strLine = "";
		var line = [];
		if ( aMove && aMove.m_aLine )
		{
			line = this.convertToCBMoveLine( ObjUtil.clone( position ), aMove.m_aLine );
			var notaGen = new NotationGenerator( InvariantLocalization );
			var blackStarts = gameKernel.game.getStartPos().getBTM();
			for ( var i = 0; i < line.length && i < maxLen; i++ )
			{
				if ( line[i].from != line[i].to )
				{
					var strMove = notaGen.getMoveNota( line[i] );
					var strMoveNum = notaGen.getStrMoveNumInLine( i, gameKernel.getCurPos().getPlyNum(), blackStarts );
					strLine += strMoveNum + strMove;
					if ( i < line.length - 1 )
						strLine += " ";
				}
			}
		}
		return {
			line: line,
			lineNota: strLine
		}
	};

	static generateLineWithStartPosition ( position, aMove, startPosition, maxMoves, notationLocalization )
	{
		var maxLen = maxMoves || 3;
		var strLine = "";
		var line = [];
		if ( aMove && aMove.m_aLine )
		{
			line = this.convertToCBMoveLine( ObjUtil.clone( position ), aMove.m_aLine );
			var notaGen = new NotationGenerator( notationLocalization );
			var blackStarts = startPosition.getBTM();
			for ( var i = 0; i < line.length && i < maxLen; i++ )
			{
				if ( line[i].from != line[i].to )
				{
					var strMove = notaGen.getMoveNota( line[i] );
					var strMoveNum = notaGen.getStrMoveNumInLine( i, position.getPlyNum(), blackStarts );
					strLine += strMoveNum + strMove;
					if ( i < line.length - 1 )
						strLine += " ";
				}
			}
		}
		return {
			line: line,
			lineNota: strLine
		}
	}

	static convertToCBMoveLine ( position, moveline )
	{
		var line = [];
		for ( var i = 0; i < moveline.length; i++ )
		{
			var from = moveline[i] & 0x3f;
			var to = ( moveline[i] >> 6 ) & 0x3f;
			var prom = ( moveline[i] >> 12 ) & 0x07;

			var move = new Move( from, to, prom );
			if ( position.isLegalMove( move ) )
			{
				position.preCalcCtx( move );
				position.makeMove( move );
				position.postCalcCtx( move, true );
				line.push( move );
			}
		}
		return line;
	};

	/*bool HashTableData::operator!=( const HashTableData &rhs ) const
   {
      if ( pos != rhs.pos  
         || m_nFlags != rhs.m_nFlags
         || m_nIdDiscoverer != rhs.m_nIdDiscoverer )
         return true;
   
      for( unsigned nLine = 0; nLine < HASH_LINE_CNT; ++nLine )
         if ( !m_aMove[ nLine ].MovesEqualTo( rhs.move[ nLine ] ) )
            return true;
   
      return false;
   }
   */
	/*
   bool HashTableData::IsSet() const
   {
      return pos.IsSet();
   }
   */
	/*
   void HashTableData::SortByCalcTime()
   {
      bool bSwapsDone = true;
      for ( int nPasses = 0; nPasses < HASH_LINE_CNT - 1 && bSwapsDone; nPasses++ )
      {	bSwapsDone = false;
         for ( int n = 0; n < HASH_LINE_CNT-1; n++ )
         {	if ( m_aMove[n].GetNCalcDepth() < m_aMove[n+1].GetNCalcDepth() )
            {	HashMoveData aSwap( m_aMove[n] );
               m_aMove[n] = m_aMove[n+1];
               m_aMove[n+1] = aSwap;
               bSwapsDone = true;
            }	
         }
      }
   }*/
	/*
         int HashTableData::CountValidLines() 
         {
            int nRet = 0;
            for ( int n = 0; n < HASH_LINE_CNT; n++ )
               if ( !m_aMove[n].Empty() )
                  nRet++;
   
            return nRet;
         }
   */
	/*    bool HashTableData::HasOwner( unsigned nUserId ) 
       {
          if ( IsSet() )
          {	for ( int n = 0; n < HASH_LINE_CNT; n++ )
                if ( m_aMove[n].GetNUserId() == nUserId )
                   return true;
          }
          return false;
 }
 */
	/*    bool HashTableData::IsReasonable( unsigned nUserId ) 
       {
          if ( IsSet() )
           {	for ( int n = 0; n < HASH_LINE_CNT; n++ )
                if ( m_aMove[n].IsReasonableDepth() || m_aMove[n].IsMate() )
                   if ( nUserId == 0 || nUserId == m_aMove[n].GetNUserId() )
                      return true;
          }
          return false;
       }
 */
	Dump () {

		/*#ifdef _DEBUG
               if ( IsSet() )
               {	TRACE( "HTD:\n" );
                  std::auto_ptr<ChessPosition> pPos( pos.CreatePosition() );
                  pPos->GetBoard().Dump();
                  for ( int n = 0; n < HASH_LINE_CNT; n++ )
                     TRACE( "%s\n", m_aMove[n].GetStr().GetStr() );
               }
            #endif*/
	};


	Count () {
		return TBRAIN.HASH_LINE_CNT;
	};

	Empty () {
		return this.m_aMove[0].Empty();
	};

	FillPos ( rPos ) {
		this.pos.FillPos( rPos );
	};

	GetHashPosition () {
		return this.pos;
	};


	GetMove ( n ) {
		CBDebug.assert( n < TBRAIN.HASH_LINE_CNT );
		if ( n < TBRAIN.HASH_LINE_CNT )
			return this.m_aMove[n];
		return this.m_aMove[0];
	};

	SetMove ( rMove, n ) {
		CBDebug.assert( n < TBRAIN.HASH_LINE_CNT );
		if ( n < TBRAIN.HASH_LINE_CNT )
			this.m_aMove[n] = rMove;
	};

	GetNPopularity () {
		return this.m_nPopularity;
	};

	SetNPopularity ( nPopularity ) {
		this.m_nPopularity = nPopularity;
	};

	GetNGames () {
		return this.m_nGames;
	};

	GetNIdDiscoverer () {
		return this.m_nIdDiscoverer;
	};

	SetNIdDiscoverer ( r ) {
		this.m_nIdDiscoverer = r;
	};

	IsDiscovered () {
		// NH2020 ISFLAG is missing. There Are multiple versions in different classes. Not sure which to use...
		Log.Missing();
		//return ISFLAG( HTD_DISCOVERED );
	};

	SetIsDiscovered ( b ) {
		// NH2020 SETFLAG is missing. There Are multiple versions in different classes. Not sure which to use...
		Log.Missing();
		//SETFLAG( HTD_DISCOVERED, b );
	};

	GetNReasonableThresh () {
		return this.m_nReasonableThresh;
	};

	GetNDiscoveryThresh () {
		return this.m_nDiscoveryThresh;
	};

	GetNDonationThresh () {
		return this.m_nDonationThresh;
	};

	GetNDeepAnalysisThresh () {
		return this.m_nDeepAnalysisThresh;
	}

}


