// mw 2.5.2013
// ES6 4.3.2020

import { Log } from 'common/Tools/Log'
// import { glApp } from "common/App/App";

//import { System } from 'common/Tools/System'
// import { MoveEnterType } from "common/Chess/Logic/Game";
import { AllowedColor } from "common/Chess/Logic/MoveEntry";

import { ManualClock } from "common/Chess/PlayChess/ManualClock";
import { ChessClock } from "common/Chess/PlayingModes/ChessClock";

import { AbstractPlayingMode } from "./AbstractPlayingMode";


export var ResultFlags = 
{
	TECHNICAL: 2,
	MY_TIME: 4,
	RESIGN: 8,
	OPP_RESIGN: 16,
};

export class PlayingMode extends AbstractPlayingMode
{
	constructor( kernel )
	{
		super();

		this.kernel = kernel;
		this.isAutoplay = false;
	};

	loadGame( game )
	{
		this.beforeGameLoad();
		this.kernel.game.assign( game );
		this.afterGameLoad();
	};

	destroy()
	{
		this.deleteClock();
	};

	deleteClock()
	{
		this.clock = null;
		if ( this.manualClock )
		{
			this.manualClock.stop();
			delete this.manualClock;
		}
		if ( this.chessClock )
		{
			this.chessClock.stop();
			delete this.chessClock;
		}
	};

	onTimeOverstepped( black )
	{
		if ( this.clock )
			this.clock.onTimeOverstepped( black );
	};

	onEnteredMove( mv )
	{
		if ( this.clock )
			this.clock.stop();
		return true;
	};

	isAnalysis()
	{
		return true;
	};

	initAnalysisEngine( options )
	{
		//	console.log( screen.width + "/" + screen.height );
		try
		{
			// "#IFDEBUG"
			// glApp.config.useAnalysisEngines = false;
			// "#ENDIF"


			// if ( typeof glApp !== "undefined" && !glApp.analysisEngine && System.isDesktop() && glApp.config.useAnalysisEngines && AnalysisEngine )	// e.g. not in LiveBlitz
			// glApp.analysisEngine = new AnalysisEngine(options);
		}
		catch ( x )
		{
			Log.Exception( "PM:initAnalysisEngine", x );
		}
	};

	initThreatEngine( options )
	{
		//if ( typeof glApp !== "undefined" && !glApp.threatEngine && System.isDesktop() && AnalysisEngine )
		//	glApp.threatEngine = new AnalysisEngine( options );

		// var engUrl = "/Common/Chess/Engine/Enginemin.js";
		// "#IFDEBUG"
		// engUrl = "/Common/Chess/Engine/Engine.js";
		// "#ENDIF"
		//   try
		//   {
		// 	   this.threatEngine = new EngineWrapper( 0, engUrl, null /*viewLink*/, this /*gameLink*/ );
		//    	if ( !System.isElectron() )
		// 	{
		// 	   	this.threatEngine.load();
		//      }
		//   }
		//   catch (x)
		//   {
		//      Log.Exception("PM:initThreatEngine", x);

		//   }
	};

	onEnginePV( n, data )
	{
		//if ( Hints.fnAfterSearch )
		//{
		//	Hints.fnAfterSearch( data.eval, data.pv );
		//}
	}

	onEngineMove( n, data )
	{
	};

	getMoveEnterType()
	{
		Log.Missing();
		// NH2021D This should be overwritten by OnlineDBModeNew
		// var gm = this.kernel.game;
		// if ( gm.isLineEnd() )
		// {
		// 	//Es gibt keinen nächsten Zug.
		// 	return MoveEnterType.NEWLINE;
		// }
		// else
		// {
		// 	if ( !glApp.allowMoveEntryDialog() )
		// 	{
		// 		return this.isAnalysis() ?
		// 			MoveEnterType.NEWLINE :
		// 			MoveEnterType.OVERWRITE_MAIN;
		// 	}
		// }
		// return MoveEnterType.QUERY;
	};

	onResult( res )
	{
		if ( this.clock )
			this.clock.onResult( res );
	};

	updateClockFromGame()
	{
		if ( this.clock )
		{
			this.clock.initFromGame( this.kernel.game );
		}
	};

	//setLastMoveTimeCentiSecs( centiSecs ) {
	//	if ( this.clock && centiSecs && this.clock.changeCurrMoveElapsedTime ) {
	//		this.clock.changeCurrMoveElapsedTime( centiSecs );
	//	}
	//};

	startClock()
	{
		if ( this.clock )
			this.clock.start();
	};

	stopClock()
	{
		if ( this.clock )
			this.clock.stop();
	};

	getAllowedColor()
	{
		return AllowedColor.BOTH_COLORS;
	};

	onGameNavigate()
	{
		this.updateClockFromGame();
	};

	setManualClock( whiteTime, blackTime, isBlack )
	{
		//	TRACE( String.formatEx( "SetManualClock {0}, {1}, {2}", whiteTime/6000, blackTime/6000, isBlack ) );

		delete this.chessClock;
		if ( this.kernel.boardWin && this.kernel.boardWin.getClockDisplay() )
		{
			if ( this.clock )
				this.clock.stop();
			this.manualClock = new ManualClock( this.kernel.boardWin.getClockDisplay() );
			this.manualClock.setTime( whiteTime, blackTime, isBlack );
			this.manualClock.onResult( this.kernel.game.hdr.getResult() );
			this.clock = this.manualClock;
		}
	};

	updateHeader()
	{
		if ( this.manualClock )
			this.manualClock.onResult( this.kernel.game.hdr.getResult() );
	};

	setChessClock( clockParams )
	{
		delete this.manualClock;
		if ( this.kernel.boardWin && this.kernel.boardWin.hasClock() )
		{
			this.chessClock = new ChessClock( this.kernel.boardWin.getClockDisplay() );
			this.chessClock.setClockParams( clockParams );

			if ( clockParams )
				this.chessClock.initFromGame( this.kernel.game );
			this.clock = this.chessClock;
		}
	};

	linkBoardHints( boardWin )
	{
		boardWin.fnMarkSquaresOnButtonDown = this.getMarkedSquaresOnButtonDown.bind( this );
		//		boardWin.fnSquareClick = this.startClickHintTimer.bind( this );

		//boardWin.fnSquareChangeInMouseMove = function ()
		//{
		//	this.clickHintTimer.stop();
		//}.bind( this );

		//boardWin.fnSquareRelease = function ()
		//{
		//	this.clickHintTimer.stop();

		//}.bind( this );
	};

	unlinkBoardHints()
	{
		if ( this.kernel.boardWin )
			this.kernel.boardWin.fnMarkSquaresOnButtonDown = null;
	};

	getMarkedSquaresOnButtonDown( clickSq )
	{
		return this.genLegalSquareHints( clickSq );
	};

	getLegalSqClr()
	{
		return "hsla( 0, 0%, 50%, 0.25 )";	//grey
	};

	genLegalSquareHints( clickSq )
	{
		var sqs = [];

		var legals = this.kernel.game.getCurPos().generateLegals();
		legals.forEach( function( move )
		{
			if ( move.from === clickSq )
			{
				sqs.push( { sq: move.to, clr: this.getLegalSqClr() } );
			}
		}.bind( this ) );
		return sqs;
	};

	setIsAutoplay( b )
	{
		this.isAutoplay = b;
	};

}

