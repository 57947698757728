// jb 12.11.2013

// "use strict";

import { Log } from "common/Tools/Log"

export class GamePerformanceStats
{
	// constructor ()
	// {
	// };

	initEmpty ()
	{
		this.strLastName = "";
		this.strFirstName = "";
		this.aNation = 0;
		this.nElo = 0;
		this.m_nEloCount = 0;
		this.m_nEloSum = 0;
		this.m_nResultCount = 0;
		this.m_nResultSum = 0;
	};

	read ( buf )
	{
		this.strLastName = buf.readString( 100 );
		this.strFirstName = buf.readString( 100 );
		this.aNation = buf.readUint32();
		this.nElo = buf.readUint32();

	};

	plus ( a, rhs )
	{
		// NH2020 uint undefined
		Log.Missing();

		// var my = new GamePerformanceStats( a );
		// my.m_nEloCount += rhs.m_nEloCount;		//	Count wächst viel langsamer als Sum, einfach addieren
		// var nEloSum = my.m_nEloSum + rhs.m_nEloSum;
		// if ( nEloSum > uint.MaxValue - 10000 )
		// {
		// 	my.m_nEloSum = ( uint )( nEloSum / 2 );
		// 	my.m_nEloCount /= 2;
		// } else
		// 	my.m_nEloSum = nEloSum;

		// //	Count kann schneller wachsen als Sum!
		// var nResultSum = my.m_nResultSum + rhs.m_nResultSum;
		// var nResultCount = my.m_nResultCount + rhs.m_nResultCount;

		// if ( ( nResultSum > uint.MaxValue - 10000 ) || ( nResultCount > uint.MaxValue - 10000 ) )
		// {
		// 	my.m_nResultSum = ( nResultSum / 2 );
		// 	my.m_nResultCount = ( nResultCount / 2 );
		// } else
		// {
		// 	my.m_nResultSum = nResultSum;
		// 	my.m_nResultCount = nResultCount;
		// }

		// return my;
	};


	/*
	void GamePerformanceStats::AddGame( const CBHeader &rHeader, bool bBtm )
	{
		AddElos( rHeader, bBtm );
		AddResult( rHeader, bBtm );
	}

	void GamePerformanceStats::AddElos( const CBHeader &rHeader, bool bBtm )
	{
		if ( bBtm )
		{	if ( rHeader.GetBlackElo() )
			{	m_nEloSum += rHeader.GetBlackElo();
				m_nEloCount++;
			}
		}
		else
		{	if ( rHeader.GetWhiteElo() )
			{	m_nEloSum += rHeader.GetWhiteElo();
				m_nEloCount++;
			}
		}

		if ( m_nEloSum > std::numeric_limits<unsigned>::max() - 10000 )
		{	m_nEloSum /= 2;
			m_nEloCount /= 2;
		}
	}

	void GamePerformanceStats::AddResult( const CBHeader &rHeader, bool bBtm )
	{
		if ( bBtm )
		{	if ( rHeader.GetCBResult().IsDecided() )
			{
				if ( rHeader.GetCBResult().GetResult() == BLACK_WINS )
					m_nResultSum += 2;
				else if ( rHeader.GetCBResult().GetResult() == DRAW )
					m_nResultSum += 1;
	
				m_nResultCount++;
			}
		}
		else
		{	if ( rHeader.GetCBResult().IsDecided() )
			{
				if ( rHeader.GetCBResult().GetResult() == WHITE_WINS )
					m_nResultSum += 2;
				else if ( rHeader.GetCBResult().GetResult() == DRAW )
					m_nResultSum += 1;
	
				m_nResultCount++;
			}
		}

		//	Count kann schneller wachsen als Sum!
		if( ( m_nResultSum > std::numeric_limits<unsigned>::max() - 10000 ) || ( m_nResultCount > std::numeric_limits<unsigned>::max() - 10000 ) )
		{	m_nResultSum /= 2;
			m_nResultCount /= 2;
		}
	}
	*/
	writeToDataBuffer ( rBuf )
	{
		rBuf.WriteUnsigned( this.m_nEloSum );
		rBuf.WriteUnsigned( this.m_nEloCount );
		rBuf.WriteUnsigned( this.m_nResultSum );
		rBuf.WriteUnsigned( this.m_nResultCount );
	};

	readFromDataBuffer ( rBuf )
	{
		this.m_nEloSum = rBuf.readUint32();
		this.m_nEloCount = rBuf.readUint32();
		this.m_nResultSum = rBuf.readUint32();
		this.m_nResultCount = rBuf.readUint32();

		return true;
	};

	getSortResult ()
	{
		if ( this.m_nResultCount != 0 )
			return ( ( 500.0 * this.m_nResultSum ) / this.m_nResultCount );		//	500.0 *!!, sonst ist das immer 0,1,2
		else
			return 0;
	};

	getSortEloAv ()
	{
		if ( this.m_nEloCount != 0 )
			return ( this.m_nEloSum / this.m_nEloCount );
		else
			return 0;
	};

	getFPercent ( bInvertBTM )
	{
		if ( this.m_nResultCount != 0 )
			if ( bInvertBTM )
				return 100.0 - ( 50.0 * this.m_nResultSum ) / this.m_nResultCount;
			else
				return ( 50.0 * this.m_nResultSum ) / this.m_nResultCount;
		else
			return 0;
	};

	getNGames ()
	{
		return Math.max( this.m_nEloCount, this.m_nResultCount );
	};
}

