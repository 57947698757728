// Queue, mw 21.1.2013
// MW 5.3.2020


export class Queue
{
	constructor()
	{
		this.nOffset = 0;
		this.queue = [];
	};

	getLength()
	{
		return ( this.queue.length - this.nOffset );
	}
	empty()
	{
		return this.queue.length === 0;
	}
	push( item )
	{
		this.queue.push( item );
	}
	pop()
	{
		if ( this.queue.length === 0 )
			return undefined;
		var item = this.queue[ this.nOffset ];
		if ( ++this.nOffset * 2 >= this.queue.length )
		{
			this.queue = this.queue.slice( this.nOffset );
			this.nOffset = 0;
		}
		return item;
	}

	front()
	{
		return ( this.queue.length > 0 ? this.queue[ this.nOffset ] : undefined );
	}

}