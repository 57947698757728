

// Fake Log Export for project build
export var Log = {
    Log: function() {},
    TraceCollapsed: function() {},
    Exception: function() {},
    Missing: function() {},
    WillItWork: function() {},
    Stats: function() {},
}

// export class Log
// {
//     static Log( txt )
//     {
//         console.log( txt );
//     }

//     static TraceCollapsed ( txt )
//     {
//         console.groupCollapsed(txt);
//         console.trace();
//         console.groupEnd();
//     }

//     static Exception( txt, x, label )
//     {
//         var xMsg = x ? x.toString() : "---";
//         Log.TraceCollapsed( txt + ", " + xMsg );
//     }

//     static Missing ()
//     {
//         Log.TraceCollapsed( "This part has been commented out" );
//     }

//     static WillItWork ()
//     {
//         Log.TraceCollapsed( "Not sure if the following code will work.");
//     }

//     Stats( txt )
//     {
//         Log.Log( txt )
//     }
// }