// "use strict";

export class PLMMRecord  {

   // constructor () {
   // };

   readFromDataBuffer ( rBuf ) {
      this.nSerial = rBuf.readUint32();
      this.aDate = rBuf.readCBDate();
      this.nMediaType = rBuf.readUint16();
      this.sName = rBuf.readASCIIString(100);
      this.sAuthor = rBuf.readASCIIString(100);
      this.sLocation = rBuf.readASCIIString(100);
      this.isExact = rBuf.readBool();
   };

   writeToDataBuffer ( rBuf ) {
      rBuf.writeUint32( this.nSerial);
      rBuf.writeCBDate(this.aDate);
      rBuf.writeUint16(this.nMediaType);
      rBuf.writeASCIIString(this.sName);
      rBuf.writeASCIIString(this.sAuthor);
      rBuf.writeASCIIString(this.sLocation);
      rBuf.writeBool(this.isExact);
   };

}

