// ST 2011
// ES 6 MW: Feb 27, 2020

//Не засираем объект, блджадь!

import { HashTable } from 'common/Patterns/HashTable'

export class ObjUtil
{
    static clone = function( _src, _hash )
    {
        if ( !_hash )
        {
            _hash = new HashTable();
        }

        var there = _hash.getItem( _src );
        if ( there )
        {
            return there;
        }

        var clone = null;

        if ( _src.clone )
        {
            clone = _src.clone( _hash );
        }
        else
        {
            if ( ObjUtil.isArray( _src ) )
            {
                clone = [];
            }
            else
                clone = new _src.constructor();

            //DAVOR!!!
            _hash.setItem( _src, clone );

            ObjUtil.copyTo( _src, clone, false, _hash );


        }
        _hash.setItem( _src, clone );


        return clone;
    };

    static clone2 = function( _src )
    {
        return ObjUtil.copyTo2( _src, new _src.constructor() );
    };


    // Sehr schnell wegen nativer Impl von JSON im Browser:
    static cloneNoFunc = function( _src )
    {

        var str = JSON.stringify( _src );

        return JSON.parse( str );
        // return JSON.parse( JSON.stringify( _src ) );
    }

    static copyTo = function( _src, _dst, _onlyMissing, _hash )
    {
        //Array?

        for ( var prop in _src )
        {
            if ( _onlyMissing && prop in _dst )
                continue;

            var val = _src[ prop ];

            var copy = val;
            if ( val != null && typeof ( val ) === 'object' )
                copy = ObjUtil.clone( val, _hash );

            _dst[ prop ] = copy;
        }

        //IE8- - wir alle hassen ihn!
        if ( _src.toString !== _dst.toString )
            _dst.toString = _src.toString;
        return _dst;
    };
    static copyTo2 = function( _src, _dst )
    {
        for ( var prop in _src )
        {
            var val = _src[ prop ];

            var copy = val;
            if ( val != null && typeof ( val ) === 'object' )
                copy = ObjUtil.clone2( val );

            _dst[ prop ] = copy;
        }

        return _dst;
    };


    static _CMP( _val1, _val2 )
    {
        if ( ( _val1 === undefined ) !== ( _val2 === undefined ) )
            return false;

        if ( ( _val1 === undefined ) && ( _val2 === undefined ) )
            return true;

        if ( ( _val1 === null ) !== ( _val2 === null ) )
            return false;

        if ( ( _val1 === null ) && ( _val2 === null ) )
            return true;

        var typ1 = typeof ( _val1 );
        var typ2 = typeof ( _val2 );

        if ( ( typ1 === "object" ) !== ( typ2 === "object" ) )
            return false;

        if ( ( typ1 === "object" ) && ( typ2 === "object" ) )
            return ObjUtil.equals( _val1, _val2 );

        return _val1 === _val2;

    }

    static equals = function( _obj1, _obj2 )
    {
        if ( _obj1.equals )
            return _obj1.equals( _obj2 );
        for ( var prop in _obj1 )
        {
            var valMy = _obj1[ prop ];
            var valHis = _obj2[ prop ];

            if ( !ObjUtil._CMP( valMy, valHis ) )
                return false;
        }

        return true;
    };

    static isEmpty = function( _obj )
    {
        return !Object.keys( _obj ).length;
    };

    static isFunction = function( _obj )
    {
        return !!( _obj && _obj.constructor && _obj.call && _obj.apply );
    };

    static isArray = function( _arr )
    {
        return Object.prototype.toString.call( _arr ) === '[object Array]';
    };

    static hashCode = function( _obj )
    {
        if ( _obj.hashCode )
            return _obj.hashCode();
        if ( typeof ( _obj ) === 'object' )
            return StringHash( _obj );
        return djb2( _obj.toString() );
    }

    static mixObject( me, mix )
    {
        for ( var m in mix )
        {
            if ( mix.hasOwnProperty( m ) )
                me[ m ] = mix[ m ];
        };
    }
}

function djb2( _str )
{
    var hash = 5381;

    for ( var inx = 0; inx < _str.length; ++inx )
    {
        let c = _str.charCodeAt( inx ) & 0xFF;
        hash = 0xFFFFFFFF & ( ( ( hash << 5 ) + hash ) + c ); /* hash * 33 + c */
    }

    return hash;
}

function StringHash( _obj )
{

    var str = typeof ( _obj ) + _obj.constructor.toString();
    return djb2( str );
}

export function EnumToString( _enumType, _val )
{
    for ( var inx in _enumType )
    {
        if ( _enumType[ inx ] === _val )
            return inx;
    }
    return "";
};

export function Mixin( me, mix )
{
	for ( var m in mix )
	{
		if ( mix.hasOwnProperty( m ) )
			me.prototype[m] = mix[m];
	};
};

// NH2020 Added this, can mixin multiple classes. Code from here:
// https://blog.bitsrc.io/understanding-mixins-in-javascript-de5d3e02b466
export function classMixin (cls, ...src)
{
    for (let _cl of src) 
    {
        for (var key of Object.getOwnPropertyNames(_cl.prototype)) 
        {
            cls.prototype[key] = _cl.prototype[key];
            //console.log("KEY: " + key);
        }
    }
}

// function StringToEnum( _enumType, _val )
// {
//     if ( _val in _enumType )
//         return _enumType[ _val ];
//     return undefined;
// };

// function StringToEnumFlags( _enumType, _val )
// {
//     var arrParts = _val.split( '|' );
//     var res = 0;
//     for ( var inx = 0, len = arrParts.length; inx < len; ++inx )
//     {
//         var strVal = arrParts[ inx ];
//         var val = StringToEnum( _enumType, strVal );
//         if ( val )
//             res |= val;
//     }
//     return res;
// };

// function evalObj( _obj )
// {
//     var res = {};
//     for ( var prop in _obj )
//     {
//         var val = _obj[ prop ];
//         res[ prop ] = val;

//         if ( typeof ( val ) !== "string" )
//             continue;
//         if ( val.indexOf( "{" ) < 0 )
//             continue;

//         res[ prop ] = eval( "(" + val + ")" );
//     }

//     return res;
// };

export function minusToCamel( _str )
{
    for ( var inx = _str.indexOf( "-" );
        inx >= 0;
        inx = _str.indexOf( "-" ) )
    {
        var pre = _str.substring( 0, inx );
        var post = _str.substring( inx + 1 );
        if ( post.length > 0 )
        {
            post = post.charAt( 0 ).toUpperCase() +
                ( ( post.length > 1 ) ? post.substring( 1 ) : "" );
        }

        _str = pre + post;
    }

    return _str;
}