
// NH2020
// <reference path="Protocol/WebSockMessage.js" />

// "use strict";

// NH2020
//CB.namespace( "CB.Server.TeraBrainLobby" );

// NH2020 excluded the following variables from the class
// due to the way they were referenced

import { Log } from "common/Tools/Log"
import { Timer } from "common/Tools/Timer"
import { Connector } from "common/WebClient/Connector"
import { PositionTracker } from "common/Chess/Logic/PositionTracker"
// import { glApp  } from "common/App/App";
import { ClientMachineInfo } from "common/Chess/TeraBrain/ClientMachineInfo"
import { TeraBrainRequest } from "common/Chess/TeraBrain/TeraBrainRequest"
import { WebSockMessage } from "common/WebClient/Protocol/WebSockMessage"
import { SockMsgId } from "common/WebClient/Protocol/WebSockMessage"
import { TeraBrainUserMsgType } from "common/Chess/TeraBrain/TeraBrainMessage"
// import { DOM } from "common/HTMLDocument/Text"
import { TeraBrainInfo } from "common/Chess/TeraBrain/TeraBrainInfo"
// import { Position } from "common/Chess/Logic/Position"
import { HashTableData } from "common/Chess/TeraBrain/HashTableData"
import { TBrainMove } from "common/Chess/TeraBrain/TBrainMove"
// import { ChatData } from "common/WebClient/Protocol/ChatData"
import { ListenersUtil } from "common/Patterns/Listeners";
// import { ColSq } from "../Format/AnnoTypes";

export var TBRAIN_INQ_MACHINEGUID = 1;
export var N_MAX_SIZE = 10 * 1024;
export var N_MAX_UPDATE = 256 * 1024;

export var TeraBrainEventType = {
	TBRAINEVENT_SETTINGS: 0,
	TBRAINEVENT_CONNECTCOMPLETE: 1,
	TBRAINEVENT_CONFIRMLOGIN: 2,
	TBRAINEVENT_RANKINGLIST: 3,
	TBRAINEVENT_DISCONNECT: 4,
	TBRAINEVENT_INITIATE_LETSCHECK: 5,
	TBRAINEVENT_LAUNCH_AUTOCONNECT: 6,
	TBRAINEVENT_MY_DISCOVERY: 7
};


export var TBAnalysisType = {
	TBANALYSIS_ACCESS_ONLY: 0,
	TBANALYSIS_STANDARD: 1,
	TBANALYSIS_WIN_VARIATION: 2
};

export var TeraBrainRequestType = {
	// LiveBook Requests:
	TBREQUEST_LB_UPLOADONLY: 0,	// not requesting an answer if window hidden:
	TBREQUEST_LB_POSINFO: 1,		// Just this position
	TBREQUEST_LB_ALLMOVES: 2,	// all moves + visiting statistics
	TBREQUEST_SEARCH_INFO: 3		// search info, if available
};

// NH2020I Klasse, um Daten über das LiveBook vom Server abzufragen.

export class TeraBrainLobby extends Connector
{

	constructor ()
	{
		// NH2020 Will inheritance work?
		super();

		// NH2020 Will listeners work?
		TeraBrainLobby.InitListeners()

		this.idGroup = 0;

		this.m_nUsageCnt = 0;
		this.m_bLoggedIn = false;
		this.m_nUserReliability = 0;
		this.m_bAdmin = false;
		this.m_bLaunchRankingDia = false;
		this.m_nAnalysisTicket = 0;
		this.m_bSuppressRequests = false;
		this.m_bLaunchAnalysisByEngineNames = false;
		this.m_bIsInAnalysis = false;
		this.m_nAnalysisMoveLimit = 1;
		this.m_bInEngineDonation = false;
		this.m_nDonationPositions = 0;
		this.m_nEngineId = 0;

		this.associativeArray = [];

		this.tableDataHandlers = {};

		this.timerClear = new Timer( function ()
		{
			this.setEmptyTable();
		}.bind( this ), 2000 );

		this.name = "TBrain";

		this.tracker = new PositionTracker();

		// NH2020 var me was outside of functions and I couldnt find a reference to it
		// Log.WillItWork();
		//var me;
	};

	// NH2020
	//CB.inherit( TeraBrainLobby, CB.Server.Connector ); // calls constructor of CB.Server.Connector

	// NH2020 Added
	hasSockMsgIds ()
	{
		return true;
	};

	// NH2020 Added
	hasSockCheckSum ()
	{
		return true;
	};

	execCommand ( id )
	{
		if ( !this.hasIdReceived )
			return false;

		Log.WillItWork();
		// NH2020 CB.LobbyIds.PING_SERVER. Lobby is not currently in the project
		var bRet = true;
		switch ( id )
		{
			default:
				bRet = false;
				break;

			// case CB.LobbyIds.PING_SERVER:
			// 	this.pingServer( true /*silent*/);
			// 	break;

			// NH2020
			// case CB.LobbyIds.LOBBY_TEST2:
			// 	"#IFDEBUG"
			// 	this.test2();
			// 	"#ENDIF"
		}
	};

	notifyUserActivity ()
	{
	};

	modifyLoginData ( ld )
	{
		ld.documentUrl = document.URL;

		// NH2021D
		// if ( !glApp.isAnyPlugin )
		// 	ld.documentUrl += " (off)";
	};

	onIdReceived ( yourId )
	{
		if ( this.fnOnIdReceived )
		{
			this.fnOnIdReceived();
		}
	};

	request ( nTicket, rPos, eReqType, bDontCountVisit, bRequestConfirm, bIsHumanServerGame, bMultiRequest )
	{
		if ( this.m_bSuppressRequests )
		{	//TRACE( "TBrainManager::Ignore suppressed request\n" );
			return;
		}

		this.s_aMachineInfo = new ClientMachineInfo();
		var aTBR = new TeraBrainRequest( rPos, eReqType, this.s_aMachineInfo );

		//#ifdef WITH_TERABRAIN_NORM_EVAL
		aTBR.setIsNormedEval( true );
		//#endif

		aTBR.setCountVisit( !bDontCountVisit );
		aTBR.setConfirmRequested( bRequestConfirm );
		aTBR.setIsHumanServerGame( bIsHumanServerGame );
		aTBR.setIsMultiRequest( bMultiRequest );

		var aMsg = aTBR.getSocketsMessage();
		aMsg.setVal( nTicket );

		this.send( aMsg );
	}

	requestPosInfo ( pos )
	{
		if ( this.hasIdReceived )
		{
			// Log.Log("Request Pos Info");
			this.request( 1, pos, TeraBrainRequestType.TBREQUEST_SEARCH_INFO, true, false, true );
			this.timerClear.runOnce( 2000 );
		}
	};

	requestWithTicket ( idTicket, pos, fnCallback )
	{
		// Log.Log("Request With ticket");
		if ( this.hasIdReceived )
		{
			this.tableDataHandlers[idTicket] = fnCallback;

			this.request( idTicket, pos, TeraBrainRequestType.TBREQUEST_SEARCH_INFO, true, false, true );
		}
	};

	requestAllMoves ( pos, noVisitCount, confirm, ticket, isMultiRequest )
	{
		// Log.Log("Request all moves");
		if ( this.hasIdReceived )
		{
		//	CB.LOG( "TB Request All Moves", "LogBold" );
			var knownPos = this.tracker.track( pos );
			this.request( ticket || 1, pos, TeraBrainRequestType.TBREQUEST_LB_ALLMOVES, knownPos, confirm, noVisitCount, isMultiRequest );
		}
	};

	static requestHotPosition ()
	{
		// NH2020 TeraBrain Message undefined, cant find it anywhere
		Log.Missing();
		// var aMsg = new CB.TeraBrainMessage( SocketsMessageType.TERABRAIN_USER );
		// aMsg.setUserType( TeraBrainUserMsgType.TBUSER_REQ_HOTPOSITION );
		// this.send( aMsg );
	};

	requestEngineNames ( EngineIds )
	{
		var aMsg = new WebSockMessage( SockMsgId.TERABRAIN_USER );
		aMsg.setUserType( TeraBrainUserMsgType.TBUSER_REQ_ENGINENAMES );
		aMsg.buf.writeUint32( EngineIds.length );
		for ( var n = 0; n < EngineIds.length; n++ )
		{
			aMsg.buf.writeUint32( EngineIds[n] );
		}
		this.send( aMsg );
	}

	handleReceived ( sockMsg )
	{
		if ( this.handleReceivedByConnector( sockMsg ) )
			return;

		// console.log("Handle TB Received");

	//	try
		{
			switch ( sockMsg.getType() )
			{
				default:
					// NH2020 Changed Log Function
					Log.Log( "Unhandled: " + sockMsg.toString() );
					break;

				case SockMsgId.DEFAULTGROUPS:
					this.onConnectedToBoundServer();
					break;
				case SockMsgId.CHAT:
					// console.log("Received Chat");
					//this.handleChat( sockMsg );
					break;
				case SockMsgId.TERABRAIN_BOOKINFO:
					// console.log("Received Info Message");
					this.handleInfoMsg( sockMsg );
					break;
				case SockMsgId.TERABRAIN_TABLEDATA:
					// console.log("Received Table Data");
					this.handleTableData( sockMsg );
					break;
				case SockMsgId.TERABRAIN_USER:
					// console.log("Received User");
					this.handleUserMsg( sockMsg );
					break;
			}
		}
		//catch ( x )
		//{
		//	CB.LogException( sockMsg.getType(), x );
		//"#IFDEBUG"
		//	alert( "Exception: " + x.toString() );
		//"#ENDIF"
		//}
	};

	handleUserMsg ( sockMsg )
	{
		switch ( sockMsg.getUserType() )
		{
			default:
				Log.Log( "UserMsg=" + TeraBrainUserMsgType.toString( sockMsg.getUserType() ) )
				break;

			case TeraBrainUserMsgType.TBUSER_ENGINENAMES:
				// Log.Log("Handle Engine Names");
				this.handleEngineNames( sockMsg );
				break;
			case TeraBrainUserMsgType.TBUSER_USERENTRIES:
				//    HandleUserEntries( rMsg );
				break;
			case TeraBrainUserMsgType.TBUSER_HOTPOSITION:
				//    HandleHotPosition( rMsg );
				break;
			case TeraBrainUserMsgType.TBUSER_CONFIRM_REQUEST:
				// Log.Log("Handle Confirm Request");
				this.handleConfirmRequest( sockMsg );
				break;
			case TeraBrainUserMsgType.TBUSER_CONFIRMCOMMENT_REQUEST:
				//    HandleConfirmCommentRequest( rMsg );
				break;
			case TeraBrainUserMsgType.TBUSER_COMMENTS:
				//    HandleComments( rMsg );
				break;
			case TeraBrainUserMsgType.TBUSER_ADD_COMMENT:
				//    HandleAddComment( rMsg );
				break;
			case TeraBrainUserMsgType.TBUSER_REFRESH_COMMENT:
				//    HandleRefreshComment( rMsg );
				break;
			case TeraBrainUserMsgType.TBUSER_CONFIRM_LOGIN:
				//    HandleConfirmLogin( rMsg );
				break;
			case TeraBrainUserMsgType.TBUSER_RANKINGLIST:
				//    HandleRankingList( rMsg );
				break;
			case TeraBrainUserMsgType.TBUSER_DEEP_VAR:
				//   HandleDeepVar( rMsg );
				break;
			case TeraBrainUserMsgType.TBUSER_SLAVE_ANALYSIS:
				//   HandleSlaveAnalysis( rMsg );
				break;
			case TeraBrainUserMsgType.TBUSER_RETRACT:
				//   HandleRetract( rMsg );
				break;
			case TeraBrainUserMsgType.TBUSER_SUBMIT_POSITION_DONE:
				//  HandleSubmitDone( rMsg );
				break;
			case TeraBrainUserMsgType.TBUSER_SUBMIT_GAME_RESPONSE:
				//  HandleSubmitGameResponse( rMsg );
				break;
			case TeraBrainUserMsgType.TBUSER_ANALYSE_GAME_RESPONSE:
				//  HandleAnalyseGameResponse( rMsg );
				break;
			case TeraBrainUserMsgType.TBUSER_USERDATA:
				//  HandlePersonalData( rMsg );
				break;
			case TeraBrainUserMsgType.TBUSER_LOCALTREE:
				// HandleLocalTree( rMsg );
				break;

		}
	};

	handleInfoMsg ( rMsg )
	{
		this.aTBI = new TeraBrainInfo();
		this.aTBI.readFromDataBuffer( rMsg.getBuf() );
		var aPos = this.aTBI.pos.getPosition();

		//for ( var i = 0; i < this.aTBI.moveList.moves.length; i++ )
		//{
		//	CB.LOG( "TBI-Move " + i + " " + this.aTBI.moveList.moves[i].move.toString() + ", ev=" + this.aTBI.moveList.moves[i].m_nEval );
		//}

		var data = {
			position: aPos,
			moves: this.aTBI.moveList.moves,
			ticket: rMsg.getVal(),
			tbInfo: this.aTBI
		};

		this.fireOnLiveBook( data );

		//if ( glApp.panelMgr.tbBook )
		//	glApp.panelMgr.tbBook.update( aPos, this.aTBI );
	};

	handleConfirmRequest ( msg )
	{
		/*	Received, when no live book info available
		*/
		this.fireOnLiveBook(
			{
				moves: [],
				ticket: msg.getVal(),
			} );
	};

	onConnectedToBoundServer ()
	{
		this.boundLoginOk = true;
		this.fireOnIDReceived();

		// NH2020 Comment. Dont do anything on connect. Will be handled by React components.
		// if ( this.callOnConnect )
		// {
		// 	this.callOnConnect()
		// 	this.callOnConnect = null;
		// }
		// else
		// {
		// 	console.log("Request Position on Connect")
		// 	this.requestPosInfo( new Position() );
		// }

	};

	handleTableData ( rMsg )
	{
		// Log.Log("Handle Table Data");
		this.timerClear.stop();
		this.aHTD = new HashTableData();
		this.aHTD.readFromDataBuffer( rMsg.getBuf() );

		var EngineIds = [];

		// NH2020 removed .prototype
		for ( var nLine = 0; nLine < TBrainMove.HASH_LINE_CNT ; ++nLine )
		{
			if ( this.associativeArray[this.aHTD.move[nLine].m_nEngineId] == null )
				EngineIds.push( this.aHTD.move[nLine].m_nEngineId );
			else
				this.aHTD.move[nLine].EngineName = this.associativeArray[this.aHTD.move[nLine].m_nEngineId];
		}

		if ( EngineIds.length > 0 )
		{
			this.requestEngineNames( EngineIds );
		}
		else
		{
			var aPos = this.aHTD.pos.getPosition();

			// NH2021D
			// if ( glApp.panelMgr.tbPanel )
			// 	glApp.panelMgr.tbPanel.update( aPos, this.aHTD );

			// two mechanisms, a ticket handler and an "everything" listener:
			if ( this.tableDataHandlers[rMsg.getVal()])
			{
				this.tableDataHandlers[rMsg.getVal()]( rMsg.getVal(), this.aHTD );
				delete this.tableDataHandlers[rMsg.getVal()];	// just once
			}
			
			this.fireOnTBData( { position: aPos, table: this.aHTD } );
		}
	};

	setEmptyTable ()
	{
		if ( !this.aHTD )
			this.aHTD = new HashTableData();
		// var empty = HashTableData.emptyFactory();

		// NH2021D
		// if ( glApp.panelMgr.tbPanel )
		// 	glApp.panelMgr.tbPanel.update( this.aHTD.pos.getPosition(), empty );
	};

	handleEngineNames ( rMsg )
	{
		var nCount = rMsg.getBuf().readUint32();
		for ( var n = 0; n < nCount; n++ )
		{
			rMsg.getBuf().beginSizedRead();
			var strName = rMsg.getBuf().readASCIIString( 255 );
			var nId = rMsg.getBuf().readUint32();
			this.associativeArray[nId] = strName;
			// var fact = rMsg.getBuf().readFloat64();
			rMsg.getBuf().endSizedRead();
		}

		// NH2020 removed .prototype
		for ( var nLine = 0; nLine < TBrainMove.HASH_LINE_CNT ; ++nLine )
		{
			this.aHTD.move[nLine].EngineName = this.associativeArray[this.aHTD.move[nLine].m_nEngineId];
		}

		var aPos = this.aHTD.pos.getPosition();

		// NH2020 no more glApp.panelMgr
		this.timerClear.stop();
		this.fireOnTBData( { position: aPos, table: this.aHTD } );

		// NH2020 no more glApp.panelMgr
		// if ( glApp.panelMgr.tbPanel )
		// {
		// 	glApp.panelMgr.tbPanel.update( aPos, this.aHTD );
		// 	this.timerClear.stop();
		// 	this.fireOnTBData( { position: aPos, table: this.aHTD } );
		// }
	};

	LogInPlayChessName ()
	{
		// TODO !?
		// NH2020 TeraBrain Message undefined, cant find it anywhere
		Log.Missing();
		// var aMsg = new TeraBrainMessage( SocketsMessageType.TERABRAIN_USER );
		// aMsg.SetUserType( TeraBrainUserMsgType.TBUSER_CHESSSERVER_LOGIN );
		// var pBuf = aMsg.GetPDataBuf();
		// pBuf.WriteString( m_strUsername );
		// pBuf.WriteString( m_strPassword );

		// InquireMachineGuid();
		// if ( !m_sMachineGuid.IsZero() )
		// {
		// 	this.m_sMachineGuid.WriteToDataBuffer( pBuf );
		// 	this.send( aMsg );
		// }
		// else
		// 	CBDebug.assert( false );

	};

	LogOutPlayChessName ( bPostMessage )
	{
		//	m_mapUserEntries.clear();
		// TODO !?

		// NH2020 TeraBrain Message undefined, cant find it anywhere
		Log.Missing();
		// var aMsg = new TeraBrainMessage( SocketsMessageType.TERABRAIN_USER );
		// aMsg.SetUserType( TeraBrainUserMsgType.TBUSER_CHESSSERVER_LOGOUT );
		// var pBuf = aMsg.GetPDataBuf();
		// this.InquireMachineGuid();
		// if ( !m_sMachineGuid.IsZero() )
		// {
		// 	this.m_sMachineGuid.WriteToDataBuffer( pBuf );
		// 	Send( aMsg );
		// }
		// else
		// 	CBDebug.assert( false );

		// this.m_bLoggedIn = false;
		// ResetLoginData();
	};


	ResetLoginData ()
	{
		this.m_nUserReliability = 0;
		this.m_bAdmin = false;
		this.m_nUserId = 0;
	};


	InquireMachineGuid ()
	{
		// NH2020 m_sMachineGuid maybe not defined... maybe due to previous comment
		Log.WillItWork();
		if ( !this.m_sMachineGuid.IsZero() )
			return this.m_sMachineGuid;

		/*GlobalInquiry aInq( IQT_TERABRAIN, TBRAIN_INQ_MACHINEGUID );
      if ( Inquire( aInq ) == INQUIRY_FINISHED )
         m_sMachineGuid = *reinterpret_cast<CBGuid*>( aInq.GetPtrVal() );
      else
         m_sMachineGuid = TeraBrain::ClientMachineInfo::CreateMachineGuid();
      */
		return this.m_sMachineGuid;
	};

	GetNUserId ()
	{
		return this.m_nUserId;
	};

	GetNEngineId ()
	{
		return this.m_nEngineId;
	};

	ShouldUsePlayChessName ()
	{
		return true;
	};

	UseEngineInAnalysis ()
	{
		return false;
	};

	GetMaxServerSilentSeconds ()
	{
		return 30;
	};


	handleChat ( sockMsg )
	{
		Log.Missing();
		// NH2021D
		// var chat = new ChatData;
		// chat.fromSocketsMsg( sockMsg );
		// if ( chat.isToSession() )
		// {
		// 	var strGameTitle = this.watchSessions.getTitleOfSession( chat.getIdSession() );
		// 	if ( strGameTitle.length )
		// 		chat.strMsg = "(" + strGameTitle + "): " + chat.strMsg;
		// 	glApp.panelMgr.chatOut( chat.toString(), "LogKibitzChat" );
		// }
		// else
		// 	glApp.panelMgr.chatOut( chat.toString() );
	};

	// NH2020 Removed As it was double
	//ListenersUtil.initForListeners( TeraBrainLobby );

	// NH2020 
	// "#IFDEBUG"
	// test = function ()
	// {
	// 	this.logIntoChat( "Start Torture" );
	// 	//	this.sendSimple( CB.SockMsgId.TEST );
	// 	this.torturer = new CB.Torturer( "ws://localhost" );
	// 	this.torturer.run();
	// };
	// "#ENDIF"

	// "#IFDEBUG"
	// test2 = function ()
	// {
	// 	this.logIntoChat( "Stop Torture" );
	// 	if ( this.torturer )
	// 		this.torturer.stop();
	// };
	// "#ENDIF"

	sendRandomMessage ()
	{
		for ( var i = 0; i < 10; i++ )
		{
			// NH2020 Commented IFDEBUG COde
			Log.Missing();
			// "#IFDEBUG"

			// var val = Math.floor( Math.random() * 15 );
			// //	CB.LOG( "Rnd=" + val );
			// switch ( val )
			// {
			// 	case 0:
			// 		this.pingServer();
			// 		break;
			// 	case 1:
			// 		this.changeGroup( 13 );
			// 		break;
			// 	case 2:
			// 		this.changeGroup( 14 );
			// 		break;
			// 	case 3:
			// 		this.requestChannels();
			// 		break;
			// 	case 4:
			// 		this.updatePlayerStatus();
			// 		break;
			// 	case 5:
			// 		this.lists.requestGameList( true );
			// 		break;
			// 	case 6:
			// 		//this.lists.sendSeek( new CB.ClockParams( 3, 2 ) );
			// 		break;
			// 	case 7:
			// 		this.lists.onShowPlayers();
			// 		this.lists.onHideGames();
			// 		break;
			// 	case 8:
			// 		this.lists.onShowGames();
			// 		this.lists.onHidePlayers();
			// 		break;
			// 	case 9:
			// 	case 10:
			// 	case 11:	// irgendeinen Dreck senden
			// 	case 12:
			// 		var msg = new WebSockMessage( 7000 + Math.floor( Math.random() * 100 ) );
			// 		msg.address( this.getNId(), CB.ConnectId.SERVER );
			// 		if ( msg.getType() != SockMsgId.LOGOFF && msg.getType() != SockMsgId.LOGON )
			// 			this.send( msg );
			// 		break;
			// 	case 13:
			// 	case 14:	// dito für User Msg
			// 		var msg = new WebSockMessage( SockMsgId.USER );
			// 		msg.setUserType( 1000 + Math.floor( Math.random() * 100 ) );
			// 		msg.address( this.getNId(), CB.ConnectId.SERVER );
			// 		this.send( msg );
			// 		break;
			// }
			// "#ENDIF"
		}
	};

	static InitListeners()
	{
		if ( !TeraBrainLobby.prototype.fireEvent )
		{
			ListenersUtil.initForListeners( TeraBrainLobby );
			ListenersUtil.addEvent( TeraBrainLobby, "LiveBook" );
			ListenersUtil.addEvent( TeraBrainLobby, "TBData" );
			ListenersUtil.addEvent( TeraBrainLobby, "LiveBookDisplay" );

			// NH2020 Added this listener
			ListenersUtil.addEvent( TeraBrainLobby, "IDReceived")
		}
	}

}








