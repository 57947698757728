// jb 12.11.2013

// "use strict";

import { CBGuid } from "common/Tools/CBGuid.js"
import { CPUInfo } from "common/Chess/TeraBrain/CPUInfo"
import { isFlag } from "common/Tools/Tools"

export class MachineInfo
{
	static PROPFLAGS = {
		PROP_NONE: 0x00,
		PROP_HT: 0x01
	};

	constructor () {
		this.m_aCPUInfo = new CPUInfo();
		this.m_nSpeed = 0;
		this.m_nFlags = 0;
	};

	read ( buf ) {
	};


	GetCPUInfo () {
		return this.m_aCPUInfo;
	};


	IsValid () {
		return this.m_nSpeed > 0;
	};

	GetNSpeed () {
		return this.m_nVisits;
	};

	SetNSpeed ( nSpeed ) {
		this.m_nVisits = nSpeed;
	};

	HasHyperThreading () {
		// NH2020 changed to lowerCase, used Flag function from Tools
		return isFlag( MachineInfo.PROPFLAGS.PROP_HT );
		//return ISFLAG( PROPFLAGS.PROP_HT );
	};

	writeToDataBuffer ( rDB ) {
		rDB.beginSizedWrite();

		this.m_aCPUInfo.writeToDataBuffer( rDB );
		rDB.writeUint32( this.m_nSpeed );
		rDB.writeUint32( this.m_nFlags );
		rDB.endSizedWrite();
		return true;
	};

	ReadFromDataBuffer ( rDB ) {
		var bRet = true;

		/*   LocalSizedRead aLSR( rDB );
   
         bRet = m_aCPUInfo.ReadFromDataBuffer( rDB );
         m_nSpeed = rDB.ReadUnsigned();
         m_nFlags = rDB.ReadUnsigned();
         */
		return bRet;
	};

	/* duplicated code from CBGuid::CreateFromHardware
      We want unique GUIDs in Debug mode.
   */
	CreateMachineGuid () {
		var aGuid = new CBGuid();
		aGuid.CreateFromHardware();
		return aGuid;
	};

}




