

import React from 'react';
import styles from "./CBButtonBadge.module.css";
import iconStyles from "CBReact/Components/DesignElements/Icons/CBIcons.module.css"

import { CBMCloseIcon } from 'CBReact/Components/DesignElements/Icons/CBIcons';
import { checkPropsAndRenderComponent } from 'CBReact/Utils/DebugUtils';

export function CBButtonBadge (props)
{

    function renderCBButtonBadge ()
    {
        return (
            <button className={`${styles.base} ${styles.clickable} ${props.renderClose && styles.deletable} ${props.size === "small" ? styles.small : styles.big}`} 
            onClick={e => props.onClick(e, props.index) } style={props.style}>
                <div className={`${styles.text} ${props.size === "small" ? styles.small : styles.big}`}>{props.children}</div>
                {props.renderClose && <div className={styles.close}><CBMCloseIcon className={iconStyles.headerIcon}/></div>}
            </button>
        );
    }

    return checkPropsAndRenderComponent([props.onClick], renderCBButtonBadge);
}

function badgePropsAreEqual(prev, next)
{
    return prev.children === next.children && prev.onClick === next.onClick;
}

export const CBMButtonBadge = React.memo(CBButtonBadge, badgePropsAreEqual);