// jb 12.11.2013

// "use strict";

import { PosTree } from "common/Chess/Format/PosTree"
import { Position } from "common/Chess/Logic/Position"

export class HashPosition 
{
	//testDataBuffers( 200 );

	constructor ( rPos ) 
	{
		this.m_cPos = [];
		for ( var n = 0 ; n < 24; n++ )
			this.m_cPos.push( 0 );

		if ( rPos ) {
			var aPosTree = new PosTree();
			aPosTree.posToHuff3( rPos, this.m_cPos, this.m_cPos.length );
		}
		/*	memset( m_cPos, 0, sizeof(m_cPos) );
		const_cast<ChessPosition &>(rPos).NormalizeEPFile();
		var a
		PosToHuff3( rPos, m_cPos, sizeof(m_cPos) );
		ASSERT( Len() <= sizeof(m_cPos) );*/
	};

	Len () {
		return ( this.m_cPos[0] & 31 );
	};

	IsSet () {
		return this.m_cPos[0] != 0;
	};

	Clear () {
		for ( var n = 0; n < this.m_cPos.Length; n++ )
			this.m_cPos[n] = 0;
	};

	GetBTM () {
		return ( this.m_cPos[1] & 0x80 ) != 0;
	};

	HashPosition ( rHP, nNormFlags ) {
		/*   if( nNormFlags )
         {	std::auto_ptr<ChessPosition> pPos( ChessPosition::Factory( GAME_TYPE_CLASSICAL_CHESS ) );
            rHP.FillPos( *pPos );
            pPos->UnNormalize( nNormFlags );
            memset( m_cPos, 0, sizeof(m_cPos) );
            PosToHuff3( *pPos, m_cPos, sizeof(m_cPos) );
            ASSERT( Len() <= sizeof(m_cPos) );
         } else
         {	memcpy( m_cPos, rHP.m_cPos, sizeof(m_cPos) );
            ASSERT( Len() <= sizeof(m_cPos) );
         }*/
	};

	writeToDataBuffer ( rDB ) {

		rDB.beginSizedWrite();
		//LocalSizedWrite aLSW( rDB );

		rDB.writeByteArray( this.m_cPos, this.m_cPos.length );

		rDB.endSizedWrite();
		return true;
	};

	readFromDataBuffer ( rDB ) {
		var bRet = false;

		rDB.beginSizedRead();
		for ( var n = 0; n < this.m_cPos.length; n++ )
			this.m_cPos[n] = rDB.readByte();
		rDB.endSizedRead();


		return bRet;
	};

	getPosition () {

		var aPosTree = new PosTree();
		var pos = new Position();
		aPosTree.huff3ToPos( pos, this.m_cPos, this.m_cPos.length );
		return pos;

	};

	FillPos ( rPos ) {
		//  ASSERT( Len() <= sizeof(m_cPos) );
		//  if( ( Len() > sizeof(m_cPos) ) || ( HuffToPos3( rPos, m_cPos, sizeof(m_cPos) ) != EC_OK ) )
		//   rPos.SetGroundPos();
	};

	SetPos ( rPos ) {
		/*ASSERT( !IsSet() );
      ASSERT( rPos.IsClassicalChess() );
      const_cast<ChessPosition &>(rPos).NormalizeEPFile();
      PosToHuff3( rPos, m_cPos, sizeof(m_cPos) );
      ASSERT( IsSet() && ( Len() <= sizeof(m_cPos) ) );*/
	};

	Normalize ( nNormFlags ) {
		if ( nNormFlags > 0 ) {
			/*       std::auto_ptr<ChessPosition> pPos( ChessPosition::Factory( GAME_TYPE_CLASSICAL_CHESS ) );
               FillPos( *pPos );
               if( nNormFlags != pPos->Normalize() )
               {	ASSERT( 0 );	//	logischer Fehler in Normalisierung
               }
               PosToHuff3( *pPos, m_cPos, sizeof(m_cPos) );
             ASSERT( IsSet() && ( Len() <= sizeof(m_cPos) ) );*/
		}
	};

	UnNormalize ( nNormFlags ) {
		/* if( nNormFlags )
      {	std::auto_ptr<ChessPosition> pPos( ChessPosition::Factory( GAME_TYPE_CLASSICAL_CHESS ) );
         FillPos( *pPos );
         pPos->UnNormalize( nNormFlags );
         PosToHuff3( *pPos, m_cPos, sizeof(m_cPos) );
         ASSERT( IsSet() && ( Len() <= sizeof(m_cPos) ) );
      }*/
	};

	IsStartPosition () {
		//return this->operator==( GetStartPos() );
		return false;
	};

	IsFinalPosition () {
		//const std::auto_ptr<ChessPosition> pPos( CreatePosition() );
		//return DrawChecker().Check( *pPos ) != POS_NOT_DECIDED;
		return false;
	};


	GetStartPos () {
		//           if ( !m_sStartPosition.IsSet() )
		//         {	std::auto_ptr<ChessPosition> pPos( ChessPosition::Factory( GAME_TYPE_CLASSICAL_CHESS ) );
		//         m_sStartPosition = HashPosition( *pPos );
		//    }

		// NH2020 Commented startpos is undefined
		//return m_sStartPosition;
	};
}


