
//"use strict";

// "NOMINIFY=Date,setYear,getFullYear"

//CB.namespace( "CB.DOM" );

import { strUtil } from "common/Tools/Tools"
import { Log } from "common/Tools/Log"
import { System } from "common/Tools/System"
// import { glApp } from "common/App/App"

export class DOM 
{
	static get ( id )
	{
		return document.getElementById( id );
	};

	static inputAdd ( msg, className )
	{
		// NH2020
		/*
		var node = document.createTextNode( msg ),
			div = document.createElement( 'div' );
		if ( className !== undefined )
		{
			div.className = className;
		}
		else
		{
			className = "Text";
		}
		document.body.appendChild( div );

		div.appendChild( node );
		
		if ( CB.input !== undefined )
		{
			document.body.insertBefore( div, CB.input );
		}
		else 
		{
			document.body.appendChild( div );
		}
		*/
	};

	static add ( text, header, className )
	{
		var div = document.createElement( 'div' );
		var node;
		if ( header && header.length )
		{
			node = document.createElement( header );
			node.innerText = text;
		}
		else
			node = document.createTextNode( text );

		if ( className !== undefined )
		{
			div.className = className;
		}
		div.appendChild( node );
		document.body.appendChild( div );
	};

	static addUrl ( url, text, className )
	{
		var div = document.createElement( 'div' );
		var node = document.createElement( 'a' );
		node.href = url;
		node.target = "_blank";
		if ( text && text.length )
			node.textContent = text;
		if ( className !== undefined )
		{
			div.className = className;
		}
		div.appendChild( node );
		document.body.appendChild( div );
	};

	static addBr ()
	{
		var node = document.createElement( 'br' );
		document.body.appendChild( node );
	};

	static insertBefore ( msg, insertId, styleClass )
	{
		var node = document.createTextNode( msg ),
			div = document.createElement( 'div' );
		if ( styleClass !== undefined )
		{
			div.className = styleClass;
		}
		else
		{
			div.className = "Text";
		}
		document.body.appendChild( div );

		//var div = document.createElement("div");
		div.appendChild( node );
		if ( document.getElementById( insertId ) !== undefined )
		{
			document.body.insertBefore( div, document.getElementById( insertId ) );
		}
		else
		{
			document.body.appendChild( div );
		}
	};

	static getCookie ( sName )
	{
		var oCrumbles = document.cookie.split( ';' );
		for ( var i = 0; i < oCrumbles.length; i++ )
		{
			//		alert( oCrumbles[i] );
			var oPair = oCrumbles[i].split( '=' );
			var sKey = oPair[0].trim(); // .toLowerCase();
			var sValue = oPair.length > 1 ? oPair[1] : '';
			if ( sKey == sName )
				return decodeURIComponent( sValue );
		}
		return '';
	};

	//CB.DOM.getDecode64Cookie = function ( sName )
	//{
	//	var val;

	//	var oCrumbles = document.cookie.split( ';' );
	//	for ( var i = 0; i < oCrumbles.length; i++ )
	//	{
	//		var oPair = oCrumbles[i].split( '=' );
	//		var sKey = oPair[0].trim(); // .toLowerCase();
	//		var sValue = oPair.length > 1 ? oPair[1] : '';
	//		if ( sKey == sName && sValue )
	//			val = sValue;
	//	}
	//	if ( val )
	//	{
	//		try
	//		{
	//			val = CB.strUtil.base64.decode( val );
	//			//	val = decodeURIComponent( val );
	//			val = CB.strUtil.htmlDecode( val );
	//		}
	//		catch( x )
	//		{
	//			val = "";
	//		}
	//	}
	//	return val;
	//};

	//CB.DOM.setEncode64Cookie = function ( sName, sValue )
	//{
	//	//	var val = encodeURIComponent( sValue );
	//	var val = CB.strUtil.htmlEncode( sValue );	// compatible to cookie stored in Default.aspx.cs of PlaychessLogin
	//	val = CB.strUtil.base64.encode( val );
	//	var oDate = new Date();
	//	oDate.setYear( oDate.getFullYear() + 1 );

	//	var domain = ".chessbase.com";
	//	"#IFDEBUG"
	//	domain = "localhost";
	//	"#ENDIF"

	//	var sCookie = sName + '=' + val + ';expires=' + oDate.toGMTString() + ';path=/;';

	//	if ( domain.length )
	//		sCookie += 'domain=' + domain + ';';

	////	alert( sCookie );

	//	document.cookie = sCookie;

	////	alert( document.cookie );	
	//};


	static codeAccountName ( name )
	{
		return strUtil.base64.encode( strUtil.htmlEncode( name ) );
	}

	static setAccountNameCookie ( name )
	{
		if ( name == "guest" )
			return;

		DOM.setCodedCookie( "AccountName64", name );

		//var value = CB.strUtil.htmlEncode( name );
		//value = CB.strUtil.base64.encode( value );
		//CB.DOM.setCookie( "AccountName64", value );
		//if ( name.toLowerCase() != "guest" )
		//	CB.DOM.setCookie( "AccountName64", this.codeAccountName( name ) );
	};

	static getAccountNameCookie ( doHtmlDecode )
	{
		return DOM.getCodedCookie( "AccountName64", doHtmlDecode );

		//var value = CB.DOM.getCookie( "AccountName64" );
		//value = CB.strUtil.base64.decode( value );
		//if ( doHtmlDecode )
		//{
		//	value = CB.strUtil.htmlDecode( value );
		//	value = value.replace( "%25", "%" );
		//	value = value.replace( "%20", " " );
		//}

		//return value;
	};

	static setCodedCookie ( name, value )
	{
		DOM.setCookie( name, DOM.codeAccountName( value ) );
	};

	static getCodedCookie ( name, doHtmlDecode )
	{
		var value = DOM.getCookie( name );
		value = strUtil.base64.decode( value );
		if ( doHtmlDecode )
		{
			value = strUtil.htmlDecode( value );
			value = value.replace( "%25", "%" );
			value = value.replace( "%20", " " );
		}

		return value;
	};

	static setCookie ( sName, sValue )
	{
		var oDate = new Date();
		var strExpires;
		if ( sValue )
		{
			oDate.setYear( oDate.getFullYear() + 1 );
			strExpires = oDate.toGMTString();
		}
		else
			strExpires = 'Thu, 01 Jan 1970 00:00:01 GMT';

		var domain = ".chessbase.com";
		var path = "/";

		if ( document.URL.search( /localhost/i ) != -1 )
		{
			domain = "localhost";
			// domain = "";
	}


		//"#IFDEBUG"
		//// domain = "localhost";
		//domain = "";		// creates duplicate cookies in Chrome, e.g. cookies with mygames.chessbase.com and *.chessbase.com.
		//"#ENDIF"

		var sCookie = sName + '=' + encodeURIComponent( sValue ) + ';expires=' + strExpires + ';path=/;'
		var cookieFront = sCookie;
		if ( domain.length )
			sCookie += 'domain=' + domain + ';';

		sCookie += 'SameSite=Lax;';

	//	alert( sCookie );
		document.cookie = sCookie;
	// alert( document.cookie );
	};

	// see developer.mozilla.org

	//CB.DOM.docCookies =
	//{
	//	getItem: function ( sKey )
	//	{
	//		if ( !sKey ) { return null; }
	//		return decodeURIComponent( document.cookie.replace( new RegExp( "(?:(?:^|.*;)\\s*" + encodeURIComponent( sKey ).replace( /[\-\.\+\*]/g, "\\$&" ) + "\\s*\\=\\s*([^;]*).*$)|^.*$" ), "$1" ) ) || null;
	//	},
	//	setItem: function ( sKey, sValue, vEnd, sPath, sDomain, bSecure )
	//	{
	//		if ( !sKey || /^(?:expires|max\-age|path|domain|secure)$/i.test( sKey ) ) { return false; }
	//		var sExpires = "";
	//		if ( vEnd )
	//		{
	//			switch ( vEnd.constructor )
	//			{
	//				case Number:
	//					sExpires = vEnd === Infinity ? "; expires=Fri, 31 Dec 9999 23:59:59 GMT" : "; max-age=" + vEnd;
	//					break;
	//				case String:
	//					sExpires = "; expires=" + vEnd;
	//					break;
	//				case Date:
	//					sExpires = "; expires=" + vEnd.toUTCString();
	//					break;
	//			}
	//		}
	//		document.cookie = encodeURIComponent( sKey ) + "=" + encodeURIComponent( sValue ) + sExpires + ( sDomain ? "; domain=" + sDomain : "" ) + ( sPath ? "; path=" + sPath : "" ) + ( bSecure ? "; secure" : "" );
	//		return true;
	//	},
	//	removeItem: function ( sKey, sPath, sDomain )
	//	{
	//		if ( !this.hasItem( sKey ) ) { return false; }
	//		document.cookie = encodeURIComponent( sKey ) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + ( sDomain ? "; domain=" + sDomain : "" ) + ( sPath ? "; path=" + sPath : "" );
	//		return true;
	//	},
	//	hasItem: function ( sKey )
	//	{
	//		if ( !sKey ) { return false; }
	//		return ( new RegExp( "(?:^|;\\s*)" + encodeURIComponent( sKey ).replace( /[\-\.\+\*]/g, "\\$&" ) + "\\s*\\=" ) ).test( document.cookie );
	//	},
	//	keys: function ()
	//	{
	//		var aKeys = document.cookie.replace( /((?:^|\s*;)[^\=]+)(?=;|$)|^\s*|\s*(?:\=[^;]*)?(?:\1|$)/g, "" ).split( /\s*(?:\=[^;]*)?;\s*/ );
	//		for ( var nLen = aKeys.length, nIdx = 0; nIdx < nLen; nIdx++ ) { aKeys[nIdx] = decodeURIComponent( aKeys[nIdx] ); }
	//		return aKeys;
	//	}
	//};

	static clearCookie ( sName )
	{
		DOM.setCookie( sName, '' );
	}


	static addCls ( el, cls )
	{
		if ( el === undefined )
			return;

		if ( el.className.length > 0 )
			el.className += " " + cls;
		else
			el.className = cls;
	}

	static removeCls ( el, cls )
	{
		if ( el === undefined )
			return;

		var ex = new RegExp( "\b" + cls + "\b" );
		el.className = el.className.replace( ex, '' );
	}

	static resize ( el, w, h )
	{
		if ( typeof el === "string" || typeof el === "number" )
			el = document.getElementById( el );
		if ( el !== undefined )
		{
			el.style.width = w;
			el.style.height = h;
		}
	};

	static setText( el, txt )
	{
		if ( !el )
			return;
		if ( typeof el.textContent == "undefined" )
			el.innerText = txt;
		else
			el.textContent = txt;
	};

	static textOut( msg, className )
	{
		// NH2020
		/*
		if ( CB.Logger )
		{
			Log.LOG( msg, className );
		}
		
		else
		{
			DOM.add( msg, "", className );
			if ( CB.input !== undefined )
				CB.input.scrollIntoView(); // input field in the HTML body
		}*/

		Log.Log(msg, className);
	}

	static TRACE( msg )
	{
		// NH2020 

		// if ( glApp.config.debug )
		// {
		// 	DOM.textOut( msg, "LogTrace" );
		// 	//CB.DOM.textAdd(msg, "Trace");
		// 	//CB.input.scrollIntoView();
		// }
		
	}

	static EXCEPTION( msg, caller )
	{
		var str = msg;
		if ( caller !== undefined )
		{
			str += " (" + caller + ")";
		}
		DOM.textOut( str, "LogException" );
	}

	static CHAT( msg )
	{
		DOM.textOut( msg, "LogChat" );
	}

}


export class CSS
{

	constructor ()
	{
		// NH2020 Changed Docstyle = null
		this.docStyle = null;
		this.iconCache = {}
	}

	getStyleSheet ()
	{
		// NH2020 Changed Docstyle = null
		if ( this.docStyle === null )
		{
			var style = document.createElement( "style" );
			style.setAttribute( "media", "screen" );
			// style.setAttribute("media", "@media only screen and (max-width : 1024px)")

			// WebKit hack :(
			style.appendChild( document.createTextNode( "" ) );

			// Add the <style> element to the page
			document.head.appendChild( style );

			this.docStyle = style.sheet;
		}

		return this.docStyle;
	};

	static createStyleSheet ( id )
	{
		var style = document.createElement( "style" );
		style.id = id;
		style.setAttribute( "media", "screen" );
		// style.setAttribute("media", "@media only screen and (max-width : 1024px)")

		// WebKit hack :(
		style.appendChild( document.createTextNode( "" ) );

		// Add the <style> element to the page
		document.head.appendChild( style );

		return style.sheet;
	};

	addRules ( selector, rule )
	{
		try
		{
			if ( this.getStyleSheet().addRule !== undefined )
				this.getStyleSheet().addRule( selector, rule );
			else
			{
				this.getStyleSheet().insertRule( selector + " { " + rule + " }", this.getStyleSheet().cssRules.length );
			}
		}
		catch( x )
		{
			Log.Exception( "CSS:addRules", x );
		}
	};

	static getGradToRight ( c1, c2 )
	{
		return "background-image:linear-gradient( to right," + c1 + "," + c2 + ")";
	};

	static getCenteredText ()
	{
		return "display:block;margin-left:auto;margin-right:auto;text-align:center";
	};

	static getHeader ( h, text, style )
	{
		if ( style )
			return '<' + h + ' style="' + style + '">' + text + '</' + h + '>';
		else
			return '<' + h + '>' + text + '</' + h + '>';
	};

	findKeyframesRule ( rule )
	{
		var ss = this.getStyleSheet();

		for ( var j = 0; j < ss.cssRules.length; ++j )
		{
			if ( ss.cssRules[j].type == window.CSSRule.KEYFRAMES_RULE && ss.cssRules[j].name == rule )
				return ss.cssRules[j];
		}

		// create if not found:

		var webk = System.isWebKit() ? "-webkit-" : "";

		if ( ss.insertRule === undefined )
			ss.addRule( "@" + webk + "keyframes " + rule + "{ 0% { top: 10px } 100% { top: 30px } }" );
		else
			ss.insertRule( "@" + webk + "keyframes " + rule + "{ 0% { top: 10px } 100% { top: 30px } }", ss.cssRules.length );

		for ( var j = 0; j < ss.cssRules.length; ++j )
		{
			if ( ss.cssRules[j].type == window.CSSRule.KEYFRAMES_RULE && ss.cssRules[j].name == rule )
				return ss.cssRules[j];
		}

		return null;
	};

	addIcon ( _icon )
	{
		// NH2021D		
		// var icons = [].concat( _icon );

		// for ( var i = 0; i < icons.length; i++ )
		// {
		// 	if ( icons[i].length > 1 && this.iconCache[icons[i]] == undefined )
		// 	{
		// 		this.getStyleSheet().insertRule( "." + icons[i] + "{ background-image: url( " + glApp.config.getRibbonPath() + icons[i] + ".png ) !important; }", 0 );
		// 		this.iconCache[icons[i]] = 1;
		// 	}
		// }

	};

}

