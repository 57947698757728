// mw 26.2.2013
// ES6 28.2.2020

import { Square } from "common/Chess/Logic/Chess";
import { HSLColor } from 'common/Tools/Graphics/Color'
import { CanvasTools } from 'common/Tools/Graphics/CanvasTools'
import { Rect } from "common/Tools/Graphics/GeometricalObjects";

function signX( x )
{
	return x > 0 ? 1 : x < 0 ? -1 : 0;
}

var attrId = 0;

export class BoardArrow 
{
	constructor ( from, to, rgba, lineWidthPercent, dash )
	{
		this.from = from;
		this.to = to;
		this.rgba = rgba;
		this.lineWidthPercent = lineWidthPercent;
		this.id = ++attrId;
		this.dash = dash;
	};

	draw( ctx )
	{
		ctx.save();
		//		Log.Log( "Draw: " + this.toString(), "LogBold" );
		var pxFrom = this.boardControl.getSquareCenterPos( this.from ),
		   pxTo = this.boardControl.getSquareCenterPos( this.to );

		var fromFile = Square.getFile( this.from ), fromRank = Square.getRank( this.from ),
		   toFile = Square.getFile( this.to ), toRank = Square.getRank( this.to );
		var dy = signX( toRank - fromRank ),
		   dx = signX( toFile - fromFile );

		if ( this.boardControl.blackIsBottom )
		{
			// NH2020
			pxTo.x += dx * this.boardControl.nSqPix / 4;
			pxTo.y -= dy * this.boardControl.nSqPix / 4;
			// pxTo.x += dx * this.boardControl.nDesiredSqPix / 4;
			// pxTo.y -= dy * this.boardControl.nDesiredSqPix / 4;
		}
		else
		{
			// NH2020
			pxTo.x -= dx * this.boardControl.nSqPix / 4;
			pxTo.y += dy * this.boardControl.nSqPix / 4;
			// pxTo.x -= dx * this.boardControl.nDesiredSqPix / 4;
			// pxTo.y += dy * this.boardControl.nDesiredSqPix / 4;
		}

		// NH2020
		var headW = this.boardControl.nSqPix * 0.5;
		// var headW = this.boardControl.nDesiredSqPix * 0.5;

		if ( this.lineWidthPercent )
		{
			// NH2020
			ctx.lineWidth = Math.max( 1, this.boardControl.nSqPix * this.lineWidthPercent * 0.01 );
			// ctx.lineWidth = Math.max( 1, this.boardControl.nDesiredSqPix * this.lineWidthPercent * 0.01 );
			headW = ctx.lineWidth * 5;
		}
		else
		{
			// NH2020
			ctx.lineWidth = Math.max( 1, this.boardControl.nSqPix * 0.09 );
			// ctx.lineWidth = Math.max( 1, this.boardControl.nDesiredSqPix * 0.09 );
		}

		ctx.lineCap = 'round';

		if ( this.rgba )
		{
			BoardArrow.setStrokeFillStyles( ctx, this.rgba, pxFrom.x, pxFrom.y, pxTo.x, pxTo.y );
		} else
		{
			//ctx.strokeStyle = 'rgba( 250, 220, 10, 0.72 )';	// was 0.6		stroke style is arrow shaft
			//ctx.fillStyle = 'rgba( 250, 220, 10, 0.9 )'; // was 0.6			fill style is arrow head
			var hsl = BoardArrow.getHslYellow();
			ctx.strokeStyle = new HSLColor( hsl.h, hsl.s, hsl.l, 0.75 ).toString();
			ctx.fillStyle = new HSLColor( hsl.h, hsl.s, hsl.l, 0.9 ).toString();
		}

		BoardArrow.drawArrow( ctx, pxFrom.x, pxFrom.y, pxTo.x, pxTo.y, 3, 1, Math.PI / 8, headW, ctx.lineWidth * 0.8, this.rgba, this.dash );

		ctx.restore();

		return this._getClipRect();
	};


	static setStrokeFillStyles = function ( ctx, rgba, fromx, fromy, tox, toy )
	{
		if ( rgba && ( rgba.alpha || rgba.hue ) )
		{
			var grad = ctx.createLinearGradient( fromx, fromy, tox, toy );
			var alpha = rgba.alpha;
			rgba.alpha = 0.15;
			grad.addColorStop( 0.0, rgba );
			rgba.alpha = 0.4;
			grad.addColorStop( 0.4, rgba );
			rgba.alpha = 0.55;
			grad.addColorStop( 0.60, rgba );
			rgba.alpha = alpha;
			grad.addColorStop( 1.0, rgba );
			ctx.strokeStyle = grad;
			ctx.fillStyle = rgba;
		}
		else
		{
			if ( rgba && rgba.search( "hsla" ) === 0 )
			{
				var rx = /hsla\(\s*(\d{1,3}),\s*(\d{1,3})%,\s*(\d{1,3})%,\s(.+)\)/g;
				var hsla = rx.exec( rgba );
				grad = ctx.createLinearGradient( fromx, fromy, tox, toy );

				var strHsla = "hsla( {0}, {1}%, {2}%, {3} )";
				var hslaFrom = String.f( strHsla, hsla[1], hsla[2], hsla[3], "0.15" );
				var hslaFrom2 = String.f( strHsla, hsla[1], hsla[2], hsla[3], "0.4" );
				var hslaMid = String.f( strHsla, hsla[1], hsla[2], hsla[3], hsla[4] * 0.55 );
				var hslaTo = String.f( strHsla, hsla[1], hsla[2], hsla[3], hsla[4] );

				grad.addColorStop( 0.0, hslaFrom );
				grad.addColorStop( 0.4, hslaFrom2 );
				grad.addColorStop( 0.60, hslaMid );
				grad.addColorStop( 1.0, hslaTo );
				ctx.strokeStyle = grad;
				ctx.fillStyle = rgba;
			}
			else
			{
				ctx.fillStyle = rgba;
				ctx.strokeStyle = rgba;
			}
		}
	};

	undo( ctx )
	{

		var fromFile = Square.getFile( this.from ),
		   fromRank = Square.getRank( this.from ),
		   toFile = Square.getFile( this.to ),
		   toRank = Square.getRank( this.to );
		var dy = signX( toRank - fromRank ),
		   dx = signX( toFile - fromFile );
		var file = fromFile, rank = fromRank;

		/*	if ( ( (  Math.abs( file-toFile)  == 1 && Math.abs( rank-toRank) == 2  ) ||
						(  Math.abs( file-toFile)  == 2 && Math.abs( rank-toRank) == 1 ) ) ) {
	
				 if ( Math.abs( rank - toRank) == 2  ) {
					  var aFile1 = Square.getFile( file );
					 var aRank1 = Square.getRank(( rank - toRank) / 2  );
					  var neighbour1 = Square.I( aFile1, aRank1 );
					  this.boardControl.restoreSquare( ctx, neighbour1 );
	
					  var aFile2 = Square.getFile( toFile );
					 var aRank2 = Square.getRank(( rank - toRank) / 2  );
					  var neighbour2 = Square.I( aFile2, aRank2 );
					  this.boardControl.restoreSquare( ctx, neighbour2 );
				 }
				 else {
					  var aFile1 = Square.getFile( (file - toFile) /2 );
					 var aRank1 = Square.getRank( rank );
					  var neighbour1 = Square.I( aFile1, aRank1 );
					  this.boardControl.restoreSquare( ctx, neighbour1 );
	
					  var aFile2 = Square.getFile( ( file - toFile) / 2 );
					 var aRank2 = Square.getRank( toRank );
					  var neighbour2 = Square.I( aFile2, aRank2 );
					  this.boardControl.restoreSquare( ctx, neighbour2 );
				 }
	
			}
			  */

		while ( file !== toFile || rank !== toRank )
		{
			//	Log.Log( "Restore1: " + Square.getStrXY( file, rank ) );
			this.boardControl.restoreSquare( ctx, Square.I( file, rank ) );

			// restore neighbouring squares if not a rook move
			if ( dx !== 0 && dy !== 0 )
			{
				var neighbour1 = Square.I( file + dx, rank ),
				   neighbour2 = Square.I( file, rank + dy );
				if ( neighbour1 >= 0 && neighbour1 < 64 )
				{
					this.boardControl.restoreSquare( ctx, neighbour1 );
					//			Log.Log( "Restore2: " + Square.getStr( neighbour1 ) );
				}
				if ( neighbour2 >= 0 && neighbour2 < 64 )
				{
					this.boardControl.restoreSquare( ctx, neighbour2 );
					//		Log.Log( "Restore3: " + Square.getStr( neighbour2 ) );
				}
			}
			if ( file !== toFile )
				file += dx;
			if ( rank !== toRank )
				rank += dy;
		}
		if ( file >= 0 && rank >= 0 )
			this.boardControl.restoreSquare( ctx, Square.I( file, rank ) );
		//		Log.Log( "Restore4: " + Square.getStrXY( file, rank ) );
		return this._getClipRect();
	};

	_getClipRect()
	{
		var rect = this.boardControl.getSquareRect( this.from );
		rect = rect.getUnion( this.boardControl.getSquareRect( this.to ) );

		if ( this.lineWidthPercent )
		{
			// NH2020
			var delta = this.boardControl.nSqPix * this.lineWidthPercent * 0.01;
			// var delta = this.boardControl.nDesiredSqPix * this.lineWidthPercent * 0.01;

			rect.expand( delta, delta );
		}

		return rect;
	};

	toString()
	{
		return "Arrow: " + Square.toString( this.from ) + "-" + Square.toString( this.to );
	};

	getId()
	{
		return "arrow" + this.from + this.to;
	}

	////////////////////////////////////////////////////////////////////////////////////////////////////////////////
	// Arrow drawing by Patrick Horgans:
	static drawLineAngle ( ctx, x0, y0, angle, length )
	{
		//	ctx.save();
		ctx.moveTo( x0, y0 );
		ctx.lineTo( x0 + length * Math.cos( angle ), y0 + length * Math.sin( angle ) );
		ctx.stroke();
		//	ctx.restore();
	}

	static drawHead = function ( ctx, x0, y0, x1, y1, x2, y2, style )
	{
		// all cases do this.
		//		ctx.save();
		ctx.beginPath();
		ctx.moveTo( x0, y0 );
		ctx.lineTo( x1, y1 );
		ctx.lineTo( x2, y2 );
		switch ( style )
		{
			default:
				break;
			case 0:
				// curved filled, add the bottom as an arcTo curve and fill
				var backdist = Math.sqrt(( ( x2 - x0 ) * ( x2 - x0 ) ) + ( ( y2 - y0 ) * ( y2 - y0 ) ) );
				ctx.arcTo( x1, y1, x0, y0, .55 * backdist );
				ctx.fill();
				break;
			case 1:
				// straight filled, add the bottom as a line and fill.
				ctx.lineTo( x0, y0 );
				ctx.fill();
				break;
			case 2:
				// unfilled head, just stroke.
				ctx.stroke();
				break;
			case 3:
				//filled head, add the bottom as a quadraticCurveTo curve and fill
				var cpx = ( x0 + x1 + x2 ) / 3;
				var cpy = ( y0 + y1 + y2 ) / 3;
				ctx.quadraticCurveTo( cpx, cpy, x0, y0 );
				ctx.fill();
				break;
			case 4:
				//filled head, add the bottom as a bezierCurveTo curve and fill
			{	let cp1x, cp1y, cp2x, cp2y, backdist;
				let shiftamt = 5;
				if ( x2 === x0 )
				{
					// Avoid a divide by zero if x2==x0
					backdist = y2 - y0;
					cp1x = ( x1 + x0 ) / 2;
					cp2x = ( x1 + x0 ) / 2;
					cp1y = y1 + backdist / shiftamt;
					cp2y = y1 - backdist / shiftamt;
				} else
				{
					backdist = Math.sqrt(( ( x2 - x0 ) * ( x2 - x0 ) ) + ( ( y2 - y0 ) * ( y2 - y0 ) ) );
					var xback = ( x0 + x2 ) / 2;
					var yback = ( y0 + y2 ) / 2;
					var xmid = ( xback + x1 ) / 2;
					var ymid = ( yback + y1 ) / 2;

					var m = ( y2 - y0 ) / ( x2 - x0 );
					var dx = ( backdist / ( 2 * Math.sqrt( m * m + 1 ) ) ) / shiftamt;
					var dy = m * dx;
					cp1x = xmid - dx;
					cp1y = ymid - dy;
					cp2x = xmid + dx;
					cp2y = ymid + dy;
				}

				ctx.bezierCurveTo( cp1x, cp1y, cp2x, cp2y, x0, y0 );
				ctx.fill();
				}	break;
		}
		//	ctx.restore();
	};


	//var drawArcedArrow = function ( ctx, x, y, r, startangle, endangle, anticlockwise, style, which, angle, d )
	//{
	//	style = typeof ( style ) != 'undefined' ? style : 3;
	//	which = typeof ( which ) != 'undefined' ? which : 1; // end point gets arrow
	//	angle = typeof ( angle ) != 'undefined' ? angle : Math.PI / 8;
	//	d = typeof ( d ) != 'undefined' ? d : 10;
	//	//	ctx.save();
	//	ctx.beginPath();
	//	ctx.arc( x, y, r, startangle, endangle, anticlockwise );
	//	ctx.stroke();
	//	var sx, sy, lineangle, destx, desty;
	//	ctx.strokeStyle = 'rgba(0,0,0,0)';	// don't show the shaft
	//	if ( which & 1 )
	//	{	    // draw the destination end
	//		sx = Math.cos( startangle ) * r + x;
	//		sy = Math.sin( startangle ) * r + y;
	//		lineangle = Math.atan2( x - sx, sy - y );
	//		if ( anticlockwise )
	//		{
	//			destx = sx + 10 * Math.cos( lineangle );
	//			desty = sy + 10 * Math.sin( lineangle );
	//		} else
	//		{
	//			destx = sx - 10 * Math.cos( lineangle );
	//			desty = sy - 10 * Math.sin( lineangle );
	//		}
	//		drawArrow( ctx, sx, sy, destx, desty, style, 2, angle, d );
	//	}
	//	if ( which & 2 )
	//	{	    // draw the origination end
	//		sx = Math.cos( endangle ) * r + x;
	//		sy = Math.sin( endangle ) * r + y;
	//		lineangle = Math.atan2( x - sx, sy - y );
	//		if ( anticlockwise )
	//		{
	//			destx = sx - 10 * Math.cos( lineangle );
	//			desty = sy - 10 * Math.sin( lineangle );
	//		} else
	//		{
	//			destx = sx + 10 * Math.cos( lineangle );
	//			desty = sy + 10 * Math.sin( lineangle );
	//		}
	//		drawArrow( ctx, sx, sy, destx, desty, style, 2, angle, d );
	//	}
	//	//	ctx.restore();
	//}

	static drawFree  ( ctx, x1, y1, x2, y2, lineW, rgba, dash )
	{
		ctx.save();
		ctx.lineWidth = lineW;
		var headW = lineW * 6;
		ctx.lineCap = 'round';
		BoardArrow.drawArrow( ctx, x1, y1, x2, y2, 3, 1, Math.PI / 8, headW, ctx.lineWidth * 0.8, rgba, dash );
		ctx.restore();
	};

	static drawArrow ( ctx, x1, y1, x2, y2, style, which, angle, d, capRadius, rgba, dash )
	{
		style = typeof ( style ) != 'undefined' ? style : 3;
		which = typeof ( which ) != 'undefined' ? which : 1; // end point gets arrow
		angle = typeof ( angle ) != 'undefined' ? angle : Math.PI / 8;
		d = typeof ( d ) != 'undefined' ? d : 10;
		capRadius = capRadius || 0;
		// default to using drawHead to draw the head, but if the style
		// argument is a function, use it instead
		var toDrawHead = typeof ( style ) != 'function' ? BoardArrow.drawHead : style;

		// For ends with arrow we actually want to stop before we get to the arrow
		// so that wide lines won't put a flat end on the arrow.
		//
		var dx = ( x2 - x1 ), dy = ( y2 - y1 );
		var dist = Math.sqrt( dx * dx + dy * dy );
		if ( dist === 0 )
			return;
		// calculate the angle of the line
		var lineangle = Math.atan2( y2 - y1, x2 - x1 );

		var capOffsX = capRadius * Math.cos( lineangle );
		var capOffsY = capRadius * Math.sin( lineangle );
		//		Log.Log( "dx=" + dx + ", dy=" + dy + ", cx=" + capOffsX + ", cy=" + capOffsY );

		var ratio = ( dist - d * 0.76 ) / dist;
		var tox, toy, fromx, fromy;

		if ( which & 1 )
		{
			tox = x1 + dx * ratio - capOffsX;
			toy = y1 + dy * ratio - capOffsY;
		} else
		{
			tox = x2;
			toy = y2;
		}
		if ( which & 2 )
		{
			fromx = x1 + ( x2 - x1 ) * ( 1 - ratio );
			fromy = y1 + ( y2 - y1 ) * ( 1 - ratio );
		} else
		{
			fromx = x1;
			fromy = y1;
		}

		// Draw the shaft of the arrow

		ctx.save();
		if ( rgba )
		{
			//console.log( this.rgba );
			BoardArrow.setStrokeFillStyles( ctx, rgba, fromx, fromy, tox, toy );
		}
		else
		{
			var grad = ctx.createLinearGradient( fromx, fromy, tox, toy );

			var hsl = BoardArrow.getHslYellow();
			grad.addColorStop( 0.0, new HSLColor( hsl.h, hsl.s, hsl.l, 0.20 ).toString() );
			grad.addColorStop( 0.2, new HSLColor( hsl.h, hsl.s, hsl.l, 0.35 ).toString() );
			grad.addColorStop( 0.65, new HSLColor( hsl.h, hsl.s, hsl.l, 0.55 ).toString() );
			grad.addColorStop( 1.0, new HSLColor( hsl.h, hsl.s, hsl.l, 0.90 ).toString() );
			ctx.strokeStyle = grad;
		}

		ctx.beginPath();
		if ( dash )
			ctx.setLineDash( dash );
		ctx.moveTo( fromx, fromy );
		ctx.lineTo( tox, toy );
		ctx.stroke();

		// h is the line length of a side of the arrow head
		var h = Math.abs( d / Math.cos( angle ) );

		if ( which & 1 )
		{	// handle far end arrow head
			var angle1 = lineangle + Math.PI + angle;
			var topx = x2 + Math.cos( angle1 ) * h;
			var topy = y2 + Math.sin( angle1 ) * h;
			var angle2 = lineangle + Math.PI - angle;
			var botx = x2 + Math.cos( angle2 ) * h;
			var boty = y2 + Math.sin( angle2 ) * h;
			toDrawHead( ctx, topx, topy, x2, y2, botx, boty, style );
		}
		if ( which & 2 )
		{ // handle near end arrow head
			let angle1 = lineangle + angle;
			let topx = x1 + Math.cos( angle1 ) * h;
			let topy = y1 + Math.sin( angle1 ) * h;
			let angle2 = lineangle - angle;
			let botx = x1 + Math.cos( angle2 ) * h;
			let boty = y1 + Math.sin( angle2 ) * h;
			toDrawHead( ctx, topx, topy, x1, y1, botx, boty, style );
		}
		ctx.restore();
	}

	static getHslYellow = function ()
	{
		return {
			h: 40,
			s: 90,
			l: 60,
		}
	};
}


/////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export class BoardColouredSquare
{
	constructor ( sq, rgba, wholeSquare )
	{
		this.sq = sq;
		this.rgba = rgba;
		this.isWholeSquare = wholeSquare;
		this.id = ++attrId;

		this.workCanvas = null;
	};

	draw( ctx )
	{
		//if ( this.isWholeSquare )
		//   return this.drawWhole( ctx );
		//else
		return this.drawFrame( ctx );
	};

	drawFrame( ctx )
	{
		var pxSq = this.boardControl.getSquareCenterPos( this.sq );

		if ( this.rgba )
		{
			ctx.strokeStyle = ctx.fillStyle = this.rgba.toString();
		} else
		{
			ctx.strokeStyle = ctx.fillStyle = 'rgba( 255, 220, 10, 0.5 )';
		}

		var divFact = 16;
		if ( this.isWholeSquare )
			divFact = 10;

		// NH2020
		ctx.lineWidth = Math.max( 1, this.boardControl.nSqPix / divFact );
		// ctx.lineWidth = Math.max( 1, this.boardControl.nDesiredSqPix / divFact );

		this.boardControl.drawSquare( ctx, this.sq );

		// NH2020
		var side = this.boardControl.nSqPix - ctx.lineWidth;
		// var side = this.boardControl.nDesiredSqPix - ctx.lineWidth;

		BoardColouredSquare.frameSquare( ctx, pxSq.x - side / 2 + 0, pxSq.y - side / 2 + 0, side - 0 );
		this.boardControl.drawPiece( ctx, this.sq )

		return this._getClipRect();
	};

	drawWhole( ctx )
	{
		var pxSq = this.boardControl.getSquareCenterPos( this.sq );

		// var sqFile = Square.getFile( this.sq ),
		//    sqRank = Square.getRank( this.sq );

		// NH2020
		ctx.lineWidth = Math.max( 1, this.boardControl.nSqPix * 0.08 );
		// ctx.lineWidth = Math.max( 1, this.boardControl.nDesiredSqPix * 0.08 );

		if ( this.rgba )
		{
			ctx.strokeStyle = ctx.fillStyle = this.rgba;
		} else
		{
			ctx.strokeStyle = 'rgba( 255, 220, 10, 0.6 )';
			ctx.fillStyle = 'rgba( 255, 220, 10, 0.6 )';
		}

		// NH2020
		BoardColouredSquare.drawSquare( ctx, pxSq.x, pxSq.y, this.boardControl.nSqPix * 0.5 );
		// BoardColouredSquare.drawSquare( ctx, pxSq.x, pxSq.y, this.boardControl.nDesiredSqPix * 0.5 );

		return this._getClipRect();
	};

	

	undo( ctx )
	{
		var sqFile = Square.getFile( this.sq ),
		   sqRank = Square.getRank( this.sq );

		var file = sqFile, rank = sqRank;

		this.boardControl.restoreSquare( ctx, Square.I( file, rank ) );

		return this._getClipRect();
	};

	_getClipRect()
	{
		var rect = this.boardControl.getSquareRect( this.sq );

		return rect;
	};

	toString()
	{
		return "Sq=" + Square.toString( this.sq ) + ", Clr=" + this.rgba;
   };

   // usually generated by c# serializer
   fromJson = function ( json )
   {
      this.sq = json.sq
   };

	getId()
	{
		return "square" + this.sq;
	}

	static drawSquare  ( ctx, x1, y1, d )
	{
		ctx.fillRect( x1 - d, y1 - d, d * 2, d * 2 );
	}

	static frameSquare  ( ctx, x0, y0, side )
	{
		//var rect = new CB.Rect( x0, y0, x0 + side, y0 + side );	// ltrb
		//CB.CanvasTools.drawRect( ctx, rect );

		CanvasTools.drawRoundedRect( ctx, x0, y0, side, side, Math.max( 1, side / 3.5 ) );
	}
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export class BoardLinePoint
{
	constructor ( sq, corner, bInside )
	{
		this.sq = sq;
		this.corner = corner;
		this.inside = bInside;
   }

   static stringToPoints = function( str )
   {
      var ret = [];

      var reg = /[a-h][1-8][t,b][l,r]/ig;
      var m = str.match( reg );
      if ( m )
      {
         for ( var i = 0; i < m.length; i++ )
         {
            var sq = Square.fromString( m[i].substr( 0, 2 ) );
            ret.push( new BoardLinePoint( sq, m[i].substr( 2, 2 ) ) );
         }
      }
      return ret;
   }

	getXY( boardControl, lineW )
	{
		if (this.inside)
			lineW = 0;
		var pos = boardControl.getPixPos( this.sq );

		// NH2020
		var w = boardControl.nSqPix;
		// var w = boardControl.nDesiredSqPix;

		switch ( this.corner )
		{
			default:
			case 'tl':
				pos.x -= lineW / 2;
				pos.y -= lineW / 2;
				break;
			case 'tr':
				pos.x += w + lineW / 2;
				pos.y -= lineW / 2;
				break;
			case 'bl':
				pos.x -= lineW / 2;
				pos.y += w + lineW / 2;
				break;
			case 'br':
				pos.x += w + lineW / 2;
				pos.y += w + lineW / 2;
				break;
			case 'ct':
				pos.x += w / 2;
				pos.y += w / 2;
				break;
			case 'bm':
				pos.x += w / 2 + lineW / 2;
				pos.y += w + lineW / 2;
				break;
			case 'tm':
				pos.x += w / 2 + lineW / 2;
				pos.y -= lineW / 2;
				break;
			case 'lm':
				pos.x -= lineW / 2;
				pos.y += w / 2 + lineW / 2;
				break;
			case 'rm':
				pos.x += w + lineW / 2;
				pos.y += w / 2 + lineW / 2;
				break;
		}
		return pos;
	};

	getClipRect( boardControl, lineW )
	{
		var pos = boardControl.getPixPos( this.sq );

		// NH2020
		var w = boardControl.nSqPix;
		// var w = boardControl.nDesiredSqPix;

		w += 2 * lineW;
		pos.x -= lineW;
		pos.y -= lineW;
		var rect = new Rect();
		rect.fromTwoPoints( pos.x, pos.y, pos.x + w, pos.y + w );

		return rect;
	};

	toString()
	{
		return "Point: Sq=" + Square.toString( this.sq ) + ", Corner=" + this.corner;
	}

	getId()
	{
		return "point" + this.sq + this.corner;
	}
}

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export class BoardLine
{
	constructor ( points, thick, clr, dash )
	{
		this.points = points || [];
		this.thickPercent = thick || 10;
		this.clr = clr;
		this.dash = dash;
		this.id = ++attrId;
		this.workCanvas = null;
	};

	draw( ctx )
	{
		ctx.save();
		if ( this.clr )
		{
			ctx.strokeStyle = ctx.fillStyle = this.clr;
		} else
		{
			ctx.strokeStyle = ctx.fillStyle = 'hsla( 0, 50%, 70%, 0.6 )';
		}
		ctx.lineCap = 'round';
		ctx.lineWidth = this.getLineWidthPixel();

		if ( this.points.length )
		{
			ctx.beginPath();
			if ( this.dash && this.dash.length )
				ctx.setLineDash( this.dash );
			var pt = this.points[0].getXY( this.boardControl, ctx.lineWidth);
			ctx.moveTo( pt.x, pt.y );
			for ( var p = 1; p < this.points.length; p++ )
			{
				let pt = this.points[p].getXY( this.boardControl, ctx.lineWidth );
				ctx.lineTo( pt.x, pt.y );
			}
			ctx.stroke();
		}
		ctx.restore();

		return this._getClipRect();
	};

	undo( ctx )
	{
		// TODO!
		return this._getClipRect();
	};

	getLineWidthPixel()
	{
		// NH2020
		return Math.max( 1, ( this.boardControl.nSqPix * this.thickPercent ) / 100 );
		// return Math.max( 1, ( this.boardControl.nDesiredSqPix * this.thickPercent ) / 100 );
	}

	_getClipRect()
	{
		var rect = null;
		for ( var p = 0; p < this.points.length; p++ )
		{
			if ( rect == null )
			{
				rect = this.points[p].getClipRect( this.boardControl, this.getLineWidthPixel() );
			}
			else
				rect = rect.getUnion( this.points[p].getClipRect( this.boardControl, this.getLineWidthPixel() ) );
		}

		return rect;
	};

	toString()
	{
		return "Line: " + this.clr.toString() + "/" + this.thickPercent;
	};

	getId()
	{
		return "line" + this.points.join( "," );
	}
}


export class BoardZone
{
	constructor ( sqFrom, sqTo, thick, clr, dash, boardControl, bDrawInside )
	{
		this.from = sqFrom;
		this.to = sqTo;
		this.thick = thick;
		this.clr = clr;
		this.dash = dash;
		this.boardControl = boardControl;
		this.inside = bDrawInside;
		this.init();
	}

	init()
	{
		this.edges = this.getEdgeSquares( this.from, this.to );
		this.id = this.getId();
		this.createLine();
	}

	getEdgeSquares( sqFrom, sqTo )
	{
		var x1 = Math.floor( sqFrom / 8 );
		var y1 = sqFrom % 8;

		var x2 = Math.floor( sqTo / 8 );
		var y2 = sqTo % 8;


		var edges = [sqFrom, sqTo, x1 * 8 + y2, x2 * 8 + y1];
		//edges.sort();
		BoardZone.sortEdges( edges );
		//edges.sort( function ( a, b ) { return a - b; } );
		//edges[2] = [edges[3], edges[3] = edges[2]][0];

		return edges;
	}

	static  sortEdges ( edgesArr )
	{
		edgesArr.sort( function ( a, b )
		{
			var x1 = Math.floor( a / 8 );
			var x2 = Math.floor( b / 8 );

			if ( x1 !== x2 )
				return x1 - x2; //leftmost

			var y1 = a % 8;
			var y2 = b % 8;

			return y1 - y2; //bottommost
		} );

		edgesArr[2] = [edgesArr[3], edgesArr[3] = edgesArr[2]][0];//topright and bottomright switch
		if ( edgesArr[2] === edgesArr[3] && edgesArr[2] - edgesArr[1] < 8 )
			edgesArr[1] = [edgesArr[3], edgesArr[3] = edgesArr[1]][0];

	}

	createLine()
	{
		var corners = ["bl", "tl", "tr", "br"];
		if ( this.boardControl.blackIsBottom )
			 corners = ["tr", "br", "bl", "tl"];
		var points = corners.map( function ( corner, index )
		{
			return new BoardLinePoint( this.edges[index], corner, this.inside );
		}.bind( this ) );
		points.push( new BoardLinePoint( this.edges[0], this.boardControl.blackIsBottom ? "tr" : "bl", this.inside ) ); //first point is also the end;
		var line = new BoardLine( points, this.thick, this.clr, this.dash );
		line.boardControl = this.boardControl;
		this.line = line;
	}


	draw( ctx )
	{
		return this.line.draw( ctx );
	}

	undo( ctx, fnGetPixPos )
	{
		return this.line.undo( ctx, fnGetPixPos );
	}

	toString()
	{
		return "Zone: " + this.edges.join( "," );
	}

	getId()
	{
		return "zone" + this.edges.join( "," );
	}

	static fromLiteral = function (zone, boardControl)
	{
		Object.setPrototypeOf( zone, BoardZone.prototype);
		zone.boardControl = boardControl;
		zone.init();
		return zone;
	}

}