// mw 1.5.2013

// ES6 4.3.2020

import { Log } from 'common/Tools/Log'

import { lm } from "common/Resources/Localization/Localization";

import { TimeTools } from 'common/Tools/Tools'

import { AnnoType, TimeAnno } from 'common/Chess/Format/AnnoTypes'
import { GameResultEnum } from "common/Chess/Logic/GameHeader";
import { ClockParams } from "common/Chess/PlayingModes/ClockParams";
import { BaseClock } from "common/Chess/Logic/BaseClock";

export var TimeTrouble =
	{

		SLIGHTLY_BEHIND: -1,
		BEHIND: -2,
		CLEARLY_BEHIND: -3,
		LOSING_ON_TIME: -4,
		ABOUT_EVEN: 0,
		SLIGHTLY_AHEAD: 1,
		AHEAD: 2,
		CLEARLY_AHEAD: 3,
	};

// all times in centiSecs.


//Die Klasse ist die logische Uhr.
//Sie hat einen Link auf die clockLink-implementierende Klasse per clockLink.
export class ChessClock extends BaseClock
{
	static _uniqueId = 1;

	constructor ( clockLink, fnTimeout, fnWarnClock )
	{
		super( clockLink );

		this.fnTimeout = fnTimeout;
		this.fnWarnClock = fnWarnClock;
		this.result = GameResultEnum.GAME_UNFINISHED;
		this.runsForward = false;

		this.centiSecsWhite = 0;
		this.centiSecsBlack = 0;

		this.uniqueId = ChessClock._uniqueId++;

		this._isRunning = false;

		this.mySide = null;

		this.warned = [];
	};


	getWTimeMilli()
	{
		return this.centiSecsWhite * 10;
	};

	getBTimeMilli()
	{
		return this.centiSecsBlack * 10;
	};

	destroy()
	{
	};

	reinit()
	{
		this.setClockParams( this.clockParams );
	};

	setClockParams( clockParams )
	{
		this.warned = [false,false];
		this.result = GameResultEnum.GAME_UNFINISHED;
		if ( clockParams )
		{
			this.clockParams = clockParams;
			this.centiSecsWhite = this.clockParams.startMins * 60 * 100;
			this.centiSecsBlack = this.clockParams.startMins * 60 * 100;
			if ( this.clockParams.startMins === 0 )
			{
				this.centiSecsWhite = 1000;
				this.centiSecsBlack = 1000;
			}
			this.clockLink.showRestTime( new Date( this.centiSecsWhite * 10 ), false );
			this.clockLink.showRestTime( new Date( this.centiSecsBlack * 10 ), true );
			this.clockLink.setDisplayHours( this.clockParams.getAvGameTimeSeconds() > 3000 );
		}
		else
		{
			//this.clockLink.setText( "No", false /*black*/ );
			//this.clockLink.setText( "Clock", true );
			this.setNoClock();
			delete this.clockParams;
		}
		delete this.startTime;
		delete this.expiredAtStop;
	};

	setRunsForward( b )
	{
		this.runsForward = b;
		this.clockLink.setRunsForward( b );
	};

	initFromGame( game )
	{
		this.stop();
		if ( this.clockParams )
		{
			var whiteTime = this.clockParams.startMins * 6000,
				blackTime = this.clockParams.startMins * 6000;

			var sgn = this.runsForward ? -1 : +1;

			for ( var i = 0; i < game.getPlyNum() ; i++ )
			{
				if ( i < game.getMoves().length )
				{ // i was out of range once

					var anno = game.getMoves()[i].getAnno();
					if ( anno )
					{
						var timeAnno = anno.getItem( AnnoType.TIME );
						if ( timeAnno )
						{
							if ( i & 1 )
							{
								blackTime -= sgn * timeAnno.getTimeIn100th();
								blackTime += sgn * this.clockParams.incSecs * 100;
							} else
							{
								whiteTime -= sgn * timeAnno.getTimeIn100th();
								whiteTime += sgn * this.clockParams.incSecs * 100;
							}
						}
					}
				}
			}
			//LOG( "Game: Restwhite = " + whiteTime / 100 );
			//LOG( "Game: Restblack = " + blackTime / 100 );
			this.centiSecsWhite = whiteTime;
			this.centiSecsBlack = blackTime;
			this.takeStartTime();
			this.isBlack = ( game.getMoves().length & 1 ) !== 0;
			this.clockLink.setToMove( this.isBlack );
			this.showTimes( whiteTime, blackTime );
			if ( game.isOnLastMove() )
			{
				if ( game.hdr.getCBResult().isFinished() )
					this.clockLink.showResult( game.hdr.getResult() );
				else
				{
					this.start();
				}
			}
		}
	};

	onResult( result )
	{
		if ( !this.isFinished() )
		{
			this.result = result;
			this.stop();
			this.clockLink.showResult( result );
			Log.Log( "Clock On result: " + this.result, "LogBold" );
		}
	};

	unfinish()
	{
		this.result = GameResultEnum.GAME_UNFINISHED;;
	};

	isFinished()
	{
		return this.result >= GameResultEnum.BLACK_WINS && this.result <= GameResultEnum.LINE;
		//	return GameResultEnum.isFinished( this.result );
	};

	setToMove( black )
	{
		Log.Log( "Clock, setToMove, black= " + black );

		if ( this.clockParams && !this.isFinished() )
		{
			this.isBlack = black;
			if ( black )
				this.centiSecsBlack += this.clockParams.incSecs * 100;
			else
				this.centiSecsWhite += this.clockParams.incSecs * 100;

			this.takeStartTime();
			this.clockLink.setToMove( black );
		}
		else
		{
			//	LOG( "setToMove: Finished" );
		}
	};

	isRunning()
	{
		return this._isRunning;
	};

	start()
	{
		if ( this._isRunning )
			return;

		if ( this.clockParams && !this.isFinished() )
		{
			this._isRunning = true;

			this.takeStartTime();
			this.showTimes( this.centiSecsWhite, this.centiSecsBlack );
			this.updateTimer.runOnce( 1000 );
			delete this.expiredAtStop;

		//	LOG( "Started Clock as black=" + this.isBlack );
		}
	};

	stop()
	{
		if (!this._isRunning)
			return;
		this.updateTimer.stop();

		if ( this.startTime )
		{
			this._isRunning = false;

			var centiSecsExpired = ( ChessClock.getTick() - this.startTime + 5 ) / 10;
			this.expiredAtStop = centiSecsExpired;
			//	LOG( "Clock.Stop: black=" + this.black + ", timeW=" + this.centiSecsWhite + ", timeB=" + this.centiSecsBlack );
			if ( !this.isFinished() )
				this.setExpired( centiSecsExpired, this.isBlack );
		}
	};

	takeStartTime()
	{
		this.startTime = ChessClock.getTick();
		this.restTimeAtStart = this.isBlack ? this.centiSecsBlack : this.centiSecsWhite;
	};

	getRestCentiSecs( black )
	{
		if ( black )
			return this.centiSecsBlack;
		else
			return this.centiSecsWhite;
	};

	showTimes( whiteTime, blackTime )
	{
		if ( whiteTime >= 0 )
		{
			this.clockLink.showRestTime( new Date( whiteTime * 10 ), false );
		} else
		{
			this.clockLink.showRestTime( -1, false );
		}
		if ( blackTime >= 0 )
		{
			this.clockLink.showRestTime( new Date( blackTime * 10 ), true );
		} else
		{
			this.clockLink.showRestTime( -1, true );
		}
	};

	setExpired( centiSecsExpired, black )
	{
		if ( this.clockParams )
		{
			this.clockLink.showCurrTime( new Date( centiSecsExpired * 10 ), black );
			var restTime = 0;
			this.clockLink.showCurrTime( new Date( centiSecsExpired * 10 ), black );

			if ( this.runsForward )
			{
				if ( black )
				{
					restTime = this.centiSecsBlack = this.restTimeAtStart + centiSecsExpired;
				} else
				{
					restTime = this.centiSecsWhite = this.restTimeAtStart + centiSecsExpired;
				}
			} else
			{
				if ( black )
				{
					restTime = this.centiSecsBlack = this.restTimeAtStart - centiSecsExpired;
				} else
				{
					restTime = this.centiSecsWhite = this.restTimeAtStart - centiSecsExpired;
				}
			}

			if ( restTime < 0 )
				this.clockLink.showRestTime( -1, black );
			else
				this.clockLink.showRestTime( new Date( restTime * 10 ), black, this.getTimeTroubleIndex() );

			//	LOG( "Clock.setExpired: " + centiSecsExpired + ", black=" + black + ", timeW=" + this.centiSecsWhite + ", timeB=" + this.centiSecsBlack );

			return restTime;
		}
	};

	changeCurrMoveElapsedTime( centiSecs )
	{
		//	LOG( "Clock.changeCurrMoveElapsed: " + centiSecs );
		if ( this.clockParams && !this.isFinished() )
		{
			var localExpired = ( ChessClock.getTick() - this.startTime + 5 ) / 10;
			this.startTime = ChessClock.getTick() - centiSecs * 10;
			this.restTimeAtStart -= ( centiSecs - localExpired );
			this.updateTimer.stop();
			this.expiredAtStop = centiSecs;
			this.setExpired( centiSecs, this.isBlack );

			this.update();
		}
	};

	reset()
	{
		this.setClockParams( new ClockParams( 0, 0 ) );
	};

	getExpired()	// centisecs
	{
		if ( this.expiredAtStop )
			return this.expiredAtStop;
		else if ( this.startTime )
		{
			return ( ChessClock.getTick() - this.startTime + 5 ) / 10;
		} else
		{
			return 0;
		}
	};

	getStrRestTimes()
	{
		var w = ChessClock.getTimeString( new Date( this.centiSecsWhite * 10 ) ),
			 b = ChessClock.getTimeString( new Date( this.centiSecsBlack * 10 ) );

		var strW = "";
		if ( this.centiSecsWhite >= 0 )
		{
			strW = String.f( "{0}={1}", lm.WHITE, w );
		}

		var strB = "";
		if ( this.centiSecsBlack >= 0 )
		{
			strB = String.f( "{0}={1}", lm.BLACK, b );
		}
		var ret = strW;
		if ( ret && strB )
			ret += ", ";
		ret += strB;

		return ret;
	};

	createTimeAnno()
	{
		return new TimeAnno( TimeTools.getCBTimeOfCentiSecs( this.getExpired() ) );
	};

	setText( txt, black )
	{
		this.clockLink.setText( txt, black );
	};

	update()
	{
		var centiSecsExpired = ( ChessClock.getTick() - this.startTime + 5 ) / 10;
		var restTime = this.setExpired( centiSecsExpired, this.isBlack );

	//	LOG( "Rest Time : " + restTime + " id=" + this.uniqueId + ", black=" + this.isBlack );

		if ( restTime > 0 )
		{
			this.clockLink.setDisplayCentiSecs( restTime < 10 * 100 );	// not used in ClockAndPlayerInfo

			var timeOut = 1000;
			if ( restTime < 10 * 100 )
			{
				timeOut = 100;
			}

			this.updateTimer.runOnce( timeOut );

			if ( centiSecsExpired / 100 > 15 && centiSecsExpired > restTime / 3 && this.fnWarnClock && !this.warned[this.isBlack ? 1 : 0] )
			{
				this.fnWarnClock( this.isBlack );
				this.warned[this.isBlack ? 1 : 0] = true;
			}
		} else
		{
			Log.Log( "Clock Timeout: " + this.uniqueId, "LogBold" );
			if ( this.fnTimeout && !this.isFinished() )
			{
				this.clockLink.setText( "TIME", this.isBlack );
				this.fnTimeout();
			}
		}
	};

	getTimeTroubleIndex()
	{
		if ( this.mySide != null )
		{
			var myTime, oppTime;
			if ( !this.isBlack )
			{
				myTime = this.centiSecsWhite;
				oppTime = this.centiSecsBlack;
			}
			else
			{
				myTime = this.centiSecsBlack;
				oppTime = this.centiSecsWhite;
			}

			if ( Math.abs( myTime - oppTime ) < 0.1 * Math.abs( myTime ) && myTime > 2000 )
				return TimeTrouble.ABOUT_EVEN;

			if ( myTime > oppTime )
			{
				if ( myTime > 1.2 * oppTime )
				{
					if ( myTime > 2 * oppTime )
						return TimeTrouble.CLEARLY_AHEAD;
					return TimeTrouble.AHEAD;
				}
				return TimeTrouble.SLIGHTLY_AHEAD;
			}
			else
			{
				if ( myTime < 0.7 * oppTime )
				{
					if ( myTime < 0.5 * oppTime )
					{
						if ( myTime < 0.3 * oppTime && myTime < 1000 )
							return TimeTrouble.LOSING_ON_TIME;

						return TimeTrouble.CLEARLY_BEHIND;
					}
					return TimeTrouble.BEHIND;
				}
				return TimeTrouble.SLIGHTLY_BEHIND;
			}
		}
		return 0;
	};

	static getTimeString = function ( time, displayHours, runsForward )
	{
		if ( time < 0 )
			time = new Date( 0 );

		var ret = "";
		if ( displayHours )
			ret = time.getUTCHours() + ":";
		if ( time.valueOf() < 10000 && !runsForward )
		{
			var secs = time.getUTCSeconds();
			var decSecs = Math.floor( time.getUTCMilliseconds() / 100 );
			//if ( decSecs.toString().length > 1 )
			//	decSecs = "0";
			ret = secs + "." + decSecs + "s";
		} else
		{
			var mins = time.getUTCMinutes();
			if ( mins.toString().length === 1 && mins > 0 )
				mins = "0" + mins;
			ret += mins;
			let secs = Math.floor( time.getUTCSeconds() );
			if ( secs.toString().length === 1 )
				secs = "0" + secs;
			ret += ":" + secs;
		}

		return ret;
	};

}