import { Piece } from "../Logic/Chess";
import { CBDebug } from "common/Tools/Debug/debug_util.debug";
import { Log } from "common/Tools/Log";
import { Square } from "common/Chess/Logic/Chess"
import { Move } from "../Logic/Move";
import { Side } from "common/Chess/Logic/Chess"

export class NumBoardImpl {

    constructor () {

        // NH2020 Added these to constructor
        this.numBoard = [];   //	64
        this.piecePos = [];  //42 - chess, 48 - ouk chatrang
    };

    // NH2020
    //NumBoardImpl.prototype.numBoard = [];		//	64
    //NumBoardImpl.prototype.piecePos = [];     //	42 - chess, 48 - ouk chatrang
}
   

export class NumBoard {
    
    constructor () {
        this.numBoard =  this.m_aImpl.numBoard;

        // NH2020 changed , to . this,piecePos
        this.piecePos =  this.m_aImpl.piecePos;

        // NH2020 added the following to constructor
    
        /////////////////////////////////////////////////////////////////////////////
        //	ouk data
        this.moveOffs =[];
        this.tableOffs = [];
        this.pieceMov = [];
        this.pieceNum = [];
        this.bPawnMov = [];
        this.pieceOffs = [];
        this.pieceInx = [];
        this.maxPiece = [];
        this.wPawnOffs = 0;
            
        this.m_aImpl = new NumBoardImpl();
        this.numBoard =[];
        this.piecePos =[];
    };

    // NH2020 Made the following static

    static moveOffs_Chess =
    [
        0, 0, 0, 0, 0, 0, 0, 4,				//	king
        2, 1, 0, 0, 0, 0, 0, 3,
        8, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0,
        9, 0, 0, 0, 0, 0, 0, 0,
        6, 7, 0, 0, 0, 0, 0, 5,

        0,  0,  1,  2,  3,  4,  5,  6,	//	queen, rook, bishop, knight
        7, 14,  1,  0,  0,  0,  6, 21,
        8,  0, 15,  0,  0,  0, 22,  7,
        9,  0,  0, 16,  0, 23,  0,  0,
        10,  0,  0,  0, 17,  0,  0,  0,
        11,  0,  0, 25,  0, 18,  0,  0,
        12,  3, 26,  0,  0,  0, 19,  4,
        13, 27,  2,  0,  0,  0,  5, 20,

        0, 0, 1, 0, 0, 0, 1, 0,				//	pawn
        0, 2, 0, 0, 0, 0, 0, 3,
        0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0,
        0, 3, 0, 0, 0, 0, 0, 2
    ];

        //	table of move indices; used as offset into move offset table
    static moveOffs2 = 
    [
        0, 0, 0, 0, 0, 0, 0, 4,				//	king
        2, 1, 0, 0, 0, 0, 0, 3,
        8, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0,
        9, 0, 0, 0, 0, 0, 0, 0,
        6, 7, 0, 0, 0, 0, 0, 5,

        0,  0,  1,  2,  3,  4,  5,  6,	//	queen, rook, bishop, knight
        7, 14,  1,  0,  0,  0,  6, 21,
        8,  0, 15,  0,  0,  0, 22,  7,
        9,  0,  0, 16,  0, 23,  0,  0,
        10,  0,  0,  0, 17,  0,  0,  0,
        11,  0,  0, 25,  0, 18,  0,  0,
        12,  3, 26,  0,  0,  0, 19,  4,
        13, 27,  2,  0,  0,  0,  5, 20,

        0, 0, 1, 0, 0, 0, 1, 0,				//	pawn
        0, 2, 0, 0, 0, 0, 0, 3,
        0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0,
        0, 3, 0, 0, 0, 0, 0, 2
    ];

        // to get the table for the move indices
    static tableOffs_Chess = 
    [	0, 0, 1, 1, 1, 1, 2, 0,
        0, 0, 1, 1, 1, 1, 2, 0
    ];

    // to get the table for the move indices
    static tableOffs2 = 
    [	0, 0, 1, 1, 1, 1, 2, 0,
        0, 0, 1, 1, 1, 1, 2, 0
    ];

    static wPawnOffs_Chess = 111;

    //	table of move offsets, octal numbers, handled separately for each digit!
    static pieceMov_Chess = 
    [
        0o00,												//	null move
        0o01, 0o11, 0o10, 0o17, 0o07, 0o77, 0o70, 0o71,	//	king					1..8
        0o20, 0o60,											//	king castle       9..10

        0o01, 0o02, 0o03, 0o04, 0o05, 0o06, 0o07,		//	queen1 north      11..38
        0o10, 0o20, 0o30, 0o40, 0o50, 0o60, 0o70,		//	queen1 east
        0o11, 0o22, 0o33, 0o44, 0o55, 0o66, 0o77,		//	queen1 northeast
        0o17, 0o26, 0o35, 0o44, 0o53, 0o62, 0o71,		//	queen1 southeast

        0o01, 0o02, 0o03, 0o04, 0o05, 0o06, 0o07,		//	rook1 north      	39..52
        0o10, 0o20, 0o30, 0o40, 0o50, 0o60, 0o70,		//	rook1 east

        0o01, 0o02, 0o03, 0o04, 0o05, 0o06, 0o07,		//	rook2 north      	53..66
        0o10, 0o20, 0o30, 0o40, 0o50, 0o60, 0o70,		//	rook2 east

        0o11, 0o22, 0o33, 0o44, 0o55, 0o66, 0o77,		//	bishop1 northeast	67..80
        0o17, 0o26, 0o35, 0o44, 0o53, 0o62, 0o71,		//	bishop1 southeast

        0o11, 0o22, 0o33, 0o44, 0o55, 0o66, 0o77,		//	bishop2 northeast	81..94
        0o17, 0o26, 0o35, 0o44, 0o53, 0o62, 0o71,		//	bishop2 southeast

        0o21, 0o12, 0o72, 0o61, 0o67, 0o76, 0o16, 0o27,	//	knight1 				95..0o2
        0o21, 0o12, 0o72, 0o61, 0o67, 0o76, 0o16, 0o27,	//	knight2 				0o3..110

        0o01, 0o02, 0o11, 0o71,							//	pawn1					111..114
        0o01, 0o02, 0o11, 0o71,							//	pawn2					115..118
        0o01, 0o02, 0o11, 0o71,							//	pawn3					119..122
        0o01, 0o02, 0o11, 0o71,							//	pawn4					123..126
        0o01, 0o02, 0o11, 0o71,							//	pawn5					127..130
        0o01, 0o02, 0o11, 0o71,							//	pawn6					131..134
        0o01, 0o02, 0o11, 0o71,							//	pawn7					135..138
        0o01, 0o02, 0o11, 0o71,							//	pawn8					139..142

        0o01, 0o02, 0o03, 0o04, 0o05, 0o06, 0o07,	//----	queen2 north      143..170
        0o10, 0o20, 0o30, 0o40, 0o50, 0o60, 0o70,		//	queen2 east
        0o11, 0o22, 0o33, 0o44, 0o55, 0o66, 0o77,		//	queen2 northeast
        0o17, 0o26, 0o35, 0o44, 0o53, 0o62, 0o71,		//	queen2 southeast

        0o01, 0o02, 0o03, 0o04, 0o05, 0o06, 0o07,		//	queen3 north      161..198
        0o10, 0o20, 0o30, 0o40, 0o50, 0o60, 0o70,		//	queen3 east
        0o11, 0o22, 0o33, 0o44, 0o55, 0o66, 0o77,		//	queen3 northeast
        0o17, 0o26, 0o35, 0o44, 0o53, 0o62, 0o71,		//	queen3 southeast

        0o01, 0o02, 0o03, 0o04, 0o05, 0o06, 0o07,		//	rook3 north      	199..212
        0o10, 0o20, 0o30, 0o40, 0o50, 0o60, 0o70,		//	rook3 east

        0o11, 0o22, 0o33, 0o44, 0o55, 0o66, 0o77,		//	bishop3 northeast	213..226
        0o17, 0o26, 0o35, 0o44, 0o53, 0o62, 0o71,		//	bishop3 southeast

        0o21, 0o12, 0o72, 0o61, 0o67, 0o76, 0o16, 0o27,	//	knight3 				227..234

        0o00, 0o00,											//	FULL_MOVE_INFO, CB_ORIGIN1

        0o01, 0o11, 0o10, 0o17, 0o07, 0o77, 0o70, 0o71,	//	king2					237..244
        0o20, 0o60											//	king2 castle      245..246
        ];

    static bPawnMov_Chess = 
    [
        0o07, 0o06, 0o77, 0o17,							//	bpawn					235..238
        0o07, 0o06, 0o77, 0o17,							//	bpawn					229..242
        0o07, 0o06, 0o77, 0o17,							//	bpawn					243..246
        0o07, 0o06, 0o77, 0o17,							//	bpawn					247..250
        0o07, 0o06, 0o77, 0o17,							//	bpawn					251..254
        0o07, 0o06, 0o77, 0o17,							//	bpawn					255..258
        0o07, 0o06, 0o77, 0o17,							//	bpawn					259..262
        0o07, 0o06, 0o77, 0o17							//	bpawn					263..266
    ];


    //	table of piece numbers, to get the piece number from the move index
    static pieceNum_Chess = 
    [
        0,																//	null move

        0x01, 0x01, 0x01, 0x01, 0x01, 0x01, 0x01, 0x01,	//	king					1..8
        0x01, 0x01,													//	king castle       9..10

        0x02, 0x02, 0x02, 0x02, 0x02, 0x02, 0x02,			//	queen1 north      11..38
        0x02, 0x02, 0x02, 0x02, 0x02, 0x02, 0x02,      	//	queen1 east
        0x02, 0x02, 0x02, 0x02, 0x02, 0x02, 0x02,			//	queen1 northeast
        0x02, 0x02, 0x02, 0x02, 0x02, 0x02, 0x02,			//	queen1 southeast

        0x05, 0x05, 0x05, 0x05, 0x05, 0x05, 0x05,			//	rook1 north       39..52
        0x05, 0x05, 0x05, 0x05, 0x05, 0x05, 0x05,      	//	rook1 east

        0x15, 0x15, 0x15, 0x15, 0x15, 0x15, 0x15,			//	rook2 north       53..66
        0x15, 0x15, 0x15, 0x15, 0x15, 0x15, 0x15,      	//	rook2 east

        0x04, 0x04, 0x04, 0x04, 0x04, 0x04, 0x04,			//	bishop1 northeast	67..80
        0x04, 0x04, 0x04, 0x04, 0x04, 0x04, 0x04,			//	bishop1 southeast

        0x14, 0x14, 0x14, 0x14, 0x14, 0x14, 0x14,			//	bishop2 northeast	81..94
        0x14, 0x14, 0x14, 0x14, 0x14, 0x14, 0x14,			//	bishop2 southeast

        0x03, 0x03, 0x03, 0x03, 0x03, 0x03, 0x03, 0x03,	//	knight1 				95..102
        0x13, 0x13, 0x13, 0x13, 0x13, 0x13, 0x13, 0x13,	//	knight2 				103..110

        0x06, 0x06, 0x06, 0x06,									//	pawn1					111..114
        0x16, 0x16, 0x16, 0x16,									//	pawn2					115..118
        0x26, 0x26, 0x26, 0x26,									//	pawn3					119..122
        0x36, 0x36, 0x36, 0x36,									//	pawn4					123..126
        0x46, 0x46, 0x46, 0x46,									//	pawn5					127..130
        0x56, 0x56, 0x56, 0x56,									//	pawn6					131..134
        0x66, 0x66, 0x66, 0x66,									//	pawn7					135..138
        0x76, 0x76, 0x76, 0x76,									//	pawn8					139..142

        0x12, 0x12, 0x12, 0x12, 0x12, 0x12, 0x12,			//	queen2 north      143..170
        0x12, 0x12, 0x12, 0x12, 0x12, 0x12, 0x12,      	//	queen2 east
        0x12, 0x12, 0x12, 0x12, 0x12, 0x12, 0x12,			//	queen2 northeast
        0x12, 0x12, 0x12, 0x12, 0x12, 0x12, 0x12,			//	queen2 southeast

        0x22, 0x22, 0x22, 0x22, 0x22, 0x22, 0x22,			//	queen3 north      171..198
        0x22, 0x22, 0x22, 0x22, 0x22, 0x22, 0x22,      	//	queen3 east
        0x22, 0x22, 0x22, 0x22, 0x22, 0x22, 0x22,			//	queen3 northeast
        0x22, 0x22, 0x22, 0x22, 0x22, 0x22, 0x22,			//	queen3 southeast

        0x25, 0x25, 0x25, 0x25, 0x25, 0x25, 0x25,			//	rook3 north       199..212
        0x25, 0x25, 0x25, 0x25, 0x25, 0x25, 0x25,      	//	rook3 east

        0x24, 0x24, 0x24, 0x24, 0x24, 0x24, 0x24,			//	bishop3 northeast	213..226
        0x24, 0x24, 0x24, 0x24, 0x24, 0x24, 0x24,			//	bishop3 southeast

        0x23, 0x23, 0x23, 0x23, 0x23, 0x23, 0x23, 0x23,	//	knight3 				227..234

        0x00, 0x00,

        0x11, 0x11, 0x11, 0x11, 0x11, 0x11, 0x11, 0x11,	//	king2					237..244
        0x11, 0x11													//	king2 castle      245..246
        ];

    //	to get index of piece in move index table; since bishop and queen are coded
    //	into one table, the bishop index has to be adjusted
    static pieceOffs_Chess = 
    [	0,  1,    11,  95,  67-14,  39, 111, 0,	//	white 1st piece
        0,  1,    11,  95,  67-14,  39, 111, 0,	//	black 1st piece
        0,  237, 143, 103,  81-14,  53, 115, 0,	//	white 2nd piece
        0,  237, 143, 103,  81-14,  53, 115, 0,	//	black 2nd piece
        0,  0,   171, 227, 213-14, 199, 119, 0, 	//	white 3rd piece
        0,  0,   171, 227, 213-14, 199, 119, 0,  //	black 3rd piece
        0,  0,     0,   0,      0,   0, 123, 0, 	//	white 4th piece
        0,  0,     0,   0,      0,   0, 123, 0, 	//	black 4th piece
        0,  0,     0,   0,  	   0,   0, 127, 0, 	//	white 5th piece
        0,  0,     0,   0,  	   0,   0, 127, 0, 	//	black 5th piece
        0,  0,     0,   0,  	   0,   0, 131, 0, 	//	white 6th piece
        0,  0,     0,   0,  	   0,   0, 131, 0, 	//	black 6th piece
        0,  0,     0,   0,  	   0,   0, 135, 0, 	//	white 7th piece
        0,  0,     0,   0,  	   0,   0, 135, 0, 	//	black 7th piece
        0,  0,     0,   0,  	   0,   0, 139, 0, 	//	white 8th piece
        0,  0,     0,   0,  	   0,   0, 139, 0   //	black 8th piece
    ];


    //	to get index of piece in array piecePos; it's easier to use a 160 entries
    //	table instead of 42 entries, but this would exceed 64K for a stack of them
    static pieceInx_Chess =
    [	0, 24,  0,  1,  2,  3, 26, 0,		//	white 1st piece
        0, 25, 12, 13, 14, 15, 27, 0,		//	black 1st piece
        0, 42,  4,  5,  6,  7, 28, 0,		//	white 2nd piece
        0, 43, 16, 17, 18, 19, 29, 0,		//	black 2nd piece
        0,  0,  8,  9, 10, 11, 30, 0, 	//	white 3rd piece
        0,  0, 20, 21, 22, 23, 31, 0,  	//	black 3rd piece
        0,  0,  0,  0,  0,  0, 32, 0, 	//	white 4th piece
        0,  0,  0,  0,  0,  0, 33, 0, 	//	black 4th piece
        0,  0,  0,  0,  0,  0, 34, 0, 	//	white 5th piece
        0,  0,  0,  0,  0,  0, 35, 0, 	//	black 5th piece
        0,  0,  0,  0,  0,  0, 36, 0, 	//	white 6th piece
        0,  0,  0,  0,  0,  0, 37, 0, 	//	black 6th piece
        0,  0,  0,  0,  0,  0, 38, 0, 	//	white 7th piece
        0,  0,  0,  0,  0,  0, 39, 0, 	//	black 7th piece
        0,  0,  0,  0,  0,  0, 40, 0, 	//	white 8th piece
        0,  0,  0,  0,  0,  0, 41, 0	 	//	black 8th piece
    ];


    //	maximum piecenumbers of each type which can be handled with 1 byte
    //	the left hex digit counts the pieces of that type
    static maxPiece_Chess = 
    [	0, 0x10, 0x30, 0x30, 0x30, 0x30, 0x80, 0,
        0, 0x10, 0x30, 0x30, 0x30, 0x30, 0x80, 0
    ];


    /////////////////////////////////////////////////////////////////////////////
    //	multi king data

    //	maximum piecenumbers of each type which can be handled with 1 byte
    //	the left hex digit counts the pieces of that type
    static maxPiece_MultiKing =
    [	0, 0x20, 0x30, 0x30, 0x30, 0x30, 0x80, 0,
        0, 0x20, 0x30, 0x30, 0x30, 0x30, 0x80, 0
    ];

    static MOVE_EP = 0x10;
    static MOVE_DOUBLE = 0x20;
    static MOVE_PROM = 0x40;

    static FORMAT_0  = 0;
    static FORMAT_1 =1;

    static FULL_MOVE_INFO	= 235;
    static CB_ORIGIN1		= 236;		//	code origin into moves; available: 237..253
    static KING2_START		= 237;
    static KING2_END		= 246;
    static CB_LINE_START	= 0xfe;
    static CB_LINE_END		= 0xff;

    //bool IsSingleByte( ref Position pPos, byte code )	
    //{	return ( code < FULL_MOVE_INFO  );
    //} 

    IsMultiByte(  code ) {
        return code == NumBoard.FULL_MOVE_INFO;
    };
    
    MOVEPIECE (fr,  to) {
        if (fr && (fr < this.maxPiece[fr & Piece.PIECE_MASK]))
            this.piecePos[ this.pieceInx[ fr ] ] = to;
    };

    init ( rBoard, nFormat ){
        var nPiece = [];
        var	run;
    
        this.moveOffs = this.moveOffs_Chess;
        this.tableOffs = this.tableOffs_Chess;
        this.pieceMov = this.pieceMov_Chess;
        this.pieceNum = this.pieceNum_Chess;
        this.bPawnMov = this.bPawnMov_Chess;
        this.pieceOffs = this.pieceOffs_Chess;
        this.pieceInx = this.pieceInx_Chess;
        this.maxPiece = this.maxPiece_Chess;
        this.wPawnOffs = this.wPawnOffs_Chess;
	   

        for( run=0; run<48; run++ )
            this.piecePos[run] = 0xff;
        for( run=0; run<16; run++ )
            nPiece[run] = run;
        switch ( nFormat ) {	
            case this.FORMAT_1 : {	//const BOARD_T *pBoard = &(const_cast<Board&>(rBoard))(0);
                for( run=63; run>=0; run-- ) {
                    var piece = rBoard[run] & 15;
                    if( piece > 0 ) {	
                        this.numBoard[run] = nPiece[piece];
                        if (nPiece[piece] < this.maxPiece[piece & Piece.PIECE_MASK]) {
                            this.piecePos[this.pieceInx[nPiece[piece]]] = run;
                            nPiece[ piece ] += 0x10;
                        }
                        CBDebug.assert(this.numBoard[run] > 0);
                    } else
                        this.numBoard[run] = 0;
                }
            }	
                break;
            case this.FORMAT_0 :
                default : {
                    //const BOARD_T *pBoard = &(const_cast<Board&>(rBoard))(0);
                    for( run=0; run<64; run++ )  {
                        var piece = rBoard[run];
                        if( piece>0 ){	
                            this.numBoard[run] = nPiece[piece];
                            if( nPiece[ piece ] < this.maxPiece[ piece & Piece.PIECE_MASK ] ) {	
                                this.piecePos[this.pieceInx[nPiece[piece]]] = run;
                                nPiece[ piece ] += 0x10;
                            }
                            CBDebug.assert(this.numBoard[run] > 0);
                        } else
                            this.numBoard[run] = 0;
                    }
                }	break;
        }
    };

    promote( pawn, piece ){
        // NH2020 uint not defined. CHess.COLOUR undefined, cannot find it.
        Log.Missing();
        // piece |= (uint) ( pawn & Chess.COLOUR );
        // while( piece < this.maxPiece[ piece & 0x0f ] ) {
        //     if( piecePos[ this.pieceInx[ piece ] ] == 0xff ) {	
        //         piecePos[ this.pieceInx[ piece ] ] = piecePos[ this.pieceInx[ pawn ] ];
        //         break;
        //     }
        //     piece += 0x10;
        // }
        // numBoard[ piecePos[ this.pieceInx[ pawn ] ] ] = piece;
        // piecePos[ this.pieceInx[ pawn ] ] = 0xff;
        // return 0;
    };

    capture (  piece ) {
        if( piece ) {
            if ((piece & Piece.PIECE_MASK) == Piece.PAWN) {
                this.MOVEPIECE( piece, 0xff);
            } else
                if( piece < this.maxPiece[ piece & 0x0f ] ) {	
                    this.MOVEPIECE(piece,0xff);	//	scrap from lists
                    var lastPiece = this.maxPiece[ piece & 0x0f ] - 0x10;
                    for( var _sq= piece; _sq<lastPiece; _sq+=0x10 ) {	
                        this.piecePos[this.pieceInx[_sq]] = this.piecePos[this.pieceInx[_sq + 0x10]];
                        if (this.piecePos[this.pieceInx[_sq]] == 0xff)
                            break;
                        this.numBoard[this.piecePos[this.pieceInx[_sq]]] = _sq;
                        this.piecePos[this.pieceInx[_sq + 0x10]] = 0xff;
                    }
                    if (this.piecePos[this.pieceInx[lastPiece | (piece & 0x0f)]] != 0xff) {
                        var _piece = this.maxPiece[piece & 0x0f] | (piece & 0x0f);
                        for( var _sq=0; _sq<64; _sq++ ) {	
                            if (this.numBoard[_sq] == _piece) {
                                this.piecePos[this.pieceInx[_piece - 0x10]] = _sq;
                                this.numBoard[_sq] = _piece - 0x10;
                                break;
                            }
                        }
                    } 
                }
        }
        return 0;
    };

    execute( rMove,  rPos ){
        if( !rMove.IsNullMove ) {
            var frSq = rMove.from;
            var toSq = rMove.to;
            var fr = this.numBoard[frSq];
            var to = this.numBoard[toSq];
		     
            if ( !fr ) {
                CBDebug.assert( "no piece to move" );
            }
            this.numBoard[toSq] = this.numBoard[frSq];
            this.numBoard[frSq] = 0;
            this.MOVEPIECE( fr, toSq);
            if (to && (((to & Piece.PIECE_MASK) != Piece.KING)))
                this.capture( to );
            if (rMove.isCastling() || rMove.isProm()  /*.Type & (this.MOVE_EP | this.MOVE_PROM | this.MOVE_CASTLE))>0*/) {
                if ( rMove.isProm() /*(rMove.Type & (this.MOVE_EP | this.MOVE_PROM))>0*/) {
                    if ( rMove.IsProm() ) {
                        this.promote(fr, rMove.prom);
                    }
                    // todo: ep , wie immer
/*                    switch ((rMove.GetMoveType() & (this.MOVE_EP |this.MOVE_PROM))) {
                        case this.MOVE_EP: {
                            var epSq = ( toSq & ~7 ) + ( frSq & 7 );
                            var epPn = this.numBoard[epSq];
                            this.MOVEPIECE(epPn,0xff);
                            this.numBoard[epSq] = 0;
                        }
                            break;
                        case this.MOVE_PROM:
                            this.promote(fr, rMove.Prom);
                            break;
                        default :
                            break;
                    }*/
                } 
                else
                    if( rMove.isCastling() /* (rMove.Type & this.MOVE_CASTLE)>0 */ ) {
                        if ( rPos.getBTM() ){
                            if ( rMove.isLongCastling() ) {
                                this.MOVEPIECE( this.numBoard[Square.A8], toSq + ( Square.B8 - Square.A8 ) );
                                this.numBoard[toSq + ( Square.B8 - Square.A8 )] = this.numBoard[Square.A8];
                                this.numBoard[Square.A8] = 0;
                            }
                            else
                            if ( rMove.isShortCastling() ) {
                                this.MOVEPIECE( this.numBoard[Square.H8], Square.F8 );
                                this.numBoard[Square.F8] = this.numBoard[Square.H8];
                                this.numBoard[Square.H8] = 0;
                            }
                        }
                        else
                        {
                            if ( rMove.isLongCastling() ) {
                                this.MOVEPIECE( this.numBoard[Square.A1], toSq + ( Square.B1 - Square.A1 ) );
                                this.numBoard[toSq + ( Square.B1 - Square.A1 )] = this.numBoard[Square.A1];
                                this.numBoard[Square.A1] = 0;
                            }
                            else
                                if ( rMove.isShortCastling() ) {
                                    this.MOVEPIECE( this.numBoard[Square.H1], Square.F1 );
                                    this.numBoard[Square.F1] = this.numBoard[Square.H1];
                                    this.numBoard[Square.H1] = 0;
                                }

                        }

                    }
            }
            for( var run=0; run<64; run++ ){
            //	if( rBoard(run) != ( this.numBoard[run] & 0x0f ) )
              //      DUMP( "Boards not identical!" );
                if ( this.numBoard[run] && ( this.numBoard[run] < this.maxPiece[this.numBoard[run] & 0o07] ) ) {
                	if( this.piecePos[ this.pieceInx[ this.numBoard[run] ] ] != run ) {
                        // NH2020 DUMP not defined. cannot find it
                        Log.Missing();
                        //DUMP( "(1) Inx corrupted: " + String( run ) );
                    }
                }
            }
        }
    };


    encode= function( rMove,  code ) {
        code = NumBoard.FULL_MOVE_INFO;
        CBDebug.assert( this.numBoard[ rMove.from ]>0 );
		if (this.numBoard[rMove.from] > 0 && (this.numBoard[rMove.from] < this.maxPiece[rMove.Piece.Nominal()])){
			var offs = ((((rMove.to & 0o70) - (rMove.from & 0o70)) & 0o70) |
				(((rMove.To & 0o07) - (rMove.From & 0o07)) & 0o07));
			//	code = pieceOffs[ numBoard[ rMove.fr ] ] + moveOffs[ tableOffs[ rMove.piece & PIECE ] ][ offs ];
			code = (this.pieceOffs[this.numBoard[rMove.from]] + this.moveOffs[this.tableOffs[rMove.Piece.Nominal()] * 64 + offs]);

			CBDebug.assert((code < NumBoard.FULL_MOVE_INFO) || ((this.maxPiece[Piece.KING] > 0x10) && (code >= NumBoard.KING2_START) && (code <= NumBoard.KING2_END)));
			//	Autotest Datenformat während der Benutzung
            var aMove = new Move();
            
            // NH2020 changed to lowercase: Decode, added this
            // NH2020 Chess.COLOUR undefined. cannot find it
            Log.Missing();
            //this.decode(aMove, code, (this.numBoard[rMove.fr] & Chess.COLOUR) == Chess.COLOUR);
            
			CBDebug.assert((aMove.From == rMove.From) && (aMove.To == rMove.To));
		}
    };

	decode (rMove, code, black){
		CBDebug.assert((code < NumBoard.FULL_MOVE_INFO) || ((this.maxPiece[Piece.KING] > 0x10) && (code >= NumBoard.KING2_START) && (code <= NumBoard.KING2_END)));
		var offs = this.pieceMov[code];
		var piece = this.pieceNum[code];
		if (black)
		{
			piece |= Side.SIDE_MASK;
			if ((piece & Piece.PIECE_MASK) == Piece.PAWN)
				offs = this.bPawnMov[code - this.wPawnOffs];
		}
		rMove.from = this.piecePos[this.pieceInx[piece]] & 0o77;
		rMove.to = (((rMove.from & 0o70) + (offs & 0o70)) & 0o70) | ((rMove.from + offs) & 0o07);

		rMove.prom = 0;	//	promotion other than default handled with FULL_MOVE_INFO
		rMove.type = 0;	//	special castling in Chess960 handled elsewhere
		for (var run = 0; run < 64; run++)
		{
			if (this.numBoard[run] != 0 && (this.numBoard[run] < this.maxPiece[this.numBoard[run] & Piece.PIECE_MASK]))
			{
				if (this.piecePos[this.pieceInx[this.numBoard[run]]] != run)
					CBDebug.assert(false);
			}
		}
	}

}
