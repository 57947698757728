import React, { useState, Fragment } from 'react';

import { CBChessBoard } from "CBReact/Components/Chess/ChessBoard/CBChessBoard"
import { CBBoardNavigationBar } from 'CBReact/Components/Chess/BoardNavigationBar/CBBoardNavigationBar';

import { checkPropsAndRenderComponent } from "CBReact/Utils/DebugUtils"

export function CBChessBoardAndNavigationBar (props)
{

    const [whiteIsBottom, setWhiteIsBottom] = useState(true);

    function renderCBChessBoardAndNavigationBar ()
    {
        return(
            <Fragment>
                {/* <CBChessBoardCanvas gameKernel={props.gameKernel} canvasRef={props.canvasRef}/> */}
                <CBChessBoard gameKernel={props.gameKernel} boardPosition={props.boardPosition} whiteIsBottom={whiteIsBottom}/>
                <CBBoardNavigationBar gameKernel={props.gameKernel} whiteIsBottom={whiteIsBottom} setWhiteIsBottom={setWhiteIsBottom}/>
            </Fragment>
        )
    }

    return checkPropsAndRenderComponent([props.gameKernel, props.boardPosition], renderCBChessBoardAndNavigationBar);
}