// ST 2011
// ES 6 MW: Feb 27, 2020

import { NOT } from 'common/Patterns/FuncUtil'

export class StringTools
{
	trimChar( str, ch )
	{
		if ( str.charAt( 0 ) === ch )
			str = str.slice( 1, str.length - 1 );
		if ( str.length > 2 && str.charAt( str.length - 1 ) === ch )
		{
			str = str.slice( 0, str.length - 1 );
		}
		return str;
	}
}

export class Char
{
	static IsDigit = function( _ch )
	{
		return _ch <= '9' && _ch >= '0';
	};

	static IsLetter = function( _ch )
	{
		return ( _ch <= 'z' && _ch >= 'a' ) || ( _ch <= 'Z' && _ch >= 'A' );
	};

	static IsUpperCaseLetter = function( _ch )
	{
		return _ch <= 'Z' && _ch >= 'A';
	};

	static IsLetterOrDigit = function( _ch )
	{
		return Char.IsLetter( _ch ) || Char.IsDigit( _ch );
	}

	static IsWhiteSpace = function( _ch )
	{
		return _ch === '\r' || _ch === '\n' || _ch === ' ' || _ch === '\t';
	};

	static getIsChar = function( _chCmp )
	{
		return function( _ch )
		{
			return _ch === _chCmp;
		};
	};

	static getIsNotChar = function( _chCmp )
	{
		return function( _ch )
		{
			return _ch !== _chCmp;
		};
	};
}

export class StringIterator
{
	constructor( _str )
	{
		this.m_str = _str;
		this.m_len = _str.length;
		// this.m_str = null;
		this.m_inx = -1;
	}


	Current = function()
	{
		return this.m_str[ this.m_inx ];
	};

	Peek = function()
	{
		if ( this.m_inx >= this.m_len - 1 )
			return 0;
		return this.m_str[ this.m_inx + 1 ];
	};

	Next = function()
	{
		++this.m_inx;
		if ( this.m_inx === this.m_len )
			return 0;
		return this.m_str[ this.m_inx ];
	};


	CurrentString = function( _len )
	{
		return this.SubString( this.m_inx, _len );
	};

	NextString = function( _len )
	{
		return this.SubString( this.m_inx + 1, _len );
	};

	SubString = function( _start, _len )
	{
		var rest = this.m_len - _start;
		if ( !_len || _len > rest )
			_len = rest;

		var end = _start + _len;
		return this.m_str.substring( _start, end );
	};

	getPosition = function()
	{
		return this.m_inx;
	};

	setPosition = function( _inx )
	{
		this.m_inx = _inx;
	};

	skip = function( _chs )
	{
		this.m_inx += _chs;
	};

	isEOF = function()
	{
		return this.m_inx === this.m_len;
	};

	AcceptWhile = function( _func, _fromCur )
	{
		var res = "";
		if ( _fromCur )
		{
			var cur = this.Current();
			if ( typeof cur === "undefined" )
				return res;
			if ( !_func( cur ) )
				return res;
			res = cur;
		}
		for ( var ch = this.Next();
			ch !== 0 && _func( ch );
			ch = this.Next() )
		{
			res += ch;
			if ( typeof ch === "undefined" )
				break;

		}
		return res;
	};

	AcceptUntil = function( _func, _fromCur )
	{
		return this.AcceptWhile( NOT( _func ), _fromCur );
	};

	AcceptStr = function( _str, _fromCur )
	{
		var len = _str.length;
		var strFollow = _fromCur ? this.CurrentString( len ) : this.NextString( len );
		if ( strFollow === _str )
		{
			if ( !_fromCur )
				++len;
			this.skip( len );
			return true;
		}
		return false;
	};

	AcceptChar = function( _ch, _fromCur )
	{
		if ( _fromCur )
		{
			var chCur = this.Current();
			if ( chCur === _ch )
			{
				this.skip( 1 );
				return true;
			}
		}
		else
		{
			var chNxt = this.Peek();
			if ( chNxt === _ch )
			{
				this.skip( 2 );
				return true;
			}
		}
		return false;
	};

	skipWhile = function( _func, _fromCur )
	{
		if ( _fromCur )
		{
			if ( !_func( this.Current() ) )
				return;
		}
		for ( var ch = this.Next();
			ch !== 0 && _func( ch );
			ch = this.Next() )
		{ }
	};

	skipUntil = function( _func )
	{
		return this.skipWhite( NOT( _func ) );
	};

}

export function AddStringFillIns()
{
	if ( !String.prototype.trim )
		String.prototype.trim = function()
		{
			return this.replace( /^\s\s*/, '' ).replace( /\s\s*$/, '' );
		};

	if ( !String.prototype.quote )
		String.prototype.quote = function()
		{
			var inquotes = this.replace( /\\/g, "\\\\" ).replace( new RegExp( '"', "g" ), "\\\"" );
			return '"' + inquotes + '"';
		};


	if ( !String.prototype.startsWith )
		String.prototype.startsWith = function( _str )
		{
			if ( this.indexOf ) // no string?
				return this.indexOf( _str ) === 0;
			else
				return false;
		};

	if ( !String.capitalize )
		String.capitalize = function()
		{
			if ( !this.length )
				return this;
			return this[ 0 ].toUpperCase() + this.substring( 1, this.length ).toLowerCase();
		};
}

