// ST 2011
// ES 6 MW: Feb 27, 2020

// import { Log } from "common/Tools/Log";

export class LineStackItem
{
	constructor ( _line, _mvsToGo )
	{
		if ( _line !== undefined )
			this.line = _line;
		else
		{
			this.line = null;
		}
		if ( _mvsToGo !== undefined )
			this.mvsToGo = _mvsToGo;
		else
			this.mvsToGo = 0;
	};

	getLine()
	{
		return this.line;
	};

	getMovesToGo()
	{
		return this.mvsToGo;
	};

	setMovesToGo( _mvsToGo )
	{
		this.mvsToGo = _mvsToGo;
	};

	incMovesToGo()
	{
		++this.mvsToGo;
	};

	decMovesToGo()
	{
		--this.mvsToGo;
	};

	isLineEnd()
	{
		return this.line.length === this.mvsToGo;
	};

	isLineStart()
	{
		return this.mvsToGo === 0;
	};

	pushMove( _mv )
	{
		this.line.length = this.mvsToGo;
		// Log.Log("Push Move to line. " + _mv.getAnno());
		this.line.push( _mv );
		++this.mvsToGo;
	};

	deleteRemaining()
	{
		this.line.length = this.mvsToGo;
	};

	deletePrevious()
	{
		this.line.splice( 0, this.mvsToGo - 1 );
		this.mvsToGo = 1;
		this.line.setSubParentData();
		this.line.setMainLine();

		return this.line;
	};

	//clone()
	//{
    //    //alert("DON'T");
	//    return null;
	//};

	shallowClone()
	{
	    return new LineStackItem( this.line, this.mvsToGo );
	};

}

////////////////////////////////////////////////////////////////////////////////

export class LineStack extends Array
{
	constructor ( _mainLine, _mvsToGo )
	{
		super();
		if ( _mainLine )
		{
			if ( !_mvsToGo )
				_mvsToGo = 0;
			this.push( new LineStackItem( _mainLine, _mvsToGo ) );
		}
	};

	Top()
	{
		return this[this.length - 1];
	};

	shallowClone()
	{
		let res = new LineStack();
		res.length = this.length;
		for ( let inx = 0, len = this.length; inx < len; ++inx )
		{
		    res[inx] = this[inx].shallowClone();
		}

		return res;
	};

    //clone = function()
    //{
    //    alert("DON'T");
    //    return null;
    //};

	deleteRemaining()
	{
        if ( this.Top() )
		    this.Top().deleteRemaining();
	};

	deletePrevious()
	{
		let itmTop = this.Top();
		let lineNewMain = itmTop.deletePrevious();

		this.length = 0;
		this.push( itmTop );

		return lineNewMain;
	};

	gotoPrev()
	{
		let itmTop = this.Top();
		if ( itmTop )
		{
			itmTop.decMovesToGo();
			if ( this.length > 1 && itmTop.getMovesToGo() === 0 )
				this.pop();
		}
	};

	promoteLine()
	{
		let itmTop = this.Top();
		if ( itmTop )
		{
			let subLine = itmTop.getLine();
			let mvsToGo = itmTop.getMovesToGo();

			let parentLine = subLine.getParentLine();
			let inxMv = subLine.getParentMoveIndex();

			let mvKnick = parentLine[inxMv];

			let inxLine = mvKnick.indexOfLine( subLine );

			mvKnick.removeLine( subLine );

			let mvNewKnick = subLine[0];
			mvKnick.moveLinesTo( mvNewKnick );

			parentLine.swapMoves( inxMv, subLine );

			mvNewKnick.insertLine( inxLine, subLine );

			mvsToGo += inxMv;

			this.pop();

			itmTop = this.Top();
			itmTop.setMovesToGo( mvsToGo );
		}

	};

	getSimplePath()
	{
		let arrRes = [];
		let mvParent = null;
		for ( let inx = 0, len = this.length; inx < len; ++inx )
		{
			let itm = this[inx];
			let line = itm.getLine();
			let mvsToGo = itm.getMovesToGo();
			let inxLine = 0;
			if ( mvParent )
			{
				inxLine = mvParent.indexOfLine( line );
			}

			arrRes.push( { inxLine: inxLine, mvsToGo: mvsToGo } );

			mvParent = line[mvsToGo];
		}

		return arrRes;
	};

	selfTest()
	{
		// scheitert nach dem Anlegen/Löschen/Promoten von Varianten.

		// let prevLine = null;
		// let prevMvsToGo = 0;
		// for (let inx = 0, len = this.length; inx < len; ++inx)
		// {
		//     let itm = this[inx];
		//     let line = itm.getLine();
		//     let mvsToGo = itm.getMovesToGo();

		//      CBDebug.assert(line.getParentLine() === prevLine);
		//      CBDebug.assert(line.getParentMoveIndex() === prevMvsToGo);
		//      CBDebug.assert(!prevLine || line.getParentMove().indexOfLine(line) >= 0);

		//     prevLine = line;
		//     prevMvsToGo = mvsToGo;
		// }
	};
}