// jb 20.11.2013

export var TeraBrainUserMsgType =
{
	TBUSER_REQ_ENGINENAMES: 1400,
	TBUSER_ENGINENAMES: 1401,

	TBUSER_REQ_USERENTRIES: 1402,
	TBUSER_USERENTRIES: 1403,

	TBUSER_CHAT_TO_ALL: 1404,

	TBUSER_INJECT_GAME: 1420,
	TBUSER_CHESSSERVER_LOGIN: 1421,
	TBUSER_CHESSSERVER_LOGOUT: 1422,
	TBUSER_CONFIRM_LOGIN: 1423,

	TBUSER_HOTPOSITION: 1424,
	TBUSER_REQ_HOTPOSITION: 1425,

	TBUSER_CONFIRM_REQUEST: 1426,
	TBUSER_DEEP_CHECK: 1427,
	TBUSER_DEEP_VAR: 1428,
	TBUSER_BEGIN_DONATION: 1429,
	TBUSER_END_DONATION: 1430,
	TBUSER_SLAVE_ANALYSIS: 1431,
	TBUSER_RETRACT_REQUEST: 1432,
	TBUSER_RETRACT: 1433,
	TBUSER_SUBMIT_POSITION: 1434,
	TBUSER_SUBMIT_POSITION_DONE: 1435,
	TBUSER_SUBMIT_GAME: 1436,	// 36
	TBUSER_SUBMIT_GAME_RESPONSE: 1437,
	TBUSER_ANALYSE_GAME: 1438,
	TBUSER_ANALYSE_GAME_RESPONSE: 1439,

	TBUSER_COMMENTS: 1440,
	TBUSER_REQ_COMMENTS: 1441,
	TBUSER_ADD_COMMENT: 1442,
	TBUSER_COMMENT_FEEDBACK: 1443,
	TBUSER_REFRESH_COMMENT: 1444,
	TBUSER_CONFIRMCOMMENT_REQUEST: 1445,

	TBUSER_REQ_RANKINGLIST: 1460,
	TBUSER_RANKINGLIST: 1461,
	TBUSER_BACKUP_DATABASES: 1462,
	TBUSER_TEST: 1463,
	TBUSER_ADMIN_RESET_RANKING: 1464,
	TBUSER_ADMIN_BLOCK_DISCOVERY: 1465,
	TBUSER_REQUEST_PERSONALDATA: 1466,
	TBUSER_USERDATA: 1467,
	TBUSER_REQUEST_LOCALTREE: 1468,
	TBUSER_LOCALTREE: 1469,

	TBUSER_SUSPICIOUS_LINE: 1480,
	TBUSER_RESET_LIN: 1481,
	TBUSER_FEEDBACK_ON_LINE: 1481,
	TBUSER_SET_MACHINE_FACTOR: 1482,

	toString:
		function ( n ) {
			for ( var attr in this ) {
				if ( this[attr] == n )
					return attr;
			}
			return "Unknown TBUserMsgId: " + n;
		}

};
