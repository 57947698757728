

import React  from 'react';
import styles from "./CBHorizontalSpacer.module.css";

export function CBHorizontalSpacer (props)
{
    return (
        <div className={`${styles.base}`}></div>
    )
}