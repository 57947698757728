// "use strict";

import { CBDate } from "common/Tools/CBDate";

export class PlayerKey
{

	constructor ( rFirstName, rLastName )
	{
		this.m_nIdenticalCount = 0;
		this.m_nCBId = -1;
		this.m_sFirstName = rFirstName;
		this.m_sLastName = rLastName;

		// NH2020 put these into constructor
		// assumend CBDate
		this.m_aBirthDate = new CBDate();
	};

	static PLKMAX = {
		MAX_LASTNAME: 100,
		MAX_FIRSTNAME: 100
	};

	write ( pBuf )
	{
		pBuf.writeString( this.m_sLastName );
		pBuf.writeString( this.m_sFirstName );
		pBuf.writeCBDate( this.m_aBirthDate );
		pBuf.writeUint16( this.m_nIdenticalCount );
		pBuf.writeUint32( this.m_nCBId );
	};

	readFromDataBuffer ( pBuf )
	{
		this.m_sLastName = pBuf.readString( PlayerKey.PLKMAX.MAX_FIRSTNAME );
		this.m_sFirstName = pBuf.readString( PlayerKey.PLKMAX.MAX_LASTNAME );

		this.m_aBirthDate = pBuf.readCBDate();
		this.m_nIdenticalCount = pBuf.readUint16();
		this.m_nCBId = pBuf.readUint32();

		return true;
	};

	getStr ()
	{
		var strRet = this.m_sLastName;
		strRet += ", " + this.m_sFirstName;
		return strRet;
	};

	getCheckSum ()
	{
		var usn = 0;
		for ( var i = 0; i < this.m_sLastName.Length; i++ )
			usn += this.m_sLastName[i];
		for ( var i = 0; i < this.m_sFirstName.Length; i++ )
			usn += this.m_sFirstName[i];
		usn += this.m_aBirthDate.GetYear();
		usn += this.m_nIdenticalCount;
		return usn;
	};

	getFirstName ()
	{
		return this.m_sFirstName;
	};

	setFirstName ( aStr )
	{
		this.m_sFirstName = aStr;
	};

	getLastName ()
	{
		return this.m_sLastName;
	};

	setLastName ( aStr )
	{
		this.m_sLastName = aStr;
	};

	getBirthDate ()
	{
		return this.m_aBirthDate;
	};

	setBirthDate ( rD )
	{
		this.m_aBirthDate = rD;
	};

	getIdenticalCount ()
	{
		return this.m_nIdenticalCount;
	};

	setIdenticalCount ( n )
	{
		this.m_nIdenticalCount = n;
	};

	incIdenticalCount ()
	{
		this.m_nIdenticalCount++;
	};

	getCBId ()
	{
		return this.m_nCBId;
	};

	setCBId ( nId )
	{
		this.m_nCBId = nId;
	};

}

