import { useEffect, useState } from "react";

import { CookieUtils } from 'CBReact/Utils/CookieUtils';
import { DESIGN_THEMES } from 'CBReact/Hooks/ReactContext/useThemeContext'
import { ONLINEDB_LAYOUTS } from 'CBReact/Hooks/ReactContext/useOnlineDBLayoutContext'

export const BOARD_LAYOUTS = 
{
    desktop: 0,
    mobile: 1,
}

// Evtl: Nummerierung ändern, damit gesetzte Cookies der alten Version bestehen bleiben
export const PIECE_THEMES = 
{
    preload: 0,
    fritz: 1,
    crystals: 2,
    uscf: 3,
    oldstyle: 4,
}

export const BOARD_THEMES = {
    cleanLight: "cleanLight",
    cleanDark: "cleanDark",
    woodLight: "woodLight",
    woodDark: "woodDark",
    blueLight: "blueLight",
    blueDark: "blueDark",
}

export const BOARD_THEMES_SETTINGS = {
    clean: 0,
    wood: 1,
    blue: 2,
}

export function useBoardThemeContext (appLayout, mainDesignTheme)
{
    const [isInitialised, setIsInitialised] = useState(false);

    const [boardLayout, setBoardLayout] = useState(BOARD_LAYOUTS.desktop);

    const [boardTheme, setBoardTheme] = useState(BOARD_THEMES.cleanLight);
    const [boardImagesCss, setBoardImagesCss] = useState();
    const [boardThemeSetting, setBoardThemeSetting] = useState(BOARD_THEMES_SETTINGS.clean);

    const [pieceTheme, setPieceTheme] = useState(PIECE_THEMES.preload);
    const [pieceCss, setPieceCss] = useState();

    useEffect(() => {
        changeBoardLayout();
    }, [appLayout]);

    function changeBoardLayout ()
    {
        if (appLayout === ONLINEDB_LAYOUTS.oneColumn)
            setBoardLayout(BOARD_LAYOUTS.mobile);
        else
            setBoardLayout(BOARD_LAYOUTS.desktop);
    }

    useEffect(() => {
        if (!isInitialised)
        {
            initBoardTheme();
            initPieceTheme();
            setIsInitialised(true);
        }
        else
            changeBoardTheme(boardThemeSetting);
    }, [mainDesignTheme]);

    //#region Board Theme

    function initBoardTheme ()
    {
        // var savedBoardThemeSetting = parseInt(CookieUtils.GetCookie("CBBoardThemeSetting"));
        var savedBoardThemeSetting = parseInt(CookieUtils.GetStorageItem("CBBoardThemeSetting"));
        
        if (savedBoardThemeSetting && Object.values(BOARD_THEMES_SETTINGS).indexOf(savedBoardThemeSetting) !== -1)
            changeBoardTheme(savedBoardThemeSetting);
        else
            changeBoardTheme(BOARD_THEMES_SETTINGS.clean);
    }

    function changeBoardTheme (newBoardThemeSetting)
    {
        setBoardThemeSetting(newBoardThemeSetting);

        switch (newBoardThemeSetting)
        {
            case BOARD_THEMES_SETTINGS.clean:
                mainDesignTheme === DESIGN_THEMES.light ? setBoardTheme(BOARD_THEMES.cleanLight) : setBoardTheme(BOARD_THEMES.cleanDark);
                break;
            case BOARD_THEMES_SETTINGS.blue:
                mainDesignTheme === DESIGN_THEMES.light ? setBoardTheme(BOARD_THEMES.blueLight) : setBoardTheme(BOARD_THEMES.blueDark);
                break;
            case BOARD_THEMES_SETTINGS.wood:
                mainDesignTheme === DESIGN_THEMES.light ? loadBoardImagesCss("ClassicWood.module.css", BOARD_THEMES.woodLight) : loadBoardImagesCss("ClassicWood.module.css", BOARD_THEMES.woodDark);
                break;
            default:
                mainDesignTheme === DESIGN_THEMES.light ? setBoardTheme(BOARD_THEMES.cleanLight) : setBoardTheme(BOARD_THEMES.cleanDark);
                break;
        }
    }

    function loadBoardImagesCss (pathEnding, newBoardTheme)
    {
        import("CBReact/Resources/CssEncoded/Board/" + pathEnding)
        .then(cssObject => {
            setBoardImagesCss(cssObject.default);
            setBoardTheme(newBoardTheme);
        })
        .catch(error => { 
            console.log("Loading Error: " + error);
        });
    }

    //#endregion

    //#region Piece Theme 

    function initPieceTheme ()
    {
        // var savedPieceTheme = parseInt(CookieUtils.GetCookie("CBPieces"));
        var savedPieceTheme = parseInt(CookieUtils.GetStorageItem("CBPieces"));

        if (savedPieceTheme && Object.values(PIECE_THEMES).indexOf(savedPieceTheme) !== -1) 
            changePieceTheme(savedPieceTheme);
        else
            changePieceTheme(PIECE_THEMES.fritz);
    }

    function changePieceTheme (newPieceTheme)
    {
        switch (newPieceTheme)
        {
            case PIECE_THEMES.fritz:
                loadPieceImagesCss("FritzPieces.module.css", newPieceTheme);
                // loadPieceImagesCss("FritzPiecesBase64.module.css", newPieceTheme);
                break;
            case PIECE_THEMES.crystals:
                loadPieceImagesCss("CrystalsPieces.module.css", newPieceTheme);
                // loadPieceImagesCss("CrystalsPiecesBase64.module.css", newPieceTheme);
                break;
            case PIECE_THEMES.uscf:
                loadPieceImagesCss("UscfPieces.module.css", newPieceTheme);
                // loadPieceImagesCss("UscfPiecesBase64.module.css", newPieceTheme);
                break;
            case PIECE_THEMES.oldstyle:
                loadPieceImagesCss("OldstylePieces.module.css", newPieceTheme);
                // loadPieceImagesCss("OldstylePiecesBase64.module.css", newPieceTheme);
                break;
            default:
                console.log("Could not find the theme selected.");
                break;
        }
    }

    function loadPieceImagesCss (pathEnding, newPieceTheme)
    {
        import("CBReact/Resources/CssEncoded/ChessPieces/" + pathEnding)
        .then(cssObject => {
            setPieceCss(cssObject.default);
            setPieceTheme(newPieceTheme);
        })
        .catch(error => { 
            console.log("Loading Error: " + error);
        });
    }

    function getPieceImageCss (pieceIndex)
    {
        if (!pieceCss)
            return "";

        switch(pieceIndex)
        {
            case 1:
                return pieceCss.whiteKing;
            case 2:
                return pieceCss.whiteQueen;
            case 3: 
                return pieceCss.whiteKnight;
            case 4:
                return pieceCss.whiteBishop;
            case 5: 
                return pieceCss.whiteRook;
            case 6:
                return pieceCss.whitePawn;
            case 9: 
                return pieceCss.blackKing;
            case 10:
                return pieceCss.blackQueen;
            case 11:
                return pieceCss.blackKnight;
            case 12:
                return pieceCss.blackBishop;
            case 13:
                return pieceCss.blackRook;
            case 14:
                return pieceCss.blackPawn;
            default:
                return "";
        }
    }

    //#endregion

    return {boardLayout, boardTheme, boardImagesCss, changeBoardTheme, boardThemeSetting, pieceTheme, changePieceTheme, getPieceImageCss};
}