// ES6 4.3.2020

import { Timer } from 'common/Tools/Timer'

export class BaseClock
{
	static nextId = 0;

	constructor( clockLink )
	{
		this.clockLink = clockLink;
		this.updateTimer = new Timer( this.update.bind( this ) );

		this.isBlack = false;

		this.id = ++BaseClock.nextId;
	};

	onTimeOverstepped( black )
	{
		this.clockLink.showTimeOverstepped( black );
	};

	setNoClock()
	{
		//this.clockLink.setText( "No", false /*black*/ );
		//this.clockLink.setText( "Clock", true );
		this.clockLink.setText( "---", false );
		this.clockLink.setText( "---", true );
	};

	start()
	{
	};

	stop()
	{
	};

	isRunning()
	{
		return false;
	};


	setToMove( black )
	{
		this.isBlack = black;
	};

	onMove( isBTM )
	{
		this.setToMove( isBTM );
		this.start();
	};

	initFromGame( game )
	{

	};

	update()
	{
	}
}