import React, { useContext, useReducer, useEffect } from 'react';
import ReactGA from 'react-ga';

import { TableReducer, TableSort, TableData } from "CBReact/Components/Table/TableUtils"

import { CBTable } from "CBReact/Components/Table/CBTable"
import { CBMButtonBadge } from "CBReact/Components/DesignElements/Buttons/ButtonBadge/CBButtonBadge"

import { LOGIN_MODES } from 'CBReact/Components/Account/AccountUtils';
import { LocalizationContext, LoginContext, OnlineLobbyContext } from "CBReact/Contexts/CBOnlineDBContext";
import { checkPropsAndRenderComponent } from 'CBReact/Utils/DebugUtils';


export function CBDatabaseTable (props)
{
    const loginStatus = useContext(LoginContext).loginStatus;
    const loc = useContext(LocalizationContext).localization;
    const databaseTableContent = useContext(OnlineLobbyContext).databaseTableContent;
    const onlineLobbyIsLoading = useContext(OnlineLobbyContext).isLoading;
    const onlineLobbyReachedScrollSearchEnd = useContext(OnlineLobbyContext).reachedScrollSearchEnd;
    const onlineLobbySearchCount = useContext(OnlineLobbyContext).currentSearchCount;

    const databaseTableData = new TableData({
        header: [loc.strings.YEAR, loc.strings.WHITE, "Elo", loc.strings.BLACK, "Elo", loc.strings.RESULT, "ECO", "Moves"],
        flexGrow: [1.2, 3.3, 1.2, 3.3, 1.2, 1.3, 1.2, 2.6],
        sortingMethods: [TableSort.numbers, TableSort.alphabet, TableSort.numbers, TableSort.alphabet, TableSort.numbers, TableSort.alphabetReversed, TableSort.alphabet, TableSort.move],
        removeOnResize: [0, 5, 6, 7],
        removeAtSize: [470, 340, 500, 440], 
        renderFancy: { 7: function () { return <CBMButtonBadge size="small" onClick={handlePremiumClick}>Premium</CBMButtonBadge> }},
    });

    const [databaseTable, databaseTableDispatch] = useReducer(TableReducer, databaseTableData);

    useEffect(() => {
            databaseTableDispatch({type: 'SELECTROW', newSelection: props.selectedGame});
    }, [props.selectedGame])

    useEffect(() => {

        let isInitialSearch = onlineLobbySearchCount <= 1;

        databaseTableDispatch({type: 'CONTENT', newContent: databaseTableContent, keepHeaderSelection: !isInitialSearch});

        if (!isInitialSearch)
            databaseTableDispatch({type: 'REFRESHSORT'});

        if (props.gameKernel && databaseTableContent.length !== 0)
            props.gameKernel.setOnlineDBLoadPos();

        // If the current game has no moves: Load first game
        if (props.gameKernel.game.getMoves().length === 0 && databaseTableContent.length !== 0)
        {
            props.gameKernel.setOnlineDBListGame(databaseTableContent[0].info.game);
            databaseTableDispatch({type: 'SELECTROW', newSelection: databaseTableContent[0].info.selection});
        }

        if (isInitialSearch)
            databaseTableDispatch({type: 'SCROLLTOTOP'});

    }, [props.gameKernel, databaseTableContent]);

    useEffect(() => {

        if (loginStatus === LOGIN_MODES.guest || loginStatus === LOGIN_MODES.free)
        {
            let newRenderFancy = {
                7: function () { return <CBMButtonBadge size="small" onClick={handlePremiumClick}>Premium</CBMButtonBadge> }
            };

            databaseTableDispatch({type: 'RENDERFANCY', newRenderFancy});
        }
        else if (loginStatus === LOGIN_MODES.standard || loginStatus === LOGIN_MODES.premium)
        {
            let newRenderFancy = {};
            databaseTableDispatch({type: 'RENDERFANCY', newRenderFancy});
        }

    }, [loginStatus]);

    useEffect (() => {
        if (loc.strings)
            databaseTableDispatch({type: "SETHEADER", header: [loc.strings.YEAR, loc.strings.WHITE, "Elo", loc.strings.BLACK, "Elo", loc.strings.RESULT, "ECO", "Moves"]})
    }, [loc]);

    function handleGameClick (e, rowInfo)
    {
        e.preventDefault();

        if (rowInfo != null && rowInfo.game != null)
            props.gameKernel.setOnlineDBListGame(rowInfo.game);

        ReactGA.event({
            category: 'Interaction',
            action: 'Opened Database Game',
        });
    }

    function handlePremiumClick (e)
    {
        e.stopPropagation(); 
        window.open( "https://account.chessbase.com/en/matrix", "_blank", "noopener");
    }

    function renderCBDatabaseTable ()
    {
        return (
            <CBTable 
                tableData={databaseTable} 
                tableDispatch={databaseTableDispatch} 
                onEntryClick={handleGameClick}
                showScrollEnd={!onlineLobbyIsLoading && !onlineLobbyReachedScrollSearchEnd}
                fillRemainingHeight={props.fillRemainingHeight} 
                maxHeight={props.tableMaxHeight} 
                isLoading={onlineLobbyIsLoading}
                loadingCircleStyle={{top: "80px"}}
            />
        );
    }

    return checkPropsAndRenderComponent([props.gameKernel, props.selectedGame, loginStatus, loc, databaseTableContent], renderCBDatabaseTable);

}