import React, { useState } from 'react';
import styles from "./CBCheckBox.module.css";

import { checkPropsAndRenderComponent } from 'CBReact/Utils/DebugUtils';

export function CBCheckBox (props)
{
    const [checked, setChecked] = useState(props.initValue);

    function handleKeyDown (e)
    {
        if (e.keyCode === 13)
        {
            e.preventDefault();
            props.handleChange(!checked);
            setChecked(!checked);
        }
    }

    function handleChange (e)
    {
        e.preventDefault();
        
        props.handleChange(!checked);
        setChecked(!checked);
    }

    function renderCBCheckBox ()
    {
        return (
            <div 
                className={styles.container}
                onClick={handleChange}>

                <div 
                    tabIndex={0}
                    className={`${styles.checkbox} ${checked ? styles.checked : ""}`} 
                    onClick={handleChange}
                    onKeyDown={handleKeyDown}/>
                
                <span className={styles.label}>
                    {props.label}
                </span>

            </div>
        );
    }

    return checkPropsAndRenderComponent([props.label, props.handleChange, props.initValue], renderCBCheckBox);

}