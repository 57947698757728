import { AccountUtils } from "CBReact/Components/Account/AccountUtils";
import { LOGIN_MODES } from "CBReact/Components/Account/AccountUtils";
import { CookieUtils } from "CBReact/Utils/CookieUtils";
import { useEffect, useRef, useState } from "react";

export function useLoginContext ()
{
    const [loginStatus, setLogInStatus] = useState((CookieUtils.GetCookie("AccountToken") ?? CookieUtils.GetStorageItem("AccountToken")) ? LOGIN_MODES.free : LOGIN_MODES.guest);
    const loginStatusRef = useRef(loginStatus);

    const [userName, setUserName] = useState(CookieUtils.GetCodedCookie("AccountName64", true) ?? CookieUtils.GetCodedStorageItem("AccountName64", true));
    const [loginToken, setLoginToken] = useState(CookieUtils.GetCookie("AccountToken") ?? CookieUtils.GetStorageItem("AccountToken"));

    //console.log(CookieUtils.GetCookie("AccountToken"), CookieUtils.GetStorageItem("AccountToken"), CookieUtils.GetCodedCookie("AccountName64", true), CookieUtils.GetCodedStorageItem("AccountName64", true));

    // If Login Token Cookie on initialisation: Init Token Login to check for subscription status
    useEffect(() => {
		if (userName && loginToken)
            AccountUtils.TokenLogin(userName, loginToken, handleTokenLogin);
    }, []);

    useEffect(() => {
        loginStatusRef.current = loginStatus;
    }, [loginStatus]);

    function changeLogInStatus (loginValue, userName, loginToken)
    {
        setLogInStatus(loginValue);

        if (userName)
            setUserName(userName);

        if (loginToken)
            setLoginToken(loginToken);
    }

    function logout ()
    {
        setLogInStatus(LOGIN_MODES.guest);

        // Clear classic Cookies -> Cross App logout
        CookieUtils.ClearCookie("AccountName64");
        CookieUtils.ClearCookie("AccountToken");
        CookieUtils.ClearCookie("AccountMode");
        CookieUtils.ClearCookie("CBCOMMON");

        // Sync Classic Cookies and local storage
        CookieUtils.ClearStorageItem("AccountName64");
        CookieUtils.ClearStorageItem("AccountToken");

        // TODO? Save AccountMode?
        // CookieUtils.SetCookie("AccountMode", "1");
    }

    function getCurrentLoginStatus ()
    {
        return loginStatusRef.current;
    }

    function handleTokenLogin (json)
    {
        if (json && json.ResultType === 1)
            changeLogInStatus(AccountUtils.GetLoginStatusFromSubscription(json.Subscription));
        else
            AccountUtils.HandleLoginFailed();
    }

    return {loginStatus, changeLogInStatus, getCurrentLoginStatus, logout, userName, loginToken};
}