
import React, { useContext, useState } from 'react';
import styles from "./CBNavBarBottom.module.css";
import iconStyles from "CBReact/Components/DesignElements/Icons/CBIcons.module.css"

import { SIDENAV_RENDERMODES } from "./CBNavBarSide"
import { SideNavContext } from "CBReact/Contexts/CBOnlineDBContext"
import { checkPropsAndRenderComponent } from 'CBReact/Utils/DebugUtils';
import { ONECOLUMNRENDERMODES } from "CBReact/Components/Frames/CBOnlineDBFrame"
import { CBMBoardIcon, CBMSearchIcon, CBMSideNavIcon, CBMBookIcon } from 'CBReact/Components/DesignElements/Icons/CBIcons';

export function CBNavBarBottom (props)
{
    const changeSideNav = useContext(SideNavContext).changeSideNav;
    const sideNavIsOpen = useContext(SideNavContext).sideNavIsOpen;

    const [selected, setSelected] = useState(ONECOLUMNRENDERMODES.board);

    function selectModule (renderMode)
    {
        props.changeOneColumnRenderMode(renderMode);
        setSelected(renderMode);
    }

    function renderCBNavBarBottom ()
    {
        return (
            <div className={styles.container}>
                <div className={styles.navigateButton} onClick={() => selectModule(ONECOLUMNRENDERMODES.board)}>
                    <div className={`${styles.selection} ${selected === ONECOLUMNRENDERMODES.board && styles.selected}`} />
                    <div className={styles.navigateText}><CBMBoardIcon height={"50%"}/></div>
                </div>
                <div className={styles.navigateButton} onClick={() => selectModule(ONECOLUMNRENDERMODES.search)}>
                    <div className={`${styles.selection} ${selected === ONECOLUMNRENDERMODES.search && styles.selected}`} />
                    <div className={styles.navigateText}><CBMSearchIcon height={"50%"}/></div>
                </div>
                <div className={styles.navigateButton} onClick={() => selectModule(ONECOLUMNRENDERMODES.book)}>
                    <div className={`${styles.selection} ${selected === ONECOLUMNRENDERMODES.book && styles.selected}`} />
                    <div className={styles.navigateText}><CBMBookIcon height={"50%"}/></div>
                </div>
                <div className={styles.navigateButton} onClick={() => changeSideNav(true, SIDENAV_RENDERMODES.main)}>
                    <div className={`${styles.selection} ${sideNavIsOpen === true && styles.selected}`} />
                    <div className={styles.navigateText}><CBMSideNavIcon className={iconStyles.defaultIcon} height={"45%"} /></div>
                </div>
            </div>
        );
    }

    return checkPropsAndRenderComponent([props.changeOneColumnRenderMode], renderCBNavBarBottom);
}