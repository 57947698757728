// mw 12.2.2013
// ES6 Feb 2020

/* eslint-disable eqeqeq */

import { Log } from 'common/Tools/Log'
import { Timer } from 'common/Tools/Timer'
import { Piece, Side, Square } from 'common/Chess/Logic/Chess';
import { Move } from 'common/Chess/Logic/Move';
import { ListenersUtil } from 'common/Patterns/Listeners';
import { ObjUtil } from 'common/Patterns/ObjectUtil';
import { BoardArrow } from 'common/Chess/GUI/BoardAttributes'


// NH2020I Klasse, die sich darum kümmert Zugeingaben abzufertigen. Hier wird ein neuer Zug gemacht (boardWinMoveEntered).
// Hier werden aber wohl auch die Pfeile für vorher eingegebene PreMoves gezeichnet etc.

export var AllowedColor = {
	NONE: 0,
	WHITE_ONLY: 1,
	BLACK_ONLY: 2,
	BOTH_COLORS: 3,

	detFromOpponentSide: function (side) {
		if (side === Side.BLACK)
			return AllowedColor.WHITE_ONLY;
		else
			return AllowedColor.BLACK_ONLY;
	}
};

export class MoveEntry {
	constructor(_board) {
		this.board = _board;
		this.fnToMove = function () { return null; };

		this.withPreMove = true;
		this.preMoves = [];
		this.bInProcessPreMove = false;

		// NH2020 Does InitListeners work? Seems to work
		// Log.WillItWork();
		MoveEntry.InitListeners()
	};

	onMove() {
		//PREMOVE?
		var sd = this.getMySide();
		if (sd === null)
			return;
		if (sd === this.getCurPos().getSideToMove()) {
			this.processPreMove();
		}
	};

	getLastPreMoveToSq() {
		if (this.preMoves.length)
			return this.preMoves[this.preMoves.length - 1].to;
	};

	onSquareClicked(sq) {
		//	Log.Log( "Click=" + sq );
		try {
			//	this.clearPreMoves();

			//if ( sq == this.getLastPreMoveToSq() )
			//	return;	//moving again with the last premove piece, dont clear -> We have only single premoves anyway

			//var piece = this.getCurPos().getPiece( sq );
			//// empty or opponent piece, cancel premoves
			//if ( piece == 0 || Piece.side( piece ) == this.getCurPos().getSideToMove() )
			//	this.clearPreMoves();

			//// premoving piece clicked? cancel premoves.
			//for ( var p = 0; p < this.preMoves.length; p++ )
			//{
			//	if ( sq == this.preMoves[p].from )
			//	{
			//		this.clearPreMoves();
			//		break;
			//	}
			//}
		}
		catch (x) {
			Log.Exception("ME:sq", x);
		}
	};

	clearPreMoves() {
		// Log.Log("Clear PreMoves", "LogBold");
		this.preMoves = [];
		this.getBoardWin().undoAttributes();
	};

	getPreMoveHsla(n) {
		switch (n % 7)  // same colors as in VisualizePreMoves in C++
		{
			default:
			case 0:
				// HC_GREEN;
				return "hsla( 120, 90%, 40%, 0.9 )";
			case 1:
				//nCol = HC_WHITE;
				return "hsla( 120, 00%, 100%, 0.9 )";
			case 2:
				//nCol = HC_ORANGE;
				return "hsla( 5, 90%, 70%, 0.9 )";
			case 3:
				// nCol = HC_CYAN;
				return "hsla( 180, 90%, 50%, 0.9 )";
			case 4:
				// nCol = HC_GRAY;
				return "hsla( 180, 00%, 40%, 0.9 )";
			case 5:
				// nCol = HC_BLACK;
				return "hsla( 0, 0%, 5%, 0.9 )";
			case 6:
				return "hsla( 270, 70%, 60%, 0.9 )";
		}
	};

	renderPreMoves() {
		try {
			var attributes = [];
			for (var p = 0; p < this.preMoves.length; p++) {
				var preMove = this.preMoves[p];
				var rgba = this.getPreMoveHsla(p);
				attributes.push(new BoardArrow(preMove.from, preMove.to, rgba));
			}
			if (attributes.length) {
				this.getBoardWin().queueBoardAttributes(attributes, {
					eraseQueue: false,
					renderNow: true,
					requestAnimInRender: true
				});
			}
		}
		catch (x) {
			Log.Exception("ME::rp");
		}
	};

	boardWinMoveEntered(from, to, prom) {
		try {
			if (this.colorOk() && this.squareHasColorToMove(from)) {
				//console.log("On Move entered = true.");
				var mv = new Move(from, to, prom & 7);
				this.fireOnStopClock();

				// Hierdurch wird bei GameKernel "React To entered Move" aufgerufen
				// Dann RawBoard Control -> _onGameCurPosChanged.
				this.fireOnMoveEntered(mv);

				return true;
			}
			else if (this.withPreMove) {
				var preMove = new Move(from, to, prom & 7);
				var found = false;
				this.preMoves.forEach(function (m) {
					if (m.from == from && m.to == to)
						found = true;
				});
				if (!found) {
					this.preMoves.push(preMove);
					Log.Log("PreMove entered: " + preMove + ", tot=" + this.preMoves.length);
				}
				return false;
			}
		}
		catch (x) {
			Log.Exception("MvE::Entd", x);
		}

		return false;
	};

	squareHasColorToMove(sq) {
		// Wenn die Seite der Figure auf sq == die Seite, die am Zug ist
		return Piece.side(this.getCurPos().getPiece(sq)) == this.getCurPos().getSideToMove();
	};

	clearAttributesOnMouseDown(sq) {
		try {
			var doClear = true;
			if (this.withPreMove) {
				var piece = this.getCurPos().getPiece(sq);	// clicked a premove piece?
				if (piece && Piece.side(piece) != this.getCurPos().getSideToMove() && Piece.side(piece) === this.getMySide()) {
					doClear = false;
				}
				if (sq == this.getLastPreMoveToSq()) {
					doClear = false;
				}
			}
			if (doClear)
				this.board.undoAttributes(true /*keep attributes from baggage*/);
		}
		catch (x) {
			Log.Exception("ME::clr", x);
		}

	};

	processPreMove() {
		if (this.bInProcessPreMove)
			return false;

		this.bInProcessPreMove = true;

		var bRet = false;
		if (this.preMoves.length) {
			var preMove = this.preMoves.shift();

			var bLegal = this.getCurPos().isLegalFromTo(preMove.from, preMove.to)
				&& this.getCurPos().getSideToMove() == this.getMySide();

			if (bLegal) {

				Log.Log("Exec Legal PreMove: " + preMove, "LogBold");

				// need to decouple in play against engine. BUT STOPS CLOCK TOO LATE, therefore fire an event:

				this.fireOnStopClock();

				new Timer.launch(function () {
					this.fireOnMoveEntered(preMove);
				}.bind(this));

				bRet = true;
				this.renderPreMoves();
			}

			else {
				Log.Log("PreMove illegal: " + preMove, "LogRed");
				this.clearPreMoves();
			}
		}
		this.bInProcessPreMove = false;
		return bRet;
	};

	fillSingleClickMove(board, to) {
		var singleClickMove = {
			ok: false,
			from: -1
		};

		try {
			if (board.get(to) == Piece.NONE) {
				var mvs = this.getCurPos().generateLegals();

				mvs = mvs.filter(function (_mv) { return _mv.to == to; });

				if ((singleClickMove.ok = (mvs.length == 1) && this.isSingleClickColorOk()) !== 0) {
					singleClickMove.from = mvs[0].from;
				}
			};
		}
		catch (x) {
			Log.Exception("ME:FS", x);
		}

		return singleClickMove;
	};

	isLegalOrPremove(board, from, to) {
		if (this.colorOk(board.get(from), from))
			return this.getCurPos().isLegalFromTo(from, to) || this.isPremove(board, from, to);

		return false;
	};

	isLegal(board, from, to) {
		if (this.canMoveForCurSide())
			return this.getCurPos().isLegalFromTo(from, to);

		return false;
	};

	isMyPiece(sq) {
		var isBlack = !!(this.getBoardWin().board.get(sq) & Piece.SIDE_MASK);
		return (isBlack == this.getCurPos().isBTM()) && this.getBoardWin().board.get(sq) > 0;
	};

	isOpponentPiece(sq) {
		var isBlack = !!(this.getBoardWin().board.get(sq) & Piece.SIDE_MASK);
		return (isBlack != this.getCurPos().isBTM()) && this.getBoardWin().board.get(sq) > 0;
	};

	tryTwoClickMove(from, to) {
		var board = this.getBoardWin().board;

		if (this.colorOk(board.get(from), from)) {
			if (this.getCurPos().isLegalFromTo(from, to)) {
				var prom = 0;
				if (this.getBoardWin().isWhitePromoPawn(from) || this.getBoardWin().isBlackPromoPawn(from))
					prom = Piece.QUEEN;
				return this.boardWinMoveEntered(from, to, prom);
			}
		}
		return false;
	};

	colorOk(touchedPiece, sq) {
		switch (this.getAllowedColor()) {
			case AllowedColor.BOTH_COLORS:
				return true;
			case AllowedColor.WHITE_ONLY:
				return this.getCurPos().isWTM() || this.colorOkInPremove(touchedPiece, Side.WHITE, sq);
			case AllowedColor.BLACK_ONLY:
				return this.getCurPos().isBTM() || this.colorOkInPremove(touchedPiece, Side.BLACK, sq);
			default:
				return false;
		}
	};

	colorOkInPremove(touchedPiece, side, sq) {
		if (this.withPreMove) {
			if (this.preMovesLeadTo(sq))
				return true;

			if (touchedPiece && Piece.side(touchedPiece) == side)
				return true;
		}
		return false;
	};

	canMoveForCurSide() {
		switch (this.getAllowedColor()) {
			case AllowedColor.BOTH_COLORS:
				return true;
			case AllowedColor.WHITE_ONLY:
				return this.getCurPos().isWTM();
			case AllowedColor.BLACK_ONLY:
				return this.getCurPos().isBTM();
			default:
				return false;
		}
	};

	isSingleClickColorOk() {
		switch (this.getAllowedColor()) {
			case AllowedColor.WHITE_ONLY:
				return this.getCurPos().isWTM();
			case AllowedColor.BLACK_ONLY:
				return this.getCurPos().isBTM();
			default:
			case AllowedColor.BOTH_COLORS:
				return true;
		}
	};

	onPosInput(pos) {
		//this.setAllowedColor( pos.getSideToMove() );
	};

	allowBothColors() {
		this.setMySide(null);
	};

	isPremove(board, from, to) {
		if (from >= 0 && to >= 0 && from <= 63 && to < 63) {
			if (this.preMovesLeadTo(from))
				return true;

			var touchedPiece = board.get(from);  // TODO or preceding premove

			// touched my piece while other side to move?
			return touchedPiece && this.withPreMove && this.getCurPos().isBTM() == (Piece.side(touchedPiece) == Side.WHITE);
		}
		return false;
	};

	isLegalFromTo(from, to) {
		return this.getCurPos().isLegalFromTo(from, to);
	};

	preMovesLeadTo(sq) {
		if (this.withPreMove) {
			for (var m = 0; m < this.preMoves.length; m++)
				if (this.preMoves[m].to == sq)
					return true;
		}

		return false;
	};

	isPremovePromoPawn(sq, side) {
		if (sq == -1)
			return false;

		for (var m = this.preMoves.length - 1; m >= 0; m--) {
			if (this.preMoves[m].to == sq) {
				var p = this.getCurPos().board[this.preMoves[0].from];
				if ((p & Piece.PIECE_MASK) == Piece.PAWN && (Piece.side(p) == side))
					return Square.getRank(sq) == 6 || Square.getRank(sq) == 1;
			}
		}
		return false;
	};

	mayEnPassant(from, to) {
		if (this.isLegalFromTo(from, to)) {
			return this.getCurPos().getEPCol() == Square.getFile(to);
		}
		return false;
	};

	test() {
		//TS. Säuberung der Abhängigkeiten...
		//this.gameKernel.profileGenLegals();
	};

	getGame() {
		return this.board.getGame();
	};

	getCurPos() {
		return this.getGame().getCurPos();
	};

	getBoardWin() {
		return this.board;
	};

	getMySide() {
		//var mod = this.gameKernel.playingMode;
		//if ( !mod )
		//    return null;
		//var sd = mod.getMySide();
		//if ( sd === undefined )
		return this.fnToMove();
		//return sd;
	};

	//HACK!!!!
	setMySide(_sd) {
		if (ObjUtil.isFunction(_sd))
			this.fnToMove = _sd;
		else
			this.fnToMove = function () { return _sd };
	};

	getAllowedColor() {
		var sd = this.getMySide();
		// console.log("GETTING SIDE: " + sd);
		switch (sd) {
			case Side.WHITE:
				return AllowedColor.WHITE_ONLY;
			case Side.BLACK:
				return AllowedColor.BLACK_ONLY;
			default:
				return AllowedColor.BOTH_COLORS;
		}
	};

	mayEnterBackwards(board, sqOfPiece) {

		// only enter backwards, if it is my move, not in premove
		if (sqOfPiece >= 0 && sqOfPiece < 64) {
			var touchedPiece = board.get(sqOfPiece);
			return this.getCurPos().isBTM() == (Piece.side(touchedPiece) == Side.BLACK);
		}
	};

	static InitListeners() {
		if (!MoveEntry.prototype.fireEvent) {
			ListenersUtil.initForListeners(MoveEntry);
			ListenersUtil.addEvent(MoveEntry, "MoveEntered");
			ListenersUtil.addEvent(MoveEntry, "StopClock");
		}
	}

}



