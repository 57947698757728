// "use strict";

import { ExtRatingType12 } from "common/Chess/Format/ExtRatingType12"

export class EloNumber
{

	constructor ( bOld )
	{
		this.bOld = bOld;
	};

	readFromDataBuffer = function ( rBuf )
	{
		rBuf.beginSizedRead();
		this.nRatingId = rBuf.readUint32();
		this.nElo = rBuf.readUint16();
		this.aDate = rBuf.readCBDate();
		this.bFide = rBuf.readBool();
		this.aRating = new ExtRatingType12();
		this.aRating.readFromDataBuffer( rBuf );
		this.nRank = rBuf.readUint32();
		rBuf.endSizedRead();
	}

	getDate = function ()
	{
		return this.aDate;
	}

	getNElo = function ()
	{
		return this.aRating.getRating();
	}

}

