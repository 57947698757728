
import React, { useContext, Fragment } from 'react';
import styles from "./CBBoardMargin.module.css";

import { BoardThemeContext } from 'CBReact/Contexts/CBOnlineDBContext';
import { BOARD_THEMES } from 'CBReact/Hooks/ReactContext/useBoardThemeContext';

import { checkPropsAndRenderComponent } from 'CBReact/Utils/DebugUtils';

export function CBBoardMargin (props)
{

    const boardTheme = useContext(BoardThemeContext).boardTheme;
    const boardImagesCss = useContext(BoardThemeContext).boardImagesCss;

    function getMarginImageCss ()
    {
        switch (boardTheme)
        {
            case BOARD_THEMES.woodLight:
            case BOARD_THEMES.woodDark:
                return boardImagesCss.margin;
            default:
                return "";
        }
    }

    function renderCBBoardMargin ()
    {
        return (
            <Fragment>
                <div className={`${styles.container} ${styles.left} ${getMarginImageCss()}`}>
                    <svg xmlns='http://www.w3.org/2000/svg' version='1.1'
                        className={styles.marginContent}>
    
                        <text y='6.25%' x='50%' textAnchor="middle" dominantBaseline="central">{props.whiteIsBottom ? 8 : 1}</text>
                        <text y='18.75%' x='50%' textAnchor="middle" dominantBaseline="central">{props.whiteIsBottom ? 7 : 2}</text>
                        <text y='31.25%' x='50%' textAnchor="middle" dominantBaseline="central">{props.whiteIsBottom ? 6 : 3}</text>
                        <text y='43.75%' x='50%' textAnchor="middle" dominantBaseline="central">{props.whiteIsBottom ? 5 : 4}</text>
                        <text y='56.25%' x='50%' textAnchor="middle" dominantBaseline="central">{props.whiteIsBottom ? 4 : 5}</text>
                        <text y='68.75%' x='50%' textAnchor="middle" dominantBaseline="central">{props.whiteIsBottom ? 3 : 6}</text>
                        <text y='81.25%' x='50%' textAnchor="middle" dominantBaseline="central">{props.whiteIsBottom ? 2 : 7}</text>
                        <text y='93.75%' x='50%' textAnchor="middle" dominantBaseline="central">{props.whiteIsBottom ? 1 : 8}</text>
                    </svg>
                </div>
    
                <div className={`${styles.container} ${styles.bottom} ${getMarginImageCss()}`}>
                    <svg xmlns='http://www.w3.org/2000/svg' version='1.1'
                        className={styles.marginContent}>
    
                        <text x='6.25%' y='50%' textAnchor="middle" dominantBaseline="central">{props.whiteIsBottom ? "A" : "H"}</text>
                        <text x='18.75%' y='50%' textAnchor="middle" dominantBaseline="central">{props.whiteIsBottom ? "B" : "G"}</text>
                        <text x='31.25%' y='50%' textAnchor="middle" dominantBaseline="central">{props.whiteIsBottom ? "C" : "F"}</text>
                        <text x='43.75%' y='50%' textAnchor="middle" dominantBaseline="central">{props.whiteIsBottom ? "D" : "E"}</text>
                        <text x='56.25%' y='50%' textAnchor="middle" dominantBaseline="central">{props.whiteIsBottom ? "E" : "D"}</text>
                        <text x='68.75%' y='50%' textAnchor="middle" dominantBaseline="central">{props.whiteIsBottom ? "F" : "C"}</text>
                        <text x='81.25%' y='50%' textAnchor="middle" dominantBaseline="central">{props.whiteIsBottom ? "G" : "B"}</text>
                        <text x='93.75%' y='50%' textAnchor="middle" dominantBaseline="central">{props.whiteIsBottom ? "H" : "A"}</text>
                    </svg>
                </div>
    
                <div className={`${styles.container} ${styles.top} ${getMarginImageCss()}`}/>
                <div className={`${styles.container} ${styles.right} ${getMarginImageCss()}`}/>
                <div className={`${styles.container} ${styles.corner} ${getMarginImageCss()}`}/>
            </Fragment>
        );
    }


    return checkPropsAndRenderComponent([props.whiteIsBottom], renderCBBoardMargin);
}

// No props used currently
function boardMarginPropsAreEqual (prev, next)
{
    return prev.whiteIsBottom === next.whiteIsBottom;
}

export const CBMBoardMargin = React.memo(CBBoardMargin, boardMarginPropsAreEqual);