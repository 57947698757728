// "use strict";


import { PlayerKey } from "common/Chess/PlayerBase/PlayerKey"
import { HighPlayerData } from "common/Chess/PlayerBase/HighPlayerData"
import { LowPlayerData } from "common/Chess/PlayerBase/LowPlayerData"
import { Player } from "common/Chess/PlayerBase/Player"

export class NewCBPlayer
{

	constructor ()
	{
		this.m_aKey = new PlayerKey();
	};

	//NewCBPlayer.prototype.readFromDataBuffer = function ( pBuf )
	//{
	//	this.m_aKey = new CB.PlayerKey();
	//	if ( !this.m_aKey.readFromDataBuffer( pBuf ) )
	//		return false;
	//	this.m_HighData = new CB.HighPlayerData();
	//	this.m_HighData.readFromDataBuffer( pBuf );
	//	this.m_LowData = new CB.LowPlayerData();
	//	this.m_LowData.readFromDataBuffer( pBuf );

	//	return true;
	//};

	readFromDataBuffer ( pBuf, nDataMask )
	{
		this.m_aKey = new PlayerKey();

		// NH2020 removed prototype and CB: CB.Player.prototype.PL...
		if ( ( nDataMask & Player.PL.PL_DATA_KEY ) > 0 )
			if ( !this.m_aKey.readFromDataBuffer( pBuf ) )
				return false;

		this.m_HighData = new HighPlayerData();
		if ( ( nDataMask & Player.PL.PL_DATA_HIGH ) > 0 )
			this.m_HighData.readFromDataBuffer( pBuf );

		this.m_LowData = new LowPlayerData();
		if ( ( nDataMask & Player.PL.PL_DATA_LOW ) > 0 )
			this.m_LowData.read( pBuf );

		return true;
	};

	getLastName ()
	{
		return this.m_aKey.getLastName();
	};

	setLastName ( rS )
	{
		this.m_aKey.setLastName( rS );
	};

	getFirstName ()
	{
		return this.m_aKey.getFirstName();
	};

	setFirstName ( rS )
	{
		this.m_aKey.setFirstName( rS );
	};

	setKey ( rKey )
	{
		this.m_aKey = rKey;
	};

	getKey ()
	{
		return this.m_aKey;
	};

	getRHighPlayerData ()
	{
		return this.m_HighData;
	};

	getRLowPlayerData ()
	{
		return this.m_LowData;
	};

	getMaxTitles ()
	{
		return this.GetRLowPlayerData().getMaxTitles();
	};

	getMaxNations ()
	{
		return this.GetRLowPlayerData().getMaxNations();
	};

}

