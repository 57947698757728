
import React, { useState, useEffect, useContext } from 'react';
import styles from "./CBGameInformation.module.css";

import { GameResultEnum } from 'common/Chess/Logic/GameHeader';
import { checkPropsAndRenderComponent } from 'CBReact/Utils/DebugUtils';
import { PlayerLobbyContext } from 'CBReact/Contexts/CBOnlineDBContext';
import { LayoutContext } from "CBReact/Contexts/CBOnlineDBContext"
import { CBPlayerInformation } from 'CBReact/Components/PlayerInformation/CBPlayerInformation';

export function CBGameInformation (props)
{
    const [gameInfo, setGameInfo] = useState({});
    const [didMount, setDidMount] = useState(false);

    const requestPlayerInfo = useContext(PlayerLobbyContext).requestPlayerInfo;
    const playerInfoWhite = useContext(PlayerLobbyContext).playerInfoWhite;
    const playerInfoBlack = useContext(PlayerLobbyContext).playerInfoBlack;
    const playerLobbyIsConnected = useContext(PlayerLobbyContext).isConnected;
    const windowWidth = useContext(LayoutContext).windowWidth;

    useEffect(() => {
        if (Object.keys(playerInfoWhite).length === 0 || Object.keys(playerInfoBlack).length === 0)
            requestPlayerInfo(props.gameHeader);
    }, [playerLobbyIsConnected]);  

    // Update Game Info on Header Change
    useEffect(() => {

        let header =  props.gameHeader;
        let newGameInfo = {
            white: header.white.getListStr(9),
            whiteLink: "https://players.chessbase.com/en/player/" + header.white.last + "_" + header.white.first,
            eloWhite: header.eloWh || '',
            black: header.black.getListStr(9),
            blackLink: "https://players.chessbase.com/en/player/" + header.black.last + "_" + header.black.first,
            eloBlack: header.eloBl || '',
            result: GameResultEnum.toString(header.result),
            event: (header.event && header.event.event) || '',
        };
        
        setGameInfo(newGameInfo);

        if (didMount)
            requestPlayerInfo(header);

        setDidMount(true);

    },[props.gameHeader]);

    function renderCBGameInformation ()
    {
        return (
            <div id="gameInfo" className={`${styles.base} ${!gameInfo.white && !gameInfo.black && styles.inactive}`} >
                <CBPlayerInformation playerName={gameInfo.white} playerFirstName={gameInfo.whiteFirst} playerLink={gameInfo.whiteLink} playerColor="White" playerTitle={playerInfoWhite.title} playerElo={gameInfo.eloWhite} playerNation={playerInfoWhite.nation} playerImage={windowWidth > 340 ? playerInfoWhite.image : null}/>
                <div className={styles.centerInfo}>
                    <div className={styles.result}  >{gameInfo.result || "0 - 0"}</div>
                    <div className={styles.tournament}>{gameInfo.event || "Event"}</div>
                </div>
                <CBPlayerInformation alignRight={true} playerName={gameInfo.black} playerFirstName={gameInfo.blackFirst} playerLink={gameInfo.blackLink} playerColor="Black" playerTitle={playerInfoBlack.title} playerElo={gameInfo.eloBlack} playerNation={playerInfoBlack.nation} playerImage={windowWidth > 340 ? playerInfoBlack.image : null}/>
            </div>
        );
    }

    return checkPropsAndRenderComponent([props.gameHeader, playerLobbyIsConnected, playerInfoWhite, playerInfoBlack, requestPlayerInfo], renderCBGameInformation);
}