export const INDEX2BACKGROUNDTRANSLATE = {
    // Black Squares
    0: "0% 0%",
    2: "0% 100%",
    4: "0% 200%",
    6: "0% 300%",

    9: "100% 0%",
    11: "100% 100%",
    13: "100% 200%",
    15: "100% 300%",

    16: "200% 0%",
    18: "200% 100%",
    20: "200% 200%",
    22: "200% 300%",

    25: "300% 0%",
    27: "300% 100%",
    29: "300% 200%",
    31: "300% 300%",

    32: "50% 50%",
    34: "50% 100%",
    36: "50% 150%",
    38: "50% 200%",

    41: "150% 50%",
    43: "150% 100%",
    45: "150% 150%",
    47: "150% 200%",

    48: "250% 50%",
    50: "250% 100%",
    52: "250% 150%",
    54: "250% 200%",

    57: "0% 50%",
    59: "0% 100%",
    61: "0% 150%",
    63: "0% 200%",

    // White Squares
    1: "0% 0%",
    3: "0% 100%",
    5: "0% 200%",
    7: "0% 300%",

    8: "100% 0%",
    10: "100% 100%",
    12: "100% 200%",
    14: "100% 300%",

    17: "200% 0%",
    19: "200% 100%",
    21: "200% 200%",
    23: "200% 300%",

    24: "300% 0%",
    26: "300% 100%",
    28: "300% 200%",
    30: "300% 300%",

    33: "50% 50%",
    35: "50% 100%",
    37: "50% 150%",
    39: "50% 200%",

    40: "150% 50%",
    42: "150% 100%",
    44: "150% 150%",
    46: "150% 200%",

    49: "250% 50%",
    51: "250% 100%",
    53: "100% 100%",
    55: "250% 200%",

    56: "0% 50%",
    58: "100% 100%",
    60: "0% 150%",
    62: "0% 200%",
}