

import React, { /* useContext, */ useEffect, useState } from 'react';
import styles from "./CBMaterialBalance.module.css";

import { checkPropsAndRenderComponent } from "CBReact/Utils/DebugUtils"
import { ComparisonUtils } from 'CBReact/Utils/ComparisonUtils';
//import { BoardThemeContext } from 'CBReact/Contexts/CBOnlineDBContext';
// import { Piece } from 'common/Chess/Logic/Chess'

import whitePawn from "CBReact/Resources/Raster/MaterialImbalance/wp.png"
import whiteKnight from "CBReact/Resources/Raster/MaterialImbalance/wn.png"
import whiteBishop from "CBReact/Resources/Raster/MaterialImbalance/wb.png"
import whiteRook from "CBReact/Resources/Raster/MaterialImbalance/wr.png"
import whiteQueen from "CBReact/Resources/Raster/MaterialImbalance/wq.png"

import blackPawn from "CBReact/Resources/Raster/MaterialImbalance/bp.png"
import blackKnight from "CBReact/Resources/Raster/MaterialImbalance/bn.png"
import blackBishop from "CBReact/Resources/Raster/MaterialImbalance/bb.png"
import blackRook from "CBReact/Resources/Raster/MaterialImbalance/br.png"
import blackQueen from "CBReact/Resources/Raster/MaterialImbalance/bq.png"

function calculateBalance(board) {
    var pieceCounts = new Array(16).fill(0, 0, 16);
    for (let i = 0; i < 64; i++)
        pieceCounts[board[i]]++;

    var matBalance = [];
    for (let i = 0; i < 6; i++)
        matBalance[i] = pieceCounts[i + 1] - pieceCounts[i + 1 + 8];

    return matBalance;
}

export function CBMaterialBalance(props) {

    const [materialBalance, setMaterialBalance] = useState([0, 0, 0, 0, 0, 0]);
    const [hasMaterialImbalance, setHasMaterialImbalance] = useState(false);

    // const getPieceImageCss = useContext(BoardThemeContext).getPieceImageCss;

    // Update Material Balance whenever position changes
    useEffect(() => {
        var matBalance = calculateBalance(props.boardPosition.board);
        if (matBalance && hasImbalance(matBalance)) {
            setMaterialBalance(matBalance);
            setHasMaterialImbalance(true);
        }
        else
            setHasMaterialImbalance(false);

    }, [props.boardPosition]);

    function hasImbalance(matBalance) {
        for (var i = 0; i < matBalance.length; i++)
            if (matBalance[i] !== 0)
                return true;

        return false;
    }

    function getBalanceObject(pieceIndex, isWhite, index) {
        switch (pieceIndex) {
            case 1:
                return <img alt={isWhite ? "White Queen" : "Black Queen" } key={index} className={styles.balanceObject} src={isWhite ? whiteQueen : blackQueen} />;
            case 2:
                return <img alt={isWhite ? "White Knight" : "Black Knight" } key={index} className={styles.balanceObject} src={isWhite ? whiteKnight : blackKnight} />;
            case 3:
                return <img alt={isWhite ? "White Bishop" : "Black Bishop" } key={index} className={styles.balanceObject} src={isWhite ? whiteBishop : blackBishop} />;
            case 4:
                return <img alt={isWhite ? "White Rook" : "Black Rook" } key={index} className={styles.balanceObject} src={isWhite ? whiteRook : blackRook} />;
            case 5:
                return <img alt={isWhite ? "White Pawn" : "Black Pawn" } key={index} className={styles.balanceObject} src={isWhite ? whitePawn : blackPawn} />;
            default:
                return "";
        }
    }

    // function getBalanceObject(pieceIndex, isWhite, index) {
    //     switch (pieceIndex) {
    //         case 1:
    //             return <div key={index} className={`${styles.balanceObject} ${getPieceImageCss(isWhite ? Piece.W_QUEEN : Piece.B_QUEEN)}`} />;
    //         case 2:
    //             return <div key={index} className={`${styles.balanceObject} ${getPieceImageCss(isWhite ? Piece.W_KNIGHT : Piece.B_KNIGHT)}`} />;
    //         case 3:
    //             return <div key={index} className={`${styles.balanceObject} ${getPieceImageCss(isWhite ? Piece.W_BISHOP : Piece.B_BISHOP)}`} />;
    //         case 4:
    //             return <div key={index} className={`${styles.balanceObject} ${getPieceImageCss(isWhite ? Piece.W_ROOK : Piece.B_ROOK)}`} />;
    //         case 5:
    //             return <div key={index} className={`${styles.balanceObject} ${getPieceImageCss(isWhite ? Piece.W_PAWN : Piece.B_PAWN)}`} />;
    //     }
    // }

    function renderBalanceObject(imbalance, pieceIndex, isWhiteImbalance) {
        let imgArray = [];
        for (let i = 0; i < Math.abs(imbalance); i++)
        {
            if ((isWhiteImbalance && imbalance > 0) || (!isWhiteImbalance && imbalance < 0))
            {
                let img = getBalanceObject(pieceIndex, imbalance > 0, i);
                if (img)
                    imgArray.push(img);
            }
        }

        return imgArray;
    }

    function renderCBMaterialBalance() {
        if (!hasMaterialImbalance)
            return (
                <div className={styles.container}>
                    <div className={`${styles.balanceObject} ${styles.inactive}`}>Equal material</div>
                </div>
            );

        let whiteImbalance = [];
        let blackImbalance = [];

        for (let i = 0; i < materialBalance.length; i++)
        {
            if (materialBalance[i] > 0)
                whiteImbalance.push(renderBalanceObject(materialBalance[i], i, true));
            else if (materialBalance[i] < 0)
                blackImbalance.push(renderBalanceObject(materialBalance[i], i, false));
        }

        return (
            <div className={styles.container}>
                {whiteImbalance}{blackImbalance}
            </div>
        );
    }

    return checkPropsAndRenderComponent([props.boardPosition], renderCBMaterialBalance);
}

function materialBalancePropsAreEqual (prev, next)
{
    return ComparisonUtils.arraysAreEqual(calculateBalance(prev.boardPosition.board), calculateBalance(next.boardPosition.board));
}

export const CBMMaterialBalance = React.memo(CBMaterialBalance, materialBalancePropsAreEqual);