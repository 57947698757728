

import localforage from "localforage"

export class LocalPlayerBase
{
	static VERSION = 1;
	constructor ()
	{
		// NH2020 Added to constructor
		// this.instance = null;
		// end NH2020

	    this.instance = localforage.createInstance( {
	        name: "Player"
	    } );
	};

	createDatabase ()
	{
	};

	setPlayer ( storageObject, firstname, lastname )
	{
	    if ( !lastname )
	    {
	        var keydata = storageObject.player.player.m_NewCBPlayer.m_aKey;
	        var key = this.getLastNameKey( keydata.m_sLastName ) + "," + this.getFirstNameKey( keydata.m_sFirstName );
	    }
	    else
	    {
	        var key = this.getLastNameKey( lastname ) + "," + this.getFirstNameKey( firstname );
	    }

	    // YUI Compressor frisst den nicht...

	    //this.instance.setItem( key, storageObject ).then( function ( value )
	    //{
	    //}
	    //).catch(
	    //	function ( err )
	    //	{
	    //	console.log( err );
	    //	}
	    //);

	    this.instance.setItem( key, storageObject );
	};

	beginGetPlayer ( firstName, lastName, elo, year, callbackSuccess, callbackError )
	{
	    var fn = this.getFirstNameKey( firstName );
	    var realLastName = this.getLastNameKey( lastName );

	    // YUI problems....
	    var prom2 = this.instance.getItem( realLastName + "," + fn ).then( function ( data )
	    {
	        if ( !data )
	        {
	            callbackError( "Player not found" );
	        }
	        else
	            callbackSuccess( data );
	    } );

	    //prom2.catch( function ( err )
	    //{
	    //	callbackError( err );
	    //} );
	};

	getPlayer (firstName, lastName, elo, year)
	{
		var fn = this.getFirstNameKey(firstName);
		var ln = this.getLastNameKey(lastName);
		var key = `${ln},${fn}`;
		return this.instance.getItem(key);
	}

	getFirstNameKey ( str )
	{
	    if ( !str )
	        return "";
	    return str.split( /[\s-]/gi )[0];
	};

	getLastNameKey ( str )
	{
	    return str.replace( /[\s-]/gi, "" );
	};

}