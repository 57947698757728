// Connection, mw 21.2.2013
// ES6 5.3.2020

// import { glApp } from "common/App/App"
import { Log } from 'common/Tools/Log'
import {  Queue } from "common/Container/Queue";
import { Timer, Tick } from 'common/Tools/Timer'
import { YourIdData } from "./Protocol/YourId";

import { WebSockMessage, SockMsgId } from "common/WebClient/Protocol/WebSockMessage";
// import { ColArrow } from "common/Chess/Format/AnnoTypes";

export var ConnectId = {
	NONE: 0,
	UNKNOWN: 0,
	SERVER: 1
};

export var SockState = {
	CONNECTING: 0,
	CONNECTED: 1,
	CLOSING: 2,
	CLOSED: 3
};

export class Connection
{
	constructor( lobby, uris )
	{
		this.connectId = ConnectId.NONE;
		this.uris = [].concat( uris );	// make it an array, even if one string
		this.nUri = 0;

		// NH2021D
		// e.g. playchess main client uses other default bridge than live blitz:
		// if ( glApp && glApp.config.nBridge !== undefined && glApp.config.nBridge < this.uris.length )
		// {
		// 	this.nUri = glApp.config.nBridge;
		// }

		this.wsUri = this.uris[ this.nUri ];

		this.msgsWaitingToSend = new Queue();
		this.msgsWaitingToSendNoId = new Queue();
		this.onYourId = function( conn ) { };
		this.onClose = function( conn ) { };
		this.isFirstConnect = true;
		this.firstConnectTries = 0;

		this.onConnectionLoss = function() { };
		this.onServerDown = function() { };
		this.lobby = lobby;
		this.reconnectTries = 0;
		this.error = false;
		this.isActiveTicker = new Tick();
		this.weAreAliveTicker = new Tick();
		this.tryTimer = new Timer( this.periodicHandler.bind( this ) );	// only for errors
		this.tryTimerPeriod = 200; // ms

		this.msgCnt = 0;
	}

	//	var socket;	!?

	open()
	{
		try
		{
			if ( this.socket )
				this.socket.close();

			Log.Log("Start Open Web Socket Uri: " + this.wsUri);
			this.socket = new WebSocket( this.wsUri );
			var conn = this;
			this.error = false;

			// Log.Log( "Open: " + conn.wsUri, "", "conn" );
			//alert( conn.wsUri );
			//console.log( "open:" + conn.wsUri );

			// NOTE: "Closure": Beim eigentlichen Callback zeigt "this" auf Websocket, daher wird Connection auf diese Weise übergeben.

			this.socket.onopen =
				function()
				{
					while ( !conn.msgsWaitingToSendNoId.empty() )
					{
						if ( conn.socket.readyState === SockState.CONNECTED )
							conn.sendMessage( conn.msgsWaitingToSendNoId.pop(), true /*noId*/ );
						else
						{
							conn.msgsWaitingToSendNoId.pop();
							Log.Log( "YOU DID SOMETHING BAD, YOU ARE SENDING BUT HAVE NOT WAITED FOR CONNECTION TO COMPLETE", "LogException" );
						}
					}
					while ( !conn.msgsWaitingToSend.empty() )
					{
						if ( conn.socket.readyState === SockState.CONNECTED )
							conn.sendMessage( conn.msgsWaitingToSend.pop(), false /*noId*/ );
						else
						{
							conn.msgsWaitingToSend.pop();
							Log.Log( "YOU DID SOMETHING BAD, YOU ARE SENDING BUT HAVE NOT WAITED FOR CONNECTION TO COMPLETE", "LogException" );
						}
					}

					// console.log( "Connected:" + conn.wsUri );
					// console.log("Ready State " + conn.socket.readyState);
					this.tryTimerPeriod = 200;
					new Timer( function()
					{
						conn.lobby.onConnect();
					} ).runOnce( 20 );
				};

			this.socket.onclose =
				function( ev )
				{
					Log.Log("Close Connection: " + conn.wsUri + " " + ev.code);
					
					// var closeCodes =
					// {
					// 	1000: "Normal Close",
					// 	1002: "Protocol Error",
					// 	1006: "Abnormal Closure",
					// 	1015: "TLS Handshake",
					// }

					// Log.Log( "Closed: " + conn.wsUri + ", " + ev.code + ", " + closeCodes[ ev.code ], "", "conn" );
					
					conn.onClose( conn );
					//conn.tryTimer.stop();
					if ( conn.onBroken !== undefined )
						conn.onBroken();

					if ( ev.Code > 1000 )
					{
						// NH2021D
						// glApp.LogConnection( conn.lobby.getName() + "-Close=" + ev.Code );


						// console.log( "Close: " + closeCodes[ ev.Code ] );
					}

					// https://developer.mozilla.org/de/docs/Web/API/CloseEvent

					// |Status Code | Meaning         
					//-+------------+-----------------
					// | 1000       | Normal Closure  
					//-+------------+-----------------
					// | 1001       | Going Away      
					//-+------------+-----------------
					// | 1002       | Protocol error  
					//-+------------+-----------------
					// | 1003       | Unsupported Data
					//-+------------+-----------------
					// | 1004       | ---Reserved---- 
					//-+------------+-----------------
					// | 1005       | No Status Rcvd  
					//-+------------+-----------------
					// | 1006       | Abnormal Closure
					//-+------------+-----------------
					// | 1007       | Invalid frame   
					// |            | payload data    
					//-+------------+-----------------
					// | 1008       | Policy Violation
					//-+------------+-----------------
					// | 1009       | Message Too Big 
					//-+------------+-----------------
					// | 1010       | Mandatory Ext.  
					//-+------------+-----------------
					// | 1011       | Internal Server 
					// |            | Error           
					//-+------------+-----------------
					// | 1015       | TLS handshake   
					//-+------------+-----------------

				};

			this.socket.onerror =
				function( error )
				{
					Log.Log( "OnError  : " + conn.wsUri, "LogError", "conn" );
					console.log( "Error: " + conn.wsUri + " " + error );
					//if ( conn.wsUri )
					//	glApp.panelMgr.chatOut( "Not connected: " + conn.wsUri );

					if ( conn.isFirstConnect && conn.uris.length > 1 && ++conn.firstConnectTries < 4 )
					{
						conn.switchConnection();
						conn.open();
						//	glApp.panelMgr.chatOut( "Server: " + conn.wsUri );
					}
					else
					{
						conn.error = true;
						conn.onSocketError( conn );
					}
				};

			this.socket.onmessage =
				function( evt )
				{
					//	setTimeout( function ()
					//	{
					conn.tryTimer.stop();
					//  console.log("Message received");
					if ( evt.data instanceof ArrayBuffer )
					{
						//Log.Log("Receiving a buffer of size=" + evt.data.byteLength);
						// console.log("Rec Buffer Size: " + evt.data.byteLength);
						var msg = new WebSockMessage();
						try
						{
							// NH2020 Receiving a message here happens quite often.
							// console.log(evt.data);
							msg.fromReceiveBuf( evt.data );
							// console.log("Msg: " + msg.getType());
						}
						catch ( x )
						{
							Log.Exception( x.toString() );
						}

						conn.handleReceivedMessage( msg );

						conn.weAreAliveTicker.start();
					}
					else
					{
						Log.Log( evt.data, "", "conn" );
						// console.log( 'RESPONSE: ' + evt.data );
					}
					conn.reconnectTries = 0;

					//	}, 0 ); 
				};
		}
		catch ( x )
		{
			Log.LogException( "Conn", x );
		}
	}

	close()
	{
		if ( this.isConnected() || this.isConnecting() )
			this.socket.close();
		this.tryTimer.stop();
	}

	isConnected()
	{
		return this.socket && this.socket.readyState === SockState.CONNECTED;
	}

	isConnecting()
	{
		return this.socket && this.socket.readyState === SockState.CONNECTING;
	}

	onSocketError()
	{
		if ( this.isFirstConnect )
		{
			this.onServerDown();
			if ( this.lobby.periodicPingTimer )
				this.lobby.periodicPingTimer.stop();
			this.tryTimer.runPeriodic( 30000 );
		}
		else
		{
			this.onConnectionLoss();
			this.tryTimer.runPeriodic( this.tryTimerPeriod );	// fast - > periodicHandler will set new frequency.
		}
	};

	// This gets a WebSockMessage with a buffer that has the Message information read in
	handleReceivedMessage( sockMsg )
	{
		// Log.Log( "Msg: " + sockMsg.getType() );
		this.isFirstConnect = false;
		var bDone = true;
		switch ( sockMsg.getType() )
		{
			default:
				bDone = false;
				break;
			case SockMsgId.YOUR_ID:
				this.handleYourId( sockMsg );
				break;
			case SockMsgId.ALIVE:
				// alert( "Illegal alive, server should send PING frame" );
				bDone = false;
				break;
		}
		if ( !bDone )
		{
			this.lobby.handleReceived( sockMsg );
			// NH2020 Added this
			this.error = false;
		}
	}

	handleYourId( sockMsg )
	{
		var yourId = new YourIdData();
		yourId.fromSocketsMsg( sockMsg );
		this.connectId = yourId.nId;
		this.lobby.idReceived( yourId );
		this.onYourId( this );
	}

	// NH2020 added "noCheck"
	sendMessage( sockMsg, noId, noCheck )
	{
		if ( !this.socket )
			return;

		if ( !sockMsg.getType() )
		{
			throw ( Error( "Sending invalid sockMsg" ) );
		}

		if ( noId )
		{
			sockMsg.msgId = 0;
			if ( this.msgCnt > 0 )
			{
				//	Log.Log( "NOID + " + this.connectId );
				// "#IFDEBUG"
				// if ( sockMsg.type != 7002 )	// logon
				// 	alert( "SOCKMSG NOID" + " " + sockMsg );
				// "#ENDIF"
			}
		}
		else
		{
			sockMsg.msgId = ++this.msgCnt;
			//	Log.Log( sockMsg.msgId + " " + sockMsg.type + " " + this.connectId );
		}


		if ( sockMsg.getIdReceiver() === ConnectId.NONE )
			sockMsg.setIdReceiver( ConnectId.SERVER );
		sockMsg.setIdSender( this.connectId );
		if ( this.socket.readyState === SockState.CONNECTED )
		{
			this.socket.binaryType = "arraybuffer";
			var arrBufSend = sockMsg.fillSendArrBuf( !noId, !noCheck );  // JS ArrayBuffer, not DataBuffer!
			this.socket.send( arrBufSend );
		}
		// open called, but handshaking not finished:
		else if ( this.socket.readyState === SockState.CONNECTING )
		{
			if ( noId )
				this.msgsWaitingToSendNoId.push( sockMsg );
			else
				this.msgsWaitingToSend.push( sockMsg );
			Log.Log( "MSGs WAITING TO SEND=" + this.msgsWaitingToSend.getLength(), "LogRed" );
		}
		else
		{
			if ( this.socket.readyState !== SockState.CLOSING && this.socket.readyState !== SockState.CLOSED )
			{
				this.error = true;
				this.onSocketError();
			}
		}
	};


	// debugging only:
	sendText( message )
	{
		if ( this.socket && this.socket.readyState === 1 )
		{
			// console.log( "SENT: " + message );
			this.socket.send( message );
		}
		else
			Log.Log( "Connection not open" );
	}

	breakForTesting()
	{
		Log.Log( "Break Connection for Testing" );
		this.close();
		//	this.wsUri = "ws://127.12.12.3:443";	// disable reconnects for testing.
	};

	periodicHandler()
	{
		Log.Log( "Conn Periodic, retries=" + this.reconnectTries + ", " + this.lobby.connectId, "", "conn" );
		this.tryTimer.runPeriodic( 10000 );
		if ( this.socket && this.socket.readyState !== SockState.CONNECTED && this.error )
		{
			this.reconnectTries += 1;
			if ( this.reconnectTries >= 1 && ( this.isFirstConnect || this.reconnectTries > 6 ) )	// do not switch immediately
			{
				// try backup server:
				this.switchConnection();
			}
			if ( this.lobby.pingServer )	// can be torturer
				this.lobby.pingServer( true /*silent*/, this.reconnectTries === 1 ? 2000 : 6000, true );
			if ( this.reconnectTries > 5 )
			{
				this.tryTimer.runPeriodic( 50000 + Math.random() * 30000 );
			}
		}
	};

	switchConnection()
	{
		this.nUri++;
		if ( this.uris && this.uris.length )
		{
			if ( this.nUri >= this.uris.length )
				this.nUri = 0;

			Log.Log( "nUri: " + this.nUri, "", "conn" );
			this.wsUri = this.uris[ this.nUri ];
			Log.Log( "Switch Conn: " + this.wsUri, "", "conn" );
			// console.log( "Switch Conn: " + this.wsUri );

			if ( this.lobby.onSwitchConn )
				this.lobby.onSwitchConn( this.nUri );
		}
	};

}
