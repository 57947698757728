// ST 22.7.2014
//Hat nur die Methoden, die von außen aufgerufen werden.
// ES6 4.3.2020

import { Log } from "common/Tools/Log";

// import { glApp } from "common/App/App";
import { System } from 'common/Tools/System'
import { MoveEnterType } from "common/Chess/Logic/Game";
import { AllowedColor } from "common/Chess/Logic/MoveEntry";

export class AbstractPlayingMode 
{
	destroy()
	{

	};

	deleteClock()
	{

	};

	onTimeOverstepped( black )
	{

	};

	onAnnotate()
	{
		this.saved = false;
		return true;
	};

	onTextAnno( text, isPost )
	{
		this.saved = false;
		return true;
	};

	onInsertDiagram()
	{
		this.saved = false;
		return true;
	};

	static onEval = function( ev )
	{
		this.saved = false;
		return true;
	};

	onPromoteLine()
	{
		this.saved = false;
		return true;
	};

	onDeletePrevious()
	{
		this.saved = false;
		return true;
	};

	onCutLine()
	{
		this.saved = false;
		return true;
	};

	onDeleteLine()
	{
		this.saved = false;
		return true;
	};

	onBeforeUnload()
	{
		// user closes browser
	};

	getMoveEnterType()
	{
		return MoveEnterType.OVERWRITE_MAIN;
	};

	mayNavigate()
	{
		return true;
	};

	mayGotoNext()
	{
		return true;
	};

	mayGotoEnd()
	{
		return true;
	};

	mayPageDown()
	{
		return true;
	};

	mayLoadGames()
	{
		return true;
	};

	onKeyDown( evt )
	{
		return false;
	};

	isAnalysis()
	{
		return false;
	};

	setAnalysis( b )
	{
	};

	engineMoveNow()
	{
		return false;
	};

	onResetGame()
	{
	};

	beforeGameLoad()
	{
		this.saveAndReset();
		this.saved = false;
	};

	afterGameLoad()
	{

	};

	updateHeader()
	{

	};

	saveAndReset()
	{
		this.saveToCloud( true /*force*/ );
		this.cntUpdates = 0;
	};

	onExit()
	{
		this.saveToCloud();
	};

	onEntry()
	{

	};

	shouldAnimatePosChange()
	{
		// 2 + 4;
		// "#IFDEBUG"
		// Log.Log( "AnimPosChange set to true", "", "board" );
		//		return true;
		//"#ENDIF"
		return System.runsOnChessBaseDomain() || !System.isNonCrossDomainIE();
	};

	saveToCloud( force )
	{
		Log.Missing();
		// NH2021D
		// if ( glApp.cloudMgr && glApp.cloudMgr.isConnected()
		// 	&& !this.saved
		// 	&& this.shouldSave( force )		// hook
		// 	&& ( this.kernel.game.getMoves().length > 6 || !this.kernel.game.isNormalInit() ) )
		// {
		// 	glApp.cloudMgr.saveGameToClipboard( this.kernel.game );
		// 	this.saved = true;
		// 	return true;
		// }
		// return false;
	};

	shouldSave()
	{
		return true;
	};

	updateGamePos()
	{
	};

	onGameMove()
	{
	};

	onGameNavigate()
	{
	};

	onClickInNotation()
	{
	};

	//setAnalysis(!isAnalysis) ?
	toggleAnalysis()
	{
	};

	onPosInput( pos )
	{
	};

	onEnteredMove( mv )
	{
		return true;
	};

	onAfterMoveEntry( mv )
	{
	};

	onResult( res )
	{
	};

	updateClockFromGame()
	{
	};

	//setLastMoveTimeCentiSecs( centiSecs )
	//{
	//};

	startClock()
	{
	};

	stopClock()
	{

	};

	getAllowedColor()
	{
		return AllowedColor.BOTH_COLORS;
	};

	setLegals( legalMoves )
	{

	};

	getMySide()
	{
		return null;
	};

	setMySide( _sd )
	{

	};

	userClosesBoard()
	{
	};

	/*
	{
		noClock: bool,
		 fixedTime: Number,
		 clockParams: clockParams
	}
	*/

	setTimeControl( _time )
	{

	};

	setManualClock()
	{
	};

	setChessClock()
	{
	};

	resetGame( game )
	{
		game.reset();	// overload to reset to a certain position in newGame
	}

	newGame()
	{
	};

	onPanelsReady()
	{
	};

	createGUIElements( _boardWin )
	{
	};

	destroyGUIElements( _boardWin )
	{
	};

	saveState( _obj )
	{
	};

	loadState( _obj )
	{
	};

	posLegalChecker( pos )
	{
		return pos.isLegal();
	};

	reactToEnteredMove( mv )
	{
		return false; // return true if handled
	};

	// Include these 2 functions in the base class of all PlayingModes as they are called when user clicks "Load White/Black/Restore Game" in a Live book display
	blockLiveBookUpdate( blockMode )
	{
	};

	getFnLoadSearchResult()
	{
		//   if ( CloudPlayingModeMixin )
		// 	   return CloudPlayingModeMixin.getFnLoadSearchResult();

		return null;
	};

}
