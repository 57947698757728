

import React from 'react';
import styles from "./CBModuleHeadline.module.css";

import { checkPropsAndRenderComponent } from 'CBReact/Utils/DebugUtils';

export function CBModuleHeadline (props)
{
    function renderCBModuleHeadline ()
    {
        return (
            <div className={`${styles.outer}`} style={{flexGrow: props.flexGrow}}>
                <div className={styles.inner}>
                    {props.children}
                </div>
            </div>
        )
    }

    return checkPropsAndRenderComponent([props.flexGrow], renderCBModuleHeadline);

}   