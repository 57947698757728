

import React, { useContext, useState, useEffect, useRef } from 'react';
import styles from "./CBNotation.module.css";

import { NotationGenerator } from "common/Chess/Logic/Notation";
import { LayoutContext, LocalizationContext } from 'CBReact/Contexts/CBOnlineDBContext';
import { checkPropsAndRenderComponent } from "CBReact/Utils/DebugUtils"
import { DimensionUtils } from 'CBReact/Utils/DimensionUtils';
import { GameResultEnum } from 'common/Chess/Logic/GameHeader';
import { CBMMove } from './CBMove';

export function CBNotation (props)
{
    const loc = useContext(LocalizationContext).localization;
    const windowWidth = useContext(LayoutContext).windowWidth;
    const appLayout = useContext(LayoutContext).appLayout;

    const [notationGenerator, setNotationGenerator] = useState(new NotationGenerator());
    const notationRef = useRef();
    const [heightStyles, setHeightStyles] = useState();

    // Change notationGenerator on new Localization
    useEffect(() => {
        if (loc.strings.NOTA_LOCALIZATION)
            setNotationGenerator(new NotationGenerator(loc.strings.NOTA_LOCALIZATION));
    },[loc]);

    useEffect(() => {
        setHeightStyles(getHeightStyles());
    }, [appLayout, windowWidth]);

    function isSelected (lineStackPath, checkSecondarySelection, isWhite) 
    {
        if (props.notationPath.length !== lineStackPath.length)
            return false;

        var shift = 0;
        for (var i = 0; i < props.notationPath.length; i++) {
            if (checkSecondarySelection && i === props.notationPath.length - 1)
                isWhite ? shift++ : shift--;

            if (props.notationPath[i].inxLine !== lineStackPath[i].inxLine || props.notationPath[i].mvsToGo - shift !== lineStackPath[i].mvsToGo)
                return false;
        }

        return true;
    }

    function cloneLineStack(lineStackPath) 
    {
        var lineStackPathNew = [];
        for (let i = 0; i < lineStackPath.length; i++)
            lineStackPathNew.push({ inxLine: lineStackPath[i].inxLine, mvsToGo: lineStackPath[i].mvsToGo });

        return lineStackPathNew;
    }

    function renderLine(line, lineIndex, lineStackPath, moveIndexGame) 
    {
        // Remove Line Inset
        return (
            <div className={`${styles.line} ${!line.isMainLine() && styles.insetLine}`} key={"l" + lineIndex}>
                {line.map(function (move, moveIndexLine) {
                    return (
                        [
                            renderMove(move, (moveIndexGame + moveIndexLine) % 2 === 0, moveIndexLine, (moveIndexGame + moveIndexLine), lineStackPath),
                            move.hasLines() &&
                            move.getSubLines().map(
                                function (line, lineIndex) {
                                    var lineStackPathNew = cloneLineStack(lineStackPath);
                                    lineStackPathNew[lineStackPathNew.length - 1].inxLine = lineIndex;
                                    lineStackPathNew[lineStackPathNew.length - 1].mvsToGo = moveIndexLine;
                                    lineStackPathNew.push({ inxLine: 0, mvsToGo: 0 });

                                    return renderLine(line, lineIndex, lineStackPathNew, (moveIndexGame + moveIndexLine));
                                })
                        ]
                    );
                })}
            </div>
        )
    }

    function renderMove(move, isWhite, moveIndexLine, moveIndexGame, lineStackPath) 
    {
        // Für ... : Braucht einen index
        var notation = notationGenerator.getMoveNota(move, isWhite || moveIndexLine === 0 ? Math.floor(moveIndexGame * 0.5 + 1) : 0);

        // Create Line Stack Path Reference. Is there a way that does not include cloning the array every time?
        var lineStackPathNew = cloneLineStack(lineStackPath);
        lineStackPathNew[lineStackPathNew.length - 1].mvsToGo = moveIndexLine + 1;

        return (
            <CBMMove key={"m" + moveIndexGame} notation={notation} changeGamePosition={() => props.changeGamePosition(lineStackPathNew)} isWhite={isWhite} isSelectedPrimary={isSelected(lineStackPathNew)} isSelectedSecondary={isSelected(lineStackPathNew, true, isWhite)} />
        );
    }

    function getHeightStyles ()
    {
        if (notationRef.current && props.fillRemainingHeight)
        {
            let bottomSpace = props.fillRemainingHeight || 0;
            let newHeight = DimensionUtils.getRemainingHeight(notationRef.current) - bottomSpace;
            newHeight = newHeight > 73 ? newHeight : 73;
            return {maxHeight: newHeight, minHeight: newHeight};
        }
        else if (props.maxHeight)
            return {maxHeight: props.maxHeight};
        else
            return;
    } 

    function renderCBNotation ()
    {
        return (
            <div id="notation" ref={notationRef} className={`${styles.base}`} style={heightStyles}>
                {props.mainLine.length === 0 ? 
                <div className={styles.inactive}>{loc.strings.NOTATION}</div> :
                renderLine(props.mainLine, 0, [{ inxLine: 0, mvsToGo: 0 }], 0)
                }
                {props.mainLine.length !== 0 && 
                <div className={`${styles.result}`}>
                    {GameResultEnum.toString(props.gameHeader.getResult())}
                </div>
                }
            </div>
        );
    }

    return checkPropsAndRenderComponent([props.mainLine, props.notationPath, props.changeGamePosition, props.gameHeader], renderCBNotation);

}