import { useEffect, useRef, useState } from "react";

export var ONLINEDB_LAYOUTS =  {
    oneColumn: 0,
    twoColumns: 1,
    threeColumns: 3
}

export function useOnlineDBLayoutContext ()
{
    const [appLayout, setAppLayout] = useState(getOnlineDbLayout(window.innerWidth));
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const timeOutFunction = useRef();

    useEffect(() => 
    {
        window.addEventListener('resize', handleResizeEvent);

        return () => {
            window.removeEventListener('resize', handleResizeEvent);
        };
    }, []);

    function handleResizeEvent ()
    {
        if (timeOutFunction.current)
            clearTimeout(timeOutFunction.current);
        
        timeOutFunction.current = setTimeout(doResize, 150);
    }

    function getOnlineDbLayout(containerWidth)
    {
        if (containerWidth < 770) // Smartphones, Portrait Tablets, etc
            return ONLINEDB_LAYOUTS.oneColumn;
        else if (containerWidth < 1250) // WideScreen tablets, small laptops
            return ONLINEDB_LAYOUTS.twoColumns;
        else
            return ONLINEDB_LAYOUTS.threeColumns; // Big Laptops, Desktops
    }

    function doResize ()
    {
        setWindowWidth(window.innerWidth);
        setAppLayout(getOnlineDbLayout(window.innerWidth));
    }

    return {appLayout, windowWidth};
}