
import React, { useState, useContext, useRef } from 'react';
import styles from "./CBAccount.module.css";

import { SideNavContext, LocalizationContext, LoginContext } from "CBReact/Contexts/CBOnlineDBContext"
import { CookieUtils } from 'CBReact/Utils/CookieUtils';
import { AccountUtils, LOGIN_MODES } from "CBReact/Components/Account/AccountUtils";

export function CBLogIn (props)
{
    const changeSideNav = useContext(SideNavContext).changeSideNav;
    const changeLogInStatus = useContext(LoginContext).changeLogInStatus;
    const loc = useContext(LocalizationContext).localization;

    const [username, setUserName] = useState("");
	const [password, setPassword] = useState("");
    const [loginHasError, setLoginHasError] = useState(false);

    const userNameRef = useRef();
	const passwordRef = useRef();

    function handleUserNameChange (e)
    {
        setUserName(e.target.value);
    }

    function handlePasswordChange (e)
    {
        setPassword(e.target.value);
    }

    function handleSubmit (e)
    {
		e.preventDefault();

		if (username === "" || password === "")
			return;

        if (userNameRef.current)
            userNameRef.current.blur();
        if (passwordRef.current)
			passwordRef.current.blur();
		
		AccountUtils.PasswordLogin(username, password, handleReceiveLogInToken);
	}

	function handleReceiveLogInToken (json)
    {
        // For original Version: See AccountMixins.receiveTokenHandler
        // TODO nochmal in die ursprüngliche Version reinschauen und sehen, wie die Exceptions gehandlet werden
        if ( json && json.Token )
		{
			var token = unescape( json.Token );
			var userName = null;
			if (json.userId && json.ResultType === 1)
				userName = json.userId;

			changeLogInStatus(LOGIN_MODES.free, userName, token);
			setLoginHasError(false);
			changeSideNav(true);

			// Save Username and Token as classic Cookies -> Cross Browser.
			if (userName)
            {
				CookieUtils.SetCodedCookie("AccountName64", userName);
                //CookieUtils.SetCodedStorageItem("AccountName64", userName);
            }

			if (token)
            {
                CookieUtils.SetCookie("AccountToken", token);
                //CookieUtils.SetStorageItem("AccountToken", token);
            }
				
			// Start Token Login
			AccountUtils.TokenLogin(userName, token, handleTokenLogin);
		}
        else
        {
			setUserName("");
			setPassword("");
			setLoginHasError(true);
			AccountUtils.HandleLoginFailed();
        }
    }

    function handleTokenLogin (json)
    {
		if (json && json.ResultType === 1)
			changeLogInStatus(AccountUtils.GetLoginStatusFromSubscription(json.Subscription));
		else
			AccountUtils.HandleLoginFailed();
	}

    return (
        <div className={styles.container}>
            <div className={`${styles.label} ${loginHasError && styles.error}`}>Enter Username</div>
            <input type="text" 
                    value={username} 
                    className={styles.input} 
                    placeholder={"Username..."} 
                    ref={userNameRef}
                    onChange={handleUserNameChange} />

            <div className={`${styles.label} ${loginHasError && styles.error}`}>Enter Password</div>
            <form onSubmit={handleSubmit}>
                <input type="password" value={password} 
                        className={styles.input} 
                        placeholder={"Password..."} 
                        ref={passwordRef}
                        onChange={handlePasswordChange}  />
            </form>

            <div onClick={handleSubmit} className={styles.button}>{loc.strings.LOGIN}</div>

            <div className={styles.label}>No account yet?</div>
            <a href="https://live.chessbase.com/createAccount?returnurl=http%3A%2F%2Fdatabase.chessbase.com%2F" target="_blank" rel="noopener noreferrer" className={`${styles.button} ${styles.register}`}>
                Register
            </a>

        </div>
    );
}