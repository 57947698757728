

import React from 'react';
import baseStyles from "../CBModules.module.css";
import styles from "./CBBoardModule.module.css";

import { CBChessBoardAndNavigationBar } from 'CBReact/Components/Chess/ChessBoardAndNavigationBar/CBChessBoardAndNavigationBar';
import { CBHorizontalSpacer } from "CBReact/Components/DesignElements/HorizontalSpacer/CBHorizontalSpacer"
import { CBNotation } from "CBReact/Components/Chess/Notation/CBNotation"
import { CBNotationBar } from 'CBReact/Components/Chess/NotationBar/CBNotationBar';
import { checkPropsAndRenderComponent } from "CBReact/Utils/DebugUtils"

export function CBBoardModule(props) {

    function renderCBBoardModule ()
    {
        return (
            <div id="board" className={`${baseStyles.base} ${styles.boardModule}`} style={props.style}>
                <CBChessBoardAndNavigationBar gameKernel={props.gameKernel} boardPosition={props.boardPosition}/>
                {/* <CBChessBoard gameKernel={props.gameKernel} boardPosition={props.boardPosition}/>
                <CBBoardNavigationBar gameKernel={props.gameKernel}/> */}
                <div className={baseStyles.paddingSection}>
                    <CBNotation mainLine={props.mainLine} gameHeader={props.gameHeader} notationPath={props.notationPath} changeGamePosition={props.changeGamePosition} fillRemainingHeight={props.fillRemainingHeight}/>
                    <CBHorizontalSpacer />
                    <CBNotationBar gameKernel={props.gameKernel} boardPosition={props.boardPosition} />
                    {/* <CBMaterialBalance boardPosition={props.boardPosition} /> */}
                </div>
            </div>
        );
    }

    return checkPropsAndRenderComponent([props.gameKernel, props.gameHeader, props.mainLine, props.notationPath, props.changeGamePosition, props.boardPosition], renderCBBoardModule);
}

// Notation Tools, possible implementation

//import { CBExpandCollaps } from "CBReact/Components/ExpandCollaps/CBExpandCollaps"
// import { CBButtonPill } from "CBReact/Components/DesignElements/Buttons/ButtonPill/CBButtonPill"
// import { MainContext, LocalizationContext } from 'CBReact/Contexts/CBOnlineDBContext';

//const loc = useContext(LocalizationContext).localization;

/* <CBExpandCollaps replace={false}>
    <div>Notationswerkzeuge</div>
    <div>
        <CBButtonPill><span>+</span><span>Like Game</span></CBButtonPill>
        <CBButtonPill><span>//</span><span>Share Game</span></CBButtonPill>
        <CBButtonPill><span>D</span><span>Download PGN</span></CBButtonPill>
        <CBButtonPill><span>+text</span><span>{loc.strings.TEXT_AFTER_TIP}</span></CBButtonPill>
        <CBButtonPill><span>-</span><span>{loc.strings.DELETE_LINE_TIP}</span></CBButtonPill>
        <CBButtonPill><span>--</span><span>{loc.strings.CUT_LINE_TIP}</span></CBButtonPill>
        <CBButtonPill><span>M</span><span>Mark as my Move</span></CBButtonPill>
        <CBButtonPill><span>!!!</span><span>Mark as important move</span></CBButtonPill>
        <CBButtonPill><span>UUU</span><span>Unmark current move</span></CBButtonPill>
        <CBButtonPill><span>+=</span><span>White has a slight advantage</span></CBButtonPill>
        <CBButtonPill><span>==</span><span>The position is equal</span></CBButtonPill>
        <CBButtonPill><span>??</span><span>The position is unclear</span></CBButtonPill>
        <CBButtonPill><span>=+</span><span>Black has a slight advantage</span></CBButtonPill>
        <CBButtonPill><span>+-</span><span>White is better</span></CBButtonPill>
        <CBButtonPill><span>-+</span><span>Black is better</span></CBButtonPill>
        <CBButtonPill><span>+-</span><span>White is winning</span></CBButtonPill>
        <CBButtonPill><span>-+</span><span>Black is winning</span></CBButtonPill>
        <CBButtonPill><span>?=</span><span>Material compensation</span></CBButtonPill>
    </div>
</CBExpandCollaps> */