import React, { useContext, useEffect } from 'react';
import styles from "./CBSquare.module.css";

import { checkPropsAndRenderComponent } from 'CBReact/Utils/DebugUtils';
import { BoardThemeContext } from 'CBReact/Contexts/CBOnlineDBContext';
import { BOARD_THEMES } from 'CBReact/Hooks/ReactContext/useBoardThemeContext';
import { INDEX2BACKGROUNDTRANSLATE } from 'CBReact/Components/Chess/ChessBoard/ChessBoardUtils'
import { Fragment } from 'react';
import { Square } from 'common/Chess/Logic/Chess'

export function CBSquare (props)
{

    const boardTheme = useContext(BoardThemeContext).boardTheme;
    const boardImagesCss = useContext(BoardThemeContext).boardImagesCss;

    useEffect(() => {
        isBottomSquareInfo();
    }, [props.isHighlighted])

    function isBottomSquareInfo ()
    {
        if (Square.R(props.squareIndex) === 7 && props.whiteIsBottom)
            return true;
        else if (Square.R(props.squareIndex) === 0 && !props.whiteIsBottom)
            return true;
        else
            return false;
    }

    function getBackgroundTranslate ()
    {
        switch (boardTheme)
        {
            case BOARD_THEMES.woodLight:
            case BOARD_THEMES.woodDark:
                return {backgroundPosition: INDEX2BACKGROUNDTRANSLATE[props.squareIndex]};
            default:
                return "";
        }
    }

    function getBoardImageCss ()
    {
        switch (boardTheme)
        {
            case BOARD_THEMES.woodLight:
            case BOARD_THEMES.woodDark:
                return props.isWhiteField ? boardImagesCss.squareWhite : boardImagesCss.squareBlack;
            default:
                return "";
        }
    }

    function renderCBSquare ()
    {
        return (
            <Fragment>
                <div squareindex={props.squareIndex}
                    className={`${styles.container} ${props.isWhiteField ? styles.whiteField : styles.blackField} ${getBoardImageCss()}`} 
                    style={Object.assign({gridArea: props.squareName}, getBackgroundTranslate())}>
                    { 
                    props.isHighlighted && 
                        <div className={styles.highlight}/>
                    }
                </div>
                {
                    props.isHighlighted && props.showSquareInfo &&
                        <div 
                            className={`${styles.squareInfo} ${isBottomSquareInfo() ? styles.squareInfoBottom : styles.squareInfoTop} `} 
                            style={{ gridArea: props.squareName }}>
                                {props.squareName}
                        </div>
                }

            </Fragment>
        )
    }

    return checkPropsAndRenderComponent([props.squareIndex, props.isWhiteField, props.isHighlighted, props.showSquareInfo, props.whiteIsBottom], renderCBSquare);
}

function squarePropsAreEqual (prev, next)
{
    return prev.isHighlighted === next.isHighlighted;
}

export const CBMSquare = React.memo(CBSquare, squarePropsAreEqual);