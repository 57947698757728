// jb 3.7.2013

// "use strict";

import { Log } from "common/Tools/Log";
import { Move } from "../Logic/Move";
import { Position } from "../Logic/Position";
import { NotationGenerator } from "../Logic/Notation";
import { Side } from "../Logic/Chess";
import { CBDate } from "common/Tools/CBDate";
import { MoveCoder } from "common/Chess/OnlineDB/MoveCoder"
import { NumBoard } from "common/Chess/OnlineDB/NumBoard"
import { lm } from "common/Resources/Localization/Localization";

export class OnlineStatEntry
{
	// NH2020 changed to one constructor
	constructor (aMove)
	{
		if (aMove === undefined)
		{
			this.clear();

			// NH2020 not sure if this.init should be here
			Log.WillItWork();
			//this.init();
		}
		else
		{
			this.moves = [];

			this.strMoves = "";
			this.strGames = "";
			this.strScore = "";
			this.iLastYear = 0;
			if ( aMove != null )
			{
				this.strMoveNr = aMove.nota;
	
				this.aMove = new Move( aMove.from, aMove.to, aMove.prom );
			}
	
			// NH2020 not sure if this.init should be here
			Log.WillItWork();
			this.init();
		}
	};

	// NH2020 Added this function
	init ()
	{
		// NH2020 added = null
		this.iLastYear = null;

		this.nWhiteEloSum = 0;
		this.nBlackEloSum = 0;
		this.nWhiteEloCount = 0;
		this.nBlackEloCount = 0;
	
		this.nLosses = 0;
		this.nDraws = 0;
		this.nWins = 0;
		this.nGames = 0;
		this.nDate = 0;
		this.score = 0;
		this.strMoveNr = "?";
	
		this.moves = [];
		this.aMove = new Move();
	}



	clear ()
	{

		this.nWhiteEloSum = this.nBlackEloSum = 0;
		this.nWhiteEloCount = this.nBlackEloCount = 0;
		this.nLosses = this.nDraws = this.nWins = 0;
		//nLines = 0;

		this.nGames = this.nDate = 0;

		this.moves = [];
	};

	getMove ( nMove )
	{
		if ( nMove < this.moves.length )
			return this.moves[nMove];
		return new Move();
	};

	getNMoves ()
	{
		return this.moves.length;
	};

	getNWhiteEloAv ()
	{
		if ( this.nWhiteEloCount > 0 )
			return Math.round( this.nWhiteEloSum / this.nWhiteEloCount );
		else
			return 0;
	};

	getNBlackEloAv ()
	{
		if ( this.nBlackEloCount > 0 )
			return Math.round( this.nBlackEloSum / this.nBlackEloCount );
		else
			return 0;
	};

	getNEloAv ( side )
	{
		if ( side == Side.WHITE )
			return this.getNWhiteEloAv();
		else
		{
			return this.getNBlackEloAv();
		}
	};

	readFromDataBuffer ( rDB, rPos )
	{

		var bRet = true;

		rDB.beginSizedRead();

		this.nGames = rDB.readUint32();

		this.strGames = this.nGames.toString();
		this.nDate = rDB.readUint32();

		// NH2020 Assuming CBDate
		var dateLastPlayed = new CBDate( this.nDate );
		this.iLastYear = dateLastPlayed.year >> 9;
		this.nLosses = rDB.readUint32();

		this.nDraws = rDB.readUint32();
		this.nWins = rDB.readUint32();
		/*nLines = */rDB.readUint32();

		this.nWhiteEloCount = rDB.readUint32();
		this.nWhiteEloSum = rDB.readInt64();
		this.nBlackEloCount = rDB.readUint32();
		this.nBlackEloSum = rDB.readInt64();

		var nMoves = rDB.readUint8();
		//	TRACE( "NMoves: %d\n", nMoves );
		if ( nMoves > 0 )
		{
			var aNumBoard = new NumBoard();
			var aBoard = rPos.board;
			aNumBoard.init( aBoard, aNumBoard.FORMAT_1 );

			var aNota = new NotationGenerator( lm.NOTA_LOCALIZATION );////MOVE  ME TO BOOK WINDOW!!!

			var aMoveCoder = new MoveCoder();
			for ( var n = 0; n < nMoves; ++n )
			{
				var cMove = aMoveCoder.decode( rDB.readUint8(), n );
				if ( cMove > 0 && ( cMove < aNumBoard.FULL_MOVE_INFO ) )
				{
					var pPos = new Position();
					rPos.copyTo( pPos );

					var aMove = new Move();
					aNumBoard.decode( aMove, cMove, pPos.getBTM() );

					if ( pPos.isLegalMove( aMove ) )
					{
						pPos.preCalcCtx( aMove );
						pPos.makeMove( aMove );
						pPos.postCalcCtx( aMove, true );

						//aMove.CalcMoveContext(pPos);
						//pPos.execute(new CB.Square(aMove.From), new CB.Square(aMove.To), aMove.Prom);
						var nota = aNota.getMoveNota( aMove );
						aMove.nota = nota;
						//this.strMoveNr = nota;
						this.moves.push( aMove );
					}

				}
			}
		}

		rDB.endSizedRead();

		return bRet;
	};

}



