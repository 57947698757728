// ST 2011

// ES 6 MW: Feb 27, 2020

import { ObjUtil } from 'common/Patterns/ObjectUtil'

export function NOT(_func)
{
    return function ()
    {
        return !_func.apply(this, arguments);
    };
};

export function TRUE()
{
    return function ()
    {
        return true;
    }
};

export function FALSE()
{
    return function ()
    {
        return false;
    }
};

export function EqualsFilter(_cmp_elem)
{
    return function (_elem, _inx, _arr)
    {
        return ObjUtil.equals(_elem, _cmp_elem);
    }
}


