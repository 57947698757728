

import { PGNWriter } from "common/Chess/Format/PGN/PGNWriter";
import { PGN } from "common/Chess/Format/PGN/PGNParser"
import { strUtil } from "common/Tools/Tools"
import { ObjUtil } from "common/Patterns/ObjectUtil";

export class CookieUtils
{

	//#region Utility Conversion Methods

    static CodeAccountName ( name )
	{
		return strUtil.base64.encode( strUtil.htmlEncode( name ) );
	}

	static GameToPgn (game, unannotate)
	{
		var strPGN = PGNWriter.toPGN( game );
		var html = encodeURI( strPGN );
		if ( html.length > 3900 || unannotate )
		{
			var g2 = ObjUtil.clone( game );
			g2.unAnnotate();
			strPGN = PGNWriter.toPGN( g2 );
		}

		html = encodeURI( strPGN );

		return [html, strPGN];
	}

	static ParseGame (strPGN, moveIndex, gameID)
	{
		if ( strPGN && strPGN != "" )
		{
			var game = PGN.parseGame( strPGN );

			if (moveIndex)
				game.gotoIndex( moveIndex )

			if (gameID)
				game.onlineDBID = gameID;

			return game;
		}

		return null;
	}

	//#endregion

	//#region Local Storage Methods

	static SetStorageItem (sName, sValue)
	{
		try
		{
			if (localStorage)
				localStorage.setItem(sName, sValue);
		}
		catch (error)
		{
			console.log(error);
		}
	}

	static GetStorageItem (sName)
	{
		try
		{
			if (localStorage)
				return localStorage.getItem(sName);
		}
		catch (error)
		{
			console.log(error);
		}

		return null;
	}

	static SetCodedStorageItem (sName, sValue)
	{
		CookieUtils.SetStorageItem( sName, CookieUtils.CodeAccountName( sValue ) );
	}

	static GetCodedStorageItem (name, doHtmlDecode)
    {
        var value = CookieUtils.GetStorageItem(name);

		if (value)
		{
			value = strUtil.base64.decode(value);
			if (doHtmlDecode)
			{
				value = strUtil.htmlDecode(value);
				value = value.replace("%25", "%");
				value = value.replace("%20", " ");
			}
		
			return value;
		}
		else
			return null;
    };

	static ClearStorageItem (sName)
	{
		if (localStorage)
			localStorage.removeItem(sName);
	}

	static SaveGameToStorage (game, unannotate)
	{
		var [html, strPGN] = CookieUtils.GameToPgn(game, unannotate);

		CookieUtils.SetStorageItem("CBLastGame", strPGN);
		CookieUtils.SetStorageItem("CBLastMoveIndex", game.getMoveIndex());
		if (game.onlineDBID)
			CookieUtils.SetStorageItem("CBLastDBID", game.onlineDBID);
	}

	static LoadGameFromStorage ()
	{
		var strPGN = null;
		var moveIndex = null;
		var gameID = null;

		strPGN = CookieUtils.GetStorageItem("CBLastGame");
		moveIndex = parseInt(CookieUtils.GetStorageItem("CBLastMoveIndex"));
		gameID = parseInt(CookieUtils.GetStorageItem("CBLastDBID"));

		return CookieUtils.ParseGame(strPGN, moveIndex, gameID);
	}

	//#endregion

	//#region Oldschool Cookies
    
	// NH2021 Original Method: Cookies.js
    static SetCookie ( sName, sValue )
	{
		try
		{
			var oDate = new Date();
			var strExpires;
			if ( sValue )
			{
				oDate.setYear( oDate.getFullYear() + 1 );
				strExpires = oDate.toUTCString();
			}
			else
				strExpires = 'Thu, 01 Jan 1970 00:00:01 GMT';
	
			var domain = ".chessbase.com";
	
			if ( document.URL.search( /localhost/i ) != -1 )
				domain = "localhost";

			var sCookie = sName + '=' + encodeURIComponent( sValue ) + ';expires=' + strExpires + ';path=/;'
			
			if ( domain.length )
				sCookie += 'domain=' + domain + ';';
	
			sCookie += 'SameSite=Lax;';
			
			document.cookie = sCookie;
		}
		catch (error)
		{
			console.log(error);
		}
	}

    static GetCookie (sName)
    {
		try 
		{
			var oCrumbles = document.cookie.split( ';' );

			for ( var i = 0; i < oCrumbles.length; i++ )
			{
				var oPair = oCrumbles[i].split( '=' );
				var sKey = oPair[0].trim();
				var sValue = oPair.length > 1 ? oPair[1] : '';

				if ( sKey == sName )
					return decodeURIComponent( sValue );
			}

			return null;
		}
		catch (error)
		{
			console.log(error);
			return null;
		}

	}

	static SetCodedCookie ( name, value )
	{
		CookieUtils.SetCookie( name, CookieUtils.CodeAccountName( value ) );
    };

	static GetCodedCookie ( name, doHtmlDecode )
    {
        var value = CookieUtils.GetCookie( name );

		if (value)
		{
			value = strUtil.base64.decode( value );
			if ( doHtmlDecode )
			{
				value = strUtil.htmlDecode( value );
				value = value.replace( "%25", "%" );
				value = value.replace( "%20", " " );
			}
		
			return value;
		}
		else
			return null;

    };

	static ClearCookie( sName )
    {
        CookieUtils.SetCookie( sName, '' );
    }

	static SaveGameAsCookie( game, unannotate )
    {
		var [html, strPGN] = CookieUtils.GameToPgn(game, unannotate);

		if ( html.length < 3900) // cookie limit
		{
			CookieUtils.SetCookie("CBLastGame", strPGN);
			CookieUtils.SetCookie("CBLastMoveIndex", game.getMoveIndex());

			// Currently: Always Save DBID in localstorage
			if (game.onlineDBID && localStorage)
				CookieUtils.SetStorageItem("CBLastDBID", game.onlineDBID);
		}
	};
	
	static LoadGameFromCookie ()
    {
		var strPGN = null;
		var moveIndex = null;
		var gameID = null;

		strPGN = CookieUtils.GetCookie("CBLastGame");
		moveIndex = parseInt(CookieUtils.GetCookie("CBLastMoveIndex"));

		// Currently: Always Load DBID from localstorage
		gameID = parseInt(CookieUtils.GetStorageItem("CBLastDBID"));

		return CookieUtils.ParseGame(strPGN, moveIndex, gameID);
    };

	//#endregion

}