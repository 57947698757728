import {Log} from 'common/Tools/Log'

export var ListenersUtil = {};

// NH2020 initiiert die Instanz eines Objekts so, dass sie die Listener Funktionen verwenden kann.
// Fügt im Grunde einfach die Funktionen der Instanz hinzu.
ListenersUtil.initInstForListeners = function ( _inst )
{
	_inst.addListener = ListenersUtil.addListener;
	_inst.removeListener = ListenersUtil.removeListener;
	_inst.fireEvent = ListenersUtil.fireEvent;
	_inst.getListeners = ListenersUtil.getListeners;
	_inst.enableEvents = ListenersUtil.enableEvents;
	_inst.eventsEnabled = true;
	_inst.idLstnr = 0;
};

ListenersUtil.initForListeners = function ( _type )
{
	ListenersUtil.initInstForListeners( _type.prototype );
};

ListenersUtil.enableEvents = function ( b )
{
	this.eventsEnabled = b;
};


ListenersUtil.getListeners = function ( _evtname )
{
	var dctListenersName = "m_lstOn" + _evtname;
	var dctListeners = this[dctListenersName];
	if ( !dctListeners )
	{
		dctListeners = {};
		this[dctListenersName] = dctListeners;
	}

	return dctListeners;
};

// NH2020 Hier wird einer Instanz ein Listener hinzugefügt.
// Wenn die Klasse "App" ein Event "fireOnChange" hat, kann über
// App.addOnFireOnChangeListener( chessapp )
// der App ein Listener hinzugefügt werden, dessen funktion
// mit dem Namen OnChange (???) dann aufgerufen wird, wenn 
// App.fireOnChange aufgerufen wird.
ListenersUtil.addListener = function ( _evtname, _listener )
{
	var dctListeners = this.getListeners( _evtname );
	dctListeners[++this.idLstnr] = _listener;

	return this.idLstnr;
};

ListenersUtil.removeListener = function ( _evtname, _inxRemove )
{
	var dctListeners = this.getListeners( _evtname );
	delete dctListeners[_inxRemove];
};

ListenersUtil.fireEvent = function ( _evtname, _args )
{
	if ( !this.eventsEnabled )
		return;

	var dctListeners = this.getListeners( _evtname );

	for ( var inx in dctListeners )
	{
		if ( !dctListeners.hasOwnProperty( inx ) )
			continue;
		var f = dctListeners[inx];
		f.apply( this, _args );
	}
};

// NH2020 Fügt einer Instanz ein Event hinzu 
// Dazu gehört, dass einige Funktionen zur Instanz hinzugefügt werden
// addOn'Name'Listener, removeListener, fireOn'Name', etc.
ListenersUtil.addInstEvent = function ( _inst, _evtname, _funcArgs )
{
	var addOnName = String.formatEx( "addOn{0}Listener", _evtname );

	_inst[addOnName] = function ( _lstnr )
	{
		return this.addListener( _evtname, _lstnr );
	};

	_inst[String.formatEx( "removeOn{0}Listener", _evtname )] = function ( _inxRemove )
	{
		this.removeListener( _evtname, _inxRemove );
	};

	_inst[String.formatEx( "fireOn{0}", _evtname )] = function ()
	{
		if ( !this[String.formatEx( "fireOn{0}", _evtname )].delayed )
		{
			try
			{
				var args = arguments || [];
				if ( _funcArgs )
					args = _funcArgs.call( this );

				this.fireEvent( _evtname, args );
			}
			catch( x )
			{
				Log.Exception( "fire-" + _evtname, x );
			}
		}
		else
		{
			this[String.formatEx( "fireOn{0}", _evtname )].needFire = true;
		}
	};

	_inst.removeAllListeners = function ()
	{
		for ( var l in this )
		{
			if ( this.hasOwnProperty( l ) && l.search( "m_lstOn" ) == 0 )
			{
				delete this[l];
			}
		}
	};

	_inst[String.formatEx( "delayfireOn{0}", _evtname )] = function ( msDelay )
	{
		msDelay = msDelay || 100 //default delay, falls continue vergessen wird
		this[String.formatEx( "fireOn{0}", _evtname )].delayed = true;
		var me = this;
		window.setTimeout( function ()
		{
			me[String.formatEx( "continuefireOn{0}", _evtname )]();

		}, msDelay )
	};

	_inst[String.formatEx( "continuefireOn{0}", _evtname )] = function ()
	{
		this[String.formatEx( "fireOn{0}", _evtname )].delayed = false;
		if ( this[String.formatEx( "fireOn{0}", _evtname )].needFire )
		{
			try
			{
				this[String.formatEx( "fireOn{0}", _evtname )]();
			}
			catch( x )
			{
				Log.Exception( "delfire-" + _evtname, x );
			}
		}
		this[String.formatEx( "fireOn{0}", _evtname )].needFire = false;
	};
};

ListenersUtil.addEvent = function ( _type, _evtname, _funcArgs )
{
	ListenersUtil.addInstEvent( _type.prototype, _evtname, _funcArgs );
};