// jb 12.11.2013

// "use strict";

import { HashPosition } from "common/Chess/TeraBrain/HashPosition"

export class TaggedHashPos 
{

	constructor ( rPos, nMoveNo, rTag ) 
	{
		this.pos = new HashPosition( rPos );
		this.m_nMoveNo = nMoveNo;
		this.m_nConnectIdSubmissor = 0;
		this.m_nConnectIdSolver = 0;
		this.m_nTicket = 0;
		this.m_strTag = rTag;
	};


	IsSet () {
		return this.pos.IsSet();
	};

	IsStartPosition () {
		return this.pos.IsStartPosition();
	};

	IsFinalPosition () {
		return this.pos.IsFinalPosition();
	};

	GetStrTag () {
		return this.m_strTag;
	};

	SetStrTag ( rStr ) {
		this.m_strTag = rStr;
	};

	WriteToDataBuffer ( rDB ) {
		this.pos.WriteToDataBuffer( rDB );

		rDB.BeginSizedWrite();
		rDB.WriteInt( this.m_nMoveNo );
		rDB.WriteString( this.m_strTag );
		rDB.EndSizedWrite();
	};

	ReadFromDataBuffer ( rDB ) {
		if ( !this.pos.ReadFromDataBuffer( rDB ) )
			return false;

		rDB.BeginSizedRead();
		this.m_nMoveNo = rDB.ReadInt();
		this.m_strTag = rDB.ReadString();

		var bRet = this.m_nMoveNo >= 0;
		rDB.EndSizedRead();
		return bRet;
	};


	CreatePosition () {
		var pPos = this.pos.CreatePosition();
		pPos.SetMoveNo( this.m_nMoveNo );

		return pPos;
	};
}

