// ES 6 MW: Feb 27, 2020

// import Side from 'common/Chess/Logic/Chess'

import { Piece } from 'common/Chess/Logic/Chess'
import { Square } from 'common/Chess/Logic/Chess'
import { MoveLine } from './MoveLine';

export class Move
{
	constructor( _from, _to, _prom )
	{
		this.from = 0;
		this.to = 0;
		this.prom = Piece.NONE;

		if ( _from )
			this.from = _from;

		if ( _to )
			this.to = _to;

		if ( _prom )
			this.prom = _prom;

		this.isACheck = false;
		this.isAMate = false;

		this.needCol = false;
		this.needRow = false;

		this.mvd = Piece.NONE;
		this.vct = Piece.NONE;

		this.shortCastling = false;
		this.longCastling = false;

		this.subLines = null;
		this.annos = null;
	}

	static g_mvW00 = new Move( Square.E1, Square.G1 );
	static g_mvW000 = new Move( Square.E1, Square.C1 );

	static g_mvB00 = new Move( Square.E8, Square.G8 );
	static g_mvB000 = new Move( Square.E8, Square.C8 );

	get piece()
	{
		return this.mvd;
	}
	set piece( p )
	{
		this.mvd = p;
	}

	get capt()
	{
		return this.vct;
	}
	set capt( c )
	{
		this.vct = c;
	}

	isNullMove()
	{
		return this.from === this.to && !( this.from );
	};

	isCastling()
	{
		return this.isShortCastling()
			||
			this.isLongCastling();
	};

	equals( _mv )
	{
		return this.from === _mv.from &&
			this.to === _mv.to &&
			this.prom === _mv.prom;
	};

	isValid()
	{
		return this.from !== this.to;
	}

	_isShortCastling()
	{
		return ( this.equals( Move.g_mvW00 ) && this.mvd === Piece.W_KING )
			||
			( this.equals( Move.g_mvB00 ) && this.mvd === Piece.B_KING );
	};

	isShortCastling()
	{
		return this.shortCastling;
	};

	_isLongCastling()
	{
		return ( this.equals( Move.g_mvW000 ) && this.mvd === Piece.W_KING )
			||
			( this.equals( Move.g_mvB000 ) && this.mvd === Piece.B_KING );
	};

	isLongCastling()
	{
		return this.longCastling;
	};

	setIsCastling()
	{
		this.shortCastling = this._isShortCastling();
		this.longCastling = this._isLongCastling();
	};


	isCheck()
	{
		return this.isACheck;
	};

	setCheck( _val )
	{
		this.isACheck = _val;
	};

	isMate()
	{
		return this.isAMate;
	};

	setMate( _val )
	{
		this.isAMate = _val;
	};

	isTake()
	{
		return this.vct !== Piece.NONE;
	};


	getMoved()
	{
		return this.mvd;
	};

	setMoved( _val )
	{
		this.mvd = _val;
	}

	getVictim()
	{
		return this.vct;
	};

	setVictim( _val )
	{
		this.vct = _val;
	};

	getPiece()
	{
		return this.mvd;
	}

	isProm()
	{
		return this.prom !== Piece.NONE;
	};

	isPawn()
	{
		return this.mvd && ( this.mvd & 7 ) === Piece.PAWN;
	};

	getSide()
	{
		return Piece.side( this.mvd );
	}

	isNeedCol()
	{
		return this.needCol;
	};

	setNeedCol( _val )
	{
		this.needCol = _val;
	};

	isNeedRow()
	{
		return this.needRow;
	};

	setNeedRow( _val )
	{
		this.needRow = _val;
	};

	getTreeconstructorCount()
	{
		var cnt = 1;

		if ( this.subLines )
		{
			cnt = this.subLines.reduce( function( _cnt, _ln )
			{
				return _cnt + _ln.getTreeconstructorCount();
			}, cnt );
		}

		return cnt;
	};

	indexOfLine( _line )
	{
		if ( this.subLines )
			return this.subLines.indexOf( _line );
		return -1;
	};

	findLine( _mv )
	{
		if ( this.subLines )
		{
			for ( var inx = 0; inx < this.subLines.length; ++inx )
			{
				var line = this.subLines[ inx ];
				if ( line[ 0 ].equals( _mv ) )
					return line;
			}
		}
		return null;
	};

	reconstructorLine( _line )
	{
		if ( this.subLines )
		{
			var inx = this.subLines.indexOf( _line );
			if ( inx < 0 )
				return;
			this.subLines.splice( inx, 1 );
		}
	};

	reconstructorLines()
	{
		this.subLines = null;
	};

	getLine( _inx )
	{
		return this.subLines[ _inx ];
	};

	getSubLines()
	{
		return this.subLines;
	};

	getSubLinesCount()
	{
		if ( !this.subLines )
			return 0;
		return this.subLines.length;
	};

	hasLines()
	{
		return this.subLines != null && this.subLines.length > 0;
	};

	// TODO!
	// newLine  ( _parent, _inxMv )
	// {
	// 	if ( !this.subLines )
	// 		this.subLines = [];
	// 	var lineNew = new CB.mvLine( _parent, _inxMv );
	// 	this.subLines.push( lineNew );

	// 	return lineNew;
	// };

	// NH2020
	newLine ( _parent, _inxMv )
	{
		if ( !this.subLines )
			this.subLines = [];
		var lineNew = new MoveLine( _parent, _inxMv );
		this.subLines.push( lineNew );

		return lineNew;
	};

	addLine( _line )
	{
		if ( !this.subLines )
			this.subLines = [];
		this.subLines.push( _line );
	}

	insertLine( _inx, _line )
	{
		if ( !this.subLines )
			this.subLines = [];
		this.subLines.splice( _inx, 0, _line );
	}

	swapLines( _inx1, _inx2 )
	{
		if ( this.subLines )
		{
			var tmp = this.subLines[ _inx1 ];
			this.subLines[ _inx1 ] = this.subLines[ _inx2 ];
			this.subLines[ _inx2 ] = tmp;
		}
	}

	write( _buf )
	{
		if ( this.prom )
		{
			var from = this.from | 0x40;
			var to = this.to | ( ( this.prom - Piece.QUEEN ) << 6 );
			_buf.writeByte( from );
			_buf.writeByte( to );
		}
		else
		{
			_buf.writeByte( this.from );
			_buf.writeByte( this.to );
		}

		//Anno
		this.writeAnnos( _buf );
		//SubLines
		this.writeSubLines( _buf );
	};

	// added prom, mw 9.4.2015
	write2( _buf )
	{
		if ( this.prom )
		{
			var from = this.from | 0x40;
			var to = this.to | ( ( this.prom - Piece.QUEEN ) << 6 );
			_buf.writeByte( from );
			_buf.writeByte( to );
		}
		else
		{
			_buf.writeByte( this.from );
			_buf.writeByte( this.to );
		}

		var nFlags = 0;
		if ( this.annos && this.annos.count() )
			nFlags |= 1;
		if ( this.getSubLinesCount() )
			nFlags |= 2;

		_buf.writeUint8( nFlags );

		//Anno   
		if ( nFlags & 1 )
		{
			this.writeAnnos2( _buf );
		}
		//SubLines
		if ( nFlags & 2 )
		{
			this.writeSubLines2( _buf );
		}
	};

	read( _buf )
	{

		this.from = _buf.readByte();
		this.to = _buf.readByte();

		if ( ( this.from & 0xC0 ) == 0x40 )
			this.prom = Piece.QUEEN + ( this.to >> 6 );

		this.from &= 0x3F;
		this.to &= 0x3F;

		//Anno
		this.readAnnos( _buf );
		//SubLines
		this.readSubLines( _buf );
	};

	read2( _buf )
	{

		this.from = _buf.readByte();
		this.to = _buf.readByte();

		if ( ( this.from & 0xC0 ) == 0x40 )
			this.prom = Piece.QUEEN + ( this.to >> 6 );

		//if ( ( this.fr & 0xc0 ) || ( this.to & 0xc0 ) )	// ????
		//	return false;

		this.from &= 0x3F;
		this.to &= 0x3F;

		var nFlags = _buf.readByte();

		if ( nFlags & 1 )
		{

			//Anno
			this.readAnnos2( _buf );
		}

		if ( nFlags & 2 )
		{
			//SubLines
			this.readSubLines2( _buf );
		}
	};


	// 	readAnnos  ( _buf )
	// 	{
	// 		/// <param name="_buf" type="DataBuffer">BUFFER.</param>
	// 	//	this.annos = CB.Annotation.readFactory( _buf );
	// 	};

	// 	readAnnos2  ( _buf )
	// 	{
	// 		/// <param name="_buf" type="DataBuffer">BUFFER.</param>
	// 	//	this.annos = CB.Annotation.readFactory2( _buf );
	// 	};

	// 	writeAnnos  ( _buf )
	// 	{
	// 	//	CB.Annotation.write( _buf, this.annos );
	// 	};

	// 	writeAnnos2  ( _buf )
	// 	{
	// 	//	CB.Annotation.write2( _buf, this.annos );
	// 	};

	// 	readSubLines  ( _buf )
	// 	{
	// 		/// <param name="_buf" type="DataBuffer">BUFFER.</param>
	// 		// var cntLines = _buf.readByte();
	// 		// if ( cntLines <= 127 )
	// 		// {
	// 		// 	this.subLines = new Array( cntLines );
	// 		// 	for ( var inx = 0; inx < cntLines; ++inx )
	// 		// 	{
	// 		// 		this.subLines[inx] = CB.constructorLine.readFactory( _buf );
	// 		// 	}
	// 		// }
	// 	};

	// 	readSubLines2  ( _buf )
	// 	{
	// 		/// <param name="_buf" type="DataBuffer">BUFFER.</param>
	// 		// var cntLines = _buf.readByte();
	// 		// if ( cntLines <= 127 )
	// 		// {
	// 		// 	this.subLines = new Array( cntLines );
	// 		// 	for ( var inx = 0; inx < cntLines; ++inx )
	// 		// 	{
	// 		// 		this.subLines[inx] = CB.constructorLine.readFactory2( _buf );
	// 		// 	}
	// 		// }
	// 	};

	// 	writeSubLines  ( _buf )
	// 	{
	// 		var cntLines = this.getSubLinesCount();
	// 		_buf.writeByte( cntLines );
	// 		if ( cntLines )
	// 		{
	// 			for ( var inx = 0; inx < cntLines; ++inx )
	// 				this.subLines[inx].write( _buf );
	// 		}
	// 	};

	// 	writeSubLines2  ( _buf )
	// 	{
	// 		var cntLines = this.getSubLinesCount();
	// 		_buf.writeByte( cntLines );
	// 		if ( cntLines )
	// 		{
	// 			for ( var inx = 0; inx < cntLines; ++inx )
	// 				this.subLines[inx].write2( _buf );
	// 		}
	// 	};

	// 	// static readFactory = createReadFactory( Mv );
	// 	// static readFactory2 = createReadFactory2( Mv );

	// 	constructorLinesTo  ( _mvDst )
	// 	{
	// 		_mvDst.subLines = this.subLines;
	// 		this.subLines = null;
	// 	};

	toString()
	{
		var str = Square.toString( this.from ) + Square.toString( this.to );
		if ( this.prom !== Piece.NONE )
			str += Piece.toString( this.prom ).toLowerCase();
		return str;
	};

	static g_regex = /^[a-h][1-8][a-h][1-8]([NBRQ])?$/i;

	static isValidString( _strMv )
	{
		return this.g_regex.test( _strMv );
	};

	static fromString( _strMv )
	{

		var strFrom = _strMv.substring( 0, 2 );
		var strTo = _strMv.substring( 2, 4 );

		var from = Square.fromString( strFrom );
		var to = Square.fromString( strTo );

		var prom = undefined;

		if ( _strMv.length > 4 )
		{
			var strProm = _strMv.charAt( 4 );
			prom = Piece.nominal( Piece.fromString( strProm ) );
		}

		return new Move( from, to, prom );
	};

	toJSON()
	{
		return {
			from: Square.toString( this.from ),
			to: Square.toString( this.to ),
			prom: [ "", "", "Q", "N", "B", "R", "", "" ][ this.prom % 8 ]
		};
	};

	fromJSON( json )
	{
		this.from = Square.fromString( json.from );
		this.to = Square.fromString( json.to );
		var prom = " KQNBR".search( RegExp( json.prom, "i" ) );
		if ( prom > 0 )
			this.prom = prom;
		else
			this.prom = 0;
		return {
			from: Square.toString( this.from ),
			to: Square.toString( this.to ),
			prom: [ "", "", "Q", "N", "B", "R", "", "" ][ this.prom % 8 ]
		};
	};

	setSubParentData( _lineParent, _inx, _rec )
	{
		if ( this.subLines )
		{
			for ( var inxLn = 0; inxLn < this.subLines.length; ++inxLn )
			{
				var line = this.subLines[ inxLn ];
				line.setParentconstructorIndex( _inx );
				line.setParentLine( _lineParent );

				if ( _rec )
				{
					line.setSubParentData( true );
				}
			}
		}
	};

	getAnno()
	{
		return this.annos;
	};

	hasAnno()
	{
		return this.annos != null;
	};

	setAnnoItem( _type, _val )
	{
		// if ( !this.annos )
		// 	this.annos = new CB.Annotation();

		// this.annos.setItem( _type, _val );
	};

	setAnno( anno )
	{
		this.annos = anno;
	};

	getAnnoItem( _type )
	{
		if ( !this.annos )
			return null;
		return this.annos.getItem( _type );
	};

	deleteAnnoItem( type )
	{
		if ( this.annos )
			this.annos.deleteItem( type );
	};

	resetAnno()
	{
		this.annos = null;
	};

	unAnnotate()
	{
		this.resetAnno();
		this.reconstructorLines();
	};

	getBaggage()
	{
		return this.baggage;
	};

	setBaggage( b )
	{
		this.baggage = b;
	}

	forAllconstructors( fn )
	{
		fn( this );
		if ( this.subLines )
		{
			for ( var i = 0; i < this.subLines.length; i++ )
				this.subLines[ i ].forAllconstructors( fn );
		}
	}
}

