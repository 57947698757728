// mw, 12.2.2013
// ES6 4.3.2020

import { Log } from 'common/Tools/Log'

// import { Timer } from 'common/Tools/Timer'

// import { DataBuffer } from 'common/Container/DataBuffer'

import { GameResultEnum } from "common/Chess/Logic/GameHeader";
import { Position } from "common/Chess/Logic/Position";
import { DrawChecker, DrawType } from "common/Chess/Logic/DrawChecker";
import { MoveEnterType, Game } from "./Game";
import { PGN } from "common/Chess/Format/PGN/PGNParser";

import { ResultFlags } from "common/Chess/PlayingModes/PlayingMode";

// NH2020 Eine Klasse, die einiges erstellt/beinhaltet, was zu einem Board/Spiel dazugehört.
// Hier wird zB das Game erstellt, sowie die Ausgangsposition.
// Es wird aber auch BoardArea und Board Control erstellt, wichtig für die Eingabe von Zügen
// Beinhaltet auch eine Referenz zum Playing Mode
// Hierüber werden können wohl auch Notations-Hinweise durch den Nutzer eingegeben werden.

export class GameKernelLight
{
   static  _uniqueId = 1;

   constructor( viewLink, game ) //viewLink ist üblicherweise PanelMgr. Ist so!
   {
      this.game = game || new Game();

      // Protect game attribute. To change the game, please use this.game.assign()
      
      Object.defineProperty( this, game, { writable: false } );

      this.setGameListeners();
      this.legalMoves = [];
      this.viewLink = viewLink;

      this.isPostAnnoEdit = true;

      this.uniqueId = GameKernelLight._uniqueId++;

      // NH2020 added to load onlineDB Games at correct position
      this.onlineDBLoadPos = new Position();
   };

   setPlayingMode( _mode )
   {
       this.modeState = this.modeState || {};

       if (this.playingMode) {
           this.playingMode.saveState(this.modeState);
       }

       this.playingMode = _mode;

       if (this.playingMode.getFnLoadSearchResult) {
           this.loadSearchResultGame = this.playingMode.getFnLoadSearchResult();
       }
   };

   setPGN( strPGN )
   {
      if ( strPGN )
      {
         this.game.assign( PGN.parseGame( strPGN ) );
         this.game.gotoFirst();
      }
   };

   initGame()
   {
      this.fnInitGame( this.game, this );
      this.playingMode.newGame( false );
   };

   newGame()
   {
      this.playingMode.resetGame( this.game );
      this.playingMode.newGame( true );
   };

   setGameListeners()
   {
      if ( this.game )
      {
         this.idNavListener = this.game.addOnNavigateListener( this._onGameNavigate.bind( this ) );
         this.idMoveListener = this.game.addOnMoveListener( this._onGameMove.bind( this ) );
         this.idChangeListener = this.game.addOnChangedListener( this._onGameChanged.bind( this ) );
         this.idHeaderListener = this.game.addOnHeaderChangedListener( this._onHeaderChanged.bind( this ) );
         this.idCurPosListener = this.game.addOnCurPosChangedListener( this._onGameCurPosChanged.bind( this ) );
         this.idResetGameListener = this.game.addOnResetGameListener( this._onResetGame.bind( this ) );
         this.idClickInNotationListener = this.game.addOnClickInNotationListener( this._onClickInNotation.bind( this ) );
      }
   };

   removeGameListeners()
   {
      this.game.removeOnNavigateListener( this.idNavListener );
      this.game.removeOnMoveListener( this.idMoveListener );
      this.game.removeOnChangedListener( this.idChangeListener );
      this.game.removeOnHeaderChangedListener( this.idHeaderListener );
      this.game.removeOnCurPosChangedListener( this.idCurPosListener );
      this.game.removeOnResetGameListener( this.idResetGameListener );
      this.game.removeOnClickInNotationListener( this.idClickOnNotationListener );
   };

   // NH2020 added to load OnlineDB Games at correct position
   // To be invoced when OnlineDB Games are received
   setOnlineDBLoadPos ()
   {
      if (this.game)
         this.onlineDBLoadPos = this.game.getCurPos();
   }

   // NH2020 added this function to set game from list without
   // questioning the server again for a single game
   setOnlineDBListGame ( newGame )
   {
      if (newGame.isNormalInit())
         newGame.gotoPos(this.onlineDBLoadPos, true /* dont fire */);
      else
         newGame.gotoFirst();

      this.game.assign( newGame );
   }

   getMySide()
   {
      if ( this.playingMode )
         return this.playingMode.getMySide();
   };

   setMySide( _sd )
   {
      if ( this.playingMode )
         this.playingMode.setMySide( _sd );
   };

   // Ein Move wurde über MouseUp oä. Eingegeben. Was tun...
    reactToEnteredMove(mv) 
    {
        try {
            // Check if Move is legal
            var pos = this.game.getCurPos();
            if (!pos.isLegalMove(mv))
                return;

            // Playing mode ReactToEnteredMove
            var bHandled = this.playingMode.reactToEnteredMove(mv);

            if (!bHandled) {
                if (this.playingMode.onEnteredMove(mv)) {
                    //Der Zug ist schon da. Was mehr?
                    if (this.game.gotoMove(mv))	// not good when playing, we want the engine to answer after reentereing the same move
                    {	// still its attributes need to be completed, because other handlers might depend on it:
                        return;
                    }

                    var mvType = this.playingMode.getMoveEnterType();
                    if (this.nextMoveEnterType) {
                        mvType = this.nextMoveEnterType;
                        this.nextMoveEnterType = null;
                    }

                    var fnMakeMoveContinue = function (_mv, _mvType) {
                        if (_mvType === MoveEnterType.CANCEL) {
                            //this._updateBoard();
                        } else {
                            this.game.makeMove(_mv, _mvType);
                            this.checkIfTechnicalGameEnd();
                        }

                    }.bind(this);

                    if (mvType === MoveEnterType.QUERY) {
                        Log.Missing();
                    }
                    else {
                        fnMakeMoveContinue(mv, mvType);
                    }
                }
                else {
                    // Hier wird zB RawBoardControl _onPositionChanged aufgerufen.
                    this.game.fireOnCurPosChanged();
                }

                // NH2020 This function does not seem to exist
                this.playingMode.onAfterMoveEntry(mv);
            }
        }
        catch (x) {
            Log.Exception("GK:ReactTo", x);
        }
    };

   setResult( res, flags )
    {
        this.game.setResult( res );
        this.playingMode.onResult( res, flags );
    };

   checkIfTechnicalGameEnd()
    {
        if (!this.isFinished()) {
            var drawObj = { drawType: 0 };
            var res = this.checkTechnicalResult(drawObj);
            // Log.Log("Result: " + res);
            if (res !== GameResultEnum.UNDEFINED) {
                if (!this.playingMode.suppressTechnicalResult)
                    this.setResult(res, ResultFlags.TECHNICAL);
                if (this.onTechnicalGameEnd)
                    this.onTechnicalGameEnd(res, drawObj);

                return true;
            }
        }
        return false;
    };

   isFinished()
   {
      return this.game.hdr.getCBResult().isFinished();
   };

   isBTM()
   {
      return this.game.getCurPos().isBTM();
   };

   checkTechnicalResult( resObj )
   {
      return GameKernelLight.staticCheckTechnicalResult( this.game, resObj );
   };

   static staticCheckTechnicalResult = function ( game, resObj )
   {
      var techEnd = new DrawChecker().checkGame( game );
      var res = GameResultEnum.UNDEFINED;

      // Log.Log("Tech End: " + techEnd);
      switch ( techEnd )
      {
         default:
            res = GameResultEnum.UNDEFINED;
            break;
         case DrawType.STALEMATE:
         case DrawType.MATERIAL:
            res = GameResultEnum.DRAW;
            break;
         case DrawType.MATE:
            if ( game.getCurPos().isBTM() )
               res = GameResultEnum.WHITE_WINS;
            else
               res = GameResultEnum.BLACK_WINS;
            break;
         case DrawType.THREE_TIMES:
         case DrawType.FIFTY_MOVES:
            res = GameResultEnum.DRAW;
            break;
      }
      if ( resObj )
         resObj.drawType = techEnd;

      return res;
   };

   _onGameNavigate()
   {
      this.playingMode.onGameNavigate();
   };

   _onGameMove()
   {
      this.playingMode.onGameMove();
   };

   _onGameCurPosChanged()
   {
      this.playingMode.updateGamePos();
      this._updateAnnoField();
   };

   _onGameChanged( config )
   {
      //	LOG( "onGameChanged" );

      this.playingMode.updateGamePos( config );
      this._updateAnnoField();
   };

   _onHeaderChanged( config )
   {
      this.playingMode.updateHeader();
   };

   _onResetGame()
   {
      this.playingMode.onResetGame();
   };

   _onClickInNotation()
   {
      this.playingMode.onClickInNotation();
   };

   //////////////////// Engine ////////////////////////

   setIdGame( idGame )
   {
      this.idGame = idGame;
   };

   getCurPos()
   {
      return this.game.getCurPos();
   };

   copyGameToClipboard()
   {
      var gameAsText = this.game.toString();
      this.toClipboard( gameAsText );
   };

   toClipboard( data )
   {
        if ( window.clipboardData && window.clipboardData.setData )
        {
            window.clipboardData.setData( "Text", data );
            //	alert( "The moves have been copied into the clip board" );
        }
   };

   pasteGameFromClipboard()
   {
      if ( window.clipboardData && window.clipboardData.getData )
      {
         var data = window.clipboardData.getData( "Text" );
         this.setPGN( data );
      }
   };

   pastGameHandler( data, context )
   {
      if ( data !== 0 )
      {
         this.setPGN( context );
         this.game.updateFromExtern();
      }
   };

   startLine()
   {
      this.game.gotoPrev();
      this.nextMoveEnterType = MoveEnterType.NEWLINE;
   };

}

