
import { Position } from "common/Chess/Logic/Position"
import { CastleRights } from "common/Chess/Logic/Chess"

export class PosTree
{

	constructor ()
	{
		// NH2020 Added these variables in the constructor with this.

		this.numBits = [1, 6, 6, 5, 5, 5, 3, 1, 1, 6, 6, 5, 5, 5, 3, 1];

		this.theBits = [0x00, 0x20, 0x22, 0x12, 0x14, 0x16, 0x06, 0x00, 0x00, 0x21, 0x23, 0x13, 0x15, 0x17, 0x07, 0x00];
	
		this.isUniqe = [0x00, 0xff, 0xff, 0xff, 0xff, 0xff, 0x06, 0x0e,
		0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff,
		0xff, 0xff, 0x03, 0x0b, 0x04, 0x0c, 0x05, 0x0d,
		0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff,
		0x01, 0x09, 0x02, 0x0a, 0xff, 0xff, 0xff, 0xff,
		0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff,
		0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff,
		0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff];
	
	
		this.HUFF_960 = 0x80;
		this.HUFF_CASTLE = 0x40;
		this.HUFF_EP = 0x20;
	};

	posToHuff3 = function ( pPos, pBuf, nBufSize )
	{
		var run, sum, bufLen;
		var index = 0;
		//BoardType& pPos = rPos.GetBoard().GetBoardData();

		pBuf[index] = 0;			//	init length
		index++;
		pBuf[index] = 0;			//	init length


		if ( pPos.getBTM() )
			pBuf[index] = 0x80;

		for ( run = 0, sum = 1, bufLen = 2; run < 64; run++ )
		{
			var piece = ( pPos.board[run] & 15 );
			sum += this.numBits[piece];
			if ( sum > 8 )
			{
				pBuf[index] |= ( this.theBits[piece] >> ( sum - 8 ) );
				if ( ++bufLen > nBufSize )
				{	//memset( pBuf, 0, nBufSize );
					return 1;
				}
				index++;
				pBuf[index] = 0;	//	init next
				sum -= 8;
			}
			pBuf[index] |= ( this.theBits[piece] << ( 8 - sum ) );
			if ( sum >= 8 )
			{
				sum = 0;
				if ( ++bufLen > nBufSize )
				{	//memset( pBuf, 0, nBufSize );
					return 1;
				}
				index++;
				pBuf[index] = 0;	//	init next
			}
		}

		var extraBits = 0, extraFlags = 0;

		if ( pPos.getCastleRights() > 0 )
		{
			extraFlags |= this.HUFF_CASTLE;
			extraBits += 4;
		}
		if ( pPos.getCPPEPFile() > 0 )
		{
			extraFlags |= this.HUFF_EP;
			extraBits += 3;
		}
		if ( extraBits > 0 )
		{
			if ( sum + extraBits > 8 )
			{
				if ( ++bufLen > nBufSize )
				{	//memset( pBuf, 0, nBufSize );
					return 1;
				}
				index++;
				pBuf[index] = 0;	//	init next
			}
			if ( pPos.getCastleRights() > 0 )
			{
				pBuf[index] |= ( pPos.getCastleRights() & 0x0f );
				if ( pPos.getCPPEPFile() > 0 )
					pBuf[index] |= ( ( ( pPos.getCPPEPFile() - 1 ) & 7 ) << 4 );
			}
			else
			{
				if ( pPos.getCPPEPFile() > 0 )
					pBuf[index] |= ( ( pPos.getCPPEPFile() - 1 ) & 7 );
			}
		} else
			if ( sum == 0 )
				--bufLen;

		pBuf[0] = ( bufLen | extraFlags );

		for ( var s = 0; s <= index; s++ )
			pBuf[s] = pBuf[s] & 0xff;

		/*#if 0
			 String strOut;
			 ERRCODE eC = ByteArrayToString( pBuf, bufLen, strOut );
			 ASSERT( eC == EC_OK );
			 TRACE( strOut + "\n");
		#endif*/
		return 0;
	};


	huff3ToPos = function ( pPos, pBuf, nBufSize )
	{
		var run, sq, maxBuf, extraBits;
		var index = 0;
		var testBit;
		pPos.ep = Position.NOEP;

		for ( sq = 0; sq < 64; sq++ )
			pPos.board[sq] = 0;

		extraBits = pBuf[index] & ( this.HUFF_960 | this.HUFF_CASTLE | this.HUFF_EP );
		maxBuf = pBuf[index] & 0x1f;

		if ( ( maxBuf > 8 ) && ( maxBuf < nBufSize ) )
		{
			if ( ( extraBits & this.HUFF_CASTLE ) > 0 )
			{
				pPos.setCastleRights(( pBuf[maxBuf - 1] & 0x0f ) );
				if ( ( extraBits & this.HUFF_EP ) > 0 )
					pPos.ep = ( ( ( ( pBuf[maxBuf - 1] >> 4 ) & 7 ) ) );	// convention: in JS ep runs from 0..7
				else
					pPos.ep = Position.NOEP;
			}
			else
			{
				pPos.setCastleRights( CastleRights.NONE );
				if ( ( extraBits & this.HUFF_EP ) > 0 )
					pPos.ep = ( ( ( pBuf[maxBuf - 1] & 7 ) ) );					// convention: in JS ep runs from 0..7
				else
					pPos.ep = Position.NOEP;
			}
			index++;
			if ( ( ( pBuf[index] ) & 0x80 ) > 0 )
				pPos.setBTM( true );
			else
				pPos.setBTM( false );


			for ( sq = 0, run = 1, testBit = 0x40; sq < 64 && run < maxBuf; sq++ )
			{
				var theCode = 0, sqSum = 0;
				do
				{
					theCode <<= 1;
					if ( ( pBuf[index] & testBit ) > 0 )
						theCode |= 1;
					sqSum++;
					testBit >>= 1;
					if ( testBit == 0 )
					{
						testBit = 0x80;
						index++; run++;
					}
				} while ( ( this.isUniqe[theCode] == 0xff ) && ( sqSum < 6 ) );
				if ( this.isUniqe[theCode] == 0xff )
					break;
				pPos.board[sq] = ( this.isUniqe[theCode] );
			}
			if ( sq < 64 )
			{
				pPos.initStartPos();
				return 1;
			}
		}
		else
		{
			pPos.initStartPos();
			return 1;
		}


		return 0;
	};
}
