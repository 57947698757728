// mw 23.2.2013
// ES6 28.2.2020

export class Color
{
	constructor ( r, g, b, a )
	{
		this.red = r;
		this.green = g;
		this.blue = b;
		if ( a !== undefined )
			this.alpha = a;
		else
		{
			this.alpha = 1.0;
		}
	};

	toString ()
	{
		return "rgba( " + Math.round( this.red ) + ", " + Math.round( this.green ) + ", " + Math.round( this.blue ) + ", " + this.alpha + " )";
	};

}

//////////////////////////////////////////////////////////

export class HSLColor
{
	constructor ( h, s, l, a )
	{
		this.hue = h;
		this.saturation = s;
		this.lightness = l;
		this.alpha = a || 1.0;
	};

	toString = function ()
	{
		if ( this.alpha < 1.0 )
			return "hsla( " + Math.round( this.hue ) + ", " + Math.round( this.saturation ) + "%, " + Math.round( this.lightness ) + "%, " + this.alpha + " )";
		else
			return "hsl( " + Math.round( this.hue ) + ", " + Math.round( this.saturation ) + "%, " + Math.round( this.lightness ) + "% )";
	};

	static RED     =   0;
	static YELLOW  =  60;
	static GREEN   = 120;
	static CYAN    = 180;
	static BLUE    = 240;
	static MAGENTA = 300;
}