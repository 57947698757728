
import { useLocalizationContext } from 'CBReact/Hooks/ReactContext/useLocalizationContext/useLocalizationContext';
import { useLoginContext } from 'CBReact/Hooks/ReactContext/useLoginContext';
import { useOnlineLobbyContext } from 'CBReact/Hooks/ReactContext/useOnlineLobbyContext';
import { useOnlineDBLayoutContext } from 'CBReact/Hooks/ReactContext/useOnlineDBLayoutContext';
import { usePlayerLobbyContext } from 'CBReact/Hooks/ReactContext/usePlayerLobbyContext';
import { useSideNavContext } from 'CBReact/Hooks/ReactContext/useSideNavContext';
import { useTeraBrainLobbyContext } from 'CBReact/Hooks/ReactContext/useTeraBrainLobbyContext';
import { useThemeContext } from 'CBReact/Hooks/ReactContext/useThemeContext';
import { useBoardThemeContext } from 'CBReact/Hooks/ReactContext/useBoardThemeContext';
import React, { useContext } from 'react'


//#region Independent Contexts - No external variables from other contexts needed

export const LocalizationContext = React.createContext();
export function CBLocalizationContext (props)
{
    // const [localization, localizationRef, changeLocalization] = useLocalizationContext();
    const localizationContext = useLocalizationContext();

    return (
        <LocalizationContext.Provider value={localizationContext}>
            {props.children}
        </LocalizationContext.Provider>
    );
}

export const LoginContext = React.createContext();
export function CBLoginContext (props)
{
    // const [loginStatus, changeLogInStatus, getCurrentLoginStatus, userName, loginToken] = useLoginContext();
    const loginContext = useLoginContext();

    return (
        // Not used: loginToken
        <LoginContext.Provider value={loginContext}>
            {props.children}
        </LoginContext.Provider>
    );
}

export const LayoutContext = React.createContext();
export function CBLayoutContext (props)
{
    // const [appLayout, windowWidth] = useOnlineDBLayoutContext();
    const layoutContext = useOnlineDBLayoutContext();

    return (
        <LayoutContext.Provider value={layoutContext}>
            {props.children}
        </LayoutContext.Provider>
    );
}

export const PlayerLobbyContext = React.createContext();
export function CBPlayerLobbyContext (props)
{
    // const [requestPlayerInfo, isConnected, playerInfoWhite, playerInfoBlack] = usePlayerLobbyContext();
    const playerLobbyContext = usePlayerLobbyContext();

    return (
        <PlayerLobbyContext.Provider value={playerLobbyContext}>
            {props.children}
        </PlayerLobbyContext.Provider>
    );
}

export const ThemeContext = React.createContext();
export function CBThemeContext (props)
{
    // const [theme, setTheme, pieceTheme, changePieceTheme, getPieceImage] = useThemeContext();
    const themeContext = useThemeContext();

    return (
        <ThemeContext.Provider value={themeContext}>
            {props.children}
        </ThemeContext.Provider>
    );
}

export const SideNavContext = React.createContext();
export function CBSideNavContext (props)
{
    // const [sideNavIsOpen, sideNavRenderMode, changeSideNav] = useSideNavContext();
    const sideNavContext = useSideNavContext();

    return (
        <SideNavContext.Provider value={sideNavContext}>
            {props.children}
        </SideNavContext.Provider>
    );
}

//#endregion

//#region Context that depend on variables from other contexts 

export const OnlineLobbyContext = React.createContext();
export function CBOnlineLobbyContext (props)
{
    const getCurrentLoginStatus = useContext(LoginContext).getCurrentLoginStatus;
    const localizationRef = useContext(LocalizationContext).localizationRef;

    // const [searchOnlineLobby, refreshOnlineDBList, requestAutoComplete, isConnected, onlineLobbyIsLoading, databaseTableContent, playerData] = useOnlineLobbyContext(getCurrentLoginStatus, localizationRef);
    const onlineLobbyContext = useOnlineLobbyContext(getCurrentLoginStatus, localizationRef);

    return (
        // Not used: online lobby is connected
        <OnlineLobbyContext.Provider value={onlineLobbyContext}>
            {props.children}
        </OnlineLobbyContext.Provider>
    );
}


export const TeraBrainLobbyContext = React.createContext();
export function CBTeraBrainLobbyContext (props)
{
    const localizationRef = useContext(LocalizationContext).localizationRef;
    // const [searchTeraBrainBook, searchPositionInfo, teraBrainLobbyIsConnected, bookTableContent, bookTableIsLoading, engineTableContent, engineTableIsLoading] = useTeraBrainLobbyContext(localizationRef);
    const teraBrainContext = useTeraBrainLobbyContext(localizationRef);

    return (
        <TeraBrainLobbyContext.Provider value={teraBrainContext}>
            {props.children}
        </TeraBrainLobbyContext.Provider>
    );
}

export const BoardThemeContext = React.createContext();
export function CBBoardThemeContext (props)
{
    const mainDesignTheme = useContext(ThemeContext).theme;
    const appLayout = useContext(LayoutContext).appLayout;
    const boardContext = useBoardThemeContext(appLayout, mainDesignTheme);


    return (
        <BoardThemeContext.Provider value={boardContext}>
            {props.children}
        </BoardThemeContext.Provider>
    );
}

//#endregion