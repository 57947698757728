// mw 22.3.2013
// ES6 4.3.2020

// import { glApp  } from "common/App/App";

export class Nation
{
	constructor( n )
	{
		this.n = n;
		if ( this.n === undefined )
			this.n = NAT.NONE;

		this.flag = {};
		// NH2020 switched the order of the following
		if ( Nation.flag.length === 0 )
		{
			Nation.initFlag();
		}
		if ( Nation.code.length === 0 )
		{
			Nation.initCode();
		}

	};

	static flag = [];

	static initFlag()
	{

		Nation.flag[ NAT.NONE ] = "United Nations";
		Nation.flag[ NAT.AFG ] = "Afghanistan";
		Nation.flag[ NAT.ALB ] = "Albania";
		Nation.flag[ NAT.ALG ] = "Algeria";
		Nation.flag[ NAT.ASU ] = "American samoa";
		Nation.flag[ NAT.AND ] = "Andorra";
		Nation.flag[ NAT.ANG ] = "Angola";
		Nation.flag[ NAT.AGG ] = "Anguilla";
		Nation.flag[ NAT.ANT ] = "Antigua and Barbuda";
		Nation.flag[ NAT.ARG ] = "Argentina";
		Nation.flag[ NAT.ARM ] = "Armenia";
		Nation.flag[ NAT.ARN ] = "Aruba";
		Nation.flag[ NAT.AUS ] = "Australia";
		Nation.flag[ NAT.AUT ] = "Austria";
		Nation.flag[ NAT.AZE ] = "Azerbaijan";
		Nation.flag[ NAT.BAH ] = "Bahamas";
		Nation.flag[ NAT.BHN ] = "Bahrain";
		Nation.flag[ NAT.BAN ] = "Bangladesh";
		Nation.flag[ NAT.BAR ] = "Barbados";
		Nation.flag[ NAT.BAS ] = "Basque Country";
		Nation.flag[ NAT.BLR ] = "Belarus";
		Nation.flag[ NAT.BEL ] = "Belgium";
		Nation.flag[ NAT.BLZ ] = "Belize";
		Nation.flag[ NAT.BNN ] = "Benin";
		Nation.flag[ NAT.BER ] = "Bermuda";
		Nation.flag[ NAT.BTN ] = "Bhutan";
		Nation.flag[ NAT.BOL ] = "Bolivia";
		Nation.flag[ NAT.BIH ] = "Bosnia and Herzegovina";
		Nation.flag[ NAT.BOT ] = "Botswana";
		Nation.flag[ NAT.BRA ] = "Brazil";
		Nation.flag[ NAT.IVB ] = "Virgin Islands British";
		Nation.flag[ NAT.ISV ] = "Virgin Islands US";
		Nation.flag[ NAT.BRN ] = "Brunei";
		Nation.flag[ NAT.BUL ] = "Bulgaria";
		Nation.flag[ NAT.BUR ] = "Burkina faso";
		Nation.flag[ NAT.BRI ] = "Burundi";
		Nation.flag[ NAT.CMB ] = "Cambodja";
		Nation.flag[ NAT.CAM ] = "Cameroon";
		Nation.flag[ NAT.CAN ] = "Canada";
		Nation.flag[ NAT.KAP ] = "Cape verde";
		Nation.flag[ NAT.CAY ] = "Cayman Islands";
		Nation.flag[ NAT.CAR ] = "Central African Republic";
		Nation.flag[ NAT.CAT ] = "Catalonia";
		Nation.flag[ NAT.CHD ] = "Chad";
		Nation.flag[ NAT.CIB ] = "Jersey";
		Nation.flag[ NAT.CHI ] = "Chile";
		Nation.flag[ NAT.CHN ] = "China";
		Nation.flag[ NAT.CIA ] = "Christmas";
		Nation.flag[ NAT.COA ] = "Cocos (Keeling)";
		Nation.flag[ NAT.COL ] = "Colombia";
		Nation.flag[ NAT.COM ] = "Comoros";
		Nation.flag[ NAT.CON ] = "Congo-Brazzaville";
		Nation.flag[ NAT.CIN ] = "Cook Islands";
		Nation.flag[ NAT.CRI ] = "Costa Rica";
		Nation.flag[ NAT.CRO ] = "Croatia";
		Nation.flag[ NAT.CUB ] = "Cuba";
		Nation.flag[ NAT.CYP ] = "Cyprus";
		Nation.flag[ NAT.CZE ] = "Czech Republic";
		Nation.flag[ NAT.DEN ] = "Denmark";
		Nation.flag[ NAT.DJI ] = "Djibouti";
		Nation.flag[ NAT.DOM ] = "Dominican Republic";
		Nation.flag[ NAT.ECU ] = "Ecuador";
		Nation.flag[ NAT.ELG ] = "Equatorial Guinea";
		Nation.flag[ NAT.EGY ] = "Egypt";
		Nation.flag[ NAT.ENG ] = "England";
		Nation.flag[ NAT.ERI ] = "Eritrea";
		Nation.flag[ NAT.ESP ] = "Spain";
		Nation.flag[ NAT.EST ] = "Estonia";
		Nation.flag[ NAT.ETH ] = "Ethiopia";
		Nation.flag[ NAT.FGB ] = "England";
		Nation.flag[ NAT.FAI ] = "Faroes";
		Nation.flag[ NAT.FIJ ] = "Fiji";
		Nation.flag[ NAT.FIN ] = "Finland";
		Nation.flag[ NAT.FRA ] = "France";
		Nation.flag[ NAT.FRG ] = "Guyana";
		Nation.flag[ NAT.FRP ] = "Tahiti(French Polinesia)";
		Nation.flag[ NAT.MKD ] = "Macedonia";
		Nation.flag[ NAT.GAB ] = "Gabon";
		Nation.flag[ NAT.GAM ] = "Gambia";
		Nation.flag[ NAT.GEO ] = "Georgia";
		Nation.flag[ NAT.GER ] = "Germany";
		Nation.flag[ NAT.SAA ] = "Germany";
		Nation.flag[ NAT.DDR ] = "Germany";
		Nation.flag[ NAT.GE2 ] = "Germany";
		Nation.flag[ NAT.GHA ] = "Ghana";
		Nation.flag[ NAT.GGB ] = "Gibraltar";
		Nation.flag[ NAT.GRE ] = "Greece";
		Nation.flag[ NAT.GRA ] = "Grenada";
		Nation.flag[ NAT.GRL ] = "Greenland";
		Nation.flag[ NAT.FGA ] = "Guadeloupe";
		Nation.flag[ NAT.GMA ] = "Guam";
		Nation.flag[ NAT.GUA ] = "Guatemala";
		Nation.flag[ NAT.GUI ] = "Guinea";
		Nation.flag[ NAT.GUB ] = "Guinea-Bissau";
		Nation.flag[ NAT.GCI ] = "Guernsey";
		Nation.flag[ NAT.GUY ] = "Guyana";
		Nation.flag[ NAT.HAI ] = "Haiti";
		Nation.flag[ NAT.HON ] = "Honduras";
		Nation.flag[ NAT.HKG ] = "Hong Kong";
		Nation.flag[ NAT.HUN ] = "Hungary";
		Nation.flag[ NAT.ISL ] = "Iceland";
		Nation.flag[ NAT.IND ] = "India";
		Nation.flag[ NAT.INA ] = "Indonesia";
		Nation.flag[ NAT.IRI ] = "Iran";
		Nation.flag[ NAT.IRQ ] = "Iraq";
		Nation.flag[ NAT.IOM ] = "Isle of MAN";
		Nation.flag[ NAT.ISR ] = "Israel";
		Nation.flag[ NAT.IRL ] = "Ireland";
		Nation.flag[ NAT.ITA ] = "Italy";
		Nation.flag[ NAT.IVO ] = "Cote d'Ivoire";
		Nation.flag[ NAT.JAM ] = "Jamaica";
		Nation.flag[ NAT.JMY ] = "Svalbard and Jan Mayen";
		Nation.flag[ NAT.JPN ] = "Japan";
		Nation.flag[ NAT.JCI ] = "Jersey";
		Nation.flag[ NAT.JOR ] = "Jordan";
		Nation.flag[ NAT.KAZ ] = "Kazakhstan";
		Nation.flag[ NAT.KEN ] = "Kenya";
		Nation.flag[ NAT.KBA ] = "Kiribati";
		Nation.flag[ NAT.KOR ] = "South Korea";
		Nation.flag[ NAT.KYR ] = "Kyrgyzstan";
		Nation.flag[ NAT.KUW ] = "Kuwait";
		Nation.flag[ NAT.LAO ] = "Laos";
		Nation.flag[ NAT.LAT ] = "Latvia";
		Nation.flag[ NAT.LBN ] = "Lebanon";
		Nation.flag[ NAT.LES ] = "Lesotho";
		Nation.flag[ NAT.LBR ] = "Liberia";
		Nation.flag[ NAT.LBY ] = "Libya";
		Nation.flag[ NAT.LIE ] = "Liechtenstein";
		Nation.flag[ NAT.LTU ] = "Lithuania";
		Nation.flag[ NAT.LUX ] = "Luxembourg";
		Nation.flag[ NAT.MAC ] = "Macao";
		Nation.flag[ NAT.MAD ] = "Madagascar";
		Nation.flag[ NAT.MAS ] = "Malaysia";
		Nation.flag[ NAT.MLI ] = "Mali";
		Nation.flag[ NAT.MLT ] = "Malta";
		Nation.flag[ NAT.MWI ] = "Malawi";
		Nation.flag[ NAT.MFR ] = "Martinique";
		Nation.flag[ NAT.MAU ] = "Mauritania";
		Nation.flag[ NAT.MRI ] = "Mauritius";
		Nation.flag[ NAT.MYF ] = "Mayotte";
		Nation.flag[ NAT.MEX ] = "Mexico";
		Nation.flag[ NAT.MIC ] = "Micronesia";
		Nation.flag[ NAT.MDA ] = "Moldova";
		Nation.flag[ NAT.MNC ] = "Monaco";
		Nation.flag[ NAT.MON ] = "Mongolia";
		Nation.flag[ NAT.MSG ] = "Montserrat";
		Nation.flag[ NAT.MAR ] = "Morocco";
		Nation.flag[ NAT.MOZ ] = "Mozambique";
		Nation.flag[ NAT.MYA ] = "Myanmar(Burma)";
		Nation.flag[ NAT.NAM ] = "Namibia";
		Nation.flag[ NAT.NEP ] = "Nepal";
		Nation.flag[ NAT.NED ] = "Netherlands";
		Nation.flag[ NAT.AHO ] = "Netherlands Antilles";
		Nation.flag[ NAT.NCF ] = "New caledonia";
		Nation.flag[ NAT.NET ] = "United Nations";
		Nation.flag[ NAT.NZL ] = "New zealand";
		Nation.flag[ NAT.NCA ] = "Nicaragua";
		Nation.flag[ NAT.NGR ] = "Nigeria";
		Nation.flag[ NAT.NNN ] = "Niue";
		Nation.flag[ NAT.NNA ] = "Norfolk";
		Nation.flag[ NAT.NMI ] = "Northern Mariana";
		Nation.flag[ NAT.NOR ] = "Norway";
		Nation.flag[ NAT.OTM ] = "Timor-Leste";
		Nation.flag[ NAT.PAK ] = "Pakistan";
		Nation.flag[ NAT.PAL ] = "Palau";
		Nation.flag[ NAT.PLE ] = "Palestine";
		Nation.flag[ NAT.PAN ] = "Panama";
		Nation.flag[ NAT.PNG ] = "Papua new GUINEA";
		Nation.flag[ NAT.PAR ] = "Paraguay";
		Nation.flag[ NAT.PER ] = "Peru";
		Nation.flag[ NAT.PHI ] = "Philippines";
		Nation.flag[ NAT.PIG ] = "Pitcairn";
		Nation.flag[ NAT.POL ] = "Poland";
		Nation.flag[ NAT.POR ] = "Portugal";
		Nation.flag[ NAT.PUR ] = "Puerto rico";
		Nation.flag[ NAT.QAT ] = "Qatar";
		Nation.flag[ NAT.RUF ] = "Reunion";
		Nation.flag[ NAT.ROU ] = "Romania";
		Nation.flag[ NAT.URS ] = "Russian Federation";
		Nation.flag[ NAT.RUS ] = "Russian Federation";
		Nation.flag[ NAT.ZAR ] = "Russian Federation";
		Nation.flag[ NAT.ESA ] = "El Salvador";
		Nation.flag[ NAT.SMR ] = "San Marino";
		Nation.flag[ NAT.SAO ] = "Sao Tome and PRINCIPE";
		Nation.flag[ NAT.SCO ] = "Scotland";
		Nation.flag[ NAT.SEN ] = "Senegal";
		Nation.flag[ NAT.YUG ] = "Serbia(Yugoslavia)";
		Nation.flag[ NAT.SRB ] = "Serbia(Yugoslavia)";
		Nation.flag[ NAT.SEY ] = "Seychelles";
		Nation.flag[ NAT.SIN ] = "Singapore";
		Nation.flag[ NAT.SVK ] = "Slovakia";
		Nation.flag[ NAT.SLO ] = "Slovenia";
		Nation.flag[ NAT.SOM ] = "Somalia";
		Nation.flag[ NAT.RSA ] = "South africa";
		Nation.flag[ NAT.SRI ] = "Sri lanka";
		Nation.flag[ NAT.SUD ] = "Sudan";
		Nation.flag[ NAT.SUR ] = "Suriname";
		Nation.flag[ NAT.SVN ] = "Svalbard and Jan Mayen";
		Nation.flag[ NAT.SWE ] = "Sweden";
		Nation.flag[ NAT.SUI ] = "Switzerland";
		Nation.flag[ NAT.SYR ] = "Syria";
		Nation.flag[ NAT.HGB ] = "Saint Helena";
		Nation.flag[ NAT.PGB ] = "Saint Pierre and Miquelon";
		Nation.flag[ NAT.TJK ] = "Tajikistan";
		Nation.flag[ NAT.TAN ] = "Tanzania";
		Nation.flag[ NAT.THA ] = "Thailand";
		Nation.flag[ NAT.TKI ] = "Tokelau";
		Nation.flag[ NAT.TRI ] = "Trinidad and Tobago";
		Nation.flag[ NAT.TUN ] = "Tunisia";
		Nation.flag[ NAT.TCI ] = "Turks and Caicos Islands";
		Nation.flag[ NAT.TUR ] = "Turkey";
		Nation.flag[ NAT.TKM ] = "Turkmenistan";
		Nation.flag[ NAT.UGA ] = "Uganda";
		Nation.flag[ NAT.UKR ] = "Ukraine";
		Nation.flag[ NAT.UAE ] = "United Arab Emirates";
		Nation.flag[ NAT.USA ] = "United States of America";
		Nation.flag[ NAT.URU ] = "Uruguay";
		Nation.flag[ NAT.UZB ] = "Uzbekistan";
		Nation.flag[ NAT.VEN ] = "Venezuela";
		Nation.flag[ NAT.VIE ] = "Viet Nam";
		Nation.flag[ NAT.WLS ] = "Wales";
		Nation.flag[ NAT.WFR ] = "Wallis and Futuna";
		Nation.flag[ NAT.YEM ] = "Yemen";
		Nation.flag[ NAT.ZAM ] = "Zambia";
		Nation.flag[ NAT.ZIM ] = "Zimbabwe";
		Nation.flag[ NAT.ZRE ] = "Congo-Kinshasa(Zaire)";
		Nation.flag[ NAT.NKO ] = "North korea";
		Nation.flag[ NAT.MDV ] = "Maldives";
		Nation.flag[ NAT.MSH ] = "Marshall Islands";
		Nation.flag[ NAT.OMN ] = "Oman";
		Nation.flag[ NAT.NAU ] = "Nauru";
		Nation.flag[ NAT.NIG ] = "Niger";
		Nation.flag[ NAT.SAU ] = "Saudi Arabia";
		Nation.flag[ NAT.TOG ] = "Togo";
		Nation.flag[ NAT.TON ] = "Tonga";
		Nation.flag[ NAT.VAN ] = "Vanuatu";
		Nation.flag[ NAT.VAT ] = "Vatican City";
		Nation.flag[ NAT.TUV ] = "Tuvalu";
		Nation.flag[ NAT.SWA ] = "Swaziland";
		Nation.flag[ NAT.SIE ] = "Sierra Leone";
		Nation.flag[ NAT.SLU ] = "Saint Lucia";
		Nation.flag[ NAT.PAP ] = "Papua New Guinea";
		Nation.flag[ NAT.SVI ] = "St Vincent and The Grenadines";
		Nation.flag[ NAT.SAM ] = "Samoa";
		Nation.flag[ NAT.SKI ] = "St Kitts and NEVIS";
		Nation.flag[ NAT.SAL ] = "Solomon islands";
		Nation.flag[ NAT.RWA ] = "Rwanda";
		Nation.flag[ NAT.TPE ] = "Taiwan";
		Nation.flag[ NAT.NIR ] = "Northern Ireland";
		Nation.flag[ NAT.GBR ] = "United Kingdom(Great Britain)";
		Nation.flag[ NAT.KOS ] = "Kosovo";
	}

	static code = [];

	static initCode()
	{
		//		LOG( "Nation: Init Code" );
		var n = 0;
		for ( var c in NAT )
		{
			if ( NAT.hasOwnProperty( c ) )
			{
				Nation.code[ n ] = c;
				n += 1;
			}
		}
		var stylesheet;
		if ( document.styleSheets.length > 0 )
			stylesheet = document.styleSheets[ document.styleSheets.length - 1 ];

		for ( var i = 0; i < Nation.code.length; i++ )
		{
			var theFlag = Nation.flag[ i ];
			if ( theFlag === undefined )
				theFlag = Nation.flag[ 0 ];
			
			// NH2020 Currently dont need css icon
			// addCSSIcon( stylesheet, Nation.code[ i ], theFlag, 16 );
		}

		// NH2021D
		// add a css rule for each nation to draw flags as background image in a cell
		// function addCSSIcon( sheet, natCode, _file, size )
		// {
		// 	if ( natCode.length > 1 )
		// 	{
		// 		var file = window.escape( _file );
		// 		var rule = " background-image: url( " + glApp.getCommonPath() + "media/flags/flags" + size + "/" + file + ".png ) !important;";
		// 		rule += "background-repeat:no-repeat; background-position:center center;";
		// 		CSS.addRules( ".cls-" + natCode, rule );
		// 	}
		// };
	}

	getStrCode()
	{
		return Nation.code[ this.n ];
	}

	getStrFlag()
	{
		if ( Nation.flag[ this.n ] )
			return Nation.flag[ this.n ];
		return Nation.flag[ NAT.NONE ];
	}
}

export var NAT = {

	NONE: 0,
	AFG: 1,
	ALB: 2,
	ALG: 3,
	AND: 4,
	ANG: 5,
	ANT: 6,
	ARG: 7,
	ARM: 8,	// 008
	AUS: 9,
	AUT: 10,
	AZE: 11,
	BAH: 12,
	BHN: 13,
	BAN: 14,
	BAR: 15,
	BLR: 16,	// 016
	BEL: 17,
	BLZ: 18,
	BER: 19,
	BOL: 20,
	BIH: 21,
	BOT: 22,
	BRA: 23,
	IVB: 24,	// 024
	BRN: 25,
	BUL: 26,
	BUR: 27,
	CAN: 28,
	CHI: 29,
	CHN: 30,
	COL: 31,
	CRI: 32,	// 032
	CRO: 33,
	CUB: 34,
	CYP: 35,
	CZE: 36,
	DEN: 37,
	DJI: 38,
	DOM: 39,
	ECU: 40, // 040
	EGY: 41,
	ENG: 42,
	ESP: 43,
	EST: 44,
	ETH: 45,
	FAI: 46,
	FIJ: 47,
	FIN: 48,	// 048
	FRA: 49,
	MKD: 50,
	GAM: 51,
	GEO: 52,
	GER: 53,
	GHA: 54,
	GRE: 55,
	GUA: 56,	// 056
	GCI: 57,
	GUY: 58,
	HAI: 59,
	HON: 60,
	HKG: 61,
	HUN: 62,
	ISL: 63,
	IND: 64, // 064
	INA: 65,
	IRI: 66,
	IRQ: 67,
	ISR: 68,
	IRL: 69,
	ITA: 70,
	IVO: 71,
	JAM: 72, // 072
	JPN: 73,
	JCI: 74,
	JOR: 75,
	KAZ: 76,
	KEN: 77,
	KOR: 78,
	KYR: 79,
	KUW: 80, // 080
	LAT: 81,
	LBN: 82,
	LBY: 83,
	LIE: 84,
	LTU: 85,
	LUX: 86,
	MAC: 87,
	MAD: 88, // 088
	MAS: 89,
	MLI: 90,
	MLT: 91,
	MAU: 92,
	MRI: 93,
	MEX: 94,
	MDA: 95,
	MNC: 96, // 096
	MON: 97,
	MAR: 98,
	MOZ: 99,
	MYA: 100,
	NAM: 101,
	NEP: 102,
	NED: 103,
	AHO: 104, // 104
	NZL: 105,
	NCA: 106,
	NGR: 107,
	NOR: 108,
	PAK: 109,
	PLE: 110,
	PAN: 111,
	PNG: 112, // 112
	PAR: 113,
	PER: 114,
	PHI: 115,
	POL: 116,
	POR: 117,
	PUR: 118,
	QAT: 119,
	ROU: 120, // 120
	RUS: 121,
	ESA: 122,
	SMR: 123,
	SCO: 124,
	SEN: 125,
	SEY: 126,
	SIN: 127,
	SVK: 128, // 128
	SLO: 129,
	RSA: 130,
	SRI: 131,
	SUD: 132,
	SUR: 133,
	SWE: 134,
	SUI: 135,
	SYR: 136, // 136
	TJK: 137,
	TAN: 138,
	THA: 139,
	TRI: 140,
	TUN: 141,
	TUR: 142,
	TKM: 143,
	UGA: 144, // 144
	UKR: 145,
	UAE: 146,
	USA: 147,
	URU: 148,
	URS: 149,
	UZB: 150,
	VEN: 151,
	VIE: 152,	// 152
	ISV: 153,
	WLS: 154,
	YEM: 155,
	YUG: 156,
	ZAM: 157,
	ZIM: 158,
	ZRE: 159, // 159
	DDR: 160,
	CSR: 161,
	CAM: 162,
	CHD: 163,
	KAP: 164,
	KBA: 165,
	COM: 166,
	CON: 167,	// 167
	NKO: 168,
	LAO: 169,
	LES: 170,
	MWI: 171,
	MDV: 172,
	MSH: 173,
	OMN: 174,
	NAU: 175,	// 175
	MIC: 176,
	NIG: 177,
	SAU: 178,
	TOG: 179,
	TON: 180,
	VAN: 181,
	VAT: 182,
	TUV: 183, // 183
	SWA: 184,
	SIE: 185,
	SLU: 186,
	PAP: 187,
	SVI: 188,
	SAM: 189,
	SKI: 190,
	SAL: 191,	// 191
	GE2: 192,
	ZAR: 193,
	RWA: 194,
	LBR: 195,
	NET: 196,
	TPE: 197, // 197
	ASU: 198,
	AGG: 199,
	ARN: 200,
	BNN: 201,
	BTN: 202,
	BRI: 203,
	CMB: 204, // 204
	CAY: 205,
	CAR: 206,
	CIB: 207,
	CIA: 208,
	COA: 209,
	CIN: 210,
	ELG: 211,
	ERI: 212, // 212
	FGB: 213,
	FRG: 214,
	FRP: 215,
	GAB: 216,
	GGB: 217,
	GRA: 218,
	GRL: 219,
	FGA: 220, // 220
	GMA: 221,
	GUI: 222,
	GUB: 223,
	IOM: 224,
	JMY: 225,
	MFR: 226,
	MYF: 227,
	MSG: 228, // 228
	NCF: 229,
	NNN: 230,
	NNA: 231,
	NMI: 232,
	OTM: 233,
	PAL: 234,
	PIG: 235,
	RUF: 236, // 236
	SAO: 237,
	SOM: 238,
	SVN: 239,
	HGB: 240,
	PGB: 241,
	TKI: 242,
	TCI: 243,
	WFR: 244, // 244
	NIR: 245,
	ISS: 246,
	GBR: 247,
	SAA: 248, // 248
	MNE: 249,
	SRB: 250,
	CAT: 251,
	BAS: 252,
	KOS: 253,

	NUM: 254
};

