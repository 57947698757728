// jb 3.7.2013

// "use strict";
import { Game } from 'common/Chess/Logic/Game';
// import { Log } from "common/Tools/Log"


export var OnlineEntryEnum = {
    FLAGS_ONLINE_NONE: 0,
    FLAGS_ONLINE_ID: 1,
    FLAGS_ONLINE_HEADER: 2,
    FLAGS_ONLINE_GAME: 2,

    toString:
        function ( n ) {
            for ( var attr in this ) {
                if ( this[attr] == n )
                    return "OnlneEntryId: " + attr;
            }
            return "Unknown OnlineEntryId: " + n;
        }
};

export class OnlineEntry 
{
    constructor () 
    {
        this.aGame = new Game();

        //NH2020 added = null
        this.aGame = null;
        this.aHeader = null;
        this.aDB = null;
        this.nGameNr = null;
        this.nFlags = null;
    };
}



