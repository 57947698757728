import React from 'react';
import styles from "./CBMove.module.css";

import { checkPropsAndRenderComponent } from "CBReact/Utils/DebugUtils"

// TODO: Maybe rename this component. This is the component for a clickable move in the notation
// Might be confused with Move.js
export function CBMove (props)
{

    function renderCBMove ()
    {
        return (
            <div className={`${styles.movePart} 
                            ${props.isWhite ? styles.movePartOne : styles.movePartTwo}
                            ${props.isSelectedPrimary && styles.selectedPrimary}
                            ${props.isSelectedSecondary && styles.selectedSecondary}`}
                onClick={() => props.changeGamePosition()}>
                {props.notation}
            </div>
        );
    }

    return checkPropsAndRenderComponent([props.notation, props.changeGamePosition, props.isWhite, props.isSelectedPrimary, props.isSelectedSecondary], renderCBMove);
}

function movePropsAreEqual (prev, next)
{
    return prev.notation == next.notation && prev.isSelectedPrimary == next.isSelectedPrimary && prev.isSelectedSecondary == next.isSelectedSecondary;
}

export const CBMMove = React.memo(CBMove, movePropsAreEqual);

