// mw 17.1.2014

// "use strict";

import { Storage } from "common/Tools/Storage";

export class Set
{
	constructor ( elements )
	{
		this.bag = [];
		var i;

		if ( arguments.length > 0 )
		{ // optional args
			for ( i = 0; i < elements.length; i++ )
			{
				this.insert( elements[i] );
			}
		}
	};

	clear ()
	{
		this.bag = [];
	};

	search ( e, start )
	{
		//TODO document this
		var j = this.bag.length;
		var pivot;
		var i = arguments.length == 2 ? start : 0;

		while ( i < j )
		{
			pivot = i + Math.floor(( j - i ) / 2 );
			if ( this.bag[pivot].setEquals( e ) )
			{
				return pivot;
			}

			if ( e.setGreater( this.bag[pivot] ) )
			{
				i = pivot + 1;
			} else
			{
				j = pivot;
			}
		}

		return i;
	}

	insert ( e )
	{
		var p = this.search( e );
		if ( this.bag[p] === undefined || !this.bag[p].setEquals( e ) )
		{
			this.bag.splice( p, 0, e ); // insert e at position p
		}
	}

	contains ( e )
	{
		var p = this.search( e );

		if ( this.bag[p] !== undefined )
			return ( this.bag[p].setEquals( e ) );
		else
			return false;
	}

	get ( e )
	{
		var p = this.search( e );
		if ( this.bag[p] !== undefined )
			if ( this.bag[p].setEquals( e ) )
				return this.bag[p];
	};

	size ()
	{
		return this.bag.length;
	}

	getElements ()
	{
		return this.bag;
	}

	equals ( otherSet )
	{
		if ( this.size() != otherSet.size() ) 
		{
			return false; 
		}
		
		var i;
		for ( i = 0; i < this.bag.length; i++ )
		{
			if ( !this.bag[i].setEquals( otherSet.bag[i] ) )
			{
				return false;
			}
		}

		return true;
	};

	save ( name, fnFilter )
	{
		var store = new Storage();
		store.save( name + "_len", this.bag.length );
		for ( var i = 0; i < this.bag.length; i++ )
		{
			if ( !fnFilter || fnFilter( this.bag[i] ) )
				store.save( name + "_o" + i, this.bag[i]);
		}
	};

	load ( name, fnConverter )
	{
		var store = new Storage();
		var len = store.load( name + "_len" );

		if ( len )
		{
			var cnt = 0;
			for ( var i = 0; i < len; i++ )
			{
				var json = store.load( name + "_o" + i );
				if ( fnConverter )
				{
					var obj = fnConverter( json );
					if ( obj )
						this.bag[cnt++] = obj;
				}
				else
					this.bag[cnt++] = json;
			}
		}
	};

	//Set.prototype.difference = function ( otherSet ) {
	//	var result = new Set();

	//	if ( this.size() == 0 ) { return result; }
	//	if ( otherSet.size() == 0 ) {
	//		result.bag = this.bag.slice( 0 );
	//		return result;
	//	}

	//	var i;
	//	var j = 0;
	//	for ( i = 0; i < this.bag.length; i++ ) {
	//		if ( this.bag[i].setGreater( otherSet.bag[j] ) ) {
	//			j = otherSet.search( this.bag[i], j ); // finds First otherSet[j] Not Smaller than this[i]
	//			if ( j == otherSet.bag.length ) { break; }  // end of otherSet
	//		}

	//		if ( this.bag[i] < otherSet.bag[j] ) {
	//			result.bag.push( this.bag[i] );
	//		}
	//	}
	//	result.bag = result.bag.concat( this.bag.slice( i ) ); // adds the remaining elements, if there are any

	//	return result;
	//}

	//Set.prototype.intersection = function ( otherSet ) {
	//	var result = new Set();
	//	if ( ( this.size() == 0 ) || ( otherSet.size() == 0 ) ) { return result; }

	//	var i;
	//	var j = 0;
	//	for ( i = 0; i < this.bag.length; i++ ) {
	//		j = otherSet.search( this.bag[i], j ); // finds First otherSet[j] Not Smaller than this[i]
	//		if ( j == otherSet.bag.length ) { break; } // end of otherSet

	//		if ( this.bag[i] == otherSet.bag[j] ) {
	//			result.bag.push( this.bag[i] );
	//		}
	//	}

	//	return result;
	//}

	//Set.prototype.union = function ( otherSet ) {
	//	var result = new Set();
	//	if ( ( this.size() == 0 ) && ( otherSet.size() == 0 ) ) { return result; }

	//	var base, merged;
	//	if ( this.size() > otherSet.size() ) {
	//		base = this;
	//		merged = otherSet;
	//	} else {
	//		base = otherSet;
	//		merged = this;
	//	}

	//	result.bag = base.bag.slice( 0 ); // make a copy
	//	var i;
	//	for ( i = 0; i < merged.bag.length; i++ ) {
	//		result.insert( merged.bag[i] ); // insert() doesn't allow repetition
	//	}

	//	return result;
	//}

}

/////////////////////////////////////////////////////////////////////////////////////////

export class NativeSet
{
	constructor ( elements )
	{
		this.bag = [];
		var i;

		if ( arguments.length > 0 )
		{ // optional args
			for ( i = 0; i < elements.length; i++ )
			{
				this.insert( elements[i] );
			}
		}
	};

	clear ()
	{
		this.bag = [];
	};

	search ( e, start )
	{
		//TODO document this
		var j = this.bag.length;
		var pivot;
		var i = arguments.length == 2 ? start : 0;

		while ( i < j )
		{
			pivot = i + Math.floor(( j - i ) / 2 );
			if ( this.bag[pivot] == e )
			{
				return pivot;
			}

			if ( e > this.bag[pivot] )
			{
				i = pivot + 1;
			} else
			{
				j = pivot;
			}
		}

		return i;
	}

	insert ( e )
	{
		var p = this.search( e );
		if ( this.bag[p] === undefined || this.bag[p] != e )
		{
			this.bag.splice( p, 0, e ); // insert e at position p
		}
	}

	contains ( e )
	{
		var p = this.search( e );

		if ( this.bag[p] !== undefined )
			return ( this.bag[p] == e );
		else
			return false;
	}

	get ( e )
	{
		var p = this.search( e );
		if ( this.bag[p] !== undefined )
			if ( this.bag[p] == e )
				return this.bag[p];
	};

	size ()
	{
		return this.bag.length;
	}

	getElements ()
	{
		return this.bag;
	}

	equals ( otherSet )
	{
		if ( this.size() != otherSet.size() )
		{
			return false;
		}

		var i;
		for ( i = 0; i < this.bag.length; i++ )
		{
			if ( this.bag[i] != otherSet.bag[i] )
			{
				return false;
			}
		}

		return true;
	};

	save ( name, fnFilter )
	{
		var store = new Storage();
		store.save( name + "_len", this.bag.length );
		for ( var i = 0; i < this.bag.length; i++ )
		{
			if ( !fnFilter || fnFilter( this.bag[i] ) )
				store.save( name + "_o" + i, this.bag[i] );
		}
	};

	load ( name, fnConverter )
	{
		var store = new Storage();
		var len = store.load( name + "_len" );

		if ( len )
		{
			var cnt = 0;
			for ( var i = 0; i < len; i++ )
			{
				var json = store.load( name + "_o" + i );
				if ( fnConverter )
				{
					var obj = fnConverter( json );
					if ( obj )
						this.bag[cnt++] = obj;
				}
				else
					this.bag[cnt++] = json;
			}
		}
	};

}
