// mw 25.3.2013

export var PlayerUserMsgId = 
{
    QUERY_MULTI_NATION_INFO                 : 1300,
    QUERY_FILL_PLAYERITEM_BY_NAME_AND_THUMB: 1301,
    QUERY_FILL_PLAYERITEM_BY_NAME: 1302,
    QUERY_FILL_MULTIPLAYERITEM_BNAT: 1303,
    QUERY_GET_BATCH_ID: 1304,
    QUERY_CURRENT_PB_VERSION: 1305,
    QUERY_FILL_PLAYER_BY_CBID: 1306,
    QUERY_FILL_CANDIDATES_ARRAY: 1307,
    QUERY_FILL_PICTURE: 1308,

	ANS_MULTI_NATION_INFO                   : 1309,
	ANS_FILL_PLAYERITEM_BY_NAME_AND_THUMB   : 1310,
	ANS_FILL_PLAYERITEM_BY_NAME             : 1311,
	ANS_FILL_MULTIPLAYERITEM_BNAT           : 1312,
	ANS_GET_BATCH_ID                        : 1313,
	ANS_CURRENT_PB_VERSION                  : 1314,
	ANS_FILL_PLAYER_BY_CBID                 : 1315,
	ANS_FILL_CANDIDATES_ARRAY               : 1316,
	ANS_FILL_PICTURE                        : 1317,

	SEND_FEEDBACK: 1320,
	SEND_FEEDBACK_ANS: 1321,

	QUERY_JS_PLAYER_BY_NAME_AND_ELO         : 1322,
	ANS_JS_PLAYER_BY_NAME_AND_ELO           : 1323,
	QUERY_JS_MULTI_PLAYER_INFO              : 1324,
	ANS_JS_PLAYER_MULTI_PLAYER_INFO			 : 1325,
	QUERY_JS_MULTI_PLAYER_WITH_PIC          : 1326,
	ANS_JS_MULTI_PLAYER_WITH_PIC            : 1327,
	toString:
		function ( n ) {
		   for ( var attr in this ) {
		      if ( this[attr] == n )
		         return attr;
		   }
		   return "Unknown PBUserMsgId: " + n;
		},
	toNumString:
			function ( n )
			{
				return "PlUserMsgId=" + n;
			}
     
};
