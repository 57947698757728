import { CookieUtils } from "CBReact/Utils/CookieUtils";
import { LocalizationUtils } from "CBReact/Hooks/ReactContext/useLocalizationContext/LocalizationUtils";
import { Language } from "common/Tools/Language";
import { useEffect, useRef, useState } from "react";


export function useLocalizationContext ()
{
    const [localization, setLocalization] = useState(LocalizationUtils.InitLocalization());
    const localizationRef = useRef();
    localizationRef.current = localization;

    // Init: If language preference exists in Storage: Set Language
    useEffect (() => {
        // let languagePreference = parseInt(CookieUtils.GetCookie("CBLoc"));
        let languagePreference = parseInt(CookieUtils.GetStorageItem("CBLoc"));

        if (localization && languagePreference && languagePreference !== Language.ENG)
            LocalizationUtils.SetLanguage(languagePreference, localization, setLocalization);
    },[]);

    function changeLocalization (language)
    {
        LocalizationUtils.SetLanguage(language, localization, setLocalization);
    }

    return {localization, localizationRef, changeLocalization};
    // return [localization, localizationRef, changeLocalization];
}