// mw 16.4.2013
// ES6 4.3.2020

import { GameResultEnum } from "common/Chess/Logic/GameHeader";

import {  BaseClock } from "common/Chess/Logic/BaseClock";


// all times in centiSecs.

export class ManualClock extends BaseClock
{
	// constructor ( clockLink )
	// {
	// 	super( clockLink );
	// };

	setTime( whiteTime, blackTime, isBlack )
	{
		this.stop();

		if ( whiteTime || blackTime )
		{
			this.currTime = this.startTime = new Date().getTime();

			if ( whiteTime >= 0 )
				this.whiteTime = whiteTime;
			if ( blackTime >= 0 )
				this.blackTime = blackTime;

			if ( this.whiteTime >= 0 )
			{
				this.isBlack = false;
				this.update();
			}
			if ( this.blackTime >= 0 )
			{
				this.isBlack = true;
				this.update();
			}
			this.isBlack = isBlack;
			this.clockLink.setToMove( isBlack );

			this.start();
		}
		else
		{
			this.setNoClock();
		}

	};

	onResult( result )
	{
		if ( GameResultEnum.isFinished( result ) )
		{
			this.stop();
			this.clockLink.showResult( result );
		}
	};

	setToMove( black )
	{
		this.isBlack = black;
		this.update();
		this.clockLink.setToMove( black );
		this.clockLink.emphasizeToMove( 8000 );
	};


	start()
	{
		this.updateTimer.runPeriodic( 1000 );
		if ( this.clockLink )
		{
			if ( this.clockLink.runningClockId && this.clockLink.runningClockId !== this.id )
			{
				// CBDebug.assert( 0 );
				// TRACE( "Clock Collision!" );
			}
			this.clockLink.runningClockId = this.id;
		}
	};

	stop()
	{
		this.updateTimer.stop();
		if ( this.clockLink )
			this.clockLink.runningClockId = 0;
	};

	update()
	{
		var centiSecsExpired = ( new Date().getTime() - this.startTime + 5 ) / 10;
		var nCurrTime = ( new Date().getTime() - this.currTime + 5 ) / 10;

		var restTime = 0;
		this.clockLink.showCurrTime( new Date( nCurrTime * 10 ), this.isBlack );
		if ( this.isBlack )
		{
			this.blackTime -= centiSecsExpired;
			restTime = this.blackTime;
		} else
		{
			this.whiteTime -= centiSecsExpired;
			restTime = this.whiteTime;
		}
		this.startTime = new Date().getTime();

		if ( restTime < 0 )
			this.clockLink.showRestTime( -1, this.isBlack );
		else
			this.clockLink.showRestTime( new Date( restTime * 10 ), this.isBlack );

	// "#IFDEBUG"
	// 	if ( this.clockLink.runningClockId && this.clockLink.runningClockId != this.id )
	// 	{
	// 		CBDebug.assert( 0, "Clock Collision" );
	// 	}
	// "#ENDIF"
	};
}