// "use strict";

import { NewCBPlayer } from "common/Chess/PlayerBase/NewCBPlayer"
import { EloArray } from "common/Chess/Format/EloArray"
import { MMNameArray } from "common/Chess/Format/MMNameArray"

export class Player
{

	// constructor ()
	// {
	// };

	// NH2020 made these static
	static PFN =
   	{
		PFN_RAW: 0,
		PFN_ARR: 1,
		PFN_BMP: 2
   	};

	static PL =
	{
		PL_DATA_KEY: 0x0001,
		PL_DATA_HIGH: 0x0002,
		PL_DATA_LOW: 0x0004,
		PL_DATA_ELO: 0x0008,
		PL_DATA_ONE_ELO: 0x0010,
		PL_DATA_PICS: 0x0020,
		PL_DATA_ONE_PIC: 0x0040
	};

	// NH2020 Does this work?
	static PL_DATA_ALL = Player.PL.PL_DATA_KEY + Player.PL.PL_DATA_HIGH + Player.PL.PL_DATA_LOW + Player.PL.PL_DATA_ELO + Player.PL.PL_DATA_PICS;

	readFromDataBuffer ( rBuf )
	{
		try
		{
			rBuf.beginSizedRead();

			this.m_nDataMask = rBuf.readInt32();
			this.m_NewCBPlayer = new NewCBPlayer();
			this.m_NewCBPlayer.readFromDataBuffer( rBuf, this.m_nDataMask );

			this.m_EloArray = new EloArray();

			if ( ( this.m_nDataMask & ( this.PL.PL_DATA_ELO ) ) > 0 )
				this.m_EloArray.readFromDataBuffer( rBuf );
			else if ( ( this.m_nDataMask & ( this.PL.PL_DATA_ONE_ELO ) ) > 0 )
				this.m_EloArray.readLatestFromDataBuffer( rBuf );

			this.m_MMNameArray = new MMNameArray();

			if ( ( this.m_nDataMask & ( this.PL.PL_DATA_PICS ) ) > 0 )
				this.m_MMNameArray.readFromDataBuffer( rBuf );
			else if ( ( this.m_nDataMask & ( this.PL.PL_DATA_ONE_PIC ) ) > 0 )
				this.m_MMNameArray.readFromDataBuffer( rBuf );
			this.setModifyDate( rBuf.readCBDate() );
			rBuf.endSizedRead();
		}
		catch ( x )
		{
			// NH2020 comment
			// "#IFDEBUG"
			// CB.LOG( x );
			// "#ENDIF"
			return false;
		}
		return true;
	};

	getRPlayerKey ()
	{
		return this.m_NewCBPlayer.GetKey();
	};

	getCBId ()
	{
		return this.m_NewCBPlayer.GetKey().GetCBId();
	};

	setCBId ( nId )
	{
		this.m_NewCBPlayer.GetKey().SetCBId( nId );
	};

	getMediaId ()
	{
		return this.m_nMediaId;
	};

	setMediaId ( nId )
	{
		this.m_nMediaId = nId;
	};

	getRKey ()
	{
		return this.m_NewCBPlayer.GetKey();
	};

	getRNewCBPlayer ()
	{
		return this.m_NewCBPlayer;
	};

	getREloArray ()
	{
		return this.m_EloArray;
	};

	getRMMNameArray ()
	{
		return this.m_MMNameArray;
	};

	hasFotos ()
	{
		return this.m_MMNameArray.Count() > 0;
	};

	// Wrapper Zugriffe
	getFirstName ()
	{
		return this.m_NewCBPlayer.getFirstName();
	};

	setFirstName ( rS )
	{
		this.m_NewCBPlayer.setFirstName( rS );
	};

	setFirstName2 (rS)
	{
	    this.m_NewCBPlayer.setFirstName(rS);
	};

	getLastName ()
	{
		return this.m_NewCBPlayer.getLastName();
	};

	setLastName ( rS )
	{
		this.m_NewCBPlayer.setLastName( rS );
	};

	getBirthDate ()
	{
		return this.getRPlayerKey().GetBirthDate();
	};

	setBirthDate ( aDate )
	{
		this.getRPlayerKey().setBirthDate( aDate );
	};

	getIdenticalCount ()
	{
		return this.getRPlayerKey().getIdenticalCount();
	};

	getTitle ()
	{
		return this.m_NewCBPlayer.getRHighPlayerData().getCurrentTitle();
	};

	setTitle ( rT )
	{
		this.m_NewCBPlayer.getRHighPlayerData().setCurrentTitle( rT );
	};

	getNation ()
	{
		return this.m_NewCBPlayer.getRHighPlayerData().GetNation();
	};

	setNation ( rN )
	{
	    this.m_NewCBPlayer.getRHighPlayerData().setNation(rN);
	};

	getBornNation ()
	{
		return this.m_NewCBPlayer.getRHighPlayerData().getBornNation();
	};

	getDeathDate ()
	{
		return this.m_NewCBPlayer.getRHighPlayerData().GetDeathDate();
	};

	setDeathDate ( aDate )
	{
		this.m_NewCBPlayer.getRHighPlayerData().setDeathDate( aDate );
	};

	getIsMal ()
	{
		return this.m_NewCBPlayer.getRHighPlayerData().getIsMale();
	};

	setIsMale ( b )
	{
		this.m_NewCBPlayer.getRHighPlayerData().setIsMale( b );
	};

	setDataMask ( nDataMask )
	{
		this.m_nDataMask = nDataMask;
	};

	getDataMask ()
	{
		return this.m_nDataMask;
	};

	getVersion ()
	{
		return this.m_nVersion;
	};

	setDateCached ( rD )
	{
		this.m_dCached = rD;
	};

	getDateCached ()
	{
		return this.m_dCached;
	};

	setModifyDate ( rD )
	{
		this.m_aModifyDate = rD;
	};

	getModifyDate ()
	{
		return this.m_aModifyDate;
	};

}

