import React from 'react';
import styles from "./CBIcons.module.css";

export function CBAccountIcon (props) {
    return (
        <svg version="1.2" baseProfile="tiny" id="AccountIcon"
            xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            viewBox="0 0 180 180"
            height={props.height || "100%"} className={styles.headerIcon} style={{display: "block", margin: "auto", float: "left"}} >
            <g id="Account">
                <path d="M90,0C40.294,0,0,40.294,0,90c0,49.705,40.294,90,90,90c49.705,0,90-40.295,90-90
                C180,40.294,139.705,0,90,0z M145.177,133.857c-11.085-18.946-31.64-31.679-55.177-31.679c-23.536,0-44.09,12.732-55.177,31.679
                C25.24,121.818,19.5,106.584,19.5,90c0-38.936,31.564-70.5,70.5-70.5c38.937,0,70.5,31.564,70.5,70.5
                C160.5,106.584,154.76,121.818,145.177,133.857z"/>
                <circle  cx="90" cy="62" r="30" />
            </g>
        </svg>
    );
}
export const CBMAccountIcon = React.memo(CBAccountIcon, () => true);

export function CBBoardIcon (props) {
    return (
        <svg version="1.2" baseProfile="tiny" id="BoardIcon" xmlns="http://www.w3.org/2000/svg"
            x="0px" y="0px" viewBox="0 0 170 170" 
            height={props.height || "100%"} className={styles.defaultIcon} style={{display: "block", margin: "auto"}}>
            <path id="Board" d="M0,0v170h170V0H0z M147.5,147.5H85V85H22.5V22.5H85V85h62.5V147.5z" />
        </svg>
    );
}
export const CBMBoardIcon = React.memo(CBBoardIcon, () => true);

export function CBBookIcon (props) {
    return (
        <svg version="1.2" baseProfile="tiny" id="Book" xmlns="http://www.w3.org/2000/svg" 
            x="0px" y="0px" viewBox="0 0 199.008 160.668"
            height={props.height || "100%"} className={styles.defaultIcon} style={{display: "block", margin: "auto"}}>
            <polygon id="Page2" points="104.334,150 188.178,120 188.178,0 104.334,30 " />
            <polygon id="Page1" points="95.178,150 11.333,120 11.333,0 95.178,30 " />
            <polygon id="Edge" points="193.688,8.91 193.688,122.333 115.467,150.422 104.334,154.354 95.178,154.417 83.845,150.187 
            5.999,122.333 5.999,9.146 0,6.999 0,126.999 83.845,156.999 95.178,160.668 104.334,160.668 115.467,156.999 199.008,126.999 
            199.008,6.999 "/>
        </svg>
    );
}
export const CBMBookIcon = React.memo(CBBookIcon, () => true);

export function CBCloseIcon (props) {

    return (
        <svg version="1.2" baseProfile="tiny" id="Plus" xmlns="http://www.w3.org/2000/svg"
            x="0px" y="0px" viewBox="0 0 150 150" 
            height={props.height || "100%"} className={props.className || styles.defaultIcon} style={{display: "block", margin: "auto"}}>
            <rect id="Rect01_1_" x="-11" y="54.933" transform="matrix(0.7071 0.7071 -0.7071 0.7071 75.0001 -31.0658)" width="171.999" height="40.133"/>
            <rect id="Rect02" x="-10.999" y="54.933" transform="matrix(-0.7071 0.7071 -0.7071 -0.7071 181.0658 74.9996)" width="171.998" height="40.133"/>
        </svg>
    );
}
export const CBMCloseIcon = React.memo(CBCloseIcon, () => true);

export function CBMinusIcon (props) {
    return (
        <svg version="1.2" baseProfile="tiny" id="Plus" xmlns="http://www.w3.org/2000/svg" 
            x="0px" y="0px" viewBox="0 0 150 150"
            height={props.height || "100%"} className={styles.defaultIcon} style={{display: "block", margin: "auto"}}>
            <rect id="Rect01" y="55" width="150" height="40" />
        </svg>
    );
}
export const CBMMinusIcon = React.memo(CBMinusIcon, () => true);

export function CBNextIcon (props) {
    return (

        <svg version="1.2" baseProfile="tiny" id="Ebene_1" xmlns="http://www.w3.org/2000/svg"
            x="0px" y="0px" viewBox="0 0 137.047 154.695" 
            height={props.height || "100%"} className={styles.defaultIcon} style={{display: "block", margin: "auto"}}>
            <path d="M0,12.347c0-11,7.794-15.5,17.32-10l112.583,65c9.526,5.5,9.526,14.5,0,20l-112.583,65c-9.526,5.5-17.32,1-17.32-10V12.347z
	        "/>
        </svg>
    );
}
export const CBMNextIcon = React.memo(CBNextIcon, () => true);

export function CBPlusIcon (props) {
    return (
        <svg version="1.2" baseProfile="tiny" id="Plus" xmlns="http://www.w3.org/2000/svg" 
            x="0px" y="0px" viewBox="0 0 150 150"
            height={props.height || "100%"} className={styles.defaultIcon} style={{display: "block", margin: "auto"}}>
            <rect id="Rect01_1_" y="55" width="150" height="40" />
            <rect id="Rect02" x="55" width="40" height="150" />
        </svg>
    );
}
export const CBMPlusIcon = React.memo(CBPlusIcon, () => true);

export function CBCirclePlusIcon (props) {
    return (
        <svg version="1.1" id="CirclePlus" xmlns="http://www.w3.org/2000/svg" 
            x="0px" y="0px" viewBox="0 0 150 150"
            height={props.height || "100%"} className={styles.defaultIcon} style={{display: "block", margin: "auto"}}>
            <path id="Circle" d="M75,0C33.579,0,0,33.579,0,75c0,41.421,33.579,75,75,75c41.421,0,75-33.579,75-75C150,33.579,116.421,0,75,0z
	        M75,134c-32.585,0-59-26.417-59-59c0-32.585,26.415-59,59-59c32.583,0,59,26.415,59,59C134,107.583,107.583,134,75,134z"/>
            <rect id="Rect01_1_" x="45" y="67" width="60" height="16" />
            <rect id="Rect02" x="67" y="45" width="16" height="60" />
        </svg>
    );
}
export const CBMCircleIcon = React.memo(CBCirclePlusIcon, () => true);

export function CBPreviousIcon(props) {
    return (

        <svg version="1.2" baseProfile="tiny" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" 
            x="0px" y="0px"  viewBox="0 0 137.047 154.695"
            height={props.height || "100%"} className={styles.defaultIcon} style={{display: "block", margin: "auto"}} >
            <path d="M137.047,142.347c0,11-7.794,15.5-17.32,10l-112.583-65c-9.526-5.5-9.526-14.5,0-20l112.583-65c9.526-5.5,17.32-1,17.32,10
	        V142.347z"/>
        </svg>
    );
}
export const CBMPreviousIcon = React.memo(CBPreviousIcon, () => true);

export function CBResetIcon(props) {
    return (
        <svg version="1.2" baseProfile="tiny" id="ResetIcon"
            xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" height={props.height || "100%"}
            viewBox="0 0 187.862 164"
            className={styles.defaultIcon} style={{display: "block", margin: "auto"}}>
            <path d="M82,135.848c-29.738,0-53.848-24.109-53.848-53.848S52.262,28.152,82,28.152c9.961,0,19.283,2.715,27.286,7.431
	        l14.266-24.269C111.364,4.135,97.168,0,82,0C36.713,0,0,36.713,0,82s36.713,82,82,82s82-36.713,82-82h-28.152
	        C135.848,111.738,111.738,135.848,82,135.848z"/>
            <polygon points="111.124,82.652 149.493,16.195 187.862,82.652 " />
        </svg>
    );
}
export const CBMResetIcon = React.memo(CBResetIcon, () => true);

export function CBSearchIcon(props) {
    return (
        <svg version="1.2" baseProfile="tiny" id="SearchIcon" xmlns="http://www.w3.org/2000/svg"
            x="0px" y="0px"  height={props.height || "100%"} viewBox="0 0 143.267 143.267" 
            className={styles.defaultIcon} style={{display: "block", margin: "auto"}}>
            <rect x="101.281" y="82.876" transform="matrix(0.7071 -0.7071 0.7071 0.7071 -46.9226 113.2813)" width="24" height="60.811" />
            <path d="M58.333,24c18.932,0,34.333,15.402,34.333,34.333c0,18.932-15.401,34.333-34.333,34.333C39.402,92.666,24,77.265,24,58.333
	        C24,39.402,39.402,24,58.333,24 M58.333,0C26.117,0,0,26.117,0,58.333c0,32.217,26.117,58.333,58.333,58.333
	        c32.217,0,58.333-26.116,58.333-58.333C116.666,26.117,90.55,0,58.333,0L58.333,0z"/>
        </svg>
    );
}
export const CBMSearchIcon = React.memo(CBSearchIcon, () => true);

export function CBSettingsIcon (props) {
    return (

        <svg version="1.2" baseProfile="tiny" id="SettingsIcon"
            xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            viewBox="0 0 173.583 173.584" 
            height={props.height || "100%"} className={styles.headerIcon} style={{display: "block", margin: "auto", float: "left"}}>
            <path id="Gear" d="M169.667,103.276c2.154-0.449,3.916-2.616,3.916-4.816V75.126c0-2.2-1.762-4.367-3.916-4.816l-12.205-2.544
            c-2.154-0.449-4.514-2.514-5.244-4.589l-2.044-5.046c-0.928-1.995-0.701-5.132,0.504-6.973l8.94-13.647
            c1.205-1.84,0.919-4.619-0.637-6.175l-16.499-16.499c-1.556-1.556-4.334-1.842-6.174-0.637l-13.557,8.879
            c-1.84,1.206-4.975,1.425-6.965,0.488l-5.382-2.198c-2.073-0.734-4.138-3.097-4.587-5.25l-2.544-12.205
            C102.826,1.762,100.659,0,98.458,0H75.125c-2.2,0-4.367,1.762-4.816,3.916l-2.543,12.205c-0.449,2.154-2.514,4.514-4.589,5.246
            l-5.35,2.182c-1.992,0.934-5.127,0.711-6.968-0.494l-13.513-8.852c-1.84-1.206-4.619-0.919-6.175,0.637l-16.5,16.5
            c-1.556,1.556-1.842,4.334-0.637,6.175l8.898,13.583c1.206,1.84,1.432,4.978,0.503,6.972l-2.072,5.108
            c-0.73,2.075-3.089,4.141-5.243,4.59L3.916,70.309C1.762,70.758,0,72.925,0,75.125v23.334c0,2.2,1.762,4.367,3.916,4.816
            l12.205,2.543c2.154,0.449,4.514,2.515,5.244,4.59l2.111,5.192c0.931,1.994,0.706,5.131-0.5,6.971l-8.951,13.666
            c-1.206,1.84-0.919,4.618,0.636,6.174l16.5,16.501c1.555,1.556,4.334,1.842,6.174,0.636l13.661-8.948
            c1.84-1.206,4.977-1.431,6.971-0.5l5.208,2.118c2.075,0.73,4.14,3.09,4.589,5.244l2.544,12.206c0.449,2.154,2.616,3.916,4.816,3.916
            h23.333c2.2,0,4.367-1.762,4.816-3.916l2.543-12.207c0.449-2.154,2.515-4.514,4.59-5.244l5.188-2.108
            c1.994-0.93,5.131-0.705,6.971,0.5l13.649,8.941c1.84,1.205,4.618,0.919,6.174-0.637l16.5-16.5c1.556-1.556,1.842-4.334,0.637-6.174
            l-8.936-13.64c-1.205-1.84-1.43-4.977-0.498-6.97l2.124-5.221c0.73-2.075,3.09-4.141,5.244-4.59L169.667,103.276z M86.792,122.566
            c-19.758,0-35.774-16.018-35.774-35.774c0-19.758,16.017-35.774,35.774-35.774c19.757,0,35.774,16.017,35.774,35.774
            C122.566,106.548,106.548,122.566,86.792,122.566z"/>
        </svg>
    );
}
export const CBMSettingsIcon = React.memo(CBSettingsIcon, () => true);

export function CBSideNavIcon (props) {
    return (

        <svg version="1.2" baseProfile="tiny" id="SideNavigation"
            xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            viewBox="0 0 150 134" 
            height={props.height || "100%"} className={props.className || styles.defaultIcon}  style={{display: "block", margin: "auto"}}>
            <rect id="Block01" width="150" height="28" />
            <rect id="Block02" y="53" width="150" height="28" />
            <rect id="Block03" y="106" width="150" height="28" />
        </svg>
    );
}
export const CBMSideNavIcon = React.memo(CBSideNavIcon, () => true);

export function CBTableSortIcon(props) {
    return (

        <svg version="1.2" baseProfile="tiny" id="Ebene_1" xmlns="http://www.w3.org/2000/svg"
            x="0px" y="0px" viewBox="0 0 200 173.206"
            height={props.height || "100%"} className={styles.tableSort} style={{display: "block", margin: "auto"}}>
            <polygon points="0,173.206 100,0 200,173.206 " />
        </svg>
    );
}
export const CBMTableSortIcon = React.memo(CBTableSortIcon, () => true);

export function CBTableSortReversedIcon (props) {
    return (

        <svg version="1.2" baseProfile="tiny" id="Ebene_1" xmlns="http://www.w3.org/2000/svg"
            x="0px" y="0px" viewBox="0 0 200 173.206"
            height={props.height || "100%"} className={styles.tableSort} style={{display: "block", margin: "auto"}}>
            <polygon points="200,0 100,173.206 0,0 "/>
        </svg>
    );
}
export const CBMTableSortReversedIcon = React.memo(CBTableSortReversedIcon, () => true);

export function CBDownloadIcon (props) {
    return (
        <svg version="1.2" baseProfile="tiny" id="Download"
            xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="-0.04 -0.04 170.079 170.079"
            height={props.height || "100%"}  className={styles.download} style={{display: "block", margin: "auto"}}>
            <rect id="Bar" x="-0.037" y="147.362" width="170.079" height="22.677"/>
            <rect id="Arrow1" x="67.907" y="-0.04" width="39.686" height="85.04"/>
            <polygon id="Arrow2" points="134.736,56.362 87.75,137.744 40.764,56.362 "/>
        </svg>
    );
}
export const CBMDownloadIcon = React.memo(CBTableSortIcon, () => true);

export function CBToEndIcon (props) {
    return (
        <svg version="1.2" baseProfile="tiny" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" 
            x="0px" y="0px" viewBox="0 0 274.446 170"
            height={props.height || "100%"} className={styles.defaultIcon} style={{display: "block", margin: "auto"}}>
            <path d="M0,20C0,9,7.794,4.5,17.32,10l112.583,65c9.526,5.5,9.526,14.5,0,20L17.32,160C7.794,165.5,0,161,0,150V20z" />
            <path d="M127.223,20c0-11,7.794-15.5,17.32-10l112.582,65c9.526,5.5,9.526,14.5,0,20l-112.582,65c-9.526,5.5-17.32,1-17.32-10V20z"
            />
            <path d="M246.446,160c0,5.5,4.5,10,10,10h8c5.5,0,10-4.5,10-10V10c0-5.5-4.5-10-10-10h-8c-5.5,0-10,4.5-10,10V160z" />
        </svg>
    );
}
export const CBMToEndIcon = React.memo(CBToEndIcon, () => true);

export function CBToStartIcon (props) {
    return (
        <svg version="1.2" baseProfile="tiny" id="Ebene_1" xmlns="http://www.w3.org/2000/svg"
            x="0px" y="0px" viewBox="0 0 274.446 170"
            height={props.height || "100%"} className={styles.defaultIcon} style={{display: "block", margin: "auto"}}>
            <path d="M274.446,150c0,11-7.794,15.5-17.32,10L144.543,95c-9.526-5.5-9.526-14.5,0-20l112.582-65c9.526-5.5,17.32-1,17.32,10V150z"
            />
            <path d="M147.223,150c0,11-7.794,15.5-17.32,10L17.32,95c-9.526-5.5-9.526-14.5,0-20l112.583-65c9.526-5.5,17.32-1,17.32,10V150z" />
            <path d="M28,10c0-5.5-4.5-10-10-10h-8C4.5,0,0,4.5,0,10v150c0,5.5,4.5,10,10,10h8c5.5,0,10-4.5,10-10V10z" />
        </svg>
    );
}
export const CBMToStartIcon = React.memo(CBToStartIcon, () => true);

export function CBTurnBoardIcon (props) {
    return (

        <svg version="1.2" baseProfile="tiny" id="TurnBoardIcon"
            xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
            viewBox="0 0 303.866 170"
            height={props.height || "100%"} className={styles.defaultIcon} style={{display: "block", margin: "auto"}}>
            <path d="M274.076,77.414c0-25.335-20.364-45.872-45.485-45.872V0c41.568,1.208,74.902,35.367,74.902,77.362
	        c0,41.993-33.334,76.154-74.902,77.362v-31.438C253.711,123.285,274.076,102.748,274.076,77.414z"/>
            <polygon id="Pointer02" points="176.938,139.509 229.621,109.018 229.621,170 " />
            <path id="Board" d="M169.956,0v170H0.374V0H169.956z M22.818,147.5h62.346V85h62.346V22.5H85.165V85H22.818V147.5z" />
        </svg>
    );
}
export const CBMTurnBoardIcon = React.memo(CBTurnBoardIcon, () => true);