export class CBDebug
{
    static assert = function (_cond, _msg)
    {
    };
    
    static call = function (_ctx, _func)
    {
    };
    
    static profile = function (_name)
    {
    };
    
    static profileEnd = function ()
    {
    };
       
};

export function ASSERT(x)
{
   
};
