
import React from 'react';
import baseStyles from "../CBModules.module.css";
import styles from "./CBGameInfoModule.module.css";

import { CBGameInformation } from "CBReact/Components/GameInformation/CBGameInformation"
import { checkPropsAndRenderComponent } from 'CBReact/Utils/DebugUtils';

export function CBGameInfoModule (props)
{
    function renderCBGameInfoModule ()
    {
        return (
            <div className={`${baseStyles.base} ${styles.gameInfoModule}`}>
                <div className={styles.gameInfoPadding}>
                    <CBGameInformation gameHeader={props.gameHeader}/>
                </div>
            </div>
        );
    }

    return checkPropsAndRenderComponent([props.gameHeader], renderCBGameInfoModule);
}

function gameInfoPropsAreEqual (prev, next)
{
    return prev.gameHeader.toString() === next.gameHeader.toString();
}

export const CBMGameInfoModule = React.memo(CBGameInfoModule, gameInfoPropsAreEqual);