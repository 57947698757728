import React, { useState } from 'react';
import styles from "./CBSearchInput.module.css";

import { checkPropsAndRenderComponent } from 'CBReact/Utils/DebugUtils';
import { CBAutoComplete } from 'CBReact/Components/DesignElements/Selects/CBAutoComplete';

export function CBSearchInput (props)
{
    const [inputData, setInputData] = useState({requestAutoComplete: false, keepAutoComplete: false, text: props.startText ? props.startText : ""});
    const [isActive, setIsActive] = useState(false);

    function handleInputChange (e)
    {
        setInputData({requestAutoComplete: true, keepAutoComplete: false, text: e.target.value});
        props.handleChange(e.target.value);
    }

    function handleFocus (e)
    {
        setIsActive(true);
    }

    function handleBlur (e)
    {
        setIsActive(false);
        setInputData({requestAutoComplete: false, keepAutoComplete: false, text: inputData.text});
    }

    function handleAutoCompleteSubmit (text)
    {
        setInputData({requestAutoComplete: false, keepAutoComplete: false, text: text});
        props.handleChange(text);
    }

    function handleAutoCompleteSelection (text)
    {
        setInputData({requestAutoComplete: false, keepAutoComplete: true, text: text});
    }

    function renderCBSearchInput ()
    {
        return (
            <div className={ `${styles.container} ${isActive ? styles.active : ""}`} style={props.style}>
                <label className={styles.label} htmlFor={props.id}>
                    {props.label}
                </label>
                <input
                    className={styles.searchField}
                    id={props.id}
                    type={props.type}
                    maxLength="40"
                    placeholder={props.placeholder}
                    value={inputData.text}
                    onChange={handleInputChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
                {   props.hasAutoComplete &&
                    <CBAutoComplete 
                    inputData={inputData}
                    handleSubmit={handleAutoCompleteSubmit}
                    handleSelection={handleAutoCompleteSelection}/>
                }
            </div>
        )
    }

    return checkPropsAndRenderComponent([props.id, props.type, props.label, props.placeholder, props.handleChange, props.startText], renderCBSearchInput);
}