

import React from 'react';

export function checkPropsAndRenderComponent (propsToCheck, renderFunction)
{
    for (var i = 0; i < propsToCheck.length; i++)
    {
        if (typeof(propsToCheck[i]) === "undefined")
        {
            console.log(i);
            console.warn("Something went wrong. Please Check props for correct usage.");
            return <div style={{padding: "15px"}}>Something went wrong. I need different props!.</div>
        }
    }

    return renderFunction();
}