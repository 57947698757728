

import React from 'react';
import styles from "./CBButton.module.css";

import { checkPropsAndRenderComponent } from 'CBReact/Utils/DebugUtils';

export function CBButton (props)
{
    function renderCBButton ()
    {
        return (
            <button className={`${styles.base}`} 
                    style={props.style ? props.style : {}} 
                    onClick={props.onClick}>
                {
                    props.children
                }
                {
                    props.borderRight && 
                    <div className={`${styles.border} ${styles.borderRight}`} />
                }
                {
                    props.borderLeft && 
                    <div className={`${styles.border} ${styles.borderLeft}`} />
                }
            </button>
        );
    }

    return checkPropsAndRenderComponent([props.onClick], renderCBButton);
}

export const CBMButton = React.memo(CBButton);