import { useState } from "react";

import { CookieUtils } from 'CBReact/Utils/CookieUtils';

export const DESIGN_THEMES = 
{
    light: 1,
    dark: 2
}

export function useThemeContext ()
{
    const [theme, setTheme] = useState(initDesignTheme());

    function initDesignTheme ()
    {
        // var savedDesignTheme = parseInt(CookieUtils.GetCookie("CBTheme"));
        var savedDesignTheme = parseInt(CookieUtils.GetStorageItem("CBTheme"));

        if (savedDesignTheme && Object.values(DESIGN_THEMES).indexOf(savedDesignTheme) !== -1)
            return savedDesignTheme;
        else if ((window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches))
            return DESIGN_THEMES.dark;
        else 
            return DESIGN_THEMES.light;
    }

    return {theme, setTheme};
}