

export default function getLocalizationObject ()
{
	return {
		LOGIN: 'Log in',
		LOGOUT: 'Log out',
		ACCOUNT: 'My Account',
		BOARDS: 'Boards',
		RECONNECT: 'Reconnect',
		RESIGN: 'Resign',
		CLOSE_BOARD: 'Close Board',
		OFFER_DRAW: 'Offer Draw',
		ACCEPT_DRAW: 'Accept Draw',
		ACCEPTED_DRAW: "{0} accepted a draw.",
		OFFERS_DRAW: "{0} offers a draw.",
		ACCEPTS_DRAW: "{0} accepts a draw.",
		RESIGNS: "{0} resigns.",
		TESTING_CONN: "Testing Connection to Server",
		CONNECTING: "Connecting to server, please wait...",
		RECONNECTED_OK: "Reconnected OK",
		NAME: 'Name',
		NATION: 'Country',
		RANK: 'Rank',
		WHITE: 'White',
		BLACK: 'Black',
		ELO_WHITE: 'Elo White',
		ELO_BLACK: 'Elo Black',
		RESULT: 'Result',
		TIME_CONTROL: 'Time Control',
		TOURNAMENT: 'Tournament',
		GAMES: 'Games',
		CHAT: 'Chat',
		CHAT_PARTNER_GONE: "Your chat partner is gone.",
		CONNECTION: 'Connection',
		MAP: 'Map',
		KING: 'King',
		QUEEN: 'Queen',
		ROOK: 'Rook',
		BISHOP: 'Bishop',
		KNIGHT: 'Knight',
		PAWN: 'Pawn',

		TYPE_CHAT_HERE: 'Type Chat Here',
		TYPE_HERE_TO_CHAT_WITH: 'Type Here To Chat With',
		GUEST: 'Guest',
		CONFIRM: 'Confirm',
		CANCEL: 'Cancel',

		CLOCK: 'Clock',
		TIME: 'Time',
		GAIN_PER_MOVE: 'Gain per Move',
		CLOSE: 'Close',
		ADD: 'Add',
		REMOVE: 'Remove',
		NOTATION: 'Notation',
		BOARD_FRITZ: 'Fritz',
		BOARD_CRYSTALS: 'Crystals',
		BOARD_USCF: 'USCF',
		BOARD_OLDSTYLE: 'OldStyle',
		BOARD_HABSBURG: 'Habsburg',
		BOARD_FRITZ_AND_CHESTER: 'Fritz & Chesster',
		PLAY_BLITZ: 'Play Blitz',

		NEW_GAME: 'New Game',
		TAKE_BACK: 'Take Back',
		MAKE_MOVE: 'Make Move',
		FLIP_BOARD: 'Flip Board',
		COPY: 'Copy',
		SEARCH_BOARD: 'Search Board',
		YEAR: 'Year',
		BOOK: 'Book',
		LIVE_BOOK: 'Live Book',
		MOVE: 'Move',
		NR_GAMES: 'Nr Games',
		SCORE: 'Score',
		LAST_PLAYED: 'Last Played',
		ELO_AVG: 'Elo Avg.',
		VISITS: 'Visits',
		LOADING_STATISTICS: 'Loading statistics',
		LETS_CHECK: "Let's Check",
		EVAL: 'Eval',
		VARIATION: 'Variation',
		DEPTH: 'Depth',
		ENGINE: 'Engine',
		ENTER_YOUR_SEARCH_TEXT: 'Enter your search text...',
		RETRIEVING_GAMES: "Retrieving games",
		PLAYER_DETAILS: 'Player details',
		PLAYER_INFORMATION: 'Player information',
		FIRST_NAME: 'First Name',
		LAST_NAME: 'Last Name',
		YEAR_OF_BIRTH: 'Year of Birth',
		TREND: 'Trend',
		RATING: 'Rating',
		SEND_FEEDBACK: 'Send Feedback',
		YOUR_COMMENT: 'Your Comment',
		YOUR_EMAIL_ADDRESS: 'Your E-mail Address',
		SEND: 'Send',
		BROWSER_DOESNT_SUPPORT_3D: 'Browser does not support 3d boards',
		BOARD3D_FRITZ: 'Fritz',
		BOARD3D_MODERN: 'Modern',
		BOARD3D_ROOM: 'Room',
		BOARD3D_BALLOON: 'Balloon',
		BOARD3D_METAL: 'Metal',
		BOARD3D_SILVER: 'Silver',
		BOARD3D_FUTURE: 'Future',
		BOARD3D_MARBLE: 'Marble',
		WELCOME: 'Welcome',
		MATERIAL: 'Material',
		ANALYSIS: 'Analysis',
		ANALYSIS_ENGINE: 'Engine',

		ENG_SEARCH_INFO_FMT: "Depth {0}/{1}, Speed = {2}kPos/s, Time = {3}s",
		PASTE: 'Paste',
		PW_INVALID: '<bold>User/Password invalid, please try again</bold>',
		PLEASE_LOGIN: 'Please log in with your ChessBase (Playchess) account:',
		ACCOUNTNAME: 'Name',
		PASSWORD: 'Password',
		CREATE_ACCOUNT: 'Create Account',
		LOGIN_GUEST: 'Guest',
		PLEASE_LOGIN_SHORT: 'Please Log In',
		LOADING_ENGINE: 'Loading Engine...',
		LOADING_LIVE_GAMES: 'Loading Live Games ...',
		INVITING_OTHER_PLAYERS: "Inviting other players to a game of {0} ({1})",
		OTHER_TOOLS: 'More Apps',
		DATABASES: 'Databases',
		OPEN_DATABASE: 'Open Database',
		CREATE_DATABASE: 'Create Database',
		DELETE_DATABASE: 'Delete Database',
		DELETE: 'Delete',
		CLEAN_UP: 'Clean Up',
		MY_DATABASES: 'My Databases',
		DATABASES_SHARED_WITH_ME: 'Databases shared with me',
		SELECT_DATABASE_FOR_USER: "Select Database For '{0}'",
		OPEN_PGN: 'Open PGN',
		SAVE: 'Save',
		SAVE_AND_REPLACE_GAMES: 'Save and replace games',
		SAVE_AS_NEW: 'Save As New',
		EDIT_GAME: 'Edit Game',
		DATABASE_FUNCTIONS: 'Open, create and delete databases',
		TEXT_BEFORE: 'Text Before',
		TEXT_AFTER: 'Text',
		START_LINE: 'Start Line',
		PROMOTE_LINE: 'Promote Line',
		DELETE_LINE: 'Delete Line',
		CUT_LINE: 'Cut Line',
		OPEN_PGN_TIP: 'Open a PGN file from your computer',
		TEXT_BEFORE_TIP: 'Add a text annotation before this move',
		TEXT_AFTER_TIP: 'Add a text annotation after this move',
		START_LINE_TIP: 'Retract current move and start a line',
		PROMOTE_LINE_TIP: 'Promote the currently selected line',
		DELETE_LINE_TIP: 'Delete the selected line',
		CUT_LINE_TIP: 'Cut off the following moves',
		STAY_LOGGED_IN: 'Stay Logged In',
		FORGOT_PASSWORD: 'Forgot Password',
		CHANGE_PASSWORD: 'Change Password',
		OLD_PASSWORD: 'Old Password',
		NEW_PASSWORD: 'New Password',
		CONFIRM_PASSWORD: 'Confirm Password',
		MENU_PLAYCHESS_TOOL: "Play & Watch",
		MENU_DATABASE_TOOL: "Live Database",
		MENU_MYGAMES_TOOL: "My Own Games",
		MENU_FRITZ_TOOL: "Chess Program Fritz",
		MENU_FRITZ_CHESSTER_TOOL: "Chess For Kids",
		MENU_TACTICS_TOOL: "Tactics Training",
		LANGUAGE: "Language",
		LOGIN_CREATE_LINK: "Click here to create an account",
		LOGIN_PLEASE_LOGIN: "Please Log In",
		LOGIN_ACCOUNT_FREE: "Creating an account is free. The account is valid for all other ChessBase Web Tools and for play.chessbase.com.",
		LOGIN_WELCOME: "Welcome, {0}",
		MANAGE_FRIENDS: "Manage friends",
		INVITE_FRIEND: "Invite a friend",
		PING: "Ping",
		NOTATION_KING: "K",
		NOTATION_QUEEN: "Q",
		NOTATION_KNIGHT: "N",
		NOTATION_BISHOP: "B",
		NOTATION_ROOK: "R",
		NOTATION_PAWN: "P",

		GENERAL_INVITE: 'Thank you for using the <span style="font-weight:bold">{0}</span>.',
		MYGAMES_INVITE: 'Thank you for using <span style="font-weight:bold">ChessBase MyGames</span>, your personal chess database in the web.',
		MYGAMES_FUNCTIONALITY: "Please login with your ChessBase Account to create and access your cloud chess databases. You can also use the cloud databases with ChessBase 13.",

		ECO: "ECO",
		BACK: "Back",

		NEXT_MOVE: "Next Move",
		PREV_MOVE: "Previous Move",
		NEXT_GAME: "Next Game",
		PREV_GAME: "Previous Game",
		MENU_VIDEO_TOOL: "Training Videos",
		MENU_OPENINGS_TOOL: "Manage Openings",

		REQUESTING_MOVES: "Requesting Moves",
		FRESH_POSITION: "Fresh Position",
		EMBED: "Embed",
		HELP: "Help",
		TOGGLE_3D_2D: "Toggle 2D/3D Board",
		BOARD3D_BLACKWHITE: "Black white",
		MATE: "Mate",
		STALEMATE: "Stalemate",
		SETUP_POSITION: "Setup Position",

		WHITE_MATES: "White mates",
		WHITE_CLEARLY_WINNING: "White is clearly winning",
		WHITE_WINNING: "White is winning",
		WHITE_DECISIVE: "White has a decisive advantage",
		WHITE_CLEARLY_BETTER: "White is clearly better",
		WHITE_BETTER: "White is better",
		WHITE_SLIGHTLY: "White is slightly better",
		WHITE_EDGE: "White is minimally better",

		BLACK_MATES: "Black mates",
		BLACK_CLEARLY_WINNING: "Black is clearly winning",
		BLACK_WINNING: "Black is winning",
		BLACK_DECISIVE: "Black has a decisive advantage",
		BLACK_CLEARLY_BETTER: "Black is clearly better",
		BLACK_BETTER: "Black is better",
		BLACK_SLIGHTLY: "Black is slightly better",
		BLACK_EDGE: "Black is minimally better",

		EQUAL_POS: "The position is equal",
		PREMIUM_ONLY: "Premium Only",
		BOARD3D_CHARACTER: "Avatar",

		CALC_TRAINING: "Assisted Calculation",
		TTIP_CALC_TRAINING: "Calculate variations with Fritz' assistance before you continue the game here.",
		CALC_INFO: "Enter moves blindly. Click in the notation to add variations anywhere. Right-click unveils current position. Click 'Assisted Calculation' to resume your game.",

		CALCULATING: "Calculating...",	// engine
		WOOD: "Wood",	// crystal

		TRAINING_SOLUTION: "Show Solution",
		TRAINING_HELP: "Get Help",
		TRAINING_QUESTION: "Find the correct move",
		UNANNOTATE: "Unannotate",
		MORE_LINES: "More Lines",
		LESS_LINES: "Less Lines",
		CALCULATE_THREAT: "Calculate Threat",
		START_RADIO: "Start",
		PAUSE_RADIO: "Pause",
		CURRENTLY_NO_LIVE_BROADCAST: "At the moment there’s no live broadcast",
		CONNECTED_TO_SERVER: "Connected to server: {0}",
		INITIALIZING: "Initializing",
		INSERT_DIAGRAM: "Insert Diagram",
		BOARD: "Board",

		LOADING_BOARD: "Loading board, just a second...",
		LIKE: "Like",
		DB_NOGAMES_FOUND: "No games found for this position and search string.",

		YOUR_KING_ACC: 'your king',
		YOUR_QUEEN_ACC: 'your queen',
		YOUR_KNIGHT_ACC: 'your knight',
		YOUR_BISHOP_ACC: 'your bishop',
		YOUR_ROOK_ACC: 'your rook',
		YOUR_PAWN_ACC: 'your pawn',

		MOVE_IMP: 'Move',
		WHITES_MOVE: "White's move",
		BLACKS_MOVE: "Black's move",
		CLICK_TO_FLIP_BOARD: "click to flip board",
		MAXIMIZE: "Maximize",
		PIECES: "Pieces",
		SOUND_ONOFF: "Sound On/Off",
		GOTO_BEGIN: "Goto Begin",
		GOTO_END: "Goto End",
		LOAD_PREV: "Load Prev.",
		LOAD_NEXT: "Load Next",
		SEARCH_MATERIAL: 'Search Material',
	};
}