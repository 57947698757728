// "use strict";

import { PLMMRecord } from "common/Chess/Format/PLMMRecord"

export class MMNameArray {

   // constructor () {
   // };

   readFromDataBuffer ( rBuf ){
      var nPhotos = rBuf.readUint16();
      this.clear ();

      for (var n =0; n< nPhotos; n++) {
         var aRecord = new PLMMRecord ();
         aRecord.readFromDataBuffer (rBuf);
         this.append (aRecord);
      }
   }

   getRecord (n) {
      return this.vecMM[n];
   }

   append ( aRecord ) {
      this.vecMM.push(aRecord);
   }

   count () {
      return this.vecMM.Count;
   }


   clear () {
      if ( this.vecMM )
         this.vecMM.clear();
      else
         this.vecMM = [];
   }

}

