// jb 12.11.2013

// "use strict";

import { Move } from "common/Chess/Logic/Move"
import { CBDate } from "common/Tools/CBDate";
import { isFlag } from "common/Tools/Tools"
import { GamePerformanceStats } from "common/Chess/TeraBrain/GamePerformanceStats"
import { CBDebug } from "common/Tools/Debug/debug_util";
import { TBrainTimeStamp } from "common/Chess/TeraBrain/TeraBrainTimeStamp"

export var TBRAIN =
{
	HASH_LINE_LEN: 3,
	HASH_LINE_CNT: 3,

	MAX_STR: 63,

	//	flags are 8 bit
	EVAL_MOVE_NO: 0x01,		// deprecated, can be reused later
	USER_IS_LOGIN: 0x02,		// anonymous user -> machine guid or chessserver login
	USER_MAY_DISCOVER: 0x04,		// user has certain rank on chessserver -> may be engraved as discoverer of a position and the position is discoverable (not in the opening)
	USER_TRUSTWORTHY: 0x08,		// user has certain rank on chessserver -> may be engraved as discoverer of a position.
	EVAL_NORMED: 0x10		// evaluation not suffering from btm toggle bug (clients after Jan 2012)
};


export class TBrainMove
{

	//var TBRAIN = {
	//	HASH_LINE_LEN: 3,
	//	HASH_LINE_CNT: 3,

	//	MAX_STR: 63,

	//	//	flags are 8 bit
	//	EVAL_MOVE_NO: 0x01,		// deprecated, can be reused later
	//	USER_IS_LOGIN: 0x02,		// anonymous user -> machine guid or chessserver login
	//	USER_MAY_DISCOVER: 0x04,		// user has certain rank on chessserver -> may be engraved as discoverer of a position and the position is discoverable (not in the opening)
	//	USER_TRUSTWORTHY: 0x08,		// user has certain rank on chessserver -> may be engraved as discoverer of a position.
	//	EVAL_NORMED: 0x10		// evaluation not suffering from btm toggle bug (clients after Jan 2012)
	//};

	static TBLineFeedbackType = {
		TBLINEFB_LIKE: 0,
		TBLINEFB_DISLIKE: 1,
		TBLINEFB_REPORT: 2
	};

	static CALCTIME_MINIMAL = 210;	// internal statistics (hot position) only

	static CALCTIME_REASONABLE_THRESH = 260;

	static CALCTIME_DONATION = 265;
	static CALCTIME_DISCOVERY = 305;	// set this value higher after some time

	static CALCTIME_CRAZY = 450;
	static TBRAINMOVE_HASEVAL = 0x01;
	static TBRAINMOVE_HASCOMMENT = 0x02;

	static HASH_LINE_LEN = 3;
	static HASH_LINE_CNT = 3;

	static CALCTIME_DEEP = 370;

	// TBrainMove.prototype.HASH_LINE_LEN = 3;
	// TBrainMove.prototype.HASH_LINE_CNT = 3;

	// TBrainMove.prototype.CALCTIME_DEEP = 370;

	constructor ()
	{
		this.move = new Move();
		this.m_performanceStats = new GamePerformanceStats();
		this.m_nFlags = 0;
	};

	getMoveRecord ()
	{
		return this.move;
	};


	getLine ( n )
	{
		CBDebug.assert( n < TBRAIN.HASH_LINE_LEN );
		if ( n < TBRAIN.HASH_LINE_LEN )
			return this.m_aLine[n];
		return 0;
	};

	getLine ( nMove, n )
	{
		// !?!?

		CBDebug.assert( n < TBRAIN.HASH_LINE_LEN );
		this.m_aLine[n] = nMove;
	};

	getNEval ()
	{
		return this.m_nEval;
	};

	getNTimeStamp ()
	{
		return this.m_nTimeStamp;
	};

	setNTimeStamp ( n )
	{
		this.m_nTimeStamp = n;
	};

	hasEval ()
	{
		return isFlag( this.m_nFlags, TBrainMove.TBRAINMOVE_HASEVAL ) || this.m_nEval != 0;
	};

	setHasEval ( b )
	{
		if ( b )
			this.m_nFlags |= TBrainMove.TBRAINMOVE_HASEVAL;
		else
			this.m_nFlags &= ~TBrainMove.TBRAINMOVE_HASEVAL;
	};

	hasComment ()
	{
	//	return ISFLAG( TBRAINMOVE_HASCOMMENT );
	};

	setHasComment ( b )
	{
	//	SETFLAG( TBRAINMOVE_HASCOMMENT, b );
	};


	getPerformanceStats ()
	{
		return this.m_performanceStats;
	};

	getNGames ()
	{
		return this.m_performanceStats.getNGames();
	};

	getEloAv ()
	{
		return this.m_performanceStats.getSortEloAv();
	};

	initNaked ( move )
	{
		this.move = move;
		this.m_nEval = 0;
		this.m_nTimeStamp = 0;
		this.m_nFlags = 0;
		this.m_aLine = [];
		this.m_performanceStats.initEmpty();
	};

	writeToDataBuffer ( rBuf )
	{
		rBuf.BeginSizedWrite();

		rBuf.WriteInt64( this.m_nVisits );

		rBuf.WriteBYTE( this.move.frSq );
		rBuf.WriteBYTE( this.move.toSq );
		rBuf.WriteBYTE( this.move.nType );
		rBuf.WriteBYTE( this.move.nProm );

		rBuf.WriteShort( this.m_nEval );
		rBuf.WriteUShort( this.m_nTimeStamp );
		rBuf.WriteShort( this.m_nFlags );

		this.m_performanceStats.WriteToDataBuffer( rBuf );

		//rBuf.WriteData( m_aLine, sizeof(m_aLine) );
		rBuf.EndSizedWrite();
	};

	readFromDataBuffer ( rBuf )
	{
		rBuf.beginSizedRead();

		this.m_nVisits = rBuf.readInt64();

		this.move.from = rBuf.readUint8();
		this.move.to = rBuf.readUint8();
		this.move.nType = rBuf.readUint8();
		this.move.prom = rBuf.readUint8();

		this.m_nEval = rBuf.readInt16();
		this.m_nTimeStamp = rBuf.readUint16();
		this.m_nFlags = rBuf.readInt16();

		this.m_performanceStats.readFromDataBuffer( rBuf );

		this.m_aLine = [];
		for ( var nLine = 0; nLine < TBrainMove.HASH_LINE_LEN ; ++nLine )
		{
			var lineItem = rBuf.readUint16();
			this.m_aLine.push( lineItem );
		}
		rBuf.endSizedRead();

		return true;
	};

	getTimeStamp ()
	{
		return TBrainTimeStamp.UShortToDate( this.m_nTimeStamp );
	};

	getNVisits ()
	{
		return this.m_nVisits;
	};

	static getTimeStampToday ()
	{
		// NH2020 assuming CBDate
		var date = new CBDate();
		date.setToday();
		date.setYear( date.getYear() - 1945 );
		return date.getNum();
	};

	toString ()
	{
		return this.move.toString() + ", N=" + this.getNGames() + ",ev=" + this.m_nEval;
	};

}




