
import { useEffect, useRef, useState } from 'react';

import { PlayerLobby } from 'common/Chess/PlayerBase/PlayerLobby';
import { LoginMode } from 'common/WebClient/Protocol/LogonData';
import { System } from 'common/Tools/System';
import { Nation } from 'common/Tools/CBNation';
import { Title } from 'common/Chess/Tools/CBTitle';
import { CBDate } from 'common/Tools/CBDate';
import { BinToString } from 'common/Container/BinToString';


const SERVER = System.isHTTPS() ? "wss://pbridge.chessbase.com:443" : "ws://pbridge.chessbase.com:7000";

export function usePlayerLobbyContext ()
{
    const playerLobbyRef = useRef();
    const [isConnected, setIsConnected] = useState(false);
    const [playerInfoWhite, setPlayerInfoWhite] = useState({});
    const [playerInfoBlack, setPlayerInfoBlack] = useState({});

    useEffect(() => {
        if (!playerLobbyRef.current)
        {
            var newPlayerLobby = new PlayerLobby();
            newPlayerLobby.create( SERVER, "Guest", "", LoginMode.GUEST );
            newPlayerLobby.connect();
            newPlayerLobby.addOnConnectedListener(handlePlayerLobbyConnected);
            playerLobbyRef.current = newPlayerLobby;
            window.addEventListener('beforeunload', logOffPlayerLobby);
        }
    },[]);

    function handlePlayerLobbyConnected ()
    {
        setIsConnected(true);
    }

    // For an extended version of these functions, check NotationHeaderElement
    function requestPlayerInfo (hdr)
    {
        if (isConnected)
        {
            setPlayerInfoWhite({});
            setPlayerInfoBlack({});
            PlayerLobbyUtils.prepareHeader( hdr );
    
            var year = hdr.date.getYear();
            if ( !year )
                year = CBDate.getThisYear();
    
            if ( hdr.white.last )
            {
                playerLobbyRef.current.getPlayer( hdr.white.first, hdr.white.last, hdr.eloWh, year, handleWhitePlayerData);
            }

            if ( hdr.black.last )
            {
                playerLobbyRef.current.getPlayer( hdr.black.first, hdr.black.last, hdr.eloBl, year, handleBlackPlayerData);
            }      
        }
    }

    function handleWhitePlayerData (res)
    {

        var player = res.player;

        if ( player.m_NewCBPlayer )
		{
            setPlayerInfoWhite({
                nation: new Nation (player.m_NewCBPlayer.m_HighData.m_aNation).getStrCode(),
                title: Title.getStr(new Title (player.m_NewCBPlayer.m_HighData.m_aTitle).eTitleCode),
                image: constructImage(res.pic),
            });
        }
    }

    function handleBlackPlayerData (res)
    {
        var player = res.player;
        if ( player.m_NewCBPlayer )
		{
            setPlayerInfoBlack({
                nation: new Nation (player.m_NewCBPlayer.m_HighData.m_aNation).getStrCode(),
                title: Title.getStr(new Title (player.m_NewCBPlayer.m_HighData.m_aTitle).eTitleCode),
                image: constructImage(res.pic),
            });
        }
    }

    function constructImage (img)
    {
        if (!img)
            return null;
        else if (typeof img === "string")
            return img;
        else
            return BinToString.ByteArrayToDataURI( img, "image/jpeg" );
    }

    function logOffPlayerLobby ()
    {
        if (playerLobbyRef.current)
            playerLobbyRef.current.logOff();        
    }

    return {requestPlayerInfo, isConnected, playerInfoWhite, playerInfoBlack};
    // return [requestPlayerInfo, isConnected, playerInfoWhite, playerInfoBlack];
}





class PlayerLobbyUtils
{
    static prepareHeader (hdr)
    {
		hdr.white.last = hdr.white.last.replace( "-", " " );	// Vachier-Lagrave
		hdr.black.last = hdr.black.last.replace( "-", " " );
		var nDot = hdr.white.first.search( /\./ );
		if ( nDot > 0 && hdr.white.first )
			hdr.white.first = hdr.white.first.slice( 0, nDot );
		nDot = hdr.black.first.search( /\./ );
		if ( nDot > 0 && hdr.black.first )
			hdr.black.first = hdr.black.first.slice( 0, nDot );
    }
}