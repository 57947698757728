

import { useContext, useEffect } from 'react';
import { ThemeContext } from "CBReact/Contexts/CBOnlineDBContext"
import { DESIGN_THEMES } from 'CBReact/Hooks/ReactContext/useThemeContext';

export function CBHtmlRoot ()
{
    const theme = useContext(ThemeContext).theme;

    useEffect(() => {

        const htmlTag = document.getElementsByTagName("html")[0];

        switch (theme)
        {
            case DESIGN_THEMES.light:
                htmlTag.setAttribute("data-theme", "light");
                break;
            case DESIGN_THEMES.dark:
                htmlTag.setAttribute("data-theme", "dark");
                break;
            default:
                htmlTag.setAttribute("data-theme", "light");
                break;
        }
    }, [theme])

    return null;
}