import { Log } from 'common/Tools/Log';

export class CBDebug
{
    static assert = function( _cond, _msg )
    {
        if ( !_cond )
        {
            if ( !_msg )
                _msg = "ASSERT FAILURE";
            if ( typeof ( console ) !== "undefined" && console.assert )
            {
                console.assert( _cond, _msg );
                alert( _msg );
                Log.Log( _msg );
            }
            else
                throw ( _msg );

        }
    };

    static call = function( _ctx, _func )
    {
        var args = arguments;
        var params = [];

        for ( var inx = 2; inx < args.length; ++inx )
            params.push( args[ inx ] );

        _func.apply( _ctx, params );
    };

    static profile = function( _name )
    {
        if ( typeof ( console ) !== "undefined" && console.profile )
        {
            console.profile( _name );
        }
    };

    static profileEnd = function()
    {
        if ( typeof ( console ) !== "undefined" && console.profileEnd )
        {
            console.profileEnd();
        }
    };

};

export function ASSERT( x )
{
    CBDebug.assert( x, x );
};
