import React, { useContext, useState, Fragment, useEffect, useRef, useCallback } from 'react';

import { SearchMask } from 'common/Chess/OnlineDB/SearchMask';
import { OnlineLobbyContext, LoginContext } from 'CBReact/Contexts/CBOnlineDBContext';
import { LOGIN_MODES } from 'CBReact/Components/Account/AccountUtils';
import { CBSearchBar } from 'CBReact/Components/SearchField/CBSearchBar';
import { CBSearchFilterContainer } from 'CBReact/Components/SearchField/CBSearchFilterContainer';
import { checkPropsAndRenderComponent } from 'CBReact/Utils/DebugUtils';
import { CBPremiumSearch } from 'CBReact/Components/SearchField/CBPremiumSearch';

// TODO: Hier können die props.gameKernel mit props.boardPosition ersetzt werden
export function CBSearchContainer (props)
{
    const searchOnlineDB = useContext(OnlineLobbyContext).searchOnlineDB;
    const onlineLobbyIsConnected = useContext(OnlineLobbyContext).isConnected;
    const loginStatus = useContext(LoginContext).loginStatus;

    const [filterList, setFilterList] = useState([]);
    const [quickSearchFilterList, setQuickSearchFilterList] = useState([]);
    const [showPremiumSearch, setShowPremiumSearch] = useState(false);

    const quickSearchFilterRef = useRef([]);
    const searchFormRef = useRef();
    const searchOptions = useRef({
        useBoard: true,
        white: null,
        whiteEloMin: null,
        black: null,
        blackEloMin: null,
        yearMin: null,
        yearMax: null,
        place: null,
        quickSearch: "",
        wins: true,
        draws: true,
        losses: true,
        ignoreColor: false,
    });

    const handleClick = useCallback ((e) =>
    {
        if (showPremiumSearch && searchFormRef.current && !searchFormRef.current.contains(e.target))
            setShowPremiumSearch(false);
    }, [showPremiumSearch]);

    // Initialise Click Handler to close premium search, when clicked outside
    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return (() => {document.removeEventListener("mousedown", handleClick)}); 
    }, [handleClick]);

    useEffect(() => {
        if (onlineLobbyIsConnected)
            searchDatabase();
    }, [onlineLobbyIsConnected]);

    function searchDatabase ()
    {
        var searchMask = new SearchMask();
        var game = props.gameKernel.game;
        if (game != null)
        {
            // Handle Board Search
            searchMask.setSearchBoard( game.getCurPos().board, game.getCurPos().getSideToMove() );
            searchMask.setUseBoard(searchOptions.current.useBoard ?? false);
            
            // Handle Free Text Search / Quick Search
            searchMask.freeText = "";
            for (let i = 0; i < quickSearchFilterRef.current.length; i++)
                searchMask.freeText += quickSearchFilterRef.current[i] + (i !== quickSearchFilterRef.current.length - 1 ? " " : "");
            
            let newFilter = addQuickSearchFilter(searchOptions.current.quickSearch);
            if (newFilter)
                searchMask.freeText += " " + newFilter;

            setQuickSearchFilterList(quickSearchFilterRef.current);
            searchOptions.current.quickSearch = "";

            // Handle extended search
            if (loginStatus === LOGIN_MODES.standard || loginStatus === LOGIN_MODES.premium)
            {
                searchMask.m_strWhMask = searchOptions.current.white ?? "";
                searchMask.m_strBlMask = searchOptions.current.black ?? "";
                searchMask.m_iWhiteMinElo = searchOptions.current.whiteEloMin ?? 0;
                searchMask.m_iBlackMinElo = searchOptions.current.blackEloMin ?? 0;
                searchMask.m_iMinYear = searchOptions.current.yearMin ?? 0;
                searchMask.m_iMaxYear = searchOptions.current.yearMax ?? 3000;
                searchMask.m_strPlaceMask = searchOptions.current.place ?? "";
                searchMask.setWins(searchOptions.current.wins ? true : false);
                searchMask.setDraws(searchOptions.current.draws ? true : false);
                searchMask.setLosses(searchOptions.current.losses ? true : false);
                searchMask.setBlack(searchOptions.current.ignoreColor ? true : false);
            }

            setFilterList([
                {onDelete: () => {searchOptions.current.useBoard = null;}, text: searchOptions.current.useBoard ? "Board" : null},
                {onDelete: () => {searchOptions.current.white = null;}, text: searchOptions.current.white},
                {onDelete: () => {searchOptions.current.whiteEloMin = null;}, text: searchOptions.current.whiteEloMin},
                {onDelete: () => {searchOptions.current.black = null;}, text: searchOptions.current.black,},
                {onDelete: () => {searchOptions.current.blackEloMin = null;}, text: searchOptions.current.blackEloMin},
                {onDelete: () => {searchOptions.current.yearMin = null;}, text: searchOptions.current.yearMin ? "Year Min: " + searchOptions.current.yearMin : null,},
                {onDelete: () => {searchOptions.current.yearMax = null;}, text: searchOptions.current.yearMax ? "Year Max: " + searchOptions.current.yearMax : null,},
                {onDelete: () => {searchOptions.current.place = null;}, text: searchOptions.current.place ? "Place: " + searchOptions.current.place : null,},
                {onDelete: () => {searchOptions.current.wins = true;}, text: searchOptions.current.wins ? null : "No Wins"},
                {onDelete: () => {searchOptions.current.draws = true;}, text: searchOptions.current.draws ? null : "No Draws"},
                {onDelete: () => {searchOptions.current.losses = true;}, text: searchOptions.current.losses ? null : "No Losses"},
                {onDelete: () => {searchOptions.current.ignoreColor = false;}, text: searchOptions.current.ignoreColor ? "Ignore Color" : null},
            ]);

            searchOnlineDB(searchMask, 50, game.getMoveNo());
        }
    }

    function removeFilter (e, filterIndex)
    {
        e.preventDefault();
        filterList[filterIndex].onDelete();
        searchDatabase();
    }

    function addQuickSearchFilter (filterText)
    {
        if (filterText && filterText !== "" && !quickSearchFilterRef.current.includes(filterText))
        {
            filterText.trim();
            quickSearchFilterRef.current.push(filterText);
            return filterText;
        }

        return false;
    }

    function removeQuickSearchFilter (e, filterIndex)
    {
        e.preventDefault();
        quickSearchFilterRef.current.splice(filterIndex, 1);
        searchDatabase();
    }
    
    function handleSubmit (e)
    {
        if (e)
            e.preventDefault();

        setShowPremiumSearch(false);
        searchDatabase();
    }

    function renderCBSearchContainer ()
    {    
        return (
            <Fragment>
                <form onSubmit={handleSubmit} autoComplete="off" ref={searchFormRef}>
                    <CBSearchBar
                        searchOptions={searchOptions}
                        handleSubmit={handleSubmit}
                        setShowPremiumSearch={setShowPremiumSearch}/>
                    {
                        showPremiumSearch &&
                        <CBPremiumSearch
                            searchOptions={searchOptions}
                            handleSubmit={handleSubmit}/>
                    }
                    <CBSearchFilterContainer 
                        filterList={filterList}
                        quickSearchFilterList={quickSearchFilterList}
                        removeFilter={removeFilter}
                        removeQuickSearchFilter={removeQuickSearchFilter}/>
                </form>
            </Fragment>
        );
    }

    return checkPropsAndRenderComponent([props.gameKernel], renderCBSearchContainer);
}