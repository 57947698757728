
import React  from 'react';
import styles from "./CBBoardArrow.module.css";

import { checkPropsAndRenderComponent } from 'CBReact/Utils/DebugUtils';

// Arrow Definitions
const arrowHeadWidth = 30;
const arrowHeadHeight = 50;
const arrowHeadStrokeWidth = 5;
const targetOffset = 26;

export function CBBoardArrow (props)
{

    function isKnightMove ()
    {
        let xDist = props.to.x - props.from.x;
        let yDist = props.to.y - props.from.y;

        if ((Math.abs(xDist) === 100 && Math.abs(yDist) === 200) || (Math.abs(xDist) === 200 && Math.abs(yDist) === 100))
            return true;

        return false;
    }

    function getArrowTargetOffset (from, to)
    {
        if (props.isLastMoveArrow)
            return from !== to ? to > from ? -targetOffset : targetOffset : 0;
        else
            return 0;
    }

    function getKnightArrowTargetOffset (from, to)
    {
        if (props.isLastMoveArrow)
            return from !== to ? 0.9 : 1.0;
        else
            return 1.0;
    }

    function renderCBBoardArrow ()
    {
        return (
            <svg 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox="-50 -50 100 100" 
            className={`${styles.arrow} ${props.hasArrowHead ? styles.abovePieces : styles.belowPieces}`}
            style={{gridArea: props.squareName}}
            opacity={"0.825"}>
            <defs>
                <marker 
                    className={styles.arrowHead}
                    id="arrow" 
                    refX={arrowHeadWidth * 0.8}
                    refY={arrowHeadHeight * 0.5}
                    markerWidth={arrowHeadWidth} 
                    markerHeight={arrowHeadHeight}
                    markerUnits="userSpaceOnUse"
                    orient="auto-start-reverse">
                    <path 
                        strokeWidth={arrowHeadStrokeWidth * 2} 
                        fill="none"
                        strokeLinecap="round"
                        d={`M ${arrowHeadStrokeWidth} ${arrowHeadStrokeWidth} 
                            L ${arrowHeadWidth - arrowHeadStrokeWidth} ${arrowHeadHeight * 0.5} 
                            L ${arrowHeadStrokeWidth} ${arrowHeadHeight - arrowHeadStrokeWidth}`}/>
                </marker>
            </defs>
            <line 
                className={styles.arrowLine}
                markerEnd={props.hasArrowHead ? "url(#arrow)" : ""}
                x1={0}
                y1={0} 
                x2={isKnightMove() ? ((props.to.x - props.from.x) * getKnightArrowTargetOffset(props.from, props.to)) : (props.to.x + getArrowTargetOffset(props.from.x, props.to.x) - props.from.x)}
                y2={isKnightMove() ? ((props.to.y - props.from.y) * getKnightArrowTargetOffset(props.from, props.to)) : (props.to.y + getArrowTargetOffset(props.from.y, props.to.y) - props.from.y)}
                strokeLinecap="round"
                strokeWidth="10%"
            />
        </svg>
        );

    }

    return checkPropsAndRenderComponent([props.squareName, props.from, props.to, props.hasArrowHead, props.doAnimate], renderCBBoardArrow);
}

// Only Rerender if start or end position changes. Dont see why the other props should change
function boardArrowPropsAreEqual (prev, next)
{
    return  prev.from === next.from && 
            prev.to === next.to;
}

export const CBMBoardArrow = React.memo(CBBoardArrow, boardArrowPropsAreEqual);